body {
  background: #051534;
}

.article-header {
  width: calc(100% + 90px);
  flex: 0 0 calc(100% + 90px);
  max-width: calc(100% + 90px);
}

.preview-container {
  padding: 0 45px;
}

@media screen and (max-width: 575.98px) {
  .preview-container {
    padding: 0 25px;
  }
}

@media screen and (max-width: 575.98px) {
  .article-header {
    width: calc(100% + 60px);
    flex: 0 0 calc(100% + 60px);
    max-width: calc(100% + 60px);
  }
}

.article::-webkit-scrollbar {
  width: 10px;
}
.article::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}
.article::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
}
.article::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.13);
}

.article-container {
  padding: 25px 50px 25px 100px;
  height: 100vh;
  overflow: hidden;
}

.article {
  overflow: auto;
  height: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  scrollbar-width: none;
  padding-top: 75px;
}

.article__slider-container {
  position: relative;
  width: 100%;
  flex-shrink: 2;
  margin: 0 -15px;
  overflow: hidden;
}

.article__slides {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  width: 600%;
}

.article__container {
  width: 100%;
  position: relative;
  padding-top: 10px;
  padding-right: 20px;
  transition: 0ms;
  overflow: hidden;
  flex-shrink: 2;

  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.445) #051534;
}

.article__back {
  position: relative;
  display: block;
  text-decoration: none;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: white;
  padding-left: 27px;
  margin-bottom: 50px;
}

.article__back:hover {
  text-decoration: none;
  color: white;
}

.article__back::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 17px;
  height: 14px;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url("https://kombatlink-assets.azureedge.net/img/arrow_left.svg");
}

@media screen and (max-width: 1519.98px) {
  .article {
    padding: 25px 0 0 0;
    height: auto;
    overflow: visible;
  }
  .article__slider-container {
    position: relative;
    width: calc(100% + 30px);
  }
  .article::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  .article__container {
    padding-right: 0;
  }
  .article-container {
    height: auto;
    overflow: visible;
  }
}

@media screen and (max-width: 1519.98px) {
  @media screen and (min-width: 1200px) {
    .article-container {
      padding: 25px 115px 0 115px;
    }
  }
}

@media screen and (max-width: 1199.98px) {
  @media screen and (min-width: 768px) {
    .article-container {
      padding: 25px 65px 0 65px;
    }
  }
}

@media screen and (max-width: 767.98px) {
  .article-container {
    padding: 60px 35px 0 35px;
  }
}

.article__header-container {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 33px;
}

@media screen and (min-width: 1519.98px) {
  .article__header-container {
    margin-right: 35px;
  }
}

.article__cancel {
  width: 140px;
  height: 40px;
  margin-top: 5px;
}
.article__go-to-premium {
  width: 160px;
  height: 40px;
  margin-top: 5px;
  margin-right: 10px;

  &::before {
    content: "";
    display: inline-block;
    margin-right: 8px;
    width: 15px;
    height: 12px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url("https://kombatlink-assets.azureedge.net/img/white_crown.svg");
  }
}

@media screen and (max-width: 575.98px) {
  .article__cancel,
  .article__go-to-premium {
    margin-top: 10px;
  }
}

.article__subheader {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 29px 0 11px 0;
}

.article__width-mini {
  width: 86px;
  margin-right: 6px;
}

.article__width-0 {
  width: 116px;
  margin-right: 6px;
  margin-top: 21px;
}

.article__width-1 {
  width: 172px;
}

.article__width-2 {
  width: 360px;
  margin-right: 15px;
}

.article__width-3 {
  width: 750px;
  max-width: 100%;
  margin-right: 0;
}

@media screen and (max-width: 899.98px) {
  @media screen and (min-width: 576px) {
    .article__width-2 {
      width: calc(50% - 30px);
      margin-right: 30px !important;
    }
    .article__width-2 .article__width-2 {
      width: 100%;
      margin-right: 0 !important;
    }
  }
}

@media screen and (max-width: 575.98px) {
  .article__width-2 {
    width: 100%;
    margin-right: 30px !important;
  }
  .article__width-1 {
    width: 132px !important;
  }
  .article__width-2 .article__width-2 {
    width: 100%;
    margin-right: 0 !important;
  }
}

.article__height-1 {
  height: 55px;
}

.article__height-custom-field {
  height: 62px;
}

.article__height-2 {
  height: 88px;
}

.article__height-3 {
  height: 200px;
  margin-bottom: 15px;
}

.article__height-4 {
  height: 220px;
  margin-bottom: 15px;
}

.article__input {
  background: #25324e;
  border-radius: 4px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: white;
  padding-left: 20px;
  border: none;
  outline: none;
}

.article__textarea {
  background: #25324e;
  border-radius: 4px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: white;
  padding: 20px 20px 20px 20px;
  border: none;
  outline: none;
  resize: none;
}

.article__input-date {
  position: relative;
  background: #25324e;
  border-radius: 4px;
}

.article__input-date::before {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 17px;
  height: 15px;
  background-color: #25324e;
  background-image: url("https://kombatlink-assets.azureedge.net/img/calendar.svg");
  background-size: 100% 100%;
  z-index: 1;
  color: transparent;
  cursor: pointer;
  opacity: 0.5;
}

.article__input-date input {
  position: absolute;
  left: 0;
  top: 0;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: white;
  padding-left: 20px;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2;
}

.article__input-date input::-webkit-outer-spin-button,
.article__input-date input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.article__input-date input::-webkit-calendar-picker-indicator {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 12px;
  height: 10px;
  z-index: 10;
  cursor: pointer;
  opacity: 0;
}

.article__input-date input::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

.article__checkbox {
  display: none;
}

.article__label {
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background: #25324e;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: 150ms;
  position: relative;
  cursor: pointer;
}

.article__label-icon {
  fill: none;
  opacity: 0.4;
  margin-bottom: 12px;
  transition: 150ms;
}

.article__label-icon path {
  fill: white;
}

.article__label-icon .path-fill-black {
  fill: black;
  opacity: 0.1;
}

.article__label-icon .path-fill-white {
  fill: white;
  opacity: 0.4;
}

.article__label-icon .path-fill-dark-grey {
  fill: #7c8495;
  opacity: 1;
}

.article__label-icon .path-fill-light-grey {
  fill: #f2f2f2;
  opacity: 0.4;
}

.article__label-icon .path-fill-grey {
  fill: #b0b5bf;
  opacity: 1;
}

.article__label-icon .path-fill-light-grey-2 {
  fill: #727989;
  opacity: 1;
}

.article__label-icon .path-fill-white-2 {
  fill: #d6d9df;
  opacity: 1;
}

.article__label-icon .path-fill-dark-white-2 {
  fill: #b0b5bf;
  opacity: 1;
}

.article__label-icon .path-fill-dark-white-3 {
  fill: #9aa0ac;
  opacity: 1;
}

.article__label-icon .path-fill-grey-3 {
  fill: #c6c9ce;
  opacity: 1;
}

.article__label-icon .path-fill-white-3 {
  fill: #e2e4e8;
  opacity: 1;
}

.article__label-text {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: white;
  opacity: 0.4;
  margin: 0;
  transition: 150ms;
}

.article__label-blocked {
  position: absolute;
  display: block;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transition: 150ms;
  background-size: 8px 11px;
  background-image: url("https://kombatlink-assets.azureedge.net/img/white_lock.svg");
  background-position: center center;
  background-repeat: no-repeat;
  transition: 150ms;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    oapcity: 1;
  }
}

.article__label-blocked-container {
  z-index: 999;
  display: block;
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: 200px;
  padding-bottom: 7px;
  transform: translate(-85%, 0);
  opacity: 0;
}

.article__label:hover .article__label-blocked-container {
  animation: show 150ms ease-in-out;
  opacity: 1;
}

.article__label:hover .article__label-blocked {
  background-color: white;
  background-image: url("https://kombatlink-assets.azureedge.net/img/purple_lock.svg");
}

.article__label-blocked-content {
  display: block;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  background: white;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #001030;
  position: relative;
  border-radius: 2px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 85%;
    bottom: -11px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top: 4px solid white;
    transform: translate(-50%, 0);
  }
}

.article__blocked-link {
  color: #3c3daa;
  text-decoration: underline;

  &:hover {
    color: #3c3daa;
    text-decoration: underline;
  }
}

.article__label-director {
  position: absolute;
  display: block;
  right: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
  background: white;
  opacity: 0.1;
  border-radius: 50%;
  transition: 150ms;
}

.article__label-director::before {
  content: "";
  display: block;
  position: absolute;
  background-color: transparent;
  left: 3px;
  top: 4px;
  width: 10px;
  height: 8px;
  background-size: 100% 100%;
  background-image: url("https://kombatlink-assets.azureedge.net/img/grey_ok.svg");
  opacity: 0;
}

.article__checkbox:checked + .article__label,
#online:checked ~ .row .article__label--online,
#live:checked ~ .row .article__label--live,
#cash-prize:checked ~ .row .article__label--cash-prize,
#merchandise-prize:checked ~ .row .article__label--merchandise-prize,
#repeat-every-hour:checked ~ .row .article__label--repeat-every-hour,
#repeat-every-day:checked ~ .row .article__label--repeat-every-day {
  border: 1px solid #6acafc;
}

.article__checkbox:checked + .article__label .article__label-icon,
#online:checked ~ .row .article__label--online .article__label-icon,
#live:checked ~ .row .article__label--live .article__label-icon,
#cash-prize:checked ~ .row .article__label--cash-prize .article__label-icon,
#merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-icon {
  opacity: 1;
}

.article__checkbox:checked + .article__label .article__label-icon path {
  fill: #6acafc;
}

#live:checked ~ .row .article__label--live .article__label-icon .path-fill-black {
  fill: black;
  opacity: 0.1;
}

#live:checked ~ .row .article__label--live .article__label-icon .path-fill-white {
  fill: white;
  opacity: 0.4;
}

#live:checked ~ .row .article__label--live .article__label-icon .path-fill-dark-grey,
#cash-prize:checked ~ .row .article__label--cash-prize .article__label-icon .path-fill-dark-grey,
#merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-icon .path-fill-dark-grey {
  fill: #6acafc;
  opacity: 1;
}

#merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-icon .path-fill-dark-grey--last {
  fill: #4eb7ed;
  opacity: 1;
}

#live:checked ~ .row .article__label--live .article__label-icon .path-fill-light-grey {
  fill: #f2f2f2;
  opacity: 0.4;
}

#live:checked ~ .row .article__label--live .article__label-icon .path-fill-grey,
#cash-prize:checked ~ .row .article__label--cash-prize .article__label-icon .path-fill-grey-3 {
  fill: #e0f4ff;
  opacity: 1;
}

#merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-icon .path-fill-grey-3 {
  fill: #f7f8ff;
  opacity: 1;
}

#online:checked ~ .row .article__label--online .article__label-icon .path-fill-grey-2 {
  fill: #6acafc;
  opacity: 1;
}

#online:checked ~ .row .article__label--online .article__label-icon .path-fill-light-grey-2 {
  fill: #6acafc;
  opacity: 1;
}

#online:checked ~ .row .article__label--online .article__label-icon .path-fill-white-2 {
  fill: #f7f8ff;
  opacity: 1;
}

#online:checked ~ .row .article__label--online .article__label-icon .path-fill-dark-white-2 {
  fill: #e0f4ff;
  opacity: 1;
}

#cash-prize:checked ~ .row .article__label--cash-prize .article__label-icon .path-fill-dark-white-3,
#merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-icon .path-fill-dark-white-3 {
  fill: #6acafc;
  opacity: 1;
}

.article__checkbox:checked + .article__label .article__label-text,
#online:checked ~ .row .article__label--online .article__label-text,
#live:checked ~ .row .article__label--live .article__label-text,
#cash-prize:checked ~ .row .article__label--cash-prize .article__label-text,
#merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-text,
#repeat-every-hour:checked ~ .row .article__label--repeat-every-hour .article__label-text,
#repeat-every-day:checked ~ .row .article__label--repeat-every-day .article__label-text {
  color: #66c3fa;
  opacity: 1;
}

.article__checkbox:checked + .article__label .article__label-director,
#cash-prize:checked ~ .row .article__label--cash-prize .article__label-director,
#merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-director,
#online:checked ~ .row .article__label--online .article__label-director,
#live:checked ~ .row .article__label--live .article__label-director {
  background: #6acafc;
  opacity: 1;
}

.article__checkbox:checked + .article__label .article__label-director::before,
#cash-prize:checked ~ .row .article__label--cash-prize .article__label-director::before,
#merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-director::before,
#online:checked ~ .row .article__label--online .article__label-director::before,
#live:checked ~ .row .article__label--live .article__label-director::before {
  opacity: 1;
}

.article__online-event-container,
.article__live-event-container,
.article__repeat-every-hour-container,
.article__repeat-every-day-container,
.article__cash-prize-container,
.article__merchandise-prize-container {
  display: none;
}

#live:checked ~ .article__live-event-container,
#online:checked ~ .article__online-event-container,
#repeat-every-hour:checked ~ .article__repeat-every-hour-container,
#repeat-every-day:checked ~ .article__repeat-every-day-container,
#cash-prize:checked ~ .article__cash-prize-container,
#merchandise-prize:checked ~ .article__merchandise-prize-container {
  display: block;
}

.structure {
  position: relative;
  height: 100px;
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-bottom: 23px;
  opacity: 0.4;
  transition: 150ms;
}

.structure__width-1 {
  width: 53px;
  margin-bottom: 4px;
  padding: 5px;
}
.structure__width-2 {
  width: 70px;
  margin-bottom: 7px;
  padding: 8px 7px;
}

.structure__container {
  display: block;
  background: white;
  border-radius: 4px;
}

.structure__container:last-of-type {
  margin-bottom: 0;
}

.structure__player {
  display: block;
  background: #e1e5fd;
  border-radius: 46px;
}

.structure__player--blue {
  background: #49c3fd;
  opacity: 0.5;
}

.structure__player--green {
  background: #b2e480;
}

.structure__player--purple {
  background: #3c3daa;
  opacity: 0.5;
}

.structure__width-1 .structure__player {
  width: 36px;
  height: 5px;
  margin-bottom: 3px;
}

.structure__width-2 .structure__player {
  width: 45px;
  height: 8px;
  margin-bottom: 4px;
}

.col-12:last-of-type .structure__player {
  margin-bottom: 0;
}

.structure__director {
  display: block;
  border-radius: 50%;
  background: #e1e5fd;
}

.structure__director--blue {
  background: #49c3fd;
}

.structure__director--green {
  background: #6cd000;
}

.structure__director--purple {
  background: #3c3daa;
}

.structure__width-1 .structure__director {
  width: 5px;
  height: 5px;
}

.structure__width-2 .structure__director {
  width: 8px;
  height: 8px;
}

.structure__arrow {
  fill: none;
  stroke: #e1e5fd;
}

.structure__text {
  display: block;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: white;
  margin-right: 10px;
}

.article__checkbox:checked + .article__label .structure {
  opacity: 1;
}

.article__buttons-container {
  margin: 25px 0 40px 0;
  padding-top: 35px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.article__buttons-container button {
  width: 140px;
  height: 40px;
  margin: 0;
}

.article__save-in-draft {
  margin-right: 10px !important;
}

@media screen and (max-width: 575.98px) {
  .article__label-text {
    font-size: 14px;
  }

  @media screen and (max-width: 575.98px) {
    .article__save-in-draft {
      margin-left: 5px !important;
      width: calc(100% - 5px) !important;
      flex-shrink: 0;
    }
    .article__next-step {
      margin-top: 10px !important;
      margin-left: 5px !important;
      width: calc(100% - 5px) !important;
      flex-shrink: 0;

      &--first {
        margin-top: 0 !important;
        margin-left: 0 !important;
        width: 100% !important;
      }
    }
    .article__create-tournament {
      margin-top: 10px !important;
      margin-left: 5px !important;
      width: calc(100% - 5px) !important;
      flex-shrink: 0;
    }
    .article__prev-stage {
      margin-right: 5px !important;
      width: calc(100% - 5px) !important;
      flex-shrink: 0;
    }
  }
}

.article__right-menu-replacement {
  width: 550px;
}

@media screen and (min-width: 1500px) {
  .article__right-menu-replacement--preview {
    width: 174px;
  }
}

@media screen and (max-width: 1519.98px) {
  @media screen and (min-width: 1200px) {
    .article__right-menu-replacement {
      display: none;
    }

  }
}


.article__prev-stage {
  width: 140px;
  height: 40px;
}

.article__prev-stage::before {
  content: "";
  margin: 14px 7px 15px 0;
  width: 13px;
  height: 11px;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url("https://kombatlink-assets.azureedge.net/img/arrow_left.svg");
}

.row:nth-of-type(3) .article__select {
  z-index: 517;
}
.row:nth-of-type(4) .article__select {
  z-index: 516;
}
.row:nth-of-type(5) .article__select {
  z-index: 515;
}
.row:nth-of-type(6) .article__select {
  z-index: 514;
}
.row:nth-of-type(7) .article__select {
  z-index: 513;
}
.row:nth-of-type(8) .article__select {
  z-index: 512;
}
.row:nth-of-type(9) .article__select {
  z-index: 511;
}
.row:nth-of-type(10) .article__select {
  z-index: 510;
}
.row:nth-of-type(11) .article__select {
  z-index: 509;
}
.row:nth-of-type(12) .article__select {
  z-index: 508;
}
.row:nth-of-type(13) .article__select {
  z-index: 507;
}
.row:nth-of-type(14) .article__select {
  z-index: 506;
}
.row:nth-of-type(15) .article__select {
  z-index: 505;
}
.row:nth-of-type(16) .article__select {
  z-index: 504;
}
.row:nth-of-type(17) .article__select {
  z-index: 503;
}
.row:nth-of-type(18) .article__select {
  z-index: 502;
}
.row:nth-of-type(19) .article__select {
  z-index: 501;
}
.row:nth-of-type(20) .article__select {
  z-index: 500;
}

.select__title {
  height: 55px;
  line-height: 55px;
  font-size: 18px;
  font-family: "BurbankSmall-Medium", sans-serif;
  letter-spacing: 0.05em;
}

.select__title::before {
  top: 25px;
}

.select__container {
  top: 60px;
}

.article__rules {
  width: 1140px;
  height: 350px !important;
  background: #25324e;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
}

.ql-toolbar {
  width: 1140px;
}

.article__main-event-type-container {
  display: none;
}

.article__warning-icon {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.2);
  opacity: 1;
  transition: 150ms;
  margin-bottom: 30px;
  margin-top: 20px;
  margin-right: 10px;
  flex-shrink: 0;
}

.article__warning-icon::before {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  height: 2px;
  top: 3px;
  left: 7px;
  background: white;
}
.article__warning-icon::after {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  height: 6px;
  top: 7px;
  left: 7px;
  background: white;
}

.article__warning-text {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 20px;
  max-width: 710px;
}

.article__hr {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  margin: 46px 30px 0 0;
}

.article__round-container:last-of-type {
  padding-bottom: 10px;

  .article__hr {
    display: none;
  }
}

.article__big-subheader {
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: center;
  -webkit-align-items: center;
  position: relative;
  width: 100%;
  margin: 40px 0 9px 0;

  &--no-lines {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: white;
  }
}

.article__big-subheader span {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: white;
  opacity: 0.4;
  margin-right: 20px;
  flex-shrink: 0;
  display: block;
}

.article__big-subheader div {
  width: 100%;
  height: 1px;
  background: white;
  opacity: 0.2;
}

.article__crop-ticket-picture {
  width: 750px;
}

.article__crop-cover-picture {
  width: 1140px;
}

.article__add-custom-field {
  margin-top: 49px;
  width: 140px;
  height: 40px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 0;
  color: white;
  text-transform: capitalize;
}

.article__add-custom-field::before {
    content: "";
    display: inline-block;
    width: 21px;
    height: 21px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url(https://kombatlink-assets.azureedge.net/img/white_add.svg);
    margin-right: 0;
    transition: 350ms;
    opacity: 0.5;
    background-size: 20px;
}

.article__add-custom-field:hover::before {
  background-image: url("https://kombatlink-assets.azureedge.net/img/dark_add.svg");
}
.state-select-wrapper {
  display: flex;
  width: 111%;
}
@media screen and (max-width: 899.98px) {
  .article__add-custom-field {
    margin-top: 20px;
  }
}

.article__custom-field {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  padding: 15px 20px;
  position: relative;
  margin: 15px 30px 15px 0;
}

.article__custom-field-title {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 12px;
  color: white;
  opacity: 0.4;
  margin-bottom: 7px;
}

.article__custom-field-text {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 13px;
  color: white;
}

.article__remove-custom-field {
  position: absolute;
  right: 20px;
  top: 19px;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
}

.error {
  display: none;
  flex-wrap: nowrap;
  margin: 40px 0 0 0 !important;

  &__icon {
    width: 16px;
    height: 16px;
    background-color: #e13b4c;
    background-size: 6px 6px;
    background-image: url("https://kombatlink-assets.azureedge.net/img/cross-white.svg");
    background-position: center center;
    margin-right: 10px;
    border-radius: 50%;
    flex-shrink: 0;
    background-repeat: no-repeat;
  }

  &__text {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #e13b4c;
  }
}

.article__remove-custom-field::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: 8px;
  left: 8px;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url("https://kombatlink-assets.azureedge.net/img/cross-white.svg");
}

.window {
  width: 460px;
  padding: 40px 25px;
}

.window__subheader--big {
  font-size: 17px;
  line-height: 27px;
  opacity: 1;
  color: #001030;
  margin-top: -10px;
}


.ct__warning-text {
  color: hsla(0,0%,100%,.7) !important;
}

.ct__admin_warning-text {
  color: rgb(178,184,194) !important;
}
.ct__article__warning-icon{
  background-color: rgb(178,184,194) !important;
}

.ct__select {
  height: 55px !important;
}

.ct__select__title-ct {
  font-family: BurbankSmall-Medium, sans-serif!important;
  font-size: 18px !important;
  padding: 7px 20px !important;

  &::before{
    top: 22px !important;
  }
}

.ct__article__subheader {
  display: flex;
  flex-direction: row;
}
.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 0px !important;
}
.article .ql-toolbar {
  overflow: unset !important;
}
.old-kl-css {
   .ql-editor {
    p {
      color: hsla(0, 0%, 100%, .7) !important;
      min-height: 0 !important;
    }
  }
}
.ct__info-icon {
  margin-left: 10px;
  width: 16px;
  height: 16px;
  background-image: url("https://kombatlink-assets.azureedge.net/img/info.svg");
  position: relative;
  display: inline-block;
  .ct__tooltiptext {
    visibility: hidden;
    width: 260px;
    background-color: white;
    color: #001030;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: -300%;
    margin-left: -60px;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 45%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: white transparent transparent transparent;
    }
  }
  &:hover .ct__tooltiptext {
    visibility: visible;
  }
}

svg.ct__article__label-icon{
  height: initial;
  width: initial;
}

.ct__select__container{
  top: 60px !important;
  max-height: 16vh !important;
}
.ct__article__big-subheader-white{
  padding-left: 5px;
}

.ct__article__slider-container {
  .ct__article__slides {
    .ct__article__container:nth-child(5) {
      height: 100vh;
      overflow-y: scroll;
      @media screen and (max-width: 767px){
        height: inherit !important;
      }
    }
  }
}

@media screen and (max-width: 575.98px) {
  .ct__article__width-1 {
    width: 100% !important;
  }
  .ct__article__save-in-draft {
    margin-top: 0 !important;
    margin-bottom: 15px;
  }
  .article{
    .ct__article-header__background{
      height: 340px !important;
      min-height: inherit !important;
      margin-bottom: 150px !important;
    }
    .ct__article-header__header {
      height: 99px;
      font-size: 24px;
      line-height: 33px !important;
      margin-bottom: 15px !important;
      width: 100% !important;
      flex: 0 0 100% !important;
    }
     .ct__article-header__content{
       padding: 20px 15px !important;
     }
    .ct__article-header__registration-container {
      padding: 0;
      margin-bottom: 100px;
    }
    .ct__registration {
      margin: 0 -20px;
      width: calc(100% + 40px);
    }
    .ct__article-header__registration-container{
      margin: 25px 25px 25px 10px !important;
    }
    .ct__registration__column {
      padding-right: 65px;
    }
    .ct__article-header__change-header{
      bottom: 125px !important;
    }
  }
}

.adm{
  .ct__admin_info-icon {
    margin-left: 10px;
    width: 16px;
    height: 16px;
    background-image: url("https://kombatlink-assets.azureedge.net/img/admin_info.svg");
    position: relative;
    display: inline-block;
    .ct__tooltiptext {
      visibility: hidden;
      width: 260px;
      background-color: white;
      color: #001030;
      text-align: center;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: -300%;
      margin-left: -60px;
      border: 1px solid #E1E5EB;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 42%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #E1E5EB transparent transparent transparent;
      }
    }
    &:hover .ct__tooltiptext {
      visibility: visible;
    }
  }
  .ct__article-header__change-header{
    bottom:35px !important;
  }
  .ct__article__remove-custom-field{
    background: #3C3DAA !important;
  }
  .ct__article__subheader{
    opacity: 1 !important;
  }
  .ct__admin_article__custom-field-text{
    color: #001030;
  }
  .ct__article__height-custom-field{
    background: #fff;
    border-radius: 2px;
  }
  .ct__article__big-subheader {
    span{
      color:#001030;
      opacity: 0.7;
    }
  }
  .ct__admin_article__rule {
    height: 350px !important;
    min-height: inherit;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #000;
    .ql-editor{
      p{
        color: #000000 !important;
      }
    }
  }
  .ct__select__title-ct {
    padding: 0 !important;
    height: 55px;
    line-height: 55px;
    font-size: 18px;
    font-family: BurbankSmall-Medium, sans-serif;
    letter-spacing: 0.05em;
    &::before {
      content: "";
      display: block;
      position: absolute;
      right: 16px;
      top: 24px;
      width: 10px;
      height: 6px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-image: url(https://kombatlink-assets.azureedge.net/img/grey_arrow_top.svg);
      transform: rotate(180deg);
      transition: 0.15s;
    }
  }
  .ct__warning-text {
    color: rgb(178,184,194) !important;
  }
  .ct__admin_article__warning-icon {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #363e4a;
    background: transparent;
    opacity: 1;
    transition: 150ms;
    margin-right: 10px;
    flex-shrink: 0;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 2px;
      height: 2px;
      top: 2px;
      left: 6px;
      background: #363e4a;
    }
  }
  .ct__admin_article__warning-icon::after {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 6px;
    top: 6px;
    left: 6px;
    background:#363e4a!important;
  }
  .ct__article__save-in-draft {
    color: #3C3DAA !important;
  }
  .ct__tab-menu__link{
    color: #fff !important;
    opacity: 0.7 !important;
    &:hover{
      opacity: 1 !important;
    }
  }
  .ct__tab-menu__link--active{
    opacity: 1 !important;
  }
  .ct__tab-menu__link:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 2px;
    background: #49c3fd;
    opacity: 0;
  }
  .ct__tab-menu__link:hover::before{
    opacity: 1;
  }
  .ct__tab-menu__link--active:before{
    opacity: 1;
  }
}

@media screen and (max-width: 767px){
  .adm {
    .ct__article__container{
      padding: 0 !important;
    }
    .ct__article__slider-container {
      .ct__article__slides {
        .ct__article__container:nth-child(5){
          overflow-y: inherit !important;
        }
      }
    }
    .ct__article-header__background{
      height: 340px !important;
      min-height: inherit !important;
      margin-bottom: 150px !important;
    }
    .ct__article-header__header {
      height: 99px;
      font-size: 24px;
      line-height: 33px !important;
      margin-bottom: 15px !important;
      width: 100% !important;
      flex: 0 0 100% !important;
    }
    .ct__article-header__content{
      padding: 20px 15px !important;
    }
    .ct__article-header__registration-container {
      padding: 0;
      margin-bottom: 100px;
    }
    .ct__registration {
      margin: 0 -20px;
      width: calc(100% + 40px);
    }
    .ct__article-header__registration-container{
      margin: 166px 25px 25px 10px !important;
    }
    .ct__registration__column {
      padding-right: 65px;
      padding-left: 0;
    }
    .ct__article-header__info-container.flex-sm-row.flex-column{
      position: relative;
      top: 100px;
    }
    .ct__article-header__change-header{
      bottom: 105px !important;
    }
    .ct__tab-menu__link{
      color: #001030 !important;
      opacity: 0.7 !important;
      &:hover{
        opacity: 1 !important;
      }
    }
    .ct__tab-menu__link--active{
      opacity: 1 !important;
    }
    .ct__article__big-subheader-white{
      color: #001030 !important;
      padding-left: 5px;
    }
  }
}


.ct__right-menu-ct__header-text {
  font-family: Burbank-Big-Cd-Bd, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 60px;
}

.ct__right-menu-ct--preview .ct__right-menu-ct__header-text {
  display: none;
}

.ct__right-menu-ct__stage-round {
  position: relative;
  width: 20px;
  height: 20px;
  background: #fff;
  opacity: 0.2;
  margin: 0 25px 80px 5px;
  border-radius: 50%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.ct__article-header__content {
  position: absolute;
  left: 15px;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 50px 60px;
  background: linear-gradient(
                  180deg
          , rgba(30, 44, 72, 0) 20%, #041534 100%), linear-gradient(
                  0deg
          , rgba(0, 20, 60, 0.3), rgba(0, 20, 60, 0.3));
  border-bottom: 1px solid #25324e;
  box-sizing: border-box;
}
.ct__right-menu-ct__stage-round:after {
  content: "";
  position: absolute;
  top: 20px;
  left: 9px;
  width: 2px;
  height: 80px;
  background: #fff;
  display: block;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.ct__right-menu-ct__stage-container:last-of-type .ct__right-menu-ct__stage-round:after {
  display: none;
}

.ct__right-menu-ct__stage-container--active .ct__right-menu-ct__stage-round {
  width: 30px;
  height: 30px;
  background: #fff;
  border: 10px solid #49c3fd;
  opacity: 1;
  margin: 0 25px 80px 0;
}

.ct__right-menu-ct__stage-container--active .ct__right-menu-ct__stage-round:after {
  left: 4px;
  opacity: 0.2;
}

.ct__right-menu-ct__stage-text {
  font-family: BurbankSmall-Medium, sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  opacity: 0.6;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.ct__right-menu-ct__stage-container--active .ct__right-menu-ct__stage-text {
  line-height: 30px;
  opacity: 1;
}

.ct__right-menu-ct__stage-container--done .ct__right-menu-ct__stage-round {
  width: 30px;
  height: 30px;
  border: none;
  background: #6cd000;
  margin: 0 25px 80px 0;
  position: relative;
  opacity: 1;
}

.ct__right-menu-ct__stage-container--done .ct__right-menu-ct__stage-round:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 11px;
  width: 11px;
  height: 9px;
  background-size: 100% 100%;
  background-color: rgba(0, 0, 0, 0);
  background-image: url(https://kombatlink-assets.azureedge.net/img/ok.svg);
}

.ct__right-menu-ct__stage-container--done .ct__right-menu-ct__stage-round:after {
  opacity: 0.2;
  left: 14px;
  top: 30px;
}

.ct__right-menu-ct__stage-container--done .ct__right-menu-ct__stage-text {
  line-height: 30px;
  opacity: 1;
  color: #6cd000;
}

.ct__right-menu-ct__stage-link {
  width: 100%;
  text-align: right;
  display: none;
  font-family: BurbankSmall-Medium, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #fff;
  height: 30px;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}

.ct__right-menu-ct__stage-link:hover {
  color: #49c3fd;
}

.ct__right-menu-ct__stage-container--done .ct__right-menu-ct__stage-link {
  display: block;
}

.ct__article__right-menu-ct.ct__right-menu-ct {
  position: absolute;
  float: right;
  right: 0px;
  min-height: 100%;
  max-height: 260vh;
  overflow: visible;
  background: #3c3daa;
  padding: 60px;
}

.article .ct__article__right-menu-ct.ct__right-menu-ct {
  position: absolute;
  float: right;
  right: 0px;
  min-height: 100%;
  max-height: 260vh;
  overflow: visible;
  background: #25324e;
  padding: 60px;
}
.state-select-wrapper {
  display: flex;
}
.plus-button button {
  margin-top: 0 !important;
}
.state-select-wrapper .plus-button button {
  width: 50px;
  height: 96% !important;
  border-radius: 5px;
  background: #25324e;
  border: none;
  padding: 0 !important;
}