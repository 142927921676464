@import "../../vars";
@import "../../utils/fonts";

.old-kl-css {
    #login-page.video-container {
        position: fixed;
        z-index: -1;
        height: 100vh;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: #051534;
    }
    #login-page.background {
        background: #051534 !important;
        z-index: 0;
        opacity: 0.9;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    #login-page {
        background: #051534 !important;
    }
    #login-page .sign-in {
        display: flex;
        align-items: center;
        min-height: 100vh;
    }
    #login-page .sign-in #login-page .row {
        justify-content: center;
    }
    #login-page .sign-in__header {
        color: white;
        font-family: "Burbank-Big-Cd-Bd", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 45px;
        line-height: 45px;
        letter-spacing: 0.05em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin: 40px 0;
        text-align: center;
    }
    #login-page .sign-in__big-header {
        color: white;
        font-family: "Burbank-Big-Cd-Bd", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 55px;
        line-height: 65px;
        letter-spacing: 0.05em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin: 0 0 30px 0;
    }
    #login-page .sign-in__subheader {
        color: white;
        font-family: "BurbankSmall-Medium", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin-block-start: 0;
        margin-block-end: 0;
    }
    .word-wrap-amount {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 155px;
    }
    @media screen and (max-width: 575.98px) {
        #login-page .sign-in__header {
            font-size: 38px;
            line-height: 38px;
        }
        #login-page .sign-in__big-header {
            font-size: 45px;
            line-height: 45px;
        }
        #login-page .sign-in__subheader {
            font-size: 16px;
            line-height: 22px;
        }
    }
    #login-page .sign-in__role {
        display: none;
    }
    #login-page .role-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 48%;
        height: 88px;
        background: #25324e;
        border: 1px solid rgba(73, 195, 253, 0);
        box-sizing: border-box;
        border-radius: 4px;
        transition: 200ms;
        cursor: pointer;
        font-family: "BurbankSmall-Medium", sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.2);
        margin: 0 0 11px 0;
    }
    #login-page .sign-in__role:checked + .role-label {
        border: 1px solid #49c3fd;
        color: #49c3fd;
    }
    #login-page .sign-in__role:checked + .role-label .role-label__svg svg {
        fill: #49c3fd;
    }
    #login-page .role-label__svg {
        position: relative;
        width: 52px;
        height: 22px;
        margin: 0 0 8px 0;
    }
    #login-page .role-label__svg svg {
        width: 100%;
        height: 100%;
        transition: 200ms;
        fill: rgba(255, 255, 255, 0.2);
    }
    #login-page .sign-in__input-title {
        font-family: "BurbankSmall-Light", sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        margin: 19px 0 12px 0;
        color: rgba(255, 255, 255, 0.7);
    }
    #login-page .sign-in__default-input {
        width: 100%;
        height: 55px;
        outline: none;
        background-color: #25324e !important;
        border-radius: 4px;
        color: white !important;
        box-sizing: border-box;
        padding: 0 20px !important;
        border: none !important;
    }
    #login-page .sign-in__default-input:focus {
        border: 1px solid #49c3fd;
        padding: 0 19px;
    }
    #login-page .sign-in__clean-button {
        position: absolute;
        right: 16px;
        top: 19px;
        width: 18px;
        height: 16px;
        background-color: transparent;
        background-size: 10px 9px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("#{$cdn}/img/cross.svg");
        cursor: pointer;
        border: none;
        outline: none;
        z-index: 0;
        opacity: 0;
        transition: 150ms;
    }
    #login-page .sign-in__default-input:focus + .sign-in__clean-button {
        opacity: 1;
        z-index: 2;
    }
    /* #login-page .sign-in__select, #login-page .sign-in__input-date {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    z-index: 2;
    position: absolute;
} */
    #login-page .sign-in__container {
        position: relative;
        background: #25324e;
        border-radius: 4px;
        height: 55px;
        width: 100%;
    }
    /* #login-page .sign-in__select-container::after, #login-page .sign-in__select-container::before {
    content: "";
    display: block;
    position: absolute;
    top: 28px;
    width: 16px;
    height: 3px;
    background-color: white;
    z-index: 1;
}

#login-page .sign-in__date-container::after {
    content: "";
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 17px;
    height: 15px;
    background-color: transparent;
    background-image: url("#{$cdn}/img/calendar.svg");
    background-size: 100% 100%;
    z-index: 1;
} */
    /* #login-page .sign-in__select-container::after {
    right: 11px;
    transform: rotate(-40deg);
} */
    /* #login-page .sign-in__select-container::before {
    right: 22px;
    transform: rotate(40deg);
} */
    #login-page .background-video {
        margin: 0px;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        visibility: visible;
        min-height: 139vh;
        width: 200vh;
        max-height: 164vh;
    }
    #login-page .sign-in__listed {
        display: none;
    }
    #login-page .listed-label {
        width: 48%;
        height: 54px;
        background: #25324e;
        border: 1px solid rgba(73, 195, 253, 0);
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        justify-content: center;
        -webkit-justify-content: center;
        align-items: center;
        -webkit-align-items: center;
        color: white;
        font-size: 16px;
        line-height: 16px;
        font-family: "BurbankSmall-Light", sans-serif;
        transition: 200ms;
    }
    #login-page .listed-label__round {
        display: block;
        background: white;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin: 0 6px 0 0;
        border: 3px solid #25324e;
        box-shadow: 0 0 0 1px white;
        box-sizing: content-box;
        transition: 200ms;
    }
    #login-page .sign-in__listed:checked + .listed-label {
        border: 1px solid #6acafc;
        color: #6acafc;
    }
    #login-page .sign-in__listed:checked + .listed-label .listed-label__round {
        background: #6acafc;
        box-shadow: 0 0 0 1px #6acafc;
    }
    #login-page .sign-in__login {
        width: 100%;
        height: 55px;
        border-radius: 4px;
        font-size: 18px;
    }
    @media screen and(min-width:768px) {
        #login-page .sign-in__login {
            margin: 0;
        }
    }
    #login-page .sign-in__agree-licence {
        display: none;
    }
    #login-page .sign-in__agree-licence-label {
        width: 20px;
        height: 20px;
        opacity: 0.4;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 2px;
        background-color: transparent;
        background-size: 14px 10px;
        background-position: center center;
        background-repeat: no-repeat;
        transition: 200ms;
        cursor: pointer;
        margin: 0 10px 0 0;
    }
    #login-page .sign-in__agree-licence:checked + .sign-in__agree-licence-label {
        background-image: url("#{$cdn}/img/ok.svg");
        background-color: #49c3fd;
        opacity: 1;
        border: 1px solid #49c3fd;
    }
    #login-page .sign-in__agree-licence-container {
        padding: 0;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        align-items: center;
        -webkit-align-items: center;
        font-family: "BurbankSmall-Light", sans-serif;
        font-style: normal;
        font-size: 14px;
        color: white;
    }
    #login-page .sign-in__no-account {
        padding: 0;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        align-items: center;
        -webkit-align-items: center;
        font-family: "BurbankSmall-Light", sans-serif;
        font-style: normal;
        font-size: 14px;
        color: white;
        justify-content: center;
        margin-top: 10px;
    }
    #login-page .color-blue {
        color: #49c3fd;
    }
    #login-page hr {
        display: block;
        background: white;
        margin: 70px 0;
    }
    #login-page .sign-in__see-more {
        width: 270px;
        height: 55px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: "Burbank-Big-Cd-Bd", sans-serif;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 55px;
        letter-spacing: 0.05em;
        color: white;
        text-decoration: none;
        transition: 200ms;
    }
    #login-page .sign-in__see-more:hover {
        text-decoration: none;
        background: white;
        color: #25324e;
    }
    @media screen and (max-width: 767.96px) {
        #login-page .m-sm-40 {
            margin: 40px 0;
        }
        #login-page .m-sm-20 {
            margin: 20px 0;
        }
        div#login-page::-webkit-scrollbar {
            width: 0px;
            height: 7px;
            border: none;
            overflow: hidden;
            border-radius: 0 3px 3px 0;
        }
        div#login-page::-webkit-scrollbar:hover {
            width: 0px;
            height: 7px;
            border: none;
            overflow: visible;
            border-radius: 0 3px 3px 0;
        }
        div#login-page::-webkit-scrollbar-thumb {
            background-color: rgb(255, 255, 255);
        }
        div#login-page::-webkit-scrollbar-track {
            background-color: transparent;
        }
    }
    #login-page .m-40 {
        margin: 20px 0;
    }
    #login-page .m-b-40 {
        margin-bottom: 40px;
    }
    .p-0 {
        padding: 0;
    }
    #login-page .m-t-38 {
        margin-top: 38px;
    }
    #login-page .back-container {
        margin-top: 100px;
        padding: 0 130px;
    }
    #login-page .back {
        font-family: "BurbankSmall-Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        color: white;
        z-index: 10;
        text-decoration: none;
    }
    #login-page .back::after {
        position: relative;
        width: 17px;
        height: 14px;
        margin: 6px 0 0 10px;
        display: inline-block;
        content: "";
        background-size: 100% 100%;
        background-color: transparent;
        background-image: url(#{$cdn}/img/arrow_left.svg);
        transform: rotate(180deg);
    }
    #login-page .back:hover {
        text-decoration: none;
        color: white;
    }
    #login-page .sign-in__password-detail-container {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }
    #login-page .sign-in__forgot-container {
        font-family: "BurbankSmall-Light", sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        text-align: right;
        color: #49c3fd;
    }
    #login-page .sign-in__forgot-container a:hover {
        text-decoration: none;
        color: #49c3fd;
    }
    #login-page .sign-in__visible-button {
        position: absolute;
        right: 16px;
        top: 19px;
        width: 18px;
        height: 16px;
        background-color: transparent;
        background-size: 18px 11px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("#{$cdn}/img/visible.svg");
        cursor: pointer;
        border: none;
        outline: none;
        z-index: 0;
        opacity: 0;
        transition: 150ms;
    }
    #login-page .sign-in__default-input:focus + .sign-in__visible-button {
        opacity: 0.5;
        z-index: 2;
    }
    input::-ms-clear,
    input::-ms-reveal {
        display: none;
    }
    #login-page .sign-in input::placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
    }
    #login-page .sign-in input:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
    }
    #login-page .sign-in input::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
    }
    #login-page .sign-in .row {
        justify-content: center;
    }
    /* ...................login css end................... */
    /* ...................Register page css start ................... */
    #register-page .background {
        background-color: #051534;
        z-index: 0;
        opacity: 0.83;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    #register-page .sign-up {
        display: flex;
        align-items: center;
        min-height: 100vh;
    }
    #register-page .sign-up__header {
        color: white;
        font-family: "Burbank-Big-Cd-Bd", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 45px;
        line-height: 45px;
        letter-spacing: 0.05em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin: 40px 0;
    }
    #register-page .sign-up__big-header {
        color: white;
        font-family: "Burbank-Big-Cd-Bd", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 55px;
        line-height: 65px;
        letter-spacing: 0.05em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin: 0 0 30px 0;
    }
    #register-page .sign-up__subheader {
        color: white;
        font-family: "BurbankSmall-Medium", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin-block-start: 0;
        margin-block-end: 0;
    }
    @media screen and (max-width: 575.98px) {
        #register-page .sign-up__header {
            font-size: 38px;
            line-height: 38px;
        }
        #register-page .sign-up__big-header {
            font-size: 45px;
            line-height: 45px;
        }
        #register-page .sign-up__subheader {
            font-size: 16px;
            line-height: 22px;
        }
    }
    #register-page .sign-up__role {
        display: none;
    }
    #register-page .role-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 48%;
        height: 88px;
        background: #25324e;
        border: 1px solid rgba(73, 195, 253, 0);
        box-sizing: border-box;
        border-radius: 4px;
        transition: 200ms;
        cursor: pointer;
        font-family: "BurbankSmall-Medium", sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.2);
        margin: 0 0 11px 0;
    }
    #register-page .sign-up__role:checked + .role-label {
        border: 1px solid #49c3fd;
        color: #49c3fd;
    }
    #register-page .sign-up__role:checked + .role-label .role-label__svg svg {
        fill: #49c3fd;
    }
    #register-page .role-label__svg {
        position: relative;
        width: 52px;
        height: 22px;
        margin: 0 0 8px 0;
    }
    #register-page .role-label__svg svg {
        width: 100%;
        height: 100%;
        transition: 200ms;
        fill: rgba(255, 255, 255, 0.2);
    }
    #register-page .sign-up__input-title {
        font-family: "BurbankSmall-Light", sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        margin: 19px 0 12px 0;
        color: rgba(255, 255, 255, 0.7);
    }
    #register-page .sign-up__default-input {
        width: 100%;
        height: 55px;
        outline: none;
        background-color: #25324e;
        border-radius: 4px;
        color: white;
        box-sizing: border-box;
        padding: 0 20px;
        border: none;
    }
    #register-page .sign-up__default-input:focus {
        border: 1px solid #49c3fd;
        padding: 0 19px;
    }
    #register-page .sign-up__clean-button {
        position: absolute;
        right: 16px;
        top: 19px;
        width: 18px;
        height: 16px;
        background-color: transparent;
        background-size: 10px 9px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("#{$cdn}/img/cross.svg");
        cursor: pointer;
        border: none;
        outline: none;
        z-index: 0;
        opacity: 0;
        transition: 150ms;
    }
    #register-page .sign-up__default-input:focus + .sign-up__clean-button {
        opacity: 1;
        z-index: 2;
    }
    #register-page .sign-up__visible-button {
        position: absolute;
        right: 16px;
        top: 19px;
        width: 18px;
        height: 16px;
        background-color: transparent;
        background-size: 18px 11px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("#{$cdn}/img/visible.svg");
        cursor: pointer;
        border: none;
        outline: none;
        z-index: 0;
        opacity: 0;
        transition: 150ms;
    }
    #register-page .sign-up__default-input:focus + .sign-up__visible-button {
        opacity: 0.5;
        z-index: 2;
    }
    #register-page .sign-up__select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        z-index: 0;
        position: absolute;
    }
    /*
#register-page .sign-up__input-date {
    background-color: transparent;
    z-index: 2;
    position: absolute;
}
 */
    #register-page .sign-up__input-date::-webkit-outer-spin-button,
    #register-page .sign-up__input-date::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        display: none;
    }
    #register-page .sign-up__input-date::-webkit-calendar-picker-indicator {
        display: block;
        position: absolute;
        top: 20px;
        right: 17px;
        width: 12px;
        height: 10px;
        background-color: #25324e;
        background-image: url("#{$cdn}/img/calendar.svg");
        background-size: 100% 100%;
        z-index: 10;
        color: transparent;
        cursor: pointer;
    }
    #register-page .sign-up__container {
        position: relative;
        background: #25324e;
        border-radius: 4px;
        height: 55px;
        width: 100%;
    }
    #register-page .sign-up__date-container::after {
        content: "";
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        width: 17px;
        height: 15px;
        background-color: #25324e;
        background-image: url("#{$cdn}/img/calendar.svg");
        background-size: 100% 100%;
        z-index: 1;
        color: transparent;
        cursor: pointer;
    }
    #register-page .sign-up__select-container::after,
    #register-page .sign-up__select-container::before {
        content: "";
        display: block;
        position: absolute;
        top: 28px;
        width: 16px;
        height: 3px;
        background-color: white;
        z-index: 1;
    }
    #register-page .sign-up__select-container::after {
        right: 11px;
        transform: rotate(-40deg);
    }
    #register-page .sign-up__select-container::before {
        right: 22px;
        transform: rotate(40deg);
    }
    #register-page .sign-up__listed {
        display: none;
    }
    #register-page .listed-label {
        width: 48%;
        height: 54px;
        background: #25324e;
        border: 1px solid rgba(73, 195, 253, 0);
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        justify-content: center;
        -webkit-justify-content: center;
        align-items: center;
        -webkit-align-items: center;
        color: white;
        font-size: 16px;
        line-height: 16px;
        font-family: "BurbankSmall-Light", sans-serif;
        transition: 200ms;
    }
    #register-page .listed-label__round {
        display: block;
        background: white;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin: 0 6px 0 0;
        border: 3px solid #25324e;
        box-shadow: 0 0 0 1px white;
        box-sizing: content-box;
        transition: 200ms;
    }
    #register-page .sign-up__listed:checked + .listed-label {
        border: 1px solid #6acafc;
        color: #6acafc;
    }
    #register-page .sign-up__listed:checked + .listed-label .listed-label__round {
        background: #6acafc;
        box-shadow: 0 0 0 1px #6acafc;
    }
    #register-page .sign-up__register {
        width: 100%;
        height: 55px;
        font-size: 18px;
    }
    @media screen and(min-width:768px) {
        #register-page .sign-up__register {
            margin: 0 10px;
        }
    }
    #register-page .sign-up__agree-licence {
        display: none;
    }
    #register-page .sign-up__agree-licence-label {
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background-size: 14px 10px;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 2px;
        cursor: pointer;
        display: block;
        margin: 0 10px 0 0;
        transition: 150ms;
    }
    #register-page .sign-up__agree-licence:checked + .sign-up__agree-licence-label {
        background-image: url("#{$cdn}/img/ok.svg");
        background-color: #49c3fd;
        border: none;
    }
    #register-page .sign-up__agree-licence-container {
        padding: 0;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        align-items: center;
        -webkit-align-items: center;
        font-family: "BurbankSmall-Light", sans-serif;
        font-style: normal;
        font-size: 14px;
        color: white;
    }
    #register-page hr {
        display: block;
        background: white;
        margin: 70px 0;
    }
    #register-page .sign-up__see-more {
        width: 270px;
        height: 55px;
        border-radius: 4px;
        font-family: "Burbank-Big-Cd-Bd", sans-serif;
        font-size: 20px;
    }
    #register-page .back-container {
        margin-top: 100px;
        padding: 0 130px;
    }
    #register-page .back {
        font-family: "BurbankSmall-Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        color: white;
        z-index: 10;
        text-decoration: none;
    }
    #register-page .back:hover {
        text-decoration: none;
        color: white;
    }
    #register-page .back::after {
        position: relative;
        width: 17px;
        height: 14px;
        margin: 6px 0 0 10px;
        display: inline-block;
        content: "";
        background-size: 100% 100%;
        background-color: transparent;
        background-image: url("#{$cdn}/img/arrow_left.svg");
        transform: rotate(180deg);
    }
    #register-page input::-ms-clear,
    input::-ms-reveal {
        display: none;
    }
    #register-page .sign-up input::placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
    }
    #register-page .sign-up input:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
    }
    #register-page .sign-up input::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
    }
    /* ...................Register page css end................... */
    .title,
    a.title {
        font-weight: lighter;
        font-size: 16px;
        line-height: 1.5rem;
        line-height: 23px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        letter-spacing: 0.05em;
        color: #ffffff;
        opacity: 0.9;
        padding: 2px;
    }
    .title,
    a .title {
        opacity: 1;
        color: #ffffff;
    }
    .center-block {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .pull-right {
        float: right !important;
    }
    .pull-left {
        float: left !important;
    }
    .hide {
        display: none !important;
    }
    .show {
        display: block !important;
    }
    .article a:not([href]):not([tabindex]),
    .article a:not([href]):not([tabindex]):focus,
    a:not([href]):not([tabindex]):hover {
        color: #ffffff;
        text-decoration: none;
    }
    a:not([href]):not([tabindex]) {
        color: rgb(0, 0, 0);
        text-decoration: none;
    }
    button:focus,
    a:focus {
        outline: 1px dotted transparent;
        outline: 5px auto transparent;
    }
    a.mobile-menu__notification.mobile-menu__notification--friends {
        color: white;
    }
    #loadingDiv svg {
        width: 15%;
        height: 15%;
        stroke: rgb(51, 120, 64);
    }
    div#adm,
    div.adm,
    .adm body {
        background: #f5f7fa;
    }
    div#adm {
        min-height: 100vh;
        max-height: 100%;
    }

    #adm .body.article,
    .adm_content.article,
    .adm.article,
    .adm .article {
        background: #f5f7fa;
    }
    .adm_content.artile,
    .adm_content.artile .article,
    .adm .article .article {
        background: #f5f7fa;
        color: #001030;
        min-width: 100%;
    }
    .adm::-webkit-scrollbar-track {
        width: 25px;
        background-color: rgba(255, 255, 255, 0.445);
    }
    .adm::-webkit-scrollbar-track {
        width: 25px;
        background-color: rgba(255, 255, 255, 0.445);
    }
    .adm::-webkit-scrollbar-thumb {
        width: 25px;
        background-color: #051534;
    }
    .adm::-webkit-scrollbar-thumb {
        width: 25px;
        background-color: #051534;
    }
    .adm::-webkit-scrollbar {
        width: 25px;
        height: 7px;
        border: none;
        background-color: rgba(255, 255, 255, 0.1);
        overflow: hidden;
        border-radius: 0 3px 3px 0;
    }
    .adm .info {
        width: 100%;
        border-radius: 4px;
        margin-bottom: 20px;
        padding: 29px 0 29px 15px;
    }
    .adm .tournament__header,
    .adm .tournament__info {
        font-family: Burbank Small, sans-serif;
        font-style: normal;
        color: #001030;
    }
    .adm .remaining-time {
        width: 140px;
        height: 40px;
        background: rgba(168, 169, 255, 0.1);
        border-radius: 2px;
        margin-left: 5px;
        padding: 8px 0 7px;
    }
    .adm #touranmentDetailTab a {
        width: 7em;
        height: 40px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 20px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.05em;
        text-decoration: none;
        margin-right: 10px;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
        padding-bottom: 0px;
        opacity: 1;
        background: transparent;
    }
    .adm nav#touranmentDetailTab {
        bottom: 2px;
    }
    a.registration__join.blue-button.btn-block.blue-button--filled {
        color: white;
    }
    .adm .tournament__info {
        font-size: 13px;
        line-height: 13px;
        margin: 0 0 17px;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .adm .remaining-time__text,
    .adm .remaining-time__time {
        font-style: normal;
        letter-spacing: 0.05em;
        color: #3c3daa;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .adm .game__title {
        color: #001030;
    }
    a.article__tab-link .adm_content .article__tab-link--player .adm_content .article__tab-link--active {
        opacity: 1;
        color: #3c3daa;
        border-bottom: 1px solid #3c3daa;
    }
    .col-sm-auto.col-12.p-0 .article__tabs.tabs {
        margin-bottom: 35px;
    }
    .adm .table__link,
    .adm .table__link {
        text-decoration: none;
        color: #001030;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .article__content {
        padding: 100px 115px 30px;
        height: 100vh;
        overflow: auto;
        scrollbar-width: none;
    }
    .shoppingcart * {
        font-family: sans-serif;
    }
    @media screen and (max-width: 649.98px) {
        .article__tab-menu-button {
            display: block;
        }
        .article__tab-menu-container {
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
            -webkit-align-items: flex-end;
            padding: 0 30px;
        }
        .adm .tab-menu {
            height: 100%;
            overflow: hidden;
        }
    }
    .blue-button,
    .green-button,
    .green-hover-button,
    .grey-button,
    .orange-button,
    .pink-button,
    .purple-button,
    .red-button,
    .white-button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 2px;
        height: 40px;
        outline: none;
        text-decoration: none;
        cursor: pointer;
        font-family: Burbank-Big-Cd-Bd, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
    .registration__join {
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        text-decoration: none;
        text-align: center;
        /* width: 100%; */
        height: 50px;
        font-size: 14px;
        line-height: 14px;
        margin: auto 0;
        vertical-align: middle;
        letter-spacing: 0.05em;
        padding: 0px;
        text-transform: none;
        font-weight: 400;
    }
    /*
aside#modal_Warning-Close .modal-container--opened aside#modal_Warning-Close {
   position: fixed;
    top: 0px;
    align-self: auto;
}*/
    .table__secondary-row-container {
        max-height: 0;
        height: auto;
        overflow: hidden;
        -webkit-transform: translateY(-50%) scaleY(0);
        -ms-transform: translateY(-50%) scaleY(0);
        transform: translateY(-50%) scaleY(0);
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        background: #fff;
        z-index: 1;
        padding: 0;
    }
    .table__secondary-row {
        height: 50px;
    }
    .table__checkbox,
    .table__checkbox-hide,
    .table__radio {
        display: none;
    }
    .table__radio-label {
        width: 14px;
        height: 15px;
        opacity: 0.4;
        border: 1px solid #d5dae0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 10px 8px;
        background-position: 50%;
        background-repeat: no-repeat;
        -webkit-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        cursor: pointer;
        margin: 0 0 3px;
    }
    .table__radio:checked + .row .table__radio-label {
        background-color: #3c3daa;
        border: 1px solid #3c3daa;
        background-image: url(#{$cdn}/img/ok.svg);
        opacity: 1;
    }
    .adm .table__radio-text {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 15px;
        line-height: 15px;
        color: #001030;
        opacity: 0.5;
        margin: 0 0 0 10px;
    }
    .table .article__column-label:last-of-type {
        margin-left: 30px;
    }
    .table__headers-row {
        font-weight: bold;
    }
    .adm .table__row,
    .adm .table__headers-row {
        background: rgba(255, 255, 255, 0.445);
        margin-bottom: 15px;
        padding: 10px 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    #show-hidden:checked ~ .table__checkbox-hide:checked + .row,
    .range {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .range {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        width: 100%;
        margin-top: 10px;
    }
    .adm .range__label {
        width: 53px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 13px;
        text-align: right;
        color: #001030;
    }
    .range__container {
        width: 100%;
        height: 6px;
        position: relative;
        background: #e8eef6;
        border-radius: 3px;
    }
    .range__range {
        height: 6px;
        border-radius: 3px;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        width: 0;
    }
    .range--blue .range__range {
        background: #49c3fd;
    }
    .range--purple .range__range {
        background: #3c3daa;
    }
    .range--green .range__range {
        background: #6cd000;
    }
    .range--pink .range__range {
        background: #cb25b3;
    }
    .range--orange .range__range {
        background: -o-linear-gradient(267.78deg, #ff9f00 -0.03%, #ff7f00 100.45%);
        background: linear-gradient(182.22deg, #ff9f00 -0.03%, #ff7f00 100.45%);
    }
    .adm .article__subheader {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #001030;
        opacity: 0.67;
        margin-bottom: 25px;
        margin-top: 30px;
    }
    .article__label {
        width: 84px;
        height: 30px;
        border-radius: 2px;
        font-family: BurbankSmall-Bold, sans-serif;
        font-style: normal;
    }
    .article__label--big {
        margin-top: 15px;
        width: 180px;
        height: 50px;
        font-size: 20px;
        line-height: 50px;
    }
    .article__label--orange {
        background: rgba(242, 104, 49, 0.1);
        color: #f26831;
    }
    .article__label--green {
        background: rgba(108, 208, 0, 0.1);
        color: #6cd000;
    }
    .article__games-container {
        position: relative;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }
    .game__hr {
        width: calc(100% + 60px);
        margin: 0 -30px 17px;
        height: 1px;
        background: #e8eef6;
    }
    .adm .tournament__header {
        font-size: 12px;
        line-height: 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        opacity: 0.8;
        margin: 0 0 7px;
    }
    .adm .game {
        border: 1px solid #e8eef6;
    }
    .adm .game__signed img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin-left: -15px;
        border: 1px solid #fff;
    }
    .game__signed img:first-of-type {
        margin-left: 0;
    }
    .game__signed img:last-of-type {
        margin-right: 5px;
    }
    .adm .game__signed span {
        font-family: Burbank-Big-Cd-Bd, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 13px;
        letter-spacing: 0.05em;
        color: #001030;
        margin-left: 1px;
    }
    .game__button {
        width: 100%;
        height: 40px;
    }
    .table__secondary-row {
        padding: 5px 0;
    }
    .table__column:first-of-type {
        padding-left: 0;
    }
    .table__column:last-of-type {
        padding-right: 0;
    }
    .info {
        padding: 30px 0 30px 35px;
        border-radius: 4px;
    }
    .info--per-cent {
        background: #3c3daa;
    }
    .info--per-cent .info__icon {
        background-image: url(#{$cdn}/img/per_cent.svg);
        width: 40px;
        height: 40px;
    }
    .info--poll {
        background: #fd6969;
    }
    .info--poll .info__icon {
        background-image: url(#{$cdn}/img/poll.svg);
        width: 42px;
        height: 40px;
    }
    .info--users {
        background: #682fbf;
    }
    .info--users .info__icon {
        background-image: url(#{$cdn}/img/users.svg);
        width: 45px;
        height: 36px;
    }
    .info--topics {
        background: #49c3fd;
    }
    .info--topics .info__icon {
        background-image: url(#{$cdn}/img/topics.svg);
        width: 40px;
        height: 40px;
    }
    .info__icon {
        background-size: 100% 100%;
        margin-right: 34px;
        background-color: rgba(0, 0, 0, 0);
    }
    .info__title {
        font-family: BurbankSmall-Bold, sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 12px;
    }
    .info__text,
    .info__title {
        font-style: normal;
        color: #fff;
    }
    .info__text {
        font-family: BurbankSmall-Medium, sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
    }
    .article__label-container--vertical {
        margin-bottom: 25px;
        margin-left: 50px;
    }
    .adm .article__color-director-label {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 17px;
        color: #001030;
        margin-right: 20px;
    }
    .article__color-director-label--big {
        font-size: 14px;
    }
    .adm .article__color-director-value {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: #001030;
        opacity: 0.5;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .article__label-container--vertical:hover .article__color-director-value {
        opacity: 1;
    }
    .adm .article__tab-link {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        color: #001030;
        text-decoration: none;
        opacity: 0.5;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        padding-bottom: 4px;
        position: relative;
        bottom: 6px;
    }
    .article__tab-link--active {
        opacity: 1;
        color: #3c3daa;
        border-bottom: 1px solid #3c3daa;
    }
    .table__column--topic {
        width: 100%;
    }
    .table__column--topic-likes,
    .table__column--topic-reposts,
    .table__column--topic-views {
        width: 90px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__column--topic-likes:before,
    .table__column--topic-reposts:before,
    .table__column--topic-views:before {
        opacity: 0.5;
        content: "";
        display: inline-block;
        margin-right: 7px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .table__column--topic-views {
        width: 75px;
    }
    .adm .table__column--topic-likes:before {
        width: 16px;
        height: 14px;
        background-image: url(#{$cdn}/img/blue_like.svg);
    }
    .table__column--topic-reposts:before {
        width: 17px;
        height: 16px;
        background-image: url(#{$cdn}/img/blue_repost.svg);
    }
    .table__column--topic-views:before {
        width: 16px;
        height: 12px;
        background-image: url(#{$cdn}/img/blue_view.svg);
    }
    .article__headers-row .article__column {
        line-height: 16px;
    }
    .article__add-penalty {
        color: #3c3daa;
    }
    .adm .table__row {
        border-top: 1px solid #ffffff;
    }
    #make-winner .window {
        width: 640px;
    }
    .article__change-fee,
    .article__edit-code {
        width: 115px;
        height: 40px;
    }
    .adm .article__hr {
        background: #e8eef6;
        width: 100%;
        height: 1px;
        margin: 35px 0;
    }
    .adm .article__code-text,
    .adm .article__code-title {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.05em;
        color: #001030;
        margin-bottom: 20px;
    }
    .article__code-title {
        opacity: 0.5;
    }
    .col-12.col-sm-12.col-md-12.col-lg-12.col-xl-3.my-ticket-table__column--button.justify-content-end.d-flex a {
        margin: 2px;
        font-size: small;
    }
    .windowSubheader {
        font-family: Burbank-Big-Cd-Bd, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 150%;
        line-height: 150%;
    }
    .table__column--id {
        width: 120px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__column--id:before {
        content: "Dispute ID";
    }
    .table__column--player-number {
        width: 120px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__column--event-id {
        width: 100%;
    }
    .table__column--event-id:before {
        content: "Event ID";
    }
    .table__column--event {
        width: 250%;
    }
    .table__column--event:before {
        content: "Event";
    }
    .table__column--round {
        width: 80%;
    }
    .table__column--round:before {
        content: "Round";
    }
    .table__column--resolved {
        width: 150px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__column--resolved:before {
        content: "Resolved";
    }
    .table__column--player {
        width: 180%;
    }
    .table__column--explanation {
        width: 300%;
    }
    .table__column--button {
        width: 150px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__column-checkbox {
        display: none;
    }
    .table__column--player-number {
        opacity: 0.5;
    }
    .table__column--explanation .table__link:hover {
        text-decoration: underline;
    }
    #closed:checked ~ .row .table__radio-label--closed,
    #open:checked ~ .row .table__radio-label--open {
        background-color: #3c3daa;
        background-image: url(#{$cdn}/img/ok.svg);
        opacity: 1;
        border: none;
    }
    .table__checkbox:checked ~ .table__main-row .article__open-dispute {
        background-color: #f1f5fa;
        border: 1px solid #f1f5fa;
    }
    .table__checkbox:checked ~ .table__main-row .article__open-dispute:before {
        -webkit-transform: rotate(1turn);
        -ms-transform: rotate(1turn);
        transform: rotate(1turn);
    }
    .table__checkbox:checked ~ .table__main-row .article__view-listing {
        background: -webkit-gradient(linear, left top, right top, from(#cb25b3), to(#7d2eae));
        background: -o-linear-gradient(left, #cb25b3 0, #7d2eae 100%);
        background: linear-gradient(90deg, #cb25b3, #7d2eae);
        color: #fff;
    }
    .table__checkbox:checked ~ .table__main-row .article__view-listing:hover {
        background: -webkit-gradient(linear, left top, right top, from(#a91493), to(#640d9a));
        background: -o-linear-gradient(left, #a91493 0, #640d9a 100%);
        background: linear-gradient(90deg, #a91493, #640d9a);
    }
    .table__checkbox:checked ~ .table__main-row .article__dispute-chat {
        background: #3c3daa;
        color: #fff;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .table__checkbox:checked ~ .table__main-row .article__dispute-chat:hover {
        background: #373895;
    }
    @media screen and (max-width: 767.98px) {
        .table__checkbox:checked ~ .table__secondary-row-container {
            max-height: 1800px;
        }
        .col-info {
            width: 100%;
            max-width: 100%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
        }
    }
    @media screen and (max-width: 1499.98px) and (min-width: 768px) {
        .table__column {
            padding: 3em;
        }
    }
    @media screen and (max-width: 767.98px) and (min-width: 576px) {
        .table__column {
            width: 100%;
            padding: 3px 0;
        }
    }
    @media screen and (max-width: 575.98px) and (min-width: 0) {
        .table__column {
            width: 100%;
            padding: 3px 0;
        }
        .table__secondary-row .table__column {
            font-size: 14px;
        }
        .table__column--topic {
            margin-bottom: 5px;
        }
        .table__column--topic-likes,
        .table__column--topic-reposts,
        .table__column--topic-views {
            width: 100%;
        }
        .article__dispute-chat,
        .article__make-winner,
        .article__open-dispute,
        .article__view-listing,
        .table__open {
            margin-top: 8px;
        }
    }
    @media screen and (max-width: 857.98px) {
        .article__games-container {
            -ms-flex-pack: distribute;
            justify-content: space-around;
            -webkit-justify-content: space-around;
        }
    }
    /*
 @media screen and (max-width:1589.98px) {
     .game__condition-container {
         left: auto;
         right: 0;
         -webkit-transform: translate(0);
         -ms-transform: translate(0);
         transform: translate(0)
     }
     .game__condition-container:after {
         left: auto;
         right: 25px
     }
 }*/
    @media screen and (max-width: 1878.98px) and (min-width: 991.99px) {
        .game {
            margin-left: 16px;
            margin-right: 16px;
            width: calc(33.33333% - 32px);
        }
    }
    @media screen and (max-width: 3000.98px) and (min-width: 1879px) {
        .game {
            margin-left: 16px;
            margin-right: 16px;
            width: calc(25% - 32px);
        }
    }
    /*
 @media screen and (max-width:1479.98px) and (min-width:1410px) {
     .game__prize, .game__time {
         margin-left: 15%
     }
 }

 @media screen and (max-width:1409.98px) and (min-width:1270px) {
     .game__prize, .game__time {
         margin-left: 15%
     }
 }

 @media screen and (max-width:1269.98px) and (min-width:1080px) {
     .game__prize, .game__time {
         margin-left: 0
     }
 }

 @media screen and (max-width:1079.98px) and (min-width:992px) {

     .game__prize, .game__time {
         margin-left: 26%
     }
 } */
    @media screen and (max-width: 991.98px) and (min-width: 870px) {
        .game {
            width: calc(50% - 32px);
        }
    }
    @media screen and (max-width: 869.98px) and (min-width: 768px) {
        .game {
            width: calc(100% - 32px);
        }
        .game__prize,
        .game__time {
            margin-left: 26%;
        }
    }
    @media screen and (max-width: 767.98px) and (min-width: 0px) {
        .game {
            width: calc(100% - 32px);
        }
        .game__prize,
        .game__time {
            margin-left: 20%;
        }
        .m-0--30.col-12 {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    @media screen and (max-width: 599.98px) and (min-width: 576px) {
        .game__prize,
        .game__time {
            margin-left: 10%;
        }
    }
    @media screen and (max-width: 1599.98px) {
        .col-info {
            width: 50%;
            max-width: 50%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
        }
        .col-management-bonus,
        .col-marketplace,
        .col-stats,
        .col-topics {
            width: 100%;
            max-width: 100%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
        }
    }
    @media screen and (min-width: 576px) {
        .table__main-row--topics {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }
    }
    @media screen and (max-width: 399.98px) {
        .table__button {
            width: 109px;
            margin-right: 2px;
        }
        .table__button:last-of-type {
            margin-right: 0;
        }
    }
    .dashboard-tournaments section.game {
        width: calc(100%);
    }
    .table__headers-row {
        height: 66px;
    }
    ._table__headers-row {
        height: 66px;
    }
    @media screen and (max-width: 1299.98px) {
        ._table__headers-row {
            display: none;
        }
    }
    .table__column--username {
        width: 200%;
    }
    .table__column--username:before {
        content: "Name";
    }
    .table__column--email {
        width: 180%;
    }
    .table__column--email:before {
        content: "Email";
    }
    .table__column--location {
        width: 100%;
    }
    .table__column--location:before {
        content: "Location";
    }
    .table__column--age-limit {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 120px;
    }
    .table__column--age-limit:before {
        content: "Age Limit";
    }
    .adm .article__tab-link:hover {
        text-decoration: none;
        color: #001030;
    }
    .table__column--exp {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 130px;
    }
    .table__column--exp:before {
        content: "Exp. Points";
    }
    .table__column--date {
        width: 150%;
        min-width: 165px;
    }
    .table__column--date:before {
        content: "Date";
    }
    .table__column--status {
        width: 104px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__column--status:before {
        content: "Status";
    }
    .table__column--buttons {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 340px;
    }
    .article__headers-row .article__column {
        line-height: 16px;
    }
    .table__column-profile-picture {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        margin-right: 16px;
    }
    @media screen and (max-width: 379.98px) {
        .table__column--email {
            line-height: 23px;
            margin-bottom: 5px;
            margin-top: 5px;
        }
        .table__button {
            width: 115px;
        }
    }
    .table__radio-label {
        width: 14px;
        height: 15px;
        opacity: 0.4;
        border: 1px solid #d5dae0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 10px 8px;
        background-position: 50%;
        background-repeat: no-repeat;
        -webkit-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        cursor: pointer;
        margin: 0 0 3px;
    }
    .table__radio:checked + .row .table__radio-label {
        background-color: #3c3daa;
        border: 1px solid #3c3daa;
        background-image: url(#{$cdn}/img/ok.svg);
        opacity: 1;
    }
    .adm .table__radio-text {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 15px;
        line-height: 15px;
        color: #001030;
        opacity: 0.5;
        margin: 0 0 0 10px;
    }
    .table .article__column-label:last-of-type {
        margin-left: 30px;
    }
    .table__column--id {
        width: 120px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__column--id:before {
        content: "Dispute ID";
    }
    .table__column--player-number {
        width: 120px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__column--event-id {
        width: 100%;
    }
    .table__column--event-id:before {
        content: "Event ID";
    }
    .table__column--event {
        width: 250%;
    }
    .table__column--event:before {
        content: "Event";
    }
    .table__column--round {
        width: 80%;
    }
    .table__column--round:before {
        content: "Round";
    }
    .table__column--resolved {
        width: 150px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__column--resolved:before {
        content: "Resolved";
    }
    .table__column--buttons {
        width: 394px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__column--player {
        width: 180%;
    }
    .table__column--explanation {
        width: 300%;
    }
    .table__column--button {
        width: 150px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__column-checkbox {
        display: none;
    }
    .table__column--player-number {
        opacity: 0.5;
    }
    .table__profile-picture {
        width: 1.5em;
        height: auto;
        margin-right: 17px;
        margin-left: 17px;
        border-radius: 50%;
    }
    .table__column--explanation .table__link:hover {
        text-decoration: underline;
    }
    #closed:checked ~ .row .table__radio-label--closed,
    #open:checked ~ .row .table__radio-label--open {
        background-color: #3c3daa;
        background-image: url(#{$cdn}/img/ok.svg);
        opacity: 1;
        border: none;
    }
    .table__checkbox:checked ~ .table__main-row .article__open-dispute:before {
        -webkit-transform: rotate(1turn);
        -ms-transform: rotate(1turn);
        transform: rotate(1turn);
    }
    .table__checkbox:checked ~ .table__main-row .article__view-listing {
        background: -webkit-gradient(linear, left top, right top, from(#cb25b3), to(#7d2eae));
        background: -o-linear-gradient(left, #cb25b3 0, #7d2eae 100%);
        background: linear-gradient(90deg, #cb25b3, #7d2eae);
        color: #fff;
    }
    .table__checkbox:checked ~ .table__main-row .article__view-listing:hover {
        background: -webkit-gradient(linear, left top, right top, from(#a91493), to(#640d9a));
        background: -o-linear-gradient(left, #a91493 0, #640d9a 100%);
        background: linear-gradient(90deg, #a91493, #640d9a);
    }
    .table__checkbox:checked ~ .table__main-row .article__dispute-chat {
        background: #3c3daa;
        color: #fff;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .table__checkbox:checked ~ .table__main-row .article__dispute-chat:hover {
        background: #373895;
    }
    @media screen and (max-width: 1499.98px) {
        .table__checkbox:checked ~ .table__secondary-row-container {
            max-height: 1600px;
        }
        .table__secondary-row {
            display: inline-table;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            height: auto;
        }
        .table__row .table__column:before {
            display: inline-block;
        }
        .table__secondary-row .table__column {
            width: 100%;
        }
        .table__secondary-row {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    @media screen and (max-width: 767.98px) {
        .table__checkbox:checked ~ .table__secondary-row-container {
            max-height: 1800px;
        }
    }
    @media screen and (max-width: 1549.98px) and (min-width: 1499.98px) {
        .table__column {
            padding: 0 10px;
        }
        .table__row {
            margin-bottom: 5px;
        }
    }
    .login-actions {
        margin-top: 15px;
    }
    .article__pages-container {
        margin: 30px 0;
    }
    .adm .article__prev-page {
        color: #001030;
        opacity: 0.3;
        margin-right: 40px;
    }
    .article__next-page,
    .article__prev-page {
        font-family: BurbankSmall-Bold, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
    }
    .article__next-page {
        color: #49c3fd;
        margin-left: 40px;
        text-decoration: none;
    }
    .adm .article__prev-page:hover {
        text-decoration: none;
        color: #001030;
    }
    .article .article__next-page:hover {
        text-decoration: none;
        color: #49c3fd;
    }
    .adm .article__page,
    .adm .article__page-dots {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 14px;
        color: #001030;
        text-decoration: none;
    }
    .adm .article__page:hover {
        text-decoration: none;
        color: #001030;
    }
    .article__page {
        margin: 0 10px;
    }
    .article__page--active {
        width: 28px;
        height: 32px;
        background: #49c3fd;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
    }
    .article__page--active:hover {
        color: #fff;
    }
    .article__tab-menu-before-container {
        margin: 50px 0 20px;
        border-bottom: 1px solid #e8ebef;
    }
    .article__tab-menu-container {
        position: relative;
        margin: 0;
        width: 100%;
    }
    .adm .tab-menu {
        position: relative;
        margin: 0;
        -ms-overflow-style: none;
    }
    .adm .tab-menu::-webkit-scrollbar {
        display: none;
    }
    .adm .tab-menu__radio-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .adm .tab-menu__radio {
        display: none;
    }
    .article__tab-menu-button--left {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        left: 0;
    }
    .adm .tab-menu__radio:checked + .tab-menu__radio-label,
    .adm .tab-menu__radio:checked + .tab-menu__radio-label {
        background-color: #3c3daa;
        border: 1px solid #3c3daa;
        background-image: url(#{$cdn}/img/ok.svg);
        opacity: 1;
    }
    .adm .tab-menu__radio-container:first-of-type .adm .tab-menu__radio-text,
    .adm .tab-menu__radio-container:nth-of-type(2) .adm .tab-menu__radio-text {
        margin: 0 20px 0 7px;
    }
    .adm .search__input {
        height: 40px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
        border: none;
        outline: none;
        padding: 0 34px 0 39px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.05em;
        color: #001030;
        position: relative;
        z-index: 1;
    }
    .adm .search {
        position: relative;
        background: #fff;
        border-radius: 4px;
    }
    .search:after {
        content: "";
        width: 14px;
        height: 14px;
        left: 15px;
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/grey_search.svg);
        opacity: 0.5;
    }
    .search:after,
    .search__clear {
        z-index: 0;
        position: absolute;
        top: 13px;
        background-color: rgba(0, 0, 0, 0);
    }
    .search__clear {
        right: 15px;
        width: 15px;
        height: 15px;
        background-size: 10px 9px;
        background-position: 50%;
        background-repeat: no-repeat;
        display: block;
        text-decoration: none;
        background-image: url(#{$cdn}/img/grey_cross.svg);
        cursor: pointer;
        opacity: 0;
        -webkit-transition: 0.1s;
        -o-transition: 0.1s;
        transition: 0.1s;
        font-weight: bold;
    }
    .article__search {
        width: 235px;
    }
    .table__column--name:before {
        content: "Organization";
    }
    .table__column--email:before {
        content: "Email";
    }
    .table__column--holder:before {
        content: "Account holder";
    }
    .table__column--role:before {
        content: "Role";
    }
    .table__column--age:before {
        content: "Age";
    }
    .table__column--location:before {
        content: "Location";
    }
    .table__column--phone:before {
        content: "Phone number";
    }
    .table__column--status:before {
        content: "Status";
    }
    .article__headers-row .article__column {
        line-height: 16px;
    }
    .table__column-profile-picture {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        margin-right: 16px;
    }
    .article__pages-container {
        margin-top: 25px;
        margin-bottom: 40px;
    }
    #disable .window {
        width: 460px;
        padding: 40px 35px;
        min-height: 0;
    }
    .adm .window__subheader--big {
        font-size: 17px;
        line-height: 27px;
        opacity: 1;
        color: #001030;
        margin-top: -10px;
    }
    .article__prev-page {
        margin-right: 15px;
    }
    .article__next-page {
        margin-left: 15px;
    }
    .article__page {
        margin: 0 5px;
    }
    @media screen and (max-width: 389.98px) {
        .article__next-page,
        .article__prev-page {
            display: none;
        }
        .article__page {
            margin: 0 8px;
        }
    }
    @media screen and (max-width: 991.98px) {
        .adm .tab-menu {
            height: 100%;
        }
        .adm .tab-menu__link {
            padding: 0 0 15px;
        }
        .article__radio-container,
        .adm .tab-menu__events-container {
            -webkit-box-ordinal-group: 0;
            -ms-flex-order: -1;
            order: -1;
        }
        .article__radio-container {
            margin-bottom: 15px;
        }
    }
    @media screen and (max-width: 449.98px) {
        .article__tab-menu-container {
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
            -webkit-align-items: flex-end;
            padding: 0 30px;
        }
        .adm .tab-menu {
            height: 100%;
            overflow: hidden;
        }
        .article__tab-menu-container {
            padding: 0;
        }
    }
    .table__column--username:before {
        content: "Name";
    }
    .article__pages-container {
        margin: 30px 0;
    }
    .adm .article__prev-page {
        color: #001030;
        opacity: 0.3;
        margin-right: 40px;
    }
    .article__next-page,
    .article__prev-page {
        font-family: BurbankSmall-Bold, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
    }
    .article__next-page {
        color: #49c3fd;
        margin-left: 40px;
        text-decoration: none;
    }
    .adm .article__prev-page:hover {
        text-decoration: none;
        color: #001030;
    }
    .article__next-page:hover {
        text-decoration: none;
        color: #49c3fd;
    }
    .adm .article__page,
    .adm.article__page-dots {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 14px;
        color: #001030;
        text-decoration: none;
    }
    .adm .article__page:hover {
        text-decoration: none;
        color: #001030;
    }
    .article__page {
        margin: 0 10px;
    }
    .adm .article__page--active {
        width: 28px;
        height: 32px;
        background: #49c3fd;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
    }
    /* .article .article__page--active:hover {
    color: #fff
} */
    .adm .article__tab-menu-before-container {
        margin: 50px 0 20px;
        border-bottom: 1px solid transparent;
    }
    .article__tab-menu-container {
        position: relative;
        margin: 0;
        width: 100%;
    }
    .adm .tab-menu__radio-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .adm .tab-menu__radio:checked + .adm .tab-menu__radio-label {
        background-color: #3c3daa;
        border: 1px solid #3c3daa;
        background-image: url(#{$cdn}/img/ok.svg);
        opacity: 1;
    }
    .adm .tab-menu__radio-text,
    .adm .tab-menu__radio-text {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 15px;
        line-height: 15px;
        color: #001030;
        opacity: 0.5;
        margin: 0 0 0 7px;
    }
    .adm .tab-menu__radio-container:first-of-type .adm .tab-menu__radio-text,
    .adm .tab-menu__radio-container:nth-of-type(2) .adm .tab-menu__radio-text {
        margin: 0 20px 0 7px;
    }
    .table__label--purple {
        background: rgba(60, 61, 170, 0.1);
        color: #3c3daa;
    }
    .table__button {
        width: 140px;
        height: 40px;
        margin-left: 10px;
    }
    .table__button:first-of-type {
        margin-left: 0;
    }
    .article__search {
        width: 235px;
    }
    .table__column--email:before {
        content: "Email";
    }
    .table__column--location:before {
        content: "Location";
    }
    .table__column--age-limit:before {
        content: "Age Limit";
    }
    .table__column--exp:before {
        content: "Exp. Points";
    }
    .table__column--date:before {
        content: "Date";
    }
    .table__column--status:before {
        content: "Status";
    }
    .article__headers-row .article__column {
        line-height: 16px;
    }
    .table__column-profile-picture {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        margin-right: 16px;
    }
    .article__pages-container {
        margin-top: 25px;
        margin-bottom: 40px;
    }
    .adm .window__subheader--big {
        font-size: 17px;
        line-height: 27px;
        opacity: 1;
        color: #001030;
        margin-top: -10px;
    }
    .article__add-penalty {
        color: #3c3daa;
    }
    .article__prev-page {
        margin-right: 15px;
    }
    .article__next-page {
        margin-left: 15px;
    }
    .article__page {
        margin: 0 5px;
    }
    @media screen and (max-width: 389.98px) {
        .article__next-page,
        .article__prev-page {
            display: none;
        }
        .article__page {
            margin: 0 8px;
        }
    }
    @media screen and (max-width: 991.98px) {
        .adm .tab-menu {
            height: 100%;
            overflow: hidden;
        }
        .adm .tab-menu__link {
            padding: 0 0 15px;
        }
        .article__radio-container,
        .adm .tab-menu__events-container {
            -webkit-box-ordinal-group: 0;
            -ms-flex-order: -1;
            order: -1;
        }
        .article__radio-container {
            margin-bottom: 15px;
        }
    }
    @media screen and (max-width: 449.98px) {
        .article__tab-menu-container {
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
            -webkit-align-items: flex-end;
            padding: 0 30px;
        }
        .article__tab-menu-container {
            padding: 0;
        }
    }
    /* .................... */
    .table__column--username:before {
        content: "Name";
    }
    @media screen and (max-width: 1100.98px) {
        .table__row {
            padding: 15px 20px;
        }
        .table__row .table__column:before {
            display: inline-block;
        }
    }
    @media screen and (max-width: 379.98px) {
        .table__button {
            width: 115px;
        }
    }
    ._table__column {
        color: #001030;
        font-size: 16px;
        line-height: 23px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        letter-spacing: 0.05em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 10px;
    }
    @media screen and (max-width: 1879.98px) {
        .table__column--buttons {
            width: 384px;
        }
    }
    @media screen and (max-width: 1299.98px) and (min-width: 0) {
        .table__column--buttons,
        .table__column--event-name {
            width: 100%;
        }
        .table__secondary-row-container {
            padding-right: 20px;
            padding-left: 20px;
        }
    }
    .no-padding {
        padding: 0px;
    }
    .padding-10 {
        padding: 10px;
    }
    .locationsetting {
        padding: 0 30px;
        margin-bottom: 10px;
    }
    label {
        cursor: default;
    }
    section {
        display: block;
    }
    .organizer {
        height: 100%;
        background: hsla(0, 0%, 100%, 0.1);
        border-radius: 2px;
        margin-bottom: 30px;
    }
    .organizer__logo {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    /* ......................................... */
    /* ............................faq css start ...................... */
    .faq .go-link-li {
        margin-bottom: 20px;
        background: #fff;
        padding: 25px 30px;
    }
    .go-link-li a {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #001030;
    }
    .faq-header {
        background: linear-gradient(163.27deg, #012065 0%, #5623a7 100%);
        height: unset;
        background-image: url(#{$cdn}/img/faq-header.png);
        background-size: cover;
        overflow: visible;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
    @media screen and (max-width: 991.98px) {
        .faq .faq-header {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 360px;
            padding-left: 40px;
            padding-right: 40px;
            border-bottom-right-radius: 0;
        }
        .faq .faq-header__logo {
            display: block;
        }
        .m-0--30 {
            margin: 0;
        }
        .breadcrumbs {
            margin-left: 15px;
        }
    }
    .pr-20 {
        padding-right: 20px;
    }
    .article__faq-header {
        font-family: BurbankSmall-Light;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
        margin: 40px 0 10px;
    }
    .faq-header__logo {
        width: 140px;
        height: 30px;
        background-size: 100% 100%;
        background-color: rgba(0, 0, 0, 0);
        background-image: url(#{$cdn}/img/logo.png);
    }
    /*
#article-container {
  margin-top: 240px;
  padding-top: 70px;
} */
    @media screen and (max-width: 1199.98px) {
        #article-container {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    @media screen and (max-width: 575.98px) {
        #article-container {
            padding-left: 15px;
            padding-right: 15px;
        }
        .withmobilecenter {
            float: none;
        }
    }
    .withmobilecenter {
        float: left;
    }
    .see-more {
        font-family: Burbank-Big-Cd-Md;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #ffffff;
        display: flex;
    }
    .article__header1 {
        font-family: BurbankSmall-Light;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
        margin: 40px 0 10px;
    }
    .__input {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.05em;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 2px;
        height: 59px;
        padding: 5px 10px 0 55px;
        margin: 15px 0 40px;
    }
    @media screen and (max-width: 991.98px) {
        .faq .article__header1 {
            margin: 20px 0 10px;
            text-align: center;
            font-size: 1.1em;
        }
        .faq .faq-header__navigation {
            margin-top: 20px;
        }
    }
    .faq .container {
        max-width: 1030px;
        padding: 0;
    }
    .faq .quick-menu li div {
        background: #ffffff;
        border-radius: 2px;
        text-align: center;
        padding: 35px 10px;
    }
    .faq .quick-menu li {
        padding: 10px;
        width: 33.3%;
        float: left;
    }
    @media screen and (max-width: 991.98px) {
        .faq .quick-menu li {
            width: 50%;
        }
    }
    @media screen and (max-width: 620px) {
        .faq .quick-menu li {
            width: 100%;
        }
    }
    .faq .quick-menu ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }
    .faq .quick-menu li a {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #001030;
        display: block;
        margin-top: 20px;
    }
    .faq .quick-menu li a:hover {
        text-decoration: none;
    }
    .faq .__input {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.05em;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 2px;
        height: 59px;
        padding: 5px 10px 0 55px;
        margin: 15px 0 40px;
    }
    .faq .__input::-webkit-input-placeholder {
        /* Chrome */
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #ffffff;
        opacity: 0.5;
    }
    .faq .__input:-ms-input-placeholder {
        /* IE 10+ */
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #ffffff;
        opacity: 0.5;
    }
    .faq .__input::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff;
        opacity: 1;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #ffffff;
        opacity: 0.5;
    }
    .faq .__input:-moz-placeholder {
        /* Firefox 4 - 18 */
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #ffffff;
        opacity: 0.5;
    }
    .pull-left {
        float: left;
    }
    /* .faq .__input-container--search:before {
  top: 38px;
  left: 20px;
  width: 15px;
  height: 15px;
} */
    .faq .__input-container--search:before {
        position: absolute;
        width: 13px;
        height: 13px;
        top: 28px;
        left: 11px;
        background-color: rgba(0, 0, 0, 0);
        content: "";
        display: block;
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/search.svg);
        opacity: 0.5;
    }
    .faq .footer {
        margin: 0;
        background: #fff;
        height: 100%;
        margin-top: 100px;
        padding: 1px 20px 0px 30px;
    }
    .faq article {
        background: transparent;
    }
    span.answer.active {
        display: inline !important;
    }
    @media screen and (min-width: 992px) {
        .faq .article {
            min-height: calc(100vh - 339px);
        }
    }
    .go-link {
        margin-bottom: 100px;
    }
    .go-link li a {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #001030;
    }
    .go-link li a:hover {
        text-decoration: none;
    }
    .article.go-link li {
        margin-bottom: 20px;
        background: #fff;
        padding: 25px 30px;
    }
    .go-link ul {
        list-style: none;
        padding-left: 0;
    }
    .adm .breadcrumbs {
        font-family: Burbank-Big-Rg-Md;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.05em;
        color: #001030;
        padding-top: 30px;
        margin-bottom: 25px;
        margin-left: -15px;
    }
    .adm .breadcrumbs a {
        color: #001030;
    }
    .breadcrumbs span:last-child {
        font-weight: 700;
    }
    @media screen and (max-width: 991.98px) {
        .breadcrumbs {
            margin-left: 15px;
        }
    }
    .walkthrough-page .faq-content {
        width: 100%;
        display: block;
        float: left;
        outline: none;
        margin-bottom: 20px;
    }
    .transbg {
        background: transparent;
    }
    .center {
        text-align: center;
    }
    .walkthrough-page.whitebg {
        background: #fff;
        padding: 60px 70px;
        margin-bottom: 20px;
        clear: both;
    }
    @media screen and (max-width: 575.98px) {
        .walkthrough-page.whitebg {
            padding: 20px;
        }
    }
    .p-description {
        margin-bottom: 20px;
        clear: both;
    }
    .p-description span.num {
        float: none;
        display: inline-block;
        color: #ff9201;
    }
    .p-description div.space {
        height: 20px;
    }
    .faq .page-head {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;
        color: #3c3daa;
        margin-bottom: 30px;
    }
    .adm .page-head-top {
        font-family: BurbankSmall-Medium;
        margin-bottom: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;
        /* or 92% */
        /* text */
        color: #001030;
    }
    .adm .p-description.bold {
        font-family: BurbankSmall-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 32px;
        /* or 178% */
        /* text */
        color: #001030;
    }
    .whitebg .p-description.different {
        font-size: 18px;
        line-height: 32px;
    }
    .walkthrough-page.contact {
        margin-top: 15px;
    }
    .adm .contact .adm .p-description {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: #001030;
        opacity: 0.7;
        margin-bottom: 40px;
        display: block;
    }
    .walkthrough-page.contact .contact-content {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #e8ebef;
    }
    .walkthrough-page.contact .contact-content .item {
        width: 100%;
        display: block;
        float: left;
        padding: 30px;
        background: #fff;
        border-radius: 3px;
    }
    .walkthrough-page.contact .contact-content .item:last-child {
        padding-top: 23px;
        padding-bottom: 25px;
    }
    .walkthrough-page .full-width {
        margin-bottom: 40px;
        margin-top: 20px;
        width: 100%;
    }
    .walkthrough-page.contact .contact-content .item:not(:last-child) {
        border-bottom: 1px solid rgba(232, 235, 239, 0.5);
    }
    .walkthrough-page.contact .contact-content .col-xl-6 {
        padding: 10px;
    }
    .adm .walkthrough-page.contact .contact-content .item span.head {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        display: block;
        color: #001030;
        margin-bottom: 30px;
    }
    .adm .walkthrough-page.contact .contact-content .item span.title {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        display: block;
        color: #001030;
        opacity: 0.6;
    }
    .adm .walkthrough-page.contact .contact-content span.mail a {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #3c3daa;
        margin-left: 10px;
    }
    .walkthrough-page .button-content a {
        height: 40px;
        line-height: 40px;
        display: inline-block;
        background: #6cd000;
        border-radius: 2px;
        color: #fff;
        font-size: 15px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        width: 140px;
        font-family: Burbank-Big-Cd-Bd;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        /* or 100% */
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        /* White */
        color: #ffffff;
    }
    .how-toplay .p-description {
        margin-bottom: 25px;
    }
    .h-100 {
        height: 100%;
    }
    .m-t-38 {
        margin-top: 38px;
    }
    .p-0 {
        padding: 0;
    }
    /* #root {
  background-color: #fffffff2;
} */
    .faq-row-items {
        background: white;
        text-align: center;
        height: 164px;
        padding: 40px;
        margin: 10px;
    }
    .padding-10 {
        padding: 10px;
    }
    .padding-5 {
        padding: 5px;
    }
    .margin-5 {
        margin: 5px;
    }
    .margin-10 {
        margin: 10px;
    }
    .flex {
        display: inline-flex;
    }
    .faq .footer__icon {
        border: 1px solid #acbad0;
    }
    .faq .footer__icon--facebook:hover {
        background-color: #2c6ac1;
    }
    .faq a:hover {
        color: #0056b3;
        text-decoration: underline;
    }
    .faq .footer__icon {
        width: 35px;
        height: 35px;
        border: 1px solid hsla(0, 0%, 100%, 0.2);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0);
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-size: 15px 15px;
        margin-right: 7px;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    @media screen and (max-width: 991.98px) {
        .faq .faq-header__navigation {
            margin-top: 20px;
        }
        .mLR-25 {
            margin-left: 25px;
            margin-right: 25px;
        }
        .faq-header {
            /* position: fixed ; */
            /* left: -100% ; */
            top: 0px;
            width: 100%;
            /* height: calc(100vh - 60px) ; */
            border-bottom-right-radius: 0px;
            -webkit-transition: 0.4s;
            -o-transition: 0.4s;
            transition: 0.4s;
            overflow: auto;
            padding-left: 0;
            padding-right: 0;
            overflow-x: hidden;
        }
        /* #article-container {
    margin-top: 300px;
    padding-top: 70px;
  } */
        ._input {
            margin-right: 18px;
        }
    }
    @media screen and (max-width: 991.98px) {
        .faq .article__header1 {
            margin: 20px 0 10px;
            text-align: center;
            font-size: 1.1em;
        }
    }
    .facebook {
        padding: 5px 0px 0px 12px;
        color: gray;
    }
    .twitter,
    .instagram,
    .twitch,
    .discord {
        padding: 5px 0px 0px 10px;
        color: gray;
    }
    .faq .scrolltop .icon {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        text-align: center;
        text-transform: capitalize;
        color: #3c3daa;
        padding: 0px 15px 0 0;
        padding-right: 15px;
    }
    .scrolltop {
        background-color: #fff;
        border-radius: 45px;
        border: 1px solid #3c3daa;
        width: 8rem;
        padding: 10px !important;
        margin-top: 16px;
        margin-right: 50px;

    }
    .faqRow {
        margin-bottom: 20px;
        background: #fff;
        padding: 25px 30px;
        color: #3c3daa;
        border-bottom: 1px solid rgba(232, 235, 239, 0.5);
    }
    .adm .breadcrumbs {
        font-family: Burbank-Big-Rg-Md;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.05em;
        color: #001030;
        padding-top: 30px;
        margin-bottom: 25px;
        margin-left: 0px;
    }
    .quick-menu {
        margin-top: 50px;
    }
    .no-padding {
        padding: 0px;
    }
    .no-margin {
        margin: 0px;
    }
    .adm .page-head-top {
        font-family: BurbankSmall-Medium;
        margin-bottom: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;
        color: #001030;
    }
    .spacedH {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .no-tb-padding {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .ml-20 {
        margin-left: -20px;
    }
    .faqRow ol li {
        font-family: BurbankSmall-Medium;
        margin-top: 5px;
        margin-bottom: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #001030;
    }
    .faqRow ul li {
        font-family: BurbankSmall-Medium;
        margin-top: 5px;
        margin-bottom: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #001030;
    }
    .collapse {
        border-top: 1px solid gainsboro;
    }
    .faqPage a {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #001030;
        display: block;
        margin-top: 20px;
    }
    .article .card-header {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        display: block;
        color: #001030;
        background: white;
        padding-top: 25px;
        padding-bottom: 15px;
    }
    .card-body {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        display: block;
        color: #001030;
        opacity: 0.6;
    }
    .article .article .card-footer a {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #3c3daa;
        background: white;
    }
    .card-footer {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #3c3daa;
        padding-top: 15px;
        padding-bottom: 15px;
        background: white;
    }
    .card1 {
        margin-right: 15px;
        margin-top: 25px;
    }
    .card2 {
        margin-right: 15px;
        margin-top: 25px;
    }
    .card3 {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .card4 {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .btn-success {
        height: 40px;
        line-height: 40px;
        display: inline-block;
        background: #6cd000;
        border-radius: 2px;
        color: #fff;
        font-size: 15px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        width: 140px;
        font-family: Burbank-Big-Cd-Bd;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #ffffff;
        padding-bottom: 30px;
        padding-top: 1px;
    }
    a[aria-expanded="false"] .fa-chevron-up {
        display: none;
        opacity: 1;
    }
    a[aria-expanded="true"] .fa-chevron-down {
        display: none;
        opacity: 1;
    }
    .fa-chevron-up {
        opacity: 0;
    }
    a[aria-expanded="true"] .fa-chevron-up {
        opacity: 1;
    }
    .opectiy-7 {
        background: #f5f7fa;
    }
    span.bold.pull-right {
        cursor: pointer;
        font-size: 115%;
    }
    h4 a:hover,
    span.bold.pull-right:hover {
        cursor: pointer;
        color: #2c6ac1;
    }
    h4 a {
        cursor: pointer;
    }
    hr {
        margin: 0px;
    }
    @media screen and (max-width: 575.98px) {
        span.bold.pull-right {
            cursor: pointer;
            font-size: 115%;
            margin-right: -20px;
        }
    }
    .bg-blue {
        background: #0056b3;
    }
    /* old faq css............ start.............. */
    @media screen and (max-width: 991.98px) {
        .faq .faq-header {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 360px;
            padding-left: 40px;
            padding-right: 40px;
            border-bottom-right-radius: 0;
        }
        .faq .faq-header__logo {
            display: block;
        }
        .m-0--30 {
            margin: 0;
        }
    }
    #article-container {
        margin-top: 250px;
    }
    @media screen and (max-width: 1199.98px) {
        #article-container {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    @media screen and (max-width: 991.98px) {
        #article-container {
            margin-top: 380px;
        }
    }
    @media screen and (max-width: 575.98px) {
        #article-container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .overflowhidden {
        overflow: hidden;
    }
    .faq .see-more {
        font-family: Burbank-Big-Cd-Md;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #ffffff;
    }
    .faq .article__header {
        font-family: BurbankSmall-Light;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
        margin: 40px 0 10px;
    }
    @media screen and (max-width: 991.98px) {
        .faq .article__header {
            margin: 20px 0 10px;
            text-align: center;
            font-size: 1.1em;
        }
        .faq .faq-header__navigation {
            margin-top: 20px;
        }
    }
    .faq .container {
        max-width: 1030px;
        padding: 0;
    }
    .faq .quick-menu li div {
        background: #ffffff;
        border-radius: 2px;
        text-align: center;
        padding: 35px 10px;
    }
    .faq .quick-menu li {
        padding: 10px;
        width: 33.3%;
        float: left;
    }
    .cursor {
        cursor: pointer;
    }
    .article .tournament__info-content {
        background: #fff;
        padding: 13px 17px;
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #001030;
        white-space: nowrap;
    }
    @media screen and (max-width: 991.98px) {
        .faq .quick-menu li {
            width: 50%;
        }
    }
    @media screen and (max-width: 620px) {
        .faq .quick-menu li {
            width: 100%;
        }
    }
    .faq .quick-menu ul {
        list-style: none;
        padding: 0;
    }
    .faq .quick-menu li a {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #001030;
        display: block;
        margin-top: 20px;
    }
    .faq .quick-menu li a:hover {
        text-decoration: none;
    }
    .faq .__input {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.05em;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 2px;
        height: 59px;
        padding: 5px 10px 0 55px;
        margin: 15px 0 40px;
    }
    .faq .__input::-webkit-input-placeholder {
        /* Chrome */
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #ffffff;
        opacity: 0.5;
    }
    .faq .__input:-ms-input-placeholder {
        /* IE 10+ */
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #ffffff;
        opacity: 0.5;
    }
    .faq .__input::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff;
        opacity: 1;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #ffffff;
        opacity: 0.5;
    }
    .faq .__input:-moz-placeholder {
        /* Firefox 4 - 18 */
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #ffffff;
        opacity: 0.5;
    }
    /* .faq .__input-container--search:before {
  top: 38px;
  left: 20px;
  width: 15px;
  height: 15px;
} */
    @media screen and (min-width: 992px) {
        .faq .article {
            min-height: calc(100vh - 339px);
        }
    }
    .adm .scrolltop .icon {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        text-transform: capitalize;
        color: #3c3daa;
        padding: 10px 20px;
        padding-right: 36px;
    }
    .adm .scrolltop {
        background-color: #fff;
        border-radius: 45px;
        border: 1px solid #3c3daa;
    }
    .scrolltop .icon:after {
        content: "";
        top: -2px;
        position: relative;
        left: 15px;
    }
    .grecaptcha-badge {
        width: 256px;
        height: 31px !important;
        display: block;
        transition: right 0.3s ease 0s;
        position: fixed;
        bottom: 0px !important;
        right: -128px !important;
        box-shadow: grey 0px 0px 5px;
        border-radius: 2px;
        overflow: hidden;
    }
    .go-link {
        margin-bottom: 100px;
    }
    .adm .go-link li a {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #001030;
    }
    .go-link li a:hover {
        text-decoration: none;
    }
    .article .go-link li {
        margin-bottom: 20px;
        background: #fff;
        padding: 25px 30px;
    }
    .go-link ul {
        list-style: none;
        padding-left: 0;
    }
    .adm .breadcrumbs {
        font-family: Burbank-Big-Rg-Md;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.05em;
        color: #001030;
        padding-top: 30px;
        margin-bottom: 25px;
        margin-left: -15px;
    }
    .adm .breadcrumbs a {
        color: #001030;
    }
    .breadcrumbs span:last-child {
        font-weight: 700;
    }
    @media screen and (max-width: 991.98px) {
        .breadcrumbs {
            margin-left: 15px;
        }
    }
    .walkthrough-page .faq-content {
        width: 100%;
        display: block;
        float: left;
        outline: none;
        margin-bottom: 20px;
    }
    .faq-content .faq-item {
        width: 100%;
        display: block;
        float: left;
        margin-bottom: 20px;
        background: rgba(0, 0, 0, 0.1);
        background: #ffffff;
        border-radius: 2px;
    }
    .faq-content .faq-item:last-child {
        margin-bottom: 0;
    }
    .faq-content .faq-item span.quest {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-size: 18px;
        line-height: 24px;
        color: #001030;
        position: relative;
        letter-spacing: -0.3px;
        cursor: pointer;
        padding: 25px 80px 25px 30px;
        width: 100%;
    }
    .faq-content .faq-item span.quest.active {
        color: #3c3daa;
        border-bottom: 1px solid rgba(232, 235, 239, 0.5);
    }
    .faq-content .faq-item span {
        display: block;
        float: left;
    }
    .faq-content .faq-item span.quest::before {
        content: url(#{$cdn}/img/arrow_down.svg);
        font-family: "kombatlink-icon";
        color: #d2d7df;
        position: absolute;
        right: 30px;
        font-size: 18px;
        display: inline-block;
        top: 24px;
        border-radius: 2px;
        border: 1px solid #dde3ec;
        margin-top: -8px;
        padding: 7px 14px;
    }
    .faq-content .faq-item span.quest.active::before {
        content: url(#{$cdn}/img/arrow_top-dark.svg);
        background: #f1f5fa;
        border: 1px solid #f1f5fa;
        content: url(#{$cdn}/img/arrow_down.svg);
        font-family: "kombatlink-icon";
        color: #d2d7df;
        position: absolute;
        right: 30px;
        font-size: 18px;
        display: inline-block;
        top: 24px;
        border-radius: 2px;
        margin-top: -8px;
        padding: 7px 14px;
        transform: rotateX(180deg);
    }
    .answer.active {
        display: inline;
    }
    .faq-content .faq-item span.answer {
        /*  width: calc(100% - 48px);*/
        letter-spacing: -0.3px;
        border-top: 1px solid rgba(255, 255, 255, 0.08);
        padding-top: 15px;
        display: none;
        /*    margin-left: 24px;*/
        /*    padding: 20px 30px 25px 0;*/
        padding: 20px 70px 25px 70px;
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #001030;
    }
    @media (max-width: 767px) {
        .faq-content .faq-item span.answer {
            padding: 16px;
        }
    }
    .faq-content .faq-item span.answer .row {
        clear: both;
    }
    .walkthrough-page.whitebg {
        background: #fff;
        padding: 60px 70px;
        margin-bottom: 20px;
        clear: both;
    }
    .pull-right {
        float: right;
    }
    @media screen and (max-width: 575.98px) {
        .walkthrough-page.whitebg {
            padding: 20px;
        }
    }
    .p-description {
        margin-bottom: 20px;
        clear: both;
    }
    .p-description span.num {
        float: none;
        display: inline-block;
        color: #ff9201;
    }
    .p-description div.space {
        height: 20px;
    }
    .faq .page-head {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;
        color: #3c3daa;
        margin-bottom: 30px;
    }
    .adm .page-head-top {
        font-family: BurbankSmall-Medium;
        margin-bottom: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;
        /* or 92% */
        /* text */
        color: #001030;
    }
    .adm .p-description.bold {
        font-family: BurbankSmall-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 32px;
        /* or 178% */
        /* text */
        color: #001030;
    }
    .whitebg .p-description.different {
        font-size: 18px;
        line-height: 32px;
    }
    .walkthrough-page.contact {
        margin-top: 15px;
    }
    .adm .contact .adm .p-description {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: #001030;
        opacity: 0.7;
        margin-bottom: 40px;
        display: block;
    }
    .walkthrough-page.contact .contact-content {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #e8ebef;
    }
    .walkthrough-page.contact .contact-content .item {
        width: 100%;
        display: block;
        float: left;
        padding: 30px;
        background: #fff;
        border-radius: 3px;
    }
    .walkthrough-page.contact .contact-content .item:last-child {
        padding-top: 23px;
        padding-bottom: 25px;
    }
    .walkthrough-page .full-width {
        margin-bottom: 40px;
        margin-top: 20px;
        width: 100%;
    }
    .walkthrough-page.contact .contact-content .item:not(:last-child) {
        border-bottom: 1px solid rgba(232, 235, 239, 0.5);
    }
    .walkthrough-page.contact .contact-content .col-xl-6 {
        padding: 10px;
    }
    .adm .walkthrough-page.contact .contact-content .item span.head {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        display: block;
        color: #001030;
        margin-bottom: 30px;
    }
    .adm .walkthrough-page.contact .contact-content .item span.title {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        display: block;
        color: #001030;
        opacity: 0.6;
    }
    .adm .walkthrough-page.contact .contact-content span.mail a {
        font-family: BurbankSmall-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #3c3daa;
        margin-left: 10px;
    }
    .walkthrough-page.contact .contact-content span.mail:before {
        vertical-align: middle;
    }
    .walkthrough-page .button-content a {
        height: 40px;
        line-height: 40px;
        display: inline-block;
        background: #6cd000;
        border-radius: 2px;
        color: #fff;
        font-size: 15px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        width: 140px;
        font-family: Burbank-Big-Cd-Bd;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        /* or 100% */
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        /* White */
        color: #ffffff;
    }
    .how-toplay .p-description {
        margin-bottom: 25px;
    }
    .faq .footer__icon--facebook:hover {
        background-image: url(#{$cdn}/img/facebook.svg);
        background-color: rgba(128, 128, 128, 0.781);
    }
    .faq .footer__icon--twitter:hover {
        background-image: url(#{$cdn}/img/twitter.svg);
        background-color: rgba(128, 128, 128, 0.781);
    }
    .faq .footer__icon--instagram:hover {
        background-image: url(#{$cdn}/img/instagram.svg);
        background-color: rgba(128, 128, 128, 0.781);
    }
    .faq .footer__icon--twitch:hover {
        background-image: url(#{$cdn}/img/twitch.svg);
        background-color: rgba(128, 128, 128, 0.781);
    }
    .faq .footer__icon--discord:hover {
        background-image: url(#{$cdn}/img/discord.svg);
        background-color: rgba(128, 128, 128, 0.781);
    }
    .faq .footer__icon--facebook {
        background-image: url(#{$cdn}/img/facebook.svg);
        background-color: rgb(128, 128, 128);
    }
    .faq .footer__icon--twitter {
        background-image: url(#{$cdn}/img/twitter.svg);
        background-color: rgb(128, 128, 128);
    }
    .faq .footer__icon--instagram {
        background-image: url(#{$cdn}/img/instagram.svg);
        background-color: rgb(128, 128, 128);
    }
    .faq .footer__icon--twitch {
        background-image: url(#{$cdn}/img/twitch.svg);
        background-color: rgb(128, 128, 128);
    }
    .faq .footer__icon--discord {
        background-image: url(#{$cdn}/img/discord.svg);
        background-color: rgb(128, 128, 128);
    }
    .scrolltop .icon:after {
        content: url(#{$cdn}/img/arrow_up.svg);
        top: -2px;
        position: relative;
    }
    .article .scrolltop {
        background-color: rgb(128, 128, 128);
        border: white;
        width: 100%;
    }
    .article .scrolltop:hover {
        background-color: rgba(128, 128, 128, 0.781);
        border: white;
        width: 100%;
    }
    .highlight {
        background-color: yellow;
    }
    .panswer {
        background: #dee2e6f5;
        color: white;
        width: 100%;
        min-height: 39%;
        max-height: 40vh;
        overflow-y: scroll;
        z-index: 9999999;
        position: absolute;
        top: 245px;
        left: 2px;
        justify-content: center;
        text-align: center;
        display: inline-flex;
    }
    #answer {
        padding: 50px 0px;
        width: 1050px;
        text-align: left;
    }
    @media screen and (max-width: 1100px) and (min-width: 990px) {
        .panswer {
            top: 235px;
        }
        #answer {
            margin: 0px 2%;
        }
    }
    @media screen and (max-width: 990px) {
        .panswer {
            top: 360px;
        }
        #answer {
            margin: 0px 2%;
        }
    }
    /*
  @media screen and (min-width: 1000px) {
      #answer {
          text-align: center;
          margin-top: 248px;
          width: 985px;
          margin-left: auto;
          margin-left: 0px;
          margin-right: 0px;
      }
  } */
    @media screen and (max-width: 1100px) and (min-width: 1000px) {
        .container.m-t-38.h-100.p-0 {
            width: calc(100% - 8%);
        }
    }
    @media screen and (max-width: 991.98px) {
        .table__main-row,
        .table__secondary-row {
            margin-left: 2em;
            margin-right: 2em;
        }
    }
    .faq {
        overflow-x: hidden;
    }
    .__input-container--search:after {
        content: "Search";
        width: 75px;
        margin: 14px 0px;
        position: absolute;
        right: 0px;
        bottom: 27px;
        opacity: 0.7;
        font-size: 20px;
        color: rgb(35, 43, 127);
        background: lightgrey;
        border: 1px solid transparent;
        border-radius: 3px;
        padding: 12px 8px;
        background-size: 72px 54px;
        padding-top: 13px;
    }
    @media screen and (max-width: 400px) and (min-width: 0px) {
        .faq .__input-container--search:before {
            position: absolute;
            width: 13px;
            height: 13px;
            top: 108px;
            left: 20px;
            background-color: rgba(0, 0, 0, 0);
            content: "";
            display: block;
            background-size: 100% 100%;
            background-image: url(#{$cdn}/img/search.svg);
            opacity: 0.5;
        }
        .__input-container--search:after {
            content: "Search";
            width: 75px;
            margin: 14px 0px;
            position: absolute;
            right: 0px;
            bottom: 27px;
            opacity: 0.7;
            font-size: 20px;
            color: rgb(35, 43, 127);
            background: lightgrey;
            border: 1px solid transparent;
            border-radius: 3px;
            padding: 12px 8px;
            background-size: 72px 54px;
            padding-top: 13px;
        }
        .article__faq-header {
            margin: 20px 0 10px;
            text-align: center;
            font-size: 1.1em;
        }
    }
    @media screen and (max-width: 1023px) and (min-width: 401px) {
        .faq .__input-container--search:before {
            position: absolute;
            width: 20px;
            height: 28px;
            top: 75px;
            left: 18px;
            background-color: rgba(0, 0, 0, 0);
            content: "";
            display: block;
            background-size: 100% 100%;
            background-image: url(#{$cdn}/img/search.svg);
            opacity: 0.5;
        }
        .__input-container--search::after {
            content: "Search";
            width: 75px;
            margin: 14px 0px;
            position: absolute;
            right: 0px;
            bottom: 27px;
            opacity: 0.7;
            font-size: 20px;
            color: rgb(35, 43, 127);
            background: lightgrey;
            border: 1px solid transparent;
            border-radius: 3px;
            padding: 12px 8px;
            background-size: 72px 54px;
            padding-top: 13px;
        }
        .article__faq-header {
            margin: 20px 0 10px;
            text-align: center;
            font-size: 1.1em;
        }
    }
    @media screen and (min-width: 1024px) {
        .faq .__input-container--search:before {
            position: absolute;
            width: 17px;
            height: 16px;
            top: 100px;
            left: 20px;
            background-color: rgba(0, 0, 0, 0);
            content: "";
            display: block;
            background-size: 100% 100%;
            background-image: url(#{$cdn}/img/search.svg);
            opacity: 0.5;
        }
        .__input-container--search:after {
            content: "Search";
            width: 75px;
            margin: 14px 0px;
            position: absolute;
            right: 0px;
            bottom: 26.5px;
            opacity: 0.7;
            font-size: 20px;
            color: rgb(35, 43, 127);
            background: lightgrey;
            border: 1px solid transparent;
            border-radius: 3px;
            padding: 13px 8px;
            background-size: 72px 54px;
            /* animation: mymove 1s infinite; */
        }
    }
    article.faq {
        color: #fff;
        background-color: transparent;
    }
    @keyframes mymove {
        from {
            opacity: 0.7;
        }
        to {
            opacity: 1;
        }
    }
    section.__input-container.__input-container--search input {
        border: transparent;
        border-radius: 5px;
    }
    /* old faq css............ end.............. */
    /* ............................faq css end ...................... */
    .headingText {
        font-weight: bold;
        margin-right: 2px;
    }
    .headingText:after {
        content: ":";
    }
    @media screen and (max-width: 768px) {
        .footer1 {
            margin-top: calc(100% - 59%);
        }
    }
    .right-menu {
        background: #1e0e46;
        width: 16em;
        position: absolute;
        right: 0px;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
        overflow-y: hidden;
        opacity: 0;
        bottom: 1px;
        top: 0px;
    }
    .right-menu * {
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .right-menu::-webkit-scrollbar {
        display: none;
    }
    @media screen and (min-width: 701px) and (max-width: 768px) {
        .right-menu {
            margin-top: 82px;
        }
    }
    @media screen and (max-width: 975px) and (min-width: 769px) {
        .right-menu {
            margin-top: 70px;
        }
    }
    #option-selected {
        font-style: normal;
        font-weight: 700;
        color: #fff;
    }
    .article__header {
        font-family: Burbank-Big-Cd-Bd, sans-serif;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0.05em;
        margin: 32px 0 0;
        font-weight: 700;
        color: #fff;
    }
    .not-active {
        width: 0%;
        z-index: 1;
    }
    @media screen and (min-width: 1200px) {
        .withdraw {
            height: 100%;
            overflow: auto;
            padding-top: 70px;
        }
        .header__section--friends1 {
            display: none;
        }
        .article,
        .article.active,
        .article.not-active {
            z-index: 99999;
        }
        .right-menu,
        .right-menu.active,
        .right-menu.not-active {
            width: 16em;
            z-index: 9999;
            opacity: 1;
            display: flex;
        }
    }
    @media screen and (max-width: 1198px) {
        .header__section--friends1 {
            display: block;
        }
        .article,
        .article.active {
            width: 100%;
            z-index: 9999;
        }
        .right-menu {
            width: 0em;
            z-index: 1;
            opacity: 0;
            display: none;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        .header__section--friends1 {
            display: block;
        }
        .article.active {
            width: 100%;
            z-index: 999999;
        }
        .article.not-active {
            width: calc(100% - 16em);
            z-index: 1;
        }
        .right-menu.active {
            width: 16em;
            z-index: 99999;
            opacity: 1;
            display: flex;
        }
        .right-menu.not-active {
            width: 0em;
            z-index: 1;
            opacity: 0;
            display: none;
        }
    }
    @media screen and (max-width: 991px) {
        .tab-menu__link {
            padding: 0 0 8px;
        }
    }
    @media screen and (max-width: 767px) {
        .header__section--friends1 {
            display: block;
        }
        .article.active {
            width: 100%;
            z-index: 9999;
        }
        .article.not-active {
            width: 0em;
            z-index: 1;
        }
        .right-menu.active {
            width: 90%;
            z-index: 99999;
            opacity: 1;
            position: fixed;
            overflow-y: scroll;
            top: 4em;
            display: flex;
        }
        .right-menu.not-active {
            width: 0em;
            z-index: 1;
            opacity: 0;
            display: none;
        }
    }
    @media screen and (min-width: 700px) and (max-width: 768px) {
        .right-menu.active {
            width: 90%;
            z-index: 999999999;
            opacity: 1;
            position: fixed;
            overflow-y: scroll;
            top: -1em;
            display: flex;
        }
    }
    #footer1 {
        margin-top: 3em;
    }
    .header__login_signup {
        margin: 16px 0px 0px 16px;
        width: 140px;
        height: 30px;
    }
    .article .select__container {
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-items: center;
        width: 100%;
        left: 0;
        top: 45px;
        -webkit-transform: translateY(-50%) scaleY(0);
        -ms-transform: translateY(-50%) scaleY(0);
        transform: translateY(-50%) scaleY(0);
        -webkit-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        background-color: #25324e;
        padding: 0;
        border-radius: 4px;
        max-height: 194px;
        overflow: auto;
        scrollbar-width: none;
    }
    .select--opened .select__container {
        -webkit-transform: translateY(0) scaleY(1);
        -ms-transform: translateY(0) scaleY(1);
        transform: translateY(0) scaleY(1);
    }
    .select__container::-webkit-scrollbar-track {
        background-color: #3c3daa29;
    }
    .select__container::-webkit-scrollbar-thumb {
        background-color: #3c3dbc30;
    }
    .select__container::-webkit-scrollbar {
        width: 5px;
        height: 7px;
        border: none;
        overflow: hidden;
        border-radius: 0 3px 3px 0;
    }
    .select__container::-webkit-scrollbar:hover {
        width: 10px;
    }
    .select__option:first-of-type {
        margin-top: 7px;
    }
    .select__option:last-of-type {
        margin-bottom: 7px;
    }
    .select__option--blocked {
        background: hsla(0, 0%, 100%, 0.07);
    }
    .select__option--blocked:after {
        content: "";
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: hsla(0, 0%, 100%, 0.2);
        background-size: 5px 7px;
        background-repeat: no-repeat;
        background-position: 50%;
        background-image: url(#{$cdn}/img/locked.svg);
        border-radius: 50%;
        right: 15px;
        top: 8px;
        opacity: 1;
    }
    .select__option:hover {
        background: hsla(0, 0%, 100%, 0.07);
    }
    .select__option--selected {
        color: #49c3fd;
    }
    .select__option--selected:after {
        opacity: 1;
    }
    .adm .select__search-container {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #e8eef6;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-justify-content: center;
        padding: 0 6px;
    }
    .select__search-container:after {
        content: "";
        display: block;
        position: absolute;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        width: 10px;
        height: 10px;
        right: 16px;
        top: 20px;
        z-index: 1;
        background-image: url(#{$cdn}/img/grey_search.svg);
        opacity: 0.3;
    }
    .adm .select__search {
        width: 100%;
        height: 30px;
        background-color: hsla(0, 0%, 78.4%, 0.1);
        border-radius: 2px;
        padding: 0 10px;
        font-family: Burbank Small, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 13px;
        color: #001030 !important;
        border: none;
        outline: none;
        z-index: 2;
    }
    .article .select__blocked {
        position: absolute;
        display: block;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-size: 8px 11px;
        background-image: url(#{$cdn}/img/white_lock.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
        z-index: 999999;
    }
    .select__blocked-container {
        z-index: 9999;
        display: none;
        position: absolute;
        left: 10px;
        top: 20px;
        -webkit-transform: translate(-100%, calc(2px - 100%));
        -ms-transform: translate(-100%, calc(2px - 100%));
        transform: translate(-100%, calc(2px - 100%));
        width: 200px;
        padding-bottom: 7px;
        -webkit-animation: show 0.15s ease-in-out;
        animation: show 0.15s ease-in-out;
        opacity: 1;
    }
    .select__blocked-container--show,
    .select__blocked-container:hover {
        display: block;
    }
    .article .select__blocked-content {
        display: block;
        width: 100%;
        padding: 10px 0;
        text-align: center;
        background: #fff;
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #001030;
        position: relative;
        border-radius: 2px;
    }
    .article .select__blocked-content:before {
        content: "";
        display: block;
        position: absolute;
        left: 85%;
        bottom: -11px;
        width: 0;
        height: 0;
        border: 8px solid rgba(0, 0, 0, 0);
        border-top: 4px solid #fff;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }
    .adm .select__blocked-link,
    .select__blocked-link:hover {
        color: #3c3daa;
        text-decoration: underline;
    }
    .article .article__selects-popup {
        z-index: 997;
    }
    @media screen and (max-width: 767.98px) {
        .article__selects-popup-close {
            background-image: url(#{$cdn}/img/cross-white.svg);
        }
        .tab-menu__link {
            margin: 0 25px 0 0;
        }
        .game__prize,
        .game__time {
            margin-left: 20%;
        }
        .article .article__selects-popup {
            height: calc(100vh - 60px);
            background-color: #051534;
            z-index: 999;
            padding: 30px 30px 0 20px;
        }
        .article__selects-popup-header {
            display: block;
        }
        .article .article__selects-popup {
            position: fixed;
            width: 100%;
            height: 100vh;
            left: 0;
            background: -webkit-gradient(linear, left bottom, left top, from(#051534), to(#051534));
            background: -o-linear-gradient(bottom, #051534, #051534);
            background: linear-gradient(0deg, #051534, #051534);
            margin: 0;
            padding: 40px;
            overflow: auto;
            top: 100%;
            -webkit-transition: 0.25s;
            -o-transition: 0.25s;
            transition: 0.25s;
            z-index: 999999;
        }
        .article__selects-popup--opened {
            top: 60px;
        }
    }
    .article .article__tab-menu-before-container {
        margin: 0rem;
        width: 100%;
    }
    .article__tab-menu-container {
        position: relative;
        margin: 0;
        width: 100%;
    }
    .article .article__tab-menu-button {
        position: absolute;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-position: 50%;
        background-image: url(#{$cdn}/img/grey_arrow_top.svg);
        border: none;
        cursor: pointer;
        bottom: 18px;
        width: 20px;
        height: 12px;
        display: none;
    }
    @media screen and (max-width: 775.98px) {
        .article__prev-page {
            margin-right: 15px;
        }
        .article__next-page {
            margin-left: 15px;
        }
        .article__page {
            margin: 0 5px;
        }
        .article__tab-menu-container {
            padding: 0 30px;
            margin: 20px 0 30px;
            overflow-x: visible;
            white-space: nowrap;
        }
        .article__tab-menu-button--left {
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
            left: -10px;
        }
        .article .article__tab-menu-button {
            display: block;
            margin-bottom: -10px;
        }
    }
    @media screen and (max-width: 775.98px) {
        .article__tab-menu-button {
            display: block;
        }
    }
    .article__tab-menu-button--left {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        left: 0;
    }
    @media screen and (max-width: 1299.98px) {
        .article__tab-menu-before-container {
            border-bottom: none;
        }
        .article__tab-menu-container {
            width: 100%;
            max-width: 100%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            margin-bottom: 0px;
        }
        .article .article__tab-menu {
            border-bottom: 1px solid #25324e;
        }
    }
    .d-flex-optional {
        display: flex;
    }
    .tab-menu__radio-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 5px 0px;
    }
    .tab-menu__radio-container:first-of-type .tab-menu__radio-text,
    .tab-menu__radio-container:nth-of-type(2) .tab-menu__radio-text {
        margin: 0 20px 0 7px;
    }
    .table .article__column-label:last-of-type {
        margin-left: 30px;
    }
    .article__selects-container {
        position: relative;
        z-index: 1;
    }
    .article__selects-container--opened {
        z-index: 3;
    }
    @media screen and (max-width: 991.98px) {
        .article__radio-container,
        .tab-menu__events-container {
            -webkit-box-ordinal-group: 0;
            -ms-flex-order: -1;
            order: -1;
        }
        .article__radio-container {
            margin-bottom: 15px;
        }
    }
    .article-header {
        margin: 0 -45px;
        width: calc(100% + 90px);
        position: relative;
        margin-bottom: 30px;
    }
    article .article-header__content {
        position: absolute;
        left: 15px;
        top: 0;
        min-height: 100%;
        width: 100%;
        padding: 2em 4em;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, rgba(30, 44, 72, 0)), to(#041534)),
            -webkit-gradient(linear, left bottom, left top, from(rgba(0, 20, 60, 0.3)), to(rgba(0, 20, 60, 0.3)));
        background: -o-linear-gradient(top, rgba(30, 44, 72, 0) 20%, #041534 100%),
            -o-linear-gradient(bottom, rgba(0, 20, 60, 0.3), rgba(0, 20, 60, 0.3));
        background: linear-gradient(180deg, rgba(30, 44, 72, 0) 20%, #041534),
            linear-gradient(0deg, rgba(0, 20, 60, 0.3), rgba(0, 20, 60, 0.3));
        border-bottom: 1px solid #25324e;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    .article-header__info {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        padding-right: 20px;
        height: 18px;
        text-transform: capitalize;
    }
    .article-header__info:before {
        content: "";
        display: inline-block;
        width: 21px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        background-repeat: no-repeat;
        background-position: 50%;
        -webkit-transform: translateY(3px);
        -ms-transform: translateY(3px);
        transform: translateY(3px);
        margin-right: 4px;
    }
    .article-header__info--date {
        color: #49c3fd;
    }
    .article-header__info--status {
        color: #6cd000;
    }
    .article-header__info--region {
        color: #fff;
    }
    .article-header__info--date:before {
        background-size: 15px 16px;
        background-image: url(#{$cdn}/img/blue_calendar.svg);
    }
    .article-header__info--status:before {
        background-size: 21px 14px;
        background-image: url(#{$cdn}/img/green_gamepad.svg);
    }
    .article-header__info--region:before {
        background-size: 12px 17px;
        background-image: url(#{$cdn}/img/location.svg);
    }
    .adm .article-header__change-header {
        position: absolute;
        right: 30px;
        bottom: 20px;
        width: 152px;
        height: 30px;
        background: hsla(0, 0%, 100%, 0.2);
        border-radius: 2px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 30px;
        color: #001030;
        opacity: 0.5;
        padding: 0 0 0 34px;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
        text-decoration: none;
    }
    .adm .article-header__change-header:hover {
        color: #001030;
        opacity: 0.7;
        text-decoration: none;
    }
    .article-header__change-header:before {
        position: absolute;
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/camera.svg);
        left: 15px;
        top: 9px;
    }
    .article__warning:before {
        content: "!";
        position: absolute;
        display: block;
        font-family: BurbankSmall-Bold, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 20px;
        color: #fff;
        left: 30px;
        top: calc(50% - 10px);
    }
    .article .article__warning a {
        font-weight: 700;
        text-decoration: underline;
        color: #fff;
    }
    .article .article__warning a:hover {
        color: #fff;
    }
    .article__subheader {
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .article-detail__container {
        background: hsla(0, 0%, 100%, 0.1);
        width: 100%;
    }
    .article-detail__container--details {
        padding: 15px 20px;
        margin-bottom: 10px;
    }
    .article-detail__container--organizer {
        padding: 25px 30px;
    }
    .article-detail__container--clans {
        padding: 20px 45px;
        margin-bottom: 20px;
    }
    .article-detail__container--additional-info {
        padding: 20px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 25px;
        color: rgba(5, 21, 52, 0.8);
    }
    .article-detail__container--additional-info,
    .article__detail-title {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 550;
    }
    .article-detail__container--additional-info {
        padding: 20px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 25px;
        color: hsla(0, 0%, 100%, 0.8);
    }
    .article__detail-title {
        font-size: 13px;
        line-height: 13px;
        color: hsla(0, 0%, 100%, 0.4);
        margin-bottom: 8px;
    }
    .article__clan {
        margin-bottom: 10px;
    }
    .article__clan:last-of-type {
        margin-bottom: 0;
    }
    .article__clans-header {
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #001030;
        margin-bottom: 22px;
    }
    .article__clans-header,
    .article__clans-link {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
    }
    .article__clans-link {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #49c3fd;
        margin-left: 10px;
    }
    .article__clans-link:hover {
        color: #49c3fd;
    }
    .article__clan-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    .adm .article__clan-title {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #001030;
        margin-left: 12px;
    }
    .article__clans-button {
        position: relative;
        width: 140px;
        height: 40px;
        padding: 0 0 0 17px;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }
    @media screen and (max-width: 575.98px) {
        .article-header {
            margin: 0px 0px 0px -15px;
            width: calc(100% + 60px);
            position: relative;
        }
    }
    .article .article__clans-button:before {
        position: absolute;
        content: "";
        display: block;
        width: 45px;
        height: 22px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 21px 22px;
        background-image: url(#{$cdn}/img/chat.svg);
        left: 0px;
        top: 13px;
        background-repeat: no-repeat;
        background-position: 50%;
    }
    .article__location {
        padding: 30px;
    }
    .article__location-header {
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 12px;
    }
    .article .article__location-address,
    .article__location-header {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        color: #fff;
    }
    .article__location-address {
        font-size: 14px;
        line-height: 20px;
        opacity: 0.8;
        margin-bottom: 22px;
    }
    .article__location-map {
        position: relative;
        width: 100%;
        height: 220px;
    }
    .adm .article__big-subheader {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 22px;
        line-height: 22px;
        color: #001030;
        margin-bottom: 39px;
    }
    .article__clan-picture,
    .article__participant-picture {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .adm .article__clan-name,
    .adm .article__participant-username {
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 23px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        letter-spacing: 0.05em;
        color: #001030;
        margin-left: 20px;
    }
    .article__delete-clan,
    .article__delete-participant {
        width: 140px;
        height: 40px;
    }
    @media screen and (max-width: 991.98px) {
        .tab-menu__link {
            padding: 0 0 15px;
        }
        .help__background-container {
            display: none;
        }
    }
    @media screen and (max-width: 849.98px) {
        .article-header__tab-menu-button {
            display: block;
        }
        .article-header__tab-menu-container {
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
            -webkit-align-items: flex-end;
        }
        .tab-menu {
            height: 100%;
            overflow: hidden;
        }
    }
    @media screen and (max-width: 767.98px) {
        .tab-menu__link {
            margin: 0 25px 0 0;
        }
        .drop__icon,
        .drop__text {
            display: none;
        }
        .table__checkbox:checked ~ .table__secondary-row-container {
            max-height: 1800px;
        }
        .article__clans-button {
            margin-bottom: 10px;
            margin-right: -15px;
            margin-top: 30px;
        }
        .help {
            padding: 0 50px;
        }
        .help__container {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .article__big-subheader {
            margin-bottom: 21px;
        }
    }
    @media screen and (max-width: 575.98px) {
        .article-header__tab-menu-container {
            padding: 0 30px;
        }
        .article-header__tab-menu-button--right {
            right: 5px;
        }
        .adm .article__big-subheader {
            font-family: BurbankSmall-Medium, sans-serif;
            font-style: normal;
            font-size: 22px;
            line-height: 22px;
            color: #001030;
            margin-bottom: 39px;
        }
        .article__clan-picture,
        .article__participant-picture {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
        .adm .article__clan-name,
        .adm .article__participant-username {
            font-family: Burbank-Big-Rg-Md, sans-serif;
            font-style: normal;
            font-size: 16px;
            line-height: 23px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            letter-spacing: 0.05em;
            color: #001030;
            margin-left: 20px;
        }
        .article__delete-clan,
        .article__delete-participant {
            width: 140px;
            height: 40px;
        }
    }
    @media screen and (max-width: 991.98px) {
        .tab-menu__link {
            padding: 0 0 15px;
        }
    }
    @media screen and (max-width: 767.98px) {
        .tab-menu__link {
            margin: 0 25px 0 0;
        }
        .drop__icon,
        .drop__text {
            display: none;
        }
        .table__checkbox:checked ~ .table__secondary-row-container {
            max-height: 1800px;
        }
        .article__clans-button {
            margin-bottom: 10px;
            margin-right: -15px;
            margin-top: 30px;
        }
        .help {
            padding: 0 50px;
        }
        .help__container {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .article__big-subheader {
            margin-bottom: 21px;
        }
    }
    .help {
        padding: 0 35px;
    }
    .help__container {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .help__header {
        font-size: 22px;
        line-height: 30px;
    }
    .participants {
        margin: 0 -20px;
    }
    .article-detail__container--organizer {
        padding-left: 12px;
        padding-right: 12px;
    }
    .article-detail__container--clans {
        padding-left: 30px;
        padding-right: 30px;
    }
    .article__location {
        padding: 20px 15px;
    }
    .article__search-container {
        width: 100%;
    }
    .article__big-subheader {
        margin-top: 15px;
    }
    .article__participant {
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .article__delete-participant {
        width: 100%;
        margin-top: 15px;
    }
    @media screen and (max-width: 1199.98px) {
        .article__participant-picture--member {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 575.98px) and (min-width: 0) {
        .table__column {
            width: 100%;
            padding: 3px 0;
        }
        .table__secondary-row .table__column {
            font-size: 14px;
        }
        .table__warning-content {
            -webkit-transform: translate(-80%);
            -ms-transform: translate(-80%);
            transform: translate(-80%);
        }
        .table__warning-content:before {
            left: 80%;
        }
        .table__warning:hover .table__warning-content {
            display: block;
        }
        .article-header {
            margin: 0 -30px;
            width: calc(100% + 60px);
        }
        .article-header__info {
            margin-bottom: 10px;
        }
        .registration__info {
            padding: 20px 30px 14px 20px;
        }
        .registration__column {
            padding-right: 40px;
        }
        .registration__players-container {
            padding: 0 5px 0 50px;
            position: relative;
            top: 8px;
        }
        .registration__join {
            width: calc(100% - 40px);
            margin-left: 20px;
        }
    }
    @media screen and (max-width: 1829.98px) {
        .article__clans-container {
            padding: 22px 35px 0;
        }
    }
    @media screen and (max-width: 1799.98px) {
        .article__container--additional-info {
            margin-bottom: 0;
        }
    }
    .article__big-subheader-white {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 22px;
        line-height: 22px;
        color: #fff;
        margin-bottom: 39px;
    }
    @media screen and (min-width: 576px) {
        .article__participant-picture--member {
            margin-left: 60px;
        }
    }
    @media screen and (max-width: 1649.98px) {
        .article-header__header {
            width: 100%;
        }
    }
    @media screen and (max-width: 1199.98px) and (min-width: 0) {
        .article-header__consoles-container {
            overflow: visible;
            padding-bottom: 10px;
            -webkit-box-flex: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            -ms-flex-negative: 2;
            flex-shrink: 2;
            -ms-flex-preferred-size: 46px;
            flex-basis: 46px;
            width: 100%;
            margin-bottom: 30px;
        }
        .article-header__container {
            padding-bottom: 10px;
        }
        .article-header__registration-container {
            padding: 0;
        }
        .article-header__change-header {
            margin-bottom: 50px;
        }
    }
    @media screen and (max-width: 1199.98px) and (min-width: 568px) {
        .article__header-container,
        .article-header__background .article-header {
            min-height: 75vh;
        }
        /* , .article-header__content {
         min-height: 75vh;
     } */
        .article-header__content {
            padding: 1em 2em;
        }
    }
    .article__edit-tournament {
        width: 127px;
        height: 40px;
    }
    .article__cancel-tournament {
        width: 157px;
        height: 40px;
    }
    .article-header__info {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        padding-right: 20px;
        height: 18px;
        text-transform: capitalize;
    }
    .article-header__info:before {
        content: "";
        display: inline-block;
        width: 21px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        background-repeat: no-repeat;
        background-position: 50%;
        -webkit-transform: translateY(3px);
        -ms-transform: translateY(3px);
        transform: translateY(3px);
        margin-right: 4px;
    }
    .article-header__info--date {
        color: #49c3fd;
    }
    .article-header__info--status {
        color: #6cd000;
    }
    .article-header__info--region {
        color: #fff;
    }
    .article-header__info--date:before {
        background-size: 15px 16px;
        background-image: url(#{$cdn}/img/blue_calendar.svg);
    }
    .article-header__info--status:before {
        background-size: 21px 14px;
        background-image: url(#{$cdn}/img/green_gamepad.svg);
    }
    .article-header__info--region:before {
        background-size: 12px 17px;
        background-image: url(#{$cdn}/img/location.svg);
    }
    .article .article__label-text {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
        opacity: 0.4;
        margin: 0;
    }
    .article__label-blocked,
    .article__label-text {
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .article__label-blocked {
        position: absolute;
        display: block;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        background-color: hsla(0, 0%, 100%, 0.2);
        border-radius: 50%;
        background-size: 8px 11px;
        background-image: url(#{$cdn}/img/white_lock.svg);
        background-position: 50%;
        background-repeat: no-repeat;
    }
    .article__label-blocked-container {
        z-index: 999;
        display: block;
        position: absolute;
        left: 50%;
        bottom: 20px;
        width: 200px;
        padding-bottom: 7px;
        -webkit-transform: translate(-85%);
        -ms-transform: translate(-85%);
        transform: translate(-85%);
        opacity: 0;
    }
    .article__label:hover .article__label-blocked-container {
        -webkit-animation: show 0.15s ease-in-out;
        animation: show 0.15s ease-in-out;
        opacity: 1;
    }
    .article__label:hover .article__label-blocked {
        background-color: #fff;
        background-image: url(#{$cdn}/img/purple_lock.svg);
    }
    .adm .article__label-blocked-content {
        display: block;
        width: 100%;
        padding: 10px 0;
        text-align: center;
        background: #fff;
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #001030;
        position: relative;
        border-radius: 2px;
    }
    .article .article__label-blocked-content:before {
        content: "";
        display: block;
        position: absolute;
        left: 85%;
        bottom: -11px;
        width: 0;
        height: 0;
        border: 8px solid rgba(0, 0, 0, 0);
        border-top: 4px solid #fff;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }
    .adm .article__blocked-link,
    .adm .article__blocked-link:hover {
        color: #3c3daa;
        text-decoration: underline;
    }
    .article .article__label-director {
        position: absolute;
        display: block;
        right: 10px;
        top: 10px;
        width: 16px;
        height: 16px;
        background: #fff;
        opacity: 0.1;
        border-radius: 50%;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    #cash-prize:checked ~ .row .article__label--cash-prize .article__label-icon,
    #live:checked ~ .row .article__label--live .article__label-icon,
    #merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-icon,
    #online:checked ~ .row .article__label--online .article__label-icon,
    .article__checkbox:checked + .article__label .article__label-icon {
        opacity: 1;
    }
    .article__checkbox:checked + .article__label .article__label-icon path {
        fill: #6acafc;
    }
    #live:checked ~ .row .article__label--live .article__label-icon .path-fill-black {
        fill: #000;
        opacity: 0.1;
    }
    #live:checked ~ .row .article__label--live .article__label-icon .path-fill-white {
        fill: #fff;
        opacity: 0.4;
    }
    #cash-prize:checked ~ .row .article__label--cash-prize .article__label-icon .path-fill-dark-grey,
    #live:checked ~ .row .article__label--live .article__label-icon .path-fill-dark-grey,
    #merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-icon .path-fill-dark-grey {
        fill: #6acafc;
        opacity: 1;
    }
    #merchandise-prize:checked
        ~ .row
        .article__label--merchandise-prize
        .article__label-icon
        .path-fill-dark-grey--last {
        fill: #4eb7ed;
        opacity: 1;
    }
    #live:checked ~ .row .article__label--live .article__label-icon .path-fill-light-grey {
        fill: #f2f2f2;
        opacity: 0.4;
    }
    #cash-prize:checked ~ .row .article__label--cash-prize .article__label-icon .path-fill-grey-3,
    #live:checked ~ .row .article__label--live .article__label-icon .path-fill-grey {
        fill: #e0f4ff;
        opacity: 1;
    }
    #merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-icon .path-fill-grey-3 {
        fill: #f7f8ff;
        opacity: 1;
    }
    #online:checked ~ .row .article__label--online .article__label-icon .path-fill-grey-2,
    #online:checked ~ .row .article__label--online .article__label-icon .path-fill-light-grey-2 {
        fill: #6acafc;
        opacity: 1;
    }
    #online:checked ~ .row .article__label--online .article__label-icon .path-fill-white-2 {
        fill: #f7f8ff;
        opacity: 1;
    }
    #online:checked ~ .row .article__label--online .article__label-icon .path-fill-dark-white-2 {
        fill: #e0f4ff;
        opacity: 1;
    }
    #cash-prize:checked ~ .row .article__label--cash-prize .article__label-icon .path-fill-dark-white-3,
    #merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-icon .path-fill-dark-white-3 {
        fill: #6acafc;
        opacity: 1;
    }
    #cash-prize:checked ~ .row .article__label--cash-prize .article__label-text,
    #live:checked ~ .row .article__label--live .article__label-text,
    #merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-text,
    #online:checked ~ .row .article__label--online .article__label-text,
    #repeat-every-day:checked ~ .row .article__label--repeat-every-day .article__label-text,
    #repeat-every-hour:checked ~ .row .article__label--repeat-every-hour .article__label-text,
    .article__checkbox:checked + .article__label .article__label-text {
        color: #66c3fa;
        opacity: 1;
    }
    #cash-prize:checked ~ .row .article__label--cash-prize .article__label-director,
    #live:checked ~ .row .article__label--live .article__label-director,
    #merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-director,
    #online:checked ~ .row .article__label--online .article__label-director,
    .article__checkbox:checked + .article__label .article__label-director {
        background: #6acafc;
        opacity: 1;
    }
    #cash-prize:checked ~ .row .article__label--cash-prize .article__label-director:before,
    #live:checked ~ .row .article__label--live .article__label-director:before,
    #merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-director:before,
    #online:checked ~ .row .article__label--online .article__label-director:before,
    .article__checkbox:checked + .article__label .article__label-director:before {
        opacity: 1;
    }
    .article__cash-prize-container,
    .article__live-event-container,
    .article__merchandise-prize-container,
    .article__online-event-container,
    .article__repeat-every-day-container,
    .article__repeat-every-hour-container {
        display: none;
    }
    #cash-prize:checked ~ .article__cash-prize-container,
    #live:checked ~ .article__live-event-container,
    #merchandise-prize:checked ~ .article__merchandise-prize-container,
    #online:checked ~ .article__online-event-container,
    #repeat-every-day:checked ~ .article__repeat-every-day-container,
    #repeat-every-hour:checked ~ .article__repeat-every-hour-container {
        display: block;
    }
    .article__pages-container {
        margin: 30px 0;
    }
    .article__prev-page {
        color: #fff;
        opacity: 0.3;
        margin-right: 40px;
    }
    article .article__selects-popup {
        margin-top: 20px;
    }
    .article .article-select__title {
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.05em;
        color: #fff;
        opacity: 0.7;
        margin: 0 0 10px;
    }

    section.article-select__select--organizer__container.select__container.select-coin {
        background: #f2f2f4 !important;
    }

    .old-kl-css .select-coin .article .select__option {
        color: #5a6379 !important;
        opacity: 1 !important;
    }
    .old-kl-css .article .select-coin .select__option {
        color: #000 !important;
        opacity: 1 !important;
    }

    .article__next-page,
    .article__prev-page {
        font-family: BurbankSmall-Bold, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
    }
    .article__next-page {
        color: #49c3fd;
        margin-left: 40px;
        text-decoration: none;
    }
    .article__prev-page:hover {
        text-decoration: none;
        color: #fff;
    }
    .article__next-page:hover {
        text-decoration: none;
        color: #49c3fd;
    }
    .article__page,
    .article__page-dots {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 14px;
        color: #fff;
        text-decoration: none;
    }
    .article__page:hover {
        text-decoration: none;
        color: #fff;
    }
    .article__prev-stage {
        width: 140px;
        height: 40px;
    }
    .article__prev-stage:before {
        content: "";
        margin: 14px 7px 15px 0;
        width: 13px;
        height: 11px;
        color: #3c3daa;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/blue_arrow_left.svg);
    }
    .article-header__header {
        font-family: BurbankSmall-Medium, sans-serif;
        color: #fff;
        font-size: 36px;
        line-height: 54px;
        font-weight: 700;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 80%;
        flex: 0 0 80%;
        width: 80%;
    }
    @media screen and (max-width: 1299.98px) and (min-width: 768px) {
        .article-select {
            width: 33.33333%;
        }
    }
    @media screen and (max-width: 1299.98px) and (min-width: 990px) {
        #transactionbtnlast button {
            transform: scale(0.7);
            float: left;
            margin: 0px;
            display: block;
            width: 6em;
        }
    }
    @media screen and (max-width: 767.98px) and (min-width: 576px) {
        .article-select {
            width: 50%;
        }
    }
    @media screen and (max-width: 575.98px) and (min-width: 0) {
        .article-select {
            width: 100%;
        }
    }
    .gamefooter {
        width: 100%;
        text-align: center;
        position: relative;
        top: -125px;
        left: 15px;
        height: 20px;
    }
    .article__pages-container {
        margin: 30px 0;
    }
    .article__next-page,
    .article__prev-page {
        font-family: BurbankSmall-Bold, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
        text-decoration: none;
    }
    .article__prev-page {
        color: rgba(255, 255, 255, 0.664);
        opacity: 0.3;
        margin-right: 40px;
        cursor: pointer;
    }
    .article__next-page {
        color: #49c3fd;
        margin-left: 40px;
        text-decoration: none;
        cursor: pointer;
    }
    .article .article__page--active {
        width: 28px;
        height: 30px;
        padding-top: 2px;
        background: #49c3fd;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
    }
    .article__page {
        margin: 0 10px;
    }
    .mtb-30 {
        margin-top: 30px;
    }
    .article__page,
    .article__page-dots {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.664);
        text-decoration: none;
    }
    @media screen and (max-width: 575.98px) {
        .article__prev-page {
            margin-right: 15px;
        }
        .article__page {
            margin: 0 5px;
        }
    }
    .adm .article-select__title {
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.05em;
        color: #001030;
        opacity: 0.7;
        margin: 0 0 10px;
    }
    .article .article-select__input-date-container:after {
        content: "";
        display: block;
        position: absolute;
        top: 12px;
        right: 16px;
        width: 17px;
        height: 15px;
        background-color: #25324e;
        background-image: url(#{$cdn}/img/calendar.svg);
        background-size: 100% 100%;
        z-index: 1;
        color: rgba(0, 0, 0, 0);
        cursor: pointer;
        opacity: 0.5;
    }
    .article .article-select__input-date {
        background-color: rgba(0, 0, 0, 0);
        position: absolute;
        left: 0;
        top: 0;
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0 30px 0 15px;
        color: #fff;
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 14px;
        letter-spacing: 0.05em;
        z-index: 2;
    }
    .article-select__input-date::-webkit-inner-spin-button,
    .article-select__input-date::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
        display: none;
    }
    .article-select__input-date::-webkit-calendar-picker-indicator {
        display: block;
        position: absolute;
        top: 12px;
        right: 13px;
        width: 12px;
        height: 10px;
        z-index: 10;
        cursor: pointer;
        opacity: 0;
    }
    .article-select__input-date::-webkit-clear-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        margin: 0;
    }
    .article-select {
        padding-right: 0;
        padding-left: 15px;
    }
    .article .article-select__select {
        position: relative;
        height: 40px;
        background: #25324e;
        border-radius: 4px;
        margin: 0 0 20px;
    }
    .article .article-select__select-coin {
        position: relative;
        height: 40px;
        background: #f2f2f4;
        border-radius: 4px;
        margin: 0 0 20px;
    }
    .search {
        margin-bottom: 20px;
    }
    .no-padding {
        padding: 0px;
    }
    .article__detail-text {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 550;
        font-size: 14px;
        line-height: 14px;
        color: #fff;
    }
    .article-header__background {
        position: relative;
        min-height: 300px;
        // min-height: 74vh;
        width: 100%;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0);
        background-size: cover;
        background-position: 50%;
    }
    .article__header-container {
        // min-height: 75vh;
    }
    /*
 .article-header {
     margin: 0 -45px;
     width: calc(100% + 90px);
     position: relative;
     margin-bottom: 30px;
 } */
    .article-header__platform,
    .article-header__platform.article-header__platform--android,
    .article-header__platform--ios {
        width: 110px;
        height: 30px;
        background-color: #091937;
        border-radius: 2px;
        margin: 2.5px 5px 2.5px 0;
        background-position: 50%;
        background-size: auto 40%;
        background-repeat: no-repeat;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        text-align: center;
        padding-top: 2px;
    }
    .article-header__platform--cross-platform {
        background-image: url(#{$cdn}/img/cross_platform.svg);
    }
    .article-header__platform--xbox {
        background-image: url(#{$cdn}/img/xbox.svg);
    }
    .article-header__platform--ps {
        background-image: url(#{$cdn}/img/ps.svg);
    }
    .article-header__platform--pc {
        background-image: url(#{$cdn}/img/pc.svg);
    }
    .article-header__platform--nintendo {
        background-image: url(#{$cdn}/img/nintendo.svg);
    }
    .article-header__consoles-container,
    .article-header__header-container,
    .article-header__info-container {
        -webkit-box-flex: 0;
        -ms-flex: 0;
        flex: 0;
    }
    .article-header__button {
        position: relative;
        width: 171px;
        height: 50px;
        padding: 0 0 0 43px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-justify-content: center;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-align-items: flex-start;
        font-size: 13px;
        line-height: 17px;
        font-family: BurbankSmall-Bold, sans-serif;
        font-weight: 700;
    }
    .article-header__button span {
        font-size: 14px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        letter-spacing: 0.05em;
    }
    .article-header__button:hover {
        text-decoration: none;
        color: #fff;
    }
    .article-header__button:before {
        content: "";
        display: block;
        position: absolute;
        left: 15px;
        top: 17px;
        width: 18px;
        height: 16px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/heart.svg);
        opacity: 0.6;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .article-header__button:hover:before {
        opacity: 0.8;
    }
    @media screen and (max-width: 991.98px) {
        .tab-menu__link {
            padding: 0 0 15px;
        }
        .article-header__tab-menu-button {
            bottom: 16px;
        }
    }
    @media screen and (max-width: 849.98px) {
        .article-header__tab-menu-button {
            display: block;
        }
        .article-header__tab-menu-container {
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
            -webkit-align-items: flex-end;
        }
        .tab-menu {
            height: 100%;
            overflow: hidden;
        }
    }
    @media screen and (max-width: 575.98px) {
        .article-header__tab-menu-container {
            padding: 0 30px;
        }
        .article-header__tab-menu-button--left {
            left: 5px;
        }
        .game__prize,
        .game__time {
            margin-left: 0;
        }
        .game {
            width: 100%;
        }
        .game__logo {
            margin: 0 10px 17px 0;
        }
        .game__title {
            margin: 0 0 10px;
            height: 23px;
        }
        .game__platforms {
            padding: 0 10px 10px;
        }
        .game__platform {
            margin: 4px 7px;
        }
        .remaining-time {
            margin: 0 0 10px;
        }
        .article-header {
            margin: 0 -30px;
            width: calc(100% + 60px);
        }
    }
    .article-header__registration-container {
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .article-header__tab-menu-container {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
        width: 100%;
        height: 40px;
        padding: 0 60px;
    }
    .article-header__tab-menu-button {
        position: absolute;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-position: 50%;
        background-image: url(#{$cdn}/img/arrow_top.svg);
        border: none;
        cursor: pointer;
        bottom: 22px;
        width: 20px;
        height: 12px;
        display: none;
    }
    .article-header__tab-menu-button--left {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        left: 30px;
    }
    .article-header__tab-menu-button--right {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        right: 30px;
    }
    /* .....................common create tornament class start................................... */
    .m-0--45 {
        margin: 0 -45px;
    }
    .m-0--30 {
        margin: 0 -30px;
    }
    .m-0--5 {
        margin: 0 -5px;
    }
    .m-0--25 {
        margin: 0 -25px;
    }
    .m-0-30 {
        margin: 0 30px;
    }
    .m-0--10 {
        margin: 0 -10px;
    }
    .m-l-15 {
        margin-left: 15px;
    }
    .m-l-0 {
        margin-left: 0;
    }
    .m-0--20 {
        margin: 0 -20px;
    }
    .m-0--31 {
        margin: 0 -31px;
    }
    .m-0--3 {
        margin: 0 -3px;
    }
    .m-0-15 {
        margin: 0 15px;
    }
    .p-r-0 {
        padding-right: 0px;
    }
    .p-r-15 {
        padding-right: 15px;
    }
    .p-r-20 {
        padding-right: 20px;
    }
    .p-r-25 {
        padding-right: 25px;
    }
    .p-r-16 {
        padding-right: 16px;
    }
    .p-r-30 {
        padding-right: 30px;
    }
    .p-r-35 {
        padding-right: 35px;
    }
    .p-r-5 {
        padding-right: 5px;
    }
    .p-l-5 {
        padding-left: 5px;
    }
    .p-l-7 {
        padding-left: 7px;
    }
    .m-r-5 {
        margin-right: 5px;
    }
    .m-r-30 {
        margin-right: 30px;
    }
    .m-r--30 {
        margin-right: -30px;
    }
    .m-l-5 {
        margin-left: 5px;
    }
    .p-0-10 {
        padding: 0 10px;
    }
    .p-0-20 {
        padding: 0 20px;
    }
    .p-0-3 {
        padding: 0 3px;
    }
    .p-0-25 {
        padding: 0 25px;
    }
    .p-0-45 {
        padding: 0 45px;
    }
    .m-r-15 {
        margin-right: 15px;
    }
    .p-l-0 {
        padding-left: 0px;
    }
    .p-l-1 {
        padding-left: 1px;
    }
    .p-l-20 {
        padding-left: 20px;
    }
    .p-l-25 {
        padding-left: 25px;
    }
    .m-40 {
        margin: 40px 0px;
    }
    .m-b-40 {
        margin-bottom: 40px;
    }
    .m-b-13 {
        margin-bottom: 13px;
    }
    .m-b-5 {
        margin-bottom: 5px;
    }
    .m-b-30 {
        margin-bottom: 30px;
    }
    .m-b-0 {
        margin-bottom: 0px;
    }
    .m-0-5 {
        margin: 0 5px;
    }
    .m-0-10 {
        margin: 0 10px;
    }
    .p-0 {
        padding: 0;
    }
    .w-200 {
        width: 200px;
    }
    .color-blue {
        color: #49c3fd;
    }
    .color-purple {
        color: #3c3daa;
    }
    .col-65 {
        width: 54%;
    }
    .hover-color-green:hover {
        color: #6cd000;
    }
    .opacity-5 {
        opacity: 0.5;
    }
    .z-index-5 {
        z-index: 5;
    }
    .z-index-10 {
        z-index: 10;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
    .crop__recommended-size {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        opacity: 0.7;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -ms-flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .crop__recommended-size-icon {
        position: relative;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: none;
        background: hsla(0, 0%, 100%, 0.2);
        opacity: 1;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
        margin-right: 10px;
    }
    .crop__recommended-size-icon:before {
        height: 2px;
        top: 3px;
    }
    .crop__recommended-size-icon:after,
    .crop__recommended-size-icon:before {
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        left: 7px;
        background: #fff;
    }
    .crop__recommended-size-icon:after {
        height: 6px;
        top: 7px;
    }
    .crop__file {
        display: none;
    }
    .drop--highlighted {
        border: 2px dashed #49c3fd;
    }
    .crop__drop-container {
        display: none;
        width: 100%;
    }
    .crop__drop-container--visible {
        display: block;
    }
    .drop__icon {
        width: 76px;
        height: 60px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/cloud.svg);
        margin-bottom: 21px;
        opacity: 0.5;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .drop--highlighted .drop__icon {
        opacity: 1;
    }
    .drop--highlighted .drop__text {
        color: #fff;
    }
    .drop__label {
        width: 221px;
        height: 40px;
    }
    .preview {
        position: relative;
        width: 100%;
        margin: 30px 0;
        display: none;
    }
    .preview--visible {
        display: block;
    }
    .preview__container {
        width: 100%;
        position: relative;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
    .preview__canvas,
    .preview__image {
        position: absolute;
        left: 0;
        top: 0;
    }
    .preview__canvas {
        z-index: 5;
        cursor: -webkit-grab;
        cursor: grab;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
    }
    .article .preview__zoom {
        -webkit-appearance: none;
        margin: 10px 0;
        width: 200px;
        overflow: hidden;
        height: 16px;
        background: #051534;
    }
    .adm .preview__zoom {
        -webkit-appearance: none;
        margin: 10px 0;
        width: 200px;
        overflow: hidden;
        height: 16px;
        background: #fff;
    }
    .preview__zoom:focus {
        outline: none;
    }
    .preview__zoom::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: #25324e;
        border-radius: 2px;
    }
    .preview__zoom::-webkit-slider-thumb {
        width: 14px;
        height: 14px;
        background: #fff;
        border: 1px solid #49c3fd;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
        -webkit-appearance: none;
        border-radius: 50%;
        position: relative;
        bottom: 5px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-box-shadow: 5px 0 0 -5px #49c3fd, 6px 0 0 -5px #49c3fd, 7px 0 0 -5px #49c3fd, 8px 0 0 -5px #49c3fd,
            9px 0 0 -5px #49c3fd, 10px 0 0 -5px #49c3fd, 11px 0 0 -5px #49c3fd, 12px 0 0 -5px #49c3fd,
            13px 0 0 -5px #49c3fd, 14px 0 0 -5px #49c3fd, 15px 0 0 -5px #49c3fd, 16px 0 0 -5px #49c3fd,
            17px 0 0 -5px #49c3fd, 18px 0 0 -5px #49c3fd, 19px 0 0 -5px #49c3fd, 20px 0 0 -5px #49c3fd,
            21px 0 0 -5px #49c3fd, 22px 0 0 -5px #49c3fd, 23px 0 0 -5px #49c3fd, 24px 0 0 -5px #49c3fd,
            25px 0 0 -5px #49c3fd, 26px 0 0 -5px #49c3fd, 27px 0 0 -5px #49c3fd, 28px 0 0 -5px #49c3fd,
            29px 0 0 -5px #49c3fd, 30px 0 0 -5px #49c3fd, 31px 0 0 -5px #49c3fd, 32px 0 0 -5px #49c3fd,
            33px 0 0 -5px #49c3fd, 34px 0 0 -5px #49c3fd, 35px 0 0 -5px #49c3fd, 36px 0 0 -5px #49c3fd,
            37px 0 0 -5px #49c3fd, 38px 0 0 -5px #49c3fd, 39px 0 0 -5px #49c3fd, 40px 0 0 -5px #49c3fd,
            41px 0 0 -5px #49c3fd, 42px 0 0 -5px #49c3fd, 43px 0 0 -5px #49c3fd, 44px 0 0 -5px #49c3fd,
            45px 0 0 -5px #49c3fd, 46px 0 0 -5px #49c3fd, 47px 0 0 -5px #49c3fd, 48px 0 0 -5px #49c3fd,
            49px 0 0 -5px #49c3fd, 50px 0 0 -5px #49c3fd, 51px 0 0 -5px #49c3fd, 52px 0 0 -5px #49c3fd,
            53px 0 0 -5px #49c3fd, 54px 0 0 -5px #49c3fd, 55px 0 0 -5px #49c3fd, 56px 0 0 -5px #49c3fd,
            57px 0 0 -5px #49c3fd, 58px 0 0 -5px #49c3fd, 59px 0 0 -5px #49c3fd, 60px 0 0 -5px #49c3fd,
            61px 0 0 -5px #49c3fd, 62px 0 0 -5px #49c3fd, 63px 0 0 -5px #49c3fd, 64px 0 0 -5px #49c3fd,
            65px 0 0 -5px #49c3fd, 66px 0 0 -5px #49c3fd, 67px 0 0 -5px #49c3fd, 68px 0 0 -5px #49c3fd,
            69px 0 0 -5px #49c3fd, 70px 0 0 -5px #49c3fd, 71px 0 0 -5px #49c3fd, 72px 0 0 -5px #49c3fd,
            73px 0 0 -5px #49c3fd, 74px 0 0 -5px #49c3fd, 75px 0 0 -5px #49c3fd, 76px 0 0 -5px #49c3fd,
            77px 0 0 -5px #49c3fd, 78px 0 0 -5px #49c3fd, 79px 0 0 -5px #49c3fd, 80px 0 0 -5px #49c3fd,
            81px 0 0 -5px #49c3fd, 82px 0 0 -5px #49c3fd, 83px 0 0 -5px #49c3fd, 84px 0 0 -5px #49c3fd,
            85px 0 0 -5px #49c3fd, 86px 0 0 -5px #49c3fd, 87px 0 0 -5px #49c3fd, 88px 0 0 -5px #49c3fd,
            89px 0 0 -5px #49c3fd, 90px 0 0 -5px #49c3fd, 91px 0 0 -5px #49c3fd, 92px 0 0 -5px #49c3fd,
            93px 0 0 -5px #49c3fd, 94px 0 0 -5px #49c3fd, 95px 0 0 -5px #49c3fd, 96px 0 0 -5px #49c3fd,
            97px 0 0 -5px #49c3fd, 98px 0 0 -5px #49c3fd, 99px 0 0 -5px #49c3fd, 100px 0 0 -5px #49c3fd,
            101px 0 0 -5px #49c3fd, 102px 0 0 -5px #49c3fd, 103px 0 0 -5px #49c3fd, 104px 0 0 -5px #49c3fd,
            105px 0 0 -5px #49c3fd, 106px 0 0 -5px #49c3fd, 107px 0 0 -5px #49c3fd, 108px 0 0 -5px #49c3fd,
            109px 0 0 -5px #49c3fd, 110px 0 0 -5px #49c3fd, 111px 0 0 -5px #49c3fd, 112px 0 0 -5px #49c3fd,
            113px 0 0 -5px #49c3fd, 114px 0 0 -5px #49c3fd, 115px 0 0 -5px #49c3fd, 116px 0 0 -5px #49c3fd,
            117px 0 0 -5px #49c3fd, 118px 0 0 -5px #49c3fd, 119px 0 0 -5px #49c3fd, 120px 0 0 -5px #49c3fd,
            121px 0 0 -5px #49c3fd, 122px 0 0 -5px #49c3fd, 123px 0 0 -5px #49c3fd, 124px 0 0 -5px #49c3fd,
            125px 0 0 -5px #49c3fd, 126px 0 0 -5px #49c3fd, 127px 0 0 -5px #49c3fd, 128px 0 0 -5px #49c3fd,
            129px 0 0 -5px #49c3fd, 130px 0 0 -5px #49c3fd, 131px 0 0 -5px #49c3fd, 132px 0 0 -5px #49c3fd,
            133px 0 0 -5px #49c3fd, 134px 0 0 -5px #49c3fd, 135px 0 0 -5px #49c3fd, 136px 0 0 -5px #49c3fd,
            137px 0 0 -5px #49c3fd, 138px 0 0 -5px #49c3fd, 139px 0 0 -5px #49c3fd, 140px 0 0 -5px #49c3fd,
            141px 0 0 -5px #49c3fd, 142px 0 0 -5px #49c3fd, 143px 0 0 -5px #49c3fd, 144px 0 0 -5px #49c3fd,
            145px 0 0 -5px #49c3fd, 146px 0 0 -5px #49c3fd, 147px 0 0 -5px #49c3fd, 148px 0 0 -5px #49c3fd,
            149px 0 0 -5px #49c3fd, 150px 0 0 -5px #49c3fd, 151px 0 0 -5px #49c3fd, 152px 0 0 -5px #49c3fd,
            153px 0 0 -5px #49c3fd, 154px 0 0 -5px #49c3fd, 155px 0 0 -5px #49c3fd, 156px 0 0 -5px #49c3fd,
            157px 0 0 -5px #49c3fd, 158px 0 0 -5px #49c3fd, 159px 0 0 -5px #49c3fd, 160px 0 0 -5px #49c3fd,
            161px 0 0 -5px #49c3fd, 162px 0 0 -5px #49c3fd, 163px 0 0 -5px #49c3fd, 164px 0 0 -5px #49c3fd,
            165px 0 0 -5px #49c3fd, 166px 0 0 -5px #49c3fd, 167px 0 0 -5px #49c3fd, 168px 0 0 -5px #49c3fd,
            169px 0 0 -5px #49c3fd, 170px 0 0 -5px #49c3fd, 171px 0 0 -5px #49c3fd, 172px 0 0 -5px #49c3fd,
            173px 0 0 -5px #49c3fd, 174px 0 0 -5px #49c3fd, 175px 0 0 -5px #49c3fd, 176px 0 0 -5px #49c3fd,
            177px 0 0 -5px #49c3fd, 178px 0 0 -5px #49c3fd, 179px 0 0 -5px #49c3fd, 180px 0 0 -5px #49c3fd,
            181px 0 0 -5px #49c3fd, 182px 0 0 -5px #49c3fd, 183px 0 0 -5px #49c3fd, 184px 0 0 -5px #49c3fd,
            185px 0 0 -5px #49c3fd, 186px 0 0 -5px #49c3fd, 187px 0 0 -5px #49c3fd, 188px 0 0 -5px #49c3fd,
            189px 0 0 -5px #49c3fd, 190px 0 0 -5px #49c3fd, 191px 0 0 -5px #49c3fd, 192px 0 0 -5px #49c3fd,
            193px 0 0 -5px #49c3fd, 194px 0 0 -5px #49c3fd, 195px 0 0 -5px #49c3fd, 196px 0 0 -5px #49c3fd,
            197px 0 0 -5px #49c3fd, 198px 0 0 -5px #49c3fd, 199px 0 0 -5px #49c3fd, 200px 0 0 -5px #49c3fd;
        box-shadow: 5px 0 0 -5px #49c3fd, 6px 0 0 -5px #49c3fd, 7px 0 0 -5px #49c3fd, 8px 0 0 -5px #49c3fd,
            9px 0 0 -5px #49c3fd, 10px 0 0 -5px #49c3fd, 11px 0 0 -5px #49c3fd, 12px 0 0 -5px #49c3fd,
            13px 0 0 -5px #49c3fd, 14px 0 0 -5px #49c3fd, 15px 0 0 -5px #49c3fd, 16px 0 0 -5px #49c3fd,
            17px 0 0 -5px #49c3fd, 18px 0 0 -5px #49c3fd, 19px 0 0 -5px #49c3fd, 20px 0 0 -5px #49c3fd,
            21px 0 0 -5px #49c3fd, 22px 0 0 -5px #49c3fd, 23px 0 0 -5px #49c3fd, 24px 0 0 -5px #49c3fd,
            25px 0 0 -5px #49c3fd, 26px 0 0 -5px #49c3fd, 27px 0 0 -5px #49c3fd, 28px 0 0 -5px #49c3fd,
            29px 0 0 -5px #49c3fd, 30px 0 0 -5px #49c3fd, 31px 0 0 -5px #49c3fd, 32px 0 0 -5px #49c3fd,
            33px 0 0 -5px #49c3fd, 34px 0 0 -5px #49c3fd, 35px 0 0 -5px #49c3fd, 36px 0 0 -5px #49c3fd,
            37px 0 0 -5px #49c3fd, 38px 0 0 -5px #49c3fd, 39px 0 0 -5px #49c3fd, 40px 0 0 -5px #49c3fd,
            41px 0 0 -5px #49c3fd, 42px 0 0 -5px #49c3fd, 43px 0 0 -5px #49c3fd, 44px 0 0 -5px #49c3fd,
            45px 0 0 -5px #49c3fd, 46px 0 0 -5px #49c3fd, 47px 0 0 -5px #49c3fd, 48px 0 0 -5px #49c3fd,
            49px 0 0 -5px #49c3fd, 50px 0 0 -5px #49c3fd, 51px 0 0 -5px #49c3fd, 52px 0 0 -5px #49c3fd,
            53px 0 0 -5px #49c3fd, 54px 0 0 -5px #49c3fd, 55px 0 0 -5px #49c3fd, 56px 0 0 -5px #49c3fd,
            57px 0 0 -5px #49c3fd, 58px 0 0 -5px #49c3fd, 59px 0 0 -5px #49c3fd, 60px 0 0 -5px #49c3fd,
            61px 0 0 -5px #49c3fd, 62px 0 0 -5px #49c3fd, 63px 0 0 -5px #49c3fd, 64px 0 0 -5px #49c3fd,
            65px 0 0 -5px #49c3fd, 66px 0 0 -5px #49c3fd, 67px 0 0 -5px #49c3fd, 68px 0 0 -5px #49c3fd,
            69px 0 0 -5px #49c3fd, 70px 0 0 -5px #49c3fd, 71px 0 0 -5px #49c3fd, 72px 0 0 -5px #49c3fd,
            73px 0 0 -5px #49c3fd, 74px 0 0 -5px #49c3fd, 75px 0 0 -5px #49c3fd, 76px 0 0 -5px #49c3fd,
            77px 0 0 -5px #49c3fd, 78px 0 0 -5px #49c3fd, 79px 0 0 -5px #49c3fd, 80px 0 0 -5px #49c3fd,
            81px 0 0 -5px #49c3fd, 82px 0 0 -5px #49c3fd, 83px 0 0 -5px #49c3fd, 84px 0 0 -5px #49c3fd,
            85px 0 0 -5px #49c3fd, 86px 0 0 -5px #49c3fd, 87px 0 0 -5px #49c3fd, 88px 0 0 -5px #49c3fd,
            89px 0 0 -5px #49c3fd, 90px 0 0 -5px #49c3fd, 91px 0 0 -5px #49c3fd, 92px 0 0 -5px #49c3fd,
            93px 0 0 -5px #49c3fd, 94px 0 0 -5px #49c3fd, 95px 0 0 -5px #49c3fd, 96px 0 0 -5px #49c3fd,
            97px 0 0 -5px #49c3fd, 98px 0 0 -5px #49c3fd, 99px 0 0 -5px #49c3fd, 100px 0 0 -5px #49c3fd,
            101px 0 0 -5px #49c3fd, 102px 0 0 -5px #49c3fd, 103px 0 0 -5px #49c3fd, 104px 0 0 -5px #49c3fd,
            105px 0 0 -5px #49c3fd, 106px 0 0 -5px #49c3fd, 107px 0 0 -5px #49c3fd, 108px 0 0 -5px #49c3fd,
            109px 0 0 -5px #49c3fd, 110px 0 0 -5px #49c3fd, 111px 0 0 -5px #49c3fd, 112px 0 0 -5px #49c3fd,
            113px 0 0 -5px #49c3fd, 114px 0 0 -5px #49c3fd, 115px 0 0 -5px #49c3fd, 116px 0 0 -5px #49c3fd,
            117px 0 0 -5px #49c3fd, 118px 0 0 -5px #49c3fd, 119px 0 0 -5px #49c3fd, 120px 0 0 -5px #49c3fd,
            121px 0 0 -5px #49c3fd, 122px 0 0 -5px #49c3fd, 123px 0 0 -5px #49c3fd, 124px 0 0 -5px #49c3fd,
            125px 0 0 -5px #49c3fd, 126px 0 0 -5px #49c3fd, 127px 0 0 -5px #49c3fd, 128px 0 0 -5px #49c3fd,
            129px 0 0 -5px #49c3fd, 130px 0 0 -5px #49c3fd, 131px 0 0 -5px #49c3fd, 132px 0 0 -5px #49c3fd,
            133px 0 0 -5px #49c3fd, 134px 0 0 -5px #49c3fd, 135px 0 0 -5px #49c3fd, 136px 0 0 -5px #49c3fd,
            137px 0 0 -5px #49c3fd, 138px 0 0 -5px #49c3fd, 139px 0 0 -5px #49c3fd, 140px 0 0 -5px #49c3fd,
            141px 0 0 -5px #49c3fd, 142px 0 0 -5px #49c3fd, 143px 0 0 -5px #49c3fd, 144px 0 0 -5px #49c3fd,
            145px 0 0 -5px #49c3fd, 146px 0 0 -5px #49c3fd, 147px 0 0 -5px #49c3fd, 148px 0 0 -5px #49c3fd,
            149px 0 0 -5px #49c3fd, 150px 0 0 -5px #49c3fd, 151px 0 0 -5px #49c3fd, 152px 0 0 -5px #49c3fd,
            153px 0 0 -5px #49c3fd, 154px 0 0 -5px #49c3fd, 155px 0 0 -5px #49c3fd, 156px 0 0 -5px #49c3fd,
            157px 0 0 -5px #49c3fd, 158px 0 0 -5px #49c3fd, 159px 0 0 -5px #49c3fd, 160px 0 0 -5px #49c3fd,
            161px 0 0 -5px #49c3fd, 162px 0 0 -5px #49c3fd, 163px 0 0 -5px #49c3fd, 164px 0 0 -5px #49c3fd,
            165px 0 0 -5px #49c3fd, 166px 0 0 -5px #49c3fd, 167px 0 0 -5px #49c3fd, 168px 0 0 -5px #49c3fd,
            169px 0 0 -5px #49c3fd, 170px 0 0 -5px #49c3fd, 171px 0 0 -5px #49c3fd, 172px 0 0 -5px #49c3fd,
            173px 0 0 -5px #49c3fd, 174px 0 0 -5px #49c3fd, 175px 0 0 -5px #49c3fd, 176px 0 0 -5px #49c3fd,
            177px 0 0 -5px #49c3fd, 178px 0 0 -5px #49c3fd, 179px 0 0 -5px #49c3fd, 180px 0 0 -5px #49c3fd,
            181px 0 0 -5px #49c3fd, 182px 0 0 -5px #49c3fd, 183px 0 0 -5px #49c3fd, 184px 0 0 -5px #49c3fd,
            185px 0 0 -5px #49c3fd, 186px 0 0 -5px #49c3fd, 187px 0 0 -5px #49c3fd, 188px 0 0 -5px #49c3fd,
            189px 0 0 -5px #49c3fd, 190px 0 0 -5px #49c3fd, 191px 0 0 -5px #49c3fd, 192px 0 0 -5px #49c3fd,
            193px 0 0 -5px #49c3fd, 194px 0 0 -5px #49c3fd, 195px 0 0 -5px #49c3fd, 196px 0 0 -5px #49c3fd,
            197px 0 0 -5px #49c3fd, 198px 0 0 -5px #49c3fd, 199px 0 0 -5px #49c3fd, 200px 0 0 -5px #49c3fd;
    }
    .preview__zoom::-moz-range-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: #25324e;
        border-radius: 2px;
    }
    .preview__zoom::-moz-range-thumb {
        width: 14px;
        height: 14px;
        background-color: #fff;
        border: 1px solid #49c3fd;
        box-sizing: border-box;
        cursor: pointer;
        -webkit-appearance: none;
        border-radius: 50%;
    }
    .preview__zoom::-moz-range-progress {
        height: 4px;
        background: #49c3fd;
        border: 0;
        margin-top: 0;
        border-radius: 2px;
    }
    .preview__zoom::-ms-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: rgba(0, 0, 0, 0);
        border-color: rgba(0, 0, 0, 0);
        border-width: 39px 0;
        color: rgba(0, 0, 0, 0);
    }
    .preview__zoom::-ms-fill-lower {
        height: 3px;
        cursor: pointer;
        background: #25324e;
        border-radius: 2px;
    }
    .preview__zoom::-ms-fill-upper {
        height: 4px;
        background: #49c3fd;
        border: 0;
        margin-top: 0;
        border-radius: 2px;
    }
    .preview__zoom::-ms-thumb {
        width: 14px;
        height: 14px;
        background: #fff;
        border: 1px solid #49c3fd;
        box-sizing: border-box;
        cursor: pointer;
        -webkit-appearance: none;
        border-radius: 50%;
    }
    .preview__button {
        width: 205px;
        height: 40px;
        margin-top: 30px;
    }
    .preview__reset {
        position: relative;
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 16px;
        color: #fff;
        opacity: 0.7;
        border: none;
        outline: none;
        cursor: pointer;
        background: rgba(0, 0, 0, 0);
        height: 30px;
        width: 100px;
        margin-top: 30px;
        text-align: right;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .preview__reset:hover {
        opacity: 1;
    }
    .preview__button:last-of-type {
        margin-left: 10px;
    }
    .preview__hint {
        position: absolute;
        width: 272px;
        height: 40px;
        background: #001030;
        opacity: 0.8;
        text-align: center;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 40px;
        color: #fff;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .preview__hint--hidden {
        opacity: 0;
    }
    .preview__second-hint {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
    }
    .article .select__title-ct {
        position: absolute;
        text-align: left;
        font-size: 14px;
        line-height: 40px;
        font-family: Burbank Small, sans-serif;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 0 20px;
        color: hsla(0, 0%, 100%, 0.5);
    }
    .select--opened .select__title-ct:before {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
    }
    .article__selects-container {
        position: relative;
        z-index: 1;
    }
    .article__selects-container--opened {
        z-index: 3;
    }
    .article__selects-popup-close {
        position: absolute;
        right: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0);
        width: 20px;
        height: 18px;
        background-size: 100% 100%;
        cursor: pointer;
        background-image: url(#{$cdn}/img/cross-white.svg);
    }
    .ql-toolbar {
        border: 0;
        height: 50px;
        background: #4b5a7b;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        overflow: auto;
        scrollbar-width: none;
    }
    .ql-toolbar::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }
    .ql-formats,
    .ql-toolbar-buttons {
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .ql-container {
        border: 0;
        height: calc(100% - 50px);
        min-height: 200px;
    }
    .ql-fill {
        fill: #fff;
    }
    .ql-fill,
    .ql-stroke {
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .ql-stroke {
        stroke: #fff;
    }
    button:hover .ql-fill {
        fill: #49c3fd;
    }
    button:hover .ql-stroke {
        stroke: #49c3fd;
    }
    .ql-picker-label {
        color: #fff;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
        font-family: BurbankSmall-Medium, sans-serif;
    }
    .ql-picker:hover .ql-picker-label {
        color: #49c3fd;
    }
    .ql-toolbar-buttons {
        border-right: 1px solid hsla(0, 0%, 100%, 0.2);
        padding-right: 12px;
        margin-right: 12px;
    }
    .ql-redo,
    .ql-undo {
        width: 8px;
        padding-top: 6px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .article-header__tab-menu-container {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
        width: 100%;
        height: 40px;
        padding: 0 60px;
    }
    .article-header__tab-menu-button {
        position: absolute;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-position: 50%;
        background-image: url(#{$cdn}/img/arrow_top.svg);
        border: none;
        cursor: pointer;
        bottom: 22px;
        width: 20px;
        height: 12px;
        display: none;
    }
    .article-header__tab-menu-button--left {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        left: 30px;
    }
    .article-header__tab-menu-button--right {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        right: 30px;
    }
    .tab-menu {
        position: relative;
        margin: 0;
        -ms-overflow-style: none;
    }
    .tab-menu::-webkit-scrollbar {
        display: none;
    }
    .tab-menu__link {
        position: relative;
        display: block;
        margin: 0 40px 0 0;
        padding: 0 0 13px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.05em;
        color: #fff;
        opacity: 0.8;
    }
    .tab-menu__link--notification:after {
        content: "";
        display: block;
        position: absolute;
        right: -7px;
        top: -7px;
        width: 5px;
        height: 5px;
        background: #49c3fd;
        opacity: 1;
        border-radius: 50%;
    }
    .tab-menu__link--active:hover {
        color: #49c3fd;
        opacity: 1;
    }
    .tab-menu__link:last-of-type {
        margin: 0;
    }
    .tab-menu__link--active {
        opacity: 1;
    }
    .article .tab-menu__link--active:before {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 2px;
        background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
        background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
        background: linear-gradient(90deg, #02b7fe, #0389fd);
    }
    .article .tab-menu__link:hover {
        text-decoration: none;
        color: #fff;
    }
    .article-header {
        margin: 0 -45px;
        position: relative;
    }
    .article-header__container {
        position: relative;
    }
    .article .article-header__header {
        font-family: BurbankSmall-Medium, sans-serif;
        color: #fff;
        font-size: 36px;
        line-height: 54px;
        font-weight: 700;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 80%;
        flex: 0 0 80%;
    }
    .article .article-header__platform {
        width: 110px;
        height: 30px;
        background-color: #091937;
        border-radius: 2px;
        margin: 2.5px 5px 2.5px 0;
        background-position: 50%;
        background-size: auto 40%;
        background-repeat: no-repeat;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .article-header__platform--cross-platform {
        background-image: url(#{$cdn}/img/cross_platform.svg);
    }
    .article-header__platform--xbox {
        background-image: url(#{$cdn}/img/xbox.svg);
    }
    .article-header__platform--ps {
        background-image: url(#{$cdn}/img/ps.svg);
    }
    .article-header__platform--pc {
        background-image: url(#{$cdn}/img/pc.svg);
    }
    .article-header__platform--nintendo {
        background-image: url(#{$cdn}/img/nintendo.svg);
    }
    .article-header__info {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        padding-right: 20px;
        height: 18px;
        text-transform: capitalize;
    }
    .article-header__info:before {
        content: "";
        display: inline-block;
        width: 21px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        background-repeat: no-repeat;
        background-position: 50%;
        -webkit-transform: translateY(3px);
        -ms-transform: translateY(3px);
        transform: translateY(3px);
        margin-right: 4px;
    }
    .article-header__info--date {
        color: #49c3fd;
    }
    .article-header__info--status {
        color: #6cd000;
    }
    .article-header__info--region {
        color: #fff;
    }
    .article-header__info--date:before {
        background-size: 15px 16px;
        background-image: url(#{$cdn}/img/blue_calendar.svg);
    }
    .article-header__info--status:before {
        background-size: 21px 14px;
        background-image: url(#{$cdn}/img/green_gamepad.svg);
    }
    .article-header__info--region:before {
        background-size: 12px 17px;
        background-image: url(#{$cdn}/img/location.svg);
    }
    .article-header__registration-container {
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .registration__info {
        padding: 20px 30px 14px;
        background: rgba(4, 21, 52, 0.9);
        border-radius: 2px;
    }
    .adm_content .registration__info,
    .adm .registration__info {
        padding: 20px 30px 14px;
        background: #fff;
        border-radius: 2px;
    }
    .registration__column {
        padding-right: 45px;
    }
    .registration__header {
        font-family: Burbank-Big-Cd-Bd, sans-serif;
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        -webkit-margin-before: 0;
        margin-block-start: 0;
        -webkit-margin-after: 0;
        margin-block-end: 0;
        margin-bottom: 7px;
    }
    .adm .registration__header,
    .adm .registration__time {
        font-style: normal;
        letter-spacing: 0.05em;
        color: #001030;
    }
    .registration__time {
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-size: 14px;
        line-height: 14px;
        opacity: 0.8;
        margin: 7px 0;
    }
    .registration__players-container {
        padding: 0 15px 0 45px;
        border-left: 1px solid hsla(0, 0%, 100%, 0.1);
    }
    .registration__players {
        font-family: Burbank-Big-Cd-Bd, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #49c3fd;
    }
    .article .registration__players span {
        color: #fff;
        font-size: 20px;
    }
    .adm .registration__players span {
        color: #001030;
        font-size: 20px;
    }
    .article .registration__players-text {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 13px;
        text-align: center;
        width: 100%;
        color: hsla(0, 0%, 100%, 0.8);
        margin-top: 5px;
    }
    .adm .registration__players-text {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 13px;
        text-align: center;
        width: 100%;
        color: #001030;
        margin-top: 5px;
    }
    .article .article-header__change-header {
        position: absolute;
        right: 30px;
        bottom: 20px;
        width: 152px;
        height: 30px;
        background: hsla(0, 0%, 100%, 0.2);
        border-radius: 2px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 30px;
        color: #fff;
        opacity: 0.5;
        padding: 0 0 0 34px;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
        text-decoration: none;
    }
    .article-header__change-header:hover {
        color: #fff;
        opacity: 0.7;
        text-decoration: none;
    }
    .article-header__change-header:before {
        position: absolute;
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/camera.svg);
        left: 15px;
        top: 9px;
    }
    .article__warning {
        position: relative;
        width: 100%;
        background: #ff5d20;
        border-radius: 2px;
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        padding: 10px 10px 10px 49px;
        margin-top: 30px;
    }
    .article__warning a {
        font-weight: 700;
        text-decoration: underline;
        color: #fff;
    }
    .article__warning a:hover {
        color: #fff;
    }
    .article-detail__container--details {
        padding: 15px 20px;
        margin-bottom: 10px;
    }
    .article-detail__container--organizer {
        padding: 25px 30px;
    }
    .article-detail__container--clans {
        padding: 20px 45px;
        margin-bottom: 20px;
    }
    .article-detail__container--additional-info,
    .article__detail-title {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 550;
    }
    .organizer__section {
        border-right: 1px solid hsla(0, 0%, 100%, 0.3);
        padding-right: 60px;
        margin-right: 15px;
    }
    .organizer__logo {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 21px;
    }
    .organizer__name {
        font-weight: 550;
        font-size: 18px;
        line-height: 18px;
        color: #fff;
    }
    .organizer__name,
    .organizer__question {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
    }
    .organizer__question {
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        color: #49c3fd;
        margin-top: 7px;
    }
    .organizer__question:hover {
        text-decoration: none;
        color: #49c3fd;
    }
    .organizer__info {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 25px;
        color: #fff;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 400;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .organizer__info:before {
        content: "";
        display: inline-block;
        position: relative;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        margin-right: 7px;
        opacity: 0.5;
    }
    .organizer__info--location:before {
        width: 9px;
        height: 11px;
        background-image: url(#{$cdn}/img/location.svg);
    }
    .organizer__info--mail:before {
        width: 9px;
        height: 6px;
        background-image: url(#{$cdn}/img/mail.svg);
    }
    .article__clan {
        margin-bottom: 10px;
    }
    .article__clan:last-of-type {
        margin-bottom: 0;
    }
    .article__clans-header {
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff;
        margin-bottom: 22px;
    }
    .article__clans-header,
    .article__clans-link {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
    }
    .article__clans-link {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #49c3fd;
        margin-left: 10px;
    }
    .article__clans-link:hover {
        color: #49c3fd;
    }
    .article__clan-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    .article__clan-title {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #fff;
        margin-left: 12px;
    }
    .article__clans-button {
        position: relative;
        width: 157px;
        height: 40px;
        padding: 0 0 0 55px;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        margin-right: -15px;
    }
    .article__clans-button:before {
        position: absolute;
        content: "";
        display: block;
        width: 14px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 14px 11px;
        background-image: url(#{$cdn}/img/chat.svg);
        left: 34px;
        top: 14px;
        background-repeat: no-repeat;
        background-position: 50%;
    }
    .article__location {
        padding: 30px;
    }
    .article__location-header {
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 12px;
    }
    .article__location-address,
    .article__location-header {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        color: #fff;
    }
    .article__location-address {
        font-size: 14px;
        line-height: 20px;
        opacity: 0.8;
        margin-bottom: 22px;
    }
    .article__location-map {
        position: relative;
        width: 100%;
        height: 220px;
    }
    .article .help {
        position: relative;
        padding: 10px 80px;
        margin: 10px 0 70px;
        background: #1e2c48;
        border-radius: 2px;
    }
    .adm .help {
        position: relative;
        padding: 10px 80px;
        margin: 10px 0 70px;
        background: #ffffff;
        border-radius: 2px;
    }
    .help__container {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .help__header {
        font-weight: 700;
        font-size: 30px;
        line-height: 46px;
        margin: 0;
        -webkit-margin-before: 0;
        margin-block-start: 0;
        -webkit-margin-after: 0;
        margin-block-end: 0;
    }
    .article .help__header,
    .article .help__text {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        color: #fff;
    }
    .help__text {
        font-size: 15px;
        line-height: 26px;
        margin: 20px 0 30px;
    }
    .help__text a {
        color: #49c3fd;
        text-decoration: none;
    }
    .help__text a:hover {
        color: #49c3fd;
        text-decoration: underline;
    }
    .help__button {
        width: 150px;
        height: 40px;
    }
    .participants {
        margin: 0 15px;
    }
    .table__column--button {
        width: 234px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .table__secondary-row-container {
        margin: 0;
    }
    .table__secondary-row {
        height: 60px;
    }
    .table__button.white-button {
        width: 158px;
        height: 36px;
        opacity: 0.5;
    }
    .table__button.white-button:hover {
        opacity: 1;
    }
    #cancellation .window {
        /* width: 579px; */
        padding: 40px 50px;
    }
    #cancellation .window__header {
        font-size: 22px;
        line-height: 22px;
    }
    #cancellation .window__big-subheader {
        font-size: 15px;
        line-height: 26px;
        margin-bottom: 15px;
    }
    .article-header {
        width: calc(100% + 90px);
        -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(100% + 90px);
        flex: 0 0 calc(100% + 90px);
        max-width: calc(100% + 90px);
    }
    .preview-container {
        padding: 0 45px;
    }
    .article::-webkit-scrollbar {
        width: 5px;
    }
    .article::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.05);
    }
    .article::-webkit-scrollbar-thumb:hover {
        background: hsla(0, 0%, 100%, 0.13);
    }
    .article-container {
        padding: 25px 50px 25px 100px;
        height: 100vh;
        overflow: hidden;
    }
    .article__slider-container {
        position: relative;
        width: 100%;
        -ms-flex-negative: 2;
        flex-shrink: 2;
        margin: 0 -15px;
        overflow: hidden;
    }
    .article .article__slides {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        position: relative;
        width: 600%;
    }
    .article__container {
        width: 100%;
        position: relative;
        padding-top: 10px;
        padding-right: 20px;
        -webkit-transition: 0ms;
        -o-transition: 0ms;
        transition: 0ms;
        -ms-flex-negative: 2;
        flex-shrink: 2;
    }
    .article__back {
        position: relative;
        display: block;
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        padding-left: 27px;
        margin-bottom: 50px;
    }
    .article__back,
    .article__back:hover {
        text-decoration: none;
        color: #fff;
    }
    .article__back:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 3px;
        width: 17px;
        height: 14px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/arrow_left.svg);
    }
    .article__cancel {
        width: 140px;
        height: 40px;
        margin-top: 5px;
    }
    .article__go-to-premium {
        width: 160px;
        height: 40px;
        margin-top: 5px;
        margin-right: 10px;
    }
    .article__go-to-premium:before {
        content: "";
        display: inline-block;
        margin-right: 8px;
        width: 15px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/white_crown.svg);
    }
    .article__width-mini {
        width: 86px;
        margin-right: 6px;
    }
    .article__width-0 {
        width: 116px;
        margin-right: 6px;
        margin-top: 21px;
    }
    .article__width-1 {
        width: 172px;
    }
    .article__width-2,
    .article__width-2 .react-datepicker__input-container {
        width: 360px;
        margin-right: 15px;
    }
    .article__width-3 {
        width: 750px;
        max-width: 100%;
        margin-right: 0;
    }
    .article__height-1,
    .article__height-1 .react-datepicker__input-container {
        height: 55px;
    }
    .article__height-custom-field {
        height: 62px;
    }
    .article__height-2 {
        height: 88px;
    }
    .article__height-3 {
        height: 200px;
        margin-bottom: 15px;
    }
    .article__height-4 {
        height: 220px;
        margin-bottom: 15px;
    }
    .article__input {
        padding-left: 20px;
    }
    .article__textarea {
        padding: 20px;
        resize: none;
    }
    .article__input-date input::-webkit-inner-spin-button,
    .article__input-date input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
        display: none;
    }
    .article__input-date input::-webkit-calendar-picker-indicator {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        width: 12px;
        height: 10px;
        z-index: 10;
        cursor: pointer;
        opacity: 0;
    }
    .article__input-date input::-webkit-clear-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        margin: 0;
    }
    .article__checkbox {
        display: none;
    }
    .article__label,
    .article__label-icon {
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .article__label-icon {
        fill: none;
        opacity: 0.4;
        margin-bottom: 12px;
    }
    .article__label-icon .path-fill-black {
        fill: #000;
        opacity: 0.1;
    }
    .article__label-icon .path-fill-white {
        fill: #fff;
        opacity: 0.4;
    }
    .article__label-icon .path-fill-dark-grey {
        fill: #7c8495;
        opacity: 1;
    }
    .article__label-icon .path-fill-light-grey {
        fill: #f2f2f2;
        opacity: 0.4;
    }
    .article__label-icon .path-fill-grey {
        fill: #b0b5bf;
        opacity: 1;
    }
    .article__label-icon .path-fill-light-grey-2 {
        fill: #727989;
        opacity: 1;
    }
    .article__label-icon .path-fill-white-2 {
        fill: #d6d9df;
        opacity: 1;
    }
    .article__label-icon .path-fill-dark-white-2 {
        fill: #b0b5bf;
        opacity: 1;
    }
    .article__label-icon .path-fill-dark-white-3 {
        fill: #9aa0ac;
        opacity: 1;
    }
    .article__label-icon .path-fill-grey-3 {
        fill: #c6c9ce;
        opacity: 1;
    }
    .article__label-icon .path-fill-white-3 {
        fill: #e2e4e8;
        opacity: 1;
    }
    .article__label-blocked,
    .article__label-text {
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .article__label-blocked {
        position: absolute;
        display: block;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        background-color: hsla(0, 0%, 100%, 0.2);
        border-radius: 50%;
        background-size: 8px 11px;
        background-image: url(#{$cdn}/img/white_lock.svg);
        background-position: 50%;
        background-repeat: no-repeat;
    }
    .article__label-blocked-container {
        z-index: 999;
        display: block;
        position: absolute;
        left: 50%;
        bottom: 20px;
        width: 200px;
        padding-bottom: 7px;
        -webkit-transform: translate(-85%);
        -ms-transform: translate(-85%);
        transform: translate(-85%);
        opacity: 0;
    }
    .article__label:hover .article__label-blocked-container {
        -webkit-animation: show 0.15s ease-in-out;
        animation: show 0.15s ease-in-out;
        opacity: 1;
    }
    .article__label:hover .article__label-blocked {
        background-color: #fff;
        background-image: url(#{$cdn}/img/purple_lock.svg);
    }
    .adm .article .article__label-blocked-content {
        display: block;
        width: 100%;
        padding: 10px 0;
        text-align: center;
        background: #fff;
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #001030;
        position: relative;
        border-radius: 2px;
    }
    .chat-field__button {
        width: 15px;
        height: 15px;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        outline: none;
        cursor: pointer;
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/send.svg);
        margin-top: 18px;
        margin-left: 10px;
    }
    .article .article__label-blocked-content:before {
        content: "";
        display: block;
        position: absolute;
        left: 85%;
        bottom: -11px;
        width: 0;
        height: 0;
        border: 8px solid rgba(0, 0, 0, 0);
        border-top: 4px solid #fff;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }
    .article__blocked-link,
    .article__blocked-link:hover {
        color: #3c3daa;
        text-decoration: underline;
    }
    #cash-prize:checked ~ .row .article__label--cash-prize .article__label-icon,
    #live:checked ~ .row .article__label--live .article__label-icon,
    #merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-icon,
    #online:checked ~ .row .article__label--online .article__label-icon,
    .article__checkbox:checked + .article__label .article__label-icon {
        opacity: 1;
    }
    #live:checked ~ .row .article__label--live .article__label-icon .path-fill-black {
        fill: #000;
        opacity: 0.1;
    }
    #live:checked ~ .row .article__label--live .article__label-icon .path-fill-white {
        fill: #fff;
        opacity: 0.4;
    }
    #merchandise-prize:checked
        ~ .row
        .article__label--merchandise-prize
        .article__label-icon
        .path-fill-dark-grey--last {
        fill: #4eb7ed;
        opacity: 1;
    }
    #live:checked ~ .row .article__label--live .article__label-icon .path-fill-light-grey {
        fill: #f2f2f2;
        opacity: 0.4;
    }
    #merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-icon .path-fill-grey-3 {
        fill: #f7f8ff;
        opacity: 1;
    }
    #online:checked ~ .row .article__label--online .article__label-icon .path-fill-grey-2,
    #online:checked ~ .row .article__label--online .article__label-icon .path-fill-light-grey-2 {
        fill: #6acafc;
        opacity: 1;
    }
    #online:checked ~ .row .article__label--online .article__label-icon .path-fill-white-2 {
        fill: #f7f8ff;
        opacity: 1;
    }
    #online:checked ~ .row .article__label--online .article__label-icon .path-fill-dark-white-2 {
        fill: #e0f4ff;
        opacity: 1;
    }
    .article__cash-prize-container,
    .article__live-event-container,
    .article__merchandise-prize-container,
    .article__online-event-container,
    .article__repeat-every-day-container,
    .article__repeat-every-hour-container {
        display: none;
    }
    .structure {
        position: relative;
        height: 100px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -ms-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-justify-content: center;
        margin-bottom: 23px;
        opacity: 0.4;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .structure__width-1 {
        width: 53px;
        margin-bottom: 4px;
        padding: 5px;
    }
    .structure__width-2 {
        width: 70px;
        margin-bottom: 7px;
        padding: 8px 7px;
    }
    .structure__container {
        display: block;
        background: #fff;
        border-radius: 4px;
    }
    .structure__container:last-of-type {
        margin-bottom: 0;
    }
    .structure__player {
        display: block;
        background: #e1e5fd;
        border-radius: 46px;
    }
    .structure__player--blue {
        background: #49c3fd;
        opacity: 0.5;
    }
    .structure__player--green {
        background: #b2e480;
    }
    .structure__player--purple {
        background: #3c3daa;
        opacity: 0.5;
    }
    /* ..................................menu css start............................... */
    .col-main-content-max-width.article,
    .article {
        background: #051534;
    }
    .round-border {
        border: 1px solid #dee2e6;
        border-radius: 5px;
    }
    @-webkit-keyframes show {
        0% {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .user-online {
        float: right;
        padding-top: 15px;
        padding-right: 6px;
    }
    .chat-header-disable {
        opacity: 0.7;
        cursor: pointer;
    }
    .chat-header__header-container {
        padding: 0 20px;
    }
    .chat-header__search-container {
        padding: 0 5px;
    }
    .chat-header__menu-container {
        padding: 0 10px 0 5px;
    }
    .chat-header__header {
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #fff;
        margin-bottom: 0;
        padding: 7px;
    }
    .chat-header__search {
        width: 15px;
        height: 15px;
        background-color: rgba(0, 0, 0, 0);
        cursor: pointer;
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/search.svg);
    }
    .chat-header__checkbox {
        display: none;
    }
    .chat-header__menu-button {
        display: block;
        width: 14px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0);
        cursor: pointer;
        background-size: 4px 20px;
        background-repeat: no-repeat;
        background-position: 50%;
        background-image: url(#{$cdn}/img/chat_menu.svg);
    }
    .chat-menu {
        position: absolute;
        right: 15px;
        top: 27px;
        background: #fff;
        width: 113px;
        border: 1px solid #e2f0ff;
        -webkit-box-shadow: 0 1px 10px hsla(0, 0%, 62.4%, 0.07);
        box-shadow: 0 1px 10px hsla(0, 0%, 62.4%, 0.07);
        border-radius: 4px;
        display: none;
    }
    .chat-header__checkbox:checked ~ .chat-menu {
        display: block;
    }
    .chat-menu__link__close {
        margin: 4px 7px 10px 0px;
        color: #fff;
        opacity: 0.7;
        text-decoration: none;
    }
    .chat-menu__link__close:hover {
        opacity: 1;
        color: white;
        text-decoration: none;
    }
    .chat-menu__link {
        display: block;
        height: 39px;
        text-decoration: none;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 39px;
        color: #001030;
        padding-left: 19px;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .chat-menu__link:hover {
        text-decoration: none;
        color: #001030;
        background: rgba(238, 246, 255, 0.6);
    }
    .chat-header__back {
        width: 8px;
        height: 20px;
        background-size: 8px 14px;
        background-position: 0;
        background-color: rgba(0, 0, 0, 0);
        background-image: url(#{$cdn}/img/arrow_left-2.svg);
        margin-left: 15px;
    }
    .chat-header__accept,
    .chat-header__back {
        display: block;
        background-repeat: no-repeat;
    }
    .chat-header__accept {
        width: 28px;
        height: 28px;
        background-color: #001030;
        opacity: 0.4;
        border-radius: 50%;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
        background-size: 13px 11px;
        background-position: 50%;
        background-image: url(#{$cdn}/img/ok.svg);
        margin-right: 15px;
    }
    .chat {
        position: relative;
        width: 100%;
        height: calc(100% - 60px);
        overflow: auto;
        background: #eef6ff;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .chat--chat {
        height: calc(100% - 110px);
    }
    .chat::-webkit-scrollbar {
        display: none;
    }
    .chat__person-container {
        height: 60px;
        width: 100%;
        border-bottom: 1px solid #e2f0ff;
        position: relative;
    }
    .chat__person-container--create-chat {
        border-bottom: 1px solid #e0e8f1;
    }
    .chat__person-picture {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-left: 5px;
        margin-right: 12px;
    }
    .chat__person-picture--group {
        background: #028ffd;
        color: #fff;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
    }
    .chat__person-name,
    .chat__person-picture--group {
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
    }
    .chat__person-name {
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.03em;
        color: #028ffd;
        margin-bottom: 4px;
    }
    .chat__person-container--create-chat .chat__person-name {
        margin-bottom: 0;
        color: #001030;
    }
    .chat__person-message {
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: 0.03em;
        color: #001030;
    }
    .chat__person-message--mine {
        opacity: 0.5;
    }
    .chat__person-date {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 10px;
        color: #aeb7bf;
        margin-top: 18px;
    }
    .chat__person-checkbox {
        display: none;
    }
    .chat__person-label {
        width: 15px;
        height: 15px;
        border: 1px solid #cedae5;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 2px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0);
        background-size: 10px 8px;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    .chat__person-checkbox:checked ~ .chat__person-label {
        background-color: #1f9cfd;
        background-image: url(#{$cdn}/img/ok.svg);
        border: 0 solid #cedae5;
    }
    .chat__person-status {
        position: absolute;
        right: 9px;
        bottom: 14px;
        width: 8px;
        height: 8px;
        background: #cdcdcd;
        border: 1px solid #eef6ff;
        border-radius: 50%;
    }
    .chat__member .chat__person-status {
        bottom: 9px;
    }
    .chat__person-status--online {
        background: #6cd000;
    }
    .chat__header {
        letter-spacing: 0.03em;
        margin: 15px 0 10px;
    }
    .chat__header,
    .chat__input {
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        color: #001030;
    }
    .chat__input {
        width: 270px;
        height: 36px;
        background: #dee8f2;
        border-radius: 4px;
        letter-spacing: 0.05em;
        border: none;
        outline: none;
        padding: 0 0 0 16px;
    }
    .chat__input-search-container {
        position: relative;
    }
    .chat__input-search-container:before {
        content: "";
        position: absolute;
        display: block;
        width: 11px;
        height: 11px;
        opacity: 0.5;
        top: 12px;
        right: 16px;
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/grey_search.svg);
    }
    .chat-header__container {
        border-top: 1px solid #e0e8f1;
    }
    .chat__person-link {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .chat-header__profile-picture {
        width: 34px;
        height: 34px;
        border-radius: 50%;
    }
    .chat-header__profile-picture--group {
        background: #fff;
        color: #028ffd;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 18px;
        line-height: 37px;
        text-align: center;
    }
    .chat-container--dispute .chat-header__profile-picture--group {
        color: #3c3daa;
    }
    .chat-header__username {
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.03em;
        color: #fff;
        margin-bottom: 6px;
    }
    .chat-header__status {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #fff;
        opacity: 0.8;
    }
    .chat__message-container {
        margin-top: 20px;
    }
    .chat__message-container:last-of-type {
        margin-bottom: 20px;
    }
    .chat__message-profile-picture {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin-top: 5px;
    }

    .add-participent-checkbox {
        width: 17px;
        height: 17px;
        margin-top: 8px;
        float: right;
    }

    .remove-padding {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .chat__message {
        white-space: pre-wrap;
        background: #fff;
        border: 1px solid #e2f0ff;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #001030;
        padding: 9px 10px;
        max-width: calc(100% - 30px);
        word-wrap: break-word;
    }
    .chat__message-username {
        line-height: 14px;
        margin-bottom: 2px;
        color: #3c3daa;
    }
    .chat__message-date,
    .chat__message-username {
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
    }
    .chat__message-date {
        line-height: 12px;
        color: #aeb7bf;
        margin-top: 6px;
    }
    .chat__message-container--own .chat__message {
        background: #028ffd;
        color: #fff;
    }
    .chat-container--dispute .chat__message-container--own .chat__message {
        background: #3c3daa;
    }
    .chat-field {
        height: 50px;
        background: #fff;
    }
    .chat-block,
    .chat-field {
        width: 100%;
        position: relative;
    }
    .chat-block {
        height: 70px;
        margin-top: -20px;
        background: #eef6ff;
    }
    .chat-field__textarea {
        border: none;
        outline: none;
        background: rgba(0, 0, 0, 0);
        height: 50px;
        width: 100%;
        -ms-overflow-style: none;
        scrollbar-width: none;
        resize: none;
        padding-top: 17px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #001030;
        opacity: 0.8;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    .chat-field__textarea::-webkit-scrollbar {
        display: none;
    }
    .chat__message-typing-container {
        position: relative;
        width: 34px;
        height: 18px;
    }
    .chat__message-typing-container div {
        display: block;
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        top: 5px;
        background: #a7cffc;
        border-radius: 50%;
        -webkit-animation: chatDots 3s linear infinite;
        animation: chatDots 3s linear infinite;
        -webkit-transform: scale(0.3);
        -ms-transform: scale(0.3);
        transform: scale(0.3);
    }
    .chat__message-typing-container div:first-of-type {
        left: 2px;
    }
    .chat__message-typing-container div:nth-of-type(2) {
        left: 13px;
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    .chat__message-typing-container div:nth-of-type(3) {
        right: 2px;
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }
    .chat-block__message {
        position: relative;
        width: 100%;
        height: 40px;
        background: rgba(255, 59, 76, 0.1);
        border-radius: 2px;
        margin: 15px 0;
        padding-left: 41px;
        padding-top: 14px;
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        color: #e13b4c;
    }
    .chat-block__message:before {
        content: "";
        display: block;
        position: absolute;
        top: 12px;
        left: 15px;
        width: 16px;
        height: 16px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/blocked.svg);
    }
    .chat__subheader {
        margin: 15px 0 5px;
    }
    .chat__subheader,
    .chat__transparent-subheader {
        font-family: Burbank-Big-Rg-Md, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.03em;
        color: #001030;
    }
    .chat__transparent-subheader {
        margin: 15px 0 0;
        opacity: 0.4;
    }
    .chat__hr {
        width: 100%;
        height: 1px;
        margin: 20px 0 0;
        background: #e0e8f1;
    }
    .chat__member {
        position: relative;
        height: 50px;
        width: 100%;
        border-bottom: 1px solid #e0e8f1;
    }
    .chat__typing-dots {
        position: relative;
        margin-left: 4px;
    }
    @-webkit-keyframes chatDots {
        0% {
            -webkit-transform: scale(0.3);
            transform: scale(0.3);
        }
        10% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        30% {
            -webkit-transform: scale(0.3);
            transform: scale(0.3);
        }
        to {
            -webkit-transform: scale(0.3);
            transform: scale(0.3);
        }
    }
    @keyframes chatDots {
        0% {
            -webkit-transform: scale(0.3);
            transform: scale(0.3);
        }
        10% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        30% {
            -webkit-transform: scale(0.3);
            transform: scale(0.3);
        }
        to {
            -webkit-transform: scale(0.3);
            transform: scale(0.3);
        }
    }
    .chat__typing-dots div {
        width: 6px;
        height: 6px;
        background: #001030;
        opacity: 0.5;
        margin-right: 2px;
        -webkit-animation: chatDots 3s linear infinite;
        animation: chatDots 3s linear infinite;
        border-radius: 50%;
        -webkit-transform: scale(0.3);
        -ms-transform: scale(0.3);
        transform: scale(0.3);
    }
    .chat__typing-dots--white div {
        background: #fff;
    }
    .chat__typing-dots div:nth-of-type(2) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    .chat__typing-dots div:nth-of-type(3) {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }
    [class*=" icon-"]:before,
    [class^="icon-"]:before {
        font-family: kombatlink-icon, monospace;
        font-style: normal;
        font-weight: 400;
        speak-as: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .icon-nav-tournaments:before {
        content: "\e812";
    }
    .icon-nav-challenges:before {
        content: "\e80e";
    }
    .icon-nav-buy_sell:before {
        content: "\e80d";
    }
    .icon-nav-store:before {
        content: "\e811";
    }
    .icon-nav-news:before {
        content: "\e818";
    }
    .icon-nav-leaderboard:before {
        content: "\e823";
    }
    .icon-nav-results,
    .icon-nav-tickets {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    .icon-nav-results:before,
    .icon-nav-tickets:before {
        content: "";
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-color: rgba(0, 0, 0, 0);
        -webkit-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
    }
    .icon-nav-results {
        height: 26px;
    }
    .icon-nav-results:before {
        background-image: url(#{$cdn}/img/results.svg);
    }
    .icon-nav-tickets {
        height: 22px;
    }
    .icon-nav-tickets:before {
        background-image: url(#{$cdn}/img/tickets.svg);
    }
    @keyframes show {
        0% {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    label {
        margin-bottom: 0;
    }
    .header {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 82px;
        padding: 0 40px;
        background: -webkit-gradient(linear, left top, right top, from(#001030), to(#061235));
        background: -o-linear-gradient(left, #001030 0, #061235 100%);
        background: linear-gradient(90deg, #001030, #061235);
    }
    header.header.header--guest {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 82px;
        padding: 0px;
        background: -webkit-gradient(linear, left top, right top, from(#001030), to(#061235));
        background: -o-linear-gradient(left, #001030 0, #061235 100%);
        background: linear-gradient(90deg, #001030, #061235);
    }
    .header__logo {
        width: 170px;
        height: 33px;
        background-size: 100% 100%;
        background-color: rgba(0, 0, 0, 0);
        background-image: url(#{$cdn}/img/logo.png);
        transform: scale(1.3);
        margin-left: 45px;
    }
    .header--guest .header__logo {
        width: 140px;
        height: 30px;
        background-size: 100% 100%;
        background-color: rgba(0, 0, 0, 0);
        background-image: url(#{$cdn}/img/logo.png);
        transform: scale(1.3);
        margin-left: 25px;
    }
    .h-100 {
        height: 100%;
    }
    .header__link {
        width: 100px;
        height: 40px;
    }
    .header__link.blue-button--filled {
        margin: 0 0 0 4px;
    }
    .header__create-challenge {
        width: 160px;
        height: 40px;
        margin: 5px 25px 5px 0;
    }
    .header__create-tourney {
        width: 160px;
        height: 40px;
        margin: 5px 25px 5px 0;
    }
    .header__create-tourney:before {
        content: "";
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-right: 7px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/white_add.svg);
    }
    .header__section {
        padding: 0 25px;
        border-right: 1px solid hsla(0, 0%, 100%, 0.1);
    }
    .header__section:last-of-type {
        height: 82px;
        border: none;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
        position: relative;
    }
    .header__info {
        position: relative;
        width: 50px;
        height: 50px;
        margin: 0 5px;
    }
    .header__info,
    .header__info-background {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-justify-content: center;
    }
    .header__info-background {
        width: 30px;
        height: 30px;
        border-radius: 2px;
        background-color: #0389fd;
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border: 2px solid #061235;
        -webkit-box-shadow: 0 0 0 2px #0389fd;
        box-shadow: 0 0 0 2px #0389fd;
    }
    .header__info-background--likes:after {
        content: "\e80b";
    }
    .header__info-background--money:after {
        content: url(#{$cdn}/img/total_tournament_header.png);
    }
    .header__info-background--stars:after {
        content: "\e815";
    }
    .header__info-background--tournaments:after {
        content: "\e812";
    }
    .header__info-background--revenue:after {
        content: "";
        width: 18px;
        height: 13px;
        bottom: 1px;
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/revenue.png);
    }
    .header__info-background--members:after {
        content: "";
        width: 18px;
        height: 15px;
        bottom: 2px;
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/members.png);
    }
    .header__info-background:after {
        display: block;
        position: relative;
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        color: #fff;
        font-size: 14px;
        line-height: 14px;
        font-family: kombatlink-icon, monospace;
    }
    .header__info-background--likes,
    .header__info-background--revenue {
        background-color: #e13b4c;
        -webkit-box-shadow: 0 0 0 2px #e13b4c;
        box-shadow: 0 0 0 2px #e13b4c;
    }
    .header__info-background--members,
    .header__info-background--stars {
        background-color: #ec5418;
        -webkit-box-shadow: 0 0 0 2px #ec5418;
        box-shadow: 0 0 0 2px #ec5418;
    }
    .header__notification {
        position: relative;
        width: 44px;
        height: 34px;
        margin: 8px 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-justify-content: center;
        text-decoration: none;
    }
    .header__notification:hover {
        text-decoration: none;
    }
    .header__notification:after {
        position: relative;
        display: block;
        width: 24px;
        height: 24px;
        color: #fff;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        font-family: kombatlink-icon, monospace;
    }
    .header__notification--email:after {
        content: "\e805";
    }
    .header__notification--notification:after {
        content: "\f0f3";
    }
    .header__notification--friends:after {
        content: "\e806";
    }
    .header__notification--friends1:after {
        content: "\e806";
    }
    .header__section--friends1 {
        display: none;
    }
    @media screen and (max-width: 1199px) {
        #chat-window {
            right: 0px;
        }
    }
    @media screen and (max-width: 1200px) and (min-width: 1201px) {
        #chat-window {
            right: 255px;
        }
        .article-header__container {
            margin: 1em;
            position: relative;
        }
    }
    .header__notification-text {
        background: #0389fd;
        font-weight: 700;
        top: 0;
        z-index: 2;
    }
    .header__info-text,
    .header__notification-text {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-justify-content: center;
        color: #fff;
        padding: 0 6px;
        border-radius: 9px;
        height: 18px;
        width: auto;
        font-size: 12px;
        line-height: 12px;
        font-family: BurbankSmall-Medium, sans-serif;
        right: 0;
    }
    .header__info-text {
        background: #071948;
        font-weight: 400;
        bottom: 0;
    }
    .header__profile-picture {
        width: 40px;
        height: 40px;
        border: 2px solid #6cd000;
        border-radius: 2px;
        margin: 5px 0px;
    }
    .header__profile-name {
        height: 40px;
        margin: 5px;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        font-family: BurbankSmall-Medium, sans-serif;
        font-weight: 700;
    }
    .header__profile-name1 {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        font-family: BurbankSmall-Medium, sans-serif;
        font-weight: 700;
    }
    .chaticon::after {
        content: "\e801";
        font-family: "kombatlink-icon";
        color: rgba(255, 255, 255, 0.25);
        font-size: 18px;
        float: right;
        position: absolute;
        right: 15px;
        top: 15px;
        transition: 0.3s all;
    }
    .header__profile-rank-red {
        color: rgba(255, 255, 255, 0.288);
        font-size: 12px;
        font-family: BurbankSmall-Light, sans-serif;
        letter-spacing: 0;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .header__profile-rank {
        color: #53d600;
        font-size: 12px;
        font-family: BurbankSmall-Light, sans-serif;
        letter-spacing: 0;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .header__profile-rank--organizer {
        color: orange;
    }
    .header__profile-rank--organizer {
        color: orange;
    }
    .header__checkbox {
        display: none;
    }
    .header__show-profile {
        display: block;
        width: 32px;
        height: 28px;
        margin: 0 0 0 10px;
        border: 1px solid hsla(0, 0%, 100%, 0.1);
        color: #fff;
        font-family: kombatlink-icon, monospace;
        text-align: center;
        font-size: 14px;
        line-height: 28px;
        background-color: rgba(0, 0, 0, 0);
        outline: none;
        cursor: pointer;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .registration__join span {
        display: block;
        font-family: BurbankSmall-Medium, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 13px;
        width: 100%;
        margin-bottom: 5px;
    }
    .registration__join:before {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 21px;
        left: 14px;
        top: 0;
        font-size: 18px;
        line-height: 70px;
        font-family: kombatlink-icon, monospace;
        color: #fff;
        opacity: 0.6;
        -webkit-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
    }
    .registration__join:hover:before {
        opacity: 1;
    }
    .registration__join--credits:before {
        content: "\e803";
    }
    .registration__join--points:before {
        content: "\e80b";
    }
    .profile-dropdown {
        position: absolute;
        right: 0;
        top: 82px;
        width: 240px;
        min-width: 100%;
        z-index: 999;
        background: #02b7fe -o-linear-gradient(right, #0389fd 0, #02b7fe 240px);
        background: #02b7fe linear-gradient(270deg, #0389fd 0, #02b7fe 240px);
        opacity: 0;
        display: none;
        padding: 10px 20px;
    }
    .profile-dropdown__section {
        height: 40px;
        border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
        text-decoration: none;
        color: #fff;
    }
    .profile-dropdown__section:last-of-type {
        border: none;
    }
    .profile-dropdown__section:hover {
        text-decoration: none;
        color: #fff;
    }
    .profile-dropdown__icon {
        opacity: 0.6;
        font-size: 16px;
        line-height: 16px;
        width: 20px;
        margin-right: 20px;
        font-family: kombatlink-icon, monospace;
        color: #fff;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .profile-dropdown__icon:before {
        position: relative;
    }
    .profile-dropdown__icon--account:before {
        content: "\e804";
    }
    .profile-dropdown__icon--account-organizer:before {
        content: "\e812";
    }
    .profile-dropdown__icon--battles:before {
        content: "\e817";
    }
    .profile-dropdown__icon--events {
        background-image: url(#{$cdn}/img/dropdown-events.svg);
    }
    .profile-dropdown__icon--events,
    .profile-dropdown__icon--scan {
        height: 15px;
        background-size: 15px 15px;
        background-position: 0;
        background-repeat: no-repeat;
    }
    .profile-dropdown__icon--scan {
        background-image: url(#{$cdn}/img/dropdown-qr.svg);
    }
    .profile-dropdown__icon--billing {
        height: 11px;
        background-size: 18px 11px;
        background-position: 0;
        background-repeat: no-repeat;
        background-image: url(#{$cdn}/img/dropdown-billing.svg);
    }
    .profile-dropdown__icon--clan:before,
    .profile-dropdown__icon--members:before {
        content: "\e80e";
        bottom: 1px;
        font-size: 17px;
    }
    .profile-dropdown__icon--disputes:before {
        content: "\f0f3";
    }
    .profile-dropdown__icon--buy-sell:before {
        content: "\e80d";
        bottom: 1px;
    }
    .profile-dropdown__icon--transactions:before {
        content: "\e809";
        bottom: 1px;
    }
    .profile-dropdown__icon--directory:before {
        content: "\e824";
        bottom: 1px;
    }
    .profile-dropdown__icon--messages:before {
        content: "\e808";
        bottom: 1px;
    }
    .profile-dropdown__icon--logout:before {
        content: "\e807";
        font-size: 15px;
    }
    .profile-dropdown__text {
        font-size: 16px;
        line-height: 16px;
        margin-right: 10px;
        font-family: BurbankSmall-Medium, sans-serif;
        font-weight: 700;
        color: #fff;
    }
    .profile-dropdown__section:hover .profile-dropdown__icon {
        opacity: 1;
    }
    .header__checkbox:checked + .header__section {
        background: #02b7fe -o-linear-gradient(right, #0389fd 0, #02b7fe 240px);
        background: #02b7fe linear-gradient(270deg, #0389fd 0, #02b7fe 240px);
    }
    .header__checkbox:checked + .header__section .header__show-profile {
        background: #fff;
        color: #061235;
    }
    .header__checkbox:checked + .header__section .header__profile-rank {
        color: #fff;
    }
    .header__checkbox:checked + .header__section .profile-dropdown {
        display: block;
        opacity: 1;
        -webkit-animation: show 0.15s ease-in-out;
        animation: show 0.15s ease-in-out;
    }
    .header__show-profile:before {
        content: "\e816";
    }
    .left-menu {
        position: fixed;
        left: 0;
        top: 82px;
        width: 120px;
        background-color: #010f2d;
        min-height: calc(100vh - 82px);
        z-index: 900;
        overflow: auto;
        scrollbar-width: none;
    }
    .left-menu::-webkit-scrollbar {
        display: none;
        width: 0;
    }
    nav ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: auto;
    }
    nav ul,
    nav ul li {
        width: 100%;
        float: left;
        display: block;
    }
    nav ul li {
        height: 84px;
        text-align: center;
        border-bottom: 1px solid hsla(0, 0%, 100%, 0.06);
        position: relative;
    }
    nav ul li.active {
        background-image: -o-linear-gradient(66deg, #07c5ff 4%, #0393fe 100%);
        background-image: -o-linear-gradient(294deg, #07c5ff 4%, #0393fe 100%);
        background-image: linear-gradient(156deg, #07c5ff 4%, #0393fe);
        border-bottom: none;
    }
    nav ul li.active i,
    nav ul li.active span {
        color: #fff;
    }
    nav ul li.active i.icon-nav-results:before,
    nav ul li.active span.icon-nav-results:before {
        background-image: url(#{$cdn}/img/white_results.svg);
    }
    nav ul li.active i.icon-nav-tickets:before,
    nav ul li.active span.icon-nav-tickets:before {
        background-image: url(#{$cdn}/img/white_tickets.svg);
    }
    nav ul li:hover i,
    nav ul li:hover span {
        color: #fff;
    }
    nav ul li:hover i.icon-nav-results:before,
    nav ul li:hover span.icon-nav-results:before {
        background-image: url(#{$cdn}/img/white_results.svg);
    }
    nav ul li:hover i.icon-nav-tickets:before,
    nav ul li:hover span.icon-nav-tickets:before {
        background-image: url(#{$cdn}/img/white_tickets.svg);
    }
    nav ul li a {
        width: 100%;
        height: 100%;
        padding: 20px 10px 0;
    }
    nav ul li a,
    nav ul li b {
        float: left;
    }
    nav ul li b {
        position: absolute;
        right: 15px;
        top: 10px;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-image: -o-linear-gradient(46deg, #02b9ff 0, #0388fd 100%);
        background-image: -o-linear-gradient(314deg, #02b9ff 0, #0388fd 100%);
        background-image: linear-gradient(136deg, #02b9ff, #0388fd);
        line-height: 22px;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        letter-spacing: -0.3px;
    }
    nav ul li i,
    nav ul li span {
        width: 100%;
        display: block;
        -webkit-transition: all 0.3s;
        -o-transition: 0.3s all;
        transition: all 0.3s;
        float: left;
    }
    nav ul li i {
        color: #576684;
        font-size: 22px;
        line-height: 20px;
        position: relative;
        padding: 0;
        margin: 0;
    }
    nav ul li i.icon-nav-news {
        font-size: 23px;
        top: -2px;
    }
    nav ul li i.icon-nav-leaderboard {
        font-size: 34px;
        top: -7px;
    }
    nav ul li i.icon-nav-challenges {
        font-size: 28px;
        top: -2px;
    }
    nav ul li i.icon-nav-tournaments {
        font-size: 24px;
        top: -2px;
    }
    nav ul li span {
        font-size: 14px;
        color: #8794b0;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: -0.15px;
        padding-top: 7px;
    }
    .mobile-menu {
        background: -webkit-gradient(linear, left top, right top, from(#001030), to(#061235));
        background: -o-linear-gradient(left, #001030 0, #061235 100%);
        background: linear-gradient(90deg, #001030, #061235);
        height: 70px;
    }
    /* .header1 .mobile-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 70px;
    z-index: 2000;
    background: -webkit-gradient( linear, left top, right top, from(#001030), to(#061235));
    background: -o-linear-gradient(left, #001030 0, #061235 100%);
    background: linear-gradient(90deg, #001030, #061235);
    display: block;
} */
    .mobile-menu__logo {
        width: 50px;
        height: 30px;
        background-size: 100% 100%;
        background-color: rgba(0, 0, 0, 0);
        background-image: url(#{$cdn}/img/gamepad.png);
    }
    .mobile-menu__button {
        position: relative;
        width: 40px;
        height: 40px;
        cursor: pointer;
        text-decoration: none;
        display: block;
    }
    .mobile-menu__button:hover {
        text-decoration: none;
    }
    .mobile-menu__button div {
        position: absolute;
        width: 70%;
        left: 15%;
        height: 2px;
        background-color: #fff;
        border-radius: 2px;
        -webkit-transition: 0.35s;
        -o-transition: 0.35s;
        transition: 0.35s;
    }
    .mobile-menu__button div:first-of-type {
        top: 22%;
    }
    .mobile-menu__button div:nth-of-type(2) {
        top: calc(50% - 1px);
    }
    .mobile-menu__button div:nth-of-type(3) {
        bottom: 22%;
    }
    .mobile-menu__button--opened div:first-of-type {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: calc(50% - 1px);
    }
    .mobile-menu__button--opened div:nth-of-type(2) {
        opacity: 0;
    }
    .mobile-menu__button--opened div:nth-of-type(3) {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        bottom: calc(50% - 2px);
    }
    .mobile-menu__notification {
        width: 48px;
        text-align: center;
        font-family: kombatlink-icon, monospace;
        font-size: 24px;
        line-height: 30px;
        color: #fff;
        position: relative;
        display: block;
    }
    .mobile-menu__notification:hover {
        text-decoration: none;
        color: #fff;
    }
    .mobile-menu__notification--messages:before {
        content: "\e805";
    }
    .mobile-menu__notification--notifications:before {
        content: "\f0f3";
    }
    .mobile-menu__notification--friends:before {
        content: "\e806";
    }
    .mobile-menu__notification div {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 4px;
        height: 20px;
        border-radius: 6px;
        border: 2px solid #061235;
        background: #49c3fd;
        font-size: 14px;
        line-height: 18px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        color: #fff;
        letter-spacing: 0.05em;
    }
    .header__section--friends {
        display: none;
    }
    .right-menu__header {
        font-family: Burbank-Big-Rg-Bd, sans-serif;
        color: #fff;
        font-size: 17px;
        line-height: 17px;
        letter-spacing: 0.05em;
        margin-top: 30px;
        padding-bottom: 10px;
    }
    .right-menu__header--orange {
        border-bottom: 2px solid orange;
    }
    .right-menu__header--green {
        border-bottom: 2px solid #6cd000;
    }
    .right-menu__header--blue {
        border-bottom: 2px solid #49c3fd;
    }
    .right-menu__subheader {
        font-family: Burbank-Big-Rg-Bd, sans-serif;
        color: #fff;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.05em;
        margin-top: 30px;
        padding-bottom: 10px;
    }
    .right-menu__input-container {
        position: relative;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .right-menu__input-container--search {
        height: 70px;
    }
    .right-menu__input-container--search:before {
        position: absolute;
        width: 13px;
        height: 13px;
        top: 28px;
        left: 11px;
        background-color: rgba(0, 0, 0, 0);
        content: "";
        display: block;
        background-size: 100% 100%;
        background-image: url(#{$cdn}/img/search.svg);
        opacity: 0.5;
    }
    .right-menu__input {
        width: 100%;
        height: 37px;
        border: 1px solid hsla(0, 0%, 100%, 0.1);
        border-radius: 2px;
        outline: none;
        font-size: 16px;
        padding: 5px 10px 0 32px;
        font-family: Burbank-Big-Rg-Md, sans-serif;
        background-color: rgba(0, 0, 0, 0);
        color: #fff;
        margin: 16px 0 40px;
    }
    .right-menu__radio {
        display: none;
    }
    .right-menu__label__voted {
        position: relative;
        width: 100%;
        padding: 0 0 0 0;
        font-size: 15px;
        font-family: Burbank Small, sans-serif;
        color: #fff;
        line-height: 20px;
        height: 30px;
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 5px;
    }
    .right-menu__label {
        position: relative;
        width: 100%;
        padding: 0 0 0 40px;
        margin-top: -1px;
        font-size: 15px;
        font-family: Burbank Small, sans-serif;
        color: #fff;
        line-height: 30px;
        height: 10px;
        display: block;
        cursor: pointer;
    }
    .right-menu__label:before {
        content: "";
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        left: 0;
        top: 6px;
        background-color: rgba(0, 0, 0, 0);
        border: 3px solid #1e0e46;
        border-radius: 50%;
        -webkit-box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.1);
        box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.1);
        -webkit-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
    }
    .right-menu__radio:checked + .right-menu__label:before {
        background-color: orange;
    }
    .right-menu__button {
        width: 100%;
        height: 40px;
        margin: 15px 0 0;
    }
    .right-menu__hr {
        width: calc(100% + 30px);
        margin: 54px -15px 20px;
        background-color: #fff;
        opacity: 0.1;
        height: 1px;
    }
    .right-menu__privacy {
        font-size: 14px;
        margin: 5px 0;
    }
    .right-menu__copyright,
    .right-menu__privacy {
        width: 100%;
        text-align: center;
        color: #fff;
        font-family: Burbank-Big-Rg-Bd, sans-serif;
    }
    .right-menu__copyright {
        font-size: 12px;
        opacity: 0.3;
    }
    .footer {
        height: 101px;
        background: #020b1c;
        margin: 0 -30px;
        padding: 0 30px;
    }
    .footer__icon {
        width: 35px;
        height: 35px;
        border: 1px solid hsla(0, 0%, 100%, 0.2);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0);
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-size: 15px 15px;
        margin-right: 7px;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s;
    }
    .footer__icon--facebook {
        background-image: url(#{$cdn}/img/facebook.svg);
    }
    .footer__icon--twitter {
        background-image: url(#{$cdn}/img/twitter.svg);
    }
    .footer__icon--instagram {
        background-image: url(#{$cdn}/img/instagram.svg);
    }
    .footer__icon--twitch {
        background-image: url(#{$cdn}/img/twitch.svg);
    }
    .footer__icon--discord {
        background-image: url(#{$cdn}/img/discord.svg);
    }
    .footer__icon--facebook:hover {
        background-color: #2c6ac1;
    }
    .footer__icon--twitter:hover {
        background-color: #49c3fd;
    }
    .footer__icon--instagram:hover {
        background-color: #cb25b3;
    }
    .footer__icon--twitch:hover {
        background-color: #3c3daa;
    }
    .footer__icon--discord:hover {
        background-color: #6cd000;
    }
    .footer__link {
        font-family: Burbank Small, sans-serif;
        font-style: normal;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.05em;
        opacity: 0.2;
        color: #fff;
        margin: 0 12px;
        -webkit-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        text-align: center;
    }
    .footer__link:hover {
        text-decoration: none;
        color: #fff;
        opacity: 1;
    }
    .footer__logo {
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        margin-left: 15px;
    }
    .footer__logo--visa {
        position: relative;
        top: 6px;
        width: 43px;
        height: 14px;
        background-image: url(#{$cdn}/img/visa.svg);
    }
    .footer__logo--mastercard {
        width: 32px;
        height: 25px;
        background-image: url(#{$cdn}/img/mastercard.svg);
    }
    .footer__logo--paypal {
        width: 20px;
        height: 24px;
        background-image: url(#{$cdn}/img/paypal.svg);
    }
    .footer__logo--security {
        width: 120px;
        height: 24px;
        background-image: url(#{$cdn}/img/crypto.svg);
    }
    #option-selected {
        font-size: 15px;
        line-height: 15px;
        text-transform: uppercase;
    }
    .translate_wrapper {
        z-index: 1;
        border-radius: 2px;
    }
    .current_lang {
        cursor: pointer;
        text-transform: uppercase;
        overflow: hidden;
        background: #1d2033;
    }
    .lang {
        padding: 15px 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
        text-align: left;
        background: #1d2033;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .more_lang .lang {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        padding: 12px 16px;
        position: relative;
        top: unset;
        left: unset;
        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
    }
    .lang img,
    .lang span.lang-txt {
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
    }
    .lang span.lang-txt {
        position: relative;
        top: -1px;
        font-weight: 700;
        padding: 0;
    }
    .lang img {
        width: 19px;
        height: 11px;
    }
    .lang span span {
        color: #999;
        font-weight: 400;
    }
    .lang span.fa {
        font-size: 12px;
        position: relative;
        top: -1px;
        margin-left: 3px;
    }
    .more_lang {
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
        cursor: pointer;
        display: none;
        -webkit-transition: all 0.3s cubic-bezier(0.25, 1.15, 0.35, 1.15);
        -o-transition: all 0.3s cubic-bezier(0.25, 1.15, 0.35, 1.15);
        transition: all 0.3s cubic-bezier(0.25, 1.15, 0.35, 1.15);
        background: #1d2033;
        margin-top: 5px;
    }
    .translate_wrapper.active .more_lang {
        display: block;
        position: absolute;
        width: 180px;
        border-radius: 4px;
        top: -58px;
        left: 115px;
        overflow-y: auto;
        max-height: 120px;
    }
    .force-overflow {
        min-height: 450px;
    }
    .more_lang::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        background-color: rgba(1, 2, 2, 0.6);
    }
    .more_lang::-webkit-scrollbar {
        width: 5px;
        background-color: rgba(1, 2, 2, 0.6);
    }
    .more_lang::-webkit-scrollbar-thumb {
        border-radius: 3px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #212529;
    }
    .more_lang.active {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    .more_lang .lang:hover {
        background: #5766b2;
        color: #fff;
    }
    .more_lang .lang:hover span {
        color: #fff;
    }
    .adm_content a:hover,
    .adm .content a:hover,
    .translate_wrapper.active,
    .translate_wrapper:hover {
        -webkit-transition: all 0.3s cubic-bezier(0, 0.99, 0.44, 0.99);
        -o-transition: all 0.3s cubic-bezier(0, 0.99, 0.44, 0.99);
        transition: all 0.3s cubic-bezier(0, 0.99, 0.44, 0.99);
    }
    .translate_wrapper .more_lang .lang:hover {
        background: hsla(0, 0%, 100%, 0.07);
    }
    .lang.selected span.lang-txt {
        color: #49c3fd;
    }
    .left-menu.lang-menu {
        position: fixed;
        overflow: visible;
        top: unset;
        bottom: 0;
        height: unset;
        min-height: unset;
        margin-bottom: 0;
        background-color: unset;
    }
    .lft-menu {
        z-index: 101;
        position: fixed;
        left: 0;
        top: 82px;
        width: 120px;
        height: calc(100vh - 82px);
        background-color: #010f2d;
    }
    .lft-menu_scroll {
        max-height: calc(100%);
        position: relative;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .lft-menu_scroll::-webkit-scrollbar {
        display: none;
    }
    .lft-menu .left-menu {
        position: relative;
        min-height: 0;
        height: auto;
        top: 0;
    }
    .lft-menu_content {
        position: relative;
        left: 0;
        width: 100%;
    }
    .lft-menu_content .left-menu {
        overflow: visible;
    }
    .lft-menu_content .left-menu > ul {
        padding-top: 84px;
        height: 84px;
    }
    .lft-menu_content nav ul li {
        border-bottom: none;
    }
    .lft-menu nav ul li path {
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
    }
    .lft-menu nav ul li:hover .svg-image_hover-path,
    .lft-menu nav ul li:hover .svg-image_hover-path path {
        fill: #fff;
    }
    @media screen and (max-width: 767.96px) {
        .m-sm-40 {
            margin: 40px 0;
        }
        .m-sm-20 {
            margin: 20px 0;
        }
    }
    @media screen and (max-width: 991.98px) {
        .p-l-sm-85 {
            padding-left: 85px;
        }
        .p-l-sm-85 {
            padding-left: 85px;
        }
        .m-sm-20 {
            margin: 20px 0;
        }
        .m-b-sm-20 {
            margin-bottom: 20px;
        }
        .m-t-sm-50 {
            margin-top: 50px;
        }
        .header--guest {
            padding-left: 40px;
        }
        .header__logo {
            display: none;
        }
        .header__link {
            position: relative;
            height: 60px;
            border: none;
            border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
            padding: 10px 0 10px 80px;
            background: rgba(0, 0, 0, 0);
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-justify-content: flex-start;
            font-size: 14px;
            line-height: 40px;
            width: 100%;
            margin: 0;
        }
        .header__link:before {
            position: absolute;
            left: 30px;
            top: 13px;
            height: 34px;
            width: 34px;
            border-radius: 50%;
            border: 1px solid hsla(0, 0%, 100%, 0.1);
            background-color: rgba(0, 0, 0, 0);
            content: "\e804";
            font-size: 20px;
            font-family: kombatlink-icon, sans-serif;
            line-height: 34px;
            text-align: center;
            display: block;
        }
        .header__link.blue-button--filled:before {
            content: "\e802";
            font-size: 14px;
        }
        .header__link:hover {
            background: rgba(0, 0, 0, 0);
            color: #fff;
        }
        .header__section {
            padding: 10px 0;
            border-right: none;
        }
        .header__notification {
            left: 7px;
        }
        .header__profile-picture {
            margin: 5px 10px 5px 16px;
        }
        .header__checkbox:checked + .header__section {
            background: rgba(0, 0, 0, 0);
        }
        .header__checkbox:checked + .header__section .header__profile-rank {
            color: #ff5d20;
        }
        .header__profile-info {
            display: none;
        }
        .profile-dropdown {
            display: block;
            position: relative;
            width: 100%;
            min-width: 100%;
            z-index: 999;
            background: rgba(0, 0, 0, 0);
            padding: 10px 20px;
            opacity: 1;
            top: 0;
        }
        .profile-dropdown__section {
            height: 44px;
            margin: 0 -5px;
            padding: 0 30px;
        }
        .profile-dropdown__text {
            opacity: 0.9;
        }
        .left-menu {
            width: 70px;
        }
        nav ul li {
            height: 60px;
        }
        nav ul li span {
            display: none;
        }
        .header {
            position: fixed;
            left: -100%;
            top: 60px;
            width: 100%;
            height: calc(100vh - 60px);
            border-bottom-right-radius: 50%;
            -webkit-transition: 0.4s;
            -o-transition: 0.4s;
            transition: 0.4s;
            background: -webkit-gradient(linear, left top, right top, from(#001030), to(#061235));
            background: -o-linear-gradient(left, #001030 0, #061235 100%);
            background: linear-gradient(90deg, #001030, #061235);
            overflow: auto;
            padding-left: 0;
            padding-right: 0;
        }
        .header__section {
            display: none;
        }
        .header__section:last-of-type {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            height: auto;
        }
        .header--opened {
            left: 0;
            border-bottom-right-radius: 0;
        }
        .left-menu {
            left: 0;
            top: 60px;
            height: calc(100vh - 60px);
        }
        .footer {
            padding: 30px;
        }
        .footer__link {
            font-size: 14px;
            line-height: 22px;
        }
    }
    @media screen and (min-width: 992px) {
        .m-t-lg-82 {
            margin-top: 82px;
        }
        .h-lg-100 {
            height: 100%;
        }
        .mobile-menu {
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        nav.mobile-menu {
            position: fixed;
            top: 0px;
            right: 0px;
            left: 0px;
            z-index: 999;
        }
    }
    @media screen and (min-width: 768.02px) {
        .p-r-md-20 {
            padding-right: 20px;
        }
        .p-r-md-25 {
            padding-right: 25px;
        }
    }
    @media screen and (max-width: 991.98px) and (min-width: 0px) {
        .p-l-sm-20 {
            padding-left: 20px;
        }
        .p-l-sm-1 {
            padding-left: 1px;
        }
        .m-r-sm-5 {
            margin-right: 5px;
        }
        .m-l-sm-5 {
            margin-left: 5px;
        }
        .row.table__row.table__row--open {
            margin: 1em;
        }
    }
    @media screen and (max-width: 575.98px) {
        .m-t-sm-10 {
            margin-top: 10px;
        }
        .p-l-sm-65 {
            padding-left: 65px;
        }
        .left-menu {
            width: 50px;
        }
        nav ul li {
            height: 45px;
        }
        nav ul li i.icon-nav-news {
            font-size: 20px;
            top: -2px;
        }
        nav ul li i.icon-nav-leaderboard {
            font-size: 30px;
            top: -10px;
        }
        nav ul li i.icon-nav-challenges {
            font-size: 25px;
            top: -7px;
        }
        nav ul li i.icon-nav-tournaments {
            top: -8px;
        }
        nav ul li i.icon-nav-store {
            top: -6px;
        }
        nav ul li i.icon-nav-buy_sell {
            top: -7px;
        }
        nav ul li b {
            right: 5px;
            top: 7px;
        }
        nav ul li i.icon-nav-results {
            width: 20px;
            height: 20px;
            left: 4px;
            bottom: 8px;
        }
        nav ul li i.icon-nav-tickets {
            bottom: 8px;
        }
        .lft-menu_scroll {
            max-height: calc(100% - 80px);
        }
        .lft-menu_content .left-menu > ul {
            padding-top: 40px;
            height: 40px;
        }
        .article-header__tab-menu-container {
            position: relative;
        }
    }
    @media screen and (max-width: 991.98px) and (min-width: 768px) {
        .footer {
            height: 120px;
        }
    }
    @media screen and (max-width: 575.98px) and (min-width: 0) {
        .footer {
            height: 300px;
        }
    }
    @media screen and (max-width: 1199.98px) {
        .header__section--friends {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }
    }
    @media screen and (max-width: 991.98px) and (min-width: 576px) {
        .footer {
            height: 220px;
        }
        .lft-menu_scroll {
            max-height: calc(100% - 120px);
        }
        .lft-menu_content .left-menu > ul {
            padding-top: 60px;
            height: 60px;
        }
    }
    @media (max-width: 991px) {
        .lang span.fa {
            display: none;
        }
    }
    @media (max-width: 991.98px) {
        .current_lang .lang {
            padding: 10px 9px;
            width: 70px;
        }
        .translate_wrapper.active .more_lang {
            top: -75px;
            left: 77px;
        }
        .lft-menu {
            top: 60px;
            width: 70px;
            height: calc(100vh - 60px);
        }
    }
    @media (max-width: 575.98px) {
        .current_lang .lang {
            padding: 10px 0;
            width: 50px;
        }
        .translate_wrapper.active .more_lang {
            top: -82px;
            left: 57px;
        }
        .lft-menu {
            width: 50px;
        }
    }
    .ReactProgress_wrapper {
        background: #fab8b8;
        position: relative;
        height: 0.5rem;
        width: 100%;
    }
    .ReactProgress_filler {
        background: #00a36d;
        height: 100%;
        transition: width 0.8s ease-in;
    }
    .friend-list-container {
        min-height: 100px;
        max-height: 380px;
        overflow: auto;
        width: 230px;
        margin-left: -20px;
        padding: 5px;
    }
    .friend-list {
        height: 40px;
        margin: 11px 12px 11px 15px;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        font-family: BurbankSmall-Medium, sans-serif;
        font-weight: 700;
        cursor: pointer;
        border-top: 1px solid #80808024;
    }
    .friend-list:hover {
        opacity: 0.7;
    }
    .friend-list:hover .chaticon::after {
        color: white;
    }
    .header__profile-rank::before {
        content: "";
    }
    ::-webkit-scrollbar {
        width: 10px;
        height: 7px;
        border: none;
        overflow: hidden;
        border-radius: 0 3px 3px 0;
    }
    ::-webkit-scrollbar:hover {
        width: 20px;
        height: 7px;
        border: none;
        overflow: visible;
        border-radius: 0 3px 3px 0;
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.445);
    }
    ::-webkit-scrollbar-track {
        background-color: #051534;
    }

    .game_h1{
        font-family: "Burbank-Big-Cd-Bd",sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: .05em;
        color: #fff;
        margin: 0 !important;
    }
    .game_h2_parent{
        font-family: 'BurbankSmall-Medium', sans-serif;
        font-style: normal;
        font-size: 20px;
        letter-spacing: .05em;
        color: #fff;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: normal;
    }
    .game_h2{
        font-family: 'BurbankSmall-Medium', sans-serif;
        font-style: normal;
        font-size: 16px;
        letter-spacing: .05em;
        color: #fff;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: normal;
    }
}


@import "./MenuSectionOne.scss";
@import "./MenuSectionTwo.scss";
@import "./MenuSectionThree.scss";

body {
    background: -webkit-gradient(linear, left bottom, left top, from(#051534), to(#051534));
    background: -o-linear-gradient(bottom, #051534, #051534);
    background: linear-gradient(0deg, #051534, #051534);
}

body {
    .old-kl-css {
        article {
            color: #fff;
            background-color: #0806294a;
        }
    }

    &.adm .article {
        background: #f5f7fa;
    }

    &.faq {
        background: #f5f7fa;
    }

    overflow-x: hidden;
}

.wg-default,
.wg-default .country-selector {
    position: fixed;
    bottom: 5px;
    left: 1px;
    width: 117px;
}

.disabled {
    pointer-events: none;
}
 .home-popup-container{
     padding: 10px 0 !important;
 }
 .home-popup-container .home-popup-cover-wrapper .homePopupCoverImage{
     min-height:100% !important;
 }
.home-popup-container .home-popup-slide
{
  margin: 0px !important;
  transform :none !important;
}

.home-popup-container .model-popup-close
{
    z-index: 99999 !important;
    top: 15px;
    right: 15px;
}
.home-popup-container .home-popup-slide .home-popup-cover-wrapper
{
    margin: 0px !important;
}

.home-popup-container .home-pop-carousel-cover, .home-popup-container .home-popup-cover-wrapper,.home-popup-container .home-popup-image-carousel-cover  {
    height: 350px;
    max-height: 100%;
    object-fit: fill;
  }

  @media screen and (max-width: 1400px) {
    .home-popup-container .home-pop-carousel-cover, .home-popup-container .home-popup-cover-wrapper,.home-popup-container .home-popup-image-carousel-cover   {
      height: 339px;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .home-popup-container .home-pop-carousel-cover, .home-popup-container .home-popup-cover-wrapper,.home-popup-container .home-popup-image-carousel-cover   {
      height: 350px;
    }
  }
  
  
  @media screen and (max-width: 768px) {
    .home-popup-container .home-pop-carousel-cover, .home-popup-container .home-popup-cover-wrapper,.home-popup-container .home-popup-image-carousel-cover   {
      height: 287px;
    }
  }
  @media screen and (max-width: 991px) {
    .btn-done, .btn-reset {
        width: 130px !important;
        margin-left: 0 !important;
        margin-top: 10px;
    }
      .zoom-slider {
        width: 100%;
    }
    .zoom-slider .col {
        width: 100%;
    }
    .zoom-slider .preview__zoom {
        width: 100% !important;
    }
  }
  @media screen and (max-width: 414px) {
    .home-popup-container .home-pop-carousel-cover, .home-popup-container .home-popup-cover-wrapper,.home-popup-container .home-popup-image-carousel-cover   {
      height: 137px;
    }
  }

  .private-participant-modal
  {
      position: relative;
    .model-popup-close{
        content: "";
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        right: -18px;
        top: -22px;
        background: #e13b4c;
        border-radius: 50%;
        &:hover{
            opacity: 0.9;
        }
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 50%;
            height: 2px;
            left: 10px;
            top: 18px;
            background: #FFF;
            border-radius: 1px;
        }
        &::after
        {
            content: "";
            display: block;
            position: absolute;
            width: 50%;
            height: 2px;
            left: 10px;
            top: 18px;
            background: #fff;
            border-radius: 1px;
        }
      }
      .private-modal-close
      {
            overflow: visible !important;
      }
  }



  @media(max-width: 767px)
  {
    .old-kl-css .scrolltop {
        width: auto;
        padding: 0 !important;
        float: none !important;
        width: 130px !important;
        margin: 0 auto  !important;
    }
  }

.seat-popup-container
{
    height: 90vh !important;
    max-height: 90vh !important;
    background-color: #25324e !important;
}
.seat-popup-container .container-fluid{
    height: 65vh;
}
.konvajs-content
{
    max-height: 100% !important;
    height: 540px !important; 
}
