@import './Select.scss';

.selectWrapper {
  &.container {
    border: 1px solid #e8eef6;
    border-radius: 4px;
  }

  .arrow {
    background-image: url(https://kombatlink-assets.azureedge.net/img/grey_arrow_top.svg);
  }

  .listContainer {
    background: white;
    border: 1px solid #e8eef6;
  }

  .title {
    background: white;
    color: rgba(0, 16, 48, 0.5);

    &.selected {
      .text {
        color: rgb(0, 16, 48);
      }
    }
  }

  .searchContainer {
    border-bottom: 1px solid #e8eef6;

    &::after {
      background-image: url($grey_search);
    }
  }

  .search {
    background-color: rgba(200, 200, 200, 0.1);
    color: #001030;
  }

  .list {
    scrollbar-width: thin;
    scrollbar-color: #3c3dbc30 #3c3daa29;

    &::-webkit-scrollbar-thumb {
      background-color: #3c3dbc30;
    }

    &::-webkit-scrollbar-track {
      background-color: #3c3daa29;
    }
  }

  .item {
    color: #001030;

    &:hover {
      background: rgba(200, 200, 200, 0.14);
    }
  
    .blocked {
      background-image: url("https://kombatlink-assets.azureedge.net/img/locked.svg");
    }
  }
}

.error {
  border: 1px solid rgb(230, 72, 70) !important;
}
