.eli_btn_screen {
  width: 36px;
  height: 36px;
  background: #24334e;
  border-radius: 4px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.eli_btn_screen path {
  transition: all 0.2s;
  opacity: 0.7;
}

.eli_btn_screen:hover path {
  opacity: 1;
  fill: #49c3fd;
}

.eli_btn_screen svg {
  stroke: none;
  height: auto;
  width: auto;
}