.friendsHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-top: 5px;  
  border-bottom: 1px solid #25324e;
  margin-bottom: 20px;

  .filterName {
    width: 250px;
    margin-bottom: 5px;
  }
}


.rightTile {
  font-family: BurbankSmall-Bold, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
}

.noFriends {
  color: white;
  text-align: center;
  width: 100%;
}