@import './Tabs.module';

.articleTabMenuBeforeContainer {
  border-bottom: 1px solid #e8ebef;
}

.tabMenuLink {
  color: #001030;
  opacity: 0.5;

  &:hover {
    color: #001030;
    opacity: 0.7;
  }

  &.notification::after {
    background: #001030;
    opacity: 0.5;
  }

  &.active {
    &.notification::after {
      background: #49c3fd;
      opacity: 1;
    }
  }
}

.tabMenuRadioLabel {
  border: 1px solid #d5dae0;
}

.tabMenuRadio:checked + .tabMenuRadioLabel {
  background-color: #3c3daa;
  border: 1px solid #3c3daa;
}

.tabMenuRadioText {
  color: #001030;
}
