.height-100{
    height:100vh;
}

.bil_modal-container--small .bil_modal-container__window{
    max-width: 490px !important;
    width: 100% !important;
}
.bil_modal_hdr_ttl {
    margin: 0 0;
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 35px;
    color: #001030;
}