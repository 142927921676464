@import "../../../css/images";

.selectWrapper {
  &.container {
    position: relative;
    height: 40px;
    margin: 0 0 20px 0;

    &.opened {
      .listContainer {
        transform: translateY(0%) scaleY(1);
      }

      .arrow {
        transform: rotate(0);
      }
    }

    &.reverse {
      .listContainer {
        flex-direction: column-reverse;
        margin-top: -47px;
      }

      .list {
        flex-direction: column-reverse;
      }

      &.opened {
        .listContainer {
          transform: translateY(-100%) scaleY(1);
        }
      }

      .searchContainer {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 0px;
      }
    }
  }

  .arrow {
    width: 10px;
    height: 6px;
    transform: rotate(180deg);
    transition: 0.15s;
    padding-left: 10px;
  }

  .listContainer {
    border-radius: 4px;
    margin-top: 7px;
    transform: translateY(-50%) scaleY(0);
    transition: 200ms;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .title {
    border-radius: 4px;
    border-width: 0px;
    text-align: left;
    font-size: 14px;
    font-family: "Burbank Small", sans-serif;
    width: 100%;
    padding-left: 20px;
    padding-right: 16px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:focus {
      outline: none;
    }

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .searchContainer {
    width: 100%;
    min-height: 50px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding: 0 6px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      background-color: transparent;
      background-size: 100% 100%;
      width: 10px;
      height: 10px;
      right: 16px;
      z-index: 1;
      opacity: 0.3;
    }
  }

  .search {
    width: 100%;
    height: 30px;
    border-radius: 2px;
    padding: 0 10px;
    font-family: "Burbank Small", sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 13px;
    border: none;
    outline: none;
  }

  .list {
    overflow: auto;
    max-height: 194px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &.withSearch {
      max-height: 144px;
    }
  }

  .item {
    border-width: 0;
    background: none;
    width: 100%;
    font-family: "Burbank Small", sans-serif;
    font-size: 13px;
    font-weight: 400;
    display: block;
    position: relative;
    height: 30px;
    line-height: 30px;
    text-align: left;
    padding: 0 0 0 15px;
    flex-shrink: 0;
    transition: 130ms;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:focus {
      outline: none;
    }

    &:first-of-type {
      margin-top: 7px;
    }
  
    &:last-of-type {
      margin-bottom: 7px;
    }

    &.selected {
      color: #49c3fd;

      &::after {
        content: "";
        display: block;
        position: absolute;
        right: 16px;
        top: 10px;
        width: 12px;
        height: 9px;
        background-color: rgba(0, 0, 0, 0);
        background-size: 100% 100%;
        background-image: url($blue_ok);
        -webkit-transition: 0.13s;
        -o-transition: 0.13s;
        transition: 0.13s;
      }
    }

    .tooltip {
      position: absolute;
      right: 15px;
      top: 8px;
    }
  
    .blocked {
      display: block;
      width: 15px;
      height: 15px;
      background-size: 5px 7px;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
      opacity: 1 !important;
    }
  
    .info {
      background: rgba(255, 255, 255, 0.07);
      display: block;
      width: 15px;
      height: 15px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("https://kombatlink-assets.azureedge.net/img/info.svg");
      border-radius: 50%;
      opacity: 1 !important;
    }
  
    .selectedTip {
      display: block;
      position: absolute;
      right: 16px;
      top: 10px;
      width: 12px;
      height: 9px;
      background-color: transparent;
      background-size: 100% 100%;
      background-image: url("https://kombatlink-assets.azureedge.net/img/blue_ok.svg");
      transition: 130ms;
    }
  }
}
