.openTableButton {
  //fixed old css 
  cursor: pointer !important;
}

.article__participantUsername {
  font-family: Burbank-Big-Rg-Md,sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: .05em;
  color: #fff;
  margin-left: 20px;
}

.delete__button {
  opacity: .5;
  &:hover {
    opacity: 1;
  }
}