@import '../../../../../css/images';

.chatPersonPicture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 12px;
}

.chatPersonStatus {
  position: absolute;
  right: 11px;
  bottom: 15px;
  width: 8px;
  height: 8px;
  background: #cdcdcd;
  border: 1px solid #eef6ff;
  border-radius: 50%;

  &.online {
    background: #6cd000;
  }
}

.chatPersonName {
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #028ffd;
  margin-bottom: 4px;
}


.chatPersonMessage {
  font-family: Burbank-Big-Rg-Md,sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: .03em;
  color: #001030;
  width : 140px; 
  display: inline-block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.chatPersonMessageMine {
  opacity: .5;
} 