@import '../../../css/images';

.input {
  display: none;

  + .label {
    .check {
      background: #edf0f4;
    }

    .check::before {
      opacity: 0;
    }

    svg {
      fill: none;
      opacity: .4;
      margin-bottom: 12px;
      transition: .15s;
      stroke: none;
  
      path {
        fill: #001030;
      }
    }
  }

  &:checked {
    + .label {
      border: 1px solid #6acafc;
      color: #6acafc;

      .labelText {
        opacity: 1;
        color: #6acafc;
      }

      .span {
        opacity: 1;
        background: #6acafc;
        box-shadow: 0 0 0 1px #6acafc;
      }

      .check {
        background: #6acafc;
        opacity: 1;
      }

      .check::before {
        opacity: 1;
      }

      svg {
        opacity: 1;

        path {
          fill: #6acafc;
        }
      }
    }
  }
}

.label {
  background: white;
  border: 1px solid #e7e8ff;
  border-radius: 4px;
  transition: .15s;
}

.labelText {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: #001030;
  opacity: .4;
  margin: 0;
}

.span {
  display: block;
  background: #001030;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 6px 0 0;
  border: 3px solid white;
  box-shadow: 0 0 0 1px #001030;
  box-sizing: content-box;
  transition: .15s;
  opacity: .4;
}

.check {
  position: absolute;
  display: block;
  right: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: .15s;
}

.check::before {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(0,0,0,0);
  left: 3px;
  top: 4px;
  width: 10px;
  height: 8px;
  background-size: 100% 100%;
  background-image: url($ok);
}