.select {
  position: relative;
  overflow: visible;
  height: 45px;
  border-radius: 4px;
  background: linear-gradient(90deg, rgba(0, 16, 48, 0.05) 0%, rgba(6, 18, 53, 0.05) 100%);
}

.article_select:nth-of-type(1) {
  z-index: 997;
}
.article_select:nth-of-type(2) {
  z-index: 996;
}
.article_select:nth-of-type(3) {
  z-index: 995;
}
.article_select:nth-of-type(4) {
  z-index: 994;
}
.article_select:nth-of-type(5) {
  z-index: 993;
}
.article_select:nth-of-type(6) {
  z-index: 992;
}
.article_select:nth-of-type(7) {
  z-index: 991;
}
.article_select:nth-of-type(8) {
  z-index: 990;
}
.article_select:nth-of-type(9) {
  z-index: 989;
}
.article_select:nth-of-type(10) {
  z-index: 988;
}

.select__title {
  position: absolute;
  text-align: left;
  font-size: 14px;
  line-height: 40px;
  font-family: "Burbank Small", sans-serif;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0 20px;
  color: rgba(0, 16, 48, 0.5);

  &::before {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 17px;
    width: 10px;
    height: 6px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url("https://kombatlink-assets.azureedge.net/img/grey_arrow_top.svg");
    transform: rotate(180deg);
    transition: 150ms;
  }
}

.select__container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  left: 0;
  top: 50px;
  transform: translateY(-50%) scaleY(0);
  transition: 200ms;
  background-color: #f2f3f5;
  padding: 0;
  border-radius: 4px;
  max-height: 160px;
  overflow: auto;
}

.select__opened {
  .select__title::before {
    transform: rotate(0);
  }
  .select__container {
    transform: translateY(0%) scaleY(1);
  }
}
.select__option {
  position: relative;
  height: 40px;
  width: 100%;
  font-size: 13px;
  line-height: 40px;
  font-family: "Burbank Small", sans-serif;
  color: #001030;
  cursor: pointer;
  text-align: left;
  padding: 0 0 0 15px;
  flex-shrink: 0;
  transition: 130ms;

  &:hover {
    background: #eaedf2;
  }
}

.select__option__selected {
  color: #49c3fd;
}

.select__option__selected::after {
  opacity: 1;
}

.select__search_container {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e8eef6;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  padding: 0 6px;
}

.select__search_container::after {
  content: "";
  display: block;
  position: absolute;
  background-color: transparent;
  background-size: 100% 100%;
  width: 10px;
  height: 10px;
  right: 16px;
  top: 20px;
  z-index: 1;
  background-image: url("https://kombatlink-assets.azureedge.net/img/grey_search.svg");
  opacity: 0.3;
}

.select__search {
  width: 100%;
  height: 30px;
  background-color: rgba(200, 200, 200, 0.1);
  border-radius: 2px;
  padding: 0 10px;
  font-family: "Burbank Small", sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  color: #001030;
  border: none;
  outline: none;
  z-index: 2;
}

.article__selects-container {
  position: relative;
  z-index: 1;
}

.article__selects-container__opened {
  z-index: 3;
}

.select__option::after {
  content: "";
  display: block;
  position: absolute;
  right: 16px;
  top: 10px;
  width: 12px;
  height: 9px;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url("https://kombatlink-assets.azureedge.net/img/blue_ok.svg");
  transition: 130ms;
  opacity: 0;
}

.select__option__selected::after {
  opacity: 1;
}
