.select {
  position: relative;
  overflow: visible;
}

.article-select:nth-of-type(1) {
  z-index: 999;
}
.article-select:nth-of-type(2) {
  z-index: 998;
}
.article-select:nth-of-type(3) {
  z-index: 997;
}
.article-select:nth-of-type(4) {
  z-index: 996;
}
.article-select:nth-of-type(5) {
  z-index: 995;
}
.article-select:nth-of-type(6) {
  z-index: 994;
}
.article-select:nth-of-type(7) {
  z-index: 993;
}
.article-select:nth-of-type(8) {
  z-index: 992;
}
.article-select:nth-of-type(9) {
  z-index: 991;
}
.article-select:nth-of-type(10) {
  z-index: 990;
}

.select__title {
  position: absolute;
  text-align: left;
  font-size: 14px;
  line-height: 40px;
  font-family: "Burbank Small", sans-serif;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0 20px;
  color: rgba(255, 255, 255, 0.5);
}

.select__title::before {
  content: "";
  display: block;
  position: absolute;
  right: 16px;
  top: 17px;
  width: 10px;
  height: 6px;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url("https://kombatlink-assets.azureedge.net/img/arrow_top.svg");
  transform: rotate(180deg);
  transition: 150ms;
}

.select--opened .select__title::before {
  transform: rotate(0);
}

.select__container {
  position: absolute;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  align-items: center;
  -webkit-align-items: center;
  width: 100%;
  left: 0;
  top: 45px;
  transform: translateY(-50%) scaleY(0);
  transition: 200ms;
  background-color: #25324e;
  padding: 0;
  border-radius: 4px;
  max-height: 194px;
  overflow: auto;
  scrollbar-width: none;
}

.select__container::-webkit-scrollbar {
  display: none;
  width: 0;
}

.select--opened .select__container {
  transform: translateY(0%) scaleY(1);
}

.select__option {
  position: relative;
  height: 30px;
  width: 100%;
  font-size: 13px;
  line-height: 30px;
  font-family: "Burbank Small", sans-serif;
  color: white;
  cursor: pointer;
  text-align: left;
  padding: 0 0 0 15px;
  flex-shrink: 0;
  transition: 130ms;

  &:first-of-type {
    margin-top: 7px;
  }

  &:last-of-type {
    margin-bottom: 7px;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 10px;
    width: 12px;
    height: 9px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url("https://kombatlink-assets.azureedge.net/img/blue_ok.svg");
    transition: 130ms;
    opacity: 0;
  }

  &--blocked {
    background: rgba(255, 255, 255, 0.07);

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: rgba(255, 255, 255, 0.2);
      background-size: 5px 7px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("https://kombatlink-assets.azureedge.net/img/locked.svg");
      border-radius: 50%;
      right: 15px;
      top: 8px;
      opacity: 1 !important;
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.07);
  }

  &--selected {
    color: #49c3fd;

    &::after {
      opacity: 1;
    }
  }
}

.select__search-container {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  padding: 0 6px;
}

.select__search-container::after {
  content: "";
  display: block;
  position: absolute;
  background-color: transparent;
  background-size: 100% 100%;
  width: 10px;
  height: 10px;
  right: 16px;
  top: 20px;
  z-index: 1;
  background-image: url("https://kombatlink-assets.azureedge.net/img/search.svg");
  opacity: 0.3;
}

.select__search {
  width: 100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  padding: 0 10px;
  font-family: "Burbank Small", sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  color: white;
  border: none;
  outline: none;
  z-index: 2;
}

.article__selects-container {
  position: relative;
  z-index: 1;
}

.article__selects-container--opened {
  z-index: 3;
}

.article__selects-popup-header {
  display: none;
  height: 40px;
  color: white;
  font-size: 24px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  letter-spacing: 0.05em;
  line-height: 24px;
  margin-bottom: 8px;
  position: relative;
}

.article__selects-popup-close {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  width: 20px;
  height: 18px;
  background-size: 100% 100%;
  cursor: pointer;
  background-image: url("https://kombatlink-assets.azureedge.net/img/cross.svg");
}

@media screen and (max-width: 767.98px) {
  .article__selects-popup-header {
    display: block;
  }
  .article__selects-popup {
    position: fixed;
    width: 100%;
    height: calc(100vh - 60px);
    left: 0;
    background-color: #051534;
    z-index: 999;
    margin: 0 !important;
    padding: 30px 30px 0 20px;
    overflow: auto;
    top: 100%;
    transition: 250ms;
  }
  .article__selects-popup--opened {
    top: 60px;
  }
}

.select__blocked {
  position: absolute;
  display: block;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-size: 8px 11px;
  background-image: url("https://kombatlink-assets.azureedge.net/img/white_lock.svg");
  background-position: center center;
  background-repeat: no-repeat;
  transition: 150ms;
  z-index: 999999;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.select__blocked-container {
  z-index: 9999;
  display: none;
  position: absolute;
  left: 10px;
  top: 20px;
  transform: translate(-100%, calc(2px - 100%));
  width: 200px;
  padding-bottom: 7px;
  animation: show 150ms ease-in-out;
  opacity: 1;

  &--show {
    display: block;
  }

  &:hover {
    display: block;
  }
}

.select__blocked-content {
  display: block;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  background: white;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #001030;
  position: relative;
  border-radius: 2px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 85%;
    bottom: -11px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top: 4px solid white;
    transform: translate(-50%, 0);
  }
}

.select__blocked-link {
  color: #3c3daa;
  text-decoration: underline;

  &:hover {
    color: #3c3daa;
    text-decoration: underline;
  }
}
