.articleHeader {
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: white;
  margin: 32px 0 0 0;
}
.articleHeaderH2{
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: white;
  margin: 10px 0 0 0;
}