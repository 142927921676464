.tableColumnExplanation {
  &::before {
    content: 'Explantation';
    font-family: 'BurbankSmall-Medium', sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 12px;
    color: white;
    opacity: 0.36;
    margin-right: 20px;
  }
}

.tableMakeWinner {
  width: 158px;
  height: 36px;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 2px;
  opacity: 0.5;
  font-family: 'Burbank-Big-Cd-Bd', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: white;
  background: transparent;
  outline: none;
  transition: 150ms;

  &:hover {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    border: none;
    opacity: 1;
  }
}

.tableColumnLabel {
  opacity: 0.4;
  border: 1px solid #ffffff;
  background-size: 10px 8px;

  &:last-of-type {
    margin-left: 30px;
  }

  &.tableColumnLabelChecked {
    background-color: #6cd000;
    background-image: url('https://kombatlink-assets.azureedge.net/img/ok.svg');
    opacity: 1;
    border: none;
  }
}

.tableColumnLabelText {
  font-family: 'BurbankSmall-Medium', sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 17px;
  color: white;
  margin: 0 0 0 7px;
}