.chatHeaderProfilePicture {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.chatHeaderProfilePictureGroup {
  background: white;
  color: #028ffd;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 37px;
  text-align: center;
}

.chatHeaderUsername {
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.03em;
  color: white;
  margin-bottom: 6px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}

.chatHeaderStatus {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: white;
  opacity: 0.8;
}

.loading {
  color: #0389fd;
}

.chattypingdots {
  position: relative;
  margin-left: 4px;
}

@-webkit-keyframes chatDots {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  10% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  to {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
}

@keyframes chatDots {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  10% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  to {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
}

.chattypingdots div {
  width: 6px;
  height: 6px;
  background: #001030;
  opacity: 0.5;
  margin-right: 2px;
  -webkit-animation: chatDots 3s linear infinite;
  animation: chatDots 3s linear infinite;
  border-radius: 50%;
  -webkit-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
}

.chattypingdots__white div {
  background: #fff;
}

.chattypingdots div:nth-of-type(2) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.chattypingdots div:nth-of-type(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}


