@media screen and (max-width: 1499.98px) {
  .right-menu {
    position: fixed;
    right: -255px;
    transition: 150ms;
    height: calc(100vh - 82px);
    overflow: auto;
    min-height: calc(100vh - 82px);
    top: 82px;
    z-index: 10000;
  }

  .right-menu--opened {
    right: 0;
  }

  .header__section--friends {
    display: flex;
  }
}

.chat-button {
  margin-left: 0;
  width: 140px;
  height: 40px;
  background: rgba(0, 0, 0, 0);
  color: #fff;
  border: 1px solid #4b566d;
  opacity: .5;
  -webkit-transition: .15s;
  -o-transition: .15s;
  transition: .15s;
}

.chat-button:hover {
  background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
  background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
  background: linear-gradient(90deg, #02b7fe, #0389fd);
  opacity: 1;
}