@import "src/css/vars";
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.quickHead {
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
}

.quickHeadWrap {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.seeMore {
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  color: #FFFFFF;
}

.seeMore svg {
  margin-left: 10px;
  display: inline;
  width: 13px;
  height: 11px;
}

.seeMore:hover {
  color: #fff;
  text-decoration: underline;
}

.mb_45 {
  margin-bottom: 45px;
}

.createTournament {
  width: 100%;
  display: flex;
  margin-bottom: 58px;
  background: #1E2C48;
  border-radius: 2px;
  margin-right: 15px;
  margin-left: 15px;
  //margin-top: 45px;
}

.createTournament .homeHead {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: none;
  justify-content: center;
  padding-bottom: 0;
  margin-bottom: 0;
  padding: 80px;
  width: 37%;
}

@media(max-width: 1024px) {
  .createTournament {
    display: block;
  }

  .createTournament .homeHead {
    width: 100%;
  }
}

@media(max-width: 600px) {

  .createTournament,
  .createTournament .homeHead {
    padding: 16px;
  }
}

.createTournament .homeHead p {
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 26px;
  font-family: "BurbankSmall-Light";
  color: #FFFFFF;
  margin-top: 30px;
  margin-bottom: 35px;
}

.createTournament .homeHead p.slogan {
  font-family: "BurbankSmall-Medium";
  font-style: normal;
  font-weight: 550;
  font-size: 22px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 25px;
  color: #CA25B3;
}

.createTournament .homeHead a {
  background: linear-gradient(90deg, #CB25B3 0%, #7D2EAE 100%);
  border-radius: 2px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  padding: 13px 55px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.createTournament .homeHead a:hover {
  text-decoration: none;
}

.createTournamentMediumImg {
  background-image: url(#{$cdn}/img/create-tournament-m.png);
  background-size: cover;
  width: 63%;
}

.createTournamentImg {
  background-image: url(#{$cdn}/img/create-tournament.png);
  background-size: cover;
  width: 63%;
}


.ownedGamesContent {
  width: 100%;
  margin-bottom: 60px;
}

.ownedGamesContent .item {
  display: inline-block;
  width: 20%;
  float: left;
  padding: 0 15px;
}

@media(max-width: 600px) {
  .ownedGamesContent .item {
    width: 50%;
    margin-bottom: 20px;
  }
}

.ownedGamesContent .item img {
  width: 100%;
  height: 350px;
  -o-object-fit: cover;
  object-fit: cover;
}

.ownedGamesContent .item .gInfo {
  font-weight: bold;
  font-size: 18px;
  margin-top: 30px;
  font-family: "Burbank-Big-Rg-Bd";
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.homepageTournaments .quickHeadWrap {
  width: 100%;
}



.homeContent {
  width: 100%;
  display: flex;
  margin-bottom: 58px;
}

@media(max-width: 1024px) {
  .homeContent {
    display: block;
  }
}

.homeContent .left {
  width: 100%;
  background: #1E2C48;
  border-radius: 2px;
  display: block;
  float: left;
}

@media only screen and (min-width: 992px) {
  .homeContent .left {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .homeContent .left {
    width: 60%;
    display: flex;
  }
}

.homeContent .right {
  width: 100%;
  display: block;
  float: left;
  padding: 0 0 15px 0;
}

@media(max-width: 1024px) {
  .homeContent .right {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 992px) {
  .homeContent .right {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .homeContent .right {
    width: 40%;
    padding-left: 30px;
    padding-top: 10px;
  }
}



/* Training */

.training {
  width: 100%;
  display: flex;
  float: left;
  padding-left: 80px;
  padding-right: 40px;
  padding-top: 15px;
}

@media(max-width: 1024px) {
  .training {
    padding: 20px;
  }
}

@media(max-width: 600px) {
  .training {
    display: block;
  }
}

.training .bars {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 55%;
}

@media (min-width: 1024px) {
  .training .bars ul {
    padding-left: 15px;
  }
}

.training ul {
  width: 100%;
}

@media(max-width: 600px) {
  .training .bars {
    width: 100%;
  }

  .training ul {
    padding-left: 0;
  }
}

.training ul li {
  width: calc(50% - 10px);
  height: 100px;
  float: left;
  display: block;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 4px;
}

.training ul li:nth-child(2),
.training ul li:nth-child(4) {
  margin-right: 0;
}

.training ul li:nth-child(3),
.training ul li:nth-child(4) {
  margin-bottom: 0;
}

.training ul li a {
  width: 100%;
  height: 100px;
  display: inline-block;
  float: left;
  position: relative;
}

.training ul li:first-child {
  background: #3C3DAA;
}

.training ul li:nth-child(2) {
  background: #FD6969;
}

.training ul li:nth-child(3) {
  background: #682FBF;
}

.training ul li:nth-child(4) {
  background: #49C3FD;
}

.training ul li span {
  font-weight: 700;
  font-size: 17px;
  letter-spacing: -0.5px;
  line-height: 26px;
  color: #fff;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  font-family: "BurbankSmall-Medium";
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

}

@media (max-width: 1024px) {
  .training ul li span {
    padding: 0px 10px;
  }
}

/* Recent News */

.homeContent .right .recentNews {
  width: 100%;
  display: block;
  float: left;
}

.homeContent .right .recentNews .content {
  width: 100%;
  display: block;
  float: left;
}

.homeContent .right .recentNews .content .item {
  width: 100%;
  display: block;
  float: left;
  padding: 25px 0 25px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.homeContent .right .recentNews .content .item:last-child {
  border-bottom: none;
  padding-bottom: 0
}

.homeContent .right .recentNews .content .item a {
  display: flex;
  align-items: center;
}

@media(max-width: 600px) {
  .homeContent .right .recentNews .content .item a {
    display: block;
  }
}

.homeContent .right .recentNews .content .item span.thumbnail {
  width: 190px;
  height: 140px;
  display: inline-block;
  float: left;
  border-radius: 1px;
  overflow: hidden;
  position: relative;
}

@media(max-width: 600px) {
  .homeContent .right .recentNews .content .item span.thumbnail {
    width: 100%;
  }
}

.homeContent .right .recentNews .content .item span.thumbnail img {
  width: 100%;
  height: 120px;
  display: inline-block;
  float: left;
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  right: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.homeContent .right .recentNews .content .item span.info {
  width: calc(100% - 190px);
  float: left;
  background: #1e2c48;
  height: 120px;
  padding: 29px 120px 29px 30px;
}

@media(max-width: 600px) {
  .homeContent .right .recentNews .content .item span.info {
    width: 100%;
    margin-top: 20px;
  }
}

.homeContent .right .recentNews .content .item span.info span {
  width: 100%;
  display: block;
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.homeContent .right .recentNews .content .item span.info span.head {
  font-family: "BurbankSmall-Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #FFFFFF;
  margin-bottom: 26px;
}

.slideInfo {
  background: #1E2C48;
  height: 100%;
  width: 30%;
  float: right;
  position: absolute;
  right: -380px;
}

.slideImage {
  width: 100%;
  height: 100%;
  float: left;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  /*  left: -20px;*/
}

.detailDate {
  font-style: normal;
  font-weight: 550;
  font-size: 18px;
  line-height: 20px;
  font-family: "BurbankSmall-Medium";
  color: #49C3FD;
}

.detailHosted {
  font-family: "BurbankSmall-Light";
  font-style: normal;
  font-weight: 550;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.4);
  clear: both;
}

.detailHosted span {
  font-style: normal;
  font-weight: 550;
  font-size: 13px;
  line-height: 13px;
  color: #FFFFFF;
  clear: both;
  margin-left: 7px;
}


.detailCountry {
  font-family: "BurbankSmall-Light";
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
}

.slideInfoInside .detailCountry {
  float: right;
}

.detailTitle {
  font-family: "BurbankSmall-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  /* or 142% */
  display: block;
  margin-bottom: 30px;
  color: #FFFFFF;
}



/* Home Head */

.homeHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  float: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 12px;
  margin-bottom: 15px;
}

.training .homeHead {
  align-items: flex-start;
  flex-direction: column;
  border-bottom: none;
  justify-content: center;
  padding-bottom: 0;
  margin-bottom: 0;
  width: 45%;
}

@media(max-width: 600px) {
  .training .homeHead {
    width: 100%;
  }
}

.recentNews .homeHead {
  margin-bottom: 0;
}

.homeHead .home_h1,
.homeHead span {
  font-style: normal;
  font-weight: 600;
  font-size: 26px !important;
  line-height: 26px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  letter-spacing: 0.05em;
  color: #FFFFFF;
}

.homeHead .home_h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px !important;
  line-height: 20px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  letter-spacing: 0.05em;
  color: #FFFFFF;
}

.training .homeHead p {
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 26px;
  font-family: "BurbankSmall-Light";
  color: #FFFFFF;
  max-width: 285px;
  margin-top: 30px;
  margin-bottom: 35px;
}

.btnBlueGradient {
  background: linear-gradient(90deg, #02B7FE 0%, #0389FD 100%);
  border-radius: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 13px 48px;
  color: #FFFFFF;
  display: block;
  float: left;
  margin-bottom: 45px;
}
.videoWrapper{
  margin: 0 auto !important;
}
//New
.wrapper {
  margin: 0 auto;
  /*width:310px;*/
  width: 100%;

  * {
    font-family: 'Montserrat', sans-serif !important;
  }

  h1 {
    font: 900 50px/55px 'Montserrat', sans-serif;
    color: #a2a2a2;
  }

  h2 {
    font: 700 50px/55px 'Montserrat', sans-serif;
    color: #000;
  }

  h3 {
    font: 600 35px/40px 'Montserrat', sans-serif;
    color: #000;
  }

  h4 {
    font: 400 30px/35px 'Montserrat', sans-serif;
    color: #000;
  }

  h5 {
    font: 400 25px/30px 'Montserrat', sans-serif;
    color: #000;
  }

  h6 {
    font: 400 20px/25px 'Montserrat', sans-serif;
    color: #000;
  }

  p {
    font: 500 15px/20px 'Montserrat', sans-serif;
    // color: #989898;
    color:#4e4c4c;
    padding: 0px 0 0;
  }

  @media(max-width:767px) {
    .popularGameTitle {
      padding: 0 0px 0 0 !important;

      .gameTitle {
        font-size: 24px !important;
        margin-bottom: 0;
        padding-top: 0;
        max-width: 65%;
        padding-bottom: 0;
      }
      .blogSeeMorePadding{
        padding: 0 0px 0 0 !important;
        justify-content: flex-end
        
      }
    }
  }

  @media(max-width:480px) {
    .popularGameTitle {
      .gameTitle {
        font-size: 18px !important;
        padding-left: 0 !important;
      }
    }
  }
}

.content_wrapper {
  padding: 90px 0;
  background-color: #fff;

  .testimonialTitle {
    color: #000 !important;
    margin-top: -5px;
  }
}

.row {
  &.flex-re {
    [class*="col-"] {
      margin-right: 2.5641%;
      margin-left: 0;
    }
  }
}

.flexs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.flexm {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.flexl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.dash_txt {
  padding-left: 25px;

  h3 {
    font: 600 35px/40px 'Montserrat', sans-serif;
    color: #000;
  }

  h6 {
    // color: #989898;
    color:#4e4c4c;
    margin: 15px 0 0 0;
  }

  ul {
    width: 100%;
    margin-top: 36px;
    padding-left: 0;

    li {
      width: 100%;
      display: inline-block;
      padding: 0 0 0 75px;
      margin: 9px 0;

      h6 {
        font-weight: 600;
        margin: 0;
      }


    }

    .cup {
      background: url(#{$cdn}/img/ti-1.png) no-repeat left top;

      p {
        padding-top: 0;
      }
    }

    .people {
      background: url(#{$cdn}/img/ti-2.png) no-repeat left top;
    }

    .chat {
      background: url(#{$cdn}/img/ti-3.png) no-repeat left top;
    }
  }
}

.creat_box {
  text-align: center;


  h2 {
    margin: 25px 0 0 0;
    color: #fff !important;
  }

  h6 {
    margin: 25px 0 0 0;
    color: #fff;
  }

  @media(max-width:767px) {
    h2 {
      font-size: 28px !important;
      line-height: 1.4;
    }

    h6 {
      font-size: 15px !important;
    }
  }
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

.img_full {
  img {
    width: 100%;
    height: auto;
    float: left;
  }

  @media(max-width:1200px) {
    .videoWrapper {
      margin: 0 auto;
      text-align: center;
    }
  }

  @media(max-width:991px) {
    .videoWrapper {
      width: 96% !important;
    }
  }

  @media(max-width:767px) {
    .videoWrapper {
      width: 96% !important;
      height: auto !important;
    }

  }

}

.video_wrap {
  background: url(#{$cdn}/img/video-bg.jpg) no-repeat center;
  background-size: cover;
  @media(max-width: 480px){
    .row{
      margin: 0;
      .col{
        padding: 0;
      }
    }
  }
}

.img_full {
  img {
    width: 100%;
    height: auto;
    float: left;
  }
}

.btn {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  background-image: linear-gradient(to right, #ca25b3, #7f2eae);
  font: 600 18px/58px 'Oswald', sans-serif;
  color: #fff;
  border: none;
  text-transform: uppercase;
  text-align: center;
  padding: 0px 51px;
  margin: 15px 0 0;
  display: inline-block;
  border-radius: 0;
  letter-spacing: 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #333;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    border-radius: 0;
  }

  &:hover {
    color: #fff;
    border: none;
    transition: all .3s ease-in-out;
    text-decoration: none;

    &::before {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
    }
  }
}

.pdr_area {
  margin: 0 0 30px 0;

  ul {
    padding-left: 23px;
    margin-bottom: 0;
  }

  ul li {
    list-style: none;
  }

  .tab_link {
    float: left;
    width: auto;
    height: auto;
    background: #02b0fd;
    border: none;
    border-radius: 15px;
    font: 500 16px/21px 'Montserrat', sans-serif;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    padding: 15px 25px;
    margin: 0 5px 10px 0;
    position: relative;
    cursor: pointer;
  }

  .tab_content {
    display: none;
    border: 1px solid #dbdbdb;
    padding: 0 25px 25px;

  }

  .tab_content_active {
    display: block;
  }
}

.pdr_area {
  .tab_link {
    &:hover {
      color: #fff;
      background: #039de2;
    }

    &.active {
      color: #fff;
      background: #039de2;
    }
  }
}

div:after,
ul:after,
ol:after {
  clear: both;
  line-height: 0;
  content: "";
  display: table;
}

.print_area {
  .print_left {
    float: left;
    width: 75%;
    border-right: 3px dotted #333;
    padding: 25px 25px 0 0;

    .heading {
      background: #ccc;
      padding: 15px;
    }

    ul {
      // display: flex;
      flex-wrap: wrap;
      align-items: initial;
      justify-content: center;
      width: 100%;
      padding-left: 0;

      li {
        display: inline-block;
        // width: 19.2%;
        width:30%;
        // float: left;
        padding: 36px 5px;
        box-shadow: 0 0 9px #ccc;
        text-align: center;
        // margin: 25px 0 25px 1%;
        margin:0 25px 25px 0%;

        &:first-child {
          // margin: 25px 0;
          margin-top: 25px;
        }

        h6 {
          font-weight: 600;
        }
      }
    }
  }

  .print_right {
    float: left;
    width: 25%;
    padding: 25px 0 0 0;

    img {
      width: 100%;
    }
  }
}

h4 {
  font: 400 30px/35px 'Montserrat', sans-serif;
  color: #000;
}

.ticket_box {
  box-shadow: 0 0 12px #999;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .tic_left {
    width: 15%;
    float: left;
    text-align: center;

    img {
      width: auto;
    }
  }

  .tic_right {
    width: 85%;
    float: right;

    h6 {
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
.ticket_ul{
  gap: 5px;
  flex-direction: row;
}

.gal_box {
  margin: 18px 0 18px 25px;
  position: relative;
  padding: 0;
  max-width: calc(100% / 4 - 25px);

  &:first-child,
  &:nth-child(5n) {
    margin-left: 0;
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  .gal_txt {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.5);

    h4 {
      color: white;
      font: 600 30px/35px 'Raleway', sans-serif;
      color: #fff;
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 96%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;

      img {
        width: auto;
      }
    }
  }

  &:hover {
    .gal_txt {
      opacity: 1;
    }
  }
}

.owl_carousel {
  .owl_stage {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

#owl-one {
  .item {
    margin: 15px 5px;
    text-align: center;

    img {
      display: inline-block;
    }
  }

  .owl-nav {
    [class*=owl-] {
      position: absolute;
      margin-top: -20px;
      outline: 0;
      width: 39px;
      height: 50px;
      text-indent: -9999px;
      z-index: 999;
      display: none;
    }

    button {
      &.owl-next {
        background-position: -63px -1px !important;
        right: -50px;
        top: 50%;
        background: url(#{$cdn}/img/right.png) no-repeat !important;
      }

      &.owl-prev {
        background-position: -3px -1px !important;
        left: -50px;
        top: 50%;
        background: url(#{$cdn}/img/left.png) no-repeat !important;
      }
    }
  }

  .owl-dots {
    display: none;
  }
}
.colorBlack{
  color:#000 !important
}
.testi_box {
  padding: 50px 25px 0;
  text-align: center;
  border-radius: 12px;
  background: #e9e9e9;

  h3 {
    font-weight: 700;
    color: #02b0fd;
    margin-bottom: 0;
  }

  p {
    font-size: 16px;
    color: #484848;
    padding: 0 0 15px 0;

    &.tag {
      font-size: 14px;
    }
  }

  img {
    height: auto !important;
    width: auto !important;
    margin: 0 auto;
  }
  
}

.testi_wrap {
  background: #e9e9e9 !important;
}

.gap {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.gap_bottom {
  padding-bottom: 20px;
}

.gap_top {
  padding-top: 20px;
}

.gap_small_top {
  padding-top: 10px;
}

.gap_small {
  padding-bottom: 10px;
  padding-top: 10px;
}

.gap_small_bottom {
  padding-bottom: 10px;
}

.tt_u {
  text-transform: uppercase;
}

.equal_height {
  align-items: initial !important;
}

.bdr_top {
  border-top: 3px solid #e5e5e5;
}

.game_list {
  justify-content: space-between;
  margin: 0 25px !important;
}

.blog_box {
  background: #fff;
  margin: 15px 15px;
  box-shadow: 0 0 12px #999;
  padding: 0;
  max-width: 30%;

  .subtitle {
    margin-bottom: 0;
  }

  .btnBlack {
    background: #000 !important;
    color: #fff !important;
    border: none;
    text-transform: uppercase;
    text-align: center;
    padding: 0px 48px;
    margin: 15px 0 0;
    display: inline-flex;
    border-radius: 0;
    letter-spacing: 0;
    position: relative;
    height: 100%;
    font-weight: 700;
    font-family: 'Oswald', sans-serif !important;
    font-size: 18px;
    line-height: 58px;

    &:after {
      content: "";
      position: absolute;
      // z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #333;
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 50%;
      transform-origin: 50%;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      border-radius: 0;

    }

    &:hover {
      text-decoration: none !important;

      span {
        position: relative;
        z-index: 99;
      }

      &:after {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
      }
    }
  }

  .blog_img {
    img {
      width: 100%;
    }
  }

  .blog_txt {
    display: inline-block;
    width: 100%;
    padding: 15px;

    h6 {
      font-weight: 600;
    }

    p {
      &.postedby {
        font-size: 14px;
        padding: 0;
        color: #02b0fd;
      }

      .postedby {
        color: #02b0fd !important;
      }
    }
  }
}
.restrict2Lines{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.restrict3Lines{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (min-width: 450px) {
  .wrapper {
    /*width:460px;*/
    width: 100%;
    overflow: hidden;
    height:100%;

  }

  .wrapper-2 {
    width: 98%;
  }

  .row [class*="col-s"] {
    margin-left: 2.5641%;
    float: left;
  }

  .row [class*="col-s"]:first-child,
  .row [class*="col-s"].first {
    margin-left: 0;
  }

  .col-s-1 {
    width: 5.98291%;
  }

  .col-s-2 {
    width: 14.5299%;
  }

  .col-s-3 {
    width: 23.0769%;
  }

  .col-s-4 {
    width: 31.6239%;
  }

  .col-s-5 {
    width: 40.1709%;
  }

  .col-s-6 {
    width: 48.7179%;
  }

  .col-s-7 {
    width: 57.265%;
  }

  .col-s-8 {
    width: 65.812%;
  }

  .col-s-9 {
    width: 74.359%;
  }

  .col-s-10 {
    width: 82.906%;
  }

  .col-s-11 {
    width: 91.453%;
  }

  .col-s-12 {
    width: 100%;
  }
}

@media only screen and (min-width: 990px) {
  .wrapper {
    /*width: 1000px;*/
    width: 100%;
  }

  .row [class*="col-l"] {
    margin-left: 2.5641%;
    float: left;
  }

  .row [class*="col-l"]:first-child,
  .row [class*="col-l"].first {
    margin-left: 0;
  }

  .col-l-1 {
    width: 5.98291%;
  }

  .col-l-2 {
    width: 14.5299%;
  }

  .col-l-3 {
    width: 23.0769%;
  }

  .col-l-4 {
    width: 31.6239%;
  }

  .col-l-5 {
    width: 40.1709%;
  }

  .col-l-6 {
    width: 48.7179%;
  }

  .col-l-7 {
    width: 57.265%;
  }

  .col-l-8 {
    width: 65.812%;
  }

  .col-l-9 {
    width: 74.359%;
  }

  .col-l-10 {
    width: 82.906%;
  }

  .col-l-11 {
    width: 91.453%;
  }

  .col-l-12 {
    width: 100%;
  }

  .wrapper-2 {
    width: 500px;
  }
}

@media only screen and (min-width: 1198px) {
  .wrapper {
    /*width: 1199px;*/
    width: 100%;
  }

  .row [class*="col-xl"] {
    margin-left: 2.5641%;
    float: left;
  }

  .row [class*="col-xl"]:first-child,
  .row [class*="col-xl"].first {
    margin-left: 0;
  }

  .col-xl-1 {
    width: 5.98291%;
  }

  .col-xl-2 {
    width: 14.5299%;
  }

  .col-xl-3 {
    width: 23.0769%;
  }

  .col-xl-4 {
    width: 31.6239%;
  }

  .col-xl-5 {
    width: 40.1709%;
  }

  .col-xl-6 {
    width: 48.7179%;
  }

  .col-xl-7 {
    width: 57.265%;
  }

  .col-xl-8 {
    width: 65.812%;
  }

  .col-xl-9 {
    width: 74.359%;
  }

  .col-xl-10 {
    width: 82.906%;
  }

  .col-xl-11 {
    width: 91.453%;
  }

  .col-xl-12 {
    width: 100%;
  }

  .wrapper-2 {
    width: 600px;
  }
}

@media only screen and (min-width: 1399px) {
  .wrapper {
    /*width: 1400px;*/
    width: 100%;
  }

  .wrapper-2 {
    width: 700px;
  }
}

@media only screen and (max-width: 1084px) {
  h1 {
    font-size: 55px;
    line-height: 1.0;
  }

  h2 {
    font-size: 45px;
    line-height: 1.0;
  }

  h3 {
    font-size: 30px;
    line-height: 1.0;
  }

  h4 {
    font-size: 25px;
    line-height: 1.0;
  }

  .top_menu ul li {
    padding: 0 20px;
  }

  .content_wrapper {
    padding: 45px 0;
  }

  .content_left,
  .content_right {
    width: 50%;
    border: none;
  }

  .RightMenu {
    display: none;
  }

  .main_wrap {
    padding-right: 0;
  }

  .right_img {
    margin: 15px 0 0;
  }
}



/********* 27-04-2022 **********/

.solutionWrapper {
  padding-top: 40px;
}

.popular-game-title {
  margin: 0;
}

.content_wrapper h2 {
  color: #02b0fd;
  text-align: center;
}

.postedby {
  display: block;
  margin-bottom: 15px;
  color: #02b0fd !important;

}

.gameBoxWrapper {
  margin: 0 15px;
}

.popularGameTitle {
  display: flex !important;
  justify-content: space-between !important;
  padding: 0 30px 0 0 !important;
  max-width: 100%;
}

@media(max-width:1200px) {
  .tournamentWrapper {
    flex-wrap: nowrap !important;

    .dash_txt {
      h3 {
        font-size: 30px;
      }
    }
  }

  .creat_box {

    h2 {
      color: #fff !important;
      max-width: 700px;
      margin: 25px auto 0;
      font-size: 45px;
    }

    h6 {
      color: #fff !important;
      max-width: 600px;
      margin: 25px auto 0;
      font-size: 20px;
    }
  }

  .solutionWrapper {
    flex-wrap: nowrap !important;
    margin-bottom: 50px !important;
  }

  .loopWrapper p {
    max-width: 700px;
    margin: 0 auto 25px;
    text-align: center;
  }
  
}

@media(max-width:1024px) {
  .print_left img {
    max-width: 100%;
  }

  .print_left h6 {
    font-size: 15px;
  }

  .print_left h4 {
    margin-bottom: 0;
  }

  .testi_box h3 {
    font-size: 26px;
  }

  .blog_box {
    max-width: 29%;
  }
}


@media(max-width:991px) {
  .tournamentWrapper {
    flex-wrap: wrap !important;
  }

  .ticket_box {
    .tic_left {
      width: 100%;
      float: none;
      text-align: center;
      margin-bottom: 20px;
    }

    .tic_right {
      width: 100%;
      float: none;
      text-align: center;
    }
  }

  .solutionWrapper {
    flex-wrap: wrap !important;
    margin-bottom: 50px !important;
  }

  .gal_box {
    max-width: calc(50% - 15px);
  }

  .gal_box:nth-child(3n) {
    margin-left: 0 !important;
  }

  .print_left h4 {
    margin-bottom: 0;
    font-size: 24px;
  }

  .print_area {
    .print_left {
      ul {
        li {
          width: calc(100% / 3 - 10px);
          margin: 13px 5px !important;
        }
      }
    }
  }

  .testi_box {
    padding: 50px 5px 0;

    h3 {
      font-size: 20px;
    }
  }

  .loopWrapper p {
    max-width: 600px;
  }

  .blog_box {
    max-width: 45%;
  }

}


@media(max-width: 767px) {
  .print_area {
    display: flex;
    flex-direction: column;
  }

  .print_area {
    .print_left {
      float: none;
      width: 100%;
      border-bottom: 3px dotted #333;
      padding: 25px 0px 30px 0;
      border-right: none;

      ul {
        li {
          width: calc(100% / 3 - 10px);
          margin: 13px 5px !important;
        }
      }
    }

    .print_right {
      float: none;
      width: 100%;
      padding: 25px 0 0 0;
      margin-bottom: 20px;
    }
  }

  .pdr_area {
    .tab_content {
      padding: 0 15px 15px;
    }
  }

  .gal_box {
    max-width: calc(50% - 5px);
    margin: 18px 0 -7px 8px;
  }

  .blog_box {
    max-width: 43%;
  }

  .blog_box {
    .btnBlack {
      padding: 0px 17px;
    }

  }

  .game_list {
    justify-content: space-between;
    margin: 0px 10px 30px !important;
    padding-top: 15px !important;
  }

  .stayTitle {
    font-size: 36px !important;
    line-height: 1.3 !important;
  }

  .testimonialTitle {
    font-size: 30px !important;
  }

  .stayLoop {
    font-size: 36px !important;
  }
  .video_wrap{
    .row{
      margin: 0;
    }
  }
  
}

@media(max-width: 480px) {

  .btn {
    padding: 0px 29px;
    font-size: 14px;
    line-height: 3;
  }

  .tournamentWrapper {
    .dash_txt {
      h3 {
        font-size: 26px;
      }

      h6 {
        font-size: 18px;
      }

      p {
        font-size: 13px;
      }
    }
  }

  .print_left {
    h4 {
      margin-bottom: 0;
      font-size: 17px;
      font-weight: 500;
    }
  }

  .print_area {
    .print_left {
      ul {
        li {
          width: 100%;
          margin: 10px 0px 0 !important;
          padding: 30px 0 !important;

          h6 {
            font-size: 20px;
          }
        }

      }
    }
  }

  .dash_txt {
    h3 {
      font-size: 26px;
    }

    h6 {
      margin: 0px 0 0 0;
      font-size: 15px;
      line-height: 1.5;
    }
  }

  .gal_box {
    max-width: 100%;
    margin: 0 0 10px 0;
  }

  .blog_box {
    max-width: 100%;
    margin: 10px 10px 0;
  }

  .stayLoop {
    font-size: 28px !important;
  }
  .dash_txt
  {
    padding-left: 10px;
    padding-right: 10px;
}
.pdr_area {
  ul{
    padding: 0 5px;
  }
  div{
    padding: 5px 5px !important;
  }
}

}


@media(max-width:360px){
  .btn {
    padding: 0px 14px;
    font-size: 13px;
    line-height: 3;
}
.tournamentWrapper {
  .dash_txt
  {
    h3 
    {
      font-size: 24px;
      line-height: 1.3;
    }
  } 
}
  .creat_box{
    h2 {
      font-size: 23px !important;
      line-height: 1.3;
    }
  } 

  .pdr_area
  { 
    .tab_link 
    {
      padding: 10px 20px;
    }
  }
  .print_left 
  {
    h4 
    {
      font-size: 16px;
    }
  }
}
.isNewLabel {
  position: absolute;
  background: linear-gradient(90deg, #ec5418 0%, #df2d06 100%);
  width: 67px;
  height: 27px;
  right: -5px;
  top: 11px;
  text-align: center;
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  font-size: 12px;
  line-height: 27px;
  color: white;
  z-index: 5;
}

.isNewLabelBackground {
  position: absolute;
  background: #b82200;
  top: 8px;
  right: -6px;
  width: 10px;
  height: 8px;
  transform: rotate(30deg);
  z-index: -1;
}

.overflowHidden{
  overflow: hidden;
}
.blogSeeMorePadding{
  padding: 0 30px 0 0 !important;
  max-width: 100%;
  justify-content: flex-end
}
.blogflexs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}