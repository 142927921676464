.preview {
  position: relative;
  width: 100%;
  margin: 30px 0;
}

.previewContainer {
  width: 100%;
  position: relative;
  height: 316px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.cropContainer {
  position: relative !important;
  width: 100%;
  height: 316px;
}

.previewImage {
  position: absolute;
  left: 0;
  top: 0;
  
  width: 100%;
  height: 100%;
}

.previewReset {
  position: relative;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 16px;
  color: #001030;
  opacity: 0.5;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  height: 30px;
  width: 100px;
  text-align: right;
  transition: 150ms;

  &:hover {
    opacity: 1;
  }
}

.previewText {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: #001030;
  margin-top: 30px;
}

.previewZoom {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 200px;
  overflow: hidden;
  height: 16px;
  background: none;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: #eeeeee;
    border-radius: 2px;
  }

  &::-webkit-slider-thumb {
    width: 14px;
    height: 14px;
    background: white;
    border: 1px solid #49c3fd;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 50%;
    position: relative;
    bottom: 5px;
    transform: rotate(180deg);
    box-shadow: 5px 0 0 -5px #49c3fd, 6px 0 0 -5px #49c3fd, 7px 0 0 -5px #49c3fd, 8px 0 0 -5px #49c3fd,
      9px 0 0 -5px #49c3fd, 10px 0 0 -5px #49c3fd, 11px 0 0 -5px #49c3fd, 12px 0 0 -5px #49c3fd, 13px 0 0 -5px #49c3fd,
      14px 0 0 -5px #49c3fd, 15px 0 0 -5px #49c3fd, 16px 0 0 -5px #49c3fd, 17px 0 0 -5px #49c3fd, 18px 0 0 -5px #49c3fd,
      19px 0 0 -5px #49c3fd, 20px 0 0 -5px #49c3fd, 21px 0 0 -5px #49c3fd, 22px 0 0 -5px #49c3fd, 23px 0 0 -5px #49c3fd,
      24px 0 0 -5px #49c3fd, 25px 0 0 -5px #49c3fd, 26px 0 0 -5px #49c3fd, 27px 0 0 -5px #49c3fd, 28px 0 0 -5px #49c3fd,
      29px 0 0 -5px #49c3fd, 30px 0 0 -5px #49c3fd, 31px 0 0 -5px #49c3fd, 32px 0 0 -5px #49c3fd, 33px 0 0 -5px #49c3fd,
      34px 0 0 -5px #49c3fd, 35px 0 0 -5px #49c3fd, 36px 0 0 -5px #49c3fd, 37px 0 0 -5px #49c3fd, 38px 0 0 -5px #49c3fd,
      39px 0 0 -5px #49c3fd, 40px 0 0 -5px #49c3fd, 41px 0 0 -5px #49c3fd, 42px 0 0 -5px #49c3fd, 43px 0 0 -5px #49c3fd,
      44px 0 0 -5px #49c3fd, 45px 0 0 -5px #49c3fd, 46px 0 0 -5px #49c3fd, 47px 0 0 -5px #49c3fd, 48px 0 0 -5px #49c3fd,
      49px 0 0 -5px #49c3fd, 50px 0 0 -5px #49c3fd, 51px 0 0 -5px #49c3fd, 52px 0 0 -5px #49c3fd, 53px 0 0 -5px #49c3fd,
      54px 0 0 -5px #49c3fd, 55px 0 0 -5px #49c3fd, 56px 0 0 -5px #49c3fd, 57px 0 0 -5px #49c3fd, 58px 0 0 -5px #49c3fd,
      59px 0 0 -5px #49c3fd, 60px 0 0 -5px #49c3fd, 61px 0 0 -5px #49c3fd, 62px 0 0 -5px #49c3fd, 63px 0 0 -5px #49c3fd,
      64px 0 0 -5px #49c3fd, 65px 0 0 -5px #49c3fd, 66px 0 0 -5px #49c3fd, 67px 0 0 -5px #49c3fd, 68px 0 0 -5px #49c3fd,
      69px 0 0 -5px #49c3fd, 70px 0 0 -5px #49c3fd, 71px 0 0 -5px #49c3fd, 72px 0 0 -5px #49c3fd, 73px 0 0 -5px #49c3fd,
      74px 0 0 -5px #49c3fd, 75px 0 0 -5px #49c3fd, 76px 0 0 -5px #49c3fd, 77px 0 0 -5px #49c3fd, 78px 0 0 -5px #49c3fd,
      79px 0 0 -5px #49c3fd, 80px 0 0 -5px #49c3fd, 81px 0 0 -5px #49c3fd, 82px 0 0 -5px #49c3fd, 83px 0 0 -5px #49c3fd,
      84px 0 0 -5px #49c3fd, 85px 0 0 -5px #49c3fd, 86px 0 0 -5px #49c3fd, 87px 0 0 -5px #49c3fd, 88px 0 0 -5px #49c3fd,
      89px 0 0 -5px #49c3fd, 90px 0 0 -5px #49c3fd, 91px 0 0 -5px #49c3fd, 92px 0 0 -5px #49c3fd, 93px 0 0 -5px #49c3fd,
      94px 0 0 -5px #49c3fd, 95px 0 0 -5px #49c3fd, 96px 0 0 -5px #49c3fd, 97px 0 0 -5px #49c3fd, 98px 0 0 -5px #49c3fd,
      99px 0 0 -5px #49c3fd, 100px 0 0 -5px #49c3fd, 101px 0 0 -5px #49c3fd, 102px 0 0 -5px #49c3fd,
      103px 0 0 -5px #49c3fd, 104px 0 0 -5px #49c3fd, 105px 0 0 -5px #49c3fd, 106px 0 0 -5px #49c3fd,
      107px 0 0 -5px #49c3fd, 108px 0 0 -5px #49c3fd, 109px 0 0 -5px #49c3fd, 110px 0 0 -5px #49c3fd,
      111px 0 0 -5px #49c3fd, 112px 0 0 -5px #49c3fd, 113px 0 0 -5px #49c3fd, 114px 0 0 -5px #49c3fd,
      115px 0 0 -5px #49c3fd, 116px 0 0 -5px #49c3fd, 117px 0 0 -5px #49c3fd, 118px 0 0 -5px #49c3fd,
      119px 0 0 -5px #49c3fd, 120px 0 0 -5px #49c3fd, 121px 0 0 -5px #49c3fd, 122px 0 0 -5px #49c3fd,
      123px 0 0 -5px #49c3fd, 124px 0 0 -5px #49c3fd, 125px 0 0 -5px #49c3fd, 126px 0 0 -5px #49c3fd,
      127px 0 0 -5px #49c3fd, 128px 0 0 -5px #49c3fd, 129px 0 0 -5px #49c3fd, 130px 0 0 -5px #49c3fd,
      131px 0 0 -5px #49c3fd, 132px 0 0 -5px #49c3fd, 133px 0 0 -5px #49c3fd, 134px 0 0 -5px #49c3fd,
      135px 0 0 -5px #49c3fd, 136px 0 0 -5px #49c3fd, 137px 0 0 -5px #49c3fd, 138px 0 0 -5px #49c3fd,
      139px 0 0 -5px #49c3fd, 140px 0 0 -5px #49c3fd, 141px 0 0 -5px #49c3fd, 142px 0 0 -5px #49c3fd,
      143px 0 0 -5px #49c3fd, 144px 0 0 -5px #49c3fd, 145px 0 0 -5px #49c3fd, 146px 0 0 -5px #49c3fd,
      147px 0 0 -5px #49c3fd, 148px 0 0 -5px #49c3fd, 149px 0 0 -5px #49c3fd, 150px 0 0 -5px #49c3fd,
      151px 0 0 -5px #49c3fd, 152px 0 0 -5px #49c3fd, 153px 0 0 -5px #49c3fd, 154px 0 0 -5px #49c3fd,
      155px 0 0 -5px #49c3fd, 156px 0 0 -5px #49c3fd, 157px 0 0 -5px #49c3fd, 158px 0 0 -5px #49c3fd,
      159px 0 0 -5px #49c3fd, 160px 0 0 -5px #49c3fd, 161px 0 0 -5px #49c3fd, 162px 0 0 -5px #49c3fd,
      163px 0 0 -5px #49c3fd, 164px 0 0 -5px #49c3fd, 165px 0 0 -5px #49c3fd, 166px 0 0 -5px #49c3fd,
      167px 0 0 -5px #49c3fd, 168px 0 0 -5px #49c3fd, 169px 0 0 -5px #49c3fd, 170px 0 0 -5px #49c3fd,
      171px 0 0 -5px #49c3fd, 172px 0 0 -5px #49c3fd, 173px 0 0 -5px #49c3fd, 174px 0 0 -5px #49c3fd,
      175px 0 0 -5px #49c3fd, 176px 0 0 -5px #49c3fd, 177px 0 0 -5px #49c3fd, 178px 0 0 -5px #49c3fd,
      179px 0 0 -5px #49c3fd, 180px 0 0 -5px #49c3fd, 181px 0 0 -5px #49c3fd, 182px 0 0 -5px #49c3fd,
      183px 0 0 -5px #49c3fd, 184px 0 0 -5px #49c3fd, 185px 0 0 -5px #49c3fd, 186px 0 0 -5px #49c3fd,
      187px 0 0 -5px #49c3fd, 188px 0 0 -5px #49c3fd, 189px 0 0 -5px #49c3fd, 190px 0 0 -5px #49c3fd,
      191px 0 0 -5px #49c3fd, 192px 0 0 -5px #49c3fd, 193px 0 0 -5px #49c3fd, 194px 0 0 -5px #49c3fd,
      195px 0 0 -5px #49c3fd, 196px 0 0 -5px #49c3fd, 197px 0 0 -5px #49c3fd, 198px 0 0 -5px #49c3fd,
      199px 0 0 -5px #49c3fd, 200px 0 0 -5px #49c3fd;
  }

  &::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: #eeeeee;
    border-radius: 2px;
  }

  &::-moz-range-thumb {
    width: 14px;
    height: 14px;
    background-color: white;
    border: 1px solid #49c3fd;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 50%;
  }
  
  &::-moz-range-progress {
    height: 4px;
    background: #49c3fd;
    border: 0;
    margin-top: 0;
    border-radius: 2px;
  }
  
  &::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 39px 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    height: 3px;
    cursor: pointer;
    background: #eeeeee;
    border-radius: 2px;
  }

  &::-ms-fill-upper {
    height: 4px;
    background: #49c3fd;
    border: 0;
    margin-top: 0;
    border-radius: 2px;
  }

  &::-ms-thumb {
    width: 14px;
    height: 14px;
    background: white;
    border: 1px solid #49c3fd;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 50%;
  }
}
