@import './DateInput.scss';

.articleSelectInputDateContainer {
  &::after {
    background-color: transparent;
    background-image: url("https://kombatlink-assets.azureedge.net/img/grey_calendar.svg");
    opacity: 0.3;
  }
}

.articleSelectSelect {
  background: white;
}

.articleSelectInputDate {
  border: 1px solid #e8eef6;
}
