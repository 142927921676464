@mixin DropDownTableMedia($mobileTableWidth, $compactTableWidth) {
  //----- commmon

  @media screen and (max-width: 575.98px) {
    .tableButton,
    .table__open {
      margin-top: 8px;
    }
  }

  @media screen and (max-width: $mobileTableWidth) and (min-width: 768px) {
    .tableColumn {
      width: 50%;
      padding: 3px 0;
    }
    .tableSecondaryRowColumn {
      width: 100%;
    }
  }
  
  @if $compactTableWidth != null {
    @media screen and (max-width: $compactTableWidth) and (min-width: $mobileTableWidth) {
      .tableColumn {
        padding: 0 10px;
      }
    }

    @media screen and (max-width: $compactTableWidth) {
      .tableSecondaryRow {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  
  @media screen and (max-width: $mobileTableWidth) {
    .tableHeadersRow {
      display: none;
    }
  }
  
  @media screen and (max-width: $mobileTableWidth) {
    .tableMainRow {
      flex-wrap: wrap;
      height: auto;
      padding: 20px;
    }
  }
  
  @if $compactTableWidth != null {
    @media screen and (max-width: $compactTableWidth) and (min-width: $mobileTableWidth) {
      .tableRow {
        margin-bottom: 15px;
      }
    }
  }
  
  @media screen and (max-width: $mobileTableWidth) {
    .tableRowColumn {
      &::before {
        display: inline-block;
      }
    }
  }
  
  @media screen and (max-width: $mobileTableWidth) {
    .tableSecondaryRow {
      flex-wrap: wrap;
      height: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  
  @media screen and (max-width: 767.98px) and (min-width: 576px) {
    .tableColumn {
      width: 100%;
      padding: 3px 0;
    }
  }
  
  @media screen and (max-width: 575.98px) and (min-width: 0) {
    .tableColumn {
      width: 100%;
      padding: 3px 0;
    }
  
    .tableSecondaryRowColumn {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 1299.98px) {
    .tableSecondaryRowContainer {
      &.secondaryRowOpened {
        max-height: 1600px;
      }
    }
  }
  
  @media screen and (max-width: 767.98px) {
    .tableSecondaryRowContainer {
      &.secondaryRowOpened {
        max-height: 1800px;
      }
    }
  }
  
  //----- dark

  @if $compactTableWidth != null {
    @media screen and (max-width: $compactTableWidth) and (min-width: $mobileTableWidth) {
      .tableRow {
        margin-bottom: 15px;
      }
    }
  }

  @media screen and (max-width: $mobileTableWidth) {
    .tablePpenReplacer {
      display: none;
    }
  }

  @media screen and (max-width: 575.98px) and (min-width: 0) {
    .tableWarningContent {
      transform: translate(-80%, 0);

      &::before {
        left: 80%;
      }
    }

    .tableWarning {
      &:hover {
        .tableWarningContent {
          display: block;
        }
      }
    }
  }
}
