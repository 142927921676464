@import '../../../css/images';

.container {
  position: relative;
  background: #25324e;
  border-radius: 4px;
}

.input {
  position: relative;
  background: transparent;
  border: none;
  height: 40px;
  outline: none;
  padding: 0 20px 0 20px;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: white;
}

.clear {
  position: absolute;
  right: 15px;
  top: 13px;
  width: 15px;
  height: 15px;
  background-color: transparent;
  background-size: 10px 9px;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  text-decoration: none;
  background-image: url($cross);
  cursor: pointer;
  z-index: 0;
  opacity: 0;
  transition: 100ms;

  &.visible {
    opacity: 1;
    z-index: 2;
  }
}

.simpleClear {
  z-index: 0;
  opacity: 0;

  &.visible {
    opacity: 1;
    z-index: 2;
  }
}
