@import "../utils/vars";
@import "../utils/mixins";

$compactTableWidth: null !default;
$mobileTableWidth: 1199.98px !default;

.table {
  &__headers-row {
    height: 66px;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__row {
    flex-wrap: nowrap;
    align-items: center;
    height: 70px;
    background: rgba(255, 255, 255, 0.1);
    margin-bottom: 15px;
    transition: 150ms;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  &__column {
    color: white;
    font-size: 16px;
    line-height: 23px;
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    letter-spacing: 0.05em;
    padding: 0 20px;
    width: 100%;

    &::before {
      display: none;
      opacity: 0.5;
      margin-right: 20px;
    }
  }

  &__link {
    text-decoration: none;
    color: white;
    transition: 150ms;

    &:hover {
      text-decoration: none;
      color: #49c3fd;
    }
  }

  &__label {
    display: inline-block;
    width: 84px;
    height: 30px;
    border-radius: 2px;
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;

    &--grey {
      background: #f3f7fb;
      color: #c3cedb;
    }

    &--green {
      background: rgba(108, 208, 0, 0.2);
      color: #6cd000;
    }

    &--red {
      background: rgba(255, 59, 76, 0.1);
      color: #e13b4c;
    }

    &--orange {
      background: rgba(242, 104, 49, 0.1);
      color: #f26831;
    }
  }

  &__warning {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    top: 3px;
    margin-left: 15px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url("https://kombatlink-assets.azureedge.net/img/red_warning.svg");

    &-text {
      height: auto;
      padding: 10px 9px;
      background: white;
      color: #001030;
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      width: 236px;
      text-align: center;
    }

    &-content {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translate(-50%, 0);
      background: transparent;
      display: none;
      padding-bottom: 10px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 2px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top: 3px solid white;
      }
    }

    &:hover &-content {
      display: block;
    }
  }

  &__button {
    width: 140px;
    height: 40px;
    margin-left: 10px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

$compactTableWidth: 1200px !default;

@if $compactTableWidth != null {
  @media screen and (max-width: $compactTableWidth) and (min-width: 1200px) {
    .table__column {
      padding: 0 10px;
    }
  }
}

@media screen and (max-width: $mobileTableWidth) {
  .table__headers-row {
    display: none;
  }
  .table__row {
    flex-wrap: wrap;
    align-items: start;
    height: auto;
    padding: 15px 20px;
  }
  .table__column {
    padding: 3px 0;
  }
  .table__row .table__column::before {
    display: inline-block;
  }
}

@media screen and (max-width: $mobileTableWidth) and (min-width: 768px) {
  .table__column {
    width: 50%;
    padding: 3px 0;
  }
  .table__row {
    padding: 15px 20px;
  }
}

@media screen and (max-width: 767.98px) and (min-width: 576px) {
  .table__column {
    width: 100%;
  }
  .table__row {
    padding: 15px 20px;
  }
}

@media screen and (max-width: 399.98px) and (min-width: 0) {
  .table__row {
    padding: 12px 16px;
  }
}
