@import '../../../css/images';

.container {
  position: relative;
  background: white;
  border-radius: 4px;
}

.input {
  height: 40px;
  background: rgba(0,0,0,0);
  border-radius: 4px;
  border: solid 1px #e7e8ff;
  width: 100%;
  outline: none;
  padding: 0 20px;
  font-family: Burbank-Big-Rg-Md,sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: .05em;
  color: #001030;
  position: relative;
  z-index: 1;
}

.clear {
  position: absolute;
  right: 15px;
  top: 13px;
  width: 15px;
  height: 15px;
  background-color: transparent;
  background-size: 10px 9px;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  text-decoration: none;
  background-image: url($grey_cross);
  cursor: pointer;
  transition: 100ms;
}