@keyframes showBackground {
  from {
    width: 100%;
    height: 2px;
  }
  to {
    width: 100%;
    height: 100%;
  }
}

@keyframes showWindow {
  from {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-container {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.modal-container--opened {
  display: block;
}

.modal-container__background {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 2px;
  left: 50%;
  top: 50%;
  background: #051534;
  opacity: 0.7;
  transform: translate(-50%, -50%);
}

.modal-container--opened .modal-container__background {
  width: 100%;
  height: 100%;
  animation: showBackground 350ms ease-in-out;
}

.window {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 870px;
  height: auto;
  max-width: 95vw;
  max-height: 95vh;
  background-color: white;
  z-index: 2;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  opacity: 0;
  overflow: auto;
}

.window__container {
  overflow: auto;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  position: absolute;
  top: 75px;
  left: 50px;
  padding: 30px 20px 10px 20px;
}

.window__header-container {
  padding: 40px 70px 0 70px;
}

.modal-container--opened .window {
  opacity: 1;
  animation: showWindow 350ms ease-in-out;
}

.window {
  &__header {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 30px;
    color: #001030;
    margin-bottom: 30px;
  }

  &__subheader {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: rgba(0, 16, 48, 0.7);
    margin-bottom: 12px;
  }

  &__textarea {
    width: 100%;
    height: 144px;
    background: linear-gradient(90deg, rgba(0, 16, 48, 0.05) 0%, rgba(6, 18, 53, 0.05) 100%);
    border-radius: 4px;
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #001030;
    margin-bottom: 20px;
    resize: none;
    outline: none;
    border: none;
    padding: 20px;
  }

  &__input {
    width: 100%;
    height: 45px;
    background: rgba(0, 16, 48, 0.05);
    border-radius: 4px;
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #001030;
    margin-bottom: 20px;
    outline: none;
    border: none;
    padding-left: 20px;
  }

  &__file-input {
    display: none;
  }

  &__file {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #49c3fd;
    cursor: pointer;
    margin-bottom: 20px;

    &::before {
      content: "";
      display: inline-block;
      width: 13px;
      height: 13px;
      background-color: transparent;
      background-size: 100% 100%;
      background-image: url("https://kombatlink-assets.azureedge.net/img/file.svg");
      margin-right: 7px;
    }
  }

  &__file-label {
    background: linear-gradient(90deg, rgba(0, 16, 48, 0.07) 0%, rgba(6, 18, 53, 0.07) 100%);
    border-radius: 2px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 5px 5px 0 0;
  }

  &__file-name {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    color: rgba(0, 16, 48, 0.7);
    opacity: 0.5;
  }

  &__cancel-file {
    width: 7px;
    height: 7px;
    margin-left: 10px;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url("https://kombatlink-assets.azureedge.net/img/grey_cross.svg");
  }

  &__select {
    width: 100%;
    height: 45px;
    margin-bottom: 20px;
  }

  &__button {
    width: 205px;
    height: 40px;
    margin: 20px 5px 0 5px;
  }

  &__hr {
    display: block;
    height: 1px;
    background: #f2f3f5;
    margin: 5px 0 30px 0;
    width: 100%;
  }

  &__warning {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      background-color: transparent;
      background-size: 100% 100%;
      background-image: url("https://kombatlink-assets.azureedge.net/img/grey_warning.svg");
      opacity: 0.2;
      margin-right: 7px;
      flex-shrink: 0;
    }

    div {
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #001030;
      opacity: 0.5;
    }
  }
}

.window--multi {
  transition: 300ms;
  left: 50%;
  &.window--prev {
    left: -50%;
  }
  &.window--next {
    left: 150%;
  }
}

.window__close {
  display: block;
  text-decoration: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 0;
}

.window__close::after,
.window__close::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  top: 7px;
  background: #333333;
  border-radius: 1px;
}

.window__close::after {
  transform: rotate(45deg);
}

.window__close::before {
  transform: rotate(-45deg);
}

.window__checkbox,
.window__warning-checkbox {
  display: none;
}

.window__label {
  width: 120px;
  height: 45px;
  background: rgba(0, 16, 48, 0.05);
  border-radius: 4px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.05em;
  color: rgba(0, 16, 48, 0.5);
  border: 1px solid transparent;
  transition: 150ms;
  margin-right: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.window__checkbox:checked + .window__label {
  border: 1px solid #49c3fd;
  color: #49c3fd;
}

.window__profile-status {
  position: relative;
  display: block;
  width: 127px;
  height: 27px;
  background: #e13b4c;
  border-radius: 67px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 27px;
  color: white;
  padding: 0 0 0 37px;
  cursor: pointer;
  transition: 150ms;
  z-index: 2;
}

.window__profile-status--open {
  background: #6cd000;
}

.window__profile-status::before {
  width: 10px;
  height: 13px;
  content: "";
  position: absolute;
  left: 20px;
  top: 7px;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url("https://kombatlink-assets.azureedge.net/img/locked.svg");
}

.window__profile-status--open::before {
  background-image: url("https://kombatlink-assets.azureedge.net/img/unlocked.svg");
}

.window__profile-status-warning-container {
  position: absolute;
  bottom: 80px;
  left: 50%;
  width: 300px;
  transform: translate(-50%, 0);
  display: none;
  z-index: 1;
}

.window__profile-status-warning-container::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -15px;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 10px solid #fc5566;
  transform: translate(-50%, 0);
}

.window__profile-status-warning {
  position: relative;
  width: 100%;
  background: #fc5566;
  border-radius: 2px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: white;
  padding: 10px 5px;
}

.window__profile-status-warning a {
  color: white;
  text-decoration: underline;
  font-family: "Burbank-Big-Cd-Md", sans-serif;
  font-weight: bold;
}
.window__profile-status-warning a:hover {
  color: white;
  text-decoration: underline;
}

.window__checkbox {
  display: none;
}

.window__checkbox:checked ~ .window__profile-status-warning-container {
  display: block;
}

@media screen and (max-width: 575.98px) {
  .window__container {
    width: calc(100% - 20px);
    height: calc(100% - 130px);
    position: absolute;
    top: 110px;
    left: 10px;
    padding: 30px 20px 10px 20px;
  }
  .window__header-container {
    padding: 20px 30px 0 30px;
    z-index: 10;
  }
  .window__profile-status {
    margin: 5px 0 30px 0;
  }
  .window__profile-status-warning-container {
    left: 130px;
    bottom: 70px;
  }
  .window__show-warning {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #333333;
    cursor: pointer;
    margin: 7px 0 0 -10px;
  }

  .window__show-warning::before {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    top: 3px;
    left: 8px;
    background: #333333;
  }
  .window__show-warning::after {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 9px;
    top: 7px;
    left: 8px;
    border-radius: 1px;
    background: #333333;
  }
  .window__profile-status-warning-container {
    display: none;
  }
  .window__warning-checkbox:checked ~ .row .col-auto .window__profile-status-warning-container {
    display: block;
  }
  .window__checkbox:checked ~ .row .col-auto .window__profile-status-warning-container {
    display: none;
  }
  .window__checkbox:checked ~ .row .col-auto .window__show-warning {
    display: none;
  }
}
