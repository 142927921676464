@import '../../../css/images';

.textarea {
  width: 100%;
  background: #25324e;
  border-radius: 4px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: white;
  padding: 20px 20px 20px 20px;
  border: none;
  outline: none;
  resize: none;
}
