.player-transaction-css {
  @import '../../../css/playerTransactions';

  .info__icon--money {
    background-image: url(https://kombatlink-assets.azureedge.net/img/total_tournament.png);
  }

  @media screen and (max-width: 575.98px) {
    .window {
      left: 42%;
      top: 20%;
    }
  }
}


.player-transaction-css .buy__button {
  width: 200px;
  height: 40px;
  margin-left: 10px;
  background: linear-gradient(90deg, #02B7FE 0%, #0389FD 100%);
  border-radius: 2px;
  color: #fff;
}
.player-transaction-css .buy__button:hover
{
  color: #000;
}
button:focus
{
  outline: none;
}
.player-transaction-css .tab-menu__link {
  opacity: 0.5;
  
}
.player-transaction-css .buy__button {
  padding-top: 5px;
}

@media(max-width: 1200px)
{
  .player-transaction-top-left .info {
    padding: 29px 0 29px 20px;
}
.loyalty-point-wrapper {
  flex-direction: column;
}
.loyalty-point-wrapper-left {
  max-width: 100%;
  margin-bottom: 20px;
}
.loyalty-point-wrapper-right {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
}
@media(max-width: 1024px)
{
  .player-transaction-top {
    flex-direction: column;
}
.player-transaction-top-left {
  max-width: 100%;
}
.player-transaction-top-left .article__info.info {
  padding-left: 15px;
}
.modal-container__window.window.payment-model {
  position: fixed;
  width: 68%;
  padding: 20px;
  transform: none;
  left: 0;
  right: 0;
  margin: auto;
  top: 10%;
}
.payment-model .window__button {
  width: auto;
  padding: 10px 30px;
}
.payment-model .card {
  margin-bottom: 15px;
  border: 1px solid #ccc;
}
}
@media(max-width: 991px)
{
  .modal-container__window.window.payment-model {
    width: 78%;
  }
}
@media(max-width: 767px)
{
  .loyalty-point-wrapper-right .buy__subheader {
    width: 100%;
}
.loyalty-point-wrapper-right .buy__select {
  width: 100%;
}
.loyalty-point-wrapper-right .buy__select-container {
  padding: 0 ;
}
.loyalty-point-wrapper-right .buy__select-container .row {
  width: 100%;
  margin: 0;
}
.loyalty-point-wrapper-right {
  padding-right: 0;
}
.payment-model .window__button {
  padding: 10px 21px;
}
.payment-model .container-fluid {
  padding: 0;
}
.payment-model .card-body .row {
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}
.payment-model .card-body .row > div {
  padding: 0;
  font-size: 14px;
  width: auto !important;
}
.payment-model .window__subheader {
  padding-left: 15px;
  padding-right: 15px;
}
}
@media(max-width: 480px)
{
  .payment-model .card-body .row > div {
    font-size: 12px;
}
.payment-model .window__button {
  width: auto;
  padding: 10px 13px;
  height: 35px;
}
.payment-model .window__subheader {
  line-height: 1.3 !important;
}
.modal-container__window.window.payment-model {
  left: 11%;
}
}