@import '../../../../../css/images';

.chatField {
  width: 100%;
  height: 50px;
  background: white;
  position: relative;
}

.chatFieldTextarea {
  border: none;
  outline: none;
  background: transparent;
  height: 50px;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  resize: none;
  padding-top: 17px;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #001030;
  opacity: 0.8;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
}

.chatFieldButton {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background-size: 100% 100%;
  background-image: url($send);
  margin-top: 18px;
  margin-left: 10px;
}

.loading {
  color: #0389fd;
  margin-top: 18px;
  margin-left: 10px;
}