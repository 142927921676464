@import "./DropDownTable.scss";
@import "./DropDownTableMixinsLight.module.scss";

.tableSecondaryRowContainer {
  background: white;

  &.secondaryRowOpened {
    background: white;
    transform: translate(0) scaleY(1);
    max-height: 530px;
    padding: 15px 0;
  }
}

.tableMainRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 70px;
  z-index: 2;
  transition: 300ms;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  background: white;

  &.opened {
    border-bottom: 1px solid #e8ebef;
  }

  &.highlighted {
    background: hsla(0, 0%, 100%, 0.2);
    border: 1px solid #49c3fd;
  }
}

.tableRadio {
  width: 15px;
  height: 15px;
  border: 1px solid #5f6d8b;
  box-sizing: border-box;
  border-radius: 2px;
  background-size: 9px 8px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center center;
  cursor: pointer;
  transition: 150ms;

  &.tableRadioChecked + .tableRadioLabel {
    background-color: #3c3daa;
    border: 1px solid #3c3daa;
    background-image: url($ok);
    opacity: 1;
  }
}

.tableRadioLabel {
  width: 14px;
  height: 15px;
  opacity: 0.4;
  border: 1px solid #d5dae0;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: transparent;
  background-size: 10px 8px;
  background-position: center center;
  background-repeat: no-repeat;
  transition: 200ms;
  cursor: pointer;
  margin: 0 0 3px 0;
}

.tableRadioText {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  color: #001030;
  opacity: 0.5;
  margin: 0 0 0 10px;
}

.tableColumn {
  color: #001030;
}

.tableLink {
  color: #001030;
}

.table__open {
  border: 1px solid #dde3ec;
  margin: 0;

  &.opened {
    background-color: #f1f5fa;
    border: 1px solid #f1f5fa;
  }

  &::before {
    opacity: 1;
    background-image: url($arrowTopDark);
  }
}

.tableHide {
  border: 1px solid #dde3ec;
  margin: 0 10px 0 0;

  &::before {
    opacity: 1;
    background-image: url($darkHide);
  }
}

.tableRow {
  .tableButton {
    margin-right: 10px;

    &:last-of-type {
      margin-left: 0;
    }
  }
}

.tableWarningText {
  background: #e13b4c;
  color: white;
}

.dropDownTableMediaQueries {
  @include DropDownTableMedia($mobileTableWidth, null);
}
.disputesMediaQueries {
  @include DropDownTableMedia($disputesMobileTableWidth, null);
}
