
.article__header {
  margin-bottom: 50px;
}
.window {
  width: 640px;
  height: auto;
  padding: 50px 70px 35px 50px;
}

.tabs {
  margin-top: 35px;

  @media screen and (max-width: 575.98px) {
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &__tab {
    width: 100px;
    height: 36px;
    border: 1px solid #3c3daa;
    font-family: "Burbank-Big-Cd-Bd", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: white;
    cursor: pointer;

    @media screen and (max-width: 384.98px) {
      width: 80px;
    }

    &:hover {
      color: white;
      text-decoration: none;
    }

    &__active {
      background: #3c3daa;
    }

    &:first-of-type {
      border-radius: 2px 0 0 2px;
    }

    &:last-of-type {
      border-radius: 0 2px 2px 0;
    }
  }
}

.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.445);
}

.scroll::-webkit-scrollbar-track {
  background-color: #051534;
}

.scroll::-webkit-scrollbar {
  width: 10px;
  height: 7px;
  border: none;
  overflow: hidden;
  border-radius: 0 3px 3px 0;
}

.scroll {
  scrollbar-color: rgba(255, 255, 255, 0.445) #051534;
  scrollbar-width: thin;
}
.disabledRow{
  opacity:0.5;
  pointer-events:none;
}