$compactTableWidth: 1499.98px;
$mobileTableWidth: 1399.98px;

.article__tab-menu-before-container {
  margin-top: 0;
}

.table__column {
  &__tournament {
    width: 100%;

    &::before {
      content: "Tournament";
    }
  }
  &__round {
    width: 50%;

    &::before {
      content: "Round";
    }
  } 
  &__match {
    width: 50%;

    &::before {
      content: "Match";
    }
  }
  &__user {
    width: 340%;

    &::before {
      content: "Author";
    }
  }
  &__date {
    width: 100%;

    &::before {
      content: "Date";
    }
  }
  &__venue {
    width: 80%;

    &::before {
      content: "Venue";
    }
  }
  &__button {
    width: 200%;
  }
  &__brackets {
    width: 140%;

    &::before {
      content: "Brackets";
    }
  }
  &__type {
    width: 80%;

    &::before {
      content: "Type";
    }
  }
  &__link {
    width: 280%;

    &::before {
      content: "Link";
    }
  }
  &__buttons {
    width: 300px;
    flex-shrink: 0;
  }
}

@media screen and (max-width: $mobileTableWidth) {
  .table__column__buttons {
    width: 100% !important;
  }
}

@media screen and (max-width: 1199.98px) {
  .table__column__tournament {
    width: 100%;
  }
}

@media screen and (max-width: 399.98px) {
  .table__button {
    width: 90px;
  }
  .table__column__link a {
    word-break: break-word;
  }
}

.table__delete {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.2);
  background-size: 21px 21px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("https://kombatlink-assets.azureedge.net/img/grey_delete.svg");
  transition: 150ms;
  margin-left: 10px;

  &:hover {
    background-image: url("https://kombatlink-assets.azureedge.net/img/white_delete.svg");
    background-color: #e13b4c;
  }
}

.table__secondary-row .table__column::before {
  display: none !important;
}

.table__secondary-row .table__column__user::before {
  display: inline-block !important;
}

.table__profilePicture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

@media screen and (max-width: 1699.98px) {
  .right-menu {
    position: fixed;
    right: -255px;
    transition: 150ms;
    height: calc(100vh - 82px);
    overflow: auto;
    min-height: calc(100vh - 82px);
    top: 82px;
    z-index: 10000;
  }
  .right-menu--opened {
    right: 0;
  }
  .header__section--friends {
    display: flex;
  }
}

.table__link {
  text-decoration: none;
  color: #fff;
  -webkit-transition: .15s;
  -o-transition: .15s;
  transition: .15s;
}