.submissionModal {
  &.window__big {
    width: 640px;
    padding: 50px 70px 40px 70px;
  }

  @media screen and (max-width: 575.98px) {
    &.window__big {
      padding: 40px 30px;
    }
  }
}


.screenshotsPreview {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 15px;
}

.imagePreview {
  height: 100px;
  width: 100px;
  cursor: pointer;
}

.image_thumbnail
{
  margin: 10px;
}