label.window__cancel-container._borderless {
  border: none;
  margin-top: 10px;
  padding: 0;
  padding-top: 15px;
  height: fit-content;
  width: 100%;
}
label.window__cancel-container._borderless span{
  font-size: 22px;
}
label.window__cancel-container._active {
  border-color: #0393fe;
}

.window__cancel-container {
  width: 153px;
  height: 63px;
  border: 1px solid rgba(0, 16, 48, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 13px 0 16px 25px;
  display: block;
  transition: 150ms;
  cursor: pointer;
  margin: 0 5px 10px 5px !important;
}

.window__cancel-time {
  display: block;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #001030;
  width: 100%;
  transition: 150ms;
}

.window__cancel-date {
  display: block;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #001030;
  width: 100%;
  transition: 150ms;
}
.window__checkbox:checked + .window__cancel-container {
  border: 1px solid #e13b4c;
}
.window__checkbox:checked + .window__cancel-container .window__cancel-date,
.window__checkbox:checked + .window__cancel-container .window__cancel-time {
  color: #e13b4c;
}