.icon {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid rgba(0, 16, 48, 0.2);
  background: rgba(0, 0, 0, 0);
  opacity: 1;
  transition: 150ms;
  margin-bottom: 30px;
  margin-top: 20px;
  margin-right: 10px;
  flex-shrink: 0;
}

.icon::before {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  height: 2px;
  top: 3px;
  left: 6px;
  background: rgba(0, 16, 48, .2);
}

.icon::after {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  height: 6px;
  top: 6px;
  left: 6px;
  background: rgba(0, 16, 48, .2);
}

.text {
  font-family: 'BurbankSmall-Medium', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 16, 48, 0.5);
  margin-top: 20px;
  max-width: 710px;
}