.match__result {
  width: 870px;
  padding: 50px 35px;
}

.window {
  width: 870px;
  padding: 50px 35px;

  @media screen and (max-width: 575.98px) {
    padding: 30px 10px;
  }

  .window__header {
    font-family: 'BurbankSmall-Bold', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: #001030;
    margin-bottom: 20px;
    padding: 0 20px 0 30px;
  }

  .window__label {
    width: 159px;
    height: 30px;
    background: rgba(73, 195, 253, 0.1);
    border-radius: 2px;
    text-align: center;
    font-family: 'BurbankSmall-Bold', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #49c3fd;

    @media screen and (max-width: 575.98px) {
      margin-left: 30px !important;
    }
    &.host__label {
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      height: 25px;
      line-height: 25px;
    }
  }
}

.window__subheader__dispute {
  color: #e13b4c;
  padding-left: 30px;
  padding-bottom: 10px;
}

.window.windowFfa {
  width: 700px;
}

.windowPlayer {
  height: 90px;
  padding: 0 30px;
  background: #f5f5f5;
  position: relative;

  @media screen and (max-width: 991.98px) {
    margin-bottom: 10px !important;
  }
  @media screen and (max-width: 575.98px) {
    height: 70px;
    padding: 0 15px;
  }
}

.windowPlayerPicture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;

  @media screen and (max-width: 575.98px) {
    width: 36px;
    height: 36px;
  }
}

.windowPlayerUsername {
  font-family: 'Burbank-Big-Rg-Md', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #001030;
  min-width: 0;
  display: flex;

  @media screen and (max-width: 575.98px) {
    font-size: 14px;
  }

  .windowPlayerUsernameText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &.pending {
      color: rgba(0, 16, 48, 0.3);
    }    
  }
}

.windowPlayerLabel {
  width: 90px;
  height: 30px;
  border-radius: 2px;
  text-align: center;
  font-family: 'BurbankSmall-Bold', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media screen and (max-width: 575.98px) {
    width: 80px;
  }
}

.windowPlayerLabelGreen {
  background: rgba(108, 208, 0, 0.2);
  color: #6cd000;
}

.windowPlayerLabelRed {
  background: rgba(225, 59, 76, 0.2);
  color: #e13b4c;
}

.windowPlayerLabelGray {
  background: rgba(75, 86, 109, 0.27);
  color: #4b566d;
}

.windowPlayerLabelPlace {
  font-size: 1rem;
}
