.reviewTitle {
  font-size: 14px;
  color: #fff;
  letter-spacing: -0.2px;
  padding-right: 10px;
}

.reviewLink {
  font-family: "BurbankSmall-Medium", sans-serif;
  color: white;
  font-size: 13px;

  &:hover {
    text-decoration: none;
    color: #49c3fd;
  }
}

.star {
  line-height: 36px;
}
