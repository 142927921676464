@import '../../../../../css/images';

.chatInputSearchContainer {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 11px;
    height: 11px;
    opacity: 0.5;
    top: 12px;
    right: 16px;
    background-size: 100% 100%;
    background-image: url($grey_search);
  }
}
