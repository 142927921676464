.container {
  height: 100%;
  position: absolute;
  width: 100%;
}

.loadingLight {
  color: #3c3daa;
}

.loadingDark {
  color: rgb(51, 120, 64);
}
