@import "../../../css/libs/modals.scss";

@media screen and (max-width: 1719.98px) {
  .right-menu {
    position: fixed;
    right: -255px;
    transition: 150ms;
    height: calc(100vh - 82px);
    overflow: auto;
    min-height: calc(100vh - 82px);
    top: 82px;
  }
  .right-menu--opened {
    right: 0;
  }
  .header__section--friends {
    display: flex;
  }
}