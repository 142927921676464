.medHead {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  margin-bottom: 12px;
  margin-top: 30px;
}

.text {
  font-size: 15px;
  color: #fff;
  line-height: 25px;
}

.list {
  margin: 0;
  padding: 0 0 0 18px;
  color: #fff;
}
