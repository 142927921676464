.warning {
  top: 0px;
  margin-left: 7px;
  text-transform: none;
}

.tableColumnId {
  width: 120px;
  flex-shrink: 0;

  &::before {
    content: 'Dispute ID';
  }
}

.tableColumnPlayerNumber {
  width: 120px;
  flex-shrink: 0;
}

.tableColumnEventId {
  width: 100%;

  &::before {
    content: 'Event ID';
  }
}

.tableColumnEvent {
  width: 250%;

  &::before {
    content: 'Event';
  }
}

.tableColumnRound {
  width: 80%;

  &::before {
    content: 'Round';
  }
}

.tableColumnResolved {
  width: 150px;
  flex-shrink: 0;

  &::before {
    content: 'Resolved';
  }
}

.tableColumnButtons {
  width: 394px;
  flex-shrink: 0;
}

.tableColumnPlayer {
  width: 180%;
}

.tableColumnExplanation {
  width: 300%;
}

.tableColumnButton {
  width: 150px;
  flex-shrink: 0;
}

.tableColumnResolved {
  div {
    width: 75px;
    height: 30px;
    border-radius: 2px;
    font-family: 'BurbankSmall-Medium', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: white;
    background: rgba(255, 255, 255, 0.1);
  }

  .yes {
    background: rgba(108, 208, 0, 0.2);
    color: #6cd000;
  }

  .no {
    background: rgba(255, 59, 76, 0.1);
    color: #e13b4c;
  }
}

.tableColumnPlayerNumber {
  opacity: 0.6;
  font-size: 15px;
  font-family: 'BurbankSmall-Medium', sans-serif;
}

.tableColumnExplanation {
  a {
    color: white;
    text-decoration: none;
    transition: 150ms;

    &:hover {
      color: #49c3fd;
      text-decoration: underline;
    }
  }

  &::before {
    content: 'Explantation';
    font-family: 'BurbankSmall-Medium', sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 12px;
    color: white;
    opacity: 0.36;
    margin-right: 20px;
  }
}

.tableColumnWinner {
  opacity: 1;
}

.tableColumnLabelText {
  font-family: 'BurbankSmall-Medium', sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 17px;
  color: white;
  margin: 0 0 0 7px;
}

.tableColumnLabel {
  opacity: 0.4;
  border: 1px solid #ffffff;
  background-size: 10px 8px;

  &:last-of-type {
    margin-left: 30px;
  }

  &.tableColumnLabelChecked {
    background-color: #6cd000;
    background-image: url('https://kombatlink-assets.azureedge.net/img/ok.svg');
    opacity: 1;
    border: none;
  }
}

.tableUserProfilePicture {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.tableUsername {
  font-family: 'Burbank-Big-Rg-Md', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  align-items: center;
  letter-spacing: 0.05em;
  color: white;
  text-decoration: none;
  transition: 150ms;
  margin-left: 15px;

  &:hover {
    text-decoration: none;
    color: #49c3fd;
  }
}

.tableMakeWinner {
  width: 158px;
  height: 36px;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 2px;
  opacity: 0.5;
  font-family: 'Burbank-Big-Cd-Bd', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: white;
  background: transparent;
  outline: none;
  transition: 150ms;

  &:hover {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    border: none;
    opacity: 1;
  }
}

.tableViewListing {
  background: linear-gradient(90deg, #cb25b3 0%, #7d2eae 100%);

  &:hover {
    background: linear-gradient(90deg, #a91493 0%, #640d9a 100%);
  }
}

.tableDisputeChat {
  background: #3c3daa;
  transition: 150ms;

  &:hover {
    background: #373895;
  }
}

.tableWinner {
  width: 90px;
  height: 30px;
  background: rgba(249, 209, 0, 0.2);
  border-radius: 2px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  color: #f9d100;
}

.window {
  height: auto;
  max-height: 90vh;
  overflow: auto;
  width: 640px;
  max-width: 90%;
  padding: 50px 70px 40px 70px;
}

.windowHeader {
  font-family: "BurbankSmall-Medium", sans-serif;
  letter-spacing: 0;
  font-size: 21px;
  line-height: 35px;
  margin-bottom: 35px;
}

.windowSubheader {
  margin-top: 0;
}

.disputesMediaQueries {
  @media screen and (max-width: 1819.98px) and (min-width: 1300px) {
    .tableColumnButton {
      width: 384px;
      flex-shrink: 0;
    }
    .tableColumnExplanation {
      width: 400%;
    }
  }

  @media screen and (max-width: 1379.98px) and (min-width: 1300px) {
    .tableColumn {
      padding: 0 10px;
      font-size: 15px;
    }
    .tableColumn:first-of-type {
      padding: 0 10px 0 20px;
    }
    .tableColumn:last-of-type {
      padding: 0 20px 0 10px;
    }
    .tableColumnButton {
      width: 374px;
      flex-shrink: 0;
    }
    .tableColumnId,
    .tableColumnPlayerNumber {
      width: 100px;
    }
  }

  @media screen and (max-width: 1359.98px) {
    .tableColumnExplanation {
      &::before {
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }

  @media screen and (max-width: 1299.98px) {
    .tableHeadersRow {
      display: flex;

      .tableColumn:not(.tableHeadersRowColumnButtons) {
        display: none;
      }
      .tableHeadersRowColumnButtons {
        width: 100%;
        padding: 0 15px;
      }
    }
    .tableSecondaryRowContainer {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  @media screen and (max-width: 1299.98px) {
    .tableMainRowColumnButtons {
      position: absolute;
      right: 0;
      top: 20px;
      width: 384px;
    }
  }

  @media screen and (max-width: 767.98px) {
    .tableMainRowColumnButtons {
      position: relative;
      top: 0 !important;
      width: 100% !important;
    }
  }

  @media screen and (max-width: 1819.98px) and (min-width: 1300px) {
    .tableSecondaryRowColumnButtons {
      display: none;
    }
  }

  @media screen and (max-width: 1299.98px) {
    .tableButton {
      margin: 0 0 8px 0;
    }
    .tableOpen {
      margin: 0;
    }
  }

  @media screen and (max-width: 1359.98px) {
    .tableUsername {
      font-size: 15px;
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 1579.98px) {
    .tableMakeWinner {
      width: 140px;
    }
  }

  @media screen and (max-width: 649.98px) {
    .window {
      padding: 30px 20px 30px 20px;
    }
  }
  
  @media screen and (max-width: 506.98px) {
    .windowButton {
      width: 100%;
    }
  }
  
}
