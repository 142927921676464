.articlePagesContainer {
  margin: 30px 0;
}

.articlePageNavigation {
  font-family: "BurbankSmall-Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  opacity: 0.3;
  cursor: default;
  pointer-events: none;

  &:hover {
    text-decoration: none;
  }

  &.prevPage {
    margin-right: 40px;
  }
  &.nextPage {
    margin-left: 40px;
  }

  &.activeNavigation {
    opacity: 1;
    color: #49c3fd;
    cursor: pointer;
    pointer-events: auto;
    &:hover {
      text-decoration: none;
      color: #49c3fd;
    }
  }
}

.articlePage,
.articlePageDots {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 14px;
  text-decoration: none;
}

.articlePage:hover {
  text-decoration: none;
}

.articlePage {
  margin: 0 10px;

  &.articlePageActive {
    width: 28px;
    height: 32px;
    background: #49c3fd;
    border-radius: 4px;
    text-align: center;
    line-height: 32px;
    color: white;
    cursor: default;
    pointer-events: none;

    &:hover {
      color: white;
    }
  }
}

@media screen and (max-width: 575.98px) {
  .articlePrevPage {
    margin-right: 15px;
  }
  .articleNextPage {
    margin-left: 15px;
  }
  .articlePage {
    margin: 0 5px;
  }
}

@media screen and (max-width: 389.98px) {
  .articlePrevPage,
  .articleNextPage {
    display: none;
  }
  .articlePage {
    margin: 0 8px;
  }
}
@media screen and (max-width: 450px) {
  .articlePageNavigation {
    &.prevPage {
      margin-right: 20px;
      
    }
    &.nextPage {
      margin-left: 20px;
     
    }
  }
}
@media screen and (max-width: 399px) {
  .articlePageNavigation {
    &.prevPage {
      margin-right: 0px;
      
    }
    &.nextPage {
      margin-left: 0px;
     
    }
  }
}
