.avatar {
  width: 100%;
  border-radius: 50%;
}

.emptyBlock {
  width: 100%;
  height: 156px;
}

.button {
  height: 40px;
}

.left {
  flex: 0 0 22%;
  border-right: 1px solid #25324e;
  padding-right: 30px;
}

.leftTile {
  font-family: BurbankSmall-Bold, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: white;
}

.rightTile {
  font-family: BurbankSmall-Bold, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  border-bottom: 1px solid #25324e;
  margin-bottom: 20px;
}

.friends {
  margin-left: -0.5rem;
}

.friendAvatar {
  width: 61px;
  height: 61px;
  border-radius: 50%;
}

.rightLabel {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-size: 12px;
  opacity: 0.7;
  color: white;
  text-transform: uppercase;
}

.leftValue,
.rightValue {
  margin-left: 10px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: white;
}

.gamesContainer {
  padding: 10px 0;
}

.games {
  display: flex;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.game {
  width: 100px;

  &:nth-of-type(n) {
    margin-left: 10px;
  }

  &:first-of-type {
    margin-left: 0px;
  }

  .gameImg {
    width: 100px;
    height: 130px;
    object-fit: cover;
  }
}

.gameName {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-size: 14px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrow {
  background-image: url("https://kombatlink-assets.azureedge.net/img/grey_arrow_top.svg");
  background: #f0f8ff00;
  user-select: none;
  
  &.arrowleft {
    transform: rotate(-90deg);
  }

  &.arrowRight {
    transform: rotate(90deg);
  }
}

.arrowButton {
  background: rgba(250, 244, 244, 0.2);
  width: 25px;
  flex: auto;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background: rgba(250, 244, 244, 0.3);
  }
}

.greenBorder {
  border-color: #53D600 !important;
  border-bottom: 4px solid;
}

.blueBorder {
  border-color: #02b9ff !important;
  border-bottom: 4px solid;
}

.userStats {
  .purple {
    border-color: #7800FF !important;
    border-bottom: 4px solid;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0 0;
    width: 100%;

    li {
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      padding: 12px 0;

      .statTitle {
        font-size: 14px;
        color: #fff;
        letter-spacing: -0.2px;
        padding-right: 10px;
      }

      .data {
        font-size: 14px;
        color: #53D600;
        font-weight: 700;
        letter-spacing: -0.2px;
      }

      .bar {
        width: 100%;
        display: block;
        border-radius: 100px;
        height: 6px;
        background-color: rgba(255, 255, 255, 0.06);
        overflow: hidden;
        margin-top: 7px;
        margin-bottom: 7px;

        span {
          &.ratio {
            height: 6px;
            background-image: linear-gradient(226deg, #02B9FF 0%, #0388FD 100%);
            display: inline-block;
            float: left;
            border-radius: 100px;
          }
        }
      }
    }
  }
}

.okIkonContainer {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100px;
  height: 130px;
  background-color: rgba(0, 0, 0, 0.61);
}

.okIcon {
  width: 50px;
  height: 130px;
}

.selectContainer {
  z-index: 1;
}