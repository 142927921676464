@import './DateInput.scss';

.articleSelectInputDateContainer {
  &::after {
    background-color: #25324e;
    background-image: url("https://kombatlink-assets.azureedge.net/img/calendar.svg");
    opacity: 0.5;
  }
}

.articleSelectSelect {
  background: #25324e;
}

.articleSelectInputDate {
  border: none;
  color: white;
}
