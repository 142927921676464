.tournament-ticket .card-body {
  opacity: 1;
  .card-header {
    background-color: rgba(0,0,0,.03) !important;
    border-bottom: none;
    margin-bottom: 20px;
  }
  .card-text-wrapper {
    flex-wrap: wrap;
    padding: 0;

  .card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 15px 0 0;
    padding: 15px 5px;
    border-radius: 5px;
    margin-bottom: 30px;
    border: 1px solid rgba(245,245,245,0.5);
    background: #fff;
    box-shadow: 3px 5px 5px #eee;
    &:last-child
    {
      margin-right: 0;
    }
    label {
      font-weight: 600;
      color: #051534;
      text-shadow: none;
      width: 100%;
      display: block;
    }
    span{
      display: block;
      width: 100%;
    }
   
  }
  }

  .card-image{
    padding: 0;
    width: 50%;
     img {
    width: 100% !important;
    height: auto !important;
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 10px;
    max-width: 100%;
}
  }
}
.barcode-title{
  text-align: center;
}
.barcode-wrapper {
    text-align: center;
    padding: 0 12px 12px 12px;;
    border-radius: 0;
    border-left: 5px dotted #444;
    .barcode-title,.barcode,.ticket-fees {
      
      display: flex;
      justify-content: center;
    }
}

@media(max-width: 1024px)
{
  .tournament-ticket .card-body .card-text-wrapper .card-text {
    max-width: 29%;
    flex: 0 0 29%;
    margin: 0 4% 20px 0;
}
.barcode canvas {
  max-width: 100% !important;
  height: 120px !important;
}
.tournament-ticket .card-body .card-image {
  padding: 0;
  width: 100%; 
  margin-top: 15px;
}
}

@media(max-width: 767px)
{
  .tournament-ticket .card-body .card-text-wrapper .card-text {
    max-width: 46%;
    flex: 0 0 46%;
    margin: 0 4% 20px 0;
}
.card-image {
  margin: 20px 0;
}

.barcode canvas {
  max-width: 100% !important;
  height: auto !important;
}
.barcode-wrapper {
  
  margin: 0 15px;
  border-top: 5px dotted #444;
  border-left: none;
}
.barcode-title {
  margin-top: 15px;
}

}

@media(max-width: 480px)
{
  .tournament-ticket .card-body .card-text-wrapper .card-text {
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0 0 20px 0;
} 
.tournament-ticket .card-body .card-text-wrapper {
  flex-wrap: wrap;
  padding: 0;
}
}

.print_only {
  display: none; 
} 

#printView {
  position: absolute;
  opacity: 0;
}
.tournament-ticket {
  position: relative;
  overflow: hidden;
}