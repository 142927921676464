.tableLabel {
  display: inline-block;
  width: 84px;
  height: 30px;
  border-radius: 2px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;

  &.grey {
    background: #f3f7fb;
    color: #c3cedb;
  }

  &.green {
    background: rgba(108, 208, 0, 0.2);
    color: #6cd000;
  }

  &.red {
    background: rgba(255, 59, 76, 0.1);
    color: #e13b4c;
  }

  &.orange {
    background: rgba(242, 104, 49, 0.1);
    color: #f26831;
  }
}