$compactTableWidth: 1499.98px;
$mobileTableWidth: 1399.98px;

.article__select:nth-of-type(1) {
  width: calc(100% / 6);
}
.article__select:nth-of-type(2) {
  width: calc(100% / 8);
}
.article__select:nth-of-type(3) {
  width: calc(100% / 7);
}
.article__select:nth-of-type(4) {
  width: calc(100% / 9.8);
}
.article__select:nth-of-type(5),
.article__select:nth-of-type(6) {
  width: calc(100% / 8);
}
.article__select:nth-of-type(7) {
  width: calc(100% / 9);
}
.article__select:nth-of-type(8) {
  width: calc(100% / 9.8);
}

@media screen and (max-width: 1829.98px) {
  .article__select__input-date {
    font-size: 13px;
  }
}

@media screen and (max-width: 1779.98px) and (min-width: 890px) {
  .article__select:nth-of-type(1) {
    width: calc(100% / 2.4);
  }
  .article__select:nth-of-type(2) {
    width: calc(100% / 4);
  }
  .article__select:nth-of-type(3) {
    width: calc(100% / 3);
  }
  .article__select:nth-of-type(4) {
    width: calc(100% / 6);
  }
  .article__select:nth-of-type(5),
  .article__select:nth-of-type(6) {
    width: calc(100% / 4.286);
  }
  .article__select:nth-of-type(7) {
    width: calc(100% / 5);
  }
  .article__select:nth-of-type(8) {
    width: calc(100% / 6);
  }
}

@media screen and (max-width: 889.98px) and (min-width: 768px) {
  .article__select:nth-of-type(1) {
    width: calc(100% / 2.5);
    order: 1;
  }
  .article__select:nth-of-type(2) {
    width: calc(100% / 3);
    order: 1;
  }
  .article__select:nth-of-type(3) {
    width: calc(100% / 2.5);
    order: 2;
  }
  .article__select:nth-of-type(4) {
    width: calc(100% / 4);
    order: 2;
  }
  .article__select:nth-of-type(5),
  .article__select:nth-of-type(6) {
    order: 3;
    width: calc(100% / 2);
  }
  .article__select:nth-of-type(7) {
    width: calc(100% / 2.86);
    order: 2;
    z-index: 997;
  }
  .article__select:nth-of-type(8) {
    width: calc(100% / 3.75);
    order: 1;
    z-index: 998;
  }
}

@media screen and (max-width: 767.98px) and (min-width: 576px) {
  .article__select:nth-of-type(n) {
    width: 50%;
  }
  .article__select:nth-of-type(2) {
    order: 1;
  }
  .article__select:nth-of-type(3) {
    order: 1;
  }
  .article__select:nth-of-type(4) {
    order: 1;
  }
  .article__select:nth-of-type(7) {
    order: 1;
    z-index: 994;
  }
  .article__select:nth-of-type(8) {
    order: 1;
    z-index: 993;
  }
  .article__select:nth-of-type(5) {
    z-index: 992;
  }
  .article__select:nth-of-type(6) {
    z-index: 991;
  }
}

@media screen and (max-width: 575.98px) {
  .article__select:nth-of-type(n) {
    width: 100%;
  }
}

.right-menu {
  z-index: 999;
}




$mobileTableWidth: 1299.98px;

.table__column {
  &.eventName {
    width: 290%;

    &::before {
      content: 'Event name';
    }
  }
  &.date {
    width: 100%;

    &::before {
      content: 'Date';
    }
  }

  &.time {
    width: 130%;

    &::before {
      content: 'Time';
    }
  }

  &.venue {
    width: 100%;
    &::before {
      content: 'Venue';
    }
  }

  &.location {
    width: 200%;
    &::before {
      content: 'Location';
    }
  }

  &.id {
    width: 50%;
    &::before {
      content: 'Id';
    }
  }

  &.buttons {
    width: 500px;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 1879.98px) {
  .table__column {
    &.buttons {
      width: 384px;
    }
  }
}

.table__secondary_row {
  .table__column {
    &::before {
      display: none !important;
    }

    &.user::before {
      display: inline-block !important;
    }
  }
}

@media screen and (max-width: $mobileTableWidth) and (min-width: 0) {
  .table__column {
    &.eventName {
      width: 100%;
    }
    &.buttons {
      width: 100%;
    }
  }
}


.confirm__modal {
  padding: 35px;
}

.clearFilters {
  color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-top: 10px;
  align-items: center;
  font-size: 20px;
  width: 100%;
  .clearFiltersText {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}