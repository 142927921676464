.old-kl-css {
  .select {
    position: relative;
    overflow: visible;
  }

  .article-select:nth-of-type(1) {
    z-index: 999;
  }

  .article-select:nth-of-type(2) {
    z-index: 998;
  }

  .article-select:nth-of-type(3) {
    z-index: 997;
  }

  .article-select:nth-of-type(4) {
    z-index: 996;
  }

  .article-select:nth-of-type(5) {
    z-index: 995;
  }

  .article-select:nth-of-type(6) {
    z-index: 994;
  }

  .article-select:nth-of-type(7) {
    z-index: 993;
  }

  .article-select:nth-of-type(8) {
    z-index: 992;
  }

  .article-select:nth-of-type(9) {
    z-index: 991;
  }

  .article-select:nth-of-type(10) {
    z-index: 990;
  }

  .select__title {
    position: absolute;
    text-align: left;
    font-size: 14px;
    line-height: 40px;
    font-family: "Burbank Small", sans-serif;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0 20px;
  }

  .select__title::before {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 17px;
    width: 10px;
    height: 6px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url("https://kombatlink-assets.azureedge.net/img/arrow_top.svg");
    transform: rotate(180deg);
    transition: 150ms;
  }

  .select--opened .select__title::before {
    transform: rotate(0);
  }

  .select__container {
    position: absolute;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    align-items: center;
    -webkit-align-items: center;
    width: 100%;
    left: 0;
    top: 45px;
    transform: translateY(-50%) scaleY(0);
    transition: 200ms;
    background-color: #25324e;
    padding: 0;
    border-radius: 4px;
  }

  .select--opened .select__container {
    transform: translateY(0%) scaleY(1);
  }

  .select__option {
    position: relative;
    height: 30px;
    width: 100%;
    font-size: 13px;
    line-height: 30px;
    font-family: "Burbank Small", sans-serif;
    color: white;
    cursor: pointer;
    text-align: left;
    padding: 0 0 0 15px;
    flex-shrink: 0;
    transition: 130ms;
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
  }

  .select__option:hover {
    background: rgba(255, 255, 255, 0.07);
  }

  .select__option:first-of-type {
    margin-top: 7px;
  }

  .select__option:last-of-type {
    margin-bottom: 7px;
  }

  .select__option--selected {
    color: #49c3fd;
  }

  .select__option::after {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 10px;
    width: 12px;
    height: 9px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url("https://kombatlink-assets.azureedge.net/img/blue_ok.svg");
    transition: 130ms;
    opacity: 0;
  }

  .select__option--selected::after {
    opacity: 1;
  }

  .select__search-container {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding: 0 6px;
  }

  .select__search-container::after {
    content: "";
    display: block;
    position: absolute;
    background-color: transparent;
    background-size: 100% 100%;
    width: 10px;
    height: 10px;
    right: 16px;
    top: 20px;
    z-index: 1;
    background-image: url("https://kombatlink-assets.azureedge.net/img/search.svg");
    opacity: 0.3;
  }

  .select__search {
    width: 100%;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    padding: 0 10px;
    font-family: "Burbank Small", sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 13px;
    color: white;
    border: none;
    outline: none;
    z-index: 2;
    margin: 10px 0;
  }

  .article__selects-container {
    position: relative;
    z-index: 1;
  }

  .article__selects-container--opened {
    z-index: 3;
  }

  .article__open-selects {
    height: 40px;
    width: 180px;
    display: none;
  }

  .article__selects-popup-header {
    display: none;
    height: 40px;
    color: white;
    font-size: 24px;
    font-family: "Burbank-Big-Cd-Bd", sans-serif;
    letter-spacing: 0.05em;
    line-height: 24px;
    margin-bottom: 8px;
    position: relative;
  }

  .article__selects-popup-close {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    width: 20px;
    height: 18px;
    background-size: 100% 100%;
    cursor: pointer;
    background-image: url("https://kombatlink-assets.azureedge.net/img/cross.svg");
  }

  @media screen and (max-width: 767.98px) {
    .article__open-selects {
      display: flex;
    }
    .article__selects-popup-header {
      display: block;
    }
    .article__selects-popup {
      position: fixed;
      width: 100%;
      height: calc(100vh - 60px);
      left: 0;
      z-index: 999;
      margin: 0;
      padding: 30px 30px 0 20px;
      overflow: auto;
      top: 100%;
      transition: 250ms;
    }
    .article__selects-popup--opened {
      top: 60px;
    }
  }

  @media screen and (max-width: 575.98px) {
    .article__open-selects {
      display: flex;
      margin-bottom: 20px;
    }
  }
}
