@import '../../../css/images';

.label {
  position: relative;
  width: 48%;
  height: 54px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}

.container {
  z-index: 999;
  display: block;
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: 200px;
  padding-bottom: 7px;
  -webkit-transform: translate(-85%);
  -ms-transform: translate(-85%);
  transform: translate(-85%);
  opacity: 0;
}

.label:hover {
  .container {
    -webkit-animation: show .15s ease-in-out;
    animation: show .15s ease-in-out;
    opacity: 1;
  }
}

.content {
  display: block;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  background: #fff;
  font-family: 'BurbankSmall-Medium', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #001030;
  position: relative;
  border-radius: 2px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 85%;
    bottom: -11px;
    width: 0;
    height: 0;
    border: 8px solid rgba(0, 0, 0, 0);
    border-top: 4px solid #fff;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

.block {
  position: absolute;
  display: block;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: hsla(0, 0%, 100%, 0.2);
  border-radius: 50%;
  background-size: 8px 11px;
  background-image: url($white_lock);
  background-position: 50%;
  background-repeat: no-repeat;
  transition: .15s;
}

.label:hover {
  .block {
    background-color: #fff;
    background-image: url($purple_lock);
  }
}

.error {
  border: 1px solid rgb(230, 72, 70) !important;
}
