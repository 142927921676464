.article__pages-container {
  margin: 30px 0;
}

.article__prev-page {
  font-family: "BurbankSmall-Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: white;
  opacity: 0.3;
  margin-right: 40px;
}

.article__next-page {
  font-family: "BurbankSmall-Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #49c3fd;
  margin-left: 40px;
  text-decoration: none;
}

.article__prev-page:hover {
  text-decoration: none;
  color: white
}

.article__next-page:hover {
  text-decoration: none;
  color: #49c3fd;
}

.article__page,
.article__page-dots {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 14px;
  color: white;
  text-decoration: none;
}

.article__page:hover {
  text-decoration: none;
  color: white;
}

.article__page {
  margin: 0 10px;
}

.article__page--active {
  width: 28px;
  height: 32px;
  background: #49c3fd;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  color: white;
}

.article__page--active:hover {
  color: white;
}

@media screen and (max-width: 575.98px) {
  .article__prev-page {
    margin-right: 15px;
  }
  .article__next-page {
    margin-left: 15px;
  }
  .article__page {
    margin: 0 5px;
  }
}

@media screen and (max-width: 389.98px) {
  .article__prev-page,
  .article__next-page {
    display: none;
  }
  .article__page {
    margin: 0 8px;
  }
}
