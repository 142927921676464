@import '../../../css/images';

.container {
  &::after {
    content: "";
    z-index: 0;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 13px;
    left: 15px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url($search);
    opacity: 0.5;
  }

  .input {
    padding: 0 20px 0 39px;
  }
}