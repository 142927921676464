.blogContent {
  width: 100%;
  display: block;
  padding: 30px;
  position: relative;

  .item {
    width: 100%;
    display: block;
    margin-bottom: 30px;

    .image {
      width: 100%;
      display: block;

      .img {
        width: 100%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        object-fit: cover;
        object-position: center;
      }
    }

    .head {
      text-align: center;
      font-size: 38px;
      margin-top: 30px;
      line-height: 43px;
      overflow: inherit;
      text-overflow: inherit;
      white-space: inherit;
      width: 100%;
      display: block;
      font-weight: 700;
      letter-spacing: -0.3px;
      color: #fff;
    }

    .published {
      font-size: 14px;
      width: 100%;
      display: block;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 15px;
      text-align: center;

      b {
        color: #fff;
      }
    }

    .content {
      width: 100%;
      font-size: 16px;
      color: #fff;
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.img {
  width: 100%;
  display: block;
  background-size: 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  object-position: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media only screen and (min-width: 992px) {
  .blogContent {
    &.detail {
      padding: 30px 150px;
    }
  }
}

.breadcrumb {
  display: none;
  visibility: hidden;
  background-color: unset;
}

@media only screen and (min-width: 992px) {
  .breadcrumb {
    width: 100%;
    display: block;
    visibility: visible;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      width: 100%;
      display: block;

      li {
        font-size: 14px;
        color: #fff;
        letter-spacing: -0.3px;
        margin-right: 10px;

        &.active {
          color: rgba(255, 255, 255, 0.8);
        }

        &::before {
          content: "\e810";
          font-family: "kombatlink-icon";
          margin-right: 5px;
          color: rgba(255, 255, 255, 0.2);
        }

        &:first-child::before {
          content: "";
        }
      }
    }
  }
}
