.articleSelectsPopup {
  z-index: 997;
}

.articleSelectsPopupHeader {
  display: none;
  height: 40px;
  font-size: 24px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  letter-spacing: 0.05em;
  line-height: 24px;
  margin-bottom: 8px;
  position: relative;
  flex: 0 0 100%;
}

.articleSelectsPopupClose {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  width: 20px;
  height: 18px;
  background-size: 100% 100%;
  cursor: pointer;
}

@media screen and (max-width: 767.98px) {
  .articleSelectsPopup {
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    margin: 0 !important;
    padding: 30px 30px 0 20px;
    overflow: auto;
    top: 100%;
    transition: 250ms;
  }

  .articleSelectsPopupHeader {
    display: block;
  }
}
