@import './Filters.module.scss';
@import "src/css/vars";

.articleSelectsPopupHeader {  
  color: white;  
}

.articleSelectsPopupClose {
  background-image: url(#{$cdn}/img/cross.svg);
}

@media screen and (max-width: 767.98px) {

  .articleSelectsPopup {
    
    background: linear-gradient(0deg, #051534, #051534);
    &.opened {
      top: 60px;
    }
  }
}