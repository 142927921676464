$mobileTableWidth: 1299.98px;

.table__column {
  &.eventName {
    width: 290%;

    &::before {
      content: 'Event name';
    }
  }
  &.user {
    width: 530%;

    &::before {
      content: 'Opponent';
      margin-right: 40px;
    }
  }
  &.class {
    width: 110%;

    &::before {
      content: 'Class';
    }
  }
  &.type {
    width: 80%;

    &::before {
      content: 'Type';
    }
  }
  &.button {
    width: 150%;
  }
  &.date {
    width: 100%;

    &::before {
      content: 'Date';
    }
  }
  &.link {
    width: 300%;

    &::before {
      content: 'Link';
    }
  }
  &.time {
    width: 130%;

    &::before {
      content: 'Time';
    }
  }
  &.round {
    width: 80%;

    &::before {
      content: 'Round';
    }
  }
  &.deadline {
    width: 270%;

    &::before {
      content: 'Deadline';
    }
  }
  &.startDate {
    width: 270%;

    &::before {
      content: 'Start date';
    }
  }
  &.buttons {
    width: 540px;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 1879.98px) {
  .table__column {
    &.buttons {
      width: 384px;
    }
  }
}

.table__secondary_row {
  .table__column {
    &::before {
      display: none !important;
    }

    &.user::before {
      display: inline-block !important;
    }
  }
}

@media screen and (max-width: $mobileTableWidth) and (min-width: 0) {
  .table__column {
    &.eventName {
      width: 100%;
    }
    &.buttons {
      width: 100%;
    }
  }
}

.tableMainRow {
  &.highlighted {
    background: hsla(0, 0%, 100%, 0.2);
    border: 1px solid #49c3fd;
  }
}
