.old-kl-css {
	.p-l-lg-135 {
		padding-left: 135px;
	}

	.m-0--45 {
		margin: 0 -45px;
	}

	.m-0--30 {
		margin: 0 -30px;
	}

	.m-0--25 {
		margin: 0 -25px;
	}

	.m-0-30 {
		margin: 0 30px;
	}

	.m-0--10 {
		margin: 0 -10px;
	}

	.m-l-15 {
		margin-left: 15px;
	}

	.m-l-0 {
		margin-left: 0;
	}

	.m-0--20 {
		margin: 0 -20px;
	}

	.m-0--31 {
		margin: 0 -31px;
	}

	.m-0-15 {
		margin: 0 15px;
	}

	.p-r-0 {
		padding-right: 0;
	}

	.p-r-15 {
		padding-right: 15px;
	}

	.p-r-20 {
		padding-right: 20px;
	}

	.p-r-25 {
		padding-right: 25px;
	}

	.p-r-16 {
		padding-right: 16px;
	}

	.p-r-30 {
		padding-right: 30px;
	}

	.p-r-35 {
		padding-right: 35px;
	}

	.p-r-5 {
		padding-right: 5px;
	}

	.p-l-5 {
		padding-left: 5px;
	}

	.p-l-7 {
		padding-left: 7px;
	}

	.m-r-5 {
		margin-right: 5px;
	}

	.m-r-30 {
		margin-right: 30px;
	}

	.m-r--30 {
		margin-right: -30px;
	}

	.m-l-5 {
		margin-left: 5px;
	}

	.p-0-10 {
		padding: 0 10px;
	}

	.p-0-20 {
		padding: 0 20px;
	}

	.p-0-25 {
		padding: 0 25px;
	}

	.m-r-15 {
		margin-right: 15px;
	}

	.p-l-0 {
		padding-left: 0;
	}

	.p-l-1 {
		padding-left: 1px;
	}

	.p-l-20 {
		padding-left: 20px;
	}

	.p-l-25 {
		padding-left: 25px;
	}

	.m-40 {
		margin: 40px 0;
	}

	.m-b-40 {
		margin-bottom: 40px;
	}

	.m-b-13 {
		margin-bottom: 13px;
	}

	.m-b-5 {
		margin-bottom: 5px;
	}

	.m-b-30 {
		margin-bottom: 30px;
	}

	.m-b-0 {
		margin-bottom: 0;
	}

	.m-0-5 {
		margin: 0 5px;
	}

	.p-0 {
		padding: 0;
	}

	.p-0-30 {
		padding-left: 30px;
		padding-right: 30px;
	}

	.p-0-15 {
		padding-left: 15px;
		padding-right: 15px;
	}

	.p-0-10 {
		padding-left: 10px;
		padding-right: 10px;
	}

	.p-0-5 {
		padding-left: 5px;
		padding-right: 5px;
	}

	.p-32 {
		padding-bottom: 32px;
	}

	.p-32,
	.p-t-32 {
		padding-top: 32px;
	}

	.m-t-5 {
		margin-top: 5px;
	}

	.m-t-38 {
		margin-top: 38px;
	}

	.m-t-30 {
		margin-top: 30px;
	}

	.m-b-15 {
		margin-bottom: 15px;
	}

	.w-200 {
		width: 200px;
	}

	.p-l-8 {
		padding-left: 8px;
	}

	.p-r-8 {
		padding-right: 8px;
	}

	.color-blue {
		color: #49c3fd;
	}

	.color-purple {
		color: #3c3daa;
	}

	.col-65 {
		width: 54%;
	}

	.hover-color-green:hover {
		color: #6cd000;
	}

	.opacity-5 {
		opacity: 0.5;
	}

	.z-index-5 {
		z-index: 5;
	}

	.z-index-10 {
		z-index: 10;
	}

	.video-container {
		position: fixed;
		z-index: -1;
		height: 100vh;
		top: 0px;
		left: 0px;
		bottom: 0px;
		right: 0px;
		overflow: hidden;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}
	.background-video {
		margin: auto;
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		visibility: visible;
		height: auto;
		width: 100%;
		min-height: 100vh;
	}

	.additional-info {
		background: hsla(0, 0%, 100%, 0.1);
		padding: 20px;
		font-family: BurbankSmall-Medium, sans-serif;
		font-style: normal;
		font-size: 14px;
		line-height: 25px;
		color: hsla(0, 0%, 100%, 0.8);
	}

	.subheader {
		font-family: BurbankSmall-Medium, sans-serif;
		font-style: normal;
		font-size: 16px;
		line-height: 16px;
		color: #fff;
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.plainList {
		list-style-type: none;
	}

	OL.nested-list {
		counter-reset: item;
		display: table;
	}
	OL.nested-list > LI {
		display: table-row;
	}
	OL.nested-list > LI:before {
		content: counters(item, ".") ". ";
		counter-increment: item;
		display: table-cell;
	}

	OL.unindented-list {
		padding-left: 0px;
		display: block;
	}
	OL.unindented-list > LI {
		display: block;
	}
	OL.unindented-list > LI:before {
		content: counters(item, ".") ". ";
		counter-increment: item;
		display: initial;
	}

	.inline-block {
		display: inline-block;
	}

	.sign-up__input-date {
		width: 200px;
		height: 20px;
		padding: 10px 20px 20px;
	}

	.react-datepicker-popper[data-placement^="bottom"] {
		margin-top: 32px;
	}

	.react-datepicker__triangle {
		display: none;
	}

	.opacity-4 {
		opacity: 0.4;
	}
}
