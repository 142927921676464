@import "src/css/vars";

@media (min-width: 1590px){
  .colMainContentMaxWidth {
      max-width: calc(100% - 255px);
  }
}
.sldrContainer {
  margin: 0 0 59px;
}
.sldr {
  width: 100%;
  max-width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  color: #fff;  
  background: rgb(30, 44, 72);
}
.sldrL {
  width: 70%;
  max-width: 70%;
  -webkit-box-shadow: 10px 0px 35px 0px rgba(0,0,0,0.6);
  -moz-box-shadow: 10px 0px 35px 0px rgba(0,0,0,0.6);
  box-shadow: 10px 0px 35px 0px rgba(0,0,0,0.6);  
}

.sldrR {
  position: relative;
  z-index: 1;
  width: 30%;
  max-width: 30%;
  background: #1E2C48;
}
@media (max-width: 1199px) {
  .sldr{
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .sldrL,
  .sldrR {
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1920px) {
  .sldrL {
    width: 67.7%;
    max-width: 67.7%;
  }
  .sldrR {
    width: 32.3%;
    max-width: 32.3%;
  }
}
.sldrNavImg {
  width: 100%;
  //padding-top: 45%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (min-width: 1600px) {
  .sldrNavImg{
    //padding-top: 42%;
    //padding-top: 43%;
  }
}

.sldrNavI { 
  outline: none !important;
  position: relative;
  cursor: pointer;
}


@media (min-width: 1200px) {

  .sldrNavI .sldrNavIInn{
    margin: 0 6% 0 6%;
    transition: all 0s;
  }
  .sldrNavI.slickCurrent.slickActive .sldrNavIInn {
    margin: 0 6%;
  }
  .sldrNavI.slickCurrent.slickActive .sldrNavImg {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .sldrNavImg {
    -webkit-box-shadow: inset 0px 0px 35px 5px rgba(0,0,0,0.6);
    -moz-box-shadow: inset 0px 0px 35px 5px rgba(0,0,0,0.6);
    box-shadow: inset 0px 0px 35px 5px rgba(0,0,0,0.6);
  }

  .sldrNavI:first-of-type.slickCurrent.slickActive .sldrNavIInn{
    margin: 0 12% 0 0;
  }
  .sldrNavI:nth-of-type(3).slickCurrent.slickActive .sldrNavIInn{
    margin: 0 0 0 12%;
  }
  .sldrNavI:first-of-type.slickCurrent.slickActive + .sldrNavI {
    transform: translateX(-12%);
    z-index: 2;
  }
  .sldrNavI:first-of-type.slickCurrent.slickActive + .sldrNavI .sldrNavIInn {
    margin: 0 12% 0 0;
  }
  .sldrNavI:first-of-type.slickCurrent.slickActive + .sldrNavI + .sldrNavI {
    transform: translateX(-106%);
    z-index: 3;
  }
  .sldrNavI:first-of-type.slickCurrent.slickActive + .sldrNavI + .sldrNavI .sldrNavIInn{
    margin: 0 12% 0 0;
  }
  .sldrNavIPrev1 {
    transform: translateX(6%);
    z-index: 2;
  }
  .sldrNavIPrev2 {
    transform: translateX(106%);
    z-index: 3;
  }
  .sldrNavIPrev2 + .sldrNavIPrev1 {
    transform: translateX(12%);
  }
  .sldrNavINext {
    transform: translateX(-6%);
    z-index: 2;
  }

  .sldrNavIPrev1 .sldrNavIInn,
  .sldrNavIPrev2 .sldrNavIInn {
    margin: 0 0 0 12%;
  }
  .sldrNavINext .sldrNavIInn {
    margin: 0 12% 0 0;
  }
  }

  .sldrRInn,
  .sldrCar,
  .sldrCar .slickList,
  .sldrCar .slickTrack,
  .sldrCar .slickSlide{
    height: 100%;
  }
  .sldrCarI {
    outline: none !important;
  }
  .sldrInf {
    position: relative;
    padding: 60px 0 0;
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
  }
  .sldrInf:after{
    content: "";
    position: absolute;
    top: 60px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.1)
  }

  .sldrInfInn{
    padding: 0 20px;
    margin: 0 0;
    width: 100%;
    max-width: 100%;
  }
@media (max-width: 479px){
  .sldrInfInn{
    padding: 30px 20px;
  }
}
@media (min-width: 480px) and (max-width: 1199px){
  .sldrInfInn{
    padding: 30px 39px;
  }
}
@media (min-width: 1800px) {
  .sldrInfInn{
    padding: 20px 40px 0 50px;
  }
}
.sldrInfInfo{
  padding-top: 4px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
}
.sldrInfDate{
  display: block;
  position: relative;
  margin: 0 0 10px;
  padding: 0 0 0 26px;
  font-family: "BurbankSmall-Medium";
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;
  color: #49C3FD;
}
.sldrInfDate:before{
  content: "";
  display: block;
  width: 16px;
  height: 18px;
  position: absolute;
  top: -4px;
  left: 0;
  background: url(#{$cdn}/img/calendar-blue.svg);
  -webkit-background-size: auto;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}
.sldrInfPlace{
  display: block;
  position: relative;
  margin: 0 0 10px;
  padding: 0 0 0 24px;
  font-family: "BurbankSmall-Medium";
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 15px;
  color: #FFFFFF;
  opacity: 0.5;
}
.sldrInfPlace:before{
  content: "";
  display: block;
  width: 13px;
  height: 17px;
  position: absolute;
  top: -4px;
  left: 0;
  background: url(#{$cdn}/img/map-icon.svg);
  -webkit-background-size: auto;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}

.sldrInfTtl{
  margin: 14px 0 0;
  font-family: 'BurbankSmall-Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
}
@media (max-width: 480px){
  .sldrInfTtl{
    font-size: 20px;
    line-height: 23px;
  }  
}
@media (min-width: 1200px) and (max-width: 1350px){
  .sldrInfTtl{
    margin: 11px 0 0;
    font-size: 20px;
    line-height: 23px;
  }
}
@media (min-width: 1600px) {
  .sldrInfTtl{
    font-size: 24px;
    line-height: 34px;
  }
}
.sldrInfBtnWrap{
  margin-top: 19px;
  text-align: center;
}
@media (min-width: 1200px) and (max-width: 1350px){
  .sldrInfBtnWrap{
    margin-top: 14px;
  }
}
.sldrInfBtn{
  padding: 13px 0 0;
  display: inline-block;
  width: 150px;
  height: 40px;
  background: linear-gradient(90deg, #02B7FE 0%, #0389FD 100%);
  border-radius: 2px;
  transition: all 0.2s;
  font-family: "Burbank-Big-Cd-Bd";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF !important;
  text-decoration: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}
.sldrInfBtn:hover{
  opacity: 0.75;
}

.sldrInfAuthor{
  margin-top: 24px;
  font-family: "BurbankSmall-Medium";
  font-weight: 550;
  color: #fff;
  line-height: 15px;
}
@media (min-width: 1200px) and (max-width: 1350px){
  .sldrInfAuthor{
    margin-top: 20px;
  }
}
@media (min-width: 1920px) {
  .sldrInfAuthor{
    margin-top: 44px;
  } 
}
.sldrInfAuthor span{
  margin-right: 10px;
  font-size: 12px;
  opacity: 0.4;
}
.sldrInfAuthor a{
  font-size: 13px;
  text-decoration: none !important;
  transition: all 0.2s;
  color: #fff;
  outline: none !important;
}
.sldrInfAuthor a:hover{
  color: #49C3FD;
}


.sldrCar .slickArrow{
    display: block;
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1;
    width: 25px;
    height: 30px;
    border: 0;
    outline: none;
    background-color: transparent;
    -webkit-background-size: auto;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0;
    opacity: 0.5;
    transition: all 0.2s;
}
.sldrCar .slickArrow:hover{
  opacity: 1;
}
.sldrCar .slickPrev{
  /*left: 15px;*/
  left: 29px;
  background-image: url(#{$cdn}/img/arrow-left-slider.svg);
}
.sldrCar .slickNext{
  /*left: 42px;*/
  left: 55px;
  background-image: url(#{$cdn}/img/arrow-right-slider.svg);
}
.sldrNav
{
  margin: 0 !important;
}
@media (min-width: 1800px) {
  .sldrCar .slickPrev{
    left: 39px;
  }
  .sldrCar .slickNext{
    left: 65px;
  }
}
.sldrCar .slickDots{
  position: absolute;
  top: 16px;
  /*right: 25px;*/
  right: 32px;
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}
.sldrCar .slickDots > li{
  display: block;
}
.sldrCar .slickDots > li > button{
  font-size: 0;
  width: 20px;
  height: 20px;
  border: 0;
  outline: none;
  background: transparent;
  opacity: 0.5;
  transition: .2s;
}
.sldrCar .slickDots > li > button:hover{
  opacity: 1;
}
.sldrCar .slickDots .slickActive > button{
  opacity: 1;
}
.sldrCar .slickDots > li > button:before{
  content: '';
  display: block;
  margin: 0 auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
}
.home_popup_title {
  font-size: 30px !important;
  margin-bottom: 10px;
  line-height: 1.3 !important;
  font-family: 'BurbankSmall-Medium', sans-serif;
  font-weight: 600;

  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: 20px;     /* fallback */
   max-height: 50px;      /* fallback */
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
}
/* slick */ 

/* Slider */
.slickSlider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slickList
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slickList:focus
{
    outline: none;
}
.slickList.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slickSlider .slickTrack,
.slickSlider .slickList
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slickTrack
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
    transition: 0.3s linear;
}
.slickTrack:before,
.slickTrack:after
{
    display: table;

    content: '';
}
.slickTrack:after
{
    clear: both;
}
.slickLoading .slickTrack
{
    visibility: hidden;
}

.slickSlide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slickSlide
{
    float: right;
}
.slickSlide img
{
    display: block;
}
.slickSlide.slickLoading img
{
    display: none;
}
.slickSlide.dragging img
{
    pointer-events: none;
}
.slickInitialized .slickSlide
{
    display: block;
}
.slickLoading .slickSlide
{
    visibility: hidden;
}
.slickVertical .slickSlide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slickArrow.slickHidden {
    display: none;
}

.slider_title
{
  color: #000 !important;
  padding: 0 0;
  text-align: center;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 27px;
  max-height: 40px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  word-break: break-all;
}
