@import './Tournament.module';

.game {  
  background-color: rgba(255, 255, 255, 0.1);
}

.tournament__info {  
  color: white;
}

.tournament__header {
  color: rgba(255, 255, 255, 0.4);
}


.game__platforms {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.tournament {  
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}


.remainingTime {  
  color: #0c0c3c;
  background: rgba(12, 12, 60, 0.5);
}

.remainingTime__time {  
  color: white;
}

.remainingTime__text {
  color: white;
}

.remainingTime:hover .remainingTime__time,
.remainingTime:hover .remainingTime__text {
  color: #49c3fd;
}

.remainingTime__container::before,
.remainingTime__container::after { 
  background-color: white;
}

.game__signed {
  color: rgba(255, 255, 255, 0.4);
}


.game__signed span {
  color: white;
}