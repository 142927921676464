@import "src/css/vars";

.chatMessageContainer {
  margin-bottom: 20px;

  &:last-of-type {
    padding-top: 20px;
  }
}

.chatMessageProfilePicture {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-top: 5px;

  &.chatMessageProfilePictureLink {
    cursor: pointer;
  }
}

.chatMessage {
  background: white;
  border: 1px solid #e2f0ff;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #001030;
  padding: 9px 10px;
  max-width: calc(100% - 30px);
  word-wrap: break-word;
}

.chatMessageDate {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: #aeb7bf;
  margin-top: 6px;
}

.chatMessageUser {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: #aeb7bf;
  margin-bottom: 5px;
}

.chatMessageContainerOwn {
  .chatMessage {
    background: #028ffd;
    color: white;
  }
}

.chat {
  height: calc(100% - 110px);
  display: flex;
  flex-direction: column-reverse;
}

.loading {
  color: #0389fd;
}


.chatSystemMessage {
  position: relative;
  width: 100%;
  border-radius: 2px;
  margin: 15px 0;
  padding-left: 41px;
  padding-top: 14px;
  padding-right: 10px;
  padding-bottom: 14px;
  font-family: BurbankSmall-Medium,sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    left: 15px;
    width: 16px;
    height: 16px;
    background-color: rgba(0,0,0,0);
    background-size: 100% 100%;
  }

  &.chatWarningMessage {
    color: #e13b4c;
    background: rgba(255,59,76,.1);
    &::before {
      background-image: url(#{$cdn}/img/blocked.svg);
    }
  }

  &.chatInfoMessage {
    color: white;
    background: #49c3fd;
  }
  &.chatSuccessMessage {
    color: white;
    background: #90ee90;
  }
}

