.old-kl-css {
  .p-l-lg-135 {
    padding-left: 135px;
  }
  .m-0--30 {
    margin: 0 -30px;
  }
  .m-0-30 {
    margin: 0 30px;
  }
  .m-0--10 {
    margin: 0 -10px;
  }
  .m-l-15 {
    margin-left: 15px;
  }
  .m-l-0 {
    margin-left: 0;
  }
  .m-0--20 {
    margin: 0 -20px;
  }
  .m-0--31 {
    margin: 0 -31px;
  }

  .m-0-15 {
    margin: 0 15px;
  }

  .p-r-0 {
    padding-right: 0;
  }

  .p-r-15 {
    padding-right: 15px;
  }

  .p-r-35 {
    padding-right: 35px;
  }

  .p-r-5 {
    padding-right: 5px;
  }

  .p-l-5 {
    padding-left: 5px;
  }

  .p-l-7 {
    padding-left: 7px;
  }

  .m-r-5 {
    margin-right: 5px;
  }

  .m-l-5 {
    margin-left: 5px;
  }

  .p-0-10 {
    padding: 0 10px;
  }

  .p-0-20 {
    padding: 0 20px;
  }

  .m-r-15 {
    margin-right: 15px;
  }

  .p-l-0 {
    padding-left: 0;
  }

  .p-l-1 {
    padding-left: 1px;
  }

  .p-l-20 {
    padding-left: 20px;
  }

  .m-40 {
    margin: 40px 0;
  }

  .m-b-40 {
    margin-bottom: 40px;
  }

  .m-b-13 {
    margin-bottom: 13px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .m-b-0 {
    margin-bottom: 0;
  }

  .m-0-5 {
    margin: 0 5px;
  }

  .p-0 {
    padding: 0;
  }

  .p-0-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .p-0-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-0-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .p-0-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .p-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .p-t-32 {
    padding-top: 32px;
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-38 {
    margin-top: 38px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .w-200 {
    width: 200px;
  }

  .p-l-8 {
    padding-left: 8px;
  }

  .p-r-8 {
    padding-right: 8px;
  }

  @media screen and (max-width: 991.98px) {
    .p-l-sm-85 {
      padding-left: 85px;
    }
    .m-sm-20 {
      margin: 20px 0;
    }
    .m-b-sm-20 {
      margin-bottom: 20px;
    }
    .m-t-sm-50 {
      margin-top: 50px;
    }
  }

  @media screen and (min-width: 1200px) {
    .h-xl-100 {
      height: 100%;
    }
    .m-l-xl-15 {
      margin-left: 15px;
    }
  }

  @media screen and (min-width: 992px) {
    .m-t-lg-82 {
      margin-top: 82px;
    }
    .h-lg-100 {
      height: 100%;
    }
    .m-md-0--15 {
      margin: 0 -15px;
    }
  }

  @media screen and (max-width: 991.98px) and (min-width: 576px) {
    .p-l-sm-20 {
      padding-left: 20px;
    }
    .p-l-sm-1 {
      padding-left: 1px;
    }
    .m-r-sm-5 {
      margin-right: 5px;
    }
    .m-l-sm-5 {
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 575.98px) {
    .m-t-sm-10 {
      margin-top: 10px;
    }
    .p-l-sm-65 {
      padding-left: 65px;
    }
  }

  @media screen and (min-width: 1920px) {
    .p-xxl-0-40 {
      padding: 0 40px;
    }
  }

  .color-blue {
    color: #49c3fd;
  }

  .col-65 {
    width: 54%;
  }

  .hover-color-green:hover {
    color: #6cd000;
  }

  .opacity-5 {
    opacity: 0.5;
  }

  .z-index-5 {
    z-index: 5;
  }

  .z-index-10 {
    z-index: 10;
  }
}
