.chatName {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}


.loadingBox {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.loading {
  color: #0389fd;
}


.chatPreviewUser {
  font-family: Burbank-Big-Rg-Md,sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: .03em;
  color: #001030;
  opacity: .5;
  margin-right: 5px;  
}


.chatPreviewMessage {  
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.unreadMessageCount {
  bottom: 3px;
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding:  3px 3px 0 ;
  margin: 0;
  background: #02a1fe;
  border-radius: 50%;
  color: #fff;
  font-size: 12px; 
  min-width: 22px;
  min-height: 22px; 
}

.chattypingdots {
  position: relative;
  margin-left: 4px;
}

@-webkit-keyframes chatDots {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  10% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  to {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
}

@keyframes chatDots {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  10% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  to {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
}

.chattypingdots div {
  width: 6px;
  height: 6px;
  background: #001030;
  opacity: 0.5;
  margin-right: 2px;
  -webkit-animation: chatDots 3s linear infinite;
  animation: chatDots 3s linear infinite;
  border-radius: 50%;
  -webkit-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
}

.chattypingdots__white div {
  background: #000;
}

.chattypingdots div:nth-of-type(2) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.chattypingdots div:nth-of-type(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
._typingText{
  display: flex;
  font-size: 13px;
  align-items: center;
}

.chat__person_message__mine {
  opacity: 0.5;
}
