.chatMember {
  position: relative;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #e0e8f1;

  .chatPersonStatus {
    bottom: 9px;
  }
  &.chatMemberLink {
    cursor: pointer;
  }
}