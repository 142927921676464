@import "../vars";

@font-face {
  font-family: "Burbank-Big-Cd-Bd";
  src: url(#{$cdn}/fonts/BurbankBigCondensed-Bold.otf) format("otf"),
    url(#{$cdn}/fonts/BurbankBigCondensed-Bold.woff2) format("woff2"),
    url(#{$cdn}/fonts/BurbankBigCondensed-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Burbank-Big-Cd-Lt";
  src: url(#{$cdn}/fonts/BurbankBigCondensed-Light.otf) format("otf"),
    url(#{$cdn}/fonts/BurbankBigCondensed-Light.woff2) format("woff2"),
    url(#{$cdn}/fonts/BurbankBigCondensed-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Burbank-Big-Rg-Bd";
  src: url(#{$cdn}/fonts/BurbankBigRegular-Bold.otf) format("otf"),
    url(#{$cdn}/fonts/BurbankBigRegular-Bold.woff2) format("woff2"),
    url(#{$cdn}/fonts/BurbankBigRegular-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Burbank-Big-Rg-Bd";
  src: url(#{$cdn}/fonts/BurbankSmall-MediumItalic.otf) format("otf"),
    url(#{$cdn}/fonts/BurbankSmall-MediumItalic.woff2) format("woff2"),
    url(#{$cdn}/fonts/BurbankSmall-MediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "BurbankSmall-Medium";
  src: url(#{$cdn}/fonts/BurbankSmall-Medium.otf) format("otf"),
    url(#{$cdn}/fonts/BurbankSmall-Medium.woff2) format("woff2"),
    url(#{$cdn}/fonts/BurbankSmall-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BurbankSmall-Light";
  src: url(#{$cdn}/fonts/BurbankSmall-Light.otf) format("otf"),
    url(#{$cdn}/fonts/BurbankSmall-Light.woff2) format("woff2"),
    url(#{$cdn}/fonts/BurbankSmall-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "BurbankSmall-Bold";
  src: url(#{$cdn}/fonts/BurbankSmall-Bold.woff2) format("woff2"),
    url(#{$cdn}/fonts/BurbankSmall-Bold.otf) format("otf"),
    url(#{$cdn}/fonts/BurbankSmall-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Burbank-Big-Cd-Md";
  src: url(#{$cdn}/fonts/BurbankBigCondensed-Medium.otf) format("otf"),
    url(#{$cdn}/fonts/BurbankBigCondensed-Medium.woff2) format("woff2"),
    url(#{$cdn}/fonts/BurbankBigCondensed-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Burbank-Big-Rg-Md";
  src: url(#{$cdn}/fonts/BurbankBigRegular-Medium.otf) format("otf"),
    url(#{$cdn}/fonts/BurbankBigRegular-Medium.woff2) format("woff2"),
    url(#{$cdn}/fonts/BurbankBigRegular-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "kombatlink-icon";
  src: url(#{$cdn}/fonts/fontello.ttf);
  src: url(#{$cdn}/fonts/fontello.woff2) format("woff2"),
    url(#{$cdn}/fonts/fontello.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
