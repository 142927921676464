.chatHeaderHeaderContainer {
  padding: 0 20px;
}

.chatHeaderHeader {
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: white;
  margin-bottom: 0;
}