.link {
  font-family: 'Burbank-Big-Rg-Md', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  align-items: center;
  letter-spacing: 0.05em;
  color: #001030 !important;
  text-decoration: none;
  transition: 150ms;
  margin-left: 15px;

  &:hover {
    text-decoration: none;
    color: #6dd0ff  !important;
  }
}
