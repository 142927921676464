@import './Filters.module.scss';
@import "src/css/vars";

.articleSelectsPopupHeader {
  color: #001030;
}

.articleSelectsPopupClose {
  background-image: url(#{$cdn}/img/grey_cross.svg);
}


@media screen and (max-width: 767.98px) {

  .articleSelectsPopup {
    background-color: #fff;

    &.opened {
      top: 0px;
    }
  }
}