

.chatButton {
  width: 157px;
  height: 40px;
  padding: 0 0 0 55px;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  margin-right: -15px;

  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 14px;
    height: 12px;
    background-color: transparent;
    background-size: 14px 11px;
    background-image: url("https://kombatlink-assets.azureedge.net/img/chat.svg");
    left: 34px;
    top: 14px;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@media screen and (max-width: 767.98px) {
  .chatButton {
    margin-bottom: 10px;
    margin-right: -15px;
    margin-top: 30px;
  }
}