.chatPersonContainer {
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #e2f0ff;
  position: relative;
}

.chatPersonDate {
  font-family: BurbankSmall-Medium,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 10px;
  color: #aeb7bf;
  margin-top: 18px;
}

.chatGroup {
  cursor: pointer;
  transition: 200ms;
  max-width : 140px !important;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chatPersonContainer:hover {
  .chatGroup {
    opacity: 0.8;
  }
}


.chatPersonPictureGroup {
  font-family: Burbank-Big-Rg-Md,sans-serif;
  font-style: normal;
  background: #028ffd;
  color: #fff;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
}


.chatPersonLink {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.unreadMessage {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatPreviewUser {
  font-family: Burbank-Big-Rg-Md,sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: .03em;
  color: #001030;
  opacity: .5;
  margin-right: 5px;  
}