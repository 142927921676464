@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.chatContainer {
  position: fixed;
  z-index: 100000;
  right: 290px;
  bottom: 120px;
  width: 300px;
  height: 396px;
  background: white;
  border-radius: 2px;
  overflow: hidden;
  display: none;
  opacity: 0;
  opacity: 1;
  display: block;
  animation: show 300ms ease-in-out;
}


@media screen and (max-width: 660px) {
  .chatContainer {
    right:auto;
    left: 70px;
  }
}

@media screen and (max-width: 575.98px) {
  .chatContainer {
    right:auto;
    left: 50px;
  }
}