body {
  font-family: 'BurbankSmall-Medium', sans-serif;
}

a {
  background-color: transparent;
}

img {
  border-style: none;
  vertical-align: middle;
}

.pageHead {
  width: 100%;
  height: 60px;
  display: block;
  float: left;
  background: transparent;

  @media screen and (min-width: 992px){
    height: 90px;
  }

  h1 {
    width: 100%;
    display: block;
    float: left;
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 22px;
    color: #fff;
    letter-spacing: -0.69px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .20);
    line-height: 30px;

    @media screen and (min-width: 992px){
      font-size: 26px;
      line-height: 34px;
    }
  }
}

.blogContent {
  width: 100%;
  display: block;
  position: relative;
  margin-top: 50px;

  .item {
    width: 100%;
    display: block;
    float: left;
    margin-bottom: 30px;

    .image {
      width: 100%;
      height: 260px;
      display: block;
      float: left;

      img {
        width: 100%;
        height: 100%;
        display: inline-block;
        float: left;
        background-size: 100%;
        background-repeat: no-repeat;
        object-fit: cover;
        object-position: center;
      }
    }

    span {
      &.head {
        width: 100%;
        display: block;
        float: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: -0.3px;
        color: #fff;
        margin-top: 15px;
        line-height: 28px;
      }
      &.published {
        font-size: 14px;
        width: 100%;
        display: block;
        float: left;
        font-weight: 700;
        color: rgba(255,255,255,0.5);
        margin-bottom: 15px;

        b {
          color: #fff;
        }
      }
      &.preview {
        width: 100%;
        float: left;
        font-size: 14px;
        color: rgba(255,255,255,0.8);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    a {
      &.readMore {
        width: 100%;
        height: 50px;
        display: block;
        float: left;
        line-height: 52px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: -0.3px;
        font-size: 14px;
        color: #fff;
        text-align: center;
        margin-top: 10px;
        transition: .3s all;
        border: 1px solid rgba(255,255,255,0.1);

        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
}

.isNewLabel {
  position: absolute;
  background: linear-gradient(90deg, #ec5418 0%, #df2d06 100%);
  width: 67px;
  height: 27px;
  right: 6px;
  top: 11px;
  text-align: center;
  font-family: "Burbank Small", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 27px;
  color: white;
  z-index: 5;
}

.isNewLabelBackground {
  position: absolute;
  background: #b82200;
  top: 8px;
  right: 7px;
  width: 10px;
  height: 8px;
  transform: rotate(30deg);
  z-index: -1;
}