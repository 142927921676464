.arrow {
  background-image: url("https://kombatlink-assets.azureedge.net/img/grey_arrow_top.svg");
  background: #f0f8ff00;
  user-select: none;
  
  &.arrowleft {
    transform: rotate(-90deg);
  }

  &.arrowRight {
    transform: rotate(90deg);
  }
}

.arrowButton {
  background: rgba(250, 244, 244, 0.2);
  width: 25px;
  flex: auto;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background: rgba(250, 244, 244, 0.3);
  }
}