.articleTabMenuBeforeContainer {
  margin: 50px 0 20px 0;
  border-bottom: 1px solid #25324e;
}

.articleTabMenuContainer {
  position: relative;
  margin: 0;
  width: 100%;
  height: 40px;
}

.tabMenu {
  position: relative;
  margin: 0;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tabMenuLink {
  position: relative;
  display: block;
  margin: 0 40px 0 0;
  padding: 0 0 24px 0;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: white;
  opacity: 0.7;
  transition: 150ms;

  &:last-of-type {
    margin: 0;
  }
  
  &:hover {
    text-decoration: none;
    color: white;
    opacity: 0.9;
  }

  &.notification::after {
    content: "";
    display: block;
    position: absolute;
    right: -7px;
    top: -7px;
    width: 5px;
    height: 5px;
    background: #49c3fd;
    opacity: 1;
    border-radius: 50%;
  }

  &.active {
    opacity: 1;
    color: #49c3fd;

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 2px;
      background: #49c3fd;
    }
  
    &:hover {
      color: #49c3fd;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 991.98px) {
  .tabMenu {
    height: 100%;
  }
  .tabMenuLink {
    padding: 0 0 15px 0;
  }
}

.tabMenuRadioContainer {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.tabMenuRadio {
  display: none;
}

@media screen and (max-width: 991.98px) {
  .articleRadioContainer {
    order: -1;
    margin-bottom: 15px;
  }
}

.articleTabMenuButton {
  position: absolute;
  background-color: transparent;
  background-size: 100% 100%;
  background-position: center center;
  background-image: url("https://kombatlink-assets.azureedge.net/img/grey_arrow_top.svg");
  border: none;
  cursor: pointer;
  bottom: 18px;
  width: 20px;
  height: 12px;

  &.left {
    transform: rotate(-90deg);
    left: 0;
  }

  &.right {
    transform: rotate(90deg);
    right: 0;
  }
}

@media screen and (max-width: 449.98px) {
  .articleTabMenuContainer {
    align-items: flex-end;
    -webkit-align-items: flex-end;
    padding: 0 30px;
  }
  .tabMenu {
    height: 100%;
    overflow: hidden;
  }
}

.tabMenuRadioLabel {
  width: 14px;
  height: 15px;
  opacity: 0.4;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: transparent;
  background-size: 10px 8px;
  background-position: center center;
  background-repeat: no-repeat;
  transition: 200ms;
  cursor: pointer;
}

.tabMenuRadio:checked + .tabMenuRadioLabel {
  background-color: #6cd000;
  border: 1px solid #6cd000;
  background-image: url("https://kombatlink-assets.azureedge.net/img/ok.svg");
  opacity: 1;
}

.tabMenuRadioText {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  color: white;
  opacity: 0.5;
  margin: 0 0 0 7px;
}

.tabMenuRadioContainer:first-of-type .tabMenuRadioText,
.tabMenuRadioContainer:nth-of-type(2) .tabMenuRadioText {
  margin: 0 20px 0 7px;
}

.tabMenuHeaderLink {
  position: relative;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: white;

  &::after {
    content: "";
    display: inline-block;
    margin-left: 10px;
    width: 13px;
    height: 11px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url("https://kombatlink-assets.azureedge.net/img/arrow-right.svg");
  }

  &:hover {
    color: white;
    text-decoration: none;
  }
}
