li {
  &.notificationRow {
    width: calc(100% - 40px);
    padding: 10px 0 5px 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 25px !important;
    margin-right: 5px !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: auto;
    float: left;
    position: relative;

    &::before {
      content: '';
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 100px;
      background-color: inherit;
      border: 1px solid rgba(255, 255, 255, 0.5);
      position: absolute;
      left: -25px;
      top: 12px;
    }

    &.new {
      &::before {
        background-color: #de324f;
        border-color: #de324f;
      }
      cursor: pointer;
    }

    div {
      &.notification_text {
        font-size: 13px;
        font-family: 'Roboto';
        font-family: 500;
        color: #fff;
        letter-spacing: -0.5px;
        line-height: 16px;
        width: 100%;
        padding: 0;
        text-align: left;
        padding-right: 10px;

        a {
          text-decoration: underline;
          font-weight: 700;
          padding: 0;
          text-align: left;
          color: white;
          width: auto;
        }
      }

      &.date {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
        letter-spacing: -0.3px;
        padding-top: 2px;
        text-align: left;
      }
    }

    .buttons {
      display: flex !important;
      margin-top: .25rem!important;

      .button {
        height: 30px;
        font-size: 14px;
        line-height: 13px;

        &:nth-child(n + 2):last-child {
          margin-left: 0.25rem;
        }
      }
    }
    
    .window__close {
      display: block;
      text-decoration: none;
      width: 8px;
      height: 8px;
      cursor: pointer;
      position: absolute;
      right: -10px;
      top: 3px;
      z-index: 0;
    }

    .window__close::after,
    .window__close::before {
      content: "";
      display: block;
      position: absolute;
      width: 50%;
      height: 2px;
      left: 0;
      top: 7px;
      background: #333333;
      border-radius: 1px;
    }

    .window__close::after {
      transform: rotate(45deg);
    }

    .window__close::before {
      transform: rotate(-45deg);
    }
  }
}
