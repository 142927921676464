
/*@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("../font/HelveticaNeueCyr/HelveticaNeueCyr-Medium.woff") format('woff'),
    url("../font/HelveticaNeueCyr/HelveticaNeueCyr-Medium.ttf") format('truetype'),
    url("../font/HelveticaNeueCyr/HelveticaNeueCyr-Medium.eot?#iefix") format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
} */

.pfl{
	margin-bottom: 80px;
}

@media screen and (min-width: 1500px) {
	.pfl_col-left{
		width: 57.9%;
		max-width: 57.9%;
	}
	.pflColRight{
		width: 42.1%;
		max-width: 42.1%;
	}
}
@media screen and (min-width: 768px) and (max-width: 1499px) {
	.recentCRow{
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		-moz-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		flex-wrap: wrap;

	}
	.recentCCol{
		width: 50%;
		max-width: 50%;
	}
}
@media screen and (min-width: 1920px) {
	.pflColRight {
		margin-left: auto;
		width: 40%;
		max-width: 40%;
	}
}
/*.pfl_ttl*/
.pfl_ttl{
	margin: 0 0 16px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
}
.pfl_ttl_l,
.pfl_ttl_r{
	margin-bottom: 20px;
}
.pfl_ttl .article__header{
	font-family: "Burbank-Big-Cd-Bd";
	margin-bottom: 0;
	margin-top: 0;
}
.btn-green{
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border: none !important;
	padding: 2px 20px 0;
	height: 40px;
	background: #6CD000;
	border-radius: 2px;
	font-family: "Burbank-Big-Cd-Bd";
	/*font-family: "Burbank-Big-Rg-Bd";*/
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: #fff;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	transition: all 0.2s;
}
.btn-green:hover{
	opacity: 0.8;
}
.btn-green span{
	margin-right: 7px;
	font-family: sans-serif;
	font-size: 0;
	line-height: 0;
	width: 12px;
	min-width: 12px;
	height: 12px;
    position: relative;
    display: inline-block;
}
.btn-green span:before{
    content: "";
    position: absolute;
    top: -2px;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    background-color: #fff;
}
.btn-green span:after{
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -3px;
    background-color: #fff;
}

.btn-premium{
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border: none !important;
	padding: 2px 23px 0;
	height: 40px;
	background: #CA25B3;
	border-radius: 2px;
	font-family: "Burbank-Big-Cd-Bd";
	/*font-family: "Burbank-Big-Rg-Bd";*/
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: #fff;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	transition: all 0.2s;
}
.btn-premium:hover{
	opacity: 0.8;
}
.btn-premium span{
	margin: -4px 8px 0 0;
	font-family: sans-serif;
	font-size: 0;
	line-height: 0;
	height: 12px;
    position: relative;
    display: inline-block;
    //background: url(../assets/crown-white.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 16px;
    height: 14px;
}


/*.pfl_img*/
.pfl_img{
	position: relative;
	margin: 0 -15px 20px;
}
@media screen and (min-width: 576px) {
	.pfl_img{
		position: relative;
		margin: 0 -30px 20px;
	}
}
.pfl_img_img img{
	width: 100%;
	max-width: 100%;
}
.pfl_img_upload{
	position: absolute;
	bottom: 10px;
	right: 10px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}
@media screen and (min-width: 768px) {
	.pfl_img{
		position: relative;
		margin: 0 -30px -20px;
	}
	.pfl_img_upload{
		bottom: 20px;
		right: 30px;
	}
}
.btn-upload-dark{
	padding: 3px 15px 0;
	height: 30px;
	/*background: rgba(255, 255, 255, 0.1);*/
	background: rgba(0, 0, 0, 0.5);
	border-radius: 2px;
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border: none !important;
    font-family: 'Burbank-Big-Cd-Bd', sans-serif;
	font-style: normal;
	font-weight: 550;
	font-size: 14px;
	line-height: 16px;
	color: rgba(255,255,255,0.5);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	font-family: "Burbank-Big-Rg-Md";
	cursor: pointer;
	transition: all 0.2s;
}
.btn-upload-dark img{
	margin-right: 7px;
	margin-top: -4px;
	display: inline-block;
	width: 12px;
	height: 12px;
	opacity: 0.5;
	transition: all 0.2s;
}
.btn-upload-dark:hover{
	color: #fff;
	/*background: rgba(255, 255, 255, 0.2);*/
	background: rgba(0, 0, 0, 0.7);
}
.btn-upload-dark:hover img{
	opacity: 1;
}
/*.pfl_company*/
.pfl_company{
	margin: 0 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
}
.pfl_company_l{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-align-items: center;
	align-items: center;
}
.pfl_logo{
	position: relative;
}
.pfl_logo_img{
	width: 70px;
	height: 70px;
	background-color: #fff;
	border-radius: 50%;
	overflow: hidden;
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.btn-upload-small{
	margin: 0;
	padding: 0;
	position: absolute;
    top: -8px;
    right: -8px;
    width: 30px;
    height: 30px;
	border-radius: 50%;
	background: #49C3FD;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	transition: all 0.2s;
	cursor: pointer;
}
.btn-upload-small:hover{
	opacity: 0.9;
}
@media screen and (min-width: 768px) {
	.pfl_logo_img{
		width: 120px;
		height: 120px;
	}
	.btn-upload-small{
		top: -7px;
		right: 7px;
		width: 35px;
		height: 35px;
	}
}
/*.pfl_title*/
.pfl_company_l_l{
	margin: 10px 23px 10px 0;
}
.pfl_company_l_r{
	margin: 10px 0 10px;
}
.pfl_title_ttl{
	margin: 0;
	font-family: "HelveticaNeueCyr";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 22px;
	color: #FFFFFF;
}
@media screen and (min-width: 768px) {
	.pfl_title_ttl{
		font-size: 24px;
		line-height: 26px;
	}	
}
.pfl_verif{
	margin: 13px 0 0;
}
.pfl_verif_active{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	font-style: normal;
	font-weight: 550;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: 0.03em;
	color: #6CD000;
	font-family: "Burbank-Big-Rg-Md";
}
.pfl_verif_active_ico{
	display: inline-block;
	position: relative;
	margin: 0 7px 0 0;
	width: 16px;
	height: 16px;
	background: #6CD000;
	border-radius: 50%;
}
.pfl_verif_active_ico:before{
	content: "";
	display: block;
	position: absolute;
	top: 3px;
	left: 6px;
	transform: rotate(45deg);
	height: 7.5px;
	width: 4px;
	border-bottom: 1px solid #FAFAFA;
	border-right: 1px solid #FAFAFA;
}

/*tooltip white*/
.pfl_verif_not-active{
	font-style: normal;
	font-family: "Burbank-Big-Rg-Md";
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	/*-ms-align-items: center;*/
	/*align-items: center;*/
}
.pfl_verif_not-active span{
	font-weight: 550;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0.03em;
	color: #FFFFFF;
	opacity: 0.4;
}
.btn-more-info{
	margin: 0 0 0 10px;
	padding: 0;
	border: none !important;
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	background-color: transparent !important;
	border-radius: 75%;
	opacity: 0.4;
	transition: all 0.2s;
}
.btn-more-info img{
	margin: -2px 0 0;
	display: block;
	width: 17px;
}
.btn-more-info:hover{
	opacity: 1;
}
.btn-more-info[aria-expanded="true"]{
	opacity: 1;
}
.white-tooltip-theme .tippy-tooltip{
	background-color: #fff;
	font-weight: 550;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #001030;
	border-radius: 2px;
}
.white-tooltip-theme .tippy-content{
	padding: 10px 9px;
	max-width: 240px;
	font-family: "BurbankSmall-Medium";
}
.white-tooltip-theme .tippy-content p{
	margin-bottom: 0;
}
.white-tooltip-theme .tippy-content a{
	font-family: "Burbank-Big-Rg-Bd";
	color: #001030 !important;
	text-decoration: underline;
}
.white-tooltip-theme .tippy-content a:hover{
	text-decoration: none;
}
.tooltip_link-button{
	font-family: "Burbank-Big-Rg-Bd";
	color: #001030 !important;
	text-decoration: underline;
	background-color: transparent !important;
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border: none !important;
	padding: 0 !important;
	margin: 0 !important;
	display: inline;
}
.tooltip_link-button:hover{
	text-decoration: none;
}
.white-tooltip-theme .tippy-tooltip[data-placement^='top'] .tippy-arrow {
  border-top-color: #fff;
}

.pfl_company_r{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}

.social-links{
	color: #fff;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 6px 0;
}
.social-links li{
	margin: 4px 7px 4px 0;
}
.social-links li:last-of-type{
	margin-right: 0;
}
.social-links a{
	width: 35px;
	height: 35px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	color: #fff;
	background: #1E2C48;
	transition: all 0.2s;
	font-size: 14px;
}
.social-links a:hover{
	outline: none !important;
	text-decoration: none !important;
    background: -webkit-linear-gradient(left, #02B7FE 0%, #0389FD 100%);
    background: -o-linear-gradient(left, #02B7FE 0%, #0389FD 100%);
    background: linear-gradient(to right, #02B7FE 0%, #0389FD 100%);
}

.sectionTtl {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: space-between;
}
.sectionTtl_ttl {
	margin: 0;
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: 550;
	font-size: 16px;
	line-height: 16px;
	color: #FFFFFF;
}
.sectionTtl_ttl_num{
	color: rgba(255,255,255,0.5);
}
.btn-edit{
	padding: 0;
	font-family: "Burbank-Big-Rg-Md";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	height: 16px;
	color: #49C3FD;
	background-color: transparent !important;
	border: none !important;
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	transition: all 0.2s;
}
.btn-edit:hover{
	opacity: 0.75;
}
.btn-edit img{
	margin: -1px 7px 0 0;
	display: block;
	width: 14px;
	height: 14px;
}
.btnViewMore{
	padding: 0;
	font-family: "Burbank-Big-Rg-Md";
	font-style: normal;
	font-weight: 550;
	font-size: 14px;
	line-height: 14px;
	color: #FFFFFF;
	border: none !important;
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	text-decoration: none !important;
	background-color: transparent !important;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	transition: all 0.2s;
}
.btnViewMore i{
	margin-left: 9px;
	font-size: 12px;
}
.btnViewMore:hover{
	color: #49C3FD;
}
.btnViewMore .btnViewMore_view{
	display: none;
}
.btnViewMore .btnViewMore_hide{
	display: block;
}
.btnViewMore.reviews-hide .btnViewMore_view{
	display: block !important;
}
.btnViewMore.reviews-hide .btnViewMore_hide{
	display: none !important;
}
/*.pfl_info_row{*/
.pfl_info-container{
	margin: 28px 0 0;
}
.pfl_info_row{
	margin: 0 -5px;
	padding: 3px 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
}
.pfl_info_col{
	padding: 5px 5px;
	flex-grow: 1;
}
.pfl_info_col-number{
	max-width: 200px;
}
.pfl_info{
	border-radius: 4px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	color: #fff;
	font-style: normal;
	font-weight: 550;
	font-size: 13px;
	line-height: 13px;
	font-family: "BurbankSmall-Medium";
}
.pfl_info-number{
	padding: 18px 13px 18px 14px;
}
.pfl_info_l{
	margin: 0 12px 0 0;
}
.pfl_info_num{
	margin: 0 0 5px;
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.9px;
}

.pfl_info_col-level{
	max-width: 254px;
}
.pfl_info_col-level .pfl_info_r{
	width: 100%;
}
.pfl_info-level{
	padding: 11px 11px 11px 20px;
}
.pfl_info-level_num{
	margin: 0 0 6px;
	font-style: normal;
	font-weight: 550;
	font-size: 26px;
	line-height: 26px;
	text-align: center;
	letter-spacing: 0.1em;
	color: #FFFFFF;
	font-family: "Burbank-Big-Rg-Md";
}
.pfl_info-level_ttl{
	display: block;
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: 550;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
	letter-spacing: 0.05em;
	color: #FFFFFF;
	opacity: 0.5;
}


/*pfl_pers*/
.pfl_pers-container{
	margin: 52px 0 0;
}
.pfl_pers_row{
	margin: 11px -5px 0;
}
.pfl_pers_row .col-12{
	padding: 5px 5px;
}
.pfl_pers{
	background: rgba(255,255,255,0.1);
	color: #fff;
	font-family: "BurbankSmall-Medium";
}
.pfl_pers-small{
	padding: 13px 20px 6px 20px;
}
.pfl_pers-big{
	padding: 13px 20px 22px 20px;
}
.pfl_pers-big .pfl_pers_ttl{
	margin-bottom: 7px;
}
.pfl_pers_ttl{
	margin: 0 0 4px;
	font-style: normal;
	font-weight: 550;
	font-size: 13px;
	line-height: 15px;
	display: flex;
	align-items: center;
	color: rgba(255, 255, 255, 0.4);
}
.pfl_pers_txt{
	font-style: normal;
	font-weight: 550;
	font-size: 14px;
	line-height: 24px;
}
.pfl_pers_txt p{
	margin: 0;
}
.pfl_pers_txt a{
	color: #fff;
	transition: all 0.2s;
	text-decoration: none !important;
}
.pfl_pers_txt a:hover{
	color: #49C3FD;
}

.recentCCcontainer{
	margin: 28px 0 0;
}
/*@media screen and (min-width: 390px) {*/
	.recentCRow{
		margin: 16px 0 0;
		padding: 0 10px 0;
		background: rgba(255,255,255,0.1);
		border-radius: 2px;
	}
/*}*/
@media screen and (min-width: 400px) {
	.recentCRow{
		padding: 0 30px 15px;
	}	
}


.recentCCol{
	position: relative;
	border-bottom: 1px solid rgba(255,255,255,0.2);
}
.recentCCol:last-of-type{
	border-bottom: none;
}

.recentC{
	overflow: hidden;
	position: relative;
	margin: 0 -10px;
	padding: 20px 0 19px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	//-webkit-flex-wrap: wrap;
	//-moz-flex-wrap: wrap;
	//-ms-flex-wrap: wrap;
	//-o-flex-wrap: wrap;
	//flex-wrap: wrap;
	color: #fff;
}
.recentCL,
.recentCR{
	padding: 10px;
}
.recentCR {
	/*width: 100%;*/
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}
a.recentCImg{
	display: block;
	transition: all 0.2s;
}
a.recentCImg:hover{
	opacity: 0.8;
}
.recentCImg img{
	width: 100%;
	max-width: 182px;
}

.recentCTtl{
	margin: 2px 0 10px;
	font-style: normal;
	font-weight: 550;
	font-size: 16px;
	line-height: 23px;
	letter-spacing: 0.05em;
	color: #FFFFFF;
	font-family: "Burbank-Big-Rg-Md";
}
.recentCTtl a{
	color: #fff;
	text-decoration: none !important;
	transition: all 0.2s;
}
.recentCTtl a:hover{
	color: #49C3FD;
}

.recentCInfo {
	margin: 0 -30px 0 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
}
.recentCInfo_i{
	margin: 10px 30px 0 0;
}

.recentCInfo_ttl{
	margin: 0 0 2px;
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: 550;
	font-size: 12px;
	line-height: 12px;
	color: rgba(255, 255, 255, 0.4);
}
.recentCInfo_txt{
	font-style: normal;
	font-weight: 550;
	font-size: 13px;
	line-height: 12px;
	color: #FFFFFF;
}


.rvws-container{
	margin: 55px auto 0;
}
.rvws-row{
	margin: 11px -5px 0;
}
.rvws-col{
	padding: 5px 5px;
}
.rvws{
	padding: 15px 20px 12px;
	background: rgba(255,255,255,0.1);
	border-radius: 4px;
}
.rvws_head{
	padding: 0 0 5px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
	border-bottom: 1px solid rgba(255,255,255,0.2)
}
.rvws_head_l,
.rvws_head_r{
	padding: 0 0 10px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}
.rvws_aut{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}
.rvws_aut_l,
.rvws_aut_r{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}
.usr-ico{
	border-radius: 50%;
	transition: all 0.2s;
	/*font-family: "Burbank-Big-Rg-Md" !important;*/
	/*font-family: "Burbank-Big-Rg-Md";*/
	font-family: "BurbankSmall-Medium";
	text-decoration: none !important;
	-webkit-background-size: cover !important;
	background-size: cover !important;
	background-position: center center !important;
	background-repeat: no-repeat !important;
}
a.usr-ico:hover{
	opacity: 0.75;
}
.usr-ico.usr-ico-md{
	padding-top: 1px;
	width: 25px;
	height: 25px;
	font-weight: 500;
	font-size: 13px;
	line-height: 11px;
	text-align: center;
	color: #FFFFFF;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}
.rvws_ttl{
	margin: 4px 0 0 10px;
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: 550;
	font-size: 14px;
	line-height: 14px;
	color: #FFFFFF;
}
.rvws_ttl a{
	color: #fff;
	transition: all 0.2s;
	text-decoration: none !important;
}
.rvws_ttl a:hover{
	color: #49C3FD;
}
.star-rat{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}
.star-rat_star{
	display: block;
	color: rgba(255,255,255,0.5);
}
.star-rat_star.active{
	color: #F9D100;
}
.star-rat-small{
	font-size: 13px;
}
.star-rat-small .star-rat_star{
	margin: 0 5px 0 0;
}
.star-rat-big{
	font-size: 18px;
}
.star-rat-big .star-rat_star{
	margin: 0 8.5px 0 0;
}

.rvws_txt{
	position: relative;
	padding: 11px 0 0 0;
	color: rgba(255,255,255,0.8);
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: 550;
	font-size: 14px;
	line-height: 20px;
}
@media screen and (min-width: 380px) {
	.rvws_txt-ico{
		padding-left: 17px;
	}
	.rvws_txt-ico:before{
		content: "\f075";
		font-family: "Font Awesome 5 Free";
		display: block;
		position: absolute;
		top: 9px;
		left: 0px;
		font-weight: 400;
		font-size: 10px;
	}
}
.rvws_txt p{
	margin: 0;
}
.rvws_date{
	margin: 13px 0 0;
	font-style: normal;
	font-weight: 550;
	font-size: 13px;
	line-height: 13px;
	text-align: right;
	color: rgba(255, 255, 255, 0.5);
}

/*add-rvws*/
.add-rvws-container{
	margin: 60px auto 0;
}
.add-rvws{
	margin: 16px auto 0;
	padding: 29px 30px;
	background: rgba(255,255,255,0.1);
	border-radius: 4px;
}
.add-rvws_ttl{
	display: block;
	margin: 0 0;
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: 550;
	font-size: 14px;
	line-height: 16px;
	color: #FFFFFF;
}
.add-rvws_ttl_num{
	color: rgba(255,255,255,0.5);
}

.add-rvws_star{
	margin: 12px 0 20px;
}
.add-rvws_text{
	margin: 8px 0 14px;
}
.add-rvws_texta{
	margin: 0 0;
	min-height: 96px;
	width: 100%;
	padding: 20px 20px;
	background: rgba(255,255,255,0.1);
	border-radius: 4px;
	color: #fff;
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: 550;
	font-size: 16px;
	line-height: 16px;
	border: none !important;
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;

}
.add-rvws_texta::placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}
.add-rvws_texta:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.add-rvws_texta::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.add-rvws .btn-green{
	width: 152px;
	justify-content: center;
}




.br-theme-fontawesome-stars .br-widget {
  height: 28px;
  white-space: nowrap;
  font-weight: 600;
}
.br-theme-fontawesome-stars .br-widget a {
  font: normal normal normal 20px/1 "Font Awesome 5 Free";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  margin-right: 2px;
}
.br-theme-fontawesome-stars .br-widget a:after {
  content: '\f005';
  color: rgba(255,255,255,0.5);
  font-weight: 600;
}
.br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: #F9D100;
  font-weight: 600;
}
.br-theme-fontawesome-stars .br-widget a.br-selected:after {
  color: #F9D100;
  font-weight: 600;
}
.br-theme-fontawesome-stars .br-widget .br-current-rating {
  display: none;
}
.br-theme-fontawesome-stars .br-readonly a {
  cursor: default;
}
@media print {
  .br-theme-fontawesome-stars .br-widget a:after {
    content: '\f006';
    color: black;
  	font-weight: 600;
  }
  .br-theme-fontawesome-stars .br-widget a.br-active:after,
  .br-theme-fontawesome-stars .br-widget a.br-selected:after {
    content: '\f005';
    color: black;
  	font-weight: 600;
  }
}


.add-rvws_star .br-theme-fontawesome-stars .br-widget{
	height: 25px;
}
.add-rvws_star .br-theme-fontawesome-stars .br-widget a{
	transition: all 0.2s;
	margin: 0 8.5px 0 0;
	font-size: 18px;
}


.profile-modal{
	height: auto !important;
	padding-top: 40px;
	padding-bottom: 40px;

}
.profile-modal-success{
	padding-bottom: 34px;
}
.profile-modal-success,
.profile-modal-medium{
	padding-left: 15px;
	padding-right: 15px;	
}
@media screen and (min-width: 576px) {
	.profile-modal-medium.window{
		width: 100%;
		max-width: 560px;
	}
	.profile-modal-success,
	.profile-modal-medium{
		padding-left: 35px;
		padding-right: 35px;	
	}
	.profile-modal-success{
		width: 100%;
		max-width: 460px;	
	}
}
.profile-modal_ttl--big{
	margin: 0 0 0;
	font-family: "Burbank-Big-Rg-Md";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	color: #001030;
}
.disable-premium .profile-modal_ttl--big{
	margin-bottom: 18px;
}
.profile-modal_txt--small{
	margin: 0 0 0;
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 27px;
	color: #001030;
}
.profile-modal_txt--big{
	margin: 0 0 0;
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #001030;	
}
.profile-modal-success .profile-modal_txt--big{
	margin: 26px 0 0;
}
.disable-premium_buttons{
	max-width: 430px;
	margin: 22px auto 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}
.btn-violet-ountline{
	margin: 10px 5px 0;
	padding: 3px 0 0;
	display: block;
	position: static;
	width: 100%;
	height: 40px;
	border: 1px solid #CA25B3;
	border-radius: 2px;
	outline: none !important;
	background-color: #fff;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	color: #000;
	font-family: "Burbank-Big-Cd-Bd";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	text-decoration: none !important;
	color: #CA25B3;
	transition: all 0.2s;
}
.btn-violet-ountline:before,
.btn-violet-ountline:after{
	display: none;
}
.btn-violet-ountline:hover{
	color: #fff;
	background: #CA25B3;
}
.btn-violet-primery{
	margin: 10px 5px 0;
	padding: 3px 0 0;
	display: block;
	position: static;
	width: 100%;
	height: 40px;
	border: 1px solid #CA25B3;
	border-radius: 2px;
	outline: none !important;
	background-color: #CA25B3;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	font-family: "Burbank-Big-Cd-Bd";
	font-style: normal;
	font-weight: bold;
	font-size: 16px !important;
	line-height: 16px !important;
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	text-decoration: none !important;
	color: #fff;
	transition: all 0.2s;
}
.btn-violet-primery:hover{
	opacity: 0.8;
}


.bil_modal_hdr{
	margin: 0 0 14px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
}
.bil_modal_hdr_i{
	margin-bottom: 10px;
}
.bil_modal_hdr_ttl{
	margin: 0 0;
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 35px;
	color: #001030;
}
.bttn_blue-light{
	padding: 2px 0 0;
	width: 115px;
	height: 36px;
	display: block;
	background: rgba(73, 195, 253, 0.1);
	color: #49C3FD;
	text-align: center;
	text-decoration: none !important;
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border: none !important;
	transition: all 0.2s;
	font-family: "Burbank-Big-Cd-Bd";
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}
.bttn_blue-light:hover{
	background: rgba(73, 195, 253, 1);
	color: #fff;
}

.bil_modal-container .window__header{
	font-weight: 500;
	font-size: 21px;
	line-height: 35px;
}

.bil_modal-container__window{
	height: auto !important;
    max-height: calc(100% - 100px);
    overflow: auto;
}
.bil_modal-container__window .window__container{
	position: static;
	margin: 0 auto;
	padding: 0 20px 25px 20px;
}
.bil_modal-container__window .window__header{
	margin-bottom: 30px;
}
.bil_modal_btns{
	margin: 10px -5px 10px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}
.bil_modal_btns_i{
	width: 180px;
	max-width: 180px;
	padding: 5px 5px;
}
.bttn_blue-outline{
	position: static;
	padding: 2px 0 0;
	display: block;
	width: 100%;
	height: 40px;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	outline: none !important;
	background: transparent;
	border: 1px solid #49C3FD;
	border-radius: 2px;
	font-family: "Burbank-Big-Cd-Bd";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 14px;
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: #49C3FD;
}
.bttn_blue-outline:before,
.bttn_blue-outline:after{
	display: none !important;
}
.bttn_blue-outline:hover{
	color: #fff;
	background: linear-gradient(90deg, #02B7FE 0%, #0389FD 100%);
}
.bttn_blue-primery{
	padding: 2px 0 0;
	display: block;
	height: 40px;
	width: 100%;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	outline: none !important;
	background: linear-gradient(90deg, #02B7FE 0%, #0389FD 100%);
	border: none !important;
	border-radius: 2px;
	font-family: "Burbank-Big-Cd-Bd";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 14px;
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: #fff;
	transition: all 0.2s;
}
.bttn_blue-primery:hover{
	opacity: 0.8;
}

.form-card-data{
	margin-left: -5px;
	margin-right: -5px;
}
.form-card-data .col-3,
.form-card-data .col-6{
	padding-left: 5px;
	padding-right: 5px;
}
.form-card-data .col-6{
    -ms-flex: 0 0 calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
}
.form-card-data_slash{
	padding: 0 1px;
	font-size: 20px;
	line-height: 20px;
}
@media (max-width: 379px) {
	.form-card-data{
		-webkit-flex-wrap: wrap !important;
		-moz-flex-wrap: wrap !important;
		-ms-flex-wrap: wrap !important;
		-o-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.form-card-data .col-3{
	    -ms-flex: 0 0 40%;
	    flex: 0 0 40%;
	    max-width: 40%;
	}
	.form-card-data .col-6{
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
	}
}


.bil_modal-container--small .bil_modal-container__window{
	max-width: 490px;
	width: 100%;
}
/*.select2_container_style-white*/
/*.select2_dropdown_style-white*/
.select2-container{
	z-index: 100000;
}
.f_select2-white-style{
	margin: 0 0 25px;
}
.f_select2-white-style .select2-container.select2-container--default{
	width: 100% !important;
}
.select2_container_style-white .select2-selection__rendered{
	font-family: "BurbankSmall-Medium" !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: 0.05em !important;
	color: #001030 !important;
}
.f_select2-white-style .select2-selection--single{
	padding: 14px 12px;
	height: 45px;
	border: none !important;
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	background: rgba(0, 16, 48, 0.05) !important;
}
.select2_dropdown_style-white{
	margin: 2px 0;
	border: none !important;
}
.select2_dropdown_style-white .select2-search.select2-search--dropdown{
	display: none;
}
.select2_dropdown_style-white .select2-results__option{
	padding: 10px 17px !important;
	font-family: "BurbankSmall-Medium" !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: 0.05em !important;
	color: #001030 !important;
	background: rgba(0, 16, 48, 0.05) !important;
	transition: all 0.2s;
}
.select2_dropdown_style-white .select2-results__option--highlighted{
	background: rgba(73, 195, 253, 0.2) !important;
}
.select2_dropdown_style-white .select2-results__option[aria-selected=true]{
	background: rgba(0, 16, 48, 0.1) !important;
}

.f_select2-white-style .select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 30px;
    top: 8px;
    right: 13px;
    width: 20px;
}
.f_select2-white-style .select2-container--default .select2-selection--single .select2-selection__arrow b{
    width: 9px;
    height: 5.5px;
    border: none !important;
    outline: none !important;
    background-color: transparent;
    //background: url(../assets/arrow_top-black.svg);
    transform: rotate(180deg);
    margin-left: -7px;
    margin-top: -3px;
    transition: all 0.3s;
}
.f_select2-white-style .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b{
    transform: rotate(0deg);
}


//fixes
.recentCImg__boxSize {
	width: 182px;
	height: 104px;
}

a.recentCImg {
	height: 104px;
}