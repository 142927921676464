.chatMenuLink {
  display: block;
  height: 39px;
  text-decoration: none;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 39px;
  color: #001030;
  padding-left: 19px;
  transition: 150ms;

  &:hover {
    text-decoration: none;
    color: #001030;
    background: rgba(238, 246, 255, 0.6);
  }
}
