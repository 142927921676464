.window.privateUserModel {
  width: 40%;
  height: auto;
  overflow: inherit;
}

.header__profilePicture {
  width: 40px;
  height: 40px;  
  border-radius: 50%;
  margin: 5px 10px;
  &.active {
    border-color: #6cd000;
  }
}

.user_card
{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 0;
    width: 100%;
    flex: auto;
    justify-content: flex-start;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border: 1px solid #f1f1f1;
    padding: 3px 15px;
    max-width: 32%;
    &:hover{
      background: #f1f1f1;
    }
}
.user_card:nth-child(3n)
{
  margin-right: 0;
}
 .user_picture
 {
  padding: 5px 0;
  img{
    margin: 0;
    border: 1px solid #f8f8f8;
  }
 }

.user_name
{
  padding: 0;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.user_list
{
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.user_list_wrapper
{
  max-height: 400px;
  overflow: auto;
}

.load_more_btn {
  padding: 13px 0 0;
  display: inline-block;
  width: 100%;
  height: 40px;
  background: linear-gradient(90deg,#02b7fe,#0389fd);
  border-radius: 2px;
  transition: all .2s;
  font-family: "Burbank-Big-Cd-Bd";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: .05em;
  text-transform: uppercase;
  color: #fff!important;
  text-decoration: none!important;
  box-shadow: none!important;
  outline: none!important;
  border: none!important;
  cursor: pointer;
}

.user_container_wrapper .loading {
  color: #fff;
}


.user_container_wrapper
{
  position: relative;
  width: 100%;
}
.user_container_wrapper .loader_container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  top: 13px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media(max-width: 1350px)
{
  .window.privateUserModel {
    width: 50%;
  }
}

@media(max-width: 1200px)
{
  .window.privateUserModel {
    width: 70%;
  }
}

@media(max-width: 1024px)
{
  .window.privateUserModel {
    width: 85%;
  }
}

@media(max-width: 767px)
{
  .user_card
  {
    width: calc(100% / 2 - 10px);
    max-width: 50%;
  }
  .user_card:nth-child(3n)
  {
    margin-right: 10px;
  }
  .user_card:nth-child(2n)
  {
    margin-right: 0 !important;
  }
}

@media(max-width:480px)
{
  .user_card
  {
    width: 100%;
    max-width: 100%;
  }
  .user_card:nth-child(2n)
  {
    margin-right: 10px !important;
  }
  .user_picture
  {
    max-width: 60px;
  }
  .user_list
  {
    height: 40px;
    overflow: auto
  }
}