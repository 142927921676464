.rightTile {
  font-family: BurbankSmall-Bold, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  border-bottom: 1px solid #25324e;
  margin-bottom: 20px;
}

.rightValue {
  margin-left: 10px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: white;
}

.aboutUs {
  width: 100%;
  min-height: 120px;
  font-size: 15px;
  padding: 20px 20px;
}

.title {
  font-family: "BurbankSmall-Light", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  margin: 19px 0 12px 0;
  color: rgba(255, 255, 255, 0.7);
}

.card {
  max-width: 260px;
}

.inviteButton {
  width: 245px;
  height: 40px;
}

@media (min-width: 768px) {
  .inviteContainer {
    border-left: 1px solid #25324e;
    padding-left: 10px;
  }
}

.selectedPlayersContainer {
  max-height: 336px;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}
