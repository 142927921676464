.updateImageModal {
  &.window {
    width: 640px;
    padding: 50px 20px 40px 20px;
  }

  @media screen and (max-width: 575.98px) {
    &.window {
      padding: 40px 30px;
    }
  }

  .button {
    height: 40px;
  }
}
