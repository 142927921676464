@import "src/css/vars";
@import '../../../css/images';

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes blink {
  from {
    opacity: 0.4;
  }
  15% {
    opacity: 0.45;
  }
  50% {
    opacity: 1;
  }
  85% {
    opacity: 0.45;
  }
  to {
    opacity: 0.4;
  }
}

.tournament__venueInfo {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url($info);
  position: relative;
  margin-left: 10px;
  top: 3px;
}

.tournament__infoContainer {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
  padding-bottom: 10px;
  display: none;
  opacity: 0;
  display: none;

  .tournament__venueInfo:hover & {
    display: block;
    opacity: 1;
    animation: show 150ms ease-in-out;
  }
}

.tournament__infoContent {
  background: white;
  border-radius: 2px;
  padding: 13px 17px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #001030;
  white-space: nowrap;

  &::before {
    content: "";
    position: absolute;
    display: block;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    bottom: 1px;
    border: 6px solid transparent;
    border-top: 3px solid white;
  }
}

.game {
  position: relative;
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 0 15px 15px 15px;
}

.gameOrganizer::after {
  content: "Organizer";
  display: block;
  position: absolute;
  background: #6cd000;
  width: 85px;
  height: 27px;
  right: -10px;
  top: 11px;
  text-align: center;
  font-family: "Burbank Small", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 27px;
  color: white;
  z-index: 3;
}

.gameOrganizer::before {
  content: "";
  display: block;
  position: absolute;
  background: #5aae00;
  top: 8px;
  right: -8px;
  width: 10px;
  height: 8px;
  transform: rotate(30deg);
}

.game__conditionBackground {
  position: absolute;
  background: #b82200;
  top: 8px;
  right: -8px;
  width: 10px;
  height: 8px;
  transform: rotate(30deg);
}

.game__condition {
  position: absolute;
  background: linear-gradient(90deg, #ec5418 0%, #df2d06 100%);
  width: 85px;
  height: 27px;
  right: -10px;
  top: 11px;
  text-align: center;
  font-family: "Burbank Small", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 27px;
  color: white;
  z-index: 5;
}

.gameOrganizer .game__conditionBackground {
  top: 48px;
}

.gameOrganizer .game__condition {
  top: 51px;
}

.game__condition-checkbox {
  display: none;
}

.game__condition-label {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: transparent;
  cursor: pointer;
}

.game__conditionContainer {
  position: absolute;
  left: 50%;
  bottom: 26px;
  width: 236px;
  padding-bottom: 10px;
  display: none;
  transform: translate(-50%, 0);
}

.game__conditionContainer::after {
  content: "";
  display: block;
  transform: translate(-50%, 0);
  position: absolute;
  width: 0;
  height: 0;
  bottom: -3px;
  left: 50%;
  border: 8px solid transparent;
  border-top: 8px solid #ff5d20;
}

.game__conditionContent {
  width: 100%;
  background: #ff5d20;
  border-radius: 2px;
  padding: 10px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: white;
}

.game__condition:hover .game__conditionContainer {
  display: block;
}

@media screen and (max-width: 1589.98px) {
  .game__conditionContainer {
    left: auto;
    right: 0;
    transform: translate(0, 0);
  }
  .game__conditionContainer::after {
    left: auto;
    right: 25px;
  }
}

.game__picture {
  width: calc(100% + 30px);
  height: 200px;
  margin: 0 -15px 17px -15px;
  z-index: 2;
}

.game__title {
  margin-block-start: 0;
  margin-block-end: 0;
  height: 46px;
  font-family: "Burbank Small", sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 23px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  color: white;
  margin: 0 0 17px 0;
  transition: 150ms;
  text-decoration: none;
}

.game_title:hover {
  text-decoration: none;
  color: #49c3fd;
}

.game__platforms {
  margin: 0 -30px 17px -30px;
  padding: 0 15px 17px 15px;
}

.tournament {
  margin: 0 -30px 15px -30px;
  padding: 0;
}

.tournament__header {
  font-family: "Burbank Small", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  margin: 0 0 7px 0;
}

.tournament__info {
  font-family: "Burbank Small", sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  margin: 0 0 17px 0;
  transition: 150ms;
}

.game__signed {
  font-family: "Burbank Small", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 13px;
}

.game__signed img {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-left: -15px;
  border: 1px solid #1e2d49;
}
.game__signed img:first-of-type {
  margin-left: 0;
}
.game__signed img:last-of-type {
  margin-right: 5px;
}

.game__signed span {
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.05em;
  margin-left: 1px;
}

.game__button {
  width: 100%;
  height: 40px;
}

.game__platform {
  height: 11px;
  background-size: 100% 100%;
  background-color: transparent;
  opacity: 0.4;
  margin-right: 15px;
}

.game__platform__crossPlatform {
  width: 78px;
  background-image: url(#{$cdn}/img/cross-platform.png);
}
.game__platform__xbox {
  width: 60px;
  background-image: url(#{$cdn}/img/xbox-one.png);
}
.game__platform__ps {
  width: 51px;
  background-image: url(#{$cdn}/img/playstation.png);
}
.game__platform__pc {
  width: 14px;
  background-image: url(#{$cdn}/img/pc.png);
}
.game__platform__nintendo {
  width: 43px;
  background-image: url(#{$cdn}/img/nintendo.svg);
}

.game__platform__ios {
  width: 14px;
  background-image: url(#{$cdn}/img/ios.svg);
}

.game__platform__android {
  width: 43px;
  background-image: url(#{$cdn}/img/android-white-text.png);
  // background-image: url("../../../../static/img/android.png");
}


.game {
  width: calc(25% - 20px);
  margin-left: 8px;
  margin-right: 8px;
}

.game:nth-of-type(4n - 3) {
  margin-left: 16px;
}

@media screen and (max-width: 1878.98px) {
  .game {
    margin-left: 16px;
    margin-right: 16px;
    width: calc((100% / 3) - 32px);
  }
}

@media screen and (max-width: 1589.98px) {
  .right-menu {
    position: fixed;
    right: -255px;
    transition: 150ms;
    height: calc(100vh - 82px);
    overflow: auto;
    min-height: calc(100vh - 82px);
    top: 82px;
  }
  .right-menu--opened {
    right: 0;
  }
  .header__section--friends {
    display: flex;
  }
}

@media screen and (max-width: 1309.98px) {
  .game {
    width: calc((100% / 2) - 32px);
  }
}

@media screen and (max-width: 1309.98px) {
  .game {
    width: calc((100% / 2) - 32px);
  }
  .game__time,
  .game__prize {
    margin-left: 15%;
  }
}

@media screen and (max-width: 1069.98px) {
  .game__time,
  .game__prize {
    margin-left: 0;
  }
}

@media screen and (max-width: 869.98px) {
  .game {
    width: calc(100% - 32px);
  }
  .game__time,
  .game__prize {
    margin-left: 26%;
  }
}

@media screen and (max-width: 767.98px) {
  .game__time,
  .game__prize {
    margin-left: 20%;
  }
}

@media screen and (max-width: 667.98px) {
  .game__time,
  .game__prize {
    margin-left: 15%;
  }
}
@media screen and (max-width: 599.98px) {
  .game__time,
  .game__prize {
    margin-left: 10%;
  }
}

@media screen and (max-width: 575.98px) {
  .game__time,
  .game__prize {
    margin-left: 0;
  }
  .game {
    width: 100%;
  }
  .game__logo {
    margin: 0 10px 17px 0;
  }
  .game__title {
    margin: 0 0 10px 0;
    height: 23px;
  }
  .game__platforms {
    padding: 0 10px 10px 10px;
  }
  .game__platform {
    margin: 4px 7px;
  }
}

.remainingTime {
  width: 140px;
  height: 40px;
  border-radius: 2px;
  margin-left: 5px;
  padding: 8px 0 7px 0;
}

.remainingTime__container {
  position: relative;
}

.remainingTime__container::before,
.remainingTime__container::after {
  content: "";
  position: absolute;
  right: -1px;
  top: 4px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
}

.remainingTime__container::after {
  top: 10px;
}
.remainingTime__container:last-of-type::before,
.remainingTime__container:last-of-type::after {
  display: none;
}

.remainingTime__time {
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition: 150ms;
}

.remainingTime__text {
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.05em;
  color: white;
  transition: 150ms;
}



@media screen and (max-width: 575.98px) {
  .remainingTime {
    margin: 0 0 10px 0;
  }
}

.tournament__running {
  position: absolute;
  left: 15px;
  top: 15px;
  width: 70px;
  height: 26px;
  background: rgba(255, 255, 255, 0.32);
  border-radius: 2px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-transform: uppercase; 
  color: white; 
  z-index: 10;
  animation: blink 3s ease-in-out infinite;
}

.game__cover {
  height: 200px; 
  overflow: hidden;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 15px;
}

.game__video {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.game__condition:hover {
  z-index: 1075;
}

.qualifierLink {
  font-weight: bold;
  cursor: pointer;
  color: white;
}

.qualifierLink:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 15px;
  transition: all 0.3s cubic-bezier(0, 0.99, 0.44, 0.99);
  color: white;
}