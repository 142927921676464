.article {
  padding-top: 0 !important;
}

.go-back {
  font-size: 1rem !important;
  padding: 10px !important;
  cursor: pointer;
}

.td__rFix {
  transform: rotate(180deg);
  margin-left: 0 !important;
  margin-right: 10px;
}
.tournament--article-header__background{
  height: 500px !important;
}

.tournament--article-header__header{
  margin-top: 0 !important;
}
.tournament--tab-menu__link{
  padding: 0 0 24px !important;
  margin-left: 0 !important;
  opacity: 0.8 !important;
}
.tournament--tab-menu__link--active{
  opacity: 1 !important;
}

.tournament-article__header-container{
  position: relative;
  margin-right: 0 !important;
}

.btn-hover-blue,
.btn-hover-red {
  border: none;
  padding-top: 5px;
  transition: 0.3s all ease-in-out;
  width: 140px;
  background-color: #4b566d !important;
  color: #fff;
  height: 40px;
}
.table__main-row.participant-row {
  display: flex !important;
}
.participant-row:hover .btn-hover-red {
  background: linear-gradient(to right, #db2953, #e3404a);
}
.participant-row:hover .btn-hover-blue {
  background: linear-gradient(to right, #02b9ff 0%, #0388fd 100%);
}
.participant-row .btn-hover-blue.btn-drop{
  margin-left: 10px;
  width: 42px;
  height: 40px;
}
.tab-menu__link{
  cursor: pointer;
}
.table__secondary-row-container.clan-member {
  margin: 0 -15px;
  border-top: 1px solid rgba(255,255,255,0.2);
}
.clan-member .participant-row {
  padding-left: 80px;
}
.old-kl-css .participants .table__row {
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 0;
  transition: 0.15s;
}
.list-wrapper {
  margin-bottom: 15px;
}
.old-kl-css .admin-wrapper .table__row {
  margin: 0;
  padding: 0;
}
.admin-wrapper .table__secondary-row-container.clan-member
{
  padding: 0;
}
.participant-row .table__column:first-child {
  padding: 0 !important;
}
.admin-wrapper .table__secondary-row-container.clan-member {
  margin: 0 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.admin-wrapper .li-wrapper {
  padding-left: 80px;
}
.old-kl-css .table__row.draft-row
{
  margin-bottom: 0;
}
// .btn-hover-red {
//     margin-right: 15px;
// }

.tournament-modal-window{
  padding: 40px;
}

.tournament-modal-subheader{
  margin-top: 2rem;
  font-size: 16px;
  width: 100%;
  text-align: center;
}
.participants {
  margin: 0 !important;
}
.participants_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.participants_name h2 {
  margin-bottom: 0 !important;
}
.search {
  margin-bottom: 0 !important;
}
.participants_item .row {
  margin: 0 0px 15px;
}

.participants_item .row .col-12 {
  padding: 0;
}
@media screen and (min-width: 576px) {
  .td__details {
    margin: 0 !important;
  }
}

@media screen and (max-width: 576px) {
  .tournament--tab-menu__link{
    margin-right: 25px !important;
  }

  .tournament-content {
    margin: 0 -30px;
  }
  .tournament-padding-0 {
    padding: 0 !important;
  }
  .tournament-margin-0 {
    margin: 0 !important;
  }
  .tournament--article-header__content {
    padding: 25px 20px !important;
  }
  .tournament--article-header__background {
    height: 340px !important;
    min-height: auto !important;
    margin-bottom: 285px !important;
  }
  .tournament--article-header__registration-container {
    margin: 0 !important;
    margin-bottom: 100px !important;
    width: auto !important;
  }
  .tournament--registration__time {
    font-size: 13px !important;
  }
  .tournament--row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    max-width: none !important;
    flex: none !important;
    width: auto !important;
  }
  .tournament--row-btn {
    margin: 0 -15px !important;
  }
  .tournament--row-btn a {
    width: calc(100% - 20px) !important;
  }
  .tournament--article__help {
    padding: 0 35px !important;
  }
  .tournament-column {
    padding: 0;
    margin: 0 -10px !important;
    width: calc(100% + 20px) !important;
    max-width: calc(100% + 20px) !important;
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 calc(100% + 20px) !important;
    flex: 0 0 calc(100% + 20px) !important;
  }
  .tournament--article-header__header {
    font-size: 24px !important;
    line-height: 33px !important;
    margin-bottom: 15px !important;
    margin-top: 60px !important;
    min-height: 99px !important;
    text-overflow: ellipsis;
    height: 99px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .tournament--registration__join {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
  }
  .tournament--help__container {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .tournament--border {
    border-bottom: 1px solid #e8eef6;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
  .tournament--tab-menu__link--active {
    opacity: 1 !important;
  }
  .tournament-article-header__change-header{
    margin-bottom: 70px !important;
  }
   .tournament--tab-menu__link {
    margin-right: 0 !important;
  }
}

label.window__cancel-container._borderless {
  border: none;
  margin-top: 10px;
  padding: 0;
  padding-top: 15px;
  height: fit-content;
  width: 100%;
}
label.window__cancel-container._borderless span{
  font-size: 22px;
}
label.window__cancel-container._active {
  border-color: #0393fe;
}
.video-wrapper> div {
  max-width: 100%;
}




/*********** 21-06-2021 *************/
.rule-start {
  margin-top: 15px;
}
.video-wrapper {
  padding: 0;
  min-height: 30vh;
}
.parti-user {
  display: flex;
  align-items: center;
}

.participants_item .col-12 .row {display: flex;justify-content: space-between;}

.party-btn {
  display: flex;
  justify-content: flex-end;
}
.adm_content .see-more {
  color: #000;
}
.adm_content .see-more:hover {
  color: #000;
}
.adm_content .see-more svg path {
  fill: #000;
}
@media(max-width: 991px)
{
.header-custom {
  padding: 15px !important;
}
.header-custom .article-header__consoles-container {
  margin-bottom: 0;
}
.header-custom .article-header__header {
  width: 100% !important;
  margin-bottom: 20px !important;
}
.header-custom .article-header__registration-container {
  margin: 0 !important;
}
.article-header__tab-menu-container.regi {
  padding: 0 30px !important;
}
.article-header__tab-menu-container.regi .article-header__tab-menu-button--left {
  left: 0;
  bottom: 26px !important;
}
.article-header__tab-menu-container.regi .article-header__tab-menu-button--right {
  right: 0;
  bottom: 26px !important;
}
.eliminationBracket .eli_grid .eli_row:first-of-type .eli_col:first-of-type .eli_general-padding {
   margin-left: 0;
    padding-left: 0;
    width: 300px;
    max-width: 300px;
}
.eliminationBracket .eli_grid .eli_row:last-of-type .eli_col:last-of-type .eli_general-padding {
  margin-right: 0;
  padding-right: 0;
  width: 300px;
  max-width: 300px;
}
.eliminationBracket .eli_general-width {
  width: 100%;
  max-width: 50%;
}
.adm #touranmentDetailTab a {
  color: #000 !important;
  box-shadow: none;
}
}

@media(max-width: 767px)
{
  .header-custom .tournament--article-header__header {
    font-size: 21px !important;
    min-height: 0 !important;
    height: auto !important;
    margin: 0 !important;
}
.header-custom .article-header__container {
  padding-bottom: 0;
}
.header-custom .article-header__header, .header-custom .article-header__info-container {
  margin: 2% 0%;
}
.tournament--article-header__registration-container {
  margin: 0 0 0 15px !important;
  margin-bottom: 100px !important;
  width: auto !important;
}
.header-custom .registration__info {
  padding: 15px !important;
  margin-bottom: 15px;
}
.article .ct__article-header__background {
  height: 400px !important;
  min-height: inherit !important;
  margin-bottom: 50px !important;
}
.regi .tab-menu__link {
  font-size: 14px !important;
  padding: 10px 5px !important;
}
.article-header__tab-menu-container.regi .article-header__tab-menu-button--left {
  left: -5px;
  bottom: 13px !important;
}
.article-header__tab-menu-container.regi .article-header__tab-menu-button--right {
  right: -5px;
  bottom: 13px !important;
}
.article-header__tab-menu-container.regi {
  padding: 0 15px !important;
}
.article .regi .tab-menu__link {
  margin: 0 8px 0 9px;
}
.participants_top {
 
  flex-direction: column;
}
.participants_name {
  width: 100%;
  margin-bottom: 15px;
}
.search {
  width: 100%;
}
.next-arrow .HorizontalScroll_scrollBlock_right__3gNe9 {
  width: 30px;
  background: transparent;
  right: -40px;
}
.eliminationBracket .eli-container {
  overflow: visible;
}
.next-arrow .HorizontalScroll_scrollBlock_left__2nBXg {
  left: -60px;
  background: transparent;
}
.HorizontalScroll_scrollBlock_right_inn__390w3 {
  right: -22px;
}
.google-map > div {
  height: 350px !important;
}
.eliminationBracket .eli_general-width {
  width: 310px;
  max-width: 50%;
}
.TournamentRules_pageHead__1C7Ec h1 {
  margin: 0 !important;
}
.TournamentRules_pageHead__1C7Ec {
  margin-top: 15px;
}
.eliminationBracket .grid-full-screen .eli_fscreen-hdr {
  flex-direction: row !important;
}
.eliminationBracket .grid-full-screen .eli-container {
  left: 2%;
  width: 96%;
  height: 100%;
  overflow: inherit;
}
.article .article-header__content,.cover-btn-container
{
  display: none;
}

.show-tournament-header-cover .article-header__content.tournament--article-header__content,.show-tournament-header-cover .cover-btn-container {
  display: block;
  padding: 15px 0 !important;
  left: 15px;
}
.desc li {
  line-height: 22px !important;
  font-size: 16px !important;
}
.desc {
  padding-left: 15px;
}
.social-media {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.social-media .footer__icon {
  max-width: 35px !important;
}
.old-kl-css .scrolltop {
  width: auto;
  padding: 0 !important;
}
.faq .scrolltop .icon {
  padding: 10px 4px 8px 14px !important;
  padding-right: 26px !important;
}
.faqRow {
  padding: 15px !important;
}
.__input-container--search:after {
  width: 105px !important;
  text-align: center !important;
}
.faq .__input-container--search:before {
  width: 24px !important;
  height: 23px !important;
  left: 10px !important;
  margin-top: -8px !important;
}
.tournament--article-header__background {
  display: none;
}
.show-tournament-header-cover .tournament--article-header__background
{
  display: block;
}
.cover-btn-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0 0 0;
}
.article .ct__article-header__change-header {
  bottom: 35px !important;
  right: 10px !important;
}
.see-more {
   margin-left: -40px;
}
.cover-btn-wrapper a:nth-child(2) {
  order: -1 !important;
}
.article__edit-link.blue-button--filled {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
#article-comp .tournament-content {
  margin: 0;
}
#article-comp #details-container {
  margin: 0;
}
#article-comp #details-container {
  margin: 0;
}
.adm_content .tournament-detail-bottom-content {
  margin: 0;
}
.adm_content .tournament-detail-bottom-content #details-container {
  margin: 0;
  padding: 0;
}
.adm_content .tournament-detail-bottom-content .tournament-column {
  margin: 0px !important;
}
.adm_content .tournament-column {
  max-width: 100% !important;
  width: 100% !important;
}
.adm .article__tournament-title {
  font-size: 26px !important;
  
}
.adm_content .tournament--row {
  margin: 0;
}
.adm_content .tournament--row-btn {
  margin: 0 !important;
}
.adm_content .tournament--row-btn button {
  width: 150px !important;
}
.adm #touranmentDetailTab a {
  width: auto !important;
  
}
.adm_content .article-header__tab-menu-button.article-header__tab-menu-button--right.white {
  right: 0;
}
.adm_content .article-header__tab-menu-button.article-header__tab-menu-button--left.white
{
  left: 0;
}
.adm_content .see-more {
  margin-left: 0;
  color: #000;
}
.tournament-margin-0.row {
  margin: 0;

}
.adm nav#touranmentDetailTab {
  bottom: 0 !important;
}
.adm .ct__article-header__content {
  padding: 20px 15px !important;
  display: none;
}
.adm_content .next-arrow .HorizontalScroll_scrollBlock_right__3gNe9 {
  right: 0;
}
.adm_content .rule-start {
  margin: 15px 15px 0;
}

}
@media(max-width: 575px)
{
  .google-map-wrapper {
    padding: 5px;
    margin-top: -15px;
  }
  .eliminationBracket {
    margin: 0 -30px;
}
.adm_content .eliminationBracket {
  margin: 15px;
}
.rule-start {
  margin-top: 15px;
  margin: 15px -30px 0;
}
.adm_content .rule-start {
  margin: 15px;
}
#article-comp .eliminationBracket {
  margin: 0;
}
#article-comp .rule-start {
margin-top: 15px;
margin: 15px 0 0;
}
#article-comp .maincol-tournament-createx {
  padding: 30px;
}
#article-comp .helpcol {
  padding: 0 15px;
}
.adm_content .tournament-content {
  margin: 0 !important;
}


}
@media(max-width: 480px)

{
  .adm_content .tournament-article__header-container {
    margin: 0 15px !important;
}
.adm_content .article-header__tab-menu-button.article-header__tab-menu-button--right.white {
  right: -5px;
}
.adm_content .article-header__tab-menu-button.article-header__tab-menu-button--left.white
{
  left: -5px;
}
  .adm_content .see-more {
    margin-left: 0;
    color: #000;
  }
  .adm_content .tournament-content {
    margin: 0;
}
  #article-comp .tournament-content {
    margin: 0;
}
#article-comp #details-container {
  margin: 0;
}
  .article .ct__article-header__background {
    height: 500px !important;
    
}
.header-custom .article-header__consoles-container {
  margin-bottom: 15px;
}
.tournament-content {
    margin: 0 -30px 15px;

}
.article__header-container {
  margin: 15px 0 0!important;
  flex-direction: column;
  padding-bottom: 15px !important;
}
.article .ct__registration__column {
  padding-right: 25px;
  margin-right: 10px;
}
.header-custom .registration__info {
  padding: 10px !important;
}
.old-kl-css .article .article-header__platform {
  width: calc(100% / 3 - 4px);
  background-size: auto 30%;
}
.eliminationBracket .eli_search_input {
  padding: 0 10px 0 39px;
  width: 140px;
}
.article__big-subheader-white {
  font-size: 16px;
}
.DropDownTableDark_tableColumn__2WpAO {
  padding: 0 10px;
  width: auto;
}
.participants_article__participantUsername__1ibhI {
  margin-left: 10px;
}
.google-map > div {
  height: 200px !important;
}
.participants_name .article__big-subheader-white {
  font-size: 16px;
  padding-left: 0;
}
.eliminationBracket .eli_general-width {
  width: 260px;
  max-width: 50%;
}
.eliminationBracket .eli_hdr_i_i {
  
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.eliminationBracket .eli_hdr_date {
  margin: 5px 0 0 0;
 
}
.eliminationBracket .eli_grid .eli_row:first-of-type .eli_col:first-of-type .eli_general-padding,
.eliminationBracket .eli_grid .eli_row:last-of-type .eli_col:last-of-type .eli_general-padding {
  margin-left: 0;
  padding-left: 0;
  width: 250px;
  max-width: 300px;
}
.TournamentRules_pageHead__1C7Ec h1 {
  margin: 0 !important;
}
.next-arrow .HorizontalScroll_scrollBlock_right__3gNe9 {
  width: 30px;
  background: linear-gradient(270deg, #051534 7.29%, rgba(5, 21, 52, 0.84) 34.38%, rgba(5, 21, 52, 0) 100%);
  right: 0;
}
.next-arrow .HorizontalScroll_scrollBlock_left__2nBXg {
  left: 0;
  background: linear-gradient(270deg, #051534 7.29%, rgba(5, 21, 52, 0.84) 39.58%, rgba(5, 21, 52, 0) 100%);
  width: 30px;
}

 .HorizontalScroll_scrollBlock_right_inn__390w3 {
  
  right: 0px;
}


.HorizontalScroll_scrollBlock__arrow__gradient__2SkQh .HorizontalScroll_scrollBlock_right_inn__390w3 {
  margin-right: 0;
}
.HorizontalScroll_scrollBlock__arrow__gradient__2SkQh .HorizontalScroll_scrollBlock_left_inn__2kUN6 {
  margin-right: 8px;
}
.page-head-top {
  font-size: 20px !important;
}
.faqRow .panel-title a {
  font-size: 18px !important;
  
}
.eliminationBracket .grid-full-screen .eli_fscreen-hdr {
  flex-direction: column !important;
}
.eli_fscreen-hdr .eli-header {
  width: 100%;
  text-align: center;
}
.eli_fscreen-hdr .eli-header .eli-header_r {
  display: flex;
  justify-content: center;
  margin: 0 0 10px 0;
}
.eli_fscreen-hdr .eli-header_ttl {
  margin: 0 !important;
}
.eli_fscreen-hdr .eli-header_type {
  margin-right: 0;
}
body section.__input-container.__input-container--search::before {
  top: 27px !important;
}
section.__input-container.__input-container--search::after {
  bottom: -45px !important;
}
.DropDownTableDark_tableRow__2Ymqu .DropDownTableDark_tableButton__3z_BP {
  width: 70px;
  height: 40px;
  margin-left: auto !important;
}

.article__cancel-tournament {
  padding: 0 5px;
}
// .maincol-tournament-createx
// {
//   padding: 15px !important;
// }
}
@media(max-width: 374px)
{
  .old-kl-css .registration__header {
    font-size: 20px;
    
}
.tournament--registration__time {
  font-size: 11px !important;
}
.eliminationBracket .eli_search_input {
  padding: 0 10px 0 35px;
  width: 127px;
}


}

@media(max-width: 350px)
{
  .page-head-top {
    font-size: 16px !important;
}
.faqRow .panel-title a {
  font-size: 13px !important;
}
.desc li {
  line-height: 17px !important;
  font-size: 13px !important;
}
.p-description.bold, .p-description h3 {
  font-size: 15px;
  line-height: 20px;
  
}
.faq .__input {
  height: 36px !important;
  padding: 5px 10px 0 39px !important;
  margin: 10px 0 10px !important;
}
.__input-container--search:after {
  width: 182px !important;
  text-align: center !important;
  height: 40px !important;
  line-height: 19px !important;
  font-size: 16px !important;
  margin-top: 0 !important;
  position: absolute !important;
  margin: 40px auto 0 !important;
  padding: 9px 15px !important;
  top: 3px !important;
  left: 0;
}
body section.__input-container.__input-container--search::before {
  top: 19px !important;
  background-size: 16px !important;
  background-repeat: no-repeat;
}
.article .article-header__platform {
  width: calc(100% / 3 - 4px);
  background-size: auto 21%;
}
.registration__header {
  font-size: 14px;
}
.header-custom .tournament--article-header__header {
  font-size: 14px !important;

}
}