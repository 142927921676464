@import "./DropDownTable.scss";
@import "./DropDownTableMixinsDark.module.scss";

.tableSecondaryRowContainer {
  background: rgba(255, 255, 255, 0.1);

  &.secondaryRowOpened {
    background: rgba(255, 255, 255, 0.2);
    transform: translate(0) scaleY(1);
    max-height: 530px;
    padding: 15px 0;
  }
}

.tableMainRow {
  background: rgba(255, 255, 255, 0.1);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.tableMainRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 70px;
  z-index: 2;
  transition: 300ms;
  border-bottom: 1px solid rgba(255, 255, 255, 0);

  &.opened {
    background: rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid #303d55;
  }

  &.highlighted {
    background: hsla(0, 0%, 100%, 0.2);
    border: 1px solid #49c3fd;
  }
}

.tableRadio {
  width: 15px;
  height: 15px;
  border: 1px solid #5f6d8b;
  box-sizing: border-box;
  border-radius: 2px;
  background-size: 9px 8px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center center;
  cursor: pointer;
  transition: 150ms;

  &.tableRadioChecked {
    background-color: #ec5418;
    border: 1px solid #ec5418;
    background-image: url($ok);
  }
}

.tableColumn {
  color: white;
}

.tableLink {
  color: white;
}

.table__open {
  border: 1px solid rgba(255, 255, 255, 0.2);

  &.opened {
    border: 1px solid rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, 0.2);
  }

  &::before {
    opacity: 0.5;
    background-image: url($arrow_top);
  }
}

.tableHide {
  border: 1px solid #4b566d;
  margin: 0 0 0 10px;

  &::before {
    opacity: 0.5;
    background-image: url($hide);
  }
}

.tableOpenReplacer {
  width: 44px;
  height: 40px;
  opacity: 0;
}

.tableRow {
  .tableButton {
    margin-right: 10px;

    &:last-of-type {
      margin-left: 0;
    }
  }
}

.tableWarningText {
  background: white;
  color: #001030;
  text-align: center;
}

.dropDownTableMediaQueries {
  @include DropDownTableMedia($mobileTableWidth, null);
}
.disputesMediaQueries {
  @include DropDownTableMedia($disputesMobileTableWidth, null);
}
