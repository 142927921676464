.headerButton {
  .articleOpenSelects {
    height: 40px;
    width: 180px;
    margin-top: 30px;
  }

  .articleOpenSelectsSmallScreen {
    display: none;
  }

  @media screen and (max-width: 767.98px) {
    .articleOpenSelectsSmallScreen {
      display: flex;
    }
  }
}
