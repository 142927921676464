.tableColumnExplanation {
  &::before {
    content: 'Explantation';
    font-family: 'BurbankSmall-Medium', sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 12px;
    color: #001030;
    opacity: 0.36;
    margin-right: 20px;
  }
}

.tableColumnLabel {
  opacity: 0.4;
  border: 1px solid #d5dae0;
  background-size: 10px 8px;

  &:last-of-type {
    margin-left: 30px;
  }

  &.tableColumnLabelChecked {
    background-color: #3c3daa;
    background-image: url('https://kombatlink-assets.azureedge.net/img/ok.svg');
    opacity: 1;
    border: none;
  }
}

.tableColumnLabelText {
  font-family: 'BurbankSmall-Medium', sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 17px;
  color: #001030;
  margin: 0 0 0 7px;
}