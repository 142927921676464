@import './Select.scss';

.selectWrapper {
  .arrow {
    background-image: url(https://kombatlink-assets.azureedge.net/img/arrow_top.svg);
  }

  .listContainer {
    background: #25324e;
  }

  .title {
    background: #25324e;
    color: rgba(255, 255, 255, 0.5);

    &.selected {
      .text {
        color: white;
      }
    }
  }

  .searchContainer {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &::after {
      background-image: url($search);
    }
  }

  .search {
    background-color: #3b4760;
    color: white;
  }

  .list {
    scrollbar-width: thin;
    scrollbar-color: darkgray #25324e;

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }

    &::-webkit-scrollbar-track {
      background-color: #25324e;
    }
  }

  .item {
    color: white;
    &:hover {
      background: rgba(255, 255, 255, 0.07);
    }
  
    .blocked {
      background-image: url("https://kombatlink-assets.azureedge.net/img/white_lock.svg");
    }
  }
}

.error {
  border: 1px solid rgb(230, 72, 70) !important;
}
