@import './utils/vars';
@import './utils/mixins';

@import './libs/tabMenu';
@import './libs/pagination';
@import './libs/modals';
@import './libs/select';

$mobileTableWidth: 1399.98px;
$compactTableWidth: 1499.98px;
@import './libs/table';

.article__header-button {
  margin-top: 30px;
  width: 157px;
  height: 40px;
}

.table__column {
  &--date {
    width: 70%;
    &::before {
      content: 'Date';
    }
  }
  &--currency {
    width: 70%;
    &::before {
      content: 'Currency';
    }
  }
  &--tournament {
    width: 200%;
    &::before {
      content: 'Tournament';
    }
  }
  &--type {
    width: 100%;
    &::before {
      content: 'Type';
    }
  }
  &--venue {
    width: 80%;
    &::before {
      content: 'Venue';
    }
  }
  &--status {
    width: 150%;
    &::before {
      content: 'Status';
    }
  }
  &--points {
    width: 80%;
    &::before {
      content: 'Points';
    }
  }
  &--points-type {
    width: 400%;
    &::before {
      content: 'Type';
    }
  }
  &--description {
    width: 300%;
    &::before {
      content: 'Type';
    }
  }
}

@media screen and (max-width: $mobileTableWidth) and (min-width: 0) {
  .table__column--tournament {
    order: -1;
    width: 100%;
  }
}

.buy {
  background: #1e2c48;
  border-radius: 4px;
  padding: 50px 55px 40px 75px;

  &__header {
    font-family: 'BurbankSmall-Bold', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: white;
    margin-bottom: 24px;
  }

  &__subheader {
    font-family: 'BurbankSmall-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: white;
    opacity: 0.7;
    margin-bottom: 12px;

    &--big {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 51px;
    }
  }

  &__input {
    display: none;
  }

  &__label-container {
    width: calc(100% / 3);
    padding: 0 10px 0 0;

    &:last-of-type {
      padding: 0 30px 0 0;
    }
  }

  &__label {
    height: 45px;
    width: 100%;
    background: #2f3d58;
    border-radius: 4px;
    text-align: center;
    font-family: 'BurbankSmall-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 45px;
    letter-spacing: 0.05em;
    color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: 150ms;
    border: 1px solid transparent;
  }

  &__input:checked ~ .buy__label {
    color: #49c3fd;
    border: 1px solid #49c3fd;
  }

  &__select {
    width: 234px;
    background: #2f3d58;
    border-radius: 4px;
    height: 45px;
  }

  &__hr {
    width: 100%;
    height: 1px;
    background: #2f3d58;
    margin: 45px 0 39px 0;
  }

  &__warning-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url('https://kombatlink-assets.azureedge.net/img/white_warning.svg');
    margin-right: 10px;
    flex-shrink: 0;
  }

  &__warning-text {
    font-family: 'BurbankSmall-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: white;
    opacity: 0.7;
  }

  &__button {
    width: 234px;
    height: 40px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 1759.98px) and (min-width: 0) {
  .buy {
    &__select-container {
      width: 100%;
    }

    &__label-container:last-of-type {
      padding: 0 10px 0 0;
    }
  }
}

@media screen and (max-width: 1599.98px) and (min-width: 0) {
  .article__info-container {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .buy {
    padding: 50px 40px 40px 40px;
  }
}

@media screen and (max-width: 991.98px) and (min-width: 0) {
  .buy__button {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767.98px) and (min-width: 576px) {
  .buy__label-container {
    width: 50%;
    margin-bottom: 10px;

    &:nth-of-type(2) {
      padding-right: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-right: 0;
    }
  }
}

@media screen and (max-width: 575.98px) and (min-width: 0) {
  .buy__label-container {
    width: 100%;
    margin-bottom: 10px;
    padding-right: 0;

    &:last-of-type {
      margin-bottom: 0;
      padding-right: 0;
    }
  }
}

.select {
  &__container {
    top: 50px;
    background: #2f3d58;
  }
  &__title {
    line-height: 45px;
    &::before {
      top: 20px;
    }
  }
}

.info {
  background: #1e2c48;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 29px 0 29px 60px;

  &__icon {
    width: 62px;
    height: 62px;
    background-color: transparent;
    background-size: 100% 100%;

    &--tournaments {
      background-image: url('https://kombatlink-assets.azureedge.net/img/tournaments.svg');
    }

    &--points {
      background-image: url('https://kombatlink-assets.azureedge.net/img/points_icon.svg');
    }

    &--bonus {
      background-image: url('https://kombatlink-assets.azureedge.net/img/bonus_icon.svg');
    }
  }

  &__header {
    font-family: 'BurbankSmall-Bold', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: white;
    margin-bottom: 15px;
  }

  &__text {
    font-family: 'BurbankSmall-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: white;
    opacity: 0.7;
  }
}

@media screen and (max-width: 439.98px) and (min-width: 0) {
  .info {
    padding: 29px 0 29px 20px;

    &__icon {
      width: 50px;
      height: 50px;
      margin-right: -10px;
    }
    &__header {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 12px;
    }

    &__text {
      font-size: 14px;
      line-height: 14px;
    }
  }
}

.window {
  width: 860px;
  padding: 50px 70px 40px 70px;
}

@media screen and (max-width: 575.98px) {
  .window {
    padding: 40px 25px 30px 25px;
  }
}

.window__slash {
  font-family: 'BurbankSmall-Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  flex-shrink: 0;
  color: #001030;
  width: 30px;
  text-align: center;
}
