@import "../../../../css/images";

.articleHeader {
  margin: 0 -45px;
  width: calc(100% + 90px);
  position: relative;
  margin-bottom: 30px !important;
}

.articleHeader__background {
  position: relative;
  height: 338px;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
  background-size: cover;
  background-position: center center;
}

.articleHeader__content {
  position: absolute;
  left: 15px;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 50px 60px;
  background: linear-gradient(180deg, rgba(30, 44, 72, 0) 20%, #041534 100%),
    linear-gradient(0deg, rgba(0, 20, 60, 0.3), rgba(0, 20, 60, 0.3));
  border-bottom: 1px solid #25324e;
  box-sizing: border-box;
}

.articleHeader__container {
  padding-bottom: 0;
  position: relative;
}

.articleHeader__header {
  font-family: "BurbankSmall-Medium", sans-serif;
  color: white;
  font-size: 36px;
  line-height: 54px;
  font-weight: bold;
  flex: 0 0 80%;
  width: 80%;
  
}
.articleHeader__headerContainer h2{
  font-family: "BurbankSmall-Medium", sans-serif;
    color: white;
    font-size: 20px;
    line-height: 100%;
    font-weight: bold;
    flex: 0 0 80%;
    width: 80%;
  // margin-bottom: 80px;
}
.articleHeader__p{
  font-family: "BurbankSmall-Medium", sans-serif;
  color: white;
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
  flex: 0 0 100%;
  width: 80%;
}

.articleHeader__platform {
  width: 110px;
  height: 30px;
  background-color: #091937;
  border-radius: 2px;
  margin: 2.5px 5px 2.5px 0;
  background-position: center center;
  background-size: auto 40%;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.articleHeader__platform__crossPlatform {
  background-image: url($cross_platform);
}
.articleHeader__platform__xbox {
  background-image: url($xbox);
}
.articleHeader__platform__ps {
  background-image: url($ps);
}
.articleHeader__platform__pc {
  background-image: url($pc);
}
.articleHeader__platform__nintendo {
  background-image: url($nintendo);
}

.articleHeader__container {
  padding-bottom: 80px;
}

.articleHeader__consolesContainer,
.articleHeader__headerContainer {
  flex: 0;
}

.articleHeader__button {
  position: relative;
  width: 171px;
  height: 50px;
  padding: 0 0 0 43px;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  font-size: 13px;
  line-height: 17px;
  font-family: "BurbankSmall-Bold", sans-serif;
  font-weight: bold;
}

.articleHeader__button span {
  font-size: 14px;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  letter-spacing: 0.05em;
}

.articleHeader__button:hover {
  text-decoration: none;
  color: white;
}

.articleHeader__button::before {
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  top: 17px;
  width: 18px;
  height: 16px;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url($heart);
  opacity: 0.6;
  transition: 150ms;
}

.articleHeader__button:hover::before {
  opacity: 0.8;
}

@media screen and (max-width: 1199.98px) {
  .articleHeader__background {
    height: 400px;
  }
  .articleHeader__container {
    padding-bottom: 30px;
  }
  .articleHeader__header {
    height: 108px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767.98px) {
  .articleHeader__consolesContainer {
    overflow: auto;
  }
}

@media screen and (max-width: 575.98px) {
  .articleHeader {
    margin: 0 -30px;
    width: calc(100% + 60px);
  }
  .articleHeader__header {
    height: 99px;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 15px;
  }
  .articleHeader__content {
    padding: 25px 20px 25px 20px;
  }
}

.articleSelect {
  width: calc(100% / 6);
}

@media screen and (max-width: 1299.98px) and (min-width: 768px) {
  .articleSelect {
    width: calc(100% / 3);
  }
}

@media screen and (max-width: 767.98px) and (min-width: 576px) {
  .articleSelect {
    width: 50%;
  }
}

@media screen and (max-width: 575.98px) and (min-width: 0) {
  .articleSelect {
    width: 100%;
  }
}



.articleHeader__tabMenuContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 40px;
  padding: 0 60px;
}

.article__selectsPopup {
  z-index: 997;
  padding-right: 15px;
  margin: 0 -30px;
}