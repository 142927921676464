.registration__join {
  font-family: Burbank-Big-Rg-Md, sans-serif;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  height: 50px;
  line-height: 14px;
  margin: auto 0;
  vertical-align: middle;
  letter-spacing: 0.05em;
  padding: 0px;
}

.registration__join__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}

.registration__join__online {
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  width: 100%;
}

.registration__join__clan {
  padding-left: 30px;
}

.registration__join__text {
  text-align: left;
  line-height: 18px;
}

.registration__membersContainer {
  border-left: 1px solid rgba(255, 255, 255, 0.1);

  .registration__members {
    font-family: Burbank-Big-Cd-Bd, sans-serif;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.05em;
    color: white;
    span {
      display: inline;
    }
  }
  .registration__membersText {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 5px;
    text-transform: none !important;
  }
}
