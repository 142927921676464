.ShoppingCardPage {
  .cart {
    text-align: right;
    padding-left: 15px;
    padding-left: 10px;
  }
  .summary__date input {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    width: 91%;
  }
  .summary__detail input {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    text-align: left;
    width: 96%;
  }

  @media only screen and (max-width: 600px) {
    .cart {
      text-align: left;
      padding-left: 15px;
    }
  }
  .cart-warrning {
    color: red;
    text-align: right;
    padding-right: 22px;
  }

  @media only screen and (max-width: 600px) {
    .cart-warrning {
      color: red;
      text-align: left;
      padding-left: 15px;
    }
  }

  .height-100 {
    height: 100vh;
  }
  .disable-cart {
    opacity: 0.5;
    pointer-events: none;
  }

  .orders__order.order.order--viewer,
  .order--participant {
    margin-bottom: 20px;
    width: 100%;
  }
  .orders__order.order.order--viewer {
    margin-bottom: 20px;
    padding: 30px 50px 30px 20px;
  }
  .order--participant {
    padding: 30px 50px 30px 20px;
  }
  .order__picture {
    width: 100% !important;
    height: 150px !important;
    margin-right: 5px !important;
    padding: 0 !important;
  }
  .order__info-header {
    margin: 0 !important;
  }
  .order__info {
    margin: 9px 0 0 !important;
  }
  .old-kl-css .order__price {
    margin: 15px 0 0 0;
  }
  .order__add,
  .order__reduce {
    margin: 15px 0 3px !important;
  }
  .order__count {
    font-size: 20px;
    line-height: 30px;
    margin: 13px 10px 0 !important;
  }
  .right-menu1 {
    background: linear-gradient(103.91deg, #012065, #5623a7);
    padding: 100px 50px 0 !important;
    width: auto !important;
    position: fixed !important;
    height: 100vh !important;
    overflow-y: scroll !important;
  }
  .summary__header,
  .payment__header {
    font-size: 36px !important;
    line-height: 48px !important;
  }

  .row-paymentDetails {
    margin: 0;
  }
  .col-paymentDetails {
    width: 100%;
  }
  .summary__detail input,
  .custom_input_payment {
    outline: 0;
    box-shadow: none !important;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #6a64a9 !important;
    border-radius: 0;
    font-size: 16px !important;
    padding: 0;
  }
  .summary__date input {
    // box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    width: 100%;
    box-shadow: none !important;
  }
  a:not([href]):not([tabindex]) {
    color: white !important;
  }
  .cart-section.right-menu1 {
    background: linear-gradient(103.91deg, #012065, #5623a7);
    padding: 100px 50px 0 !important;
    width: 100% !important;
    position: absolute !important;
    height: 100vh !important;
    overflow-y: scroll !important;
  }

  .grey-button--filled {
    background: hsla(0, 0%, 100%, 0.2) !important;
  }
  .summary,
  .payment {
    transition: 0.35s !important;
  }
  .article__content::-webkit-scrollbar {
    width: 0 !important;
  }
  .article__content {
    overflow: -moz-scrollbars-none;
  }
  .article__content {
    -ms-overflow-style: none;
  }
  .order__info-text {
    font-size: 13px !important;
  }

  .cart-section {
    scrollbar-color: rgba(255, 255, 255, 0.445) #051534;
    scrollbar-width: thin;
  }
  @media (max-width: 1700px) {
    article.row.cart-article-row {
      flex-direction: column;
    }
    .cart-article-col-left {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .cart-article-col-right {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .right-menu1 {
      background: linear-gradient(103.91deg, #012065, #5623a7);
      padding: 100px 50px 0 !important;
      width: 100% !important;
      position: static !important;
      height: 100vh !important;
      overflow-y: scroll !important;
      z-index: 999999;
      margin-right: 0 !important;
      top: 0;
    }
    .article__summary.summary {
      width: 100% !important;
      max-width: 620px !important;
    }
    .article__payment.payment.payment--next {
      position: static !important;
      padding: 0 !important;
      max-width: 620px !important;
      margin: 0 auto !important;
    }
  }
}
