.left {
  flex: 0 0 22%;
  border-right: 1px solid #25324e;
  padding-right: 30px;
}

.avatar {
  width: 100%;
  border-radius: 50%;
}

.leftTile {
  font-family: BurbankSmall-Bold, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: white;
}

.greenBorder {
  border-color: #53D600 !important;
  border-bottom: 4px solid;
}

.stats {
  .purple {
    border-color: #7800FF !important;
    border-bottom: 4px solid;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0 0;
    width: 100%;
    display: block;
    float: left;

    li {
      width: 100%;
      display: block;
      float: left;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      padding: 12px 0;

      .statTitle {
        float: left;
        display: inline-block;
        font-size: 14px;
        color: #fff;
        letter-spacing: -0.2px;
        padding-right: 10px;
      }

      .data {
        float: left;
        display: inline-block;
        font-size: 14px;
        color: #53D600;
        font-weight: 700;
        letter-spacing: -0.2px;
      }

      .bar {
        width: 100%;
        display: block;
        float: left;
        border-radius: 100px;
        height: 6px;
        background-color: rgba(255, 255, 255, 0.06);
        overflow: hidden;
        margin-top: 7px;
        margin-bottom: 7px;

        span {
          &.ratio {
            height: 6px;
            background-image: linear-gradient(226deg, #02B9FF 0%, #0388FD 100%);
            display: inline-block;
            float: left;
            border-radius: 100px;
          }
        }
      }
    }
  }
}