.old-kl-css {
  #cookie-policy .window {
    width: 640px;
    height: auto;
    padding: 50px 70px 35px 50px;
  }

  .window__cookie-header {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 28px;
    color: #001030;
    margin-bottom: 35px;
  }

  .window__cookie-text {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 167%;
    color: rgba(0, 16, 48, 0.7);
    margin-bottom: 35px;
  }

  .window__cookie-button {
    width: 205px;
    height: 40px;
    margin: 5px;
  }
}
