.articleSelectInputDateContainer {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    right: 16px;
    width: 17px;
    height: 15px;
    background-size: 100% 100%;
    z-index: 1;
    color: transparent;
    cursor: pointer;
  }
}

.articleSelectSelect {
  position: relative;
  height: 40px;
  border-radius: 4px;
  margin: 0 0 20px 0;
}

.articleSelectInputDate {
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 0 30px 0 15px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.05em;
  z-index: 2;

  &::-webkit-calendar-picker-indicator {
    display: block;
    position: absolute;
    top: 12px;
    right: 13px;
    width: 12px;
    height: 10px;
    z-index: 10;
    cursor: pointer;
    opacity: 0;
  }

  &::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }
}
