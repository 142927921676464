@import '../../../../../../../css/images';

.chatMenu {
  position: absolute;
  right: 15px;
  top: 27px;
  background: white;
  width: 113px;
  border: 1px solid #e2f0ff;
  box-shadow: 0 1px 10px rgba(159, 159, 159, 0.07);
  border-radius: 4px;
  display: none;
}

.chatHeaderMenuContainer {
  padding: 0 10px 0 5px;
  display: flex;
  position: relative;
}

.chatHeaderCheckbox {
  display: none;

  &:checked ~ .chatMenu {
    display: block;
  }
}

.chatHeaderMenuButton {
  display: block;
  width: 14px;
  height: 20px;
  background-color: transparent;
  cursor: pointer;
  background-size: 4px 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url($chat_menu);
}