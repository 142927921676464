.chatInput {
  width: 270px;
  height: 36px;
  background: #dee8f2;
  border-radius: 4px;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #001030;
  border: none;
  outline: none;
  padding: 0 0 0 16px;
}