$mobileTableWidth: 1199.98px;

.articleTabMenuBeforeContainer {
  margin-bottom: 30px;
}

.articleSelect {
  width: calc(100% / 6);
}

@media screen and (max-width: 1199.98px) and (min-width: 767.98px) {
  .articleSelect {
    width: calc(100% / 3);
  }
}

@media screen and (max-width: 767.98px) and (min-width: 576px) {
  .articleSelect {
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 575.98px) and (min-width: 0) {
  .articleSelect {
    width: 100%;
  }
}

@media screen and (max-width: 767.98px) {
  .articleSelectsPopup {
    padding-bottom: 130px !important;
  }
}

.tableColumnTournament {
  width: 240%;

  &::before {
    content: "Tournament";
  }
}

.tableColumnUser {
  width: 240%;

  &::before {
    content: "Opponent";
    margin-right: 40px;
  }
}

.tableColumnDate {
  width: 110%;

  &::before {
    content: "Date";
  }
}

.tableColumnButton {
  width: 110%;
}

.tableColumnTime {
  width: 120%;

  &::before {
    content: "Time";
  }
}

.tableColumnEventId {
  width: 100%;

  &::before {
    content: "Event ID";
  }
}

.tableColumnButtons {
  width: 600px;
  flex-shrink: 0;
}

.tableSecondaryRow {
  .tableColumn {
    &::before {
      display: none !important;
    }
  }

  .tableColumnUser {
    &::before {
      display: inline-block !important;
    }
  }
}

.tableProfilePicture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.eventsDropDownTableMediaQueries {
  @media screen and (max-width: 1869.98px) {
    .tableColumnButtons {
      width: 384px;
    }
  }

  @media screen and (max-width: $mobileTableWidth) and (min-width: 0) {
    .tableColumnTournament {
      width: 100%;
      order: -1;
    }
    .tableColumnButtons {
      width: 100%;
      order: 3;
    }
    .tableSecondaryRowContainer {
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }

  @media screen and (max-width: 469.98px) {
    .tableButton {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;

      &:first-of-type {
        margin-top: 0;
      }
    }
    .tableOpen {
      margin: 10px 0 0 0;
    }
    .tableColumnUser::before {
      width: 100%;
      margin-bottom: 5px;
      margin-right: 0;
    }
  }
}

.clearFilters {
  color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-top: 10px;
  align-items: center;
  font-size: 20px;
  width: 100%;
  .clearFiltersText {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}