.card {
  background: #1e2c48;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
}

.label {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-size: 12px;
  opacity: 0.7;
  color: white;
  text-transform: uppercase;
}

.value {
  margin-left: 10px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: white;
  word-break: break-all;
}