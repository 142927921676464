


.ticket__scan__container {
  max-width: 450px;
  margin: 0 50px;
}
.ticket__scan__wrapper {
  background-color: white;
  max-width: 340px;
  border-bottom: 12px solid purple;
  padding-left: 30px;
  margin-bottom: 50px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  .ticket__scan {
    border-left: 2px dashed  black;
    padding-left: 15px;
    padding-top: 25px;
    padding-bottom: 15px;

    .qrCode {
      text-align: center;
    }
    .ticket_id {
      color: black;
      font-size: large;
      text-align: center;
      font-weight: bold;
    }
    .ticket_price {
      color: purple;
      font-size: large;
      text-align: center;
      font-weight: bold;
    }
  }
}


.validation__valid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  width: 100%;
  height: 50px;
  outline: none;
  text-decoration: none;
  font-family: 'Burbank-Big-Cd-Bd', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  background: transparent;
  color: white;
  background-color: #6cd000;
  padding: 0 20px;
}


.validation__invalid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  width: 100%;
  height: 50px;
  outline: none;
  text-decoration: none;
  font-family: 'Burbank-Big-Cd-Bd', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  background: transparent;
  color: white;
  background-color: #e13b4c;
  padding: 0 20px;
}

.validation__invalid__detail {
  color: black;
  background-color: white;
  padding: 20px;
}



.ticket__detail {
  color: black;
  background-color: white;
  text-align: center;
  width: 450px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .ticket_cover {
    height: 180px;
    width: 450px;
  }
  .ticket_tournamentName {
    padding: 15px 25px;
    font-weight: bold;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    border-top: 2px dashed  black;
    border-bottom: 12px solid purple;    
    padding: 15px;

    .user_name {
      font-weight: bold;
    }
    .ticket_id {
      opacity: 0.5;
      font-weight: bold;
    }   
  }
}

.highlight_invalid_control {
  border: 1px solid rgb(230, 72, 70) !important;
}