@import "../utils/vars";
@import "../utils/mixins";

$compactTableWidth: null !default;
$mobileTableWidth: 1199.98px !default;

.table {
  &__headers-row {
    height: 66px;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__row {
    margin: 0 -15px 15px -15px;
  }

  &__secondary-row-container {
    max-height: 0;
    height: auto;
    overflow: hidden;
    transform: translate(0, -50%) scaleY(0);
    transition: 300ms;
    background: rgba(255, 255, 255, 0.1);
    z-index: 1;
    padding: 0;
  }

  &__secondary-row,
  &__main-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  &__main-row {
    position: relative;
    height: 70px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 2;
    transition: 300ms;
    border-bottom: 1px solid rgba(255, 255, 255, 0);

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  &__secondary-row {
    height: 50px;
  }

  &__checkbox {
    display: none;
  }

  &__checkbox-hide {
    display: none;
  }

  .article__column-label:last-of-type {
    margin-left: 30px;
  }

  &__column {
    color: white;
    font-size: 16px;
    line-height: 23px;
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    letter-spacing: 0.05em;
    padding: 0 20px;
    width: 100%;

    &::before {
      display: none;
      opacity: 0.5;
      margin-right: 20px;
    }

    .table__secondary-row & {
      font-size: 15px;
    }

    .table__secondary-row &::before {
      display: inline-block;
    }
  }

  &__link {
    text-decoration: none;
    color: white;
    transition: 150ms;

    &:hover {
      text-decoration: none;
      color: #49c3fd;
    }
  }

  &__open {
    display: block;
    position: relative;
    width: 44px;
    height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin: 0 0 0 10px;
  }

  &__open::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.5;
    background-size: 12px 7px;
    background-image: url("https://kombatlink-assets.azureedge.net/img/arrow_top.svg");
    transform: rotate(180deg);
    transition: 200ms;
  }

  &__hide {
    display: block;
    position: relative;
    width: 44px;
    height: 40px;
    border: 1px solid #4b566d;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin: 0 0 0 10px;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      opacity: 0.5;
      background-size: 20px 20px;
      background-image: url("https://kombatlink-assets.azureedge.net/img/hide.svg");
    }
  }

  &__open-replacer {
    width: 44px;
    height: 40px;
    opacity: 0;
  }

  &__button {
    width: 140px;
    height: 40px;
    margin-left: 10px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  &__label {
    display: inline-block;
    width: 84px;
    height: 30px;
    border-radius: 2px;
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;

    &--grey {
      background: #f3f7fb;
      color: #c3cedb;
    }

    &--green {
      background: rgba(108, 208, 0, 0.2);
      color: #6cd000;
    }

    &--red {
      background: rgba(255, 59, 76, 0.1);
      color: #e13b4c;
    }

    &--orange {
      background: rgba(242, 104, 49, 0.1);
      color: #f26831;
    }
  }

  &__radio {
    width: 15px;
    height: 15px;
    border: 1px solid #5f6d8b;
    box-sizing: border-box;
    border-radius: 2px;
    background-size: 9px 8px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    cursor: pointer;
    transition: 150ms;
    margin-right: 13px;
    margin-bottom: 1px;
  }

  &__warning {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 15px;
    top: 3px;
    margin-left: 15px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url("https://kombatlink-assets.azureedge.net/img/red_warning.svg");

    &-text {
      height: auto;
      padding: 10px 9px;
      background: white;
      color: #001030;
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      width: 236px;
      text-align: center;
    }

    &-content {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translate(-50%, 0);
      background: transparent;
      display: none;
      padding-bottom: 10px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 2px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top: 3px solid white;
      }
    }

    &:hover &-content {
      display: block;
    }
  }

  &__checkbox:checked ~ &__main-row &__open::before {
    transform: rotate(360deg);
  }

  &__checkbox:checked ~ &__main-row &__open {
    border: 1px solid rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, 0.2);
  }

  &__checkbox:checked ~ &__main-row {
    background: rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid #303d55;
  }

  &__checkbox-hide:checked + .row &__hide {
    background-color: #ec5418;
    border: 1px solid #ec5418;

    &::before {
      background-size: 20px 12px;
      opacity: 1;
      background-image: url("https://kombatlink-assets.azureedge.net/img/visible.svg");
    }
  }

  &__checkbox-hide:checked + .row {
    display: none;
  }

  #show-hidden:checked ~ &__checkbox-hide:checked + .row {
    display: flex;
  }

  &__checkbox:checked ~ &__secondary-row-container {
    background: rgba(255, 255, 255, 0.2);
    transform: translate(0) scaleY(1);
    max-height: 500px;
    padding: 15px 0;
  }

  @media screen and (max-width: 1299.98px) {
    &__checkbox:checked ~ &__secondary-row-container {
      max-height: 1600px;
    }
  }
  @media screen and (max-width: 767.98px) {
    &__checkbox:checked ~ &__secondary-row-container {
      max-height: 1800px;
    }
  }
}

#show-hidden:checked ~ .table__headers-row .table__radio {
  background-color: #ec5418;
  border: 1px solid #ec5418;
  background-image: url("https://kombatlink-assets.azureedge.net/img/ok.svg");
}

@if $compactTableWidth != null {
  @media screen and (max-width: $compactTableWidth) and (min-width: $mobileTableWidth) {
    .table__column {
      padding: 0 10px;
    }
    .table__row {
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: $mobileTableWidth) {
  .table__headers-row {
    display: none;
  }
  .table__main-row {
    flex-wrap: wrap;
    height: auto;
    padding: 20px;
  }
  .table__secondary-row {
    flex-wrap: wrap;
    height: auto;
  }
  .table__row .table__column::before {
    display: inline-block;
  }
  .table__open-replacer {
    display: none;
  }
}

@media screen and (max-width: $mobileTableWidth) and (min-width: 768px) {
  .table__column {
    width: 50%;
    padding: 3px 0;
  }
}

@media screen and (max-width: 767.98px) and (min-width: 576px) {
  .table__column {
    width: 100%;
    padding: 3px 0;
  }
}

@media screen and (max-width: 575.98px) and (min-width: 0) {
  .table__column {
    width: 100%;
    padding: 3px 0;
  }
  .table__secondary-row .table__column {
    font-size: 14px;
  }
  .table__warning {
    &-content {
      transform: translate(-80%, 0);

      &::before {
        left: 80%;
      }
    }

    &:hover &-content {
      display: block;
    }
  }
}
