.article__warningIcon {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: none;
    background: rgba(255, 255, 255, 0.2);
    opacity: 1;
    transition: 150ms;
    margin-bottom: 30px;
    margin-top: 20px;
    margin-right: 10px;
    flex-shrink: 0;
  }
  
  .article__warningIcon::before {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 2px;
    top: 3px;
    left: 7px;
    background: white;
  }
  .article__warningIcon::after {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 6px;
    top: 7px;
    left: 7px;
    background: white;
  }
  
  .article__warningText {
    line-height: 22px;
    margin-top: 20px;
    max-width: 710px;
  }
  
  .ct__article__warningIcon{
    background-color: rgb(178,184,194) !important;
  }
  
  .ct__article__subheader {
    display: flex;
    flex-direction: row;
  }
  
  .ct__article__subheader{
    opacity: 1 !important;
  }
  
  .article__subheader {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.7);
    margin: 29px 0 11px 0;
  }
  
  .article__width_0 {
    width: 116px;
    margin-right: 6px;
    margin-top: 21px;
  }
  
  .article__width_1 {
    width: 172px;
  }
  
  .article__width_2 {
    width: 360px;
    margin-right: 15px !important;
  }
  
  .zIndex_1 {
    z-index: 1;
  }
  
  .zIndex_2 {
    z-index: 2;
  }
  
  .article__height_1 {
    height: 55px !important;
  }
  
  .article__height_2 {
    height: 88px;
  }
  
  .article__height_3 {
    height: 200px;
    margin-bottom: 15px !important;
  }
  
  .article__input {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    height: 100%;
  }
  
  svg.clansSvg  {
    width: 41px;
    height: 25px;
  }
  
  .article__buttonsContainer {
    margin: 25px 0 40px 0;
    padding-top: 35px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .article__nextStep, .article__saveInDraft {
    width: 140px;
    height: 40px;
    margin: 0;
  }
  
  .article__saveInDraft {
    margin-right: 10px!important;
  }
  
  @media screen and (max-width: 575.98px) and (max-width: 575.98px) {
    .article__nextStep, .article__saveInDraft {
        margin-left: 5px!important;
        width: calc(100% - 5px)!important;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
  }
  
  @media screen and (max-width: 575.98px) {
    .article__width_1 {
      width: 132px!important;
    }
  }
  
  @media screen and (max-width: 899.98px) and (min-width: 576px) {
    .article__width_2 {
      width: calc(50% - 30px);
      margin-right: 30px!important;
    }
  }
  
  @media screen and (max-width: 575.98px) {
    .article__width_2 {
      width: 100%;
      margin-right: 30px!important;
    }
  }
  
  .errorMessage {
    margin: -15px 0 0 0;
  }