.ticket-row-wrapper .col-12:before
{
  display:none !important;
}

.ticket-row-wrapper .table__row,
.ticket-row-wrapper .table__headers-row 
{
  width: 100%;
}

.old-kl-css div#tickets-filter {
  z-index: 9;
}

.my-ticket-table__column--id {
  padding-left: 10px !important;
}

@media(max-width: 1200px)
{
  .ticket-row-wrapper .table__row  
  {
    width: 100%;
    flex-direction: column !important;
    justify-content: flex-start !important;
  }
  .ticket-row-wrapper .table__headers-row{
    display: none !important;
  }
  .ticket-row-wrapper .col-12:before
  {
    display: inline-block !important;
    margin-left: 0;
    width: auto;
    margin-right: 5px;
    margin-bottom: 6px;
    font-weight: 100;
    opacity: 0.7 !important;
  }
  .ticket-row-wrapper .table__row .col-12.col-sm-6.col-md-6.col-lg-2.col-xl-2,
  .ticket-row-wrapper .table__row .col-12.col-sm-6.col-md-6.col-12.col-sm-6.col-md-6.col-lg-1.col-xl-1 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
    opacity: 1 !important;
  }

  .my-ticket-table__column--button {
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  .my-ticket-table__column--button button {
    width: 20% !important;
    margin-top: 10px;
  }

  .ticket-row-wrapper {
    padding: 0;
  }
  .my-ticket-table__column--id {
    padding-left: 0px !important;
  }
}

@media(max-width: 767px)
{
  .my-ticket-table__column--button button {
    width: 35% !important;
    margin-top: 10px;
  }  

  .ticket-row-wrapper .article__selects-popup {
    padding: 30px 30px 0 60px !important;
  }
  
  .ticket-row-wrapper #tickets-filter.article__selects-popup--opened
  {
    top: 60px !important;
  }
}

@media(max-width: 480px)
{
  .my-ticket-table__column--button button {
    width: 49% !important;
    margin-top: 10px;
  }

  .ticket-row-wrapper .search {
    position: relative;
    background: #25324e;
    border-radius: 4px;
    margin-bottom: 20px !important;
  }
}
