@import './Pagination.module';

.articlePageNavigation {
  color: #001030;

  &:hover {
    color: #001030;
  }
}

.articlePage,
.articlePageDots {
  color: #001030;
}

.articlePage:hover {
  color: #001030;
}

