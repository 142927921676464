@import '../../../../../css/images';

.chatPersonContainer {
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #e2f0ff;
  position: relative;
}

.chatPersonLabel {
  width: 15px;
  height: 15px;
  border: 1px solid #cedae5;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  background-color: transparent;
  background-size: 10px 8px;
  background-position: center center;
  background-repeat: no-repeat;
}

.chatPersonCheckbox {
  display: none;

  &:checked ~ .chatPersonLabel {
    background-color: #1f9cfd;
    background-image: url($ok);
    border: 0 solid #cedae5;
  }
}

.chatPersonContainerCreateChat {
  border-bottom: 1px solid #e0e8f1;

  .chatPersonName {
    margin-bottom: 0;
    color: #001030;
  }
}
