.avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.name {
  font-family: BurbankSmall-Bold, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: white;

  &:hover {
    text-decoration: none;
    color: #49c3fd;
  }
}

.detail{
  font-family: "BurbankSmall-Medium", sans-serif;
  color: white;
  font-size: 13px;
  border: 1px solid rgba(255, 255, 255, 0.17);

  &:hover {
    text-decoration: none;
    color: #49c3fd;
  }
}

.card {
  display: flex;
  background: #1e2c48;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
}

.fixedSize {
  max-width: 279px;
}

.checkBoxContainer {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.checkBoxLabel {
  width: 14px;
  height: 15px;
  opacity: 0.4;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: transparent;
  background-size: 10px 8px;
  background-position: center center;
  background-repeat: no-repeat;
  transition: 200ms;
  cursor: pointer;
}

.checkBox {
  display: none;
}

.checkBox:checked + .checkBoxLabel {
  background-color: #6cd000;
  border: 1px solid #6cd000;
  background-image: url("https://kombatlink-assets.azureedge.net/img/ok.svg");
  opacity: 1;
}