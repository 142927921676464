@import '../../../../../../css/images';

.memberContainer {
  width: calc(100% / 4);

  &:last-of-type {
    margin-bottom: 30px;
  }
}

.member {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px 20px 0 20px;
  margin: 35px 0 5px 0;
}

.memberName {
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: white;
}

.memberClan {
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: #49c3fd;
}

.memberHeader {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.5);
  margin: 22px 0 8px 0;
}

.memberInfo {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 17px;
  color: white;
}

.memberFirstColumn {
  width: calc(100% / 12 * 3.9);

  &:first-of-type {
    width: 80px;
  }
}

.memberSecondThirdColumn {
  width: calc(100% / 12 * 8.1);

  &:first-of-type {
    width: calc(100% - 80px);
  }
}

.memberSecondColumn {
  width: calc(100% / 12 * 3.9);
}

.memberThirdColumn {
  width: calc(100% / 12 * 4.2);
}

.memberPicture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.memberButtons {
  margin: 20px -5px 0 -5px;
  padding: 15px 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: calc(100% + 10px);
  max-width: calc(100% + 10px);
  flex: 0 calc(100% + 10px);
}

.memberButton {
  width: 140px;
  height: 40px;

  &.left {
    margin-right: 8px;
  }
}

.memberFounder {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 12px;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url($crown);
  cursor: pointer;
  margin-right: 7px;
}

.memberFounderText {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: 128px;
  height: 29px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 29px;
  text-align: center;
  color: #49c3fd;
  transform: translate(-50%, 0);
  background: white;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -12px;
    left: 50%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top: 6px solid white;
    transform: translate(-50%, 0);
  }
}

.memberCheckbox {
  display: none;

  &:checked ~ .memberFounder {
    .memberFounderText {
      display: block;
    }
  }
}

@media screen and (max-width: 1419.98px) {
  .memberContainer {
    width: calc(100% / 3);
  }

  .memberFirstColumn {
    width: calc(100% / 12 * 3.9);
  }
}

@media screen and (max-width: 1049.98px) {
  .memberContainer {
    width: 50%;
  }

  .memberFirstColumn {
    width: calc(100% / 12 * 3.9);
  }
}

@media screen and (max-width: 699.98px) {
  .memberContainer {
    width: 100%;
  }
}

@media screen and (max-width: 1899.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 4);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 8);
  }
}

@media screen and (max-width: 1862.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 4.2);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7.8);
  }
}

@media screen and (max-width: 1799.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 4.5);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7.5);
  }
}

@media screen and (max-width: 1719.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 4.7);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7.3);
  }
}

@media screen and (max-width: 1419.98px) {
  .memberSecondColumn {
    width: calc(100% / 12 * 3.9);
  }
  .memberThirdColumn {
    width: calc(100% / 12 * 4.2);
  }
  .memberSecondThirdColumn {
    width: calc(100% / 12 * 8.1);
  }
}

@media screen and (max-width: 1269.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 4.2);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7.8);
  }
}

@media screen and (max-width: 1199.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 4.5);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7.5);
  }
}

@media screen and (max-width: 1139.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 4.8);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7.2);
  }
}

@media screen and (max-width: 1079.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 5);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7);
  }
}

@media screen and (max-width: 1049.98px) {
  .memberSecondColumn {
    width: calc(100% / 12 * 3.9);
  }
  .memberThirdColumn {
    width: calc(100% / 12 * 4.2);
  }
  .memberSecondThirdColumn {
    width: calc(100% / 12 * 8.1);
  }
}

@media screen and (max-width: 849.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 4.2);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7.8);
  }
}

@media screen and (max-width: 829.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 4.5);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7.5);
  }
}

@media screen and (max-width: 759.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 5);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7);
  }
}

@media screen and (max-width: 699.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 3.9);
  }
  .memberSecondColumn {
    width: calc(100% / 12 * 3.9);
  }
  .memberThirdColumn {
    width: calc(100% / 12 * 4.2);
  }
  .memberSecondThirdColumn {
    width: calc(100% / 12 * 8.1);
  }
}

@media screen and (max-width: 429.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 4.2);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7.8);
  }
}

@media screen and (max-width: 399.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 4.5);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7.5);
  }
}

@media screen and (max-width: 379.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 5);
  }
  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 7);
  }
}

@media screen and (max-width: 349.98px) {
  .memberFirstColumn {
    padding: 0 7px;
  }

  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    padding: 0 7px;
  }

  .member {
    padding-left: 28px;
    padding-right: 28px;
  }

  .memberName {
    margin-left: -8px;
  }

  .memberClan {
    margin-left: -8px;
  }

  .memberButtons {
    margin: 20px -13px 0 -13px;
    padding: 15px 33px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    width: calc(100% + 26px);
    max-width: calc(100% + 26px);
    flex: 0 calc(100% + 26px);
  }
}

@media screen and (max-width: 329.98px) {
  .memberFirstColumn {
    width: calc(100% / 12 * 5.2);
  }

  .memberSecondThirdColumn,
  .memberSecondColumn,
  .memberThirdColumn {
    width: calc(100% / 12 * 6.8);
  }

  .memberName {
    font-size: 15px;
  }
}
