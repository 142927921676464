.previewText {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: white;

  &.light {
    color: #001030;
  }
}