@import "../../../../css/utils/vars.scss";
@import "../../../../css/images";

.tableColumn {
  font-size: 16px;
  line-height: 23px;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  letter-spacing: 0.05em;
  padding: 0 20px;
  width: 100%;

  &::before {
    display: none;
    opacity: 0.5;
    margin-right: 20px;
  }
}

.tableHeadersRow {
  height: 66px;
  flex-wrap: nowrap;
  align-items: center;
}

.tableHide {
  display: block;
  position: relative;
  width: 44px;
  height: 40px;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  outline: none;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px 20px;
  }

  &.hidden {
    background-color: #ec5418;
    border: 1px solid #ec5418;

    &::before {
      background-size: 20px 12px;
      opacity: 1;
      background-image: url("https://kombatlink-assets.azureedge.net/img/visible.svg");
    }
  }
}

.tableLink {
  text-decoration: none;
  transition: 150ms;

  &:hover {
    text-decoration: none;
    color: #49c3fd;
  }
}

.table__open {
  display: block;
  position: relative;
  width: 44px;
  height: 40px;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  outline: none;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 12px 7px;
    transform: rotate(180deg);
    transition: 200ms;
  }

  &.opened {
    &::before {
      transform: rotate(360deg);
    }
  }
}

.tableRow {
  margin: 0 -15px 15px -15px;

  .tableButton {
    width: 140px;
    height: 40px;
  }
}

.tableSecondaryRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
}

.tableSecondaryRowColumn {
  font-size: 15px;

  &::before {
    display: inline-block;
  }
}

.tableSecondaryRowContainer {
  max-height: 0;
  height: auto;
  overflow: auto;
  transform: translate(0, -50%) scaleY(0);
  transition: 300ms;
  z-index: 1;
  padding: 0;
}

.tableWarning {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  top: 3px;
  margin-left: 15px;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url("https://kombatlink-assets.azureedge.net/img/red_warning.svg");

  &:hover {
    .tableWarningContent {
      display: block;
    }
  }
}

.tableWarningContent {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0);
  background: transparent;
  display: none;
  padding-bottom: 10px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top: 3px solid white;
  }
}

.tableWarningText {
  height: auto;
  padding: 10px 9px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  width: 236px;
}
