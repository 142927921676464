.uploadPopup {
  &.window {
    border: 1px solid #49c3fdd4;
    background-color: #051534;
    padding: 25px 30px;
    font-style: normal;
    color: rgba(255, 255, 255, 0.7);
  }

  .hr {
    background-color: #40a7dd;
  }

  .container {
    font-size: 15px;
  }

  .fileName {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 15px;
    white-space: nowrap;
  }
}
