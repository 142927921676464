.window {
  width: 640px;
  height: auto;
  padding: 50px 70px 35px 50px;
}

.windowCookieHeader {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
  color: #FFF;
  margin-bottom: 35px;
}

.windowCookieText {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 167%;
  color: #FFF;
  margin-bottom: 35px;
}

.windowCookieButton {
  width: 205px;
  height: 40px;
  margin: 5px;
}

.windowCookieFooter{
  background-color: #1e2c48;
  z-index: 99999;
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  padding: 10px;
  padding-left: 150px;
  .windowCookieHeader{
    margin-bottom: 5px;
  }
  .windowCookieText{
    margin-bottom: 5px;
  }
}

@media(max-width: 1300px){
  .windowCookieFooter {
      padding-left: 15px;
  }
}

@media(max-width: 767px){
  .windowCookieText,.windowCookieButton{
    max-width: 100%;
    flex: 100%;
  }
  .windowCookieButton {
    width: auto;
    max-width: 30%;
}
}

@media(max-width: 374px){
  
  .windowCookieButton {
    width: auto;
    max-width: 40%;
}
}