.article__tab_menu_before_container {
  margin: 40px 0;
  border-bottom: 1px solid #25324e;
  @media screen and (max-width: 1299.98px) {
    border-bottom: none;
  }
}

.article__tab_menu_container {

  @media screen and (max-width: 1299.98px) {
    width: 100%;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 575.98px) {
    margin: 20px 0 30px;
    padding: 0 15px !important;
  }
}

.article__header {
  font-family: Burbank-Big-Cd-Bd, sans-serif;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: .05em;
  margin: 32px 0 0 !important;
  font-style: normal;
  font-weight: 700;
  color: #fff;
}

.tab_menu__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  width: 180px;
  height: 70px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  font-family: Burbank-Big-Rg-Md, sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  letter-spacing: .05em;
  text-decoration: none;
  color: hsla(0, 0%, 100%, .5);
  background: #1e2c48;
  margin-right: 10px;
  -webkit-transition: .15s;
  -o-transition: .15s;
  transition: .15s;

  @media screen and (max-width: 767.98px) {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 150px;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    margin-top: 7px;
  }

  &:hover {
    color: hsla(0, 0%, 100%, 1);
    text-decoration: none;
    background-color: #1e2c48 !important;
  }
}

.tab_menu__link__active {
  background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
  background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
  background: linear-gradient(
                  90deg, #02b7fe, #0389fd);
  color: #fff;
}

.table__headers_row_all_transaction {
  display: flex;
  height: 66px;
  width: 100%;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.table__radio {
  width: 15px;
  height: 15px;
  border: 1px solid #5f6d8b;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  background-size: 9px 8px;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
  -webkit-transition: .15s;
  -o-transition: .15s;
  transition: .15s;
  margin-right: 13px;
  margin-bottom: 1px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0);
}
.withdraw__payment__method__label__active{
  background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd)) !important;
  background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%) !important;
  background: linear-gradient(
                  90deg, #02b7fe, #0389fd) !important;
  color: #fff !important;
}

.table__column {
  color: #fff;
  font-size: 16px;
  line-height: 23px;
  font-family: Burbank-Big-Rg-Md, sans-serif;
  letter-spacing: .05em;
  padding: 0 20px;
  width: 100%;
  display: inline-table;

  &:before {
    display: none;
    opacity: .5;
    margin-right: 20px;
  }
  

  @media screen and (max-width: 1429.98px) {
    padding: 3px 0;
  }
  @media screen and (max-width: 991.98px) {
    width: 100% !important;
  }

  @media screen and (max-width: 575.98px) and (min-width: 0) {
    width: 100%;
    padding: 3px 0;
  }
}

.table__column__type {
  width: 300px;

  &:before {
    content: "Type";
  }
}

.table__column__id {
  width: 102px;

  &:before {
    content: "Event ID";
  }
}

.table__column__name {
  width: 300px;

  @media screen and (max-width: 991.98px) {
    width: 100%;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  &:before {
    content: "Event Name";
  }
}

.table__column__price {
  width: 85px;

  &:before {
    content: "Price";
  }
}

.table__column__sales {
  width: 95px;

  &:before {
    content: "Sales";
  }
}
.table__column__ticketname {
  width: 95px;

  &:before {
    content: "Ticket";
  }
}

.table__column__refunded {
  width: 115px;

  &:before {
    content: "Refunded";
  }
}

.table__column__amount {
  width: 115px;

  &:before {
    content: "Amount";
  }
}

.table__column__fees {
  width: 150px;

  &:before {
    content: "Service fees";
  }
}

.table__column__balance {
  width: 110px;

  &.table__column--balance:before {
    content: "Balance";
  }
}

.table__column__venue {
  width: 95px;

  &:before {
    content: "Venue";
  }
}

.table__column__date {
  width: 200px;

  &:before {
    content: "Event Date";
  }
}

.table__column__sale__date {
  width: 140px;

  &:before {
    content: "Sales Date";
  }
}

.table__column__status {
  width: 160px;
  flex-shrink: 0;

  &:before {
    content: "Status";
  }
}

.table_button {
  @media screen and (max-width: 575.98px) {
    width: 100% !important;
    margin-right: 0 !important;
    height: 35px !important;
  }
}

.table__column__buttons {
  @media screen and (max-width: 991.98px) {
    padding: 3px 0 !important;
  }
}

.table__column__button {
  @media screen and (max-width: 991.98px) {
    padding: 0 !important;
  }
}

.table__row {
  margin: 0 0 15px;
  width: 100%;

  @media screen and (max-width: 1429.98px) {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    height: auto;
    padding: 15px 20px;
  }

  @media screen and (max-width: 399.98px) and (min-width: 0) {
    padding: 12px 16px;
  }
}

.available__table__row {
  margin: 0 0 15px;
  width: 100%;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 70px;
  background: hsla(0, 0%, 100%, .1);
  -webkit-transition: .15s;
  -o-transition: .15s;
  transition: .15s;

  @media screen and (max-width: 1429.98px) {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    height: auto;
    padding: 15px 20px;
  }

  @media screen and (max-width: 399.98px) and (min-width: 0) {
    padding: 12px 16px;
  }
}

.table__main_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  height: 70px;
  background: hsla(0, 0%, 100%, .1);
  z-index: 2;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0);

  &:hover {
    background: hsla(0, 0%, 100%, .2);
    border-bottom: 1px solid #303d55;
  }

  @media screen and (max-width: 1409.98px) {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: auto;
    padding: 20px;
  }
}

.table__secondary_row_container {
  background: hsla(0, 0%, 100%, .2) !important;
}

.table_checkbox:checked + .table__main_row {
  background: hsla(0, 0%, 100%, .2);
  border-bottom: 1px solid #303d55;
}

.table_checkbox:checked + .table__main_row .table_open {
  border: 1px solid hsla(0, 0%, 100%, 0);
  background-color: hsla(0, 0%, 100%, .2);
}

.table_checkbox:checked + .table__main_row .table_open:before {
  -webkit-transform: rotate(1turn);
  -ms-transform: rotate(1turn);
  transform: rotate(1turn);
}

.table__secondary_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: 50px;

  @media screen and (max-width: 1409.98px) {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: auto;
  }
}

.table__secondary_row .table__column {
  font-size: 15px;
}

.table__secondary_row .table__column__type:before {
  display: inline-block;
}

@media screen and (max-width: 1429.98px) {
  .table__main_row .table__column:before,
  .table__row .table__column:before,
  .available__table__row .table__column:before {
    display: inline-block;
  }
}

.article__fix {
  @media screen and (min-width: 576px) {
    overflow: hidden !important;
  }
  @media screen and (max-width: 575.98px) {
    overflow: visible !important;
    width: 110% !important;
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media screen and (max-width: 1409.98px) and (min-width: 0) {
  .table__details_button {
    margin-bottom: 10px !important;
  }
  .table__headers_row_all_transaction .table__column--buttons {
    width: 100%;
    padding: 0 20px;
  }
  .table__headers_row_all_transaction .table__column:not(.table__column--buttons) {
    display: none;
  }
}

@media screen and (max-width: 575.98px) and (min-width: 0) {
  .table__secondary_row .table__column {
    font-size: 14px;
  }
}

@media screen and (max-width: 575.98px) {
  .table__secondary_row .table__column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 16px !important;
  }
}
.header-sales-title{
  color:#000 !important;
  font: size 700px;
  line-height: 15px;
  
}
