@media screen and (max-width: 569px) and (min-width: 0px) {
  .wg-default, .wg-default .country-selector {
    position: fixed !important;
    bottom: 0 !important;
    left: 1px !important;
    width: 48px !important;
  }
  .wg-drop.country-selector a {
    display: inline-grid !important;
    height: 30px !important;
    line-height: 45px !important;
    color: transparent !important;
    padding: 6px 3px !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    width: 48px !important;
  }
  .wg-drop.country-selector .wgcurrent:after {
    top: -2px !important;
    right: 0 !important;
  }
  .wg-drop.country-selector:not(.closed) ul {
    display: block !important;
    background-color: #fff !important;
    max-height: 300px !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    width: 48px !important;
  }
}

