@import './Pagination.module';

.articlePageNavigation {
  color: white;

  &:hover {
    color: white;
  }
}

.articlePage,
.articlePageDots {
  color: white;
}

.articlePage:hover {
  color: white;
}
