
.window.homePopModel {
  width: 40%;
  height: auto;
  //padding: 50px 20px 20px 20px;
  //background: #1e2c48;
}

.home_popup_container
{
  width: 40%;
  .home_popup
  {
    .home_popup_title 
    {
      .title{
        color:#000 !important;
        padding: 0 0;
        text-align: center;
        font-family: "BurbankSmall-Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 27px;
        max-height: 40px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 100%;
        word-break: break-all;
        text-align: center !important;
      }
    } 
    .popup_info
    {
      margin-top: 15px;
      .popup-info p {
        font-family: "BurbankSmall-Medium",sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: rgba(0,16,48,.7);
        margin-bottom: 5px;
      }
      .popup_btn {
        padding: 13px 0 0;
        display: inline-block;
        width: 100%;
        height: 40px;
        background: linear-gradient(90deg,#02b7fe,#0389fd);
        border-radius: 2px;
        transition: all .2s;
        font-family: "Burbank-Big-Cd-Bd";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: .05em;
        text-transform: uppercase;
        color: #fff!important;
        text-decoration: none!important;
        box-shadow: none!important;
        outline: none!important;
        border: none!important;
      }
      .description {
        max-height: 200px;
        overflow: hidden;
        &.show_description{
          overflow: auto;
        }
        p{
          font-family: "BurbankSmall-Medium",sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: rgba(0,16,48,.7);
          line-height: 20px;
        }
      }
      .view_more
        {
          font-size: 12px;
          color: #003cff;
          cursor: pointer;
          transition: 0.3s all ease-in-out;
          &:hover
          {
            text-decoration: none;
            color: #aaa;
          }
        }
    } 
  }
}
@media(max-width: 1024px)
{
  .home_popup_container
  {
    width: 90%;
  }
}