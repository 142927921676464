@import '../../../css/images';

.eliminationBracket {
  .eliminationBracketSelectContainer {
    border: 1px solid rgba(255,255,255,0.5);
    width: 108px;
    margin: 0;
    height: 32px;
    border-radius: 4px;
  }

  .eliminationBracketSelect {
    background: #47556D;
    z-index: 1;
  }

  .ml--30 {
    margin-left: -30px;
  }

  .mr--30 {
    margin-right: -30px;
  }

  .mt--5 {
    margin-top: -5px;
  }

  .ml10 {
    margin-left: 10px;
  }

  .ml15 {
    margin-left: 15px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .mr30 {
    margin-right: 30px;
  }

  /*.mt20{margin-top: 20px;}*/

  .mt20 {
    margin-top: 20px;
  }

  /*.mt30{margin-top: 30px;}*/

  .mb20 {
    margin-bottom: 20px;
  }

  .mb50 {
    margin-bottom: 50px;
  }

  .opacity-05 {
    opacity: 0.5;
  }

  .opacity-05i {
    opacity: 0.5 !important;
  }

  .d-nonei {
    display: none !important;
  }

  .eli_general-width {
    width: 410px;
    max-width: 410px;
  }

  .eli_general-padding {
    padding-right: 30px;
    padding-left: 30px;
  }

  .eli_grid .eli_row:first-of-type .eli_col:first-of-type .eli_general-padding {
    margin-left: 30px;
    padding-left: 0;
    width: 380px;
    max-width: 380px;
  }

  .eli_grid .eli_row:last-of-type .eli_col:last-of-type .eli_general-padding {
    margin-right: 30px;
    padding-right: 0;
    width: 380px;
    max-width: 380px;
  }

  @media (max-width: 575px) {
    .eli_general-width {
      width: 380px;
      max-width: 380px;
    }
    .eli_general-padding {
      padding-right: 15px;
      padding-left: 15px;
    }
    .eli_grid .eli_row:first-of-type .eli_col:first-of-type .eli_general-padding {
      margin-left: 15px;
      padding-left: 0;
      width: 365px;
      max-width: 365px;
    }
    .eli_grid .eli_row:last-of-type .eli_col:last-of-type .eli_general-padding {
      margin-right: 15px;
      padding-right: 0;
      width: 375px;
      max-width: 375px;
    }
  }

  @media (max-width: 1449.98px) {
    .right-menu {
      position: fixed;
      right: -255px;
      transition: 150ms;
      height: calc(100vh - 82px);
      overflow: auto;
      min-height: calc(100vh - 82px);
      top: 82px;
    }
  }

  @media (min-width: 1449.98px) {
    .col-main-content-max-width {
      max-width: calc(100% - 255px);
    }
  }

  .eli-page {
    color: #ffffff;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
  }

  .eli-header {
    display: flex;
  }

  @media (max-width: 576px) {
    .eli-header {
      flex-direction: column;
    }
  }

  .eli_users-group--has-winner .eli_usr_inn {
    opacity: 0.5;
  }

  .eli_users-group--has-winner .eli_usr--winner .eli_usr_inn {
    opacity: 1;
  }

  .eli_usr--hover .eli_usr_inn {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  }

  .eli_usr--hover .eli_usr_inn .eli_usr_place {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
  }

  .eli_usr--search-highlight .eli_usr_inn {
    border: 1px solid #49c3fd;
    opacity: 1 !important;
  }

  .eli-header_l_i {
    margin: 5px 0;
  }

  .eli-header_r {
    margin: 0 15px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    -o-flex: 1;
    flex: 1;
    justify-content: space-between;
  }

  @media (min-width: 576px) {
    .eli-header_r {
      -webkit-flex-wrap: nowrap;
      -moz-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      -o-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
  }

  .eli-header_r_i {
    margin: 5px 0;
  }

  .eli-header_ttl {
    margin: 0;
    font-family: 'Burbank-Big-Cd-Bd';
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.05em;
  }

  @media (min-width: 576px) {
    .eli-header_ttl {
      font-size: 32px;
      line-height: 36px;
    }
  }

  .eli-header_type {
    margin: 0 5px 0 0;
    padding: 11px 12px;
    display: block;
    height: 30px;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: 'BurbankSmall-Bold';
    white-space: nowrap;
  }

  .eli-header_type--green {
    background: rgba(108, 208, 0, 0.15);
    color: #6cd000;
  }

  .eli-header_type--blue {
    background: rgba(73, 195, 253, 0.15);
    color: #49c3fd;
  }

  .eli-header_type--orange {
    background: rgba(255, 159, 0, 0.15);
    color: #ff9f00;
  }

  .eli-header_type--pink {
    background: rgba(202, 37, 179, 0.15);
    color: #ca25b3;
  }

  .eli-brefc {
    margin-top: 7px;
    margin-bottom: 50px;
  }

  .eli-container {
    overflow: hidden;
  }

  @media (min-width: 576px) {
    .eli-container {
    }
  }

  .eli_ttl {
    margin-bottom: 5px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .eli_ttl_l,
  .eli_ttl_r {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
  }

  .eli_ttl_l > div {
    margin-bottom: 20px;
  }

  .eli_ttl_r {
    margin-bottom: 10px;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .eli_ttl_r_i {
    margin: 0 10px 10px 0;
  }

  .eli_ttl_r_i:last-of-type {
    margin-right: 0;
  }

  .eli_ttl .eli_ttl_l .white-tooltip-theme {
    margin: 0 0 1px 15px;
  }

  .eli_ttl_ttl {
    margin: 0;
    font-family: 'BurbankSmall-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  @media (min-width: 400px) {
    .eli_ttl_ttl {
      font-size: 24px;
      line-height: 24px;
    }
  }

  .eli_search {
    position: relative;
  }

  .eli_search_input {
    padding: 0 23px 0 39px;
    width: 200px;
    height: 36px;
    background: #24334e;
    border-radius: 4px;
    font-family: 'Burbank-Big-Rg-Md';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: #ffffff;
    border: none !important;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  @media (min-width: 480px) {
    .eli_search_input {
      width: 235px;
    }
  }

  .eli_search_input::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  .eli_search_input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  .eli_search_input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  .eli_search_btn {
    opacity: 0.7;
    position: absolute;
    left: 0;
    top: 0;
    height: 36px;
    width: 39px;
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 0;
    border: none !important;
    outline: none !important;
    background-color: transparent;
    background-image: url($search);
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 0.2s;
  }

  .eli_search_btn:hover {
    opacity: 1;
  }

  .eli_search__clean-button {
    position: absolute;
    right: 6px;
    top: 8px;
    width: 20px;
    height: 20px;
    border: none !important;
    outline: none !important;
    background-color: transparent;
    background-size: 10px 9px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url($cross);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 0;
    opacity: 0;
    transition: 150ms;
  }

  .eli_search_input:focus + .eli_search__clean-button {
    opacity: 1;
    z-index: 2;
  }
  .scroll-block {
    width: 100%;
    overflow-x: hidden;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    -o-flex-direction: row;
    flex-direction: row;
  }

  .scroll-block .mCustomScrollBox {
    min-width: 100%;
  }

  .scroll-block.mCustomScrollbar .mCustomScrollBox.mCSB_horizontal .mCSB_container {
    margin-bottom: 0 !important;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    min-width: 100%;
  }

  .scroll-block .mCustomScrollBox.mCSB_horizontal .mCSB_scrollTools_horizontal {
    height: 0 !important;
  }

  .scroll-block_inn {
    min-width: 100%;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }

  .eli-content {
    position: relative;
  }

  .scroll-block_left,
  .scroll-block_right {
    display: none;
    z-index: 99;
    width: 100px;
    height: 100%;
    position: absolute;
    top: 0;
    cursor: pointer;
  }

  .scroll-block_left {
    left: 0;
    background: linear-gradient(270deg, #051534 7.29%, rgba(5, 21, 52, 0.84) 39.58%, rgba(5, 21, 52, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .scroll-block_right {
    right: 0;
    background: linear-gradient(270deg, #051534 7.29%, rgba(5, 21, 52, 0.84) 34.38%, rgba(5, 21, 52, 0) 100%);
  }

  .scroll-block_left_inn,
  .scroll-block_right_inn {
    position: absolute;
    top: 34px;
    font-size: 0;
    line-height: 0;
    right: 0;
    margin-right: 30px;
  }

  @media (min-width: 1600px) {
    .scroll-block_left,
    .scroll-block_right {
      width: 100px;
    }
    .scroll-block_left_inn,
    .scroll-block_right_inn {
      margin-right: 30px;
    }
  }

  @media (min-width: 1600px) {
    .scroll-block_left,
    .scroll-block_right {
      width: 100px;
    }
  }

  .scroll-block_right_arrow {
    width: 13px;
    height: 21px;
    background: url(https://kombatlink-assets.azureedge.net/img/caret-right-white.svg);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .eli {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  .eli_hdr {
    /*margin-left: 30px;*/
    /*margin-right: 30px;*/
    padding-top: 17px;
    padding-bottom: 17px;
    background: #24334e;
  }

  .eli_hdr_inn {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }

  .eli_hdr_i {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
  }

  .eli_hdr_i:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    right: 0;
    width: 1px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
  }

  .eli_hdr.eli_hdr--small .eli_hdr_i:after {
    top: 0;
    height: 100%;
  }

  .eli_hdr_i:last-of-type:after {
    display: none !important;
  }

  .eli_hdr_i_i {
    width: 100%;
    max-width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
  }

  .eli_hdr_ttl {
    margin: 0 0 0;
    font-family: 'BurbankSmall-Bold';
    font-style: normal;
    font-weight: bold;
    height: 30px;
    font-size: 18px;
    line-height: 34px;
    overflow: hidden;
    text-transform: uppercase;
    color: #ffffff;
  }

  .round-active-state .eli_hdr_ttl {
    color: #49c3fd !important;
  }

  .eli_hdr_date {
    margin: 0 0 0 16px;
    padding: 1px 10px 0;
    height: 30px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    /*background: rgba(73, 195, 253, 0.15);*/
    background: rgba(255, 255, 255, 0.15);
    border-radius: 2px;
    font-family: 'BurbankSmall-Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #fff;
    /*color: #001030;*/
  }

  .round-active-state .eli_hdr_date {
    color: #49c3fd !important;
    background: rgba(73, 195, 253, 0.15) !important;
  }

  .eli_hdr_result {
    margin: 17px 0 -3px;
    width: 100%;
    max-width: 100%;
    font-family: 'BurbankSmall-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
  }

  .eli_hdr_result-dark {
    color: rgba(255, 255, 255, 0.2);
  }

  /*eli_bod*/

  .eli_bod {
    overflow: hidden;
    padding: 24px 0 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }

.eli_bod_show
{
  overflow: visible !important;
}
  .eli_grid {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }

  .eli_row {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }

  .eli_users-group_hdr {
    /*padding-top: 26px;*/
    padding-top: 7px;
    padding-bottom: 1px;
    margin: 0;
    width: 100%;
    max-width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    font-family: 'BurbankSmall-Medium';
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.3);
    white-space: nowrap;
  }

  .eli_users-group_hdr_m {
    margin: 0 10px 0 20px;
    padding: 0 0 3px;
    width: 100%;
    max-width: 100%;
  }

  .eli_users-group_hdr_line {
    width: 100%;
    max-width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
  }

  .open_modal_checked {
    margin: 0;
    padding: 0;
    background-color: transparent !important;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-decoration: none !important;
    border: none !important;
    color: rgba(255, 255, 255, 0.3);
    transition: all 0.2s;
    &.open_modal_settings {
      margin-right: 10px;
    }
  }  

  .open_modal_checked:hover {
    color: rgba(255, 255, 255, 1);
  }

  .eli_usr {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .eli_usr:last-of-type {
    padding-bottom: 25px;
  }

  .eli_usr_inn {
    height: 60px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    border-radius: 4px;
    // overflow: hidden;
    background: #24334e;
  }

  .eli_usr_l {
    padding-right: 15px;
    height: 100%;
  }

  .eli_usr_num {
    padding: 2px 0 0;
    height: 100%;
    width: 34px;
    max-width: 34px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    background: rgba(247, 248, 255, 0.15);
    font-family: 'BurbankSmall-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
  }

  .eli_usr_m {
    padding-right: 15px;
    width: 100%;
    max-width: 100%;
  }

  .eli_usr_m a.prsn:hover .prsn_icon {
    opacity: 1 !important;
  }

  .eli_usr_m a.prsn:hover .prsn_ttl {
    color: #fff !important;
    text-decoration: underline;
  }

  .prsn {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    text-decoration: none !important;
    transition: all 0.2s;
  }

  a.prsn:hover .prsn_icon {
    opacity: 0.8;
  }

  a.prsn:hover .prsn_ttl {
    color: #49c3fd !important;
  }

  .prsn_icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.2s;
  }

  .prsn_icon-indicator {
    position: relative;
    transition: all 0.2s;
  }

  .prsn_icon-indicator-position {
    width: 45px;
    margin-right: 15px;
  }

  .prsn_icon-indicator:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -1px;
    right: -1px;
    border-radius: 50%;
    border: 1px solid #24334e;
    background: #cdcdcd;
  }

  .prsn_icon-indicator-active:after {
    background: #6cd000;
  }

  .prsn_r {
    margin: 3px 0 0 10px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
  }

  .prsn_ttl {
    margin: 0;
    font-family: 'Burbank-Big-Rg-Md';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #ffffff;
    -ms-word-break: break-all;
    word-break: break-all;
    transition: all 0.2s;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /*//*/

  .prsn-big {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    text-decoration: none !important;
    transition: all 0.2s;
  }

  a.prsn-big:hover .prsn-big_icon {
    opacity: 0.8;
  }

  a.prsn-big:hover .prsn-big_ttl {
    color: #49c3fd !important;
  }

  .prsn-big_icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.2s;
  }

  .prsn-big_icon-indicator {
    position: relative;
    transition: all 0.2s;
  }

  .prsn-big_icon-indicator:after {
    content: '';
    display: block;
    width: 13px;
    height: 13px;
    position: absolute;
    bottom: -1px;
    right: -1px;
    border-radius: 50%;
    border: 2px solid #f5f5f5;
    background: #cdcdcd;
  }

  .prsn-big_icon-indicator-active:after {
    background: #6cd000;
  }

  .prsn-big_r {
    margin: 3px 0 0 10px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
  }

  .prsn-big_ttl {
    width: 100%;
    max-width: 100%;
    margin: 0;
    font-family: 'Burbank-Big-Rg-Md';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
    -ms-word-break: break-all;
    word-break: break-all;
    transition: all 0.2s;
    color: #001030;
  }

  .prsn-big--pending .prsn-big_ttl {
    color: rgba(0, 16, 48, 0.3);
  }

  .eli_usr_r {
    padding-right: 20px;
  }

  .btn-winnr {
    opacity: 0.5;
    padding: 3px 0 0;
    width: 90px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 1);
    /*font-family: "Burbank-Big-Cd-Bd";*/
    /*font-size: 14px;*/
    font-family: 'BurbankSmall-Bold';
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
    background: transparent !important;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    transition: all 0.2s;
  }

  .btn-winnr:hover {
    opacity: 1;
    border-color: #f9d100;
    color: #f9d100;
  }

  .eli_usr_place {
    padding: 3px 0 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 30px;
    text-align: center;
    font-family: 'BurbankSmall-Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border-radius: 2px;
  }

  .eli_usr_place-1 {
    color: #f9d100;
    background: rgba(249, 209, 0, 0.1);
  }

  .eli_usr_place-2 {
    color: #d4d4d4;
    background: rgba(212, 212, 212, 0.1);
  }

  .eli_usr_place-3 {
    color: #c88d61;
    background: rgba(200, 141, 97, 0.2);
  }

  .eli_usr_place-5 {
    color: #49c3fd;
    background: rgba(73, 195, 253, 0.2);
  }

  /**/

  .breadcrumbs-white {
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    color: #ffffff;
    font-style: normal;
    font-weight: 550;
    font-size: 15px;
    line-height: 15px;
    font-family: 'Burbank-Big-Rg-Md';
  }

  .breadcrumbs-white a:after {
    margin: 0 0 0 6px;
    content: '/';
    display: inline;
    color: #828a99 !important;
  }

  .breadcrumbs-white a {
    margin: 0 6px 0 0;
    color: #7b8495;
    text-decoration: none !important;
    transition: all 0.2s;
  }

  .breadcrumbs-white a:hover {
    color: #ffffff;
  }

  .btn-more-info {
    margin: 0;
    padding: 0;
    border: none !important;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border-radius: 75%;
    opacity: 0.4;
    transition: all 0.2s;
  }

  .btn-more-info img {
    margin: 0;
    display: block;
    width: 17px;
  }

  .btn-more-info_icon {
    width: 17px;
    height: 17px;
    display: block;
    margin: 0;
    padding: 0;
    background: url(https://kombatlink-assets.azureedge.net/img/info.svg);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .btn-more-info:hover {
    opacity: 1;
  }

  .btn-more-info[aria-expanded='true'] {
    opacity: 1;
  }

  .white-tooltip-theme {
    z-index: 99;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
  }

  .white-tooltip-theme .tippy-tooltip {
    background-color: #fff;
    font-weight: 550;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #001030;
    border-radius: 2px;
  }

  .white-tooltip-theme .tippy-content {
    padding: 10px 9px;
    max-width: 240px;
    font-family: 'BurbankSmall-Medium';
  }

  .white-tooltip-theme .tippy-content p {
    margin-bottom: 0;
  }

  .white-tooltip-theme .tippy-content a {
    font-family: 'Burbank-Big-Rg-Bd';
    color: #001030 !important;
    text-decoration: underline;
  }

  .white-tooltip-theme .tippy-content a:hover {
    text-decoration: none;
  }

  .white-tooltip-theme .tippy-tooltip[data-placement^='top'] .tippy-arrow {
    border-top-color: #fff;
  }

  .white-tooltip-theme .tippy-tooltip[data-placement^='bottom'] .tippy-arrow {
    border-bottom-color: #fff;
  }

  .btn-green {
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: none !important;
    padding: 2px 20px 0;
    height: 40px;
    background: #6cd000;
    border-radius: 2px;
    font-family: 'Burbank-Big-Cd-Bd';
    /*font-family: "Burbank-Big-Rg-Bd";*/
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    transition: all 0.2s;
    white-space: nowrap;
  }

  .btn-green:hover {
    opacity: 0.8;
  }

  .btn-green > img {
    margin: -4px 7px 0 0;
  }

  .btnSaveChanges {
    height: 40px;
    padding: 2px 38px 0;
    width: unset;
  }

  .backButton {
    height: 40px;
    padding: 2px 23px 0;
    width: 144px;
  }

  .winner-warning {
    padding: 40px 20px;
    height: auto;
  }

  @media (min-width: 576px) {
    .winner-warning {
      width: 100% !important;
      max-width: 460px !important;
    }
  }

  .winner-warning_ttl {
    margin: 0;
    font-family: 'Burbank-Big-Rg-Md';
    font-style: normal;
    font-weight: 550;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #001030;
  }

  .winner-warning_txt {
    margin: 18px 0 0;
    font-family: 'BurbankSmall-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 27px;
    text-align: center;
    color: #001030;
  }

  .winner-warning_txt b {
    font-family: 'BurbankSmall-Bold';
  }

  .winner-warning_buttons {
    margin: 10px -5px 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
  }

  .winner-warning_buttons .gold-btn-outline,
  .winner-warning_buttons .gold-btn-primary {
    margin: 10px 5px 0;
    width: 50%;
  }

  .winner-warning_buttons .gold-btn-outline:before {
    display: none;
  }

  .winner-warning_buttons .gold-btn-outline:after {
    display: none;
  }

  .gold-btn-outline {
    position: static;
    margin: 0;
    width: 100%;
    max-width: 100%;
    height: 40px !important;
    padding: 2px 15px 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background: transparent;
    border-radius: 2px;
    border: 1px solid #f9d100 !important;
    color: #f9d100;
    transition: all 0.2s;
    font-family: 'Burbank-Big-Cd-Bd';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  a.gold-btn-outline,
  button.gold-btn-outline {
    position: static;
  }

  .gold-btn-outline:hover {
    background: #f9d100;
    color: #fff;
  }

  .gold-btn-primary {
    position: static;
    margin: 0;
    width: 100%;
    max-width: 100%;
    height: 40px;
    padding: 2px 15px 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: none !important;
    background: #f9d100;
    border-radius: 2px;
    color: #fff;
    transition: all 0.2s;
    font-family: 'Burbank-Big-Cd-Bd';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .gold-btn-primary:hover {
    background: #ecc600;
  }

  .m-checked {
    padding: 50px 15px 50px;
    height: auto !important;
    max-height: 100vh !important;
    overflow: auto;
  }

  .m-checked_i {
    margin-top: 10px;
  }

  .m-checked_i:first-of-type {
    margin: 0;
  }

  .m-checked_i_drop {
    border-top: 1px solid rgba(0, 16, 48, 0.05);
    background: #f5f5f5;
    max-height: 0;
    height: auto;
    overflow: hidden;
    transform: translate(0, -50%) scaleY(0);
    transition: 300ms;
    display: block;
  }

  .m-checked_i_drop.open {
    transform: translate(0) scaleY(1);
    max-height: 500px;
  }

  .m-checked_i_drop .m-checked_i {
    margin-top: 0;
  }

  .m-checked_i_drop .m-checked_row {
    padding-bottom: 3px;
  }

  .m-checked_row {
    padding: 0 10px 12px;
    background: #f5f5f5;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .m-checked_col {
    margin: 12px 5px 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    justify-content: center;
  }

  .m-checked .prsn {
    margin-right: 20px;
  }

  .m-checked .prsn_ttl {
    color: #001030;
  }

  .m-checked .prsn_icon {
  }

  .m-checked .prsn_icon-indicator:after {
    border-color: #f5f5f5;
  }

  .m-checked_i_drop {
    padding: 0 10px 12px;
  }

  .m-checked_i_drop .prsn {
    margin-right: 0;
  }

  @media (min-width: 576px) {
    .m-checked {
      padding: 50px 40px 50px;
    }
    .m-checked_row {
      padding: 0 25px 14px;
    }
    .m-checked_i_drop {
      padding: 0 45px 12px;
    }
  }

  .btn-copy-text {
    width: 36px;
    height: 32px;
    background: transparent;
    border: 1px solid #b3b7c0;
    border-radius: 2px;
    background: url($copyLink);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: auto;
    background-size: auto;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    line-height: 0;
    font-size: 0;
    transition: all 0.2s;
    opacity: 0.6;
  }

  .btn-copy-text:hover {
    opacity: 1;
  }

  .btn-copy-text:active {
    background-color: rgba(0, 16, 48, 0.05);
    opacity: 1;
  }

  .btn-open-drop {
    padding: 0;
    width: 36px;
    height: 32px;
    background: transparent;
    border: 1px solid #b3b7c0;
    border-radius: 2px;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    line-height: 0;
    font-size: 0;
    transition: all 0.2s;
    opacity: 0.6;
  }

  .btn-open-drop:after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    background: url(https://kombatlink-assets.azureedge.net/img/grey_arrow_down.svg);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: auto;
    background-size: auto;
    transition: all 0.2s;
  }

  .btn-open-drop:hover {
    opacity: 1;
  }

  .btn-open-drop:active {
    background-color: rgba(0, 16, 48, 0.05);
    opacity: 1;
  }

  .btn-open-drop.active {
    background-color: rgba(0, 16, 48, 0.05);
    opacity: 1;
    border: 1px solid rgba(0, 16, 48, 0.05);
  }

  .btn-open-drop.active:after {
    transform: rotate(180deg);
  }

  .m-checked_i_link {
    font-family: 'Burbank-Big-Rg-Md';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #001030;
    text-decoration: none !important;
    transition: all 0.2s;
    -ms-word-break: break-all;
    word-break: break-all;
  }

  .m-checked_i_link:hover {
    color: #49c3fd;
  }

  .btn-link-publish {
    position: static;
    margin: 0;
    width: 100px;
    height: 32px;
    padding: 3px 0px 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background: transparent;
    border-radius: 2px;
    border: 1px solid #49c3fd !important;
    color: #49c3fd;
    transition: all 0.2s;
    font-family: 'Burbank-Big-Cd-Bd';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .btn-link-publish:hover {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    color: #fff;
  }

  .btn-link-publish span:last-of-type {
    display: none;
  }

  .btn-link-publish.active span:first-of-type {
    display: none;
  }

  .btn-link-publish.active span:last-of-type {
    display: block;
  }

  .btn-link-publish.active {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    color: #fff;
  }

  .btn-link-publish.active:hover {
    opacity: 0.75;
  }

  .m-checked_ttl {
    margin-bottom: 5px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .m-checked_ttl_i {
    margin: 0 20px 15px 0;
  }

  .m-checked_ttl_i:last-of-type {
    margin-right: 0;
  }

  .m-checked_ttl_ttl {
    margin-bottom: 0;
    padding: 0 0 0 10px;
    font-family: 'BurbankSmall-Medium';
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
  }

  .m-checked_ttl_ttl-bold {
    font-family: 'BurbankSmall-Bold';
    font-weight: bold;
  }

  @media (min-width: 576px) {
    .m-checked_ttl {
      margin-bottom: 15px;
    }
    .m-checked_ttl_ttl {
      font-size: 26px;
      line-height: 30px;
      padding: 0 0 0 30px;
    }
  }

  .m-checked_ttl_date {
    display: block;
    height: 30px;
    padding: 10px 11px 0;
    background: rgba(73, 195, 253, 0.1);
    border-radius: 2px;
    font-family: 'BurbankSmall-Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #49c3fd;
  }

  .m-checked_users {
    margin: 0 -10px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .m-checked_users_i {
    margin: 0 10px 10px;
    padding: 20px 15px;
    width: 100%;
    max-width: 100%;
    background: #f5f5f5;
  }

  @media (min-width: 576px) {
    .m-checked_users_i {
      padding: 20px 30px;
    }
  }

  @media (min-width: 992px) {
    .m-checked_users {
      -webkit-flex-wrap: nowrap;
      -moz-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      -o-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .m-checked_users_i {
      width: 50%;
      max-width: 50%;
    }
  }

  .m-checked_user {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
  }

  .m-checked_user_outcome {
    display: block;
    padding: 11px 0 0;
    width: 90px;
    height: 30px;
    border-radius: 2px;
    font-family: 'BurbankSmall-Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .m-checked_user_outcome--win {
    color: #6cd000;
    background: rgba(108, 208, 0, 0.2);
  }

  .m-checked_user_outcome-los {
    color: #e13b4c;
    background: rgba(225, 59, 76, 0.2);
  }

  .m-checked_tabs {
    margin-top: 18px;
  }

  .m-checked_tabs_hdr {
    margin: 0 -5px 0;
    border-bottom: 1px solid rgba(37, 50, 78, 0.1);
  }

  .m-checked_tabs_hdr_inn {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-bottom: -1px;
  }

  .m-checked_tabs_contol {
    display: block;
    margin: 0 10px 0 30px;
    padding: 0;
    height: 40px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    border: none;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    color: #000;
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    transition: all 0.2s;
    font-family: 'Burbank-Big-Rg-Md';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: rgba(0, 16, 48, 0.3);
  }

  .m-checked_tabs_contol:hover {
    color: #49c3fd;
  }

  .m-checked_tabs_contol.active {
    border-bottom: 2px solid #49c3fd;
    color: #49c3fd;
  }

  .m-checked_tabs_body {
    padding: 20px 5px 0;
  }

  .m-checked_tabs_tab {
    display: none;
  }

  .m-checked_tabs_tab.active {
    display: block;
  }

  .m-checked_tabs_tab_empty {
    margin: 15px 0 0;
    font-family: 'Burbank-Big-Rg-Md';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #001030;
    opacity: 0.5;
    text-align: center;
  }

  .m-checked_watch_i {
    margin: 10px 0 0;
    padding: 14px 15px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
    background: #f5f5f5;
  }

  .m-checked_watch_i:first-of-type {
    margin-top: 0;
  }

  @media (min-width: 576px) {
    .m-checked_watch_i {
      padding: 14px 30px;
    }
  }

  .m-checked_watch_ttl {
    margin: 0;
    font-family: 'Burbank-Big-Rg-Md';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #001030;
  }

  .m-checked_watch_link {
    display: block;
    width: 100px;
    height: 32px;
    padding: 9px 0 0;
    text-decoration: none !important;
    text-align: center;
    border: 1px solid #49c3fd;
    border-radius: 2px;
    transition: all 0.2s;
    color: #49c3fd;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-family: 'Burbank-Big-Cd-Bd';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .m-checked_watch_link:hover {
    color: #fff;
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  }

  .f_select2-style {
    width: 108px;
  }

  .f_select2_user {
    width: 100%;
  }

  .select2-dropdown {
    border: none !important;
    background: #47556d;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
  }

  .select2-place {
    padding: 10px 12px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    line-height: 12px;
  }

  .select2-place_r {
    margin-left: 5px;
  }

  .select2-place_r .btn-more-info {
    opacity: 0.75;
  }

  .select2-place_r .btn-more-info:hover {
    opacity: 1;
  }

  .select2-place_r .btn-more-info img {
    margin-top: 1px;
    width: 14px;
  }

  .select2-place_r .btn-more-info .btn-more-info_icon {
    width: 14px;
    height: 14px;
  }

  .select2-container--default .select2-selection--single {
    padding-top: 9px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    height: 32px;
    transition: all 0.2s;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    opacity: 0.6;
    transition: all 0.2s;
  }

  .select2-container--default .select2-selection--single:hover {
    opacity: 1;
  }

  .select2-container--default.select2-container--open .select2-selection--single {
    opacity: 1;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 30px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    width: 9px;
    height: 5.5px;
    border: none !important;
    outline: none !important;
    background-color: transparent;
    background: url($arrow_top);
    transform: rotate(180deg);
    margin-left: -7px;
    margin-top: -3px;
    transition: all 0.3s;
  }

  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    transform: rotate(0deg);
  }

  .select2-selection__rendered {
    font-family: 'BurbankSmall-Medium' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #ffffff;
  }

  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: rgba(255, 255, 255, 1);
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: rgba(255, 255, 255, 1);
  }

  .select2-place_ttl {
    font-family: 'Burbank-Big-Rg-Md' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #ffffff;
  }

  .select2-container .select2-search.select2-search--dropdown {
    display: none;
  }

  .select2-results {
    max-height: 184px;
  }

  .select2-results__option {
    padding: 0;
  }

  .select2-container--default .select2-results__option[aria-disabled='true'] .select2-place_ttl {
    opacity: 0.3;
  }

  .select2-container--default .select2-results__option[aria-selected='true'] {
    background: #5a6880;
  }

  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #5a6880;
  }

  .select2-results .mCSB_container {
    margin-right: 0;
  }

  .select2-results .mCSB_scrollTools {
    width: 12px;
  }

  .select2-results .mCSB_draggerRail {
    background-color: transparent;
  }

  .select2-results .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    width: 2px;
  }

  .select2-results .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 1px 0 auto;
  }

  .select2-results .mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
  }
}
