@import '../../../../../../../css/images';

.chatHeaderBack {
  display: block;
  width: 8px;
  height: 20px;
  background-size: 8px 14px;
  background-repeat: no-repeat;
  background-position: 0 center;
  background-color: transparent;
  background-image: url($arrow_left2);
  margin-left: 15px;
}
