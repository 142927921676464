$duration: 4s;

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading_line_wrapper {
  width: 100%;
  height: 2px;
  position: relative;
  top: 0px;
  left: 0px;
  bottom: auto;
  right: auto;
}

.loading_line {
  position: relative;
  top: 0px;
  left: 0px;
  bottom: auto;
  right: auto;
  width: 100%;
  height: 100%;
  transform-origin: 100% 0%;
  animation: kf_loading_line $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
  
  .loading_line_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 100%;
    background-color: #49c3fd;
    transform-origin: 0% 0%;
  }
}

.loading_line_inner_first {
  opacity: 0;  //change for inner line opacity, currently 0/hidden
  animation: kf_loading_line_inner--1 $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}

.loading_line_inner_second {
  opacity: 1;
  animation: kf_loading_line_inner--2 $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}

@keyframes kf_loading_line {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes kf_loading_line_inner--1 {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes kf_loading_line_inner--2 {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}
