.shopping-cart-input {
  width: 100% !important;
  margin-bottom: 0.8rem !important;
}

.opacity-high {
  opacity: 1 !important;
}
.ticketSelectedSeats-div::-webkit-scrollbar {
  display: none;
}
.ticketSelectedSeats-div{
  overflow:auto;
  color:white;
  max-height:100px;
  -ms-overflow-style: none;
  scrollbar-width: none; 
}
.font-white{
  color:white
}
@media only screen and (max-width: 576px) {
  .shopping-article__content {
    padding: 60px 30px !important;

    .row {
      margin: 0 !important;
    }
  }
  .shopping-right_menu {
    padding: 0 !important;
    margin-right: 0 !important;
  }
  .cart-warrning {
    padding-left: 10px !important;
  }
}
