.notifDropdownMaxHeight {
    max-height: 220px;
}

.notif_dropdown {
    width: calc(268px - 30px);
    float: left;
    border-radius: 0 0 3px 3px;
    background-image: -webkit-linear-gradient(left, #02b9ff 0%, #0388fd 99%);
    background-image: -o-linear-gradient(left, #02b9ff 0%, #0388fd 99%);
    background-image: linear-gradient(to right, #02b9ff 0%, #0388fd 99%);
    padding: 0 15px;
    position: absolute;
    right: 0;
    top: 52px;
    display: none;
    visibility: hidden;

    &.active {
        display: inline-block;
        visibility: visible;
    }

    span {
        &.notif_head {
            width: 100%;
            display: block;
            float: left;
            font-size: 13px;
            color: #fff;
            font-weight: 700;
            letter-spacing: -0.3px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            text-transform: uppercase;
            padding: 12px 0 8px 0;
        }
    }

    ul {
        list-style-type: none;
        padding: 5px 0 10px 0;
        margin: 0;
        display: inline-block;
        float: left;
        width: 100%;

        &::-webkit-scrollbar {
            width: 5px;
            height: 10px;
            border: none;
            background-color: rgba(255, 255, 255, 0.1);
            overflow: hidden;
            border-radius: 0 3px 3px 0;
        }

        &::-webkit-scrollbar-thumb {
            background: #fff;
            border: none;
        }

        &::-webkit-scrollbar-corner {
            background: rgba(255, 255, 255, 0.1);
        }
    }
}

@media only screen and (min-width: 992px) {
    .notif_dropdown {
        top: 64px;
    }
}
