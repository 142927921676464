.eliminationBracket {
  .padding-top {
    padding-top: 50px;
  }

  body.grid-full-screen {
    overflow: hidden !important;
  }

  .grid-full-screen .eli-container {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: linear-gradient(0deg, #051534, #051534);
  }

  .grid-full-screen .eli_fscreen-hdr {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }

  @media (max-width: 576px) {
    .grid-full-screen .eli_fscreen-hdr {
      flex-direction: column;
    }
  }

  .eli_grid--brackets_col {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
  }

  .eli_grid--extra {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
  }

  .eli_grid--extra_row {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -ms-align-self: flex-start;
    align-self: flex-start;
  }

  .eli_grid--extra_col {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
  }

  .eli_grid_users-group {
    position: relative;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    -o-flex: 1;
    flex: 1;
    flex-grow: 1;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
  }

  .eli_grid--extra .eli_usr + .eli_usr {
    border-top: 1px solid rgba(118, 128, 155, 0.5);
  }

  .eli_grid--extra .eli_usr:last-of-type {
    padding-bottom: 24px;
  }

  .eli_grid--extra .eli_grid--brackets_col .eli_grid_users-group:before,
  .eli_grid--extra_col .eli_grid_users-group:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 50%;
    transform: translateY(50%);
    z-index: 1;
    content: "";
    background: rgba(118, 128, 155, 0.5);
  }

  .eli_grid--extra .eli_grid--brackets_col:last-of-type .eli_grid_users-group:before,
  .eli_grid--extra_col:last-of-type .eli_grid_users-group:before {
    display: none;
  }

  .eli_grid--extra_col .eli_users-group_hdr {
    border-left: 1px solid rgba(118, 128, 155, 0.5);
  }

  .eli_grid--extra_col .eli_usr {
    border-left: 1px solid rgba(118, 128, 155, 0.5);
  }

  .eli_grid--extra_col .eli_usr + .eli_usr {
    border-left: none;
  }

  .eli_grid--elimination_col {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
  }

  .eli_grid--elimination .eli_grid--elimination_col .eli_grid_users-group:before {
    position: absolute;
    top: 90px;
    right: 0;
    width: 1px;
    height: calc(100% - 180px);
    z-index: 1;
    content: "";
    background: rgba(118, 128, 155, 0.5);
  }

  .eli_grid--elimination .eli_grid--elimination_col:last-of-type .eli_grid_users-group:before {
    display: none !important;
  }

  .eli_grid--elimination_col .eli_users-group:first-of-type {
    margin-top: 0 !important;
  }

  .eli_grid--elimination_col .eli_users-group:last-of-type {
    margin-bottom: 0 !important;
  }

  .eli_grid--elimination .eli_usr + .eli_usr {
    border-top: 1px solid rgba(118, 128, 155, 0.5);
  }

  .eli_grid--elimination .eli_usr:last-of-type {
    padding-bottom: 24px;
  }

  .eli_fscreen-hdr {
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: none;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
    background: linear-gradient(90deg, #041534 0%, #061235 100%);
  }

  .eli_fscreen-hdr_logo {
    display: block;
    width: 140px;
  }

  .btn-blue.eli_fscreen-hdr_btn-blue {
    padding: 2px 23px 0;
    width: 144px;
  }

  .eli-page .article__header-container.article-header {
    margin: 0 0 50px;
    width: 100%;
  }

  @media (max-width: 576px) {
    .eli-page .article-header__background-container {
      height: 440px;
      padding-bottom: 175px;
    }
    .eli-page .article-header__content {
      background: linear-gradient(180deg, transparent calc(100% - 180px), #051534 calc(100% - 313px), #051534 100%),
        linear-gradient(0deg, rgba(0, 20, 60, 0.6), rgba(0, 20, 60, 0.6));
    }
  }

  @media (min-width: 992px) {
    .registration__info.align-items-stretch {
      margin-top: -75px;
    }
  }
}

.lobby_detail_available{
  color: #fff !important;
}

.eli_usr_inn.result-box {
  margin-bottom: 3px;
  margin-top: 3px;
}
.result-box {
  position: relative;
}
.eliminationBracket .eli_users-group--has-winner .eli_usr_inn {
  opacity: 1 !important;
}
.result-container .result-link {
  color: rgba(255, 255, 255, 0.3) !important;
  font-size: 11px;
  line-height: 1;
  cursor: pointer;
  position: relative;
  z-index: 9;
}

.result-link:hover {
  color: #fff;
}

.result-container {
  position: absolute;
  left: 0;
  top: -26px;
}
.result-box {
  margin-bottom: 2px;
}

.result-show {
  padding-right: 0px !important;
}
.eliminationBracket .eli_grid--elimination .eli_grid--elimination_col .eli_grid_users-group:before {
  top: 94px;
  height: calc(100% - 187px);
}

.result-container .result_detail_available{
  color: #fff !important;
}

.result-box .result-container {
  top: -13px;
  background: #051534;
  line-height: 0.5;
  padding-left: 0px;
  padding-right: 8px;
}
.result-show .eli_users-group_hdr_m {
  margin-left: 50px;
}
.first-result-box .result-container {
  top: -21px !important;
  left: 17px;
}
.result-box .result-container:after {
  position: absolute;
  content: "";
  height: 5px;
  width: 15px;
  background: #051534;
  left: -10px;
  top: 2px;
}
.first-result-box .result-container:after
{
  display: none;
}