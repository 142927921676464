.simpleClear {
  z-index: 0;
  opacity: 0;

  &.visible {
    opacity: 1;
    z-index: 2;
  }
}

.error {
  border: 1px solid rgb(230, 72, 70) !important;
}