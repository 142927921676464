@import '../../../../../css/images';

.cropDropContainer {
  width: 100%;
}

.drop {
  height: 316px;
  border: 2px dashed #d7d7d7;
  position: relative;
  width: 100%;
  margin: 30px 0;
  transition: 150ms;
}

.dropIcon {
  width: 76px;
  height: 60px;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url($cloud);
  margin-bottom: 21px;
  opacity: 0.5;
  transition: 150ms;
}

.dropHighlighted {
  border: 2px dashed #49c3fd;

  .dropIcon {
    opacity: 1;
  }

  .dropText {
    color: #555555;
  }
}

.dropText {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  color: #8e8e8e;
  margin-bottom: 10px;
  transition: 150ms;
}

.dropLabel {
  width: 221px;
  height: 40px;
}

.cropRecommendedSize {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  color: #8e8e8e;
}

@media screen and (max-width: 767.98px) {
  .dropIcon {
    display: none;
  }
  .dropText {
    display: none;
  }
}
