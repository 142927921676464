body {
  background: #051534;
}

.header {
  height: 82px;
  background: linear-gradient(90deg, #041534 0%, #061235 100%);
  padding: 0 40px;

  &__logo {
    width: 140px;
    height: 30px;
    background-color: transparent;
    background-image: url("https://kombatlink-assets.azureedge.net/img/logo.png");
    background-size: 100% 100%;
    margin-right: 20px;
  }

  &__button {
    width: 144px;
    height: 40px;
  }

  @media screen and (max-width: 575.98px) {
    height: 120px;

    &__button {
      margin-top: 20px;
    }
  }
}

.search {
  width: 235px;
  height: 36px;
  @media screen and (max-width: 991.98px) {
    margin-top: 10px;
  }
}

.article {
  &__headerContainer {
    padding: 0 40px;
    margin: 30px -15px;
  }
  &__headerBracket {
    padding: 0 40px;
    margin-bottom: 10px;
  }

  &__header {
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: white;
    margin: 0 30px 0 0;

    @media screen and (max-width: 575.98px) {
      font-size: 18px;
    }
  }

  &__label {
    display: block;
    width: 136px;
    height: 26px;
    border-radius: 2px;
    font-family: "BurbankSmall-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 26px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;

    @media screen and (max-width: 767.98px) {
      margin-top: 10px;
    }

    &Green {
      background: rgba(108, 208, 0, 0.15);
      color: #6cd000;
    }
  }

  &__bracket {
    overflow: auto;
  }

  @media screen and (max-width: 767.98px) {
    &__bracket {
      max-height: none;
    }
  }

  &__roundsRow {
    background: #24334e;
    height: 64px;
    width: 100%;
  }

  &__roundsColumn {
    width: 380px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 25px;
    flex-shrink: 0;
  }

  &__roundTitle {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    color: white;
    margin-right: 15px;
  }

  &__roundTime {
    width: 160px;
    height: 30px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 2px;
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: white;
  }
}

.movies__column__block {
  &::after {
    content: "";
    display: block;
    position: absolute;
    background: #76809b;
    opacity: 0.5;
    right: 0;
    top: 25%;
    width: 1px;
    height: 50%;
  }

  &.movies__column__block2::after {
    top: 25%;
    height: 50%;
  }
  &.movies__column__block3::after {
    top: 16.668%;
    height: 66.668%;
  }
  &.movies__column__block4::after {
    top: 12.5%;
    height: 75%;
  }
  &.movies__column__block5::after {
    top: 10%;
    height: 80%;
  }
  &.movies__column__block6::after {
    top: 8.335%;
    height: 83.33%;
  }
  &.movies__column__block7::after {
    top: 7.145%;
    height: 85.712%;
  }
  &.movies__column__block8::after {
    top: 6.25%;
    height: 87.5%;
  }
  &.movies__column__block9::after {
    top: 5.556%;
    height: 88.889%;
  }
  &.movies__column__block10::after {
    top: 5%;
    height: 90%;
  }
}


.movies {
  padding: 30px 0;
  padding-left: 10px;

  .colAuto {
    max-width: none;
  }

  &__column {
    position: relative;
    flex-shrink: 0;
  }

  &__row {
    position: relative;
    height: 50%;
    align-items: center;
    flex-shrink: 0;
    flex-wrap: nowrap;
    max-width: none;

    &::before {
      width: 380px;
      height: 1px;
      top: 50%;
      right: 0;
      content: "";
      position: absolute;
      display: block;
      background: #76809b;
      opacity: 0.5;
      z-index: 0;
    }

    &Last {
      &::before {
        width: 300px;
      }
    }
  }

  & > .colAuto > &__row {
    height: 100%;

    &::before {
      width: 300px;
      right: 80px;
    }
  }

  &__movie {
    flex-shrink: 0;
    width: 380px;
    padding: 0 40px;
    margin-top: 20px;
    overflow: hidden;
  }
  &__stream {
    height: 130px;
    width: 100%;
    position: relative;
    border-radius: 2px;
    margin-bottom: 20px;
    padding: 0 3px;
    min-width: 150px;
    overflow: hidden;
  }

  &__streamPreview {
    width: 100%;
    height: 100%;
    display: block;
    background-size: cover;
    background-position: center center;
    border-radius: 2px;
    cursor: default;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% - 36px);
      background: rgba(0, 16, 48, 0.5);
      opacity: 0;
      transition: 200ms;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: calc(50% - 18px);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-size: 18px 18px;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: rgba(255, 255, 255, 0.25);
      background-image: url("https://kombatlink-assets.azureedge.net/img/play.svg");
      opacity: 0;
      transition: 200ms;
      transform: translate(-50%, -50%);
    }

    /*&:hover::before,
    &:hover::after {
      opacity: 1;
    }*/
  }

  &__streamPreview__hasStream:hover::before,
  &__streamPreview__hasStream:hover::after {
    opacity: 1;
    cursor: pointer;
  }

  &__streamAuthor {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(0, 16, 48, 0.8);
    border-radius: 0 0 2px 2px;
    padding: 0 10px;
  }

  &__streamProfilePicture {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 7px 0 0;
  }

  &__streamUsername {
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: white;
    &.movies__streamUsername_highlighted {
      color: #49c3fd;
    }
  }

  &__number {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    opacity: 0.3;
    margin-bottom: 5px;

    span {
      flex-shrink: 0;
      display: block;
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 12px;
      color: white;
      padding-right: 10px;
      margin-top: 3px;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: white;
    }
  }
  &__details {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    color: white;
    position: relative;
    bottom: 5px;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }
}

.search {
  &::after {
    top: 12px;
  }

  &__input {
    height: 36px;
  }

  &__clear {
    top: 11px;
  }
}

.window {
  width: 900px;
  padding: 20px 40px;

  &__header {
    font-family: "BurbankSmall-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: #001030;
    margin-bottom: 20px;
    padding: 0 20px 0 30px;
  }

  &__label {
    width: 159px;
    height: 30px;
    background: rgba(73, 195, 253, 0.1);
    border-radius: 2px;
    text-align: center;
    font-family: "BurbankSmall-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #49c3fd;

    @media screen and (max-width: 575.98px) {
      margin-left: 30px !important;
    }
  }

  &__player {
    height: 90px;
    padding: 0 30px;
    background: #f5f5f5;

    @media screen and (max-width: 991.98px) {
      margin-bottom: 10px !important;
    }
    @media screen and (max-width: 575.98px) {
      height: 70px;
      padding: 0 15px;
    }

    &Picture {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;

      @media screen and (max-width: 575.98px) {
        width: 36px;
        height: 36px;
      }
    }

    &Username {
      font-family: "Burbank-Big-Rg-Md", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #001030;

      @media screen and (max-width: 575.98px) {
        font-size: 14px;
      }
    }

    &Label {
      width: 90px;
      height: 30px;
      border-radius: 2px;
      text-align: center;
      font-family: "BurbankSmall-Bold", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 30px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      @media screen and (max-width: 575.98px) {
        width: 80px;
      }

      &Green {
        background: rgba(108, 208, 0, 0.2);
        color: #6cd000;
      }

      &Red {
        background: rgba(225, 59, 76, 0.2);
        color: #e13b4c;
      }
    }
  }
}

#match-info .window {
  width: 870px;
  padding: 50px 35px;

  @media screen and (max-width: 575.98px) {
    padding: 30px 10px;
  }
}

iframe {
  width: 100%;
}

.movie__card__scrolled {
  margin-left: 0;
  margin-right: 0;
}
.movie__card__highlighted {
  border-top: 1px solid #49c3fd;
  border-bottom: 1px solid #49c3fd;
}