.articleTabMenuBeforeContainer {
  margin: 50px 0 20px;
  border-bottom: 1px solid #25324e;
}

@media screen and (max-width: 767.98px) {
  .articleSelectsPopup {
    padding-bottom: 130px !important;
  }
}

.articleSelect {
  width: calc(100% / 6.06);
}

@media screen and (max-width: 1199.98px) and (min-width: 768px) {
  .articleSelect {
    width: calc(100% / 3);
  }
}

@media screen and (max-width: 767.98px) and (min-width: 576px) {
  .articleSelect {
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 575.98px) and (min-width: 0) {
  .articleSelect {
    width: 100%;
  }
}

.search {
  margin-bottom: 20px;
}