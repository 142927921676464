@import "src/css/vars";

body {
  background-color: #080629;
}

.background {
  background-color: #051534;
  z-index: 0;
  opacity: 0.9;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.signIn {
  display: flex;
  align-items: center;
  min-height: 100vh;
}
.signIn .row {
  justify-content: center;
}
.signIn__header {
  color: white;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 45px;
  letter-spacing: 0.05em;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 40px 0;
  text-align: center;
}

.signIn__bigHeader {
  color: white;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  line-height: 65px;
  letter-spacing: 0.05em;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0 0 30px 0;
}

.signIn__subheader {
  color: white;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin-block-start: 0;
  margin-block-end: 0;
}

@media screen and (max-width: 575.98px) {
  .signIn__header {
    font-size: 38px;
    line-height: 38px;
  }
  .signIn__big-header {
    font-size: 45px;
    line-height: 45px;
  }
  .signIn__subheader {
    font-size: 16px;
    line-height: 22px;
  }
}

.signIn__role {
  display: none;
}

.role-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 48%;
  height: 88px;
  background: #25324e;
  border: 1px solid rgba(73, 195, 253, 0);
  box-sizing: border-box;
  border-radius: 4px;
  transition: 200ms;
  cursor: pointer;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.2);
  margin: 0 0 11px 0;
}

.signIn__role:checked + .role-label {
  border: 1px solid #49c3fd;
  color: #49c3fd;
}
.signIn__role:checked + .role-label .role-label__svg svg {
  fill: #49c3fd;
}

.role-label__svg {
  position: relative;
  width: 52px;
  height: 22px;
  margin: 0 0 8px 0;
}

.role-label__svg svg {
  width: 100%;
  height: 100%;
  transition: 200ms;
  fill: rgba(255, 255, 255, 0.2);
}

.signIn__inputTitle {
  font-family: "BurbankSmall-Light", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  margin: 19px 0 12px 0;

  color: rgba(255, 255, 255, 0.7);
}

.signIn__defaultInput {
  width: 100%;
  height: 55px;
  outline: none;
  background-color: #25324e;
  border-radius: 4px;
  color: white;
  box-sizing: border-box;
  padding: 0 20px;
  border: none;
}

.signIn__defaultInput:focus {
  border: 1px solid #49c3fd;
  padding: 0 19px;
}

.signIn__cleanButton {
  position: absolute;
  right: 16px;
  top: 19px;
  width: 18px;
  height: 16px;
  background-color: transparent;
  background-size: 10px 9px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(#{$cdn}/img/cross.svg);
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 0;
  opacity: 0;
  transition: 150ms;
}

.signIn__defaultInput:focus + .signIn__cleanButton {
  opacity: 1;
  z-index: 2;
}

.signIn__select,
.signIn__input-date {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  z-index: 2;
  position: absolute;
}

.signIn__container {
  position: relative;
  background: #25324e;
  border-radius: 4px;
  height: 55px;
  width: 100%;
}

.signIn__select-container::after,
.signIn__select-container::before {
  content: "";
  display: block;
  position: absolute;
  top: 28px;
  width: 16px;
  height: 3px;
  background-color: white;
  z-index: 1;
}

.signIn__date-container::after {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 17px;
  height: 15px;
  background-color: transparent;
  background-image:  url("#{$cdn}/img/calendar.svg");
  background-size: 100% 100%;
  z-index: 1;
}

.signIn__select-container::after {
  right: 11px;
  transform: rotate(-40deg);
}
.signIn__select-container::before {
  right: 22px;
  transform: rotate(40deg);
}

.signIn__listed {
  display: none;
}

.listed-label {
  width: 48%;
  height: 54px;
  background: #25324e;
  border: 1px solid rgba(73, 195, 253, 0);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  color: white;
  font-size: 16px;
  line-height: 16px;
  font-family: "BurbankSmall-Light", sans-serif;
  transition: 200ms;
}

.listed-label__round {
  display: block;
  background: white;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 6px 0 0;
  border: 3px solid #25324e;
  box-shadow: 0 0 0 1px white;
  box-sizing: content-box;
  transition: 200ms;
}

.signIn__listed:checked + .listed-label {
  border: 1px solid #6acafc;
  color: #6acafc;
}

.signIn__listed:checked + .listed-label .listed-label__round {
  background: #6acafc;
  box-shadow: 0 0 0 1px #6acafc;
}

.signIn__agree-licence {
  display: none;
}

.signIn__agree-licence-label {
  width: 20px;
  height: 20px;
  opacity: 0.4;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: transparent;
  background-size: 14px 10px;
  background-position: center center;
  background-repeat: no-repeat;
  transition: 200ms;
  cursor: pointer;
  margin: 0 10px 0 0;
}
.signIn__agree-licence:checked + .signIn__agree-licence-label {
  background-image: url(#{$cdn}/img/ok.svg);
  background-color: #49c3fd;
  opacity: 1;
  border: 1px solid #49c3fd;
}

.signIn__agree-licence-container {
  padding: 0;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  font-family: "BurbankSmall-Light", sans-serif;
  font-style: normal;
  font-size: 14px;
  color: white;
}
.signIn__no-account {
  padding: 0;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  font-family: "BurbankSmall-Light", sans-serif;
  font-style: normal;
  font-size: 14px;
  color: white;
  justify-content: center;
  margin-top: 10px;
}
.color-blue {
  color: #49c3fd;
}

hr {
  display: block;
  background: white;
  margin: 70px 0;
}

.signIn__see-more {
  width: 270px;
  height: 55px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 55px;
  letter-spacing: 0.05em;
  color: white;
  text-decoration: none;
  transition: 200ms;
}

.signIn__see-more:hover {
  text-decoration: none;
  background: white;
  color: #25324e;
}

@media screen and (max-width: 767.96px) {
  .m-sm-40 {
    margin: 40px 0;
  }
  .m-sm-20 {
    margin: 20px 0;
  }
}

.m-40 {
  margin: 20px 0;
}

.m-b-40 {
  margin-bottom: 40px;
}

.p-0 {
  padding: 0;
}

.m-t-38 {
  margin-top: 38px;
}

.back-container {
  margin-top: 100px;
  padding: 0 130px;
}
.back {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: white;
  z-index: 10;
  text-decoration: none;
}
.back::after {
  position: relative;
  width: 17px;
  height: 14px;
  margin: 6px 0 0 10px;
  display: inline-block;
  content: "";
  background-size: 100% 100%;
  background-color: transparent;
  background-image: url(#{$cdn}/img/arrow_left.svg);
  transform: rotate(180deg);
}
.back:hover {
  text-decoration: none;
  color: white;
}
.signIn__password-detail-container {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.signIn__forgot-container {
  font-family: "BurbankSmall-Light", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  color: #49c3fd;
}
.signIn__forgot-container a:hover {
  text-decoration: none;
  color: #49c3fd;
}
.signIn__visibleButton {
  position: absolute;
  right: 16px;
  top: 19px;
  width: 18px;
  height: 16px;
  background-color: transparent;
  background-size: 18px 11px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(#{$cdn}/img/visible.svg);
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 0;
  opacity: 0;
  transition: 150ms;
}

.signIn__defaultInput:focus + .signIn__visibleButton {
  opacity: 0.5;
  z-index: 2;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.signIn input::placeholder{
  color: rgba(255,255,255,0.5) !important;
}
.signIn input:-ms-input-placeholder{
  color: rgba(255,255,255,0.5) !important;
}
.signIn input::-ms-input-placeholder{
  color: rgba(255,255,255,0.5) !important;
}




.pass-recovery_back{
  position: absolute;
}

.pass-recovery_back .back-container{
  margin-top: 10.5vh;
  padding: 0 50px 0 0;
}

.passRecoveryContainer .signIn__header{
  margin-top: 34px;
  margin-bottom: 20px;
}
.passRecoveryContainer >.container{
  margin-top: 13vh;
  margin-bottom: 13vh;
}

.passRecoveryContainer .signIn__login{
  margin-top: 40px;
  width: 100%;
  height: 55px;
  border-radius: 4px;
  font-size: 18px;
}

.passRecoveryContainer .signIn__input-title{
  margin-bottom: 9px;
}

@media screen and (min-width: 576px) {
  .pass-recovery_back .back-container{
    padding: 0 130px 0 0;
  }
}


.errorMess{
	position: relative;
	display: block;
	margin: 22px 0 -4px;
	padding: 0 0 0 26px;
	font-family: "BurbankSmall-Medium";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.05em;
	color: #E13B4C;
}
.errorMess_icon{
	position: absolute;
	top: -2px;
	left: 0;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #E13B4C;
	background-image: url(#{$cdn}/img/cross-white.svg);
	background-repeat: no-repeat;
	background-position: center center;
}



























