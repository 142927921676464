@keyframes showWindow {
  from {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.window {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 20rem;
  background-color: white;
  animation: showWindow 350ms ease-in-out;
  padding: 25px 0;
  z-index: 999999;
}

.window__close {
  display: block;
  text-decoration: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 0;
}

.window__close::after,
.window__close::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  top: 7px;
  background: #333333;
  border-radius: 1px;
}

.window__close::after {
  transform: rotate(45deg);
}

.window__close::before {
  transform: rotate(-45deg);
}

.disabled {
  opacity: 0.6 !important;
  cursor: default !important;
}

@media screen and (max-width: 575.98px) {
  .window {
    width: calc(100% - 5rem);
  }
}
