@media screen and (max-width: 991.98px) {
  a {
    &.KlLink {
      &.header__link {
        position: relative;
        height: 60px;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 10px 0 10px 80px;
        background: transparent;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        font-size: 14px;
        line-height: 40px;
        width: 100%;
        margin: 0 !important;

        &::before {
          position: absolute;
          left: 30px;
          top: 13px;
          height: 34px;
          width: 34px;
          border-radius: 50%;
          border: 1px solid rgba(255, 255, 255, 0.1);
          background-color: transparent;
          content: "\e804";
          font-size: 20px;
          font-family: "kombatlink-icon", sans-serif;
          line-height: 34px;
          text-align: center;
          display: block;
        }

        &:nth-of-type(2)::before {
          content: "\e802";
          font-size: 14px;
        }

        &:hover {
          background: transparent;
          color: white;
        }
      }
    }
  }
}

a {
  &.KlLink {
    padding: 0;

    &.header__link {
      width: 100px;
      height: 40px;

      &:nth-of-type(2) {
        margin: 0 0 0 4px;
      }
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.KlLinkPointer {
  cursor: pointer;
}

.low {
  height: 40px !important;
}
