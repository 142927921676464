.goldButton,
.blueButton,
.greenButton,
.redButton,
.orangeButton,
.pinkButton,
.purpleButton,
.greyButton,
.whiteButton,
.darkGreyButton,
.greenHoverButton,
.lightPurpleButton {
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  border-radius: 2px;
  width: 200px;
  height: 50px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Burbank-Big-Cd-Bd', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.goldButton {
  background: transparent;
  color: #f9d100;
  border: 1px solid #f9d100;
  transition: 150ms;

  &:hover {
    color: white;
    text-decoration: none;
    background: #f9d100;
  }
}

.goldButtonFilled {
  background: #f9d100;
  border: none;
  color: white;
  transition: none;

  &:hover {
    background: #ecc600;
  }
}

.blueButton {
  background: transparent;
  color: #49c3fd;
  border: 1px solid #49c3fd;
  transition: 150ms;

  &:hover {
    color: white;
    text-decoration: none;
    background: #49c3fd;
  }
}

.blueButtonFilled {
  background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  border: none;
  color: white;
  transition: none;

  &:hover {
    background: linear-gradient(90deg, #13a9e4 0%, #0a71cb 100%);
  }
}

.blueButtonFilledNoGradient {
  background: #49c3fd;
  border: none;
  color: white;

  &:hover {
    background: #0389fd;
  }
}

.blueButtonTransparentFilled {
  background: linear-gradient(90deg, rgba(2, 183, 254, 0.1) 0%, rgba(3, 137, 253, 0.1) 100%);
  border: none;

  &:hover {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  }
}

.greenButton {
  background: transparent;
  color: #6cd000;
  border: 1px solid #6cd000;
  transition: 150ms;

  &:hover {
    background: #6cd000;
    color: white;
    text-decoration: none;
  }
}

.greenButtonFilled {
  background: #6cd000;
  border: none;
  color: white;

  &:hover {
    background: #64bc04;
  }
}

.redButton {
  background: transparent;
  color: #e13b4c;
  border: 1px solid #e13b4c;
  transition: 150ms;

  &:hover {
    background: #e13b4c;
    color: white;
    text-decoration: none;
  }
}

.redButtonFilled {
  background: #e13b4c;
  border: none;
  color: white;

  &:hover {
    background: #cb2f3f;
  }
}

.orangeButton {
  background: transparent;
  color: #ec5418;
  border: 1px solid #ec5418;
  transition: 150ms;

  &:hover {
    background: #ec5418;
    color: white;
    text-decoration: none;
  }
}

.orangeButtonFilled {
  background: linear-gradient(90deg, #ec5418 0%, #df2d06 100%);
  border: none;
  color: white;

  &:hover {
    background: linear-gradient(90deg, #df551f 0%, #c22c0b 100%);
  }
}

.pinkButton {
  background: transparent;
  color: #cb25b3;
  border: 1px solid #cb25b3;
  transition: 150ms;

  &:hover {
    background: #cb25b3;
    color: white;
    text-decoration: none;
  }
}

.pinkButtonFilled {
  background: linear-gradient(90deg, #cb25b3 0%, #7d2eae 100%);
  border: none;
  color: white;

  &:hover {
    background: linear-gradient(90deg, #a91493 0%, #640d9a 100%);
  }
}

.lightPurpleButton {
  background: rgba(60, 61, 170, 0.05);
  border: none;
  color: #3c3daa;

  &:hover {
    color: white;
    text-decoration: none;
    border: 1px solid transparent;
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    opacity: 1;
  }
}

.lightPurpleButtonFilled {
  background: rgba(60, 61, 170, 0.05);
  border: none;
  color: #3c3daa;

  &:hover {
    color: white;
    text-decoration: none;
    border: 1px solid transparent;
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    opacity: 1;
  }
}

.purpleButton {
  background: transparent;
  color: #3c3daa;
  border: 1px solid #3c3daa;
  transition: 150ms;

  &:hover {
    background: #3c3daa;
    color: white;
    text-decoration: none;
  }
}

.purpleButtonFilled {
  background: #3c3daa;
  border: none;
  color: white;

  &:hover {
    background: #3c3d9c;
  }
}


.purpleButtonTransparentFilled {
  background: rgba(60, 61, 170, 0.05);
  color: #3c3daa;
  border: none;

  &:hover {
    background: #3c3daa;
    color: white;
  }
}

.greyButton {
  background: transparent;
  color: white;
  border: 1px solid #4b566d;
  opacity: 0.5;
  transition: 150ms;

  &:hover {
    color: white;
    text-decoration: none;
    border: 1px solid transparent;
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    opacity: 1;
  }
}

.greyButtonFilled {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  opacity: 1;
  transition: none;

  &:hover {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  }
}

.darkGreyButton {
  background: transparent;
  color: white;
  border: 1px solid #4b566d;
  opacity: 0.5;
  transition: 150ms;

  &:hover {
    color: white;
    text-decoration: none;
    border: 1px solid transparent;
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    opacity: 1;
  }
}

.darkGreyButtonFilled {
  background: #37445d;
  border: none;
  color: white;
  opacity: 1;
  transition: none;

  &:hover {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  }
}

.whiteButton {
  background: transparent;
  color: white;
  border: 1px solid white;
  transition: 150ms;

  &:hover {
    color: #061235;
    text-decoration: none;
    background: white;
  }
}

.greenHoverButton {
  &:hover {
    background: #6cd000;
    border: none;
    color: white;
  }
}

.redHoverButton {
  &:hover {
    background: #e13b4c;
    border: none;
    color: white;
  }
}

.klButton {
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;

    &:hover {
      opacity: 0.6;
    }
  }
}

.low {
 height: 40px !important; 
}