.headerNotificationNotifications {
    &::after {
        content: "\f0f3";
    }
}

.mobileMenuNotificationNotifications {
    &::before {
        content: "\f0f3";
    }
}


.notificationModal {
   width: 640px;
   padding: 35px;
}