.prsnIconIndicator {
  position: relative;
  transition: all 0.2s;
}

.prsnIconIndicator:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-radius: 50%;
  border: 1px solid #24334e;
  background: #cdcdcd;
}

.prsnIconIndicatorActive:after {
  background: #6cd000;
}