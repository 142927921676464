@import '../../../css/images';

.icon {
  width: 16px;
  height: 16px;
  background-color: #e13b4c;
  background-size: 6px 6px;
  background-image: url($cross_white);
  background-position: 50%;
  margin-right: 10px;
  border-radius: 50%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-repeat: no-repeat;
}

.text {
  font-family: 'BurbankSmall-Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: .05em;
  color: #e13b4c;
}

.error {
  display: flex;
  flex-wrap: nowrap;
  margin: 10px 0 0 !important;
}