@import './Tournament.module';

.game {
  background-color: #fff;
}

.game__title, .tournament__header, .tournament__info {
  color: #001030;
}


.game__platforms {
  border-bottom: 1px solid #e8eef6;
}

.tournament {  
  border-bottom: 1px solid #e8eef6;
}

.remainingTime {
  background: rgba(168,169,255,.1);
}

.remainingTime__time {  
  color: #3c3daa;
}

.remainingTime__text {
  color: #3c3daa;
}

.remainingTime__container::before,
.remainingTime__container::after { 
  background-color: #3c3daa;
}

.tournament__header {
  opacity: 0.4;
}


.game__signed {
  color: rgba(0,16,48,.4);
}

.game__signed span {
  color: #001030;
}


.game__platform {
  width: 100px;
  height: 30px;
  background-color: #091937;
  border-radius: 2px;
  margin: 2.5px 5px 2.5px 0;
  background-position: 50%;
  background-size: auto 40%;
  background-repeat: no-repeat;
  flex-shrink: 0;
  opacity: 1;
}