.window {
  width: 460px;
  padding: 40px 0;
}

.window__header__center {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 35px;
  text-align: center;
  color: #001030;
  width: 100%;
  padding: 0 110px;
}

@media screen and (max-width: 489.98px) {
  .window__header {
    padding: 0 40px;
  }
}
