.content {
  position: relative;
  display: flex;

  &.inernal {
    margin: 0 -10px;
  }
}

.scrollBlock {
  width: 100%;
  overflow-x: hidden;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
}

.scrollBlock_left,
.scrollBlock_right {
  display: none;
  z-index: 99;
  width: 25px;
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
}

.scrollBlock_left {
  left: 0;
  background: linear-gradient(270deg, #051534 7.29%, rgba(5, 21, 52, 0.84) 39.58%, rgba(5, 21, 52, 0) 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.scrollBlock_right {
  right: 0;
  background: linear-gradient(270deg, #051534 7.29%, rgba(5, 21, 52, 0.84) 34.38%, rgba(5, 21, 52, 0) 100%);
}

.scrollBlock_left_inn,
.scrollBlock_right_inn {
  position: absolute;
  top: 34px;
  font-size: 0;
  line-height: 0;
  right: 0;
  //margin-right: 30px;
}

.scrollBlock_right_arrow {
  width: 13px;
  height: 21px;
  background: url(https://kombatlink-assets.azureedge.net/img/caret-right-white.svg);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.scrollBlock_inn {
  min-width: 100%;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

.scrollBlock__arrow__gradient {
  width: 100px;

  .scrollBlock_left_inn,
  .scrollBlock_right_inn {
    margin-right: 30px;
  }
}
