.window {
  width: 460px;
  padding: 40px 0;
}

.windowHeader {
  text-align: center;
  width: 100%;
  font-size: 24px;
  line-height: 24px;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  margin-bottom: 24px;
}

.windowSubheader {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  text-align: center;
  color: #001030;
  opacity: 1;
  width: 100%;
  padding: 0 70px;
}
