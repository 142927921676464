@import "../../../../css/images";

.videoContainer {
  position: fixed;
  z-index: -1;
  height: 100vh;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.video {
  margin: auto;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  height: auto;
  width: 100%;
  min-height: 100vh;
}

.background {
  background-color: #051534;
  z-index: 0;
  opacity: 0.83;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.back {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: white;
  z-index: 10;
  text-decoration: none;

  &::after {
    position: relative;
    width: 17px;
    height: 14px;
    margin: 6px 0 0 10px;
    display: inline-block;
    content: "";
    background-size: 100% 100%;
    background-color: transparent;
    background-image: url($arrow_left);
    transform: rotate(180deg);
  }

  &:hover {
    text-decoration: none;
    color: white;
  }
}

.signUp {
  display: flex;
  align-items: center;
  min-height: 100vh;

  .regionSelectContainerClassName {
    height: 55px;
    z-index: 1;
  }
}

.mb40 {
  margin-bottom: 40px;
}

.signUpBigHeader {
  color: white;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  line-height: 65px;
  letter-spacing: 0.05em;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0 0 30px 0;
}

.signUpSubheader {
  color: white;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.signUpSeeMore {
  width: 270px;
  height: 55px;
  border-radius: 4px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-size: 20px;
}

.backContainer {
  margin-top: 100px;
  padding: 0 130px;
}

.hr {
  display: block;
  background: white;
  margin: 70px 0;
}

.m40 {
  margin: 40px 0;
}

.signUpHeader {
  color: white;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 45px;
  letter-spacing: 0.05em;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 40px 0;
}

.signUpRole {
  display: none;

  &:checked + .roleLabel {
    border: 1px solid #49c3fd;
    color: #49c3fd;

    .roleLabelSvg svg {
      fill: #49c3fd;
    }
  }
}

.roleLabelSvg {
  position: relative;
  width: 52px;
  height: 22px;
  margin: 0 0 8px 0;

  svg {
    width: 100%;
    height: 100%;
    transition: 200ms;
    fill: rgba(255, 255, 255, 0.2);
  }
}

.roleLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 48%;
  height: 88px;
  background: #25324e;
  border: 1px solid rgba(73, 195, 253, 0);
  box-sizing: border-box;
  border-radius: 4px;
  transition: 200ms;
  cursor: pointer;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.2);
  margin: 0 0 11px 0;
}

.signUpAgreeLicence {
  display: none;

  &:checked {
    + .signUpAgreeLicenceLabel {
      background-image: url($ok);
      background-color: #49c3fd;
      border: none;
    }
  }
}

.signUpAgreeLicenceContainer {
  padding: 0;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  font-family: "BurbankSmall-Light", sans-serif;
  font-style: normal;
  font-size: 14px;
  color: white;
}

.signUpAgreeLicenceLabel {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-size: 14px 10px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  margin: 0 10px 0 0;
  transition: 150ms;
}

.colorBlue {
  color: #49c3fd;
}

.signUpRegister {
  width: 100%;
  height: 55px;
  font-size: 18px;
}

@media screen and (max-width: 767.96px) {
  .mSm40 {
    margin: 40px 0;
  }
}

.signUpInputTitle {
  font-family: "BurbankSmall-Light", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  margin: 19px 0 12px 0;
  color: rgba(255, 255, 255, 0.7);
}

.signUpContainer {
  position: relative;
  background: #25324e;
  border-radius: 4px;
  height: 55px;
  width: 100%;
}

.signUpDefaultInput {
  width: 100%;
  height: 55px;
  outline: none;
  background-color: #25324e;
  border-radius: 4px;
  color: white;
  box-sizing: border-box;
  padding: 0 20px;
  border: none;

  &:focus {
    border: 1px solid #49c3fd;
    padding: 0 19px;

    + .signUpVisibleButton {
      opacity: 0.5;
      z-index: 2;
    }
  }
}

.signUpCleanButton {
  position: absolute;
  right: 16px;
  top: 19px;
  width: 18px;
  height: 16px;
  background-color: transparent;
  background-size: 10px 9px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url($cross);
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 0;
  opacity: 0;
  transition: 150ms;
}

.signUpInputDate {
  z-index: 2;
  position: absolute;

  &::-webkit-calendar-picker-indicator {
    display: block;
    position: absolute;
    top: 20px;
    right: 17px;
    width: 12px;
    height: 10px;
    background-color: #25324e;
    background-image: url($calendar);
    background-size: 100% 100%;
    z-index: 10;
    color: transparent;
    cursor: pointer;
  }
}

.signUpDateContainer {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 17px;
    height: 15px;
    background-color: #25324e;
    background-image: url($calendar);
    background-size: 100% 100%;
    z-index: 1;
    color: transparent;
    cursor: pointer;
  }
}

.signUpVisibleButton {
  position: absolute;
  right: 16px;
  top: 19px;
  width: 18px;
  height: 16px;
  background-color: transparent;
  background-size: 18px 11px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url($visible);
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 0;
  opacity: 0;
  transition: 150ms;
}

.signUpListed {
  display: none;

  &:checked {
    + .signUpListedLabel {
      border: 1px solid #6acafc;
      color: #6acafc;
    }

    + .listedLabel {
      .listedLabelRound {
        background: #6acafc;
        box-shadow: 0 0 0 1px #6acafc;
      }
    }
  }
}

.listedLabel {
  width: 48%;
  height: 54px;
  background: #25324e;
  border: 1px solid rgba(73, 195, 253, 0);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  color: white;
  font-size: 16px;
  line-height: 16px;
  font-family: "BurbankSmall-Light", sans-serif;
  transition: 200ms;
}

.listedLabelRound {
  display: block;
  background: white;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 6px 0 0;
  border: 3px solid #25324e;
  box-shadow: 0 0 0 1px white;
  box-sizing: content-box;
  transition: 200ms;
}