.structure {
  position: relative;
  height: 100px;
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-bottom: 23px;
  opacity: 0.4;
  transition: 150ms;
}

.width_1 {
  width: 53px;
  margin-bottom: 4px;
  padding: 5px;
}
.width_2 {
  width: 70px;
  margin-bottom: 7px;
  padding: 8px 7px;
}

.container {
  display: block;
  background: white;
  border-radius: 4px;
}

.container:last-of-type {
  margin-bottom: 0;
}

.player {
  display: block;
  background: #e1e5fd;
  border-radius: 46px;
}

.playerBlue {
  background: #49c3fd;
  opacity: 0.5;
}

.playerGreen {
  background: #b2e480;
}

.playerPurple {
  background: #3c3daa;
  opacity: 0.5;
}

.width_1 .player {
  width: 36px;
  height: 5px;
  margin-bottom: 3px;
}

.width_2 .player {
  width: 45px;
  height: 8px;
  margin-bottom: 4px;
}

.col-12:last-of-type .player {
  margin-bottom: 0;
}

.director {
  display: block;
  border-radius: 50%;
  background: #e1e5fd;
}

.directorBlue {
  background: #49c3fd;
}

.directorGreen {
  background: #6cd000;
}

.directorPurple {
  background: #3c3daa;
}

.width_1 .director {
  width: 5px;
  height: 5px;
}

.width_2 .director {
  width: 8px;
  height: 8px;
}

svg.arrow_2 {
  fill: none;
  stroke: #e1e5fd;
  width: 30px;
  height: 45px;
  margin-bottom: 0 !important;
  path {
    fill: none !important
  }
}

svg.arrow_1 {
  fill: none;
  stroke: #e1e5fd;
  width: 20px;
  height: 28px;
  margin-bottom: 0 !important;
  path {
    fill: none !important
  }
}

svg.arrow_3 {
  fill: none;
  stroke: #e1e5fd;
  width: 20px;
  height: 61px;
  margin-bottom: 0 !important;
  path {
    fill: none !important
  }
}

svg.arrow_4 {
  fill: none;
  stroke: #e1e5fd;
  width: 30px;
  height: 70px;
  margin-bottom: 0 !important;
  path {
    fill: none !important
  }
}

.text {
  display: block;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: white;
  margin-right: 10px;
}

.article__checkbox:checked + .article__label .structure {
  opacity: 1;
}
