@import "../../vars";

.old-kl-css {
  .tab-menu {
    position: relative;
    margin: 0;
    -ms-overflow-style: none;
  }

  .tab-menu::-webkit-scrollbar {
    display: none;
  }

  @keyframes blink {
    0% {
      opacity: 0.4;
    }
    15% {
      opacity: 0.45;
    }
    50% {
      opacity: 1;
    }
    85% {
      opacity: 0.45;
    }
    to {
      opacity: 0.4;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0.4;
    }
    15% {
      opacity: 0.45;
    }
    50% {
      opacity: 1;
    }
    85% {
      opacity: 0.45;
    }
    to {
      opacity: 0.4;
    }
  }

  .tournament__venue-info {
    width: 12px;
    height: 12px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/info.svg);
    position: relative;
    margin-left: 10px;
    top: 3px;
  }

  .tournament__info-container {
    position: absolute;
    left: 50%;
    bottom: 10px;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    padding-bottom: 10px;
    opacity: 0;
    display: none;
  }

  .tournament__venue-info:hover .tournament__info-container {
    display: block;
    opacity: 1;
    -webkit-animation: show 0.15s ease-in-out;
    animation: show 0.15s ease-in-out;
  }

  .adm .tournament__info-content {
    background: #fff;
    border-radius: 2px;
    padding: 13px 17px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #001030;
    white-space: nowrap;
  }

  .article .tournament__info-content:before {
    content: "";
    position: absolute;
    display: block;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    width: 0;
    height: 0;
    bottom: 1px;
    border: 6px solid rgba(0, 0, 0, 0);
    border-top: 3px solid #fff;
  }

  .game {
    position: relative;
    background-color: hsla(0, 0%, 100%, 0.1);
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 0 15px 15px;
  }

  .game--organizer:after {
    content: "Organizer";
    display: block;
    position: absolute;
    background: #6cd000;
    width: 85px;
    height: 27px;
    right: -10px;
    top: 11px;
    text-align: center;
    font-family: Burbank Small, sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 27px;
    color: #fff;
    z-index: 3;
  }

  .game--organizer:before {
    content: "";
    display: block;
    background: #5aae00;
  }

  .game--organizer:before,
  .game__condition-background {
    position: absolute;
    top: 8px;
    right: -8px;
    width: 10px;
    height: 8px;
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  .game__condition-background {
    background: #b82200;
  }

  .game__condition {
    position: absolute;
    background: -webkit-gradient(linear, left top, right top, from(#ec5418), to(#df2d06));
    background: -o-linear-gradient(left, #ec5418 0, #df2d06 100%);
    background: linear-gradient(90deg, #ec5418, #df2d06);
    width: 85px;
    height: 27px;
    right: -10px;
    top: 11px;
    text-align: center;
    font-family: Burbank Small, sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 27px;
    color: #fff;
    z-index: 5;
  }

  .game--organizer .game__condition-background {
    top: 48px;
  }

  .game--organizer .game__condition {
    top: 51px;
  }

  .game__condition-checkbox {
    display: none;
  }

  .game__condition-label {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .game__condition-container {
    bottom: 26px;
    width: 236px;
    padding-bottom: 10px;
    display: none;
  }

  .game__condition-container,
  .game__condition-container:after {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }

  .game__condition-container:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    bottom: -3px;
    border: 8px solid rgba(0, 0, 0, 0);
    border-top-color: #ff5d20;
  }

  .game__condition-content {
    width: 100%;
    background: #ff5d20;
    border-radius: 2px;
    padding: 10px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #fff;
  }

  .game__condition:hover .game__condition-container {
    display: block;
  }

  .game__picture {
    width: calc(100% + 30px);
    height: auto;
    margin: 0 -15px 17px;
    z-index: 2;
  }

  .game__title {
    -webkit-margin-before: 0;
    margin-block-start: 0;
    -webkit-margin-after: 0;
    margin-block-end: 0;
    height: 46px;
    font-family: Burbank Small, sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 23px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    color: #fff;
    margin: 0 0 17px;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    text-decoration: none;
  }

  .game_title:hover {
    text-decoration: none;
    color: #49c3fd;
  }

  .game__platforms {
    margin: 0 -30px 17px;
    padding: 0 15px 17px;
  }

  .tournament {
    margin: 0 -30px 15px;
    padding: 0;
  }

  .remaining-time__container {
    position: relative;
  }

  .remaining-time__container:after,
  .remaining-time__container:before {
    content: "";
    position: absolute;
    right: -1px;
    top: 4px;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #fff;
  }

  .remaining-time__container:after {
    top: 10px;
  }

  .remaining-time__container:last-of-type:after,
  .remaining-time__container:last-of-type:before {
    display: none;
  }

  .remaining-time__time {
    font-family: Burbank-Big-Cd-Bd, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
  }

  .remaining-time__text,
  .remaining-time__time {
    font-style: normal;
    letter-spacing: 0.05em;
    color: #fff;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .remaining-time__text {
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-size: 10px;
    line-height: 10px;
  }

  .remaining-time:hover .remaining-time__text,
  .remaining-time:hover .remaining-time__time {
    color: #49c3fd;
  }

  .tournament__running {
    position: absolute;
    left: 15px;
    top: 15px;
    width: 70px;
    height: 26px;
    background: hsla(0, 0%, 100%, 0.32);
    border-radius: 2px;
    font-family: Burbank-Big-Cd-Bd, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    line-height: 29px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
    z-index: 100;
    -webkit-animation: blink 3s ease-in-out infinite;
    animation: blink 3s ease-in-out infinite;
  }

  /* .................pagination css..................... */

  /* ................................Game css end.................................... */

  /* ................................Leaderboard.css start.................................... */

  .article .page-head {
    color: white;
    float: left;
    background-image: -webkit-linear-gradient(89deg, #02b9ff 0%, #0388fd 82%);
    background-image: -o-linear-gradient(89deg, #02b9ff 0%, #0388fd 82%);
    background-image: linear-gradient(179deg, #02b9ff 0%, #0388fd 82%);
    border-left: 1px solid #103598;
    width: 100%;
    padding: 10px;
  }

  .userimage {
    width: 86px;
    height: 86px;
    display: inline-block;
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 2px;
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: 5px;
  }

  .left-col-bg {
    background: rgb(1, 23, 79);
    background: linear-gradient(90deg, rgba(1, 23, 79, 1) 0%, rgba(57, 52, 167, 1) 100%);
  }

  @media only screen and (min-width: 1200px) {
    .username {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .username {
    font-family: "Burbank", sans-serif;
    line-height: 55px;
    font-size: 27px;
    color: #fff;
    letter-spacing: 0.6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 700;
    padding-top: 15px;
    padding-left: 0px;
    min-height: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 0px;
  }

  article .article label,
  article .article p,
  article .article h1,
  article .article h2,
  article article h3,
  article .article h4,
  article .article h5,
  article .article h6 {
    color: white;
  }

  .adm .p {
    font-family: "Burbank", sans-serif;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    min-height: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
  }

  .article__warning-text {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
    max-width: 710px;
  }

  .border-bottom {
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 5px 0px 0px 0px;
  }

  .pull-right {
    text-align: end;
  }

  .col__header {
    font-family: Burbank-Big-Cd-Bd, sans-serif;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: bolder;
    color: #fff;
  }

  .center {
    text-align: center;
  }

  .sr-number {
    font-family: "Burbank", sans-serif;
    margin: 0px 0px 0px 0px;
    padding: 1px 0px 0px 0px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    width: 20px;
    height: 20px;
    overflow-x: hidden;
    border: 1px solid transparent;
    border-radius: 2px;
    -webkit-font-smoothing: antialiased;
    border-top: 1px solid #0388fd;
    background-image: -webkit-linear-gradient(46deg, #02b9ff 0%, #0388fd 100%);
    background-image: -o-linear-gradient(46deg, #02b9ff 0%, #0388fd 100%);
    background-image: linear-gradient(136deg, #02b9ff 0%, #0388fd 100%);
  }

  .cardbox:nth-child(1) div.sr div.sr-number {
    border-top: 1px solid #4e9301;
    background-image: -webkit-linear-gradient(#6cd000 25%, #4e9301 100%);
    background-image: -o-linear-gradient(#6cd000 25%, #4e9301 100%);
    background-image: linear-gradient(#6cd000 25%, #4e9301 100%);
  }

  .cardbox:nth-child(2) div.sr div.sr-number {
    border-top: 1px solid #ec5418;
    background-image: -webkit-linear-gradient(top right, #ec5418 0%, #ec5418 100%);
    background-image: -o-linear-gradient(top right, #ec5418 0%, #ec5418 100%);
    background-image: linear-gradient(to bottom left, #ec5418 0%, #ec5418 100%);
  }

  .cardbox:nth-child(3) div.sr div.sr-number {
    border-top: 1px solid #d61d57;
    background-image: -webkit-linear-gradient(100deg, #e64846 1%, #d61d57 100%);
    background-image: -o-linear-gradient(100deg, #e64846 1%, #d61d57 100%);
    background-image: linear-gradient(190deg, #e64846 1%, #d61d57 100%);
  }

  .search {
    position: relative;
    background: #25324e;
    border-radius: 4px;
  }

  .article .search:after {
    content: "";
    width: 14px;
    height: 14px;
    left: 15px;
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/search.svg);
    opacity: 0.5;
  }

  .search:after,
  .search__clear {
    z-index: 0;
    position: absolute;
    top: 13px;
    background-color: rgba(0, 0, 0, 0);
  }

  .article .search__clear {
    right: 15px;
    width: 15px;
    height: 15px;
    background-size: 10px 9px;
    background-position: 50%;
    background-repeat: no-repeat;
    display: block;
    text-decoration: none;
    background-image: url(#{$cdn}/img/cross.svg);
    cursor: pointer;
    opacity: 0;
    -webkit-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s;
  }

  .member__second-third-column {
    width: 67.5%;
  }

  .member__picture {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .member__name {
    font-size: 16px;
    color: #fff;
  }

  .member__clan,
  .member__name {
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0.05em;
  }

  .member__clan {
    font-size: 14px;
    color: #49c3fd;
  }

  .member__checkbox {
    display: none;
  }

  .member__founder {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/crown.svg);
    cursor: pointer;
    margin-right: 7px;
  }

  .member__founder-text {
    display: none;
    bottom: 20px;
    width: 128px;
    height: 29px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 29px;
    text-align: center;
    color: #49c3fd;
    background: #fff;
  }

  .member__founder-text,
  .member__founder-text:before {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }

  .member__founder-text:before {
    content: "";
    display: block;
    bottom: -12px;
    width: 0;
    height: 0;
    border: 8px solid rgba(0, 0, 0, 0);
    border-top: 6px solid #fff;
  }

  .member__checkbox:checked ~ .member__founder .member__founder-text {
    display: block;
  }

  .member__header {
    font-size: 12px;
    line-height: 12px;
    color: hsla(0, 0%, 100%, 0.5);
    margin: 22px 0 8px;
  }

  .member__header,
  .member__info {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
  }

  .member__info {
    font-size: 13px;
    line-height: 17px;
    color: #fff;
  }

  .member__button {
    width: 140px;
    height: 40px;
  }

  .member__buttons {
    margin: 20px -5px 0;
    padding: 15px 30px;
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
    -webkit-box-flex: 0;
    -ms-flex: 0 calc(100% + 10px);
    flex: 0 calc(100% + 10px);
  }

  .member__button:first-of-type {
    margin-right: 8px;
  }

  .member__first-column:first-of-type {
    width: 80px;
  }

  .member__second-third-column:first-of-type {
    width: calc(100% - 80px);
  }

  @media screen and (max-width: 429.98px) {
    .member__first-column {
      width: 35%;
    }
    .member__second-column,
    .member__second-third-column,
    .member__third-column {
      width: 65%;
    }
  }

  @media screen and (max-width: 399.98px) {
    .member__first-column {
      width: 37.5%;
    }
    .member__second-column,
    .member__second-third-column,
    .member__third-column {
      width: 62.5%;
    }
  }

  @media screen and (max-width: 379.98px) {
    .member__first-column {
      width: 41.66667%;
    }
    .member__second-column,
    .member__second-third-column,
    .member__third-column {
      width: 58.33333%;
    }
  }

  @media screen and (max-width: 349.98px) {
    .member {
      padding-left: 28px;
      padding-right: 28px;
    }
    .member__first-column,
    .member__second-column,
    .member__second-third-column,
    .member__third-column {
      padding: 0 7px;
    }
    .member__buttons {
      margin: 20px -13px 0;
      padding: 15px 33px;
      border-top: 1px solid hsla(0, 0%, 100%, 0.1);
      width: calc(100% + 26px);
      max-width: calc(100% + 26px);
      -webkit-box-flex: 0;
      -ms-flex: 0 calc(100% + 26px);
      flex: 0 calc(100% + 26px);
    }
    .member__clan,
    .member__name {
      margin-left: -8px;
    }
  }

  @media screen and (max-width: 329.98px) {
    .member__first-column {
      width: 43.33333%;
    }
    .member__second-column,
    .member__second-third-column,
    .member__third-column {
      width: 56.66667%;
    }
    .member__name {
      font-size: 15px;
    }
  }

  div#player-members {
    z-index: 999999;
  }

  /* ................................player member.css end.................................... */

  /* ................................myprofile css Start.................................... */
  .pfl {
    margin-bottom: 80px;
  }

  @media screen and (min-width: 1500px) {
    .pfl_col-left {
      width: 57.9%;
      max-width: 57.9%;
    }
    .pfl_col-right {
      width: 42.1%;
      max-width: 42.1%;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1499px) {
    .recent-c-row {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .recent-c-col {
      width: 50%;
      max-width: 50%;
    }
  }

  @media screen and (min-width: 1920px) {
    .pfl_col-right {
      margin-left: auto;
      width: 40%;
      max-width: 40%;
    }
  }

  /*.pfl_ttl*/

  .pfl_ttl {
    margin: 0 0 16px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .pfl_ttl_l,
  .pfl_ttl_r {
    margin-bottom: 20px;
  }

  .pfl_ttl .article__header {
    font-family: "Burbank-Big-Cd-Bd";
    margin-bottom: 0;
    margin-top: 0;
  }

  .btn-green {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 2px 20px 0;
    height: 40px;
    background: #6cd000;
    border-radius: 2px;
    font-family: "Burbank-Big-Cd-Bd";
    /*font-family: "Burbank-Big-Rg-Bd";*/
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    transition: all 0.2s;
  }

  .btn-green:hover {
    opacity: 0.8;
  }

  .btn-green span {
    margin-right: 7px;
    font-family: sans-serif;
    font-size: 0;
    line-height: 0;
    width: 12px;
    min-width: 12px;
    height: 12px;
    position: relative;
    display: inline-block;
  }

  .btn-green span:before {
    content: "";
    position: absolute;
    top: -2px;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    background-color: #fff;
  }

  .btn-green span:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -3px;
    background-color: #fff;
  }

  .btn-premium {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 2px 23px 0;
    height: 40px;
    background: #ca25b3;
    border-radius: 2px;
    font-family: "Burbank-Big-Cd-Bd";
    /*font-family: "Burbank-Big-Rg-Bd";*/
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    transition: all 0.2s;
  }

  .btn-premium:hover {
    opacity: 0.8;
  }

  .btn-premium span {
    margin: -4px 8px 0 0;
    font-family: sans-serif;
    font-size: 0;
    line-height: 0;
    height: 12px;
    position: relative;
    display: inline-block;
    background: url(#{$cdn}/img/white_crown.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 16px;
    height: 14px;
  }

  .font-20 {
    font-size: 25px;
  }

  /*.pfl_img*/

  .pfl_img {
    position: relative;
    margin: 0 -15px 20px;
  }

  @media screen and (max-width: 991.98px) and (min-width: 576px) {
    .pfl_img {
      position: relative;
      margin: 0 -15px 20px;
    }
  }

  .pfl_img_img img {
    width: 100%;
    max-width: 100%;
    height: -webkit-fill-available;
  }

  .img-box-size {
    width: 110px;
    height: 85px;
  }

  .pfl_img_upload {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }

  @media screen and (min-width: 768px) {
    .pfl_img {
      position: relative;
      margin: 0 -15px 20px;
    }
    .pfl_img_upload {
      bottom: 20px;
      right: 30px;
    }
  }

  .btn-upload-dark {
    padding: 3px 15px 0;
    height: 30px;
    /*background: rgba(255, 255, 255, 0.1);*/
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    font-family: "Burbank-Big-Cd-Bd", sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    font-family: "Burbank-Big-Rg-Md";
    cursor: pointer;
    transition: all 0.2s;
  }

  .btn-upload-dark img {
    margin-right: 7px;
    margin-top: -4px;
    display: inline-block;
    width: 12px;
    height: 12px;
    opacity: 0.5;
    transition: all 0.2s;
  }

  .btn-upload-dark:hover {
    color: #fff;
    /*background: rgba(255, 255, 255, 0.2);*/
    background: rgba(0, 0, 0, 0.7);
  }

  .btn-upload-dark:hover img {
    opacity: 1;
  }

  /*.pfl_company*/

  .pfl_company {
    margin: 0 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .pfl_company_l {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-align-items: center;
    align-items: center;
  }

  .pfl_logo {
    position: relative;
  }

  .pfl_logo_img {
    width: 70px;
    height: 70px;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .btn-upload-small {
    margin: 0;
    padding: 0;
    position: absolute;
    top: -8px;
    right: -8px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #49c3fd;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    cursor: pointer;
  }

  .btn-upload-small:hover {
    opacity: 0.9;
  }

  @media screen and (min-width: 768px) {
    .pfl_logo_img {
      width: 120px;
      height: 120px;
    }
    .btn-upload-small {
      top: -7px;
      right: 7px;
      width: 35px;
      height: 35px;
    }
  }

  /*.pfl_title*/

  .pfl_company_l_l {
    margin: 10px 23px 10px 0;
  }

  .pfl_company_l_r {
    margin: 10px 0 10px;
  }

  .pfl_title_ttl {
    margin: 0;
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #ffffff;
  }

  @media screen and (min-width: 768px) {
    .pfl_title_ttl {
      font-size: 24px;
      line-height: 26px;
    }
  }

  .pfl_verif {
    margin: 13px 0 0;
  }

  .pfl_verif_active {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #6cd000;
    font-family: "Burbank-Big-Rg-Md";
  }

  .pfl_verif_active_ico {
    display: inline-block;
    position: relative;
    margin: 0 7px 0 0;
    width: 16px;
    height: 16px;
    background: #6cd000;
    border-radius: 50%;
  }

  .pfl_verif_active_ico:before {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 6px;
    transform: rotate(45deg);
    height: 7.5px;
    width: 4px;
    border-bottom: 1px solid #fafafa;
    border-right: 1px solid #fafafa;
  }

  /*tooltip white*/

  .pfl_verif_not-active {
    font-style: normal;
    font-family: "Burbank-Big-Rg-Md";
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    /*-ms-align-items: center;*/
    /*align-items: center;*/
  }

  .pfl_verif_not-active span {
    font-weight: 550;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #ffffff;
    opacity: 0.4;
  }

  .btn-more-info {
    margin: 0 0 0 10px;
    padding: 0;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    border-radius: 75%;
    opacity: 0.4;
    transition: all 0.2s;
  }

  .btn-more-info img {
    margin: -2px 0 0;
    display: block;
    width: 17px;
  }

  .btn-more-info:hover {
    opacity: 1;
  }

  .btn-more-info[aria-expanded="true"] {
    opacity: 1;
  }

  .white-tooltip-theme .tippy-tooltip {
    background-color: #fff;
    font-weight: 550;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #001030;
    border-radius: 2px;
  }

  .white-tooltip-theme .tippy-content {
    padding: 10px 9px;
    max-width: 240px;
    font-family: "BurbankSmall-Medium";
  }

  .white-tooltip-theme .tippy-content p {
    margin-bottom: 0;
  }

  .white-tooltip-theme .tippy-content a {
    font-family: "Burbank-Big-Rg-Bd";
    color: #001030;
    text-decoration: underline;
  }

  .white-tooltip-theme .tippy-content a:hover {
    text-decoration: none;
  }

  .tooltip_link-button {
    font-family: "Burbank-Big-Rg-Bd";
    color: #001030;
    text-decoration: underline;
    background-color: transparent;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0;
    margin: 0;
    display: inline;
  }

  .tooltip_link-button:hover {
    text-decoration: none;
  }

  .white-tooltip-theme .tippy-tooltip[data-placement^="top"] .tippy-arrow {
    border-top-color: #fff;
  }

  .pfl_company_r {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
  }

  .social-links {
    color: #fff;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 6px 0;
  }

  .social-links li {
    margin: 4px 7px 4px 0;
  }

  .social-links li:last-of-type {
    margin-right: 0;
  }

  .social-links a {
    width: 35px;
    height: 35px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    color: #fff;
    background: #1e2c48;
    transition: all 0.2s;
    font-size: 14px;
  }

  .table__row {
    margin: 0 -15px 15px;
  }

  .table__secondary-row .table__column {
    font-size: 15px;
  }

  .article .table__link {
    text-decoration: none;
    color: #fff;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .table__link:hover {
    text-decoration: none;
    color: #49c3fd;
  }

  .table__open,
  .table__close {
    display: block;
    position: relative;
    width: 44px;
    height: 40px;
    border: 1px solid hsla(0, 0%, 100%, 0.2);
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin: 0 0 0 10px;
  }

  .adm .table__open:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-position: 50%;
    top: 1px;
    left: 0px;
    opacity: 0.5;
    background-size: 12px 7px;
    background-image: url(#{$cdn}/img/arrow_up.svg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
  }
  .adm .table__close:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0.5;
    background-size: "55%";
    background-image: url(#{$cdn}/img/arrow_down.svg);
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background-position: center center !important;
    left: 0;
    top: 0;
  }
  .table__hide {
    display: block;
    position: relative;
    width: 44px;
    height: 40px;
    border: 1px solid #4b566d;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin: 0 0 0 10px;
  }

  .table__hide:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0.5;
    background-size: 20px 20px;
    background-image: url(#{$cdn}/img/hide.svg);
  }

  .table__open-replacer {
    width: 44px;
    height: 40px;
    opacity: 0;
  }

  .table__button {
    width: 140px;
    height: 40px;
    margin-left: 10px;
  }

  .table__button:first-of-type {
    margin-left: 0;
  }

  .table__label {
    display: inline-block;
    width: 84px;
    height: 30px;
    border-radius: 2px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  .table__label--grey {
    background: #f3f7fb;
    color: #c3cedb;
  }

  .table__label--green {
    background: rgba(108, 208, 0, 0.2);
    color: #6cd000;
  }

  .table__label--red {
    background: rgba(255, 59, 76, 0.1);
    color: #e13b4c;
  }

  .table__label--orange {
    background: rgba(242, 104, 49, 0.1);
    color: #f26831;
  }

  .table__radio {
    width: 15px;
    height: 15px;
    border: 1px solid #5f6d8b;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    background-size: 9px 8px;
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    margin-right: 13px;
    margin-bottom: 1px;
  }

  .table__radio,
  .table__warning {
    background-color: rgba(0, 0, 0, 0);
  }

  .table__warning {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    top: 3px;
    margin-left: 15px;
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/red_warning.svg);
  }

  .table__warning-text {
    height: auto;
    padding: 10px 9px;
    background: #fff;
    color: #001030;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    width: 236px;
    text-align: center;
  }

  .table__warning-content {
    bottom: 15px;
    background: rgba(0, 0, 0, 0);
    display: none;
    padding-bottom: 10px;
  }

  .table__warning-content,
  .table__warning-content:before {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }

  .table__warning-content:before {
    content: "";
    display: block;
    bottom: 2px;
    width: 0;
    height: 0;
    border: 5px solid rgba(0, 0, 0, 0);
    border-top: 3px solid #fff;
  }

  .table__warning:hover .table__warning-content {
    display: block;
  }

  .table__checkbox:checked ~ .table__main-row .table__open {
    border: 1px solid hsla(0, 0%, 100%, 0);
    background-color: hsla(0, 0%, 100%, 0);
  }

  .table__checkbox:checked ~ .table__main-row {
    background: hsla(0, 0%, 100%, 0);
    border-bottom: 1px solid #303d55;
  }

  .table__checkbox-hide:checked + .row .table__hide {
    background-color: #ec5418;
    border: 1px solid #ec5418;
  }

  .table__checkbox-hide:checked + .row .table__hide:before {
    background-size: 20px 12px;
    opacity: 1;
    background-image: url(#{$cdn}/img/visible.svg);
  }

  .table__checkbox-hide:checked + .row {
    display: none;
  }

  ._table__column {
    color: #001030;
    font-size: 16px;
    line-height: 23px;
    font-family: Burbank-Big-Rg-Md, sans-serif;
    letter-spacing: 0.05em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px;
  }

  .table__link:hover {
    text-decoration: none;
    color: #49c3fd;
  }

  .adm .table__open {
    display: inline-block;
    position: relative;
    width: 44px;
    height: 40px;
    border: 1px solid #dde3ec;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin: 0;
  }

  .table__button:last-of-type {
    margin-left: 0;
  }

  .table__checkbox:checked ~ .table__main-row .table__open:before {
    -webkit-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    transform: rotate(1turn);
  }

  .table__checkbox:checked ~ .table__main-row .table__open {
    border: 1px solid #f1f5fa;
  }

  .table__checkbox:checked ~ .table__main-row {
    border-bottom: 1px solid #e8ebef;
  }

  .table__checkbox-hide:checked + .row .table__hide:before {
    background-size: 20px 12px;
    background-image: url(#{$cdn}/img/dark_visible.svg);
  }

  .table__checkbox-hide:checked + .row {
    display: none;
  }

  .table__headers-row,
  .table__headers-row-1 {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #1e2c48;
  }

  .table .articl__column-label:last-of-type {
    margin-left: 30px;
  }

  .article.table__secondary-row-container {
    max-height: 0;
    height: auto;
    overflow: hidden;
    -webkit-transform: translateY(-50%) scaleY(0);
    -ms-transform: translateY(-50%) scaleY(0);
    transform: translateY(-50%) scaleY(0);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background: hsla(0, 0%, 100%, 0.1);
    z-index: 1;
    padding: 0;
  }

  .table__checkbox,
  .table__checkbox-hide {
    display: none;
  }

  .table__column {
    color: #fff;
    font-size: 16px;
    line-height: 23px;
    font-family: Burbank-Big-Rg-Md, sans-serif;
    letter-spacing: 0.05em;
    padding: 0 20px;
    margin: auto;
    margin-left: 0px;
  }

  .article .table__column:before {
    display: none;
    opacity: 0.5;
    margin-right: 20px;
  }

  .table__secondary-row .table__column {
    font-size: 15px;
  }

  .table__link:hover {
    text-decoration: none;
    color: #49c3fd;
  }

  .table__open {
    display: block;
    position: relative;
    width: 44px;
    height: 40px;
    border: 1px solid hsla(0, 0%, 100%, 0.2);
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin: 0 0 0 10px;
  }

  .table__open:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0.5;
    background-size: 12px 7px;
    background-image: url(#{$cdn}/img/arrow_top.svg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
  }

  .table__hide {
    display: inline-block;
    position: relative;
    width: 44px;
    height: 40px;
    border: 1px solid #4b566d;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin: 0 0 0 10px;
  }

  .table__hide:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0.5;
    background-size: 20px 20px;
    background-image: url(#{$cdn}/img/hide.svg);
  }

  .table__open-replacer {
    width: 44px;
    height: 40px;
    opacity: 0;
  }

  .table__label {
    display: inline-block;
    width: 84px;
    height: 30px;
    border-radius: 2px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  .table__label--grey {
    background: #f3f7fb;
    color: #c3cedb;
  }

  .table__label--green {
    background: rgba(108, 208, 0, 0.2);
    color: #6cd000;
  }

  .table__label--red {
    background: rgba(255, 59, 76, 0.1);
    color: #e13b4c;
  }

  .table__label--orange {
    background: rgba(242, 104, 49, 0.1);
    color: #f26831;
  }

  .table__radio {
    width: 15px;
    height: 15px;
    border: 1px solid #5f6d8b;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    background-size: 9px 8px;
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    margin-right: 13px;
    margin-bottom: 1px;
  }

  .table__radio,
  .table__warning {
    background-color: rgba(0, 0, 0, 0);
  }

  .table__warning {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    top: 3px;
    margin-left: 15px;
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/red_warning.svg);
  }

  .table__warning-text {
    height: auto;
    padding: 10px 9px;
    background: #fff;
    color: #001030;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    width: 236px;
    text-align: center;
  }

  .table__warning-content {
    bottom: 15px;
    background: rgba(0, 0, 0, 0);
    display: none;
    padding-bottom: 10px;
  }

  .table__warning-content,
  .table__warning-content:before {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }

  .table__warning-content:before {
    content: "";
    display: block;
    bottom: 2px;
    width: 0;
    height: 0;
    border: 5px solid rgba(0, 0, 0, 0);
    border-top: 3px solid #fff;
  }

  .table__warning:hover .table__warning-content {
    display: block;
  }

  .table__checkbox-hide:checked + .row .table__hide {
    background-color: #ec5418;
    border: 1px solid #ec5418;
  }

  .table__checkbox-hide:checked + .row .table__hide:before {
    background-size: 20px 12px;
    opacity: 1;
    background-image: url(#{$cdn}/img/visible.svg);
  }

  .table__checkbox-hide:checked + .row {
    display: none;
  }

  #show-hidden:checked ~ .table__checkbox-hide:checked + .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .table__checkbox:checked ~ .table__secondary-row-container {
    background: rgba(255, 255, 255, 0);
    -webkit-transform: translate(0) scaleY(1);
    -ms-transform: translate(0) scaleY(1);
    transform: translate(0) scaleY(1);
    max-height: 500px;
    padding: 15px 0;
  }

  @-webkit-keyframes showBackground {
    0% {
      width: 100%;
      height: 2px;
    }
    to {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes showBackground {
    0% {
      width: 100%;
      height: 2px;
    }
    to {
      width: 100%;
      height: 100%;
    }
  }

  @-webkit-keyframes showWindow {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes showWindow {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .modal-container--opened {
    display: block;
  }

  .modal-container__background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 2px;
    left: 50%;
    top: 50%;
    background: #051534;
    opacity: 0.7;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .modal-container--opened .modal-container__background {
    width: 100%;
    height: 100%;
    -webkit-animation: showBackground 0.35s ease-in-out;
    animation: showBackground 0.35s ease-in-out;
  }

  .window__container {
    overflow: auto;
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    position: absolute;
    top: 75px;
    left: 50px;
    padding: 30px 20px 10px;
  }

  .window__header-container {
    padding: 40px 70px 0;
  }

  .window__header {
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    color: #001030;
    margin-bottom: 30px;
  }

  .window__subheader,
  .window__textarea {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
  }

  .window__textarea {
    height: 144px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 16, 48, 0.05)), to(rgba(6, 18, 53, 0.05)));
    background: -o-linear-gradient(left, rgba(0, 16, 48, 0.05) 0, rgba(6, 18, 53, 0.05) 100%);
    background: linear-gradient(90deg, rgba(0, 16, 48, 0.05), rgba(6, 18, 53, 0.05));
    resize: none;
    padding: 20px;
  }

  .window__input,
  .window__textarea {
    width: 100%;
    border-radius: 4px;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #001030;
    margin-bottom: 20px;
    outline: none;
    border: none;
  }

  .window__input {
    height: 45px;
    background: rgba(0, 16, 48, 0.05);
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    padding-left: 20px;
  }

  .window__file-input {
    display: none;
  }

  .window__file {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #49c3fd;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .window__file:before {
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/file.svg);
    margin-right: 7px;
  }

  .window__file-label {
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 16, 48, 0.07)), to(rgba(6, 18, 53, 0.07)));
    background: -o-linear-gradient(left, rgba(0, 16, 48, 0.07) 0, rgba(6, 18, 53, 0.07) 100%);
    background: linear-gradient(90deg, rgba(0, 16, 48, 0.07), rgba(6, 18, 53, 0.07));
    border-radius: 2px;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 5px 5px 0 0;
  }

  .window__file-name {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    color: rgba(0, 16, 48, 0.7);
    opacity: 0.5;
  }

  .window__cancel-file {
    width: 7px;
    height: 7px;
    margin-left: 10px;
    text-decoration: none;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/grey_cross.svg);
  }

  .table__column--id,
  .table__column-player-number {
    width: 120px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .table__column--id:before {
    content: "Dispute ID";
  }

  .table__column--event-id {
    width: 100%;
  }

  .table__column--event-id:before {
    content: "Event ID";
  }

  .table__column--event {
    width: 250%;
  }

  .table__column--event:before {
    content: "Event";
  }

  .table__column--round {
    width: 80%;
  }

  .table__column--round:before {
    content: "Round";
  }

  .table__column--resolved {
    width: 150px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .table__column--resolved:before {
    content: "Resolved";
  }

  .table__column--buttons {
    width: 394px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .table__column--player {
    width: 180%;
  }

  .table__column--explanation {
    width: 300%;
  }

  .table__column--button {
    width: 150px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .table__column-label-text {
    font-size: 15px;
    line-height: 17px;
    margin: 0 0 0 7px;
  }

  .table__column--resolved div,
  .table__column-label-text {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    color: #fff;
  }

  .table__column--resolved div {
    width: 75px;
    height: 30px;
    border-radius: 2px;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    background: hsla(0, 0%, 100%, 0.1);
  }

  .table__resolved_yes {
    background: rgba(108, 208, 0, 0.2);
    color: #6cd000;
  }

  .table__column--explanation:before,
  .explanation:before {
    content: "Explantation";
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 12px;
    color: #fff;
    opacity: 0.36;
    margin-right: 20px;
  }

  .table__column--player-number {
    opacity: 0.6;
    font-size: 15px;
    font-family: BurbankSmall-Medium, sans-serif;
  }

  .table__user-profile-picture {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }

  .table__username {
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 23px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.05em;
    color: #fff;
    text-decoration: none;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    margin-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table__username:hover {
    text-decoration: none;
    color: #49c3fd;
  }

  .table__column--explanation a {
    color: #fff;
    text-decoration: none;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .table2:hover {
    background: -webkit-gradient(linear, left top, right top, from(#a91493), to(#640d9a));
    background: -o-linear-gradient(left, #a91493 0, #640d9a 100%);
    background: linear-gradient(90deg, #a91493, #640d9a) !important;
  }

  .table2 {
    background: -webkit-gradient(linear, left top, right top, from(#cb25b3), to(#7d2eae));
    background: -o-linear-gradient(left, #cb25b3 0, #7d2eae 100%);
    background: linear-gradient(90deg, #cb25b3, #7d2eae) !important;
  }

  .table1 {
    background: #3c3daa !important;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .table__column--explanation a:hover {
    color: #49c3fd;
    text-decoration: underline;
  }

  .table__column--explanation:before {
    content: "Explantation";
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 12px;
    color: #fff;
    opacity: 0.36;
    margin-right: 20px;
  }

  .table__make-winner {
    width: 158px;
    height: 36px;
    border: 1px solid #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    opacity: 0.5;
    font-family: Burbank-Big-Cd-Bd, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #fff;
    background: rgba(0, 0, 0, 0);
    outline: none;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .table__make-winner:hover {
    background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
    background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
    background: linear-gradient(90deg, #02b7fe, #0389fd);
    border: none;
    opacity: 1;
  }

  .table__checkbox:checked ~ .table__main-row .table__view-listing {
    background: -webkit-gradient(linear, left top, right top, from(#cb25b3), to(#7d2eae));
    background: -o-linear-gradient(left, #cb25b3 0, #7d2eae 100%);
    background: linear-gradient(90deg, #cb25b3, #7d2eae);
  }

  .table__checkbox:checked ~ .table__main-row .table__view-listing:hover {
    background: -webkit-gradient(linear, left top, right top, from(#a91493), to(#640d9a));
    background: -o-linear-gradient(left, #a91493 0, #640d9a 100%);
    background: linear-gradient(90deg, #a91493, #640d9a);
  }

  .table__checkbox:checked ~ .table__main-row .table__dispute-chat {
    background: #3c3daa;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .table__checkbox:checked ~ .table__main-row .table__dispute-chat:hover {
    background: #373895;
  }

  .window__header {
    font-family: BurbankSmall-Medium, sans-serif;
    letter-spacing: 0;
    font-size: 21px;
    line-height: 35px;
    margin-bottom: 35px;
  }

  .window__subheader {
    margin-top: 0;
  }

  .table__column-label {
    width: 15px;
    height: 15px;
    opacity: 0.4;
    border: 1px solid #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 10px 8px;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    cursor: pointer;
  }

  .table__column-label:last-of-type {
    margin-left: 30px;
  }

  #closed:checked ~ .row .table__column-label--closed,
  #open:checked ~ .row .table__column-label--open {
    background-color: #6cd000;
    background-image: url(#{$cdn}/img/ok.svg);
    opacity: 1;
    border: 1px solid gray;
  }

  .table__column-label--closed,
  .table__column-label,
  .table__column-label--open {
    border: 1px solid gray;
  }

  .table__column--winner {
    opacity: 1;
  }

  .table__winner {
    width: 90px;
    height: 30px;
    background: rgba(249, 209, 0, 0.2);
    border-radius: 2px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: #f9d100;
  }

  @media screen and (max-width: 991.98px) {
    .table__column--buttons {
      position: absolute;
      right: 0;
      top: 20px;
      width: 384px;
    }
    .table__headers-row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .table__headers-row-1 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .table__headers-row .table__column:not(.table__column--buttons) {
      display: none;
    }
    .table__headers-row .table__column--buttons {
      width: 100%;
      padding: 0 15px;
    }
    .table__secondary-row-container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media screen and (max-width: 767.98px) {
    .table__main-row .table__column--buttons {
      position: relative;
      top: 0;
    }
  }

  @media screen and (max-width: 1819.98px) and (min-width: 1300px) {
    .table__column--button {
      width: 170px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
    }
    .table__secondary-row-container .table__column--buttons {
      display: none;
    }
    .table__column--explanation {
      width: 400%;
    }
  }

  @media screen and (max-width: 1379.98px) and (min-width: 1300px) {
    .table__column {
      padding: 0 10px;
      font-size: 15px;
    }
    .table__column:first-of-type {
      padding: 0 10px 0 20px;
    }
    .table__column:last-of-type {
      padding: 0 20px 0 10px;
    }
    .table__column--button {
      width: 374px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
    }
    .table__column--id,
    .table__column--player-number {
      width: 100px;
    }
  }

  @media screen and (max-width: 1579.98px) {
    .table__make-winner {
      width: 140px;
    }
  }

  @media screen and (max-width: 1359.98px) {
    .table__column--explanation:before {
      font-size: 14px;
      margin-right: 10px;
    }
    .table__username {
      font-size: 15px;
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 506.98px) {
    .window__button {
      width: 100%;
    }
    .window__button--filled {
      margin-left: 0;
    }
  }

  .table__column {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .table__column--resolved .no {
    background: #b6182054;
    color: red;
  }

  .table__column--button {
    width: 11em;
  }

  .table__column.table__column--player-number.table__column--winner {
    overflow: visible;
    position: relative;
    right: 50px;
  }

  .adm .article__tab-menu-before-container {
    margin: 50px 0 20px;
    border-bottom: 1px solid rgba(0, 16, 48, 0.1);
  }

  .adm .tab-menu__link--active.adm .tab-menu__link--notification:after {
    background: #49c3fd;
    opacity: 1;
  }

  .adm .table__secondary-row-container {
    max-height: 0;
    height: auto;
    overflow: hidden;
    -webkit-transform: translateY(-50%) scaleY(0);
    -ms-transform: translateY(-50%) scaleY(0);
    transform: translateY(-50%) scaleY(0);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background: #fff;
    z-index: 1;
    padding: 0;
  }

  .adm .table__main-row,
  .table__secondary-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    padding: 0;
  }

  .table__secondary-row {
    height: 50px;
  }

  .table__checkbox,
  .table__checkbox-hide,
  .table__radio {
    display: none;
  }

  .table__radio-label {
    width: 14px;
    height: 15px;
    opacity: 0.4;
    border: 1px solid #d5dae0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 24%);
    background-size: 10px 8px;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    cursor: pointer;
    margin: 0 0 3px;
  }

  .table__radio:checked + .row .table__radio-label {
    background-color: #3c3daa;
    border: 1px solid #3c3daa;
    background-image: url(#{$cdn}/img/ok.svg);
    opacity: 1;
  }

  .table__radio-text {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 15px;
    color: #001030;
    opacity: 0.5;
    margin: 0 0 0 10px;
  }

  .table .article__column-label:last-of-type {
    margin-left: 30px;
  }

  .adm .table__column {
    color: #001030;
    font-size: 16px;
    line-height: 23px;
    font-family: Burbank-Big-Rg-Md, sans-serif;
    letter-spacing: 0.05em;
    padding: 0 20px;
  }

  .adm .table__column--resolved div,
  .adm .table__column-label-text {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    color: #c4c9d2;
  }

  .adm .table__open,
  .adm .table__close {
    display: inline-block;
    position: relative;
    width: 44px;
    height: 40px;
    border: 1px solid #dde3ec;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin: 0;
    vertical-align: middle;
  }

  .activestatus {
    background: #6cd000;
    border: none;
    color: #fff;
  }

  .green-button:hover {
    background: #6cd000;
    color: #fff;
    text-decoration: none;
  }

  .adm a.activestatus {
    border: none;
    color: #fff;
  }

  .adm .grey-button--filled {
    background: #f1f5fa;
    border: none;
    color: #bccadb;
    opacity: 1;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  .adm-grey-button--filled {
    background: #f1f5fa;
    border: none;
    color: #bccadb;
    opacity: 1;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  .adm .table__hide {
    display: inline-block;
    position: relative;
    width: 44px;
    height: 40px;
    border: 1px solid #dde3ec;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin: 0 10px 0 0;
    vertical-align: middle;
  }

  .adm .table__hide:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-position: 0%;
    opacity: 1;
    background-size: 20px 20px;
    top: 0px;
    left: 5px;
    background-image: url(#{$cdn}/img/dark_hide.svg);
  }
  .adm .table__show {
    display: inline-block;
    position: relative;
    width: 44px;
    height: 40px;
    border: 1px solid #dde3ec;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin: 0 10px 0 0;
    vertical-align: middle;
  }

  .adm .table__show:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-position: 0%;
    opacity: 1;
    background-size: 20px 20px;
    top: 0px;
    left: 5px;
    background-image: url(#{$cdn}/img/dark_visible.svg);
  }
  .select-redem {
    position: relative;
    overflow: visible;
    height: 34px;
    padding: 1.5rem;
    color: #001030;
    border-radius: 4px;
    min-width: fit-content;
  }

  .select-redem option {
    padding: 1.5rem;
    color: #001030;
  }

  .table__button {
    width: 140px;
    height: 40px;
    margin-right: 10px;
  }

  .table__button:last-of-type {
    margin-left: 0;
  }

  .table__warning-text {
    height: auto;
    padding: 10px 9px;
    background: #e13b4c;
    color: #fff;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    width: 236px;
  }

  .adm .table__warning-content:before {
    content: "";
    display: block;
    bottom: 2px;
    width: 0;
    height: 0;
    border: 5px solid rgba(0, 0, 0, 0);
    border-top: 3px solid #e13b4c;
  }

  .table__checkbox:checked ~ .table__main-row {
    border-bottom: 1px solid #e8ebef0f;
  }

  .table__checkbox-hide:checked + .row .table__hide:before {
    background-size: 20px 12px;
    background-image: url(#{$cdn}/img/dark_visible.svg);
  }

  .table__column--date {
    width: 85%;
  }

  .table__column--date:before {
    content: "Date";
  }

  .table__column--username {
    width: 180%;
  }

  .table__column--amount {
    width: 70%;
  }

  .table__column--amount:before {
    content: "Amount";
  }

  .table__column--method {
    width: 150%;
  }

  .table__column--method:before {
    content: "Payment method";
  }

  .table__column--tournament {
    width: 200%;
  }

  .table__column--tournament:before {
    content: "Tournament";
  }

  .table__column--status {
    width: 160px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .table__column--status:before {
    content: "Status";
  }

  .table__column--buttons {
    width: 288px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .table__column--first-name::before {
    content: "First name";
  }
  .first-name-column::after {
    content: "First name";
    color: #b6b6c3;
  }

  .table__column--last-name:before {
    content: "Last name";
  }
  .last-name-column::after {
    content: "Last name";
    color: #b6b6c3;
  }

  .table__column--country:before {
    content: "Country";
  }
  .country-column::after {
    content: "Country";
    color: #b6b6c3;
  }

  .table__column--city:before,
  .city-column::after {
    content: "City";
  }
  .city-column::after {
    content: "City";
    color: #b6b6c3;
  }

  .table__column--address:before,
  .address-column::after {
    content: "Street address";
  }
  .address-column::after {
    color: #b6b6c3;
    content: "Street address";
  }
  .table__column--code:before {
    content: "Zip / Postal Code";
  }
  .code-column::after {
    content: "Zip / Postal Code";
    color: #b6b6c3;
  }

  .table__column--state:before {
    content: "Province/State";
  }
  .state-column::after {
    color: #b6b6c3;
    content: "Province/State";
  }

  .table__column--canada-usa:before {
    content: "Canada/USA";
  }

  .table__row {
    position: relative;
  }

  .table__checkbox:checked ~ .table__main-row .table__change-status {
    background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
    background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
    background: linear-gradient(90deg, #02b7fe, #0389fd);
    color: #fff;
  }

  .table__checkbox:checked ~ .table__main-row .table__change-status:hover {
    background: -webkit-gradient(linear, left top, right top, from(#13a9e4), to(#0a71cb));
    background: -o-linear-gradient(left, #13a9e4 0, #0a71cb 100%);
    background: linear-gradient(90deg, #13a9e4, #0a71cb);
  }

  .table__secondary-row {
    height: 100px;
    padding: 0 20px;
  }

  .table__secondary-row .table__column {
    font-size: 15px;
    width: auto;
    padding: 0 0 0 10px;
  }

  .table__secondary-row .table__column--title {
    padding: 0;
  }

  .table__secondary-row .table__column .table__column {
    height: 50px;
    margin-right: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .table__secondary-row-columns-container,
  .table__secondary-row .table__column .table__column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .table__secondary-row-columns-container {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-right: 80px;
  }

  .table__secondary-row-columns-container:last-of-type {
    margin-right: 0;
  }

  .table__select {
    width: 121px;
    height: 40px;
  }

  @media screen and (max-width: 991.98px) {
    .adm .tab-menu {
      height: 100%;
    }
    .adm .tab-menu__link {
      padding: 0 0 15px;
    }
    .article__radio-container,
    .adm .tab-menu__events-container {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
    .article__radio-container {
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 768px) {
    .article__tab-menu-button {
      display: block;
    }
    .article__tab-menu-container {
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
      -webkit-align-items: flex-end;
    }
    .adm .tab-menu {
      height: 100%;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 1499.98px) and (min-width: 0) {
    .table__column--username {
      -webkit-box-ordinal-group: -1;
      -ms-flex-order: -2;
      order: -2;
      width: 100%;
    }
    .table__column--tournament {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
      width: 100%;
    }
  }

  @media screen and (max-width: 1499.98px) and (min-width: 1200px) {
    .table__column--buttons {
      position: absolute;
      right: 20px;
      top: 9px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
    }
    .table__secondary-row-columns-container {
      margin-right: 0px;
    }
    .table__secondary-row-columns-container:last-of-type {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1199.98px) and (min-width: 768px) {
    .table__column--buttons {
      position: relative;
      left: 0;
      top: 0;
    }
    .table__secondary-row-columns-container {
      margin-right: 0;
      width: 50%;
    }
  }

  @media screen and (max-width: 767.98px) and (min-width: 0) {
    .table__column--buttons {
      position: relative;
      left: 0;
      top: 0;
    }
    .table__secondary-row-columns-container {
      margin-right: 0;
      width: 100%;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .table__secondary-row .table__column {
      padding: 0;
    }
    .table__secondary-row .table__column .table__column {
      height: 35px;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
    .table__secondary-row .table__column--title .table__column {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
  }

  .table__row {
    background: hsla(0, 0%, 100%, 0.1);
    margin-bottom: 15px;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .table__headers-row,
  .table__row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .table__secondary-row-container {
    max-height: 0;
    height: auto;
    overflow: hidden;
    -webkit-transform: translateY(-50%) scaleY(0);
    -ms-transform: translateY(-50%) scaleY(0);
    transform: translateY(-50%) scaleY(0);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background: hsla(0, 0%, 100%, 0.1);
    z-index: 1;
    padding: 0;
  }

  .table__secondary-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .table__main-row:hover {
    background: rgba(255, 255, 255, 0);
  }

  .article .table__main-row {
    position: relative;
    background: rgba(255, 255, 255, 0);
    z-index: 2;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0);
  }

  .table__secondary-row {
    height: 50px;
  }

  .table__checkbox,
  .table__checkbox-hide {
    display: none;
  }

  .f-right {
    float: right;
  }

  .table__column--date {
    width: 30em;
  }

  .table__column--time {
    width: 30em;
  }

  .table__column--event-id {
    width: 30em;
  }

  @media screen and (max-width: 1869.98px) {
    .table__column--buttons {
      width: 384px;
    }
  }

  .table__column--buttons {
    width: 600px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .table__column--tournament {
    width: 178em;
    padding-right: 0px;
  }

  .table__column--tournament:before {
    content: "Tournament";
  }

  .table__column--user {
    width: 1240%;
  }

  .table__column--date {
    width: 120em;
  }

  .table__column--date:before {
    content: "Date";
  }

  .table__column--button {
    width: 27em;
  }

  .table__column--time {
    width: 120%;
  }

  .table__column--time:before {
    content: "Time";
  }

  .table__column--event-id {
    width: 100%;
  }

  .table__column--event-id:before {
    content: "Event ID";
  }

  .table__secondary-row-container1 {
    background: hsla(0, 0%, 100%, 0.1);
    -webkit-transform: translate(0) scaleY(1);
    -ms-transform: translate(0) scaleY(1);
    transform: translate(0) scaleY(1);
    max-height: 500px;
    padding: 15px 0;
    border-top: 1px solid rgba(128, 128, 128, 0.178);
  }

  .table__column--buttons {
    width: 600px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .table__secondary-row .table__column--user:before {
    display: inline-block;
  }

  @media screen and (max-width: 1869.98px) {
    .table__column--buttons {
      width: 384px;
    }
  }

  @media screen and (max-width: 1199.98px) and (min-width: 0) {
    .table__column--tournament {
      width: 30em;
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
    .table__column--buttons {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }
    .table__secondary-row-container {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  @media screen and (max-width: 469.98px) {
    .table__button {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }
    .table__button:first-of-type {
      margin-top: 0;
    }
    .table__open {
      margin: 10px 0 0;
    }
    .table__column--user:before {
      width: 100%;
      margin-bottom: 5px;
      margin-right: 0;
    }
  }

  @media screen and (max-width: 469.98px) {
    .table__button:first-of-type {
      margin-top: 0;
    }
  }

  .table__button:first-of-type {
    margin-left: 0;
  }

  @media screen and (max-width: 440px) {
    .table__button:first-of-type {
      margin-top: 0;
    }
  }

  .table__button {
    width: 140px;
    height: 40px;
    margin-left: 10px;
  }

  /* .................pagination css..................... */

  /* React Player -------------------- */

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .bil_modal-container .window__header {
    font-weight: 500;
    font-size: 21px;
    line-height: 35px;
  }

  .bil_modal-container--small .bil_modal-container__window {
    max-width: 490px;
    width: 100%;
  }

  .bil_modal-container__window {
    height: auto;
    max-height: calc(100% - 100px);
    overflow: auto;
  }

  .bil_modal-container__window .window__container {
    position: static;
    margin: 0 auto;
    padding: 0 20px 25px 20px;
  }

  .bil_modal-container__window .window__header {
    margin-bottom: 30px;
  }

  .bil_modal_btns {
    margin: 10px -5px 10px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
  }

  .bil_modal_btns_i {
    width: 180px;
    max-width: 180px;
    padding: 5px 5px;
  }

  .bttn_blue-outline {
    position: static;
    padding: 2px 0 0;
    display: block;
    width: 100%;
    height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    border: 1px solid #49c3fd;
    border-radius: 2px;
    font-family: "Burbank-Big-Cd-Bd";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #49c3fd;
  }

  .background-color {
    background-color: transparent;
  }

  .custom-close {
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 25px;
    z-index: 99;
  }

  div#live-events-filters {
    z-index: 999999;
  }

  .article__back {
    position: relative;
    display: block;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    padding-left: 27px;
    margin-bottom: 50px;
  }

  .article__back,
  .article__back:hover {
    text-decoration: none;
    color: #fff;
  }

  .article__back:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 3px;
    width: 17px;
    height: 14px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/arrow_left.svg);
  }

  .article__containers-row {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  /* .................pagination css..................... */

  .modal-container--opened {
    display: block;
  }

  .modal-container__background {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 50%;
    top: 50%;
    background: #051534;
    opacity: 0.7;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .modal-container--opened .modal-container__background {
    width: 100%;
    height: 100%;
    -webkit-animation: showBackground 0.35s ease-in-out;
    animation: showBackground 0.35s ease-in-out;
  }

  .window__container {
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    top: 75px;
    left: 50px;
    padding: 30px 20px 10px;
  }

  .window__header-container {
    padding: 40px 70px 0;
  }

  .window__header {
    font-size: 21px;
    line-height: 30px;
    color: #001030;
    margin-bottom: 30px;
  }

  .window__header,
  .window__subheader {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
  }

  .window__subheader {
    font-size: 14px;
    line-height: 14px;
    color: rgba(0, 16, 48, 0.7);
    margin-bottom: 12px;
  }

  .window__textarea {
    height: 144px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 16, 48, 0.05)), to(rgba(6, 18, 53, 0.05)));
    background: -o-linear-gradient(left, rgba(0, 16, 48, 0.05) 0, rgba(6, 18, 53, 0.05) 100%);
    background: linear-gradient(90deg, rgba(0, 16, 48, 0.05), rgba(6, 18, 53, 0.05));
    resize: none;
    padding: 20px;
  }

  .window__input,
  .window__textarea {
    width: 100%;
    border-radius: 4px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #001030;
    margin-bottom: 20px;
    outline: none;
    border: none;
  }

  .window__input {
    height: 45px;
    background: rgba(0, 16, 48, 0.05);
    padding-left: 20px;
  }

  .window__file-input {
    display: none;
  }

  .window__file {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #49c3fd;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .window__file:before {
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/file.svg);
    margin-right: 7px;
  }

  .window__file-label {
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 16, 48, 0.07)), to(rgba(6, 18, 53, 0.07)));
    background: -o-linear-gradient(left, rgba(0, 16, 48, 0.07) 0, rgba(6, 18, 53, 0.07) 100%);
    background: linear-gradient(90deg, rgba(0, 16, 48, 0.07), rgba(6, 18, 53, 0.07));
    border-radius: 2px;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 5px 5px 0 0;
  }

  .window__file-name {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    color: rgba(0, 16, 48, 0.7);
    opacity: 0.5;
  }

  .window__cancel-file {
    width: 7px;
    height: 7px;
    margin-left: 10px;
    text-decoration: none;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/grey_cross.svg);
  }

  .window__select {
    width: 100%;
    height: 45px;
    margin-bottom: 20px;
  }

  .window__button {
    margin: 20px 5px 0;
  }

  .window__hr {
    display: block;
    height: 1px;
    background: #f2f3f5;
    margin: 5px 0 30px;
    width: 100%;
  }

  .window__warning:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/grey_warning.svg);
    opacity: 0.2;
    margin-right: 7px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .window__warning div {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #001030;
    opacity: 0.5;
  }

  .window--multi {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    left: 50%;
  }

  .window--multi.window--prev {
    left: -50%;
  }

  .window--multi.window--next {
    left: 150%;
  }

  .window__close {
    display: block;
    text-decoration: none;
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 0;
  }

  .window__close:after,
  .window__close:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 7px;
    background: #333;
    border-radius: 1px;
  }

  .window__close:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .window__close:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .window__checkbox,
  .window__warning-checkbox {
    display: none;
  }

  .window__label {
    width: 120px;
    height: 45px;
    background: rgba(0, 16, 48, 0.05);
    border-radius: 4px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.05em;
    color: rgba(0, 16, 48, 0.5);
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    margin-right: 5px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .window__checkbox:checked + .window__label {
    border: 1px solid #49c3fd;
    color: #49c3fd;
  }

  .window__profile-status {
    position: relative;
    display: block;
    width: 127px;
    height: 27px;
    background: #e13b4c;
    border-radius: 67px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 27px;
    color: #fff;
    padding: 1px 0 0 17px;
    cursor: pointer;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    z-index: 2;
    text-transform: capitalize;
  }

  .window__profile-status--open {
    background: #6cd000;
  }

  .window__profile-status:before {
    width: 10px;
    height: 13px;
    content: "";
    position: absolute;
    left: 20px;
    top: 7px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/locked.svg);
  }

  .window__profile-status--open:before {
    background-image: url(#{$cdn}/img/unlocked.svg);
  }

  .window__profile-status-warning {
    position: relative;
    width: 100%;
    background: #fc5566;
    border-radius: 2px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #fff;
    padding: 10px 5px;
  }

  .window__profile-status-warning a {
    font-family: Burbank-Big-Cd-Md, sans-serif;
    font-weight: 700;
  }

  .window__profile-status-warning a,
  .window__profile-status-warning a:hover {
    color: #fff;
    text-decoration: underline;
  }

  .window__checkbox {
    display: none;
  }

  .article__content {
    padding-right: 39px;
  }

  @media screen and (max-width: 1499.98px) {
    .article__content {
      overflow: visible;
    }
    .article__containers-row {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 767.98px) and (min-width: 576px) {
    .article__content {
      padding: 80px 50px;
    }
  }

  @media screen and (max-width: 575.98px) and (min-width: 0) {
    .article__content {
      padding: 60px 30px;
    }
  }

  @media screen and (max-width: 1199.98px) {
    .orders__table-headers {
      padding-right: 146px;
    }
  }

  @media screen and (max-width: 991.98px) {
    .orders__table-headers {
      padding-right: 100px;
    }
    .order__price {
      width: 70px;
    }
    .order__organizer {
      margin: 10px 0 0;
    }
    .order__organizer:before {
      margin-bottom: 7px;
    }
  }

  @media screen and (max-width: 767.98px) {
    .orders__table-headers {
      display: none;
    }
    .order--participant:after,
    .order--viewer:after {
      bottom: 20px;
    }
    .order--participant:before,
    .order--viewer:before {
      bottom: 48px;
    }
    .order__remove {
      top: 15px;
      right: 12px;
    }
    .order__remaining {
      margin: 12px 0 0;
    }
    .right-menu1 {
      padding: 0;
    }
  }

  @media screen and (max-width: 567.98px) {
    .order--participant:after,
    .order--viewer:after {
      font-size: 13px;
    }
    .order--viewer:after {
      width: 130px;
    }
    .order--participant:after {
      width: 150px;
    }
  }

  @media screen and (max-width: 767.98px) {
    .order__picture {
      width: 100%;
      height: auto;
    }
  }

  .payment__radio:checked + .payment__label {
    border: 1px solid #49c3fd;
    color: #49c3fd;
  }

  .col-12.col-sm-4.col-md-12.align-items-center {
    padding: 0px 10px 10px 10px;
    border: 1px solid #1e2c48;
    border-radius: 7px;
    margin: 20px 7px;
    background: #48379829;
  }

  .payment__radio:checked + .payment__label--credit {
    border: 1px solid #49c3fd;
    color: #49c3fd;
  }

  @media screen and (max-width: 1700px) {
    .payment,
    .summary {
      height: auto;
      overflow: visible;
      position: relative;
      -ms-flex-negative: 0;
      flex-shrink: 0;
    }
    .payment--next {
      position: relative;
      right: 100%;
    }
    .payment {
      right: 0;
    }
    .article__content {
      overflow: visible;
    }
    .article__containers-row {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }

  .article__back,
  .article__back:hover {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
  }

  @media screen and (max-width: 1499.98px) {
    .article__containers-row {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 1700px) {
    .article__content {
      overflow: visible;
    }
  }

  @media screen and (max-width: 1700px) {
    .article__content,
    .right-menu0 {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 575.98px) and (min-width: 0) {
    .article__content,
    .right-menu0 {
      padding: 60px 30px;
      /* padding: 10px ; */
    }
  }

  @media screen and (max-width: 1499.98px) {
    .article__content {
      overflow: visible;
    }
  }

  @media screen and (max-width: 1499.98px) {
    .article__content,
    .right-menu0 {
      width: 100%;
      height: auto;
    }
  }

  .custom_input_payment {
    background: transparent;
    border: transparent;
    color: white;
    font-size: 20px;
    font-family: sans-serif;
  }

  .custom_input_payment {
    background: transparent;
    border: transparent;
    color: white;
    font-size: 20px;
    font-family: sans-serif;
  }

  .no-padding {
    padding: 0px;
  }

  .article__back:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 3px;
    width: 17px;
    height: 14px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/arrow_left.svg);
  }

  .article__header-container {
    padding-bottom: 40px;
    margin-bottom: 50px;
  }

  .article__header-container-ticket {
    padding-bottom: 40px;
    margin-bottom: 50px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
  }

  section.article__summary.summary {
    vertical-align: middle;
    margin: auto;
    width: 100%;
  }

  .profile-modal.profile-modal-medium.disable-premium.modal-container__window.window {
    min-height: 12vh;
    width: 40vw;
  }

  .bg {
    background: #2c2186;
  }

  .bg-trans {
    background: transparent;
  }

  .pageSeting {
    padding-left: 25%;
    padding-right: 25%;
  }

  .bg-main {
    background: #e5e5e5;
    min-height: 100vh;
  }

  .greeting {
    padding-top: 12px;
    color: #ffffff;
    font-family: "BurbankSmall-Bold", sans-serif;
    font-size: 19px;
    line-height: 22px;
    text-align: center;
    font-weight: bold;
  }

  .greetingText {
    padding: 6px;
    color: #ffffff;
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-size: 17px;
    letter-spacing: 0.05em;
    padding-bottom: 26px;
    line-height: 22px;
    text-align: center;
    padding-bottom: 26px;
  }

  .infotext {
    padding-right: 55px;
    padding-left: 55px;
    padding-bottom: 25px;
    color: #001030;
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-size: 15px;
    line-height: 26px;
    opacity: 0.8;
    text-align: center;
    letter-spacing: 0.05em;
    font-weight: 400;
  }

  .bluebtn {
    color: #ffffff;
    text-decoration: none;
    margin: 0 10px;
    background: linear-gradient(153.03deg, #012065 0%, #5623a7 100%);
    width: 203px;
    display: block;
    padding-top: 14px;
    padding-bottom: 14px;
    border: 1px solid #012065;
    font-family: " ", sans-serif;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;
  }

  .secondbtn {
    color: #d85d5c;
    text-decoration: none;
    margin: 0 10px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(37, 50, 78, 0.05);
    border: 1px solid #cb25b3;
    border-radius: 2px;
    width: 203px;
    display: block;
    padding-top: 14px;
    padding-bottom: 14px;
    font-family: " ", sans-serif;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;
  }

  .goodluck {
    color: #001030;
    font-family: "BurbankSmall-Medium", sans-serif;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-weight: 400;
  }

  .fotertext {
    padding-bottom: 18px;
    color: #001030;
    font-family: "BurbankSmall-Bold", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-weight: 600;
  }

  .padding-15 {
    padding: 2em;
  }

  .ordersummary {
    padding-bottom: 5px;
    color: #3c2293;
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.05em;
    float: left;
    width: 50%;
    display: block;
    text-align: left;
  }

  .datetext {
    padding-bottom: 5px;
    color: rgba(0, 16, 48, 0.5);
    opacity: 0.8;
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: center;
    float: left;
    width: 50%;
    display: block;
    text-align: right;
  }

  .orderno {
    color: rgba(0, 16, 48, 0.8);
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-bottom: 5px;
  }

  .itemtext {
    padding: 6px 0;
    color: rgba(51, 64, 89, 0.7);
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
  }

  .totalamount {
    padding: 6px 0;
    color: #cb25b3;
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-size: 16px;
    line-height: 16px;
    text-align: right;
  }

  .policytext {
    padding: 5px 0 0;
    color: #334059;
    font-family: "BurbankSmall-Medium", sans-serif;
    font-size: 12px;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.04em;
  }

  .policytext span {
    color: #cb25b3;
  }

  .pageSeting hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 768px) {
    .pageSeting {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }
  }

  .icondiv {
    font-size: 0pt;
    line-height: 0pt;
    text-align: left;
    padding: 0 3.5px;
    border: 1px solid #80808054;
    padding: 15px 8px;
    border-radius: 100%;
    background: #80808054;
    margin: 2px;
  }

  .transactions-all .article__tab-menu-before-container {
    margin: 56px 0px;
    border-bottom: 1px solid #25324e;
    width: 100%;
  }

  .transactions-all .article__tab-menu-container {
    position: relative;
    margin: 0;
    width: 100%;
  }

  .transactions-all .table__column {
    color: #fff;
    font-size: 16px;
    line-height: 23px;
    font-family: Burbank-Big-Rg-Md, sans-serif;
    letter-spacing: 0.05em;
    padding: 0 20px;
    overflow: visible;
  }

  .transactions-all .article__tab-menu-button {
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 23px;
    width: 25px;
    height: 24px;
    border: none;
    background-size: 20px 12px;
    background-image: url(#{$cdn}/img/arrow_top-dark.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .transactions-all .table__radio {
    width: 15px;
    height: 15px;
    border: 1px solid #5f6d8b;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    background-size: 9px 8px;
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    margin-right: 13px;
    margin-bottom: 1px;
  }

  #transactionall .tab-menu__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    width: 180px;
    height: 70px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-decoration: none;
    color: hsla(0, 0%, 100%, 0.5);
    background: #1e2c48;
    margin-right: 10px;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    padding-bottom: 0px;
  }

  #transactionall .tab-menu__link--active {
    background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
    background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
    background: linear-gradient(90deg, #02b7fe, #0389fd);
    color: #fff;
  }

  .adm .tab-menu__link {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    width: 180px;
    height: 41px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-decoration: none;
    color: #001030;
    margin-right: 10px;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    padding-bottom: 0px;
    opacity: 0.7;

    @media screen and (max-width: 768px) {
      width: 50%;
      padding-bottom: 15px;
      height: auto;
    }
  }

  .adm .tab-menu__link:hover {
    text-decoration: none;
    color: #001030;
    opacity: 1;
  }

  .adm .tab-menu__link--active {
    color: #49c3fd;
    opacity: 0.7;

    @media screen and (max-width: 768px) {
      padding-bottom: 15px;
    }
  }

  .transactions-all label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  .adm .tab-menu__link--active:hover {
    color: #49c3fd;
    opacity: 1;
  }

  .article .table__radio-text {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 15px;
    color: white;
    opacity: 0.5;
    margin: 0 0 0 10px;
  }

  #closed:checked ~ .row .table__column-label--closed,
  #open:checked ~ .row .table__column-label--open {
    background-color: #6cd000;
    background-image: url(#{$cdn}/img/ok.svg);
    opacity: 1;
    border: 1px solid gray;
  }

  #transactionpart.summary {
    position: relative;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 25vh 6vw;
    transition: 350ms;
    width: 100%;
    overflow: visible;
    max-height: 100%;
    min-height: -webkit-fill-available;
    background: linear-gradient(103.91deg, #012065 0%, #5623a7 100%);
  }

  #transactionpart1.upload-btn-wrapper input[type="file"] {
    width: 1px;
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 1px;
  }

  .withdraw__balance {
    width: 180px;
    height: 82px;
    border: 1px solid #6cd000;
    box-sizing: border-box;
    border-radius: 2px;
    text-align: center;
    margin-left: 35px;
    margin-right: 35px;
  }

  .member {
    position: relative;
    background: hsla(0, 0%, 100%, 0.1);
    padding: 20px 20px 0;
    margin: 35px 0 5px;
  }

  .adm_content.article,
  .adm_content .article {
    width: 100%;
    background: #f5f7fa;
  }

  .adm_content .registration__header,
  .adm_content .registration__time {
    font-style: normal;
    letter-spacing: 0.05em;
    color: #001030;
  }

  .adm_content .registration__players span {
    color: #001030;
    font-size: 20px;
  }

  .adm_content .article-detail__container {
    background: #fff;
    width: 100%;
  }

  .adm_content .article__detail-title {
    font-size: 13px;
    line-height: 13px;
    color: #21252994;
    margin-bottom: 8px;
  }

  .adm_content .article__detail-text {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 14px;
    color: #051534;
  }

  .adm_content .article__header-container,
  .article .article__header-container {
    min-height: 2em;
  }

  .adm_content #haveQuestion {
    color: blue;
  }

  .adm_content .organizer__info {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 25px;
    color: #212529;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .adm_content .organizer__name {
    font-weight: 550;
    font-size: 18px;
    line-height: 18px;
    color: #001030;
  }

  .adm_content .help {
    position: relative;
    padding: 10px 80px;
    margin: 55px 0 70px;
    background: white;
    border-radius: 2px;
  }

  .adm_content .help__header,
  .adm_content .help__text {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    color: #051534c2;
  }

  .adm_content .article-detail__subheader,
  .adm_content .article__subheader,
  .adm .preview__text {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: #001030;
  }

  .adm_content .white-button {
    background: transparent;
    color: #c4c9d2;
    border: 1px solid #c4c9d2;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .adm_content .pfl_info-level_ttl {
    display: block;
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #c4c9d2;
    opacity: 0.5;
  }

  .adm_content .white-button:hover {
    color: gray;
    text-decoration: none;
    background: #00000021;
  }

  .pfl {
    margin-bottom: 0;
  }

  .faq {
    color: #001030;
  }

  @media screen and (max-width: 575.98px) {
    .walkthrough-page.whitebg {
      padding: 20px;
    }
  }

  .faq .p-description {
    margin-bottom: 20px;
    clear: both;
  }

  .p-description.bold,
  .p-description h3 {
    font-family: BurbankSmall-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    color: #001030;
  }

  .whitebg .quick-head {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #3c3daa;
    padding-bottom: 20px;
    padding-top: 30px;
  }

  .quick-head {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;
    color: #3c3daa;
    margin-bottom: 30px;
  }

  h3.spacedH {
    font-family: BurbankSmall-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    color: #001030;
  }

  .page-desc{
    font-family: "BurbankSmall-Medium",sans-serif;
    font-style: normal;
    font-size: 16px;
    letter-spacing: .05em;
    margin-top: 10px;
  }
  .page-head-top {
    font-family: BurbankSmall-Medium;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;
    color: #001030;
  }

  span.p-description.different,
  .faq .p-description {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #001030;
    margin-bottom: 20px;
    display: block;
  }

  .faq .p-description.bold {
    font-family: BurbankSmall-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */
    /* text */
    color: #001030;
  }

  /* .................................................... */

  .whitebg .p-description.different {
    font-size: 18px;
    line-height: 32px;
  }

  .walkthrough-page.contact {
    margin-top: 15px;
  }

  span.bold.pull-right {
    cursor: pointer;
    font-size: 115%;
    color: #001030;
  }

  .faq .page-head {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;
    color: #3c3daa;
    margin-bottom: 30px;
  }

  .faqRow .panel-title a {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #001030;
  }

  .faq .contact .p-description {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #001030;
    opacity: 0.7;
    margin-bottom: 40px;
    display: block;
  }

  .walkthrough-page.contact .contact-content {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #e8ebef;
  }

  .walkthrough-page.contact .contact-content .item {
    width: 100%;
    display: block;
    float: left;
    padding: 30px;
    background: #fff;
    border-radius: 3px;
  }

  .walkthrough-page.contact .contact-content .item:last-child {
    padding-top: 23px;
    padding-bottom: 25px;
  }

  .walkthrough-page .full-width {
    margin-bottom: 40px;
    margin-top: 20px;
    width: 100%;
  }

  .walkthrough-page.contact .contact-content .item:not(:last-child) {
    border-bottom: 1px solid rgba(232, 235, 239, 0.5);
  }

  .walkthrough-page.contact .contact-content .col-xl-6 {
    padding: 10px;
  }

  .walkthrough-page.contact .contact-content .item span.head {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: block;
    color: #001030;
    margin-bottom: 30px;
  }

  .walkthrough-page.contact .contact-content .item span.title {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    display: block;
    color: #001030;
    opacity: 0.6;
  }

  .walkthrough-page.contact .contact-content span.mail a {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #3c3daa;
    margin-left: 10px;
  }

  .walkthrough-page.contact .contact-content span.mail:before {
    content: url(#{$cdn}/img/envelope.png);
    vertical-align: middle;
  }

  i.fa.fa-envelope:after {
    content: url(#{$cdn}/img/envelope.png);
    vertical-align: sub;
  }

  .faq span.card-header {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: block;
    color: #001030;
    margin-bottom: 30px;
  }

  .walkthrough-page.whitebg .quick-head {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #3c3daa;
    padding-bottom: 20px;
    padding-top: 30px;
  }

  .walkthrough-page .quick-head {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;
    color: #001030;
    padding-bottom: 20px;
  }

  .faq .page-head,
  .walkthrough-page .page-head {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;
    color: #3c3daa;
    margin-bottom: 30px;
  }

  .faq .p-description .page-head {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;
    color: #3c3daa;
    margin-bottom: 30px;
    background-color: #ffffff !important;
  }

  .faq .breadcrumbs {
    font-family: Burbank-Big-Rg-Md;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #001030;
    padding-top: 30px;
    margin-bottom: 25px;
    margin-left: 15px;
  }
  .faq .walkthrough-page .faq-desc{
    font-family: "BurbankSmall-Medium",sans-serif;
    font-style: normal;
    font-size: 16px;
    letter-spacing: .05em;
  }

  .walkthrough-page .button-content a {
    height: 40px;
    line-height: 40px;
    display: inline-block;
    background: #6cd000;
    border-radius: 2px;
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    width: 140px;
    font-family: Burbank-Big-Cd-Bd;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    /* or 100% */
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    /* White */
    color: #ffffff;
  }

  .how-toplay .p-description {
    margin-bottom: 25px;
  }

  /* .................................................... */

  .walkthrough-page.whitebg {
    background: #fff;
    padding: 60px 70px;
    margin-bottom: 20px;
    clear: both;
  }

  .p-description span.num {
    float: none !important;
    display: inline-block !important;
    color: #ff9201;
  }

  .whitebg .p-description {
    font-family: BurbankSmall-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #001030;
    margin-bottom: 20px;
    display: block;
  }

  .adm .pfl_title_ttl {
    color: #001030;
    margin: 0;
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 500;
  }

  .section-ttl_ttl {
    margin: 0;
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 16px;
  }

  .adm .pfl_info_col,
  .adm_content .pfl_info {
    background: rgb(255, 255, 255);
  }

  .adm .pfl_info-level .pfl_info-level_num {
    color: #001030;
  }

  t .pfl_info-level .pfl_info-level_ttl {
    color: rgba(0, 16, 48, 0.5);
  }

  .adm .pfl_pers {
    background: #fff;
  }

  .adm .pfl_pers_ttl {
    color: rgba(0, 16, 48, 0.4);
    margin: 0 0 4px;
    font-style: normal;
    font-weight: 550;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
  }

  .adm .pfl_pers_txt {
    color: #001030;
  }

  .pfl_pers_txt {
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 24px;
  }

  .pfl_tran_i {
    padding: 9px 0;
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
    border-bottom: 1px solid #e8eef6;
  }

  .pfl_tran_inn {
    padding: 0 20px;
  }

  .pfl_tran {
    margin: 16px 0 0;
    background: #fff;
    border-radius: 2px;
  }

  .pfl_tran_ttl {
    margin: 10px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #001030;
    font-family: "Burbank-Big-Rg-Md";
    transition: all 0.2s;
  }

  .pfl_tran_price {
    margin: 10px 0;
    font-family: "Burbank-Big-Rg-Md";
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #001030;
    transition: all 0.2s;
  }

  .pfl_tran_btn {
    margin: 10px 0;
    padding: 4px 43px 0;
    height: 36px;
    color: #49c3fd;
    border: 1px solid #49c3fd;
    border-radius: 2px;
    outline: none;
    text-decoration: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    font-family: "Burbank-Big-Cd-Bd";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    transition: all 0.2s;
  }

  .pfl_tran_btn:hover {
    color: #fff;
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  }

  .adm .section-ttl_ttl {
    color: #001030;
  }

  @media screen and (min-width: 400px) {
    .recent-c-row {
      padding: 0 30px 15px;
    }
  }

  .recent-c-row {
    background: rgba(255, 255, 255, 0.1);
    margin: 16px 0 0;
    padding: 0 10px 0;
    border-radius: 2px;
  }

  .recent-c {
    overflow: hidden;
    position: relative;
    margin: 0 -10px;
    padding: 20px 0 19px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    -o-flex-wrap: nowrap;
    flex-wrap: nowrap !important;
    color: #fff;
  }

  .recent-c_r {
    padding: 10px;
    width: 60% !important;
  }
  .recent-c_l {
    padding: 10px;
    width: 40% !important;
  }

  .recent-c_ttl {
    margin: 2px 0 10px;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: #ffffff;
    font-family: "Burbank-Big-Rg-Md";
  }
  .recent-c_ttl-admin {
    margin: 2px 0 10px;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: #000000;
    font-family: "Burbank-Big-Rg-Md";
  }

  .recent-c_r {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
  }

  a.recent-c_img {
    display: block;
    transition: all 0.2s;
  }

  .adm_content .background-video {
    margin: auto;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: visible;
    height: auto;
    width: 100%;
    min-height: 100vh;
  }

  .recent-c_ttl a {
    color: #fff;
    text-decoration: none;
    transition: all 0.2s;
  }
  .recent-c_ttl-admin a {
    color: #000;
    text-decoration: none;
    transition: all 0.2s;
  }

  .recent-c_info {
    margin: 0 -30px 0 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .recent-c_info_i {
    margin: 10px 30px 0 0;
  }

  .recent-c_info_ttl {
    color: rgba(255, 255, 255, 0.4);
  }
  .recent-c_info_ttl-admin {
    color: rgba(0, 16, 48, 0.4);
  }

  .recent-c_info_ttl,
  .recent-c_info_ttl-admin {
    margin: 0 0 2px;
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 550;
    font-size: 12px;
    line-height: 12px;
  }

  .recent-c_info_txt {
    color: #fff;
    font-style: normal;
    font-weight: 550;
    font-size: 13px;
    line-height: 12px;
  }
  .recent-c_info_txt-admin {
    color: #001030;
    font-style: normal;
    font-weight: 550;
    font-size: 13px;
    line-height: 12px;
  }

  .recent-c-col {
    border-color: #e8eef6;
  }

  .recent-c-col {
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .recent-c {
    overflow: hidden;
    position: relative;
    margin: 0 -10px;
    padding: 20px 0 19px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    color: #fff;
  }

  .recent-c_l,
  .recent-c_r {
    padding: 10px;
  }

  .recent-c_img {
    display: block;
    transition: all 0.2s;
  }

  .recent-c-col:last-of-type {
    border-bottom: none;
  }

  .adm .profile_socials {
    margin: 32px 0 0;
  }

  .adm_content .social-links {
    color: #fff;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 6px 0;
  }

  .adm .profile_socials a {
    background: #fff;
    color: #001030;
  }

  .adm_content .social-links a {
    background: #fff;
    color: #001030;
  }

  .adm_content .social-links a {
    width: 35px;
    height: 35px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    color: #fff;
    background: #1e2c48;
    transition: all 0.2s;
    font-size: 14px;
  }

  .adm .profile_socials .social-links a {
    background: #fff;
    color: #001030;
  }

  @media screen and (min-width: 1319.98px) {
    .adm_content .article__header-container {
      margin-right: 35px;
    }
  }

  .adm_content .social-links li {
    margin: 4px 7px 4px 0;
  }

  .article__header-container {
    padding-bottom: 33px;
    padding-bottom: 35px;
    margin-right: 35px;
  }

  #article-comp {
    width: 100%;
  }

  .maincol-tournament-createx,
  .right-menu-ct {
    padding: 40px;
  }

  .admin_article__header {
    font-family: Burbank-Big-Cd-Bd, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #3c3daa;
    margin-right: 15px;
  }

  .admin_article__header {
    font-family: Burbank-Big-Cd-Bd, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0.05em;
    margin-right: 15px;
  }

  .adm in_article__header {
    color: #3c3daa;
  }

  .article-comp .admin_article__header {
    color: #fff;
  }

  .article__header-container,
  #article-comp .article__header-container {
    min-height: 4em;
  }

  .adm .right-menu-ct__stage-link {
    width: 100%;
    text-align: right;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 30px;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #fff;
    height: 30px;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .adm .article__input,
  .adm .article__textarea {
    background: #fff;
    border-radius: 4px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #001030;
    border: none;
    outline: none;
  }

  .adm .article__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    background: #fff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0);
    position: relative;
    cursor: pointer;
  }

  .adm .article__label-text {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: #001030;
    opacity: 0.4;
    margin: 0;
  }

  #cash-prize:checked ~ .row .article__label--cash-prize,
  #live:checked ~ .row .article__label--live,
  #merchandise-prize:checked ~ .row .article__label--merchandise-prize,
  #online:checked ~ .row .article__label--online,
  #repeat-every-day:checked ~ .row .article__label--repeat-every-day,
  #repeat-every-hour:checked ~ .row .article__label--repeat-every-hour,
  .article__checkbox:checked + .article__label {
    border: 1px solid #6acafc;
  }

  .article__height-2 {
    height: 88px;
  }

  .article__width-1 {
    width: 172px;
  }

  .adm .article__label-icon path {
    fill: #001030;
  }

  #cash-prize:checked ~ .row .article__label--cash-prize .article__label-text,
  #live:checked ~ .row .article__label--live .article__label-text,
  #merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-text,
  #online:checked ~ .row .article__label--online .article__label-text,
  #repeat-every-day:checked ~ .row .article__label--repeat-every-day .article__label-text,
  #repeat-every-hour:checked ~ .row .article__label--repeat-every-hour .article__label-text,
  .article__checkbox:checked + .adm .article__label .adm .article__label-text {
    color: #66c3fa;
    opacity: 1;
  }

  .article__height-3 {
    height: 200px;
    margin-bottom: 15px;
  }

  .article__width-2,
  .article__width-2 .react-datepicker__input-container {
    width: 360px;
    margin-right: 15px;
  }

  [role="button"],
  a,
  area,
  button,
  input,
  label,
  select,
  summary,
  textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .article__slides {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    position: relative;
    width: 600%;
  }

  .article__label-director {
    position: absolute;
    display: block;
    right: 10px;
    top: 10px;
    width: 16px;
    height: 16px;
    background: #edf0f4;
    border-radius: 50%;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .m-r--30 {
    margin-right: -30px;
  }

  .article__height-1,
  .article__height-1 .react-datepicker__input-container {
    height: 55px;
  }

  .article__width-0 {
    width: 116px;
    margin-right: 6px;
    margin-top: 21px;
  }

  label {
    margin-bottom: 0;
  }

  .article__right-menu-ct.right-menu-ct {
    position: absolute;
    float: right;
    right: 0px;
    min-height: 100%;
    max-height: 260vh;
    overflow: visible;
    background: #3c3daa;
    padding: 60px;
  }

  .article .article__right-menu-ct.right-menu-ct {
    position: absolute;
    float: right;
    right: 0px;
    min-height: 100%;
    max-height: 260vh;
    overflow: visible;
    background: #25324e;
    padding: 60px;
  }

  .article__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    background: #25324e;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0);
    position: relative;
    cursor: pointer;
  }

  .article .article__label-blocked {
    position: absolute;
    display: block;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    background-color: hsla(0, 0%, 100%, 0.2);
    border-radius: 50%;
    background-size: 8px 11px;
    background-image: url(#{$cdn}/img/white_lock.svg);
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .article .article__label:hover .article .article__label-blocked {
    background-color: #fff;
    background-image: url(#{$cdn}/img/purple_lock.svg);
  }

  .article .article__label-blocked-container {
    z-index: 999;
    display: block;
    position: absolute;
    left: 50%;
    bottom: 20px;
    width: 200px;
    padding-bottom: 7px;
    -webkit-transform: translate(-85%);
    -ms-transform: translate(-85%);
    transform: translate(-85%);
    opacity: 0;
  }

  .article .article__label-icon path {
    fill: #fff;
  }

  .article__label-icon {
    opacity: 0.4;
    margin-bottom: 12px;
  }

  .adm .select__title-ct:before {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 17px;
    width: 10px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/grey_arrow_top.svg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .adm .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
    width: 100%;
  }

  .adm .grey-button,
  .adm_content .grey-button {
    background: rgba(60, 61, 170, 0.05);
    color: #3c3daa;
    border: 1px solid #4b566d;
    opacity: 0.5;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .adm .select__option {
    position: relative;
    height: 30px;
    width: 100%;
    font-size: 13px;
    line-height: 30px;
    font-family: Burbank Small, sans-serif;
    color: #001030;
    cursor: pointer;
    text-align: left;
    padding: 0 0 0 15px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: 0.13s;
    -o-transition: 0.13s;
    transition: 0.13s;
    opacity: 0.5;
  }

  .adm .select__container {
    background-color: white;
    overflow-x: hidden;
  }

  .article-select .select__container {
    overflow-x: hidden;
  }

  .adm .select__option--selected:after {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 10px;
    width: 12px;
    height: 9px;
    /* background-color: rgba(0, 0, 0, 0); */
    /* background-color: #c4c9d2; */
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/blue_ok.svg);
    -webkit-transition: 0.13s;
    -o-transition: 0.13s;
    transition: 0.13s;
    opacity: 1;
  }

  .article .select__option--selected:after {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 10px;
    width: 12px;
    height: 9px;
    /* background-color: rgba(0, 0, 0, 0); */
    /* background-color: #c4c9d2; */
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/ok.svg);
    -webkit-transition: 0.13s;
    -o-transition: 0.13s;
    transition: 0.13s;
    opacity: 1;
  }

  .adm .select__option--selected {
    color: #49c3fd;
    opacity: 1;
  }

  .article .select__option--selected {
    background: 25324e;
    color: white;
    opacity: 1;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    font-family: sans-serif;
  }

  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    font-family: sans-serif;
  }

  .article-select__input-date {
    font-family: sans-serif;
  }

  .article__width-2,
  .article__width-2 .react-datepicker__input-container {
    width: 360px;
    margin-right: 15px;
  }

  .article__height-1,
  .article__height-1 .react-datepicker__input-container {
    height: 55px;
  }

  .article__width-2,
  .article__width-2 .react-datepicker__input-container {
    width: 360px;
    margin-right: 15px;
  }

  /*
:-webkit-any(article, aside, nav, section) h1 {
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
} */

  .article__input-date:before {
    content: "";
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 17px;
    height: 15px;
    background-color: #fff;
    background-image: url(#{$cdn}/img/grey_calendar.svg);
    background-size: 100% 100%;
    z-index: 1;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    opacity: 0.5;
  }

  .article__input-date input {
    position: absolute;
    left: 0;
    top: 0;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #001030;
    padding-left: 20px;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 2;
  }

  .article__input-date:before {
    content: "";
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 17px;
    height: 15px;
    background-color: #fff;
    background-image: url(#{$cdn}/img/grey_calendar.svg);
    background-size: 100% 100%;
    z-index: 1;
    color: #ffffff;
    cursor: pointer;
    opacity: 1;
  }

  .adm .small,
  .adm .small-btn {
    margin: 1em;
  }

  .react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
  }

  .article .article__input-date:before {
    content: "";
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 17px;
    height: 15px;
    background-color: #25324e;
    background-image: url(#{$cdn}/img/calendar.svg);
    background-size: 100% 100%;
    z-index: 1;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    opacity: 0.5;
  }

  .article .article__input,
  .article .article__textarea {
    background: #25324e;
    border-radius: 4px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
    border: none;
    outline: none;
  }

  .pink-button--filled {
    background: -webkit-gradient(linear, left top, right top, from(#cb25b3), to(#7d2eae));
    background: -o-linear-gradient(left, #cb25b3 0, #7d2eae 100%);
    background: linear-gradient(90deg, #cb25b3, #7d2eae);
    border: none;
    color: #fff;
  }

  .article__go-to-premium {
    width: 160px;
    height: 40px;
    margin-top: 5px;
    margin-right: 10px;
  }

  .pink-button {
    background: rgba(0, 0, 0, 0);
    color: #cb25b3;
    border: 1px solid #cb25b3;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .select__title-ct {
    color: #fff;
  }

  .article-comp.article,
  .article-comp .article {
    width: 100%;
  }

  .select__title-ct:before {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 17px;
    width: 10px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/arrow_top.svg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .article .ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .article .article__prev-stage:before {
    content: "";
    margin: 14px 7px 15px 0;
    width: 13px;
    height: 11px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/arrow_left.svg);
  }

  .article .drop {
    height: 316px;
    border: 2px dashed #d7d7d7;
    position: relative;
    width: 100%;
    margin: 30px 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .article .article__input-date input {
    position: absolute;
    left: 0;
    top: 0;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #fff;
    padding-left: 20px;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
  }

  .article .article__input-date:before {
    content: "";
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 17px;
    height: 15px;
    background-color: #25324e;
    background-image: url(#{$cdn}/img/calendar.svg);
    background-size: 100% 100%;
    z-index: 1;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    opacity: 0.5;
  }

  .article .article__slides {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    position: relative;
    width: 600%;
  }

  .article .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
  }

  .article .article__input-date {
    position: relative;
    background: #25324e;
    border-radius: 4px;
  }

  .article .article__input-date input {
    position: absolute;
    left: 0;
    top: 0;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #fff;
    padding-left: 20px;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
  }

  .article__input-date input {
    position: absolute;
    left: 0;
    top: 0;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #001030;
    padding-left: 20px;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 2;
  }

  .article .ql-container.ql-snow {
    border: 1px solid transparent;
  }

  .article .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
    width: 100%;
  }

  .article .drop {
    height: 316px;
    border: 2px dashed #d7d7d7;
    position: relative;
    width: 100%;
    margin: 30px 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .article .article__tournament-title {
    font-family: Burbank-Big-Cd-Bd, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  .article .article__custom-field {
    background: hsla(0, 0%, 100%, 0.1);
    border-radius: 2px;
    padding: 15px 20px;
    position: relative;
    margin: 15px 30px 15px 0;
  }

  .article .article__height-custom-field {
    height: 62px;
  }

  .article .article__custom-field-text,
  .article .article__custom-field-title {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    color: #fff;
  }

  .article .article__custom-field-title {
    font-size: 13px;
    line-height: 12px;
    opacity: 0.4;
    margin-bottom: 7px;
  }

  .article .article__remove-custom-field {
    position: absolute;
    right: 20px;
    top: 19px;
    width: 24px;
    height: 24px;
    background: hsla(0, 0%, 100%, 0.1);
    border-radius: 50%;
    cursor: pointer;
    border: none;
    outline: none;
  }

  .article .article__custom-field-text {
    font-size: 14px;
    line-height: 13px;
  }

  .adm .article__tournament-title {
    font-family: Burbank-Big-Cd-Bd, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.05em;
    color: #3c3daa;
  }

  .article-select__input-date {
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #e8eef6;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 0 10px 0 15px;
    font-size: 14px;
    letter-spacing: 0.05em;
    z-index: 2;
  }

  .article__selects-popup {
    z-index: 997;
  }

  .article-select__title {
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    opacity: 0.7;
    margin: 0 0 10px;
  }

  .adm .article-select__select {
    position: relative;
    height: 40px;
    background: #fff;
    border-radius: 4px;
    margin: 0 0 20px;
    width: 100%;
    color: black;
  }

  .article-select__select--name input {
    width: 100%;
  }

  .article-select {
    padding-right: 0;
    padding-left: 15px;
    -ms-flex-negative: 2;
    flex-shrink: 2;
  }

  .adm .icon-inner,
  svg {
    display: block;
    /* fill: currentColor; */
    /* stroke: currentColor; */
  }

  .adm .svg:not(:root) {
    overflow: visible;
  }

  .adm .row.article__tab-menu-before-container {
    display: flex;
    flex-wrap: wrap-reverse;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  #open-selects {
    visibility: hidden;
  }

  @media screen and (max-width: 767.98px) {
    #open-selects {
      visibility: visible;
    }
    .article__selects-popup.article__selects-popup--opened .adm .article__selects-popup {
      position: none !important;
      width: 1px !important;
      height: 0px !important;
    }
    .adm .article__selects-popup.article__selects-popup--opened {
      top: 0 !important;
      z-index: 999999999 !important;
      position: fixed;
      width: 100%;
      height: 100vh;
      left: 0;
      background-color: #e0e0e0;
      margin: 0 !important;
      padding: 30px 30px 0 20px;
      overflow: auto;
      -webkit-transition: 0.25s;
      -o-transition: 0.25s;
      transition: 0.25s;
    }
    .adm .article__selects-popup-close {
      position: absolute;
      right: 7px;
      top: 0;
      background-color: transparent;
      z-index: 99999999999;
      width: 20px;
      height: 18px;
      background-size: 100% 100%;
      cursor: pointer;
      background-image: url(#{$cdn}/img/cross-dark.svg);
    }
  }

  /* ....................................................adm menu css start................................................... */
  .p-l-lg-135 {
    padding-left: 135px;
  }

  .m-0--45 {
    margin: 0 -45px;
  }

  .m-0--30 {
    margin: 0 -30px;
  }

  .m-0--5 {
    margin: 0 -5px;
  }

  .m-0--25 {
    margin: 0 -25px;
  }

  .m-0-30 {
    margin: 0 30px;
  }

  .m-0--10 {
    margin: 0 -10px;
  }

  .m-l-15 {
    margin-left: 15px;
  }

  .m-l-0 {
    margin-left: 0;
  }

  .m-0--20 {
    margin: 0 -20px;
  }

  .m-0--31 {
    margin: 0 -31px;
  }

  .m-0--3 {
    margin: 0 -3px;
  }

  .m-0-15 {
    margin: 0 15px;
  }

  .p-r-0 {
    padding-right: 0;
  }

  .p-r-15 {
    padding-right: 15px;
  }

  .p-r-20 {
    padding-right: 20px;
  }

  .p-r-25 {
    padding-right: 25px;
  }

  .p-r-16 {
    padding-right: 16px !important;
  }

  .p-r-30 {
    padding-right: 30px;
  }

  .p-r-35 {
    padding-right: 35px;
  }

  .p-r-5 {
    padding-right: 5px !important;
  }

  .p-l-5 {
    padding-left: 5px !important;
  }

  .p-l-7 {
    padding-left: 7px !important;
  }

  .m-r-5 {
    margin-right: 5px;
  }

  .m-r-30 {
    margin-right: 30px !important;
  }

  .m-r--30 {
    margin-right: -30px !important;
  }

  .m-l-5 {
    margin-left: 5px !important;
  }

  .p-0-10 {
    padding: 0 10px;
  }

  .p-0-20 {
    padding: 0 20px;
  }

  .p-0-3 {
    padding: 0 3px;
  }

  .p-0-25 {
    padding: 0 25px;
  }

  .p-0-45 {
    padding: 0 45px;
  }

  .m-r-15 {
    margin-right: 15px;
  }

  .p-l-0 {
    padding-left: 0;
  }

  .p-l-1 {
    padding-left: 1px;
  }

  .p-l-20 {
    padding-left: 20px;
  }

  .p-l-25 {
    padding-left: 25px;
  }

  .m-40 {
    margin: 40px 0;
  }

  .m-b-40 {
    margin-bottom: 40px;
  }

  .m-b-13 {
    margin-bottom: 13px !important;
  }

  .m-b-5 {
    margin-bottom: 5px !important;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .m-b-0 {
    margin-bottom: 0 !important;
  }

  .m-0-5 {
    margin: 0 5px;
  }

  .m-0-10 {
    margin: 0 10px;
  }

  .p-0 {
    padding: 0;
  }

  .p-0-30 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .p-0-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-0-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .p-0-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .p-32 {
    padding-bottom: 32px;
  }

  .p-32,
  .p-t-32 {
    padding-top: 32px;
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-38 {
    margin-top: 38px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .w-200 {
    width: 200px;
  }

  .p-l-8 {
    padding-left: 8px !important;
  }

  .p-r-8 {
    padding-right: 8px !important;
  }

  .color-blue {
    color: #49c3fd;
  }

  .color-purple {
    color: #3c3daa;
  }

  .col-65 {
    width: 54%;
  }

  .hover-color-green:hover {
    color: #6cd000;
  }

  .opacity-5 {
    opacity: 0.5;
  }

  .z-index-5 {
    z-index: 5;
  }

  .z-index-10 {
    z-index: 10;
  }

  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .blue-button {
    background: rgba(0, 0, 0, 0);
    color: #49c3fd;
    border: 1px solid #49c3fd;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .blue-button--filled {
    background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
    background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
    background: linear-gradient(90deg, #02b7fe, #0389fd);
    border: none;
    color: #fff;
  }

  .blue-button--filled-no-gradient {
    background: #49c3fd;
    border: none;
    color: #fff;
  }

  .blue-button--transparent-filled {
    background: -webkit-gradient(linear, left top, right top, from(rgba(2, 183, 254, 0.1)), to(rgba(3, 137, 253, 0.1)));
    background: -o-linear-gradient(left, rgba(2, 183, 254, 0.1) 0, rgba(3, 137, 253, 0.1) 100%);
    background: linear-gradient(90deg, rgba(2, 183, 254, 0.1), rgba(3, 137, 253, 0.1));
    border: none;
  }

  .blue-button:hover {
    color: #fff;
    text-decoration: none;
    background: #49c3fd;
  }

  .blue-button--filled:hover {
    background: -webkit-gradient(linear, left top, right top, from(#13a9e4), to(#0a71cb));
    background: -o-linear-gradient(left, #13a9e4 0, #0a71cb 100%);
    background: linear-gradient(90deg, #13a9e4, #0a71cb);
  }

  .blue-button--transparent-filled:hover {
    background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
    background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
    background: linear-gradient(90deg, #02b7fe, #0389fd);
  }

  .blue-button--filled-no-gradient:hover {
    background: #0389fd;
  }

  .green-button {
    background: rgba(0, 0, 0, 0);
    color: #6cd000;
    border: 1px solid #6cd000;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .green-button--filled {
    background: #6cd000;
    border: none;
    color: #fff;
  }

  .green-button:hover {
    background: #6cd000;
    color: #fff;
    text-decoration: none;
  }

  .green-button--filled:hover {
    background: #64bc04;
  }

  .red-button {
    background: rgba(0, 0, 0, 0);
    color: #e13b4c;
    border: 1px solid #e13b4c;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .red-button--filled {
    background: #e13b4c;
    border: none;
    color: #fff;
  }

  .red-button:hover {
    background: #e13b4c;
    color: #fff;
    text-decoration: none;
    background: #cb2f3f;
  }

  .orange-button {
    background: rgba(0, 0, 0, 0);
    color: #ec5418;
    border: 1px solid #ec5418;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .orange-button--filled {
    background: -webkit-gradient(linear, left top, right top, from(#ec5418), to(#df2d06));
    background: -o-linear-gradient(left, #ec5418 0, #df2d06 100%);
    background: linear-gradient(90deg, #ec5418, #df2d06);
    border: none;
    color: #fff;
  }

  .orange-button:hover {
    background: #ec5418;
    color: #fff;
    text-decoration: none;
    background: -webkit-gradient(linear, left top, right top, from(#df551f), to(#c22c0b));
    background: -o-linear-gradient(left, #df551f 0, #c22c0b 100%);
    background: linear-gradient(90deg, #df551f, #c22c0b);
  }

  .pink-button {
    background: rgba(0, 0, 0, 0);
    color: #cb25b3;
    border: 1px solid #cb25b3;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .pink-button--filled {
    background: -webkit-gradient(linear, left top, right top, from(#cb25b3), to(#7d2eae));
    background: -o-linear-gradient(left, #cb25b3 0, #7d2eae 100%);
    background: linear-gradient(90deg, #cb25b3, #7d2eae);
    border: none;
    color: #fff;
  }

  .pink-button:hover {
    background: #cb25b3;
    color: #fff;
    text-decoration: none;
  }

  .pink-button--filled:hover {
    background: -webkit-gradient(linear, left top, right top, from(#a91493), to(#640d9a));
    background: -o-linear-gradient(left, #a91493 0, #640d9a 100%);
    background: linear-gradient(90deg, #a91493, #640d9a);
  }

  .purple-button {
    background: rgba(0, 0, 0, 0);
    color: #3c3daa;
    border: 1px solid #3c3daa;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .purple-button--filled {
    background: #3c3daa;
    border: none;
    color: #fff;
  }

  .purple-button--transparent-filled {
    background: rgba(60, 61, 170, 0.05);
    color: #3c3daa;
    border: none;
  }

  .purple-button:hover {
    background: #3c3daa;
    color: #fff;
    text-decoration: none;
  }

  .purple-button--filled:hover {
    background: #3c3d9c;
  }

  .purple-button--transparent-filled:hover {
    background: #3c3daa;
    color: #fff;
  }

  .grey-button {
    background: rgba(0, 0, 0, 0);
    color: #fff;
    border: 1px solid #4b566d;
    opacity: 0.5;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .adm_content .grey-button--filled {
    background: #f1f5fa;
    border: none;
    color: #bccadb;
    opacity: 1;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  .grey-button:hover {
    color: #fff;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0);
    opacity: 1;
  }

  .grey-button--filled:hover,
  .grey-button:hover {
    background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
    background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
    background: linear-gradient(90deg, #02b7fe, #0389fd);
  }

  .white-button {
    background: rgba(0, 0, 0, 0);
    color: #fff;
    border: 1px solid #fff;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .white-button:hover {
    color: #061235;
    text-decoration: none;
    background: #fff;
  }

  .green-hover-button:hover {
    background: #6cd000;
    border: none;
    color: #fff;
  }

  .blue-hover-button:hover {
    background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
    background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
    background: linear-gradient(90deg, #02b7fe, #0389fd);
    border: none;
    color: #fff;
  }

  .select {
    position: relative;
    overflow: visible;
    height: 40px;
    border-radius: 4px;
    min-width: 100%;
  }

  .adm_content .select,
  .adm .select {
    position: relative;
    overflow: visible;
    height: 45px;
    border-radius: 4px;
    min-width: 100%;
  }

  .article .article-select {
    width: calc(23vw);
    /* padding-right: 0;
    padding-left: 15px;
    -ms-flex-negative: 2;
    flex-shrink: 2; */
  }

  .p-content {
    font-family: Burbank-Big-Cd-Bd, sans-serif;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: lighter;
    font-size: 1.5rem;
    line-height: 1.5;
    position: relative;
    min-height: 100%;
    padding: 10px;
    margin-top: 1rem;
  }

  .btn-blue-gradient svg {
    margin-left: 5px;
    width: 14px;
    height: 12px;
  }

  .article .select__title:before {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 17px;
    width: 10px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/grey_arrow_top.svg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .adm .select__title:before {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 17px;
    width: 10px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/grey_arrow_top.svg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .select--opened .select__title:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  .adm .select__container {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    left: 0;
    top: 45px;
    -webkit-transform: translateY(-50%) scaleY(0);
    -ms-transform: translateY(-50%) scaleY(0);
    transform: translateY(-50%) scaleY(0);
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background-color: #fff;
    padding: 0;
    border-radius: 4px;
    border: 1px solid #e8eef6;
    overflow-y: scroll;
  }

  .select--opened .select__container {
    -webkit-transform: translateY(0) scaleY(1);
    -ms-transform: translateY(0) scaleY(1);
    transform: translateY(0) scaleY(1);
    max-height: 25vh;
  }

  .select__option:hover {
    background: hsla(0, 0%, 78.4%, 0.14);
  }

  .select__option:first-of-type {
    margin-top: 7px;
  }

  .select__option:last-of-type {
    margin-bottom: 7px;
  }

  .select__search-container {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #e8eef6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding: 0 6px;
  }

  .select__search-container:after {
    content: "";
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    width: 10px;
    height: 10px;
    right: 16px;
    top: 20px;
    z-index: 1;
    background-image: url(#{$cdn}/img/grey_search.svg);
    opacity: 0.3;
  }

  .select__search {
    width: 100%;
    height: 30px;
    background-color: hsla(0, 0%, 78.4%, 0.1);
    border-radius: 2px;
    padding: 0 10px;
    font-family: Burbank Small, sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 13px;
    color: #001030;
    border: none;
    outline: none;
    z-index: 2;
  }

  .article__selects-container {
    position: relative;
    z-index: 1;
  }

  .article__selects-container--opened {
    z-index: 3;
  }

  .select__option:after {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 10px;
    width: 12px;
    height: 9px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/blue_ok.svg);
    -webkit-transition: 0.13s;
    -o-transition: 0.13s;
    transition: 0.13s;
    opacity: 0;
  }

  .select__option--selected:after {
    opacity: 1;
  }

  .select__blocked {
    position: absolute;
    display: block;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-size: 8px 11px;
    background-image: url(#{$cdn}/img/locked.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    z-index: 999999;
  }

  @-webkit-keyframes show {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .select__blocked-container {
    z-index: 9999;
    display: none;
    position: absolute;
    left: 10px;
    top: 20px;
    -webkit-transform: translate(-100%, calc(2px - 100%));
    -ms-transform: translate(-100%, calc(2px - 100%));
    transform: translate(-100%, calc(2px - 100%));
    width: 200px;
    padding-bottom: 7px;
    -webkit-animation: show 0.15s ease-in-out;
    animation: show 0.15s ease-in-out;
    opacity: 1;
  }

  .select__blocked-container--show,
  .select__blocked-container:hover {
    display: block;
  }

  .select__blocked-content {
    display: block;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    background: #fff;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #001030;
    position: relative;
    border-radius: 2px;
  }

  .select__blocked-content:before {
    content: "";
    display: block;
    position: absolute;
    left: 85%;
    bottom: -11px;
    width: 0;
    height: 0;
    border: 8px solid rgba(0, 0, 0, 0);
    border-top: 4px solid #fff;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }

  .select__blocked-link,
  .select__blocked-link:hover {
    color: #3c3daa;
    text-decoration: underline;
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .chat-container {
    position: fixed;
    z-index: 99999;
    right: 257px;
    bottom: 0px;
    width: 300px;
    height: 396px;
    background: #fff;
    border-radius: 2px;
    display: none;
    opacity: 0;
  }

  .chat-container--opened {
    opacity: 1;
    display: block;
    -webkit-animation: show 0.3s ease-in-out;
    animation: show 0.3s ease-in-out;
    z-index: 99999;
  }

  .chat-header {
    position: relative;
    width: 100%;
    height: 60px;
    background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
    background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
    background: linear-gradient(90deg, #02b7fe, #0389fd);
    z-index: 2;
  }

  .chat-container--dispute .chat-header {
    background: #3c3daa;
  }

  .chat-header__header-container {
    padding: 0 20px;
  }

  .chat-header__search-container {
    padding: 0 5px;
  }

  .chat-header__menu-container {
    padding: 0 10px 0 5px;
  }

  .chat-header__header {
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #fff;
    margin-bottom: 0;
  }

  .chat-header__search {
    width: 15px;
    height: 15px;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/search.svg);
  }

  .chat-header__checkbox {
    display: none;
  }

  .chat-header__menu-button {
    display: block;
    width: 14px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    background-size: 4px 20px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url(#{$cdn}/img/chat_menu.svg);
  }

  .chat-menu {
    position: absolute;
    right: 15px;
    top: 27px;
    background: #fff;
    width: 113px;
    border: 1px solid #e2f0ff;
    -webkit-box-shadow: 0 1px 10px hsla(0, 0%, 62.4%, 0.07);
    box-shadow: 0 1px 10px hsla(0, 0%, 62.4%, 0.07);
    border-radius: 4px;
    display: none;
  }

  .chat-header__checkbox:checked ~ .chat-menu {
    display: block;
  }

  .chat-menu__link {
    display: block;
    height: 39px;
    text-decoration: none;
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 39px;
    color: #001030;
    padding-left: 19px;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
  }

  .chat-menu__link:hover {
    text-decoration: none;
    color: #001030;
    background: rgba(238, 246, 255, 0.6);
  }

  .chat-header__back {
    width: 8px;
    height: 20px;
    background-size: 8px 14px;
    background-position: 0;
    background-color: rgba(0, 0, 0, 0);
    background-image: url(#{$cdn}/img/arrow_left-2.svg);
    margin-left: 15px;
  }

  .chat-header__accept,
  .chat-header__back {
    display: block;
    background-repeat: no-repeat;
  }

  .chat-header__accept {
    width: 28px;
    height: 28px;
    background-color: #001030;
    opacity: 0.4;
    border-radius: 50%;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    background-size: 13px 11px;
    background-position: 50%;
    background-image: url(#{$cdn}/img/ok.svg);
    margin-right: 15px;
  }

  .chat {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    overflow: auto;
    background: #eef6ff;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .chat--chat {
    height: calc(100% - 110px);
  }

  .chat::-webkit-scrollbar {
    display: none;
  }

  .chat__person-container {
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #e2f0ff;
    position: relative;
  }

  .chat__person-container--create-chat {
    border-bottom: 1px solid #e0e8f1;
  }

  .chat__person-picture {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 12px;
  }

  .chat__person-picture--group {
    background: #028ffd;
    color: #fff;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
  }

  .chat__person-name,
  .chat__person-picture--group {
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
  }

  .chat__person-name {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: #028ffd;
    margin-bottom: 4px;
  }

  .chat__person-container--create-chat .chat__person-name {
    margin-bottom: 0;
    color: #001030;
  }

  .chat__person-message {
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: #001030;
  }

  .chat__person-message--mine {
    opacity: 0.5;
  }

  .chat__person-date {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    color: #aeb7bf;
    margin-top: 18px;
  }

  .chat__person-checkbox {
    display: none;
  }

  .chat__person-label {
    width: 15px;
    height: 15px;
    border: 1px solid #cedae5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    background-size: 10px 8px;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .chat__person-checkbox:checked ~ .chat__person-label {
    background-color: #1f9cfd;
    background-image: url(#{$cdn}/img/ok.svg);
    border: 0 solid #cedae5;
  }

  .chat__person-status {
    position: absolute;
    right: 9px;
    bottom: 14px;
    width: 8px;
    height: 8px;
    background: #cdcdcd;
    border: 1px solid #eef6ff;
    border-radius: 50%;
  }

  .chat__member .chat__person-status {
    bottom: 9px;
  }

  .chat__person-status--online {
    background: #6cd000;
  }

  .chat__header {
    letter-spacing: 0.03em;
    margin: 15px 0 10px;
  }

  .chat__header,
  .chat__input {
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    color: #001030;
  }

  .chat__input {
    width: 270px;
    height: 36px;
    background: #dee8f2;
    border-radius: 4px;
    letter-spacing: 0.05em;
    border: none;
    outline: none;
    padding: 0 0 0 16px;
  }

  .chat__input-search-container {
    position: relative;
  }

  .chat__input-search-container:before {
    content: "";
    position: absolute;
    display: block;
    width: 11px;
    height: 11px;
    opacity: 0.5;
    top: 12px;
    right: 16px;
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/grey_search.svg);
  }

  .chat-header__container {
    border-top: 1px solid #e0e8f1;
  }

  .chat__person-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .chat-header__profile-picture {
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  .chat-header__profile-picture--group {
    background: #fff;
    color: #028ffd;
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 18px;
    line-height: 37px;
    text-align: center;
  }

  .chat-header__username {
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.03em;
    color: #fff;
    margin-bottom: 6px;
  }

  .chat-header__status {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    opacity: 0.8;
  }

  .chat__message-container {
    margin-top: 20px;
  }

  .chat__message-container:last-of-type {
    margin-bottom: 20px;
  }

  .chat__message-profile-picture {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin-top: 5px;
  }

  .add-participent-checkbox {
    width: 17px;
    height: 17px;
    margin-top: 8px;
    float: right;
  }

  .remove-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .chat__message {
    background: #fff;
    border: 1px solid #e2f0ff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #001030;
    padding: 9px 10px;
    max-width: calc(100% - 30px);
    word-wrap: break-word;
  }

  .chat__message-username {
    line-height: 14px;
    margin-bottom: 2px;
    color: #3c3daa;
  }

  .chat__message-date,
  .chat__message-username {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
  }

  .chat__message-date {
    line-height: 12px;
    color: #aeb7bf;
    margin-top: 6px;
  }

  .chat__message-container--own .chat__message {
    background: #028ffd;
    color: #fff;
  }

  .chat-field {
    height: 50px;
    background: #fff;
  }

  .chat-block,
  .chat-field {
    width: 100%;
    position: relative;
  }

  .chat-block {
    height: 70px;
    margin-top: -20px;
    background: #eef6ff;
  }

  .chat-field__textarea {
    border: none;
    outline: none;
    background: rgba(0, 0, 0, 0);
    height: 50px;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    resize: none;
    padding-top: 17px;
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #001030;
    opacity: 0.8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .chat-field__textarea::-webkit-scrollbar {
    display: none;
  }

  .chat__message-typing-container {
    position: relative;
    width: 34px;
    height: 18px;
  }

  .chat__message-typing-container div {
    display: block;
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: 5px;
    background: #a7cffc;
    border-radius: 50%;
    -webkit-animation: chatDots 3s linear infinite;
    animation: chatDots 3s linear infinite;
    -webkit-transform: scale(0.3);
    -ms-transform: scale(0.3);
    transform: scale(0.3);
  }

  .chat__message-typing-container div:first-of-type {
    left: 2px;
  }

  .chat__message-typing-container div:nth-of-type(2) {
    left: 13px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .chat__message-typing-container div:nth-of-type(3) {
    right: 2px;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .chat-block__message {
    position: relative;
    width: 100%;
    height: 40px;
    background: rgba(255, 59, 76, 0.1);
    border-radius: 2px;
    margin: 15px 0;
    padding-left: 41px;
    padding-top: 14px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    color: #e13b4c;
  }

  .chat-block__message:before {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    left: 15px;
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/blocked.svg);
  }

  .chat__subheader {
    margin: 15px 0 5px;
  }

  .chat__subheader,
  .chat__transparent-subheader {
    font-family: Burbank-Big-Rg-Md, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: #001030;
  }

  .chat__transparent-subheader {
    margin: 15px 0 0;
    opacity: 0.4;
  }

  .chat__hr {
    width: 100%;
    height: 1px;
    margin: 20px 0 0;
    background: #e0e8f1;
  }

  .chat__member {
    position: relative;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #e0e8f1;
  }

  .chat__typing-dots {
    position: relative;
    margin-left: 4px;
  }

  @-webkit-keyframes chatDots {
    0% {
      -webkit-transform: scale(0.3);
      transform: scale(0.3);
    }
    10% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    30% {
      -webkit-transform: scale(0.3);
      transform: scale(0.3);
    }
    to {
      -webkit-transform: scale(0.3);
      transform: scale(0.3);
    }
  }

  @keyframes chatDots {
    0% {
      -webkit-transform: scale(0.3);
      transform: scale(0.3);
    }
    10% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    30% {
      -webkit-transform: scale(0.3);
      transform: scale(0.3);
    }
    to {
      -webkit-transform: scale(0.3);
      transform: scale(0.3);
    }
  }

  .chat__typing-dots div {
    width: 6px;
    height: 6px;
    background: #001030;
    opacity: 0.5;
    margin-right: 2px;
    -webkit-animation: chatDots 3s linear infinite;
    animation: chatDots 3s linear infinite;
    border-radius: 50%;
    -webkit-transform: scale(0.3);
    -ms-transform: scale(0.3);
    transform: scale(0.3);
  }

  .chat__typing-dots--white div {
    background: #fff;
  }

  .chat__typing-dots div:nth-of-type(2) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .chat__typing-dots div:nth-of-type(3) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .container-margin {
    padding-right: 20px;
    padding-left: 20px;
  }

  .btn_disable {
    border: none !important;
    outline: none !important;
    color: #fff !important;
    text-decoration: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-family: Burbank-Big-Cd-Bd;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    height: 40px;
    padding: 3px 65px 0;
    background: #e13b4c;
    border-radius: 2px;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .btn_disable:hover {
    opacity: 0.8;
  }

  .hdr {
    padding: 0 20px;
    height: 75px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .adm_ttl {
    margin: 32px 0 0;
    font-family: Burbank-Big-Cd-Bd;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    color: #3c3daa;
  }

  .adm_brefc {
    margin: 15px 0 0;
    padding: 0;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    color: #001030;
    font-style: normal;
    font-weight: 550;
    font-size: 15px;
    line-height: 15px;
    font-family: Burbank-Big-Rg-Md;
  }

  .adm_brefc a:after {
    margin: 0 0 0 6px;
    content: "/";
    display: inline;
    color: #7b8495 !important;
  }

  .adm_brefc a {
    margin: 0 6px 0 0;
    color: #7b8495;
    text-decoration: none !important;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .adm_brefc a:hover {
    color: #001030;
  }

  .adm_nav {
    z-index: 998;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    -webkit-transition: width 0.5s;
    -o-transition: width 0.5s;
    transition: width 0.5s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 260px;
    -webkit-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    transform: translateX(-260px);
    -webkit-transition: -webkit-transform 0.5s;
    transition: -webkit-transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
    background: #3c3daa;
  }

  .adm_nav_inn {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .adm-nav-open .adm_nav {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .adm_logo {
    z-index: 1;
    position: relative;
    padding: 16.3px 0 16px;
    margin: 0 auto;
    width: 100%;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #3c3daa;
  }

  a.adm_logo_inn {
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  a.adm_logo_inn:hover {
    opacity: 0.8;
  }

  .adm_logo img {
    width: 200px;
    max-width: 200px;
  }

  .adm_usr {
    z-index: 1;
    height: 110px;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 18px 18px 0;
    position: relative;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    text-decoration: none !important;
    background: #3c3daa;
  }

  a.adm_usr:hover {
    background: #e5f7ff;
  }

  .adm_usr_inn {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 100%;
  }

  .adm_usr_img {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #d7ecfb;
  }

  a.adm_usr_img {
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  a.adm_usr_img:hover {
    opacity: 0.8;
  }

  .adm_usr_img > img {
    max-width: 100%;
  }

  .adm_usr_m {
    -moz-flex: 1;
    -ms-flex: 1;
    -o-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .adm_usr_ttl,
  .adm_usr_txt {
    padding-left: 10px;
    width: 100%;
  }

  .adm_usr_ttl {
    margin: 3px 0 0;
    color: #fff !important;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    font-family: Burbank-Big-Cd-Bd;
    text-decoration: none !important;
  }

  a.adm_usr_ttl {
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  a.adm_usr_ttl:hover {
    color: #6dd0ff !important;
  }

  .adm_usr_txt {
    margin: 0;
    color: #9ba4e5 !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    font-family: Burbank-Big-Rg-Md;
    text-decoration: none !important;
  }

  .adm_usr_more {
    margin-top: 25px;
    display: block;
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff !important;
    width: 20px;
    height: 20px;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .adm_usr_more:hover {
    color: #1991eb !important;
  }

  .adm_usr_drop {
    padding: 0 0 10px;
    display: none;
    width: 230px;
    position: absolute;
    left: 15px;
    top: 80%;
    background: #fff;
    border: 1px solid rgba(152, 166, 173, 0.15);
    border-radius: 4px;
  }

  .adm_usr_drop_arrow:before {
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    border-left: 1px solid #eeece5;
    border-top: 1px solid #eeece5;
    top: 0;
    right: 5%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -8px;
    background: #fff;
  }

  .adm_usr_drop .adm_usr {
    height: auto;
    margin-top: 10px;
    padding: 13px 10px;
    background: #fff;
  }

  .adm_usr_drop .adm_usr_img {
    width: 50px;
    height: 50px;
  }

  .adm_usr_drop .adm_usr_ttl {
    margin: 0;
    color: #435966 !important;
    font-size: 16px;
    line-height: 18px;
  }

  .adm_usr_drop .adm_usr_txt {
    font-size: 13px;
    color: #424247 !important;
  }

  .adm_usr_date {
    padding: 2px 0 0 10px;
    width: 100%;
    color: #cdcdd2;
    font-size: 13px;
    line-height: 13px;
    font-family: Burbank-Big-Rg-Md;
  }

  .adm_last-login a {
    top: 0;
    color: #435966;
    font-size: 14px;
    line-height: 20px;
    display: block;
    font-family: Burbank-Big-Rg-Md;
    padding: 7px 10px;
    text-decoration: none !important;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .adm_add-user,
  .adm_last-login a:hover {
    color: #6dd0ff !important;
  }

  .adm_add-user {
    text-decoration: none !important;
    font-family: Burbank-Big-Cd-Bd;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 10px;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .adm_add-user ion-icon {
    margin-top: -3px;
    font-size: 18px;
    line-height: 20px;
    margin-right: 10px;
  }

  .adm_add-user span {
    padding: 3px 0 0;
  }

  .adm_add-user:hover {
    color: #000 !important;
  }

  .adm_menu {
    position: relative;
    margin-top: -185px;
    padding-top: 185px;
    height: 100%;
  }

  .adm_menu_inn {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    overflow: auto;
  }

  .adm_menu_list {
    margin: 25px 6px 0 0;
    padding: 0;
    list-style: none;
  }

  .adm_menu_i {
    padding: 16px 20px 12px;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #9ba4e5 !important;
    text-decoration: none !important;
    font-size: 20px;
    line-height: 22px;
    font-family: Burbank-Big-Cd-Bd;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .adm_menu_i:hover {
    color: #6dd0ff !important;
  }

  .adm_menu_i span {
    margin: 0 0 0 12px;
  }

  .adm_menu_icon {
    font-size: 22px;
  }

  .adm_menu_i .s-ion-icon {
    fill: #9ba4e5 !important;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .adm_menu_i:hover .s-ion-icon {
    fill: #6dd0ff !important;
  }

  .adm_menu_icon {
    margin: -4px 0 0;
    display: block;
    width: 22px;
    height: 22px;
    line-height: 0;
  }

  .disable-acc {
    padding: 40px 20px;
    width: 100% !important;
    max-width: 460px !important;
    height: auto !important;
  }

  .disable-acc_ttl {
    margin: 0;
    font-family: Burbank-Big-Rg-Md;
    font-size: 24px;
    line-height: 24px;
  }

  .disable-acc_ttl,
  .disable-acc_txt {
    font-style: normal;
    font-weight: 550;
    text-align: center;
    color: #001030;
  }

  .disable-acc_txt {
    margin: 18px auto 0;
    max-width: 260px;
    font-family: BurbankSmall-Medium, sans-serif;
    font-size: 17px;
    line-height: 27px;
  }

  .modal_disable_buttons {
    margin: 5px -5px 0;
  }

  .disable-acc .window__button--cancel {
    height: 40px;
    margin: 15px 5px 0;
    background: rgba(0, 0, 0, 0);
    border: 1px solid #e13b4c;
    color: #e13b4c;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .disable-acc .window__button--cancel:hover {
    background: #e13b4c;
    color: #fff;
  }

  .disable-acc .window__button--delete {
    height: 40px;
    margin: 15px 5px 0;
    background: #e13b4c;
    color: #fff;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .disable-acc .window__button--delete:hover {
    background: #cb2f3f;
  }

  .sandwich {
    cursor: pointer;
  }

  .sandwich span {
    display: block;
    width: 23px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;
    background: #55566b;
    border-radius: 4px;
    z-index: 1;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .sandwich span:first-child {
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .sandwich span:nth-last-child(2) {
    -webkit-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }

  .sandwich span:last-child {
    margin-bottom: 0;
  }

  .sandwich:hover span {
    background: #6dd0ff;
  }
  .highlight-primary-card {
    background: rgb(14,162,252);
  }
  .btn-primary-card{
    width:118px !important
  }
  @media screen and (max-width: 767.96px) {
    .m-sm-40 {
      margin: 40px 0;
    }
    .m-sm-20 {
      margin: 20px 0;
    }
  }

  @media screen and (max-width: 991.98px) {
    .p-l-sm-85 {
      padding-left: 85px;
    }
    .m-sm-20 {
      margin: 20px 0;
    }
    .m-b-sm-20 {
      margin-bottom: 20px;
    }
    .m-t-sm-50 {
      margin-top: 50px;
    }
    .m-t-sm-60 {
      margin-top: 60px;
    }
  }

  @media screen and (min-width: 1200px) {
    .h-xl-100 {
      height: 100%;
    }
    .m-l-xl-15 {
      margin-left: 15px;
    }
  }

  @media screen and (min-width: 992px) {
    .m-t-lg-82 {
      margin-top: 82px;
    }
    .h-lg-100 {
      height: 100%;
    }
    .m-md-0--15 {
      margin: 0 -15px !important;
    }
    .adm_ttl {
      margin: 15px 0 0;
      font-size: 36px;
      line-height: 36px;
    }
    .adm_nav {
      width: 75px;
      -webkit-transform: translateX(0) !important;
      -ms-transform: translateX(0) !important;
      transform: translateX(0) !important;
    }
    .adm_page {
      margin-left: 75px !important;
    }
    .adm-nav-open .adm_nav {
      width: 260px;
    }
    .adm-nav-open .adm_page {
      margin-left: 260px !important;
    }
    .adm .adm_logo {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .adm.adm-nav-open .adm_logo {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .adm .adm_logo img {
      width: 140px;
      max-width: 140px;
    }
    .adm.adm-nav-open .adm_logo {
      margin: 0 auto;
      width: 200px;
      max-width: 200px;
    }
    .adm.adm-nav-open .adm_logo img {
      width: 100%;
      max-width: 100%;
    }
    .adm_logo_inn {
      width: 46px;
      margin: 0 auto;
      overflow: hidden;
    }
    .adm.adm-nav-open .adm_logo_inn {
      width: auto;
    }
    .adm_usr {
      display: none !important;
    }
    .adm.adm-nav-open .adm_usr {
      display: block !important;
    }
    .adm_menu_i span {
      display: none;
    }
    .adm.adm-nav-open .adm_menu_i span {
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .adm .adm_menu {
      position: relative;
      margin-top: -75px;
      padding-top: 75px;
    }
    .adm.adm-nav-open .adm_menu {
      position: relative;
      margin-top: -185px;
      padding-top: 185px;
    }
    .adm .adm_menu_list {
      margin: 15px 6px 0 0;
    }
    .adm.adm-nav-open .adm_menu_list {
      margin: 25px 6px 0 0;
    }
  }

  @media screen and (min-width: 768px) {
    .p-r-md-20 {
      padding-right: 20px !important;
    }
    .p-r-md-25 {
      padding-right: 25px !important;
    }
  }

  @media screen and (min-width: 576px) {
    .p-l-sm-20 {
      padding-left: 20px;
    }
    .p-l-sm-1 {
      padding-left: 1px;
    }
    .m-r-sm-5 {
      margin-right: 5px;
    }
    .m-l-sm-5 {
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 575.98px) {
    .m-t-sm-10 {
      margin-top: 10px;
    }
    .p-l-sm-65 {
      padding-left: 65px;
    }
    .adm_content .article {
      margin: 0 -15px;
    }
    .disable-acc .window__button--cancel,
    .disable-acc .window__button--delete {
      width: 100%;
    }
  }

  @media screen and (min-width: 1920px) {
    .p-xxl-0-40 {
      padding: 0 40px;
    }
    .container-margin {
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  /*
@media screen and (max-width:991px) {
    .hdr {
        background-color: #fff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
        -moz-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
        -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
    }
} */

  /* ....................................................adm menu css end................................................... */

  .article__label-director:before {
    content: "";
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    left: 3px;
    top: 4px;
    width: 10px;
    height: 8px;
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/grey_ok.svg);
    opacity: 0;
  }

  .adm .article__label-director:before {
    content: "";
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    left: 3px;
    top: 4px;
    width: 10px;
    height: 8px;
    background-size: 100% 100%;
    background-image: url(#{$cdn}/img/ok.svg);
    opacity: 0;
  }

  #cash-prize:checked ~ .row .article__label--cash-prize .article__label-director:before,
  #live:checked ~ .row .article__label--live .article__label-director:before,
  #merchandise-prize:checked ~ .row .article__label--merchandise-prize .article__label-director:before,
  #online:checked ~ .row .article__label--online .article__label-director:before,
  .article__checkbox:checked + .article__label .article__label-director:before {
    opacity: 1;
  }

  .article__custom-field-title {
    font-size: 13px;
    line-height: 12px;
    opacity: 0.4;
    margin-bottom: 7px;
  }

  .adm .article__big-subheader,
  .adm .article__custom-field-title {
    font-family: BurbankSmall-Medium, sans-serif;
    color: #001030;
  }

  .adm_content .article-select__input-date,
  .adm .article-select__input-date {
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #e8eef6;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 0 30px 0 15px;
    font-size: 14px;
    letter-spacing: 0.05em;
    z-index: 2;
  }

  .adm_content .article-select__input-date-container:after,
  .adm .article-select__input-date-container:after {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    right: 16px;
    width: 17px;
    height: 15px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url(#{$cdn}/img/grey_calendar.svg);
    background-size: 100% 100%;
    z-index: 1;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    opacity: 0.3;
  }

  .ql-editor p {
    min-height: 300px;
  }

  .adm .ql-editor p {
    color: #001030;
  }

  .article .ql-editor p {
    color: #fff;
  }

  .right-menu1 {
    -ms-flex-negative: 0;
    /* flex-shrink: 0; */
    min-height: 100vh;
    max-height: 200vh;
    overflow: visible;
    padding: 3em 2em 3em 0.1em;
    float: right;
    margin: auto;
    margin-right: 5px;
    width: 100%;
    position: relative;
  }

  section.article__payment.payment.payment--next {
    position: absolute;
    padding: 3em;
  }

  article .grey-button--filled {
    background: rgba(255, 255, 255, 13%);
    border: none;
    color: white;
    opacity: 1;
    transition: none;
  }

  .adjestPriceSect {
    vertical-align: bottom;
    margin: auto 0px;
    text-align: center;
  }

  .bold {
    font-weight: bold;
    opacity: 0.9;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: 1200px) {
    .my-ticket-table__column--sales,
    .my-ticket-table__column--sale-date,
    .my-ticket-table__column--venue,
    .my-ticket-table__column--refunded,
    .my-ticket-table__column--price,
    .my-ticket-table__column--amount,
    .my-ticket-table__column--method,
    .my-ticket-table__column--tournament,
    .my-ticket-table__column--status,
    .my-ticket-table__column--date,
    .my-ticket-table__column--name,
    .my-ticket-table__column--id,
    .my-ticket-table__column--location,
    .my-ticket-table__column--role,
    .my-ticket-table__column--time {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-family: Burbank-Big-Rg-Md, sans-serif;
    }
    .my-ticket-table__column--sales:before {
      content: "Sales: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--name:before {
      content: "Name: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--name1:before {
      content: "Name: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--date:before {
      content: "Date: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--time:before {
      content: "Time: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--role:before {
      content: "Role: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--location:before {
      content: "Location: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--id:before {
      content: "ID: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--price:before {
      content: "Price: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--refunded:before {
      content: "Refunded: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--venue:before {
      content: "Venue: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--sale-date:before {
      content: "Venue: ";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--amount:before {
      content: "Amount";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--method:before {
      content: "Payment method";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--tournament:before {
      content: "Tournament";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
    .my-ticket-table__column--status:before {
      content: "Status";
      margin-left: 2em;
      font-weight: bold;
      opacity: 1;
    }
  }

  .my-ticket-table__column--sales,
  .my-ticket-table__column--sale-date,
  .my-ticket-table__column--venue,
  .my-ticket-table__column--refunded,
  .my-ticket-table__column--price,
  .my-ticket-table__column--amount,
  .my-ticket-table__column--method,
  .my-ticket-table__column--tournament,
  .my-ticket-table__column--status,
  .my-ticket-table__column--date,
  .my-ticket-table__column--name,
  .my-ticket-table__column--name1,
  .my-ticket-table__column--id,
  .my-ticket-table__column--location,
  .my-ticket-table__column--role,
  .my-ticket-table__column--time {
    font-weight: normal;
    opacity: 0.7;
    font-family: Burbank-Big-Rg-Md, sans-serif;
  }

  button.btn-sm,
  a.btn-sm {
    display: flex;
    display: -webkit-flex;
    display: -ms-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    border-radius: 2px;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    font-family: "Burbank-Big-Cd-Bd", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 3% 4%;
    height: 2rem;
    width: inherit;
    min-height: auto;
  }

  @media screen and (min-width: 1200px) {
    .bil_col-left {
      width: 500px;
      max-width: 500px;
      flex-basis: 500px;
    }
    .bil_col-right {
      width: calc(100% - 500px);
      max-width: calc(100% - 500px);
      flex-basis: calc(100% - 500px);
    }
    .bil_inf {
      max-width: 470px;
    }
  }

  .bil {
    margin-bottom: 60px;
    color: #fff;
  }

  .bil_ttl {
    margin: 0 0 16px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .bil_ttl_l,
  .bil_ttl_r {
    margin-bottom: 20px;
  }

  .bil_ttl .article__header {
    font-family: "Burbank-Big-Cd-Bd";
    margin-bottom: 0;
    margin-top: 0;
  }

  /*bil_payments*/

  .bil_payments_ttl {
    margin: 5px 0 20px;
    font-family: "Burbank-Big-Rg-Md";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  .bil_pay {
    margin-bottom: 15px;
  }

  .bil_pay_head {
    padding: 3px 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
  }

  .bil_pay_head_l {
    padding: 5px 0 5px 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-align-items: center;
    align-items: center;
  }

  .bil_pay_head_l_i {
    margin: 7px 20px;
  }

  @media screen and (min-width: 1600px) {
    .bil_pay_head_l_i {
      margin: 7px 35px 7px 20px;
    }
  }

  .bil_pay_head_btn {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    line-height: 0;
  }

  .bil_pay_head_r {
    padding: 5px 15px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-align-items: center;
    align-items: center;
  }

  .bil_pay_head_r_i {
    margin: 7px 5px;
    line-height: 0;
  }

  .bil_pay_head_img {
    line-height: 0;
    width: 44px;
  }

  .bil_pay_head_ttl {
    margin: 3px 0 0;
    display: block;
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #ffffff;
  }

  .bil_pay_head_edit {
    margin: 0;
    padding: 3px 0 0;
    height: 40px;
    width: 84px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    font-family: "Burbank-Big-Cd-Bd";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    border-radius: 2px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
  }

  .bil_pay_head_edit:hover {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  }

  .bil_pay_head_delete {
    position: relative;
    margin: 0;
    padding: 0 0;
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    border-radius: 2px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
  }

  .bil_pay_head_delete:after {
    content: "";
    display: block;
    width: 21px;
    height: 21px;
    position: absolute;
    top: 10px;
    left: 10px;
    background-image: url(#{$cdn}/img/white_delete.svg);
    background-repeat: no-repeat;
    -webkit-background-size: auto;
    background-size: auto;
    background-position: center;
    opacity: 0.5;
  }

  .bil_pay_head_delete:hover {
    background: linear-gradient(225deg, #e64846 -0.03%, #d61d57 96.73%);
  }

  .bil_pay_head_delete:hover:after {
    opacity: 1;
  }

  .bil_pay_head_toggle {
    position: relative;
    width: 40px;
    height: 40px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s;
  }

  .bil_pay_head_toggle:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  .bil_pay_head_toggle:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0.5;
    background-size: 12px 7px;
    background-image: url(#{$cdn}/img/arrow_top.svg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }

  .toggle--bil_pay_body:checked ~ .bil_pay_head .bil_pay_head_toggle {
    background: rgba(255, 255, 255, 0.2);
  }

  .toggle--bil_pay_body:checked ~ .bil_pay_head .bil_pay_head_toggle:before {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .bil_pay_body {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1);
    width: 100%;
    height: 100px;
    max-height: 0;
    height: auto;
    overflow: hidden;
    -webkit-transform: translateY(-50%) scaleY(0);
    -ms-transform: translateY(-50%) scaleY(0);
    transform: translateY(-50%) scaleY(0);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background: rgba(255, 255, 255, 0.1);
    z-index: 1;
  }

  .toggle--bil_pay_body:checked ~ .bil_pay_body {
    max-height: 1000px;
    -webkit-transform: translate(0) scaleY(1);
    -ms-transform: translate(0) scaleY(1);
    transform: translate(0) scaleY(1);
  }

  .bil_pay_body_inn {
    padding: 0 20px 18px;
  }

  /*bil_inf*/

  .bil_pay_head {
    background: rgba(255, 255, 255, 0.1);
  }

  .bil_inf {
    margin: 0 0 20px;
    padding: 21px 29px 27px 29px;
    background: #1e2c48;
  }

  @media (max-width: 480px) {
    .bil_inf {
      padding: 21px 15px 27px 15px;
      background: #1e2c48;
    }
  }

  .bil_inf_t {
    padding: 0 0 29px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
  }

  .bil_inf_t_l,
  .bil_inf_t_r {
    margin-top: 10px;
  }

  .bil_inf_ttl {
    margin: 3px 0 0;
    font-family: "BurbankSmall-Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 23px;
    color: #ffffff;
  }

  .bttn-green-outline {
    padding: 2px 25px 0;
    height: 36px;
    display: inline-block;
    background-color: transparent;
    border: 1px solid #6cd000;
    border-radius: 2px;
    font-family: "Burbank-Big-Cd-Bd";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #6cd000;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: all 0.2s;
  }

  .bttn-green-outline:hover {
    color: #fff;
    background: #6cd000;
  }

  .bttn-blue-outline {
    padding: 2px 25px 0;
    height: 36px;
    display: inline-block;
    background-color: transparent;
    border: 1px solid #49c3fd;
    border-radius: 2px;
    font-family: "Burbank-Big-Cd-Bd";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #49c3fd;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: all 0.2s;
  }

  .bttn-blue-outline:hover {
    color: #fff;
    background: #49c3fd;
  }

  .bil_txt {
    margin: -10px 0 23px;
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    opacity: 0.7;
  }

  .bil_inf_b {
    padding: 3px 0 0;
    border-top: 1px solid #2f3d58;
  }

  .data_list {
    margin: 0 -23px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .data_list_i {
    padding: 0 23px;
  }

  @media (max-width: 480px) {
    .data_list {
      margin: 0 -15px;
    }
    .data_list_i {
      padding: 0 15px;
    }
  }

  .data_list_txt {
    margin-top: 14px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .data_list_txt_i + .data_list_txt_i {
    margin-top: 5px;
  }

  .data_list.data_list--vertical .data_list_txt {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
  }

  .data_list.data_list--horisontal .data_list_txt {
    margin-top: 24px;
    display: block;
  }

  .data_list.data_list--horisontal .data_list_txt_i {
    display: inline;
  }

  .data_list.data_list--horisontal .data_list_type {
    margin-right: 26px;
  }

  @media (max-width: 480px) {
    .data_list.data_list--horisontal .data_list_type {
      margin-right: 10px;
    }
  }

  .data_list_type {
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.4);
  }

  .data_list_ttl {
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  .btn-more-info {
    margin: 0 0 1px 10px;
    padding: 0;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    border-radius: 75%;
    transition: all 0.2s;
    line-height: 0;
  }

  .btn-more-info img {
    margin: 0 0;
    display: block;
    width: 17px;
  }

  .btn-more-info:hover {
    opacity: 0.75;
  }

  .btn-more-info[aria-expanded="true"] {
    opacity: 1;
  }

  .white-tooltip-theme .tippy-tooltip {
    background-color: #fff;
    font-weight: 550;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #001030;
    border-radius: 2px;
  }

  .white-tooltip-theme .tippy-content {
    padding: 10px 9px;
    max-width: 240px;
    font-family: "BurbankSmall-Medium";
  }

  .white-tooltip-theme .tippy-content p {
    margin-bottom: 0;
  }

  .white-tooltip-theme .tippy-content a {
    font-family: "Burbank-Big-Rg-Bd";
    color: #001030;
    text-decoration: underline;
  }

  .white-tooltip-theme .tippy-content a:hover {
    text-decoration: none;
  }

  .white-tooltip-theme .tippy-tooltip[data-placement^="top"] .tippy-arrow {
    border-top-color: #fff;
  }

  .bil_bttn {
    margin-bottom: 15px;
    padding: 20px 20px;
    display: block;
    border: 1px dashed rgba(255, 255, 255, 0.2);
    color: #ffffff;
    text-decoration: none;
    transition: all 0.2s;
  }

  .bil_bttn:hover .bil_bttn_inn {
    opacity: 0.75;
  }

  .bil_bttn_inn {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    transition: all 0.2s;
  }

  .bil_bttn_l {
    margin-right: 20px;
  }

  .bil_bttn_r span {
    display: block;
    margin: 2px 0 0;
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  }

  .bil_bttn_icon {
    position: relative;
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
  }

  .bil_bttn_icon:before {
    content: "";
    position: absolute;
    top: 7px;
    left: 13px;
    width: 2px;
    height: 12px;
    margin-left: -1px;
    background-color: #fff;
  }

  .bil_bttn_icon:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 7px;
    width: 12px;
    height: 2px;
    margin-top: -3px;
    background-color: #fff;
  }

  .bil_modal_hdr {
    margin: 0 0 14px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
  }

  .bil_modal_hdr_i {
    margin-bottom: 10px;
  }

  .bil_modal_hdr_ttl {
    margin: 0 0;
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 35px;
    color: #001030;
  }

  .bttn_blue-light {
    padding: 2px 0 0;
    width: 115px;
    height: 36px;
    display: block;
    background: rgba(73, 195, 253, 0.1);
    color: #49c3fd;
    text-align: center;
    text-decoration: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    transition: all 0.2s;
    font-family: "Burbank-Big-Cd-Bd";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .bttn_blue-light:hover {
    background: rgba(73, 195, 253, 1);
    color: #fff;
  }

  .bil_modal-container .window__header {
    font-weight: 500;
    font-size: 21px;
    line-height: 35px;
  }

  .bil_modal-container__window {
    height: auto;
    max-height: calc(100% - 100px);
    overflow: auto;
  }
  @media only screen and (max-width: 767px) {
    .bil_modal-container__window {
    }
  }

  .bil_modal-container__window .window__container {
    position: static;
    margin: 0 auto;
    padding: 0 20px 25px 20px;
  }

  .bil_modal-container__window .window__header {
    margin-bottom: 30px;
  }

  .bil_modal_btns {
    margin: 10px -5px 10px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
  }

  .bil_modal_btns_i {
    width: 180px;
    max-width: 180px;
    padding: 5px 5px;
  }

  .bttn_blue-outline {
    position: static;
    padding: 2px 0 0;
    display: block;
    width: 100%;
    height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    border: 1px solid #49c3fd;
    border-radius: 2px;
    font-family: "Burbank-Big-Cd-Bd";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #49c3fd;
  }

  .bttn_blue-outline:before,
  .bttn_blue-outline:after {
    display: none;
  }

  .bttn_blue-outline:hover {
    color: #fff;
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  }

  .bttn_blue-primery {
    padding: 2px 0 0;
    display: block;
    height: 40px;
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    border: none;
    border-radius: 2px;
    font-family: "Burbank-Big-Cd-Bd";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
    transition: all 0.2s;
  }

  .bttn_blue-primery:hover {
    opacity: 0.8;
  }

  .form-card-data {
    margin-left: -5px;
    margin-right: -5px;
  }

  .form-card-data .col-3,
  .form-card-data .col-6 {
    padding-left: 5px;
    padding-right: 5px;
    @media only screen and (max-width: 767px) {
      margin-top: 12px;
    }
  }

  .form-card-data .col-6 {
    -ms-flex: 0 0 calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }

  .form-card-data_slash {
    padding: 0 1px;
    font-size: 20px;
    line-height: 20px;
  }

  @media (max-width: 379px) {
    .form-card-data {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .form-card-data .col-3 {
      -ms-flex: 0 0 40%;
      flex: 0 0 40%;
      max-width: 40%;
    }
    .form-card-data .col-6 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .bil_modal-container--small .bil_modal-container__window {
    max-width: 490px;
    width: 100%;
  }

  /*.select2_container_style-white*/

  /*.select2_dropdown_style-white*/

  .select2-container {
    z-index: 100000;
  }

  .f_select2-white-style {
    margin: 0 0 25px;
  }

  .f_select2-white-style .select2-container.select2-container--default {
    width: 100%;
  }

  .select2_container_style-white .select2-selection__rendered {
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #001030;
  }

  .f_select2-white-style .select2-selection--single {
    padding: 14px 12px;
    height: 45px;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgba(0, 16, 48, 0.05);
  }

  .select2_dropdown_style-white {
    margin: 2px 0;
    border: none;
  }

  .select2_dropdown_style-white .select2-search.select2-search--dropdown {
    display: none;
  }

  .select2_dropdown_style-white .select2-results__option {
    padding: 10px 17px;
    font-family: "BurbankSmall-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #001030;
    background: rgba(0, 16, 48, 0.05);
    transition: all 0.2s;
  }

  .select2_dropdown_style-white .select2-results__option--highlighted {
    background: rgba(73, 195, 253, 0.2);
  }

  .select2_dropdown_style-white .select2-results__option[aria-selected="true"] {
    background: rgba(0, 16, 48, 0.05);
  }

  .f_select2-white-style .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 30px;
    top: 8px;
    right: 13px;
    width: 20px;
  }

  .f_select2-white-style .select2-container--default .select2-selection--single .select2-selection__arrow b {
    width: 9px;
    height: 5.5px;
    border: none;
    outline: none;
    background-color: transparent;
    background: url(#{$cdn}/img/arrow_top-black.svg);
    transform: rotate(180deg);
    margin-left: -7px;
    margin-top: -3px;
    transition: all 0.3s;
  }

  .f_select2-white-style
    .select2-container--default.select2-container--open
    .select2-selection--single
    .select2-selection__arrow
    b {
    transform: rotate(0deg);
  }

  .modal .window__subheader {
    font-size: 14px;
    line-height: 14px;
    color: rgba(0, 16, 48, 0.7);
    margin-bottom: 12px;
    text-align: left;
    margin: 0px;
    padding: 10px 0px;
  }

  .bil_modal-container__window {
    height: auto !important;
    max-height: calc(100% - 100px);
    overflow: auto;
  }
  .bil_modal-container__windowNew {
    height: auto !important;
    max-height: calc(100% - 100px);
    overflow: auto;
  }
  .modal-container--opened .modal-container__background {
    width: 100%;
    height: 100%;
    animation: showBackground 350ms ease-in-out;
  }

  .article__big-subheader span {
    font-size: 16px;
    line-height: 16px;
    opacity: 0.4;
    margin-right: 20px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: block;
  }

  .article__big-subheader--no-lines,
  .article__big-subheader span {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    color: #fff;
  }

  .article__big-subheader div {
    width: 100%;
    height: 1px;
    background: #fff;
    opacity: 0.2;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: #ccc;
  }

  .article .article__header {
    font-family: Burbank-Big-Cd-Bd, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #fff;
    margin-right: 15px;
  }

  .article .ql-toolbar-buttons {
    border-right: 1px solid hsla(0, 0%, 100%, 0.2);
    padding-right: 12px;
    margin-right: 12px;
    color: white;
    fill: white;
    stroke: white;
  }

  .article .ql-snow.ql-toolbar button svg,
  .article .ql-snow .ql-toolbar button svg .article .ql-formats,
  .article .ql-toolbar-buttons {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: white;
    fill: white;
    stroke: white;
  }

  .article .ql-toolbar {
    border: 0 !important;
    height: 50px;
    background: #4b5a7b48;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: auto;
    scrollbar-width: none;
    width: 100%;
  }

  .article .article__slides {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    position: relative;
    width: 600%;
  }

  .article .ql-fill {
    fill: #fff !important;
  }

  .article .ql-stroke {
    stroke: #fff !important;
  }

  .article .ql-toolbar.ql-snow .ql-picker-label {
    border: 1px solid transparent;
  }

  .article .preview__text {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
  }

  .article .preview__canvas {
    z-index: 5;
    cursor: -webkit-grab;
    cursor: grab;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
  }

  .article .preview__canvas,
  .preview__image {
    position: absolute;
    left: 0;
    top: 0;
  }

  .article .crop__recommended-size {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    opacity: 0.7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  hr.my-1.opectiy-7 {
    background: #f5f7fa14;
  }

  .table-lable {
    font-family: "BurbankSmall-Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #ffffff;
    background: transparent;
    opacity: 0.5;
  }

  .table-lable-next {
    font-family: "BurbankSmall-Bold";
    font-style: normal;
    font-weight: lighter;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #ffffff;
    background: transparent;
    opacity: 0.9;
  }

  h2.window__header {
    font-family: BurbankSmall-Medium, sans-serif;
    font-style: normal;
    font-size: 30px;
    line-height: 30px;
    color: #001030;
    margin-bottom: 22px;
    text-align: left;
    padding-left: 0px;
  }

  div#login-page {
    position: fixed;
    z-index: -1;
    height: 100vh;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  #edit-profile .modal-container__window {
    position: absolute;
    left: 50%;
    top: 50vh;
    width: 870px;
    height: calc(100% - 130px);
    max-width: 95vw;
    max-height: 95vh;
    background-color: white;
    z-index: 2;
    border-radius: 4px;
    transform: translate(-50%, -50%);
    overflow: visible;
  }
  #notic {
    font-size: 12px;
    a {
      color: white;
      text-decoration: underline;
      font-family: "Burbank-Big-Cd-Md", sans-serif;
      font-weight: bold;
    }
  }
  .window__button-edit-profile {
    width: 205px;
    height: 45px;
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    border-radius: 2px;
    font-family: "Burbank-Big-Cd-Bd", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0 30px 0;
  }
  .popup-title {
    display: flex;
  }
  .prof-btn {
    position: relative;
  }
  .window__profile-status-warning-container-edit-profile {
    position: absolute;
    width: 300px;
    top: -73px;
    left: -75%;
    z-index: 9999;
    background: #e13b4c;
    border-radius: 3px;
    padding: 5px 10px;
  }
  .window__profile-status-warning-container-edit-profile:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px;
    border-color: #e13b4c transparent transparent transparent;
    bottom: -19px;
  }
}

.wg-default,
.wg-default .country-selector {
  position: fixed;
  bottom: 5px;
  left: 1px;
  width: 117px;
}

@media screen and (max-width: 569px) and (min-width: 0px) {
  .wg-drop.country-selector a:after {
    display: none;
  }
  .wg-drop.country-selector:not(.closed) ul {
    display: block;
    background-color: #fff;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 50px;
  }
  .wg-default,
  .wg-default .country-selector {
    position: fixed;
    bottom: 5px;
    left: 1px;
    width: 48px;
  }
  .wg-drop.country-selector .wgcurrent:after {
    top: -2px;
    right: 0px;
  }
  .wg-drop.country-selector a {
    display: inline-grid;
    height: 30px;
    line-height: 45px;
    color: transparent;
    padding: 6px 3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 48px;
  }
  .withdraw {
    padding: 60px 0px 0px 16px;
  }
}
.old-kl-css .pfl_tran_i {
  -moz-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
}
.pfl_tran_i_l {
  width: 33%;
}
.pfl_tran_i_m {
  width: 33%;
}
