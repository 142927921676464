@import '../../../../../css/images';

.chatBlock {
  width: 100%;
  height: 70px;
  margin-top: -20px;
  position: relative;
  background: #eef6ff;
}

.chatBlockMessage {
  position: relative;
  width: 100%;
  height: 40px;
  background: rgba(255, 59, 76, 0.1);
  border-radius: 2px;
  margin: 15px 0;
  padding-left: 41px;
  padding-top: 14px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: #e13b4c;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    left: 15px;
    width: 16px;
    height: 16px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url($blocked);
  }
}