.offlinePage {
  .videoContainer {
    position: fixed;
    z-index: -1;
    height: 100vh;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    video {
      margin: auto;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: visible;
      height: auto;
      width: 100%;
      min-height: 100vh;
    }
  }

  .background {
    background-color: #051534;
    z-index: 0;
    opacity: 0.9;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .containerFluid {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    padding: 147px 120px 177px 120px;
    scrollbar-width: 0;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  .logo {
    width: 140px;
    height: 30px;
    background-image: url("https://kombatlink-assets.azureedge.net/img/logo.png");
    background-color: transparent;
    background-size: 100% 100%;
    flex-shrink: 0;
  }

  h1 {
    font-family: "Burbank-Big-Cd-Md", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 60px;
    letter-spacing: 0.05em;
    color: white;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
  }

  p {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: white;
    margin-bottom: 45px;
    white-space: pre-wrap;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 1199.98px) {
    .logo {
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: 991.98px) {
    h1 {
      font-size: 36px;
    }
    p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767.98px) {
    .containerFluid {
      padding: 60px 50px 60px 50px;
    }
    h1 {
      font-size: 26px;
      line-height: 33px;
    }
    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
