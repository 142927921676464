.old-kl-css {
  header .create-challenge a,
  .blue-button,
  .green-button,
  .red-button,
  .orange-button,
  .pink-button,
  .purple-button,
  .grey-button,
  .white-button,
  .green-hover-button {
    display: flex;
    display: -webkit-flex;
    display: -ms-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    border-radius: 2px;
    min-height: 40px;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    font-family: "Burbank-Big-Cd-Bd", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  header .create-challenge a,
  .blue-button {
    background: transparent;
    color: #49c3fd;
    border: 1px solid #49c3fd;
    transition: 150ms;
  }

  header .create-challenge a,
  .blue-button--filled {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    border: none;
    color: white;
  }

  .blue-button--filled-no-gradient {
    background: #49c3fd;
    border: none;
    color: white;
  }

  .blue-button--transparent-filled {
    background: linear-gradient(90deg, rgba(2, 183, 254, 0.1) 0%, rgba(3, 137, 253, 0.1) 100%);
    border: none;
  }

  header .create-challenge a,
  .blue-button:hover {
    color: white;
    text-decoration: none;
    background: #49c3fd;
  }

  .blue-button--filled:hover {
    background: linear-gradient(90deg, #13a9e4 0%, #0a71cb 100%);
  }

  .blue-button--transparent-filled:hover {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  }

  .blue-button--filled-no-gradient:hover {
    background: #0389fd;
  }

  .green-button {
    background: transparent;
    color: #6cd000;
    border: 1px solid #6cd000;
    transition: 150ms;
  }

  .green-button--filled {
    background: #6cd000;
    border: none;
    color: white;
  }

  .green-button:hover {
    background: #6cd000;
    color: white;
    text-decoration: none;
  }

  .green-button--filled:hover {
    background: #64bc04;
  }

  .right-menu__button.green-button:hover {
        background-image: linear-gradient(#6CD000 25%, #4E9301 100%);
        font-family: arial;
        min-height: 40px;
        letter-spacing: -0.5px;
    }

  .red-button {
    background: transparent;
    color: #e13b4c;
    border: 1px solid #e13b4c;
    transition: 150ms;
  }

  .red-button--filled {
    background: #e13b4c;
    border: none;
    color: white;
  }

  .red-button:hover {
    background: #e13b4c;
    color: white;
    text-decoration: none;
  }

  .red-button--filled:hover {
    background: #cb2f3f;
  }

  .orange-button {
    background: transparent;
    color: #ec5418;
    border: 1px solid #ec5418;
    transition: 150ms;
  }

  .orange-button--filled {
    background: linear-gradient(90deg, #ec5418 0%, #df2d06 100%);
    border: none;
    color: white;
  }

  .orange-button:hover {
    background: #ec5418;
    color: white;
    text-decoration: none;
  }

  .orange-button--filled:hover {
    background: linear-gradient(90deg, #df551f 0%, #c22c0b 100%);
  }

  .pink-button {
    background: transparent;
    color: #cb25b3;
    border: 1px solid #cb25b3;
    transition: 150ms;
  }

  .pink-button--filled {
    background: linear-gradient(90deg, #cb25b3 0%, #7d2eae 100%);
    border: none;
    color: white;
  }

  .pink-button:hover {
    background: #cb25b3;
    color: white;
    text-decoration: none;
  }

  .pink-button--filled:hover {
    background: linear-gradient(90deg, #a91493 0%, #640d9a 100%);
  }

  .purple-button {
    background: transparent;
    color: #3c3daa;
    border: 1px solid #3c3daa;
    transition: 150ms;
  }

  .purple-button--filled {
    background: #3c3daa;
    border: none;
    color: white;
  }

  .purple-button--transparent-filled {
    background: rgba(60, 61, 170, 0.05);
    color: #3c3daa;
    border: none;
  }

  .pink-button:hover {
    background: #3c3daa;
    color: white;
    text-decoration: none;
  }

  .pink-button--filled:hover {
    background: #3c3d9c;
  }

  .purple-button--transparent-filled:hover {
    background: #3c3daa;
    color: white;
  }

  .grey-button {
    background: transparent;
    color: white;
    border: 1px solid #4b566d;
    opacity: 0.5;
    transition: 150ms;
  }

  .grey-button--filled {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: white;
    opacity: 1;
    transition: none;
  }

  .grey-button:hover {
    color: white;
    text-decoration: none;
    border: 0 solid transparent;
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
    opacity: 1;
  }

  .grey-button--filled:hover {
    background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  }

  .white-button {
    background: transparent;
    color: white;
    border: 1px solid white;
    transition: 150ms;
  }

  .white-button:hover {
    color: #061235;
    text-decoration: none;
    background: white;
  }

  .green-hover-button:hover {
    background: #6cd000;
    border: none;
    color: white;
  }
}


.cancel-row {
  padding-top: 0.6rem !important;
  display: flex;
  align-items: center;
}

.org-row .cancel-row:hover {
  background: #303d55 !important;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.admin-row .cancel-row:hover{
  background: #fff !important;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.btn-wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 0;
}
.org-row .btn-hover-blue,
.org-row .btn-hover-red {
  border: none;
  padding-top: 5px;
  transition: 0.3s all ease-in-out;
  width: 47%;
  background-color: #4b566d !important;
  color: #fff;
}
.admin-row .btn-hover-blue ,
.admin-row .btn-hover-red {
  border: none;
  padding-top: 5px;
  transition: 0.3s all ease-in-out;
  width: 47%;
  color: #BCCADB;
  background-color: #F1F5FA !important;
}
.cancel-row:hover .btn-hover-blue {
 
  background: linear-gradient(to right,#02b5fd, #048bfe);
}

.cancel-row:hover .btn-hover-red {
  
  background: linear-gradient(to right,#db2953, #e3404a);
}




.cancel-row:hover .my-ticket-table__column--name {
  color: #43a1d3;
}




.modals_window__2qFBg {
  padding: 40px 15px;
}

.modals_window__subheader__K4GBT {
  width: 100%;
  text-align: center;
  font-size: 18px;
  
  font-weight: bold;
}

.modals_window__header__3w6Rb {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #001030;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
}
.modals_window__subheader__K4GBT {
  font-size: 16px;
  width: 100%;
  text-align: center;
}