
.article__selectsPopup {
  margin-top: 20px;
}

.article__select {
  width: calc(100% / 7);
}

.h2{
  font-family: 'BurbankSmall-Medium', sans-serif;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.05em;
    color: white;
    margin-top: 10px;
}

@media screen and (max-width: 1879.98px) and (min-width: 910px) {
  .article__select:nth-of-type(4),
  .article__select:nth-of-type(5),
  .article__select:nth-of-type(6),
  .article__select:nth-of-type(7) {
    width: calc(100% / 4);
  }
  .article__select:nth-of-type(1),
  .article__select:nth-of-type(2),
  .article__select:nth-of-type(3) {
    width: calc(100% / 3);
  }
}

@media screen and (max-width: 909.98px) and (min-width: 768px) {
  .article__select:nth-of-type(1),
  .article__select:nth-of-type(2),
  .article__select:nth-of-type(3),
  .article__select:nth-of-type(4) {
    width: calc(100% / 2);
  }
  .article__select:nth-of-type(5),
  .article__select:nth-of-type(6),
  .article__select:nth-of-type(7) {
    width: calc(100% / 3);
  }
}

@media screen and (max-width: 767.98px) and (min-width: 576px) {
  .article__select:nth-of-type(1) {
    width: 100%;
  }
  .article__select:nth-of-type(2),
  .article__select:nth-of-type(3),
  .article__select:nth-of-type(4),
  .article__select:nth-of-type(5),
  .article__select:nth-of-type(6),
  .article__select:nth-of-type(7) {
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 575.98px) {
  .article__select:nth-of-type(1),
  .article__select:nth-of-type(2),
  .article__select:nth-of-type(3),
  .article__select:nth-of-type(4),
  .article__select:nth-of-type(5),
  .article__select:nth-of-type(6),
  .article__select:nth-of-type(7) {
    width: 100%;
  }
}

.article__selectsPopup {
  z-index: 997;
}

@media screen and (max-width: 767.98px) {
  .article__select:last-of-type {
    margin-bottom: 80px;
  }
}


.clearFilters {
  color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-top: 10px;
  align-items: center;
  font-size: 20px;
  width: 100%;
  .clearFiltersText {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}