.tournamentCarouselCover, .coverWrapper {
  height: 540px;
}



.tournamentCarouselVideoCover {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media screen and (max-width: 2200px) {
  .tournamentCarouselCover, .coverWrapper {
    height: 452px;
  }
}

@media screen and (max-width: 2000px) {
  .tournamentCarouselCover, .coverWrapper  {
    height: 400px;
  }
}

@media screen and (max-width: 1800px) {
  .tournamentCarouselCover, .coverWrapper  {
    height: 362px;
  }
}

@media screen and (max-width: 1600px) {
  .tournamentCarouselCover, .coverWrapper  {
    height: 309px;
  }
}

@media screen and (max-width: 1400px) {
  .tournamentCarouselCover, .coverWrapper  {
    height: 339px;
  }
}

@media screen and (max-width: 1024px) {
  .tournamentCarouselCover, .coverWrapper  {
    height: 380px;
  }
}


@media screen and (max-width: 768px) {
  .tournamentCarouselCover, .coverWrapper  {
    height: 287px;
  }
}

@media screen and (max-width: 414px) {
  .tournamentCarouselCover, .coverWrapper  {
    height: 137px;
  }
}
