.articleSelect {
  padding-right: 0;
  padding-left: 15px;

  &:nth-of-type(1) {
    z-index: 999;
  }
  &:nth-of-type(2) {
    z-index: 998;
  }
  &:nth-of-type(3) {
    z-index: 997;
  }
  &:nth-of-type(4) {
    z-index: 996;
  }
  &:nth-of-type(5) {
    z-index: 995;
  }
  &:nth-of-type(6) {
    z-index: 994;
  }
  &:nth-of-type(7) {
    z-index: 993;
  }
  &:nth-of-type(8) {
    z-index: 992;
  }
  &:nth-of-type(9) {
    z-index: 991;
  }
  &:nth-of-type(10) {
    z-index: 990;
  }
}

.articleSelectTitle {
  font-family: "Burbank-Big-Rg-Md", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.05em;
  opacity: 0.7;
  margin: 0 0 10px 0;
}
