/*@import "../libs/search";
@import "../libs/modals";
@import "../libs/filters";
@import "../libs/pagination";
@import "../libs/select";*/

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.article__header {
  margin-bottom: 50px;
}

.article__select:nth-of-type(1),
.article__select:nth-of-type(2),
.article__select:nth-of-type(3),
.article__select:nth-of-type(4),
.article__select:nth-of-type(5) {
  width: calc(100% / 6);

  @media screen and (max-width: 1499.98px) and (min-width: 768px) {
    width: calc(100% / 3);
  }

  @media screen and (max-width: 767.98px) and (min-width: 576px) {
    width: calc(100% / 2);
  }

  @media screen and (max-width: 575.98px) and (min-width: 0) {
    width: 100%;
  }
}

.article__select:nth-of-type(6),
.article__select:nth-of-type(7) {
  width: calc(100% / 12);

  @media screen and (max-width: 1499.98px) and (min-width: 768px) {
    width: calc(100% / 6);
  }

  @media screen and (max-width: 767.98px) and (min-width: 576px) {
    width: calc(100% / 4);
  }

  @media screen and (max-width: 575.98px) and (min-width: 0) {
    width: calc(100% / 2);
  }
}

.article__tournamentContainer {
  margin-top: 30px;
  padding-bottom: 30px;
}

.article__hr {
  height: 1px;
  background: #25324e;
}

.tournamentInfo {
  padding: 20px 25px;
  background: rgba(255, 255, 255, 0.1);
  width: 486px;

  @media screen and (max-width: 1199.98px) {
    width: 100%;
    margin-bottom: 20px;
  }

  &__header {
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: white;
    margin-bottom: 15px;
  }

  &__label {
    width: 136px;
    height: 26px;
    border-radius: 2px;
    font-family: "BurbankSmall-Bold", sans-serif;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 26px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &__green {
      background: rgba(108, 208, 0, 0.15);
      color: #6cd000;
    }
  }

  &__openBracket {
    display: block;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: transparent;
      background-size: 18px 21px;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("https://kombatlink-assets.azureedge.net/img/bracket.svg");
      opacity: 0.6;
      transition: 150ms;
    }

    &:hover::after {
      opacity: 1;
    }
  }

  &__hr {
    height: 1px;
    margin: 21px -15px;
    background: white;
    opacity: 0.1;
  }

  &__info {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: white;

    &::before {
      display: inline-block;
      width: 63px;
      font-size: 12px;
      opacity: 0.4;
    }

    &__organizer {
      margin-bottom: 20px;
      &::before {
        content: "Organizer";
      }
    }

    &__venue::before {
      content: "Venue";
    }
  }

  &__winner {
    border-left: 1px solid rgba(255, 255, 255, 0.1);

    @media screen and (max-width: 575.98px) {
      border-left: none;
      padding-left: 5px;
      margin-top: 15px;
    }
  }

  &__winnerPicture {
    width: 22px;
    height: 22px;
    margin-right: 7px;
    border-radius: 50%;
  }
  &__winnerUsername {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }
  &__winnerPrize {
    position: absolute;
    right: -13px;
    top: 1px;
    width: 19px;
    height: 19px;
    background-size: 100% 100%;
    background-image: url("https://kombatlink-assets.azureedge.net/img/gold_cup.svg");
  }

  &__openWinners {
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: white;
    opacity: 0.5;
    margin: 13px 0 6px 0;
    transition: 150ms;

    &:hover {
      text-decoration: none;
      color: white;
      opacity: 1;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 9px;
      height: 8px;
      background-color: transparent;
      background-size: 100% 100%;
      background-image: url("https://kombatlink-assets.azureedge.net/img/arrow-right.svg");
      margin-left: 7px;
    }
  }

  &__notifierContainer {
    position: absolute;
    left: 50%;
    bottom: 100%;
    width: 171px;
    padding-bottom: 8px;
    transform: translate(-50%, 0);
    z-index: 9999;
    display: none;
    opacity: 0;

    &:hover {
      opacity: 1;
      display: block;
    }
  }

  &__openBracket:hover ~ &__notifierContainer {
    opacity: 1;
    display: block;
    animation: show 150ms ease-in-out;
  }

  &__notifierContent {
    background: white;
    border-radius: 2px;
    padding: 8px 16px;
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #001030;

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 50%;
      bottom: -1px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-top: 3px solid white;
      transform: translate(-50%, 0);
    }
  }
}

.movies {
  position: relative;
  width: calc(100% - 486px);

  &__select {
    width: 235px;
    height: 34px;
    background: #25324e;
    border-radius: 2px;
    margin-bottom: 0;
    z-index: 1;

    @media screen and (max-width: 575.98px) {
      width: 130px;
    }
  }

  &__next,
  &__prev {
    width: 34px;
    height: 34px;
    margin-left: 5px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    position: relative;
    transition: 150ms;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: transparent;
      background-size: 6px 10px;
      background-image: url("https://kombatlink-assets.azureedge.net/img/arrow_right-2.svg");
      background-repeat: no-repeat;
      background-position: center center;
      transition: 150ms;
    }

    &:hover {
      background: rgba(73, 195, 253, 0.15);

      &::after {
        background-image: url("https://kombatlink-assets.azureedge.net/img/blue_arrow_right_simple.svg");
      }
    }
  }

  &__prev {
    &::after {
      transform: rotate(180deg);
    }
  }
  &__slides {
    margin: 0;
    overflow: hidden;
    width: 100%;
  }
  &__movie {
    flex-shrink: 0;
    padding: 0 15px 0 0;
    margin-top: 20px;
    width: calc((100% + 15px) / 4);

    &:last-of-type {
      padding: 0;
      width: calc((100% + 15px) / 4 - 15px);
    }

    @media screen and (max-width: 1549.98px) and (min-width: 1300px) {
      width: calc((100% + 15px) / 3);

      &:last-of-type {
        padding: 0;
        width: calc((100% + 15px) / 3 - 15px);
      }
    }

    @media screen and (max-width: 1299.98px) and (min-width: 1200px) {
      width: calc((100% + 15px) / 2);

      &:last-of-type {
        padding: 0;
        width: calc((100% + 15px) / 2 - 15px);
      }
    }

    @media screen and (max-width: 849.98px) and (min-width: 600px) {
      width: calc((100% + 15px) / 3);

      &:last-of-type {
        padding: 0;
        width: calc((100% + 15px) / 3 - 15px);
      }
    }

    @media screen and (max-width: 599.98px) and (min-width: 400px) {
      width: calc((100% + 15px) / 2);

      &:last-of-type {
        padding: 0;
        width: calc((100% + 15px) / 2 - 15px);
      }
    }

    @media screen and (max-width: 399.98px) and (min-width: 0) {
      width: 100%;
      padding: 0;

      &:last-of-type {
        padding: 0;
        width: calc(100%);
      }
    }
  }
  &__stream {
    height: 132px;
    width: 100%;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
  }

  &__streamPreview {
    width: 100%;
    height: 100%;
    display: block;
    background-size: cover;
    background-position: center center;
    border-radius: 2px;
    cursor: default;

    &.hasPlayer {
      cursor: pointer;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% - 36px);
      background: rgba(0, 16, 48, 0.5);
      opacity: 0;
      transition: 200ms;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: calc(50% - 18px);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-size: 18px 18px;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: rgba(255, 255, 255, 0.25);
      background-image: url("https://kombatlink-assets.azureedge.net/img/play.svg");
      opacity: 0;
      transition: 200ms;
      transform: translate(-50%, -50%);
    }

    &.hasPlayer:hover::before,
    &.hasPlayer:hover::after {
      opacity: 1;
    }

    &:last-of-type {
      position: absolute;
      left: -25px;
      top: 0;
      transition: 150ms;

      div {
        opacity: 0;
        background: rgba(0, 16, 48, 0.5);
        width: 100%;
        height: calc(100% - 36px);
        position: absolute;
        display: block;
        transition: 150ms;
        z-index: 0;
      }
    }

    &:first-of-type {
      left: 0;
    }

    &:first-of-type:hover + &:last-of-type {
      left: -50px;

      div {
        opacity: 1;
      }
    }

  }

  &__streamAuthor {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(0, 16, 48, 0.8);
    border-radius: 0 0 2px 2px;
    padding: 0 10px;
    transition: 150ms;

    &--two {
      left: -25px;
      padding-left: 35px;
    }
  }

  &__stream-preview:first-of-type:hover ~ &__stream-author--two {
    left: -50px;
    padding-left: 60px;
  }

  &__streamProfilePicture {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 7px 0 0;
  }

  &__streamUsername {
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: white;
  }

  &__number {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    opacity: 0.3;

    span {
      flex-shrink: 0;
      display: block;
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 12px;
      color: white;
      padding-right: 10px;
      margin-top: 3px;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: white;
    }
  }
  &__details {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    color: white;
    cursor: pointer;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }
}

iframe {
  width: 100%;
}

.movies__select {
  .select__title {
    line-height: 34px;

    &::before {
      top: 13px;
    }
  }

  .select__container {
    top: 40px;
  }
}

.window {
  width: 900px;
  padding: 20px;
  max-height: 556px;
}

#winners .window {
  width: 666px;
  padding: 50px 40px;
  max-height: 556px;

  @media screen and (max-width: 575.98px) {
    padding: 30px 10px;
  }
}

#match-info .window {
  width: 870px;
  padding: 50px 35px;

  @media screen and (max-width: 575.98px) {
    padding: 30px 10px;
  }
}

.window {
  &__header {
    font-family: "BurbankSmall-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #001030;
    margin-bottom: 20px;
    padding: 0 20px 0 30px;
  }

  &__subheader {
    font-family: "Burbank-Big-Rg-Md", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #49c3fd;
    margin-bottom: 30px;
    padding: 0 30px;
  }

  .table__mainRow {
    height: 60px !important;
  }

  .table__mainRow,
  .table__secondaryRowContainer {
    background: #f5f5f5 !important;
    padding: 0 30px !important;

    @media screen and (max-width: 575.98px) {
      padding: 0 20px !important;
    }
  }

  .table__secondary-row {
    height: 50px !important;
  }

  @media screen and (max-width: 575.98px) {
    .table__mainRowClans {
      height: 100px !important;
    }
  }

  .table__secondaryRowContainer {
    padding: 0 0 0 76px !important;
  }

  .table__checkbox:checked ~ .table__mainRow,   .table__mainRow__opened  {
    border-bottom: 1px solid rgba(0, 16, 48, 0.05);
  }
  .table__checkbox:checked ~ .table__secondaryRowContainer {
    padding: 10px 0 10px 76px !important;
  }

  .table__open {
    border: 1px solid #e0e1e2;
    width: 36px;
    height: 32px;

    &::before {
      background-image: url("https://kombatlink-assets.azureedge.net/img/grey_arrow_top.svg");
    }
  }

  .table__checkbox:checked ~ .table__mainRow .table__open {
    background-color: rgba(0, 16, 48, 0.05);
  }

  &__label {
    width: 159px;
    height: 30px;
    background: rgba(73, 195, 253, 0.1);
    border-radius: 2px;
    text-align: center;
    font-family: "BurbankSmall-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #49c3fd;

    @media screen and (max-width: 575.98px) {
      margin-left: 30px !important;
    }
  }

  &__winner {
    &Pciture {
      width: 36px;
      height: 36px;
      border-radius: 50%;

      .table__mainRowClans &-pictire {
        @media screen and (max-width: 575.98px) {
          margin-bottom: 10px;
        }
      }

      @media screen and (max-width: 575.98px) {
        width: 30px;
        height: 30px;
      }
    }

    &Username {
      font-family: "Burbank-Big-Rg-Md", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #001030;
      margin-left: 10px;

      @media screen and (max-width: 575.98px) {
        font-size: 14px;
      }
    }

    &Label {
      width: 90px;
      height: 30px;
      border-radius: 2px;
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: white;
      background: #49c3fd;

      @media screen and (max-width: 575.98px) {
        width: 80px;
      }

      &__gold {
        background: #f9d100;
      }
      &__silver {
        background: #d4d4d4;
      }
      &__bronze {
        background: #c88d61;
      }
    }
  }

  &__player {
    height: 90px;
    padding: 0 30px;
    background: #f5f5f5;

    @media screen and (max-width: 991.98px) {
      margin-bottom: 10px !important;
    }
    @media screen and (max-width: 575.98px) {
      height: 70px;
      padding: 0 15px;
    }

    &-picture {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;

      @media screen and (max-width: 575.98px) {
        width: 36px;
        height: 36px;
      }
    }

    &Username {
      font-family: "Burbank-Big-Rg-Md", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #001030;

      @media screen and (max-width: 575.98px) {
        font-size: 14px;
      }
    }

    &-label {
      width: 90px;
      height: 30px;
      border-radius: 2px;
      text-align: center;
      font-family: "BurbankSmall-Bold", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 30px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      @media screen and (max-width: 575.98px) {
        width: 80px;
      }

      &--green {
        background: rgba(108, 208, 0, 0.2);
        color: #6cd000;
      }

      &--red {
        background: rgba(225, 59, 76, 0.2);
        color: #e13b4c;
      }
    }
  }
}

#match-info .window__header {
  font-family: "BurbankSmall-Bold", sans-serif;
  font-weight: bold;
}

.article__openSelects {
  margin-bottom: 10px;
}

.movies__stream__empty__header {
  font-family: "BurbankSmall-Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: white;
  padding-left: 5px;
}
.movies__stream__empty {
  display: flex;
  align-items: center;
}