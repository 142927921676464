.articleSelectSelect {
  background: #25324e;
  border-radius: 4px;
  margin: 0 0 20px 0;
}

.selectTitle {
  text-align: left;
  font-size: 14px;
  line-height: 40px;
  font-family: "Burbank Small", sans-serif;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 16px;
  color: rgba(255, 255, 255, 0.5);

  &.text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 10px);
  }
}

.arrow {
  width: 13px;
  height: 9px;
  background-image: url(https://kombatlink-assets.azureedge.net/img/arrow_down_white.svg);
  transition: 150ms;
}

.selectOpened {
  .arrow {
    transform: rotate(180deg);
  }

  &.below {
    .selectContainer {
      transform: translateY(calc(0% + 7px)) scaleY(1);
    }
  }

  &.above {
    .selectContainer {
      transform: translateY(calc(-100% - 7px)) scaleY(1);
    }
  }
}

.selectContainer {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  align-items: center;
  -webkit-align-items: center;
  width: 100%;
  transform: translateY(-50%) scaleY(0);
  transition: 200ms;
  background-color: #25324e;
  padding: 0;
  border-radius: 4px;
  max-height: 194px;
  overflow: hidden;
  scrollbar-width: none;
}

.scrollContainer {
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.select {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.selectOption {
  position: relative;
  height: 30px;
  width: 100%;
  font-size: 13px;
  line-height: 30px;
  font-family: "Burbank Small", sans-serif;
  color: white;
  cursor: pointer;
  text-align: left;
  padding: 0 0 0 15px;
  flex-shrink: 0;
  transition: 130ms;

  &:first-of-type {
    margin-top: 7px;
  }

  &:last-of-type {
    margin-bottom: 7px;
  }

  .tooltip {
    position: absolute;
    right: 15px;
    top: 8px;
  }

  .blocked {
    display: block;
    width: 15px;
    height: 15px;
    background-size: 5px 7px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("https://kombatlink-assets.azureedge.net/img/locked.svg");
    border-radius: 50%;
    opacity: 1 !important;
  }

  .info {
    background: rgba(255, 255, 255, 0.07);
    display: block;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("https://kombatlink-assets.azureedge.net/img/info.svg");
    border-radius: 50%;
    opacity: 1 !important;
  }

  .selectedTip {
    display: block;
    position: absolute;
    right: 16px;
    top: 10px;
    width: 12px;
    height: 9px;
    background-color: transparent;
    background-size: 100% 100%;
    background-image: url("https://kombatlink-assets.azureedge.net/img/blue_ok.svg");
    transition: 130ms;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.07);
  }

  &.selected {
    color: #49c3fd;
  }
}

.selectSearchContainer {
  width: 100%;
  min-height: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  padding: 0 6px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    background-color: transparent;
    background-size: 100% 100%;
    width: 10px;
    height: 10px;
    right: 16px;
    top: 20px;
    z-index: 1;
    background-image: url("https://kombatlink-assets.azureedge.net/img/search.svg");
    opacity: 0.3;
  }
}

.selectSearch {
  width: 100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  padding: 0 10px;
  font-family: "Burbank Small", sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  color: white;
  border: none;
  outline: none;
  z-index: 2;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.selectBlockedContainer {
  z-index: 9999;
  display: none;
  width: 200px;
  padding-bottom: 7px;
  animation: show 150ms ease-in-out;
  opacity: 1;

  &.show {
    display: block;
  }

  &:hover {
    display: block;
  }
}

.selectBlockedContent {
  background: #fff;
  display: block;
  width: 100%;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #001030;
  padding: 10px 0;
  position: relative;
  border-radius: 2px;
  text-align: center;

  .selectBlockedContentArrow {
    display: block;
    position: absolute;
    left: 8px;
    bottom: -11px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top: 4px solid white;
    transform: translate(-50%, 0);
  }
}

.selectBlockedLink {
  color: #3c3daa;
  text-decoration: underline;

  &:hover {
    color: #3c3daa;
    text-decoration: underline;
  }
}
