$compactTableWidth: 1449.98px;
$mobileTableWidth: 1299.98px;

@import "../../../../css/libs/dropDownTable";
@import "../../../../css/libs/tabMenu";

@media screen and (max-width: 1699.98px) {
    .right-menu {
        position: fixed;
        right: -255px;
        transition: 150ms;
        height: calc(100vh - 82px);
        overflow: auto;
        min-height: calc(100vh - 82px);
        top: 82px;
        z-index: 10000;
    }
    .right-menu--opened {
        right: 0;
    }
    .header__section--friends {
        display: flex;
    }
}

@media screen and (max-width: $mobileTableWidth) and (min-width: 0) {
    .table__secondary-row-container {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
}

@media screen and (max-width: 575.98px) and (min-width: 0) {
    .table__button {
        margin: 0 0 10px 0;
    }
    .table__open {
        margin: 0;
    }
}

.table__profile-picture {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
}
.table__column .chat-button {
    margin-left: 0;
    width: 140px;
    height: 40px;
    background: rgba(0, 0, 0, 0);
    color: #fff;
    border: 1px solid #4b566d;
    opacity: .5;
    -webkit-transition: .15s;
    -o-transition: .15s;
    transition: .15s;
  }
  
  .table__column .chat-button:hover {
    background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
    background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
    background: linear-gradient(90deg, #02b7fe, #0389fd);
    opacity: 1;
  }

  .btn-wrapper .table__button {
    width: 95px;
}
.battle-row .table__column {
    padding: 0 10px;
}
.battle-row .table__column .table__button {
    margin-left: 7px;
}
.battle-row .table__column a {
    display: block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.5em;
    height: 3em;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    word-break: break-all;
}
.battle-row .table__column a {
    display: -webkit-box;
    height: 100%;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.2;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.battle-row a {
    color: #fff;
    transition: 0.3s all ease-in-out;
}
.battle-row a:hover {
    color: #49c3fd;
    text-decoration: none;
}
@media(max-width: 2600px)
{
    .battle-row.finished-row .table__column:nth-child(6),
    .battle-row.finished-row .table__column:nth-child(7),
    .tbl-header .table__headers-row .table__column:nth-child(7) {
        text-align: center;
    }
}
@media(min-width: 1200px) and (max-width: 1300px)
{
    .battle-row .table__column {
        padding: 0 10px;
        width: calc(100% / 7);
        word-break: break-all;
    }
    .battle-row .table__column:last-child {
        width: 37%;
        padding-left: 0;
    }
    .battle-row .table__column:nth-child(5) {
        width: 20%;
    }
    .table__warning {
        top: 1px;
        margin-left: 12px;
       
    }
    .battle-row .table__column:nth-child(4) {
        width: 5%;
        text-align: center;
    }
    .battle-row .table__column:nth-child(3) {
        width: 16%;
        padding-right: 0;
    }
    .battle-row .table__column:nth-child(2) {
        width: 7%;
    }
    .battle-row .table__column:nth-child(1) {
        width: 20%;
    }
   
    .btn-wrapper .table__button {
        width: 97px;
        margin-left: 7px;
        line-height: 1;
        font-size: 12px
    }
    .table__headers-row .table__column {
        width: calc(100% / 7);
    }
    .table__headers-row .table__column:first-child {
        width: 19%;
    }
    .table__headers-row .table__column:nth-child(2) 
    {
        width:6%;
    }
    .table__headers-row .table__column:nth-child(3) {
        width: 14%;
        text-align: center;
    }
    .table__headers-row .table__column:nth-child(4) {
        width: 6%;
    }
    .table__headers-row .table__column:nth-child(5) {
        text-align: center;
    }
    .battle-row .table__column:nth-child(5) {
        width: 20%;
        text-align: center;
    }
    .battle-row.finished-row .table__column:nth-child(2) {
        width: 20%;
    }
    .battle-row.finished-row .table__column:nth-child(1) {
        width: 35%;
    }
    .battle-row.finished-row .table__column:nth-child(3) {
        text-align: center;
    }
    .battle-row.finished-row .table__column:nth-child(4) {
        width: 21%;
    }
    .battle-row.finished-row .table__column:last-child {
        width: 30%;
    }
    .battle-row.finished-row .table__column:nth-child(6),.battle-row.finished-row .table__column:nth-child(7) {
        text-align: center;
    }
    .finished-row .table__button {
        width: 100px;
    }
    
    .tbl-header .table__headers-row .table__column:nth-child(6) {
        width: 8%;
    }
    .tbl-header .table__headers-row .table__column:nth-child(7) {
        width: 23% !important;
        text-align: left;
    }

}
@media(max-width: 1201px)
{
    .btn-wrapper {
        margin-top: 15px !important;
    }
}
@media(max-width: 1199px)
{
    .battle-row.finished-row .table__column:nth-child(6), .battle-row.finished-row .table__column:nth-child(7), .tbl-header .table__headers-row .table__column:nth-child(7) {
        text-align: left;
    }
}
@media(max-width:767px)
{
    .battle-row .table__column .table__button {
        margin-left: 0;
    }
    .table__row .table__column::before {
        width: 78px;
    }
    .battle-row .table__column a {
        width: 100%;
        text-align: left;
        margin-bottom: 5px;
    }
    .btn-wrapper .table__button {
        width: 106px;
        margin-left: 0px !important;
        margin-right: 5px;
    }
}
@media(max-width: 575px)
{
    .btn-wrapper .table__button
    {
        width: 150px !important;
    }
}
@media(max-width: 480px)
{
    .btn-wrapper .table__button {
        width: 150px !important;
        margin-left: 0px !important;
        margin-right: 0;
    }
}