

.pageHead {
  color: white;
  background-image: linear-gradient(179deg, #02b9ff 0%, #0388fd 82%);
  border-left: 1px solid #103598;
  width: 100%;
  padding: 10px;

  h1 {
    margin-top: 0.83em;
    margin-bottom: 0.83em;
  }
}


.pageContent {
  padding: 2rem 0;
}