@import "../../vars";

.old-kl-css {
   .loginSignUpbtn {
      margin-right: 12px;
      color: white;
      margin: 10px;
      border: 1px solid transparent;
      padding: 2px 12px 0px 12px;
      border-radius: 5px;
      opacity: 0.5;
   }

   .trim-data {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   nav.active ul li span {
      display: block;
   }

   nav.active ul li {
      height: 84px;
   }

   nav.active {
      width: 120px;
   }

   @media screen and (min-width: 1015px) {
      nav.active ul li span {
         display: block;
      }
      nav.active ul li {
         height: 84px;
      }
      nav.active {
         width: 120px;
      }
      #lft-menu {
         width: 120px;
      }
   }

   .recaptcha {
      position: fixed;
      bottom: 40px;
      right: 17px;
      z-index: 2;
   }

   .cart-icon {
      background-image: url(#{$cdn}/img/cart.svg);
      background-repeat: no-repeat;
      width: 34px;
      height: 34px;
      margin: 0px 5px 0px 5px;
   }

   .cart-icon:before {
      content: "";
   }

   .cart-icon {
      transform: scaleY(0.8);
      margin-top: -1px;
   }

   button :hover {
      z-index: 9999999;
   }

   @media screen and (min-width: 760px) {
      .friend-list-container {
         overflow-y: scroll;
         width: 230px;
         margin-left: -20px;
         padding: 5px;
      }
   }

   @media screen and (max-width: 759px) {
      .friend-list-container {
         overflow-y: scroll;
         width: 100%;
         margin-left: -20px;
         padding: 5px;
      }
   }

   @media screen and (min-width: 1301px) {
      .header__section--friends {
         display: none;
      }
      .footer {
         height: 101px;
         background: #020b1c;
         padding: 0 30px;
         margin-right: -25px;
      }
   }

   @media screen and (max-width: 1300px) {
      .footer {
         height: 20vh;
         background: #020b1c;
         padding: 0 30px;
         margin-right: -25px;
      }
   }

   @media screen and (min-width: 793px) {
      .footer {
         height: 85px;
         background: #020b1c;
         padding: 0px 30px 30px 30px;
         margin-right: -25px;
      }
   }

   .header__notification {
      cursor: pointer;
      z-index: 9;
   }

   .mobile-menu-button {
      position: absolute;
      left: 22px;
      z-index: 1001;
      top: 20px;
   }

   .hamburger {
      display: inline-block;
      cursor: pointer;
      transition-property: opacity, filter;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      font: inherit;
      color: #fff;
      text-transform: none;
      background-color: transparent;
      border: 0;
      margin: 0;
      overflow: visible;
   }

   .hamburger-box {
      width: 40px;
      height: 24px;
      display: inline-block;
      position: relative;
   }

   .hamburger--spring.is-active .hamburger-inner {
      transition-delay: 0.22s;
      background-color: inherit;
   }

   .hamburger--spring .hamburger-inner {
      top: 2px;
      transition: background-color 0s 0.13s linear;
   }

   .hamburger-inner,
   .hamburger-inner::before,
   .hamburger-inner::after {
      width: 26px;
      height: 2px;
      background-color: #fff;
      border-radius: 0;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
   }

   .hamburger-inner {
      display: block;
      top: 50%;
      margin-top: -2px;
   }

   .hamburger-inner::after {
      bottom: -10px;
   }

   .hamburger-inner::before,
   .hamburger-inner::after {
      content: "";
      display: block;
   }

   .header1 .logo {
      width: 170px;
      height: 41px;
      margin: 0 auto;
      position: relative;
      top: 0px;
      z-index: 1001;
   }

   .header1 .logo a {
      width: 100%;
      height: 100%;
      float: left;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url(#{$cdn}/img/logo.png);
   }

   .header1 .guest {
      float: right;
      display: none;
      visibility: hidden;
      padding-top: 20px;
   }

   .header1 .guest a.btn.login {
      border: 1px solid rgba(255, 255, 255, 0.1);
      margin-right: 10px;
      height: 39px;
   }

   .header1 .guest a.btn {
      float: left;
      height: 38px;
      line-height: 40px;
      padding: 0 30px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
      letter-spacing: -0.3px;
      transition: 0.3s all;
      font-size: 14px;
      border-radius: 2px;
   }

   .header1 .guest a.btn.signup {
      background-image: -webkit-linear-gradient(46deg, #02b9ff 0%, #0388fd 100%);
      background-image: -o-linear-gradient(46deg, #02b9ff 0%, #0388fd 100%);
      background-image: linear-gradient(136deg, #02b9ff 0%, #0388fd 100%);
   }

   .header1 .mobile-menu ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
   }

   .header1 ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
   }

   .header1 .mobile-menu.active {
      display: inline-block;
      visibility: visible;
   }

   .header1 .mobile-menu {
      width: 100%;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      background-image: url(#{$cdn}/img/opacity-background.png);
      background-size: cover;
      float: left;
      z-index: 1000;
      padding: 60px 15px 30px 15px;
      display: none;
      visibility: hidden;
   }

   .header1 .mobile-menu ul li {
      width: 100%;
      display: block;
      float: left;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.3px;
      color: #fff;
      line-height: 43px;
      text-transform: uppercase;
   }

   .header1 li {
      display: list-item;
      text-align: -webkit-match-parent;
   }

   .header1 {
      width: 100%;
      height: 60px;
      display: block;
      float: left;
      position: fixed;
      top: 5px;
      z-index: 9999999;
      background-color: rgba(0, 14, 39, 0.92);
      padding: 15px 0px 0px 0px;
   }

   .header1 .mobile-menu ul li span i {
      color: #fff;
      width: 100%;
      float: left;
      line-height: 35px;
      text-align: center;
   }

   .header1 .mobile-menu ul li span {
      float: left;
      width: 42px;
      height: 42px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 100px;
      margin-right: 15px;
      margin-top: 4px;
   }

   .close {
      position: absolute;
      right: 32px;
      top: 32px;
      width: 32px;
      height: 32px;
      opacity: 0.3;
      content: "\00d7";
   }

   .close:hover {
      opacity: 1;
   }

   .hamburger--spring.is-active .hamburger-inner:after {
      transform: rotate(-45deg);
   }

   .hamburger--spring.is-active .hamburger-inner:before {
      transform: rotate(45deg);
   }

   .icon-dropdown-account:before {
      content: "\e804";
   }

   [class^="icon-"]:before,
   [class*=" icon-"]:before {
      font-family: "kombatlink-icon";
      font-style: normal;
      font-weight: 400;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
   }

   .header1 .mobile-menu ul li a {
      width: 100%;
      height: 100%;
      float: left;
      padding: 15px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      color: white;
   }

   .header1 img {
      margin: 0px 0px 10px 10px;
      width: 50%;
      transform: scale3d(1.5, 1.5, 1.5);
   }

   .header1 .hamburger--spring.is-active .hamburger-inner::before {
      top: 0px;
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
         transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 10px, 0) rotate(45deg);
      background-color: #fff;
   }

   @media screen and (min-width: 990px) {
      .header1 {
         display: none;
      }
   }

   ul li .profile-dropdown__icon {
      opacity: 1;
      font-size: 16px;
      line-height: 35px;
      width: 46px;
      margin-right: 35px;
      font-family: kombatlink-icon, monospace;
      color: #fff;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      padding-left: 13px;
      padding-top: 2px;
   }

   .mobile-menu.active {
      overflow-y: scroll;
      z-index: 1000;
      opacity: 1;
   }

   @media screen and (max-width: 1300px) {
      .footer {
         height: 20vh;
         background: #020b1c;
         padding: 0px 30px 0px 68px;
         margin-right: -25px;
      }
   }

   nav.col-lg.col-md-12.header__navigation {
      z-index: 99999;
   }

   @media screen and (max-width: 575px) {
      header.header1 {
         background-color: rgb(1, 16, 111);
      }
   }

   @media screen and (min-width: 576px) and (max-width: 767px) {
      header.header1 {
         background-color: rgb(1, 16, 111);
      }
   }

   @media screen and (max-width: 970px) {
      nav.mobile-menu {
         position: fixed;
         right: 0px;
         top: 0px;
         left: 0px;
         background: #010f2d;
         z-index: 999999;
      }
   }

   .mobile-menu.active {
      position: relative;
      padding-top: 0px;
      padding-bottom: 4em;
   }

   .container-fluid.m-t-sm-60.m-t-lg-82.p-l-lg-135.p-l-sm-85.p-l-sm-65 {
      overflow-x: hidden;
   }

   .footer1 {
      background: #020b1c;
      margin-left: -2em;
      margin-right: -2em;
      padding-right: 5px;
      margin-bottom: 6px;
      margin-bottom: 2px;
      margin-top: calc(100% - 95%);
   }

   .header1 {
      width: 100%;
      height: 60px;
      display: block;
      float: left;
      position: fixed;
      top: 5px;
      z-index: 9999999;
      background-color: rgb(34, 23, 142);
      padding: 15px 0px 0px 0px;
   }

   nav ul li i.icon-nav-challenge {
      font-size: 23px;
      top: -2px;
   }

   .icon-nav-challenge:before {
      background-image: url(#{$cdn}/img/white_add.svg);
   }

   .header__profile-dropdown.profile-dropdown {
      width: 230px;
   }

   @media screen and (max-width: 1199.98px) and (min-width: 0) {
      .article-header {
         overflow: hidden;
      }
      .article-header__consoles-container {
         overflow: visible;
         min-height: 100%;
         -webkit-box-flex: 0;
         -ms-flex-positive: 0;
         flex-grow: 0;
         -ms-flex-negative: 2;
         flex-shrink: 2;
         -ms-flex-preferred-size: 46px;
         flex-basis: 46px;
         width: 100%;
         margin-bottom: 60px;
      }
      .article-header__consoles-container .row {
         width: auto;
         height: 30px;
      }
      .article-header__container {
         padding-bottom: 20px;
      }
      .article-header__registration-container {
         padding: 0;
         margin-bottom: 100px;
      }
      .article-header__content {
         background: -o-linear-gradient(
               top,
               rgba(0, 0, 0, 0) calc(100% - 54px),
               #051534 calc(100% - 54px),
               #051534 100%
            ),
            -o-linear-gradient(bottom, rgba(0, 20, 60, 0.6), rgba(0, 20, 60, 0.6));
      }
      .article-header__change-header {
         margin-bottom: 50px;
      }
   }

   input::-webkit-inner-spin-button,
   input::-webkit-outer-spin-button {
      -webkit-appearance: none;
   }

   .article .green-button--filled {
      background: #6cd000;
      border: none;
      color: #fff;
   }

   .article .green-button:hover {
      background: #6cd000;
      color: #fff;
      text-decoration: none;
   }

   .article .green-button--filled:hover {
      background: #64bc04;
   }

   .red-button {
      background: rgba(0, 0, 0, 0);
      color: #e13b4c;
      border: 1px solid #e13b4c;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .red-button--filled {
      background: #e13b4c;
      border: none;
      color: #fff;
   }

   .red-button:hover {
      background: #e13b4c;
      color: #fff;
      text-decoration: none;
      background: #cb2f3f;
   }

   .orange-button {
      background: rgba(0, 0, 0, 0);
      color: #ec5418;
      border: 1px solid #ec5418;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .orange-button--filled {
      background: -webkit-gradient(linear, left top, right top, from(#ec5418), to(#df2d06));
      background: -o-linear-gradient(left, #ec5418 0, #df2d06 100%);
      background: linear-gradient(90deg, #ec5418, #df2d06);
      border: none;
      color: #fff;
   }

   .orange-button:hover {
      background: #ec5418;
      color: #fff;
      text-decoration: none;
      background: -webkit-gradient(linear, left top, right top, from(#df551f), to(#c22c0b));
      background: -o-linear-gradient(left, #df551f 0, #c22c0b 100%);
      background: linear-gradient(90deg, #df551f, #c22c0b);
   }

   .pink-button {
      background: rgba(0, 0, 0, 0);
      color: #cb25b3;
      border: 1px solid #cb25b3;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .pink-button--filled {
      background: -webkit-gradient(linear, left top, right top, from(#cb25b3), to(#7d2eae));
      background: -o-linear-gradient(left, #cb25b3 0, #7d2eae 100%);
      background: linear-gradient(90deg, #cb25b3, #7d2eae);
      border: none;
      color: #fff;
   }

   .pink-button:hover {
      background: #cb25b3;
      color: #fff;
      text-decoration: none;
   }

   .pink-button--filled:hover {
      background: -webkit-gradient(linear, left top, right top, from(#a91493), to(#640d9a));
      background: -o-linear-gradient(left, #a91493 0, #640d9a 100%);
      background: linear-gradient(90deg, #a91493, #640d9a);
   }

   .purple-button {
      background: rgba(0, 0, 0, 0);
      color: #3c3daa;
      border: 1px solid #3c3daa;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .purple-button--filled {
      background: #3c3daa;
      border: none;
      color: #fff;
   }

   .purple-button--transparent-filled {
      background: rgba(60, 61, 170, 0.05);
      color: #3c3daa;
      border: none;
   }

   .purple-button:hover {
      background: #3c3daa;
      color: #fff;
      text-decoration: none;
   }

   .purple-button--filled:hover {
      background: #3c3d9c;
   }

   .purple-button--transparent-filled:hover {
      background: #3c3daa;
      color: #fff;
   }

   .select2-place_ttl {
      color: rgba(0, 16, 48, 1);
   }

   .select2-container--default .select2-results__option[aria-disabled="true"] {
      color: rgba(0, 16, 48, 0.5);
   }

   .select2-container--default .select2-results__option[aria-disabled="true"] .select2-place_ttl {
      opacity: 0.5;
   }

   .select2-dropdown {
      background: #fff;
      border-left: 1px solid rgba(0, 16, 48, 0.5);
      border-right: 1px solid rgba(0, 16, 48, 0.5);
   }

   .select2-dropdown.select2-dropdown--below {
      border-bottom: 1px solid rgba(0, 16, 48, 0.5);
   }

   .select2-dropdown.select2-dropdown--above {
      border-top: 1px solid rgba(0, 16, 48, 0.5);
   }

   .select2-container--default .select2-results__option--highlighted[aria-selected] {
      background: rgba(73, 195, 253, 0.1);
   }

   .select2-container--default .select2-results__option[aria-selected="true"] {
      background: rgba(73, 195, 253, 0.3);
   }

   .select2-results .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background: rgba(0, 16, 48, 0.3);
   }

   .mCustomScrollbar {
      -ms-touch-action: pinch-zoom;
      touch-action: pinch-zoom;
   }

   .mCustomScrollbar.mCS_no_scrollbar,
   .mCustomScrollbar.mCS_touch_action {
      -ms-touch-action: auto;
      touch-action: auto;
   }

   .mCustomScrollBox {
      position: relative;
      overflow: hidden;
      height: 100%;
      max-width: 100%;
      outline: 0;
      direction: ltr;
   }

   .mCSB_container {
      overflow: hidden;
      width: auto;
      height: auto;
   }

   .mCSB_inside > .mCSB_container {
      margin-right: 30px;
   }

   .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
      margin-right: 0;
   }

   .mCS-dir-rtl > .mCSB_inside > .mCSB_container {
      margin-right: 0;
      margin-left: 30px;
   }

   .mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
      margin-left: 0;
   }

   .mCSB_scrollTools {
      position: absolute;
      width: 16px;
      height: auto;
      left: auto;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.75;
      filter: "alpha(opacity=75)";
      -ms-filter: "alpha(opacity=75)";
   }

   .mCSB_outside + .mCSB_scrollTools {
      right: -26px;
   }

   .mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
   .mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
      right: auto;
      left: 0;
   }

   .mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
      left: -26px;
   }

   .mCSB_scrollTools .mCSB_draggerContainer {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: auto;
   }

   .mCSB_scrollTools a + .mCSB_draggerContainer {
      margin: 20px 0;
   }

   .mCSB_scrollTools .mCSB_draggerRail {
      width: 2px;
      height: 100%;
      margin: 0 auto;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
   }

   .mCSB_scrollTools .mCSB_dragger {
      cursor: pointer;
      width: 100%;
      height: 30px;
      z-index: 1;
   }

   .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      position: relative;
      width: 4px;
      height: 100%;
      margin: 0 auto;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
      text-align: center;
   }

   .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      .mCSB_dragger_bar,
   .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_dragger
      .mCSB_dragger_bar {
      width: 12px;
   }

   .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      + .mCSB_draggerRail,
   .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
      width: 8px;
   }

   .mCSB_scrollTools .mCSB_buttonDown,
   .mCSB_scrollTools .mCSB_buttonUp {
      display: block;
      position: absolute;
      height: 20px;
      width: 100%;
      overflow: hidden;
      margin: 0 auto;
      cursor: pointer;
   }

   .mCSB_scrollTools .mCSB_buttonDown {
      bottom: 0;
   }

   .mCSB_horizontal.mCSB_inside > .mCSB_container {
      margin-right: 0;
      margin-bottom: 30px;
   }

   .mCSB_horizontal.mCSB_outside > .mCSB_container {
      min-height: 100%;
   }

   .mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
      margin-bottom: 0;
   }

   .mCSB_scrollTools.mCSB_scrollTools_horizontal {
      width: auto;
      height: 16px;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
   }

   .mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
   .mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
      bottom: -26px;
   }

   .mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
      margin: 0 20px;
   }

   .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
      width: 100%;
      height: 2px;
      margin: 7px 0;
   }

   .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
      width: 30px;
      height: 100%;
      left: 0;
   }

   .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
      width: 100%;
      height: 4px;
      margin: 6px auto;
   }

   .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      .mCSB_dragger_bar,
   .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_dragger
      .mCSB_dragger_bar {
      height: 12px;
      margin: 2px auto;
   }

   .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      + .mCSB_draggerRail,
   .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
      height: 8px;
      margin: 4px 0;
   }

   .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
   .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
      display: block;
      position: absolute;
      width: 20px;
      height: 100%;
      overflow: hidden;
      margin: 0 auto;
      cursor: pointer;
   }

   .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
      left: 0;
   }

   .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
      right: 0;
   }

   .mCSB_container_wrapper {
      position: absolute;
      height: auto;
      width: auto;
      overflow: hidden;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-right: 30px;
      margin-bottom: 30px;
   }

   .mCSB_container_wrapper > .mCSB_container {
      padding-right: 30px;
      padding-bottom: 30px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
   }

   .mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
      bottom: 20px;
   }

   .mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
      right: 20px;
   }

   .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
      bottom: 0;
   }

   .mCS-dir-rtl
      > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside
      > .mCSB_scrollTools.mCSB_scrollTools_horizontal,
   .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden
      + .mCSB_scrollTools
      ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
      right: 0;
   }

   .mCS-dir-rtl
      > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside
      > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
      left: 20px;
   }

   .mCS-dir-rtl
      > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside
      > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden
      + .mCSB_scrollTools
      ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
      left: 0;
   }

   .mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
      margin-right: 0;
      margin-left: 30px;
   }

   .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
      padding-right: 0;
   }

   .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
      padding-bottom: 0;
   }

   .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
      margin-right: 0;
      margin-left: 0;
   }

   .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
      margin-bottom: 0;
   }

   .mCSB_scrollTools,
   .mCSB_scrollTools .mCSB_buttonDown,
   .mCSB_scrollTools .mCSB_buttonLeft,
   .mCSB_scrollTools .mCSB_buttonRight,
   .mCSB_scrollTools .mCSB_buttonUp,
   .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      -webkit-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
      -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
      -o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
      transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
   }

   .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
   .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
   .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
   .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
      -webkit-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s,
         margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
         opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
      -moz-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s,
         margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
         opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
      -o-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s,
         margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
         opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
      transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s,
         margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
         opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
   }

   .mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
   .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
      opacity: 0;
      filter: "alpha(opacity=0)";
      -ms-filter: "alpha(opacity=0)";
   }

   .mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
   .mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools,
   .mCustomScrollBox:hover > .mCSB_scrollTools,
   .mCustomScrollBox:hover ~ .mCSB_scrollTools,
   .mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
   .mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
      opacity: 1;
      filter: "alpha(opacity=100)";
      -ms-filter: "alpha(opacity=100)";
   }

   .mCSB_scrollTools .mCSB_draggerRail {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.4);
      filter: "alpha(opacity=40)";
      -ms-filter: "alpha(opacity=40)";
   }

   .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.75);
      filter: "alpha(opacity=75)";
      -ms-filter: "alpha(opacity=75)";
   }

   .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.85);
      filter: "alpha(opacity=85)";
      -ms-filter: "alpha(opacity=85)";
   }

   .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.9);
      filter: "alpha(opacity=90)";
      -ms-filter: "alpha(opacity=90)";
   }

   .mCSB_scrollTools .mCSB_buttonDown,
   .mCSB_scrollTools .mCSB_buttonLeft,
   .mCSB_scrollTools .mCSB_buttonRight,
   .mCSB_scrollTools .mCSB_buttonUp {
      background-repeat: no-repeat;
      opacity: 0.4;
      filter: "alpha(opacity=40)";
      -ms-filter: "alpha(opacity=40)";
   }

   .mCSB_scrollTools .mCSB_buttonUp {
      background-position: 0 0;
   }

   .mCSB_scrollTools .mCSB_buttonDown {
      background-position: 0 -20px;
   }

   .mCSB_scrollTools .mCSB_buttonLeft {
      background-position: 0 -40px;
   }

   .mCSB_scrollTools .mCSB_buttonRight {
      background-position: 0 -56px;
   }

   .mCSB_scrollTools .mCSB_buttonDown:hover,
   .mCSB_scrollTools .mCSB_buttonLeft:hover,
   .mCSB_scrollTools .mCSB_buttonRight:hover,
   .mCSB_scrollTools .mCSB_buttonUp:hover {
      opacity: 0.75;
      filter: "alpha(opacity=75)";
      -ms-filter: "alpha(opacity=75)";
   }

   .mCSB_scrollTools .mCSB_buttonDown:active,
   .mCSB_scrollTools .mCSB_buttonLeft:active,
   .mCSB_scrollTools .mCSB_buttonRight:active,
   .mCSB_scrollTools .mCSB_buttonUp:active {
      opacity: 0.9;
      filter: "alpha(opacity=90)";
      -ms-filter: "alpha(opacity=90)";
   }

   .mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.15);
   }

   .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.75);
   }

   .mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: rgba(0, 0, 0, 0.85);
   }

   .mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: rgba(0, 0, 0, 0.9);
   }

   .mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -80px 0;
   }

   .mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -80px -20px;
   }

   .mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -80px -40px;
   }

   .mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -80px -56px;
   }

   .mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-light-2.mCSB_scrollTools .mCSB_draggerRail {
      width: 4px;
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
      -webkit-border-radius: 1px;
      -moz-border-radius: 1px;
      border-radius: 1px;
   }

   .mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      width: 4px;
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.75);
      -webkit-border-radius: 1px;
      -moz-border-radius: 1px;
      border-radius: 1px;
   }

   .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
      width: 100%;
      height: 4px;
      margin: 6px auto;
   }

   .mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.85);
   }

   .mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.9);
   }

   .mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -32px 0;
   }

   .mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -32px -20px;
   }

   .mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -40px -40px;
   }

   .mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -40px -56px;
   }

   .mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.1);
      -webkit-border-radius: 1px;
      -moz-border-radius: 1px;
      border-radius: 1px;
   }

   .mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.75);
      -webkit-border-radius: 1px;
      -moz-border-radius: 1px;
      border-radius: 1px;
   }

   .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.85);
   }

   .mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.9);
   }

   .mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -112px 0;
   }

   .mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -112px -20px;
   }

   .mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -120px -40px;
   }

   .mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -120px -56px;
   }

   .mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail {
      width: 4px;
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
   }

   .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      width: 6px;
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.75);
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
   }

   .mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
      width: 100%;
      height: 4px;
      margin: 6px 0;
   }

   .mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
      width: 100%;
      height: 6px;
      margin: 5px auto;
   }

   .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.85);
   }

   .mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.9);
   }

   .mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -16px 0;
   }

   .mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -16px -20px;
   }

   .mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -20px -40px;
   }

   .mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -20px -56px;
   }

   .mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.1);
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
   }

   .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.75);
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
   }

   .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.85);
   }

   .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.9);
   }

   .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -96px 0;
   }

   .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -96px -20px;
   }

   .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -100px -40px;
   }

   .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -100px -56px;
   }

   .mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
   }

   .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      width: 2px;
   }

   .mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
      width: 100%;
   }

   .mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
      width: 100%;
      height: 2px;
      margin: 7px auto;
   }

   .mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.15);
   }

   .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.75);
   }

   .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.85);
   }

   .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.9);
   }

   .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -80px 0;
   }

   .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -80px -20px;
   }

   .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -80px -40px;
   }

   .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -80px -56px;
   }

   .mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.15);
   }

   .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger,
   .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
   .mCS-rounded.mCSB_scrollTools .mCSB_dragger {
      height: 14px;
   }

   .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      width: 14px;
      margin: 0 1px;
   }

   .mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
   .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
   .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
   .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger {
      width: 14px;
   }

   .mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
      height: 14px;
      margin: 1px 0;
   }

   .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      .mCSB_dragger_bar,
   .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_dragger
      .mCSB_dragger_bar,
   .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      .mCSB_dragger_bar,
   .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_dragger
      .mCSB_dragger_bar {
      width: 16px;
      height: 16px;
      margin: -1px 0;
   }

   .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      + .mCSB_draggerRail,
   .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_draggerRail,
   .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      + .mCSB_draggerRail,
   .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_draggerRail {
      width: 4px;
   }

   .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      .mCSB_dragger_bar,
   .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_dragger
      .mCSB_dragger_bar,
   .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      .mCSB_dragger_bar,
   .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_dragger
      .mCSB_dragger_bar {
      height: 16px;
      width: 16px;
      margin: 0 -1px;
   }

   .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      + .mCSB_draggerRail,
   .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_draggerRail,
   .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      + .mCSB_draggerRail,
   .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_draggerRail {
      height: 4px;
      margin: 6px 0;
   }

   .mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
      background-position: 0 -72px;
   }

   .mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
      background-position: 0 -92px;
   }

   .mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: 0 -112px;
   }

   .mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
      background-position: 0 -128px;
   }

   .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.75);
   }

   .mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.15);
   }

   .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.85);
   }

   .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.9);
   }

   .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -80px -72px;
   }

   .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -80px -92px;
   }

   .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -80px -112px;
   }

   .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -80px -128px;
   }

   .mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail,
   .mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail {
      width: 4px;
   }

   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
      background-color: transparent;
      background-position: center;
   }

   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail {
      background-repeat: repeat-y;
      opacity: 0.3;
      filter: "alpha(opacity=30)";
      -ms-filter: "alpha(opacity=30)";
   }

   .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
      height: 4px;
      margin: 6px 0;
      background-repeat: repeat-x;
   }

   .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -16px -72px;
   }

   .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -16px -92px;
   }

   .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -20px -112px;
   }

   .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -20px -128px;
   }

   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -96px -72px;
   }

   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -96px -92px;
   }

   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -100px -112px;
   }

   .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -100px -128px;
   }

   .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-repeat: repeat-y;
      background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
      background-image: -webkit-gradient(
         linear,
         left top,
         right top,
         color-stop(0, rgba(255, 255, 255, 0.5)),
         color-stop(100%, rgba(255, 255, 255, 0))
      );
      background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
      background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
      background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
   }

   .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
      background-repeat: repeat-x;
      background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
      background-image: -webkit-gradient(
         linear,
         left top,
         left bottom,
         color-stop(0, rgba(255, 255, 255, 0.5)),
         color-stop(100%, rgba(255, 255, 255, 0))
      );
      background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
      background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
      background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
   }

   .mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger,
   .mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger {
      height: 70px;
   }

   .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
   .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger {
      width: 70px;
   }

   .mCS-3d-dark.mCSB_scrollTools,
   .mCS-3d.mCSB_scrollTools {
      opacity: 1;
      filter: "alpha(opacity=30)";
      -ms-filter: "alpha(opacity=30)";
   }

   .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
   }

   .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
      width: 8px;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
   }

   .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
   .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
   .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
   .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #555;
   }

   .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      width: 8px;
   }

   .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail {
      width: 100%;
      height: 8px;
      margin: 4px 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
   }

   .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
      width: 100%;
      height: 8px;
      margin: 4px auto;
   }

   .mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -32px -72px;
   }

   .mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -32px -92px;
   }

   .mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -40px -112px;
   }

   .mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -40px -128px;
   }

   .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.1);
      box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
   }

   .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
   }

   .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -112px -72px;
   }

   .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -112px -92px;
   }

   .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -120px -112px;
   }

   .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -120px -128px;
   }

   .mCS-3d-thick-dark.mCSB_scrollTools,
   .mCS-3d-thick.mCSB_scrollTools {
      opacity: 1;
      filter: "alpha(opacity=30)";
      -ms-filter: "alpha(opacity=30)";
   }

   .mCS-3d-thick-dark.mCSB_scrollTools,
   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer,
   .mCS-3d-thick.mCSB_scrollTools,
   .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
   }

   .mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical,
   .mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical {
      right: 1px;
   }

   .mCS-3d-thick-dark.mCSB_scrollTools_vertical,
   .mCS-3d-thick.mCSB_scrollTools_vertical {
      box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
   }

   .mCS-3d-thick-dark.mCSB_scrollTools_horizontal,
   .mCS-3d-thick.mCSB_scrollTools_horizontal {
      bottom: 1px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
   }

   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
      width: 12px;
      margin: 2px;
      position: absolute;
      height: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
   }

   .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
      height: 12px;
      width: auto;
   }

   .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
   .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #555;
   }

   .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.05);
      box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
   }

   .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
      background-color: transparent;
   }

   .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -32px -72px;
   }

   .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -32px -92px;
   }

   .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -40px -112px;
   }

   .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -40px -128px;
   }

   .mCS-3d-thick-dark.mCSB_scrollTools {
      box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
   }

   .mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
   }

   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
   }

   .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
   }

   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #777;
   }

   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
      background-color: #fff;
      background-color: rgba(0, 0, 0, 0.05);
      box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
   }

   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-minimal.mCSB_scrollTools .mCSB_draggerRail {
      background-color: transparent;
   }

   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -112px -72px;
   }

   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -112px -92px;
   }

   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -120px -112px;
   }

   .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -120px -128px;
   }

   .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical,
   .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
      right: 0;
      margin: 12px 0;
   }

   .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
   .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
   .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
   .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
      bottom: 0;
      margin: 0 12px;
   }

   .mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical,
   .mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
      left: 0;
      right: auto;
   }

   .mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger,
   .mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger {
      height: 50px;
   }

   .mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
   .mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger {
      width: 50px;
   }

   .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.2);
      filter: "alpha(opacity=20)";
      -ms-filter: "alpha(opacity=20)";
   }

   .mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.5);
      filter: "alpha(opacity=50)";
      -ms-filter: "alpha(opacity=50)";
   }

   .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.2);
      filter: "alpha(opacity=20)";
      -ms-filter: "alpha(opacity=20)";
   }

   .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.5);
      filter: "alpha(opacity=50)";
      -ms-filter: "alpha(opacity=50)";
   }

   .mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
      width: 6px;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.2);
   }

   .mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      width: 6px;
   }

   .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
      width: 100%;
      height: 6px;
      margin: 5px 0;
   }

   .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      + .mCSB_draggerRail,
   .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
   .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      + .mCSB_draggerRail,
   .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_draggerRail {
      width: 12px;
   }

   .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      + .mCSB_draggerRail,
   .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_draggerRail,
   .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_dragger.mCSB_dragger_onDrag_expanded
      + .mCSB_draggerRail,
   .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
      .mCSB_draggerContainer:hover
      .mCSB_draggerRail {
      height: 12px;
      margin: 2px 0;
   }

   .mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -32px -72px;
   }

   .mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -32px -92px;
   }

   .mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -40px -112px;
   }

   .mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -40px -128px;
   }

   .mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.75);
   }

   .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.85);
   }

   .mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.9);
   }

   .mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.1);
   }

   .mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -112px -72px;
   }

   .mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -112px -92px;
   }

   .mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -120px -112px;
   }

   .mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -120px -128px;
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-inset.mCSB_scrollTools .mCSB_draggerRail {
      width: 12px;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.2);
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      width: 6px;
      margin: 3px 5px;
      position: absolute;
      height: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
   }

   .mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
      height: 6px;
      margin: 5px 3px;
      position: absolute;
      width: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
   }

   .mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
   .mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail {
      width: 100%;
      height: 12px;
      margin: 2px 0;
   }

   .mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
   .mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp,
   .mCS-inset.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -32px -72px;
   }

   .mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
   .mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown,
   .mCS-inset.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -32px -92px;
   }

   .mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
   .mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft,
   .mCS-inset.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -40px -112px;
   }

   .mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
   .mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight,
   .mCS-inset.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -40px -128px;
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
   .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.75);
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
   .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.85);
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
   .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.9);
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.1);
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp,
   .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp {
      background-position: -112px -72px;
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown,
   .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown {
      background-position: -112px -92px;
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft,
   .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft {
      background-position: -120px -112px;
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight,
   .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight {
      background-position: -120px -128px;
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
   .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
      background-color: transparent;
      border-width: 1px;
      border-style: solid;
      border-color: #fff;
      border-color: rgba(255, 255, 255, 0.2);
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
   }

   .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
      border-color: #000;
      border-color: rgba(0, 0, 0, 0.2);
   }

   .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.6);
   }

   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.6);
   }

   .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.75);
   }

   .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.85);
   }

   .mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.9);
   }

   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.75);
   }

   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.85);
   }

   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
   .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.9);
   }

   .white-tooltip-theme .tippy-tooltip {
      border: 1px solid #f0f3f6;
   }

   .adm .page .eli-page {
      margin-left: 0;
      margin-right: 0;
   }

   body.grid-full-screen .adm .page .eli-container {
      background: #f5f7fa;
   }

   @media (max-width: 1199.98px) {
      .adm .page .article-header__content {
         background: linear-gradient(180deg, rgba(0, 0, 0, 0) calc(100% - 54px), #f5f7fa calc(100% - 54px), #f5f7fa),
            linear-gradient(0deg, rgba(0, 20, 60, 0.6), rgba(0, 20, 60, 0.6));
      }
   }

   .adm .page .eli_usr_inn {
      opacity: 1;
      background: #fff;
   }

   .adm .page .eli_usr--hover .eli_usr_inn {
      background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
   }

   .eli_users-group--has-winner .eli_usr--hover .eli_usr_inn {
      opacity: 0.7;
   }

   .eli_users-group--has-winner .eli_usr--winner.eli_usr--hover .eli_usr_inn {
      opacity: 1;
   }

   .adm .page .eli_users-group_hdr {
      color: rgba(0, 16, 48, 0.3);
   }

   .adm .page .open_modal_checked {
      color: rgba(0, 16, 48, 0.3);
   }

   .adm .page .open_modal_checked:hover {
      color: rgba(0, 16, 48, 1);
   }

   .adm .page .prsn_ttl {
      color: #001030;
   }

   .adm .page .eli_usr:not(.eli_usr--hover) .eli_usr_m a.prsn:hover .prsn_ttl {
      color: #001030;
   }

   .adm .page .eli_usr_num {
      color: #001030;
      background: rgba(255, 255, 255, 0.15);
   }

   .adm .page .eli_usr--hover .prsn_ttl,
   .adm .page .eli_usr--hover .eli_usr_num {
      color: #fff;
   }

   .adm .page .eli_usr_place-1 {
      background: #f9d100;
      color: #fff;
   }

   .adm .page .eli_grid--elimination .eli_usr + .eli_usr {
      border-color: rgba(118, 128, 155, 0.2);
   }

   .eli_grid--elimination .eli_grid--elimination_col .eli_grid_users-group:before {
      background: rgba(118, 128, 155, 0.2);
   }

   .adm .page .eli_ttl_ttl {
      color: #001030;
   }

   .adm .page .eli_hdr {
      background: #fff;
   }

   .adm .page .eli_hdr_ttl,
   .adm .page .eli_hdr_date {
      color: rgba(0, 16, 48, 0.6);
   }

   .adm .page .eli_hdr_date {
      background: rgba(0, 16, 48, 0.07);
   }

   .adm .page .prsn_icon-indicator:after {
      border-color: transparent;
   }

   .adm .page .eli_users-group_hdr_line {
      background: rgba(118, 128, 155, 0.2);
   }

   .adm .page .eli_grid--extra .eli_usr + .eli_usr {
      border-top: 1px solid rgba(118, 128, 155, 0.2);
   }

   .adm .page .eli_grid--extra_col .eli_users-group_hdr {
      border-left: 1px solid rgba(118, 128, 155, 0.2);
   }

   .adm .page .eli_grid--extra_col .eli_usr {
      border-left: 1px solid rgba(118, 128, 155, 0.2);
   }

   .adm .page .eli_grid--extra .eli_grid--brackets_col .eli_grid_users-group:before,
   .adm .page .eli_grid--extra_col .eli_grid_users-group:before {
      background: rgba(118, 128, 155, 0.2);
   }

   .adm .page .scroll-block_left {
      background: linear-gradient(
         270deg,
         rgba(255, 255, 255, 1) 7.29%,
         rgba(255, 255, 255, 0.7) 50%,
         rgba(255, 255, 255, 0.01) 100%
      );
   }

   .adm .page .scroll-block_right {
      background: linear-gradient(
         270deg,
         rgba(255, 255, 255, 1) 7.29%,
         rgba(255, 255, 255, 0.7) 50%,
         rgba(255, 255, 255, 0.01) 100%
      );
   }

   .adm .page .scroll-block_right_arrow {
      background: url(#{$cdn}/img/caret-right-dark.svg);
      background-repeat: no-repeat;
      margin-top: -10px;
   }

   .scroll-block::-webkit-scrollbar {
      opacity: 0;
   }

   .btn-more-info_icon {
      background: url(#{$cdn}/img/info-dark.svg);
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
   }

   .adm .page .eli_search_input {
      color: #001030;
      background: #fff;
   }

   .adm .page .eli_search_input::placeholder {
      color: rgba(0, 16, 48, 0.5);
   }

   .adm .page .eli_search_input:-ms-input-placeholder {
      color: rgba(0, 16, 48, 0.5);
   }

   .adm .page .eli_search_input::-ms-input-placeholder {
      color: rgba(0, 16, 48, 0.5);
   }

   .adm .page .eli_search_btn {
      background-image: url(#{$cdn}/img/search-dark.svg);
   }

   .adm .page .eli_search__clean-button {
      background-image: url(#{$cdn}/img/cross-dark.svg);
   }

   .adm .page .eli_btn_screen {
      background: #fff;
   }

   .adm .page .eli_btn_screen path {
      fill: #001030;
   }

   .adm .page .eli_btn_screen:hover path {
      fill: #49c3fd;
   }

   .adm .page .eli_fscreen-hdr {
      background: #fff;
   }

   .adm .page .btn-winnr {
      color: rgba(118, 128, 155, 0.9);
      border-color: rgba(118, 128, 155, 0.7);
   }

   .adm .page .eli_usr--hover .btn-winnr {
      color: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, 1);
   }

   .adm .page .btn-winnr:hover {
      opacity: 1;
      border-color: #f9d100;
      color: #f9d100;
   }

   .adm .page .eli_usr_place-2 {
      background-color: rgba(192, 192, 192, 0.17);
   }

   .adm .page .eli_hdr_result {
      color: rgba(0, 16, 48, 0.6);
   }

   .adm
      .page
      .eli_usr:not(.eli_usr--hover)
      .select2-container--default
      .select2-selection--single
      .select2-selection__rendered {
      color: #001030;
   }

   .adm .page .eli_usr:not(.eli_usr--hover) .select2-container--default .select2-selection--single {
      border: 1px solid rgba(0, 16, 48, 0.5);
   }

   .adm
      .page
      .eli_usr:not(.eli_usr--hover)
      .select2-container--default
      .select2-selection--single
      .select2-selection__arrow
      b {
      background: url(#{$cdn}/img/arrow_top-black.svg);
   }

   .select2-place_ttl {
      color: rgba(0, 16, 48, 1);
   }

   .select2-container--default .select2-results__option[aria-disabled="true"] {
      color: rgba(0, 16, 48, 0.5);
   }

   .select2-container--default .select2-results__option[aria-disabled="true"] .select2-place_ttl {
      opacity: 0.5;
   }

   .select2-dropdown {
      background: #fff;
      border-left: 1px solid rgba(0, 16, 48, 0.5);
      border-right: 1px solid rgba(0, 16, 48, 0.5);
   }

   .select2-dropdown.select2-dropdown--below {
      border-bottom: 1px solid rgba(0, 16, 48, 0.5);
   }

   .select2-dropdown.select2-dropdown--above {
      border-top: 1px solid rgba(0, 16, 48, 0.5);
   }

   .select2-container--default .select2-results__option--highlighted[aria-selected] {
      background: rgba(73, 195, 253, 0.1);
   }

   .select2-container--default .select2-results__option[aria-selected="true"] {
      background: rgba(73, 195, 253, 0.3);
   }

   .select2-results .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background: rgba(0, 16, 48, 0.3);
   }

   .article-header__tab-menu-container {
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 0;
      width: 100%;
      height: 40px;
      padding: 0 60px;
   }

   .article-header__tab-menu-button {
      position: absolute;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-position: 50%;
      background-image: url(#{$cdn}/img/arrow_top.svg);
      border: none;
      cursor: pointer;
      bottom: 22px;
      width: 20px;
      height: 12px;
      display: none;
   }

   .article-header__tab-menu-button--left {
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      left: 30px;
   }

   .article-header__tab-menu-button--right {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      right: 30px;
   }

   .adm .tab-menu__link--active,
   .adm .tab-menu__link--active:hover {
      color: #49c3fd;
      opacity: 1;
   }

   .adm .tab-menu__link--active:before {
      background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
      background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
      background: linear-gradient(90deg, #02b7fe, #0389fd);
   }

   .crop__recommended-size {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 18px;
      color: #8e8e8e;
   }

   .crop__file {
      display: none;
   }

   .drop {
      height: 316px;
      border: 2px dashed #d7d7d7;
      position: relative;
      width: 100%;
      margin: 30px 0;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .drop__text {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 18px;
      color: #8e8e8e;
      margin-bottom: 10px;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .article__tournament-title-container {
      padding: 32px 0 0;
      border-top: 1px solid #e8ebef;
      margin: 20px 0 25px;
   }

   .article__tournament-title {
      font-family: Burbank-Big-Cd-Bd, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.05em;
      color: #334059;
   }

   .article__edit-tournament {
      width: 127px;
      height: 40px;
   }

   .article__edit-tournament:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 7px;
      margin-bottom: 2px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-image: url(#{$cdn}/img/pencil_white.svg);
   }

   .article__cancel-tournament {
      width: 157px;
      height: 40px;
   }

   .article-header {
      margin: 0;
      width: 100%;
      position: relative;
   }

   .article-header__background {
      position: relative;
      // height: 75vh;
      width: 100%;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0);
      background-size: cover;
      background-position: 50%;
   }

   .article .article-header__content {
      position: absolute;
      left: 15px;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 50px 60px;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, rgba(30, 44, 72, 0)), to(#041534)),
         -webkit-gradient(linear, left bottom, left top, from(rgba(0, 20, 60, 0.3)), to(rgba(0, 20, 60, 0.3)));
      background: -o-linear-gradient(top, rgba(30, 44, 72, 0) 20%, #041534 100%),
         -o-linear-gradient(bottom, rgba(0, 20, 60, 0.3), rgba(0, 20, 60, 0.3));
      background: linear-gradient(180deg, rgba(30, 44, 72, 0) 20%, #041534),
         linear-gradient(0deg, rgba(0, 20, 60, 0.3), rgba(0, 20, 60, 0.3));
      border-bottom: 1px solid #25324e;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
   }

   .article-header__container {
      padding-bottom: 100px;
      position: relative;
   }

   .article .article-header__header {
      font-family: BurbankSmall-Medium, sans-serif;
      color: #fff;
      font-size: 36px;
      line-height: 54px;
      font-weight: 700;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 80%;
      flex: 0 0 80%;
      width: 80%;
      margin-bottom: 80px;
   }

   .article-header__platform {
      width: 110px;
      height: 30px;
      background-color: #091937;
      border-radius: 2px;
      margin: 2.5px 5px 2.5px 0;
      background-position: 50%;
      background-size: auto 40%;
      background-repeat: no-repeat;
      -ms-flex-negative: 0;
      flex-shrink: 0;
   }

   .article-header__platform--cross-platform {
      background-image: url(#{$cdn}/img/cross_platform.svg);
   }

   .article-header__platform--xbox {
      background-image: url(#{$cdn}/img/xbox.svg);
   }

   .article-header__platform--ps {
      background-image: url(#{$cdn}/img/ps.svg);
   }

   .article-header__platform--pc {
      background-image: url(#{$cdn}/img/pc.svg);
   }

   .article-header__platform--nintendo {
      background-image: url(#{$cdn}/img/nintendo.svg);
   }

   .article-header__consoles-container,
   .article-header__header-container,
   .article-header__info-container {
      -webkit-box-flex: 0;
      -ms-flex: 0;
      flex: 0;
   }

   .article-header__info {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 15px;
      line-height: 18px;
      padding-right: 20px;
      height: 18px;
      text-transform: capitalize;
   }

   .article-header__info:before {
      content: "";
      display: inline-block;
      width: 21px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      background-repeat: no-repeat;
      background-position: 50%;
      -webkit-transform: translateY(3px);
      -ms-transform: translateY(3px);
      transform: translateY(3px);
      margin-right: 4px;
   }

   .article-header__info--date {
      color: #49c3fd;
   }

   .article-header__info--status {
      color: #6cd000;
   }

   .article-header__info--region {
      color: #fff;
   }

   .article-header__info--date:before {
      background-size: 15px 16px;
      background-image: url(#{$cdn}/img/blue_calendar.svg);
   }

   .article-header__info--status:before {
      background-size: 21px 14px;
      background-image: url(#{$cdn}/img/green_gamepad.svg);
   }

   .article-header__info--region:before {
      background-size: 12px 17px;
      background-image: url(#{$cdn}/img/location.svg);
   }

   .article-header__registration-container {
      -ms-flex-negative: 0;
      flex-shrink: 0;
   }

   .article-header__change-header {
      position: absolute;
      right: 30px;
      bottom: 20px;
      width: 152px;
      height: 30px;
      background: hsla(0, 0%, 100%, 0.2);
      border-radius: 2px;
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 30px;
      color: #fff;
      opacity: 0.5;
      padding: 0 0 0 34px;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      text-decoration: none;
   }

   .article-header__change-header:hover {
      color: #fff;
      opacity: 0.7;
      text-decoration: none;
   }

   .article-header__change-header:before {
      position: absolute;
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-image: url(#{$cdn}/img/camera.svg);
      left: 15px;
      top: 9px;
   }

   .article-detail__subheader {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 550;
      font-size: 16px;
      line-height: 16px;
      color: #001030;
      margin-bottom: 18px;
      margin-top: 58px;
   }

   .article-detail__container {
      background: #fff;
      width: 100%;
   }

   .article-detail__container--details {
      padding: 15px 20px;
      margin-bottom: 10px;
   }

   .article-detail__container--organizer {
      padding: 25px 30px;
   }

   .article-detail__container--clans {
      padding: 20px 45px;
      margin-bottom: 60px;
   }

   .article-detail__container--additional-info {
      padding: 20px;
      margin-bottom: 60px;
      font-size: 14px;
      line-height: 25px;
      color: rgba(5, 21, 52, 0.8);
   }

   .article-detail__container--additional-info,
   .article__detail-title {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 550;
   }

   .article__detail-title {
      font-size: 13px;
      line-height: 13px;
      color: rgba(5, 21, 52, 0.4);
      margin-bottom: 8px;
   }

   .article__detail-text {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 550;
      font-size: 14px;
      line-height: 14px;
      color: #051534;
   }

   .article__clan {
      margin-bottom: 10px;
   }

   .article__clan:last-of-type {
      margin-bottom: 0;
   }

   .article__clans-header {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #001030;
      margin-bottom: 22px;
   }

   .article__clans-header,
   .article__clans-link {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
   }

   .article__clans-link {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #49c3fd;
      margin-left: 10px;
   }

   .article__clans-link:hover {
      color: #49c3fd;
   }

   .article__clan-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
   }

   .article__clan-title {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #001030;
      margin-left: 12px;
   }

   .article__clans-button {
      position: relative;
      width: 157px;
      height: 40px;
      padding: 0 0 0 55px;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
      margin-right: -15px;
   }

   .article__clans-button:before {
      position: absolute;
      content: "";
      display: block;
      width: 14px;
      height: 12px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 14px 11px;
      background-image: url(#{$cdn}/img/chat.svg);
      left: 34px;
      top: 14px;
      background-repeat: no-repeat;
      background-position: 50%;
   }

   .adm .article__tab-menu-before-container {
      margin: 10rem 0rem 10rem;
      border-bottom: 1px solid #25324e;
   }

   .adm .tab-menu {
      position: relative;
      margin: 0;
      -ms-overflow-style: none;
   }

   .adm .tab-menu::-webkit-scrollbar {
      display: none;
   }

   .adm .tab-menu__link--notification:after {
      content: "";
      display: block;
      position: absolute;
      right: -7px;
      top: -7px;
      width: 5px;
      height: 5px;
      background: #49c3fd;
      opacity: 1;
      border-radius: 50%;
   }

   .adm .tab-menu__link--active {
      opacity: 1;
      color: #49c3fd;
   }

   .adm .tab-menu__link--active:before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 2px;
      background: #49c3fd;
   }

   .adm .tab-menu__link:hover {
      text-decoration: none;
      color: #fff;
      opacity: 0.9;
   }

   .adm .tab-menu__link--active:hover {
      color: #49c3fd;
      opacity: 1;
   }

   .adm .tab-menu__radio-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      display: -webkit-flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
   }

   .adm .tab-menu__radio {
      display: none;
   }

   .article__tab-menu-button {
      position: absolute;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-position: 50%;
      background-image: url(#{$cdn}/img/grey_arrow_top.svg);
      border: none;
      cursor: pointer;
      bottom: 18px;
      width: 20px;
      height: 12px;
      display: none;
   }

   .article__tab-menu-button--left {
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      left: 0;
   }

   .article__tab-menu-button--right {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      right: 0;
   }

   .article .tab-menu__radio-label {
      width: 14px;
      height: 15px;
      opacity: 0.4;
      border: 1px solid #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 10px 8px;
      background-position: 50%;
      background-repeat: no-repeat;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
      cursor: pointer;
   }

   .adm .tab-menu__radio:checked + .tab-menu__radio-label {
      background-color: #6cd000;
      border: 1px solid #6cd000;
      background-image: url(#{$cdn}/img/ok.svg);
      opacity: 1;
   }

   .article .tab-menu__radio-text {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 15px;
      line-height: 15px;
      color: #fff;
      opacity: 0.5;
      margin: 0 0 0 7px;
   }

   .adm .tab-menu__radio-container:first-of-type.adm .tab-menu__radio-text,
   .adm .tab-menu__radio-container:nth-of-type(2).adm .tab-menu__radio-text {
      margin: 0 20px 0 7px;
   }

   .adm .tab-menu__header-link {
      position: relative;
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #fff;
   }

   .adm .tab-menu__header-link:after {
      content: "";
      display: inline-block;
      margin-left: 10px;
      width: 13px;
      height: 11px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-image: url(#{$cdn}/img/arrow-right.svg);
   }

   .adm .tab-menu__header-link:hover {
      color: #fff;
      text-decoration: none;
   }

   @media screen and (max-width: 991.98px) {
      .article-header__tab-menu-button {
         bottom: 16px;
      }
      .adm .tab-menu {
         height: 100%;
      }
      .adm .tab-menu__link {
         padding: 0 0 15px;
      }
      .article__radio-container,
      .adm .tab-menu__events-container {
         -webkit-box-ordinal-group: 0;
         -ms-flex-order: -1;
         order: -1;
      }
      .article__radio-container {
         margin-bottom: 15px;
      }
   }

   @media screen and (max-width: 849.98px) {
      .article-header__tab-menu-button {
         display: block;
      }
      .article-header__tab-menu-container {
         -webkit-box-align: end;
         -ms-flex-align: end;
         align-items: flex-end;
         -webkit-align-items: flex-end;
      }
      .adm .tab-menu {
         height: 100%;
         overflow: hidden;
      }
   }

   @media screen and (max-width: 575.98px) {
      .article-header__tab-menu-container {
         padding: 0 30px;
      }
      .article-header__tab-menu-button--left {
         left: 5px;
      }
      .article-header__tab-menu-button--right {
         right: 5px;
      }
   }

   @media screen and (max-width: 349.98px) {
      .article__cancel-tournament {
         width: 150px;
      }
      .article__edit-tournament {
         width: 120px;
      }
   }

   @media screen and (max-width: 1649.98px) and (min-width: 0) {
      .article-header__header {
         width: 100%;
      }
   }

   @media screen and (max-width: 1199.98px) and (min-width: 0) {
      .article-header {
         overflow: hidden;
      }
      .article-header__content {
         border-bottom: 1px solid #c8d2dc;
      }
      .article-header__consoles-container {
         overflow: auto;
         height: 46px;
         padding-bottom: 10px;
         -webkit-box-flex: 0;
         -ms-flex-positive: 0;
         flex-grow: 0;
         -ms-flex-negative: 2;
         flex-shrink: 2;
         -ms-flex-preferred-size: 46px;
         flex-basis: 46px;
         width: 100%;
         margin-bottom: 30px;
      }
      .article-header__consoles-container .row {
         width: auto;
         height: 30px;
      }
      .article-header__container {
         padding-bottom: 20px;
      }
      .article-header__registration-container {
         padding: 0;
         margin-bottom: 100px;
      }
      .article-header__background {
         height: 75vh;
         margin-bottom: 54px;
      }
      .article-header__content {
         padding: 25px 60px 24px;
         background: -o-linear-gradient(
               top,
               rgba(0, 0, 0, 0) calc(100% - 54px),
               #f5f7fa calc(100% - 54px),
               #f5f7fa 100%
            ),
            -o-linear-gradient(bottom, rgba(0, 20, 60, 0.6), rgba(0, 20, 60, 0.6));
         background: linear-gradient(180deg, rgba(0, 0, 0, 0) calc(100% - 54px), #f5f7fa calc(100% - 54px), #f5f7fa),
            linear-gradient(0deg, rgba(0, 20, 60, 0.6), rgba(0, 20, 60, 0.6));
      }
      .article-header__header {
         font-size: 28px;
         line-height: 28px;
         margin-bottom: 30px;
      }
      .article-header__info-container {
         margin-bottom: 30px;
      }
      .adm .tab-menu__link {
         color: #001030;
         opacity: 0.9;
         padding-bottom: 18px;
      }
      .article-header__tab-menu-button {
         background-image: url(#{$cdn}/img/grey_arrow_top.svg);
      }
      .article-header__change-header {
         margin-bottom: 50px;
      }
   }

   @media screen and (max-width: 575.98px) and (min-width: 0) {
      .article-header__header {
         font-size: 25px;
         line-height: 25px;
         margin-bottom: 15px;
      }
      .article-header__info {
         margin-bottom: 5px;
      }
      .article-header__content {
         padding: 25px 20px;
      }
   }

   .structure__width-1 .structure__player {
      width: 36px;
      height: 5px;
      margin-bottom: 3px;
   }

   .structure__width-2 .structure__player {
      width: 45px;
      height: 8px;
      margin-bottom: 4px;
   }

   .col-12:last-of-type .structure__player {
      margin-bottom: 0;
   }

   .structure__director {
      display: block;
      border-radius: 50%;
      background: #e1e5fd;
   }

   .structure__director--blue {
      background: #49c3fd;
   }

   .structure__director--green {
      background: #6cd000;
   }

   .structure__director--purple {
      background: #3c3daa;
   }

   .structure__width-1 .structure__director {
      width: 5px;
      height: 5px;
   }

   .structure__width-2 .structure__director {
      width: 8px;
      height: 8px;
   }

   .structure__arrow {
      fill: none;
      stroke: #e1e5fd;
   }

   .structure__text {
      display: block;
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 13px;
      color: #fff;
      margin-right: 10px;
   }

   .article__checkbox:checked + .article__label .structure {
      opacity: 1;
   }

   .article__buttons-container {
      margin: 25px 0 40px;
      padding-top: 35px;
      border-top: 1px solid hsla(0, 0%, 100%, 0.2);
   }

   .article__buttons-container button {
      width: 140px;
      height: 40px;
      margin: 0;
   }

   .article__save-in-draft {
      margin-right: 10px;
   }

   .article__right-menu-ct-replacement {
      width: 550px;
   }

   .right-menu-ct__header-text {
      font-family: Burbank-Big-Cd-Bd, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: #fff;
      margin-bottom: 60px;
   }

   .right-menu-ct--preview .right-menu-ct__header-text {
      display: none;
   }

   .right-menu-ct__stage-round {
      position: relative;
      width: 20px;
      height: 20px;
      background: #fff;
      opacity: 0.2;
      margin: 0 25px 80px 5px;
      border-radius: 50%;
      -ms-flex-negative: 0;
      flex-shrink: 0;
   }

   .right-menu-ct__stage-round:after {
      content: "";
      position: absolute;
      top: 20px;
      left: 9px;
      width: 2px;
      height: 80px;
      background: #fff;
      display: block;
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
   }

   .right-menu-ct__stage-container:last-of-type .right-menu-ct__stage-round:after {
      display: none;
   }

   .right-menu-ct__stage-container--active .right-menu-ct__stage-round {
      width: 30px;
      height: 30px;
      background: #fff;
      border: 10px solid #49c3fd;
      opacity: 1;
      margin: 0 25px 80px 0;
   }

   .right-menu-ct__stage-container--active .right-menu-ct__stage-round:after {
      left: 4px;
      opacity: 0.2;
   }

   .right-menu-ct__stage-text {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 20px;
      line-height: 20px;
      color: #fff;
      opacity: 0.6;
      -ms-flex-negative: 0;
      flex-shrink: 0;
   }

   .right-menu-ct__stage-container--active .right-menu-ct__stage-text {
      line-height: 30px;
      opacity: 1;
   }

   .right-menu-ct__stage-container--done .right-menu-ct__stage-round {
      width: 30px;
      height: 30px;
      border: none;
      background: #6cd000;
      margin: 0 25px 80px 0;
      position: relative;
      opacity: 1;
   }

   .right-menu-ct__stage-container--done .right-menu-ct__stage-round:before {
      content: "";
      position: absolute;
      left: 10px;
      top: 11px;
      width: 11px;
      height: 9px;
      background-size: 100% 100%;
      background-color: rgba(0, 0, 0, 0);
      background-image: url(#{$cdn}/img/ok.svg);
   }

   .right-menu-ct__stage-container--done .right-menu-ct__stage-round:after {
      opacity: 0.2;
      left: 14px;
      top: 30px;
   }

   .right-menu-ct__stage-container--done .right-menu-ct__stage-text {
      line-height: 30px;
      opacity: 1;
      color: #6cd000;
   }

   .right-menu-ct__stage-link {
      width: 100%;
      text-align: right;
      display: none;
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 30px;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      color: #fff;
      height: 30px;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .right-menu-ct__stage-link:hover {
      color: #49c3fd;
   }

   .right-menu-ct__stage-container--done .right-menu-ct__stage-link {
      display: block;
   }

   .article__prev-stage {
      width: 140px;
      height: 40px;
   }

   .select__title-ct {
      height: 55px;
      line-height: 55px;
      font-size: 18px;
      font-family: BurbankSmall-Medium, sans-serif;
      letter-spacing: 0.05em;
   }

   .select__title-ct:before {
      top: 25px;
   }

   .ql-toolbar {
      width: 1140px;
   }

   .article__main-event-type-container {
      display: none;
   }

   .article__warning-icon:before {
      height: 2px;
      top: 3px;
   }

   .article__warning-icon:after {
      height: 6px;
      top: 7px;
   }

   .article__hr {
      width: 100%;
      height: 1px;
      background: hsla(0, 0%, 100%, 0.2);
      margin: 46px 30px 0 0;
   }

   .article__round-container:last-of-type {
      padding-bottom: 10px;
   }

   .article__round-container:last-of-type .article__hr {
      display: none;
   }

   .article__big-subheader {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      display: -ms-flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-items: center;
      position: relative;
      width: 100%;
      margin: 40px 0 9px;
   }

   .article__big-subheader--no-lines {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
   }

   .article__big-subheader span {
      font-size: 16px;
      line-height: 16px;
      opacity: 0.4;
      margin-right: 20px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      display: block;
   }

   .article__crop-ticket-picture {
      width: 750px;
   }

   .article__crop-cover-picture {
      width: 1140px;
   }

   .article__add-custom-field {
      margin-top: 49px;
      width: 140px;
      height: 40px;
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 0;
      color: #fff;
      text-transform: capitalize;
   }

   .article__add-custom-field:hover:before {
      background-image: url(#{$cdn}/img/dark_add.svg);
   }

   .article__custom-field-title {
      font-size: 13px;
      line-height: 12px;
      opacity: 0.4;
      margin-bottom: 7px;
   }

   .article__remove-custom-field {
      position: absolute;
      right: 20px;
      top: 19px;
      width: 24px;
      height: 24px;
      background: hsla(0, 0%, 100%, 0.1);
      border-radius: 50%;
      cursor: pointer;
      border: none;
      outline: none;
   }

   .error {
      display: none;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      margin: 40px 0 0;
   }

   .error__icon {
      width: 16px;
      height: 16px;
      background-color: #e13b4c;
      background-size: 6px 6px;
      background-image: url(#{$cdn}/img/cross-white.svg);
      background-position: 50%;
      margin-right: 10px;
      border-radius: 50%;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      background-repeat: no-repeat;
   }

   .error__text {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #e13b4c;
   }

   .article__remove-custom-field:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      top: 8px;
      left: 8px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-image: url(#{$cdn}/img/cross-white.svg);
   }

   @media screen and (max-width: 991.98px) {
      .p-l-sm-85 {
         padding-left: 85px;
      }
      .m-sm-20 {
         margin: 20px 0;
      }
      .m-b-sm-20 {
         margin-bottom: 20px;
      }
      .m-t-sm-50 {
         margin-top: 50px;
      }
      .tab-menu__link {
         padding: 0 0 15px;
      }
      .article-header__tab-menu-button {
         bottom: 16px;
      }
      .help__background-container {
         display: none;
      }
   }

   @media screen and (min-width: 1200px) {
      .h-xl-100 {
         height: 100%;
      }
      .m-l-xl-15 {
         margin-left: 15px;
      }
   }

   @media screen and (min-width: 992px) {
      .m-t-lg-82 {
         margin-top: 82px;
      }
      .h-lg-100 {
         height: 100%;
      }
      .m-md-0--15 {
         margin: 0 -15px;
      }
   }

   @media screen and (min-width: 576px) {
      .p-l-sm-20 {
         padding-left: 20px;
      }
      .p-l-sm-1 {
         padding-left: 1px;
      }
   }

   @media screen and (max-width: 575.98px) {
      .p-l-sm-65 {
         padding-left: 65px;
      }
      .article-header__tab-menu-container {
         padding: 0 55px;
      }
      .article-header__tab-menu-button--left {
         left: 12px;
      }
      .article-header__tab-menu-button--right {
         right: 12px;
      }
      .article-detail__container--organizer {
         padding-left: 12px;
         padding-right: 12px;
      }
      .article-detail__container--clans {
         padding-left: 30px;
         padding-right: 30px;
      }
      .article__location {
         padding: 20px 15px;
      }
      .help {
         padding: 0 35px;
      }
      .help__container {
         padding-top: 30px;
         padding-bottom: 30px;
      }
      .help__header {
         font-size: 22px;
         line-height: 30px;
      }
      .participants {
         margin: 0 -20px;
      }
      .preview-container {
         padding: 0 25px;
      }
      .article-header {
         width: calc(100% + 60px);
         -webkit-box-flex: 0;
         -ms-flex: 0 0 calc(100% + 60px);
         flex: 0 0 calc(100% + 60px);
         max-width: calc(100% + 60px);
      }
      .article__cancel,
      .article__go-to-premium {
         margin-top: 10px;
      }
      .article__width-2,
      .article__width-2 .react-datepicker__input-container {
         width: 100%;
         margin-right: 30px;
      }
      .article__width-1,
      .article__width-1 .react-datepicker__input-container {
         width: 132px;
      }
      .article__width-2 .article__width-2 {
         width: 100%;
         margin-right: 0;
      }
      .article__label-text {
         font-size: 14px;
      }
      .right-menu-ct-header {
         font-size: 30px;
         line-height: 30px;
      }
      .right-menu-ct__stage-round {
         width: 16px;
         height: 16px;
         margin-right: 15px;
         margin-left: 3px;
      }
      .right-menu-ct__stage-round:after {
         top: 22px;
         left: 6px;
      }
      .right-menu-ct__stage-container--active .right-menu-ct__stage-round {
         width: 22px;
         height: 22px;
         border: 7px solid #49c3fd;
         margin-right: 15px;
      }
      .right-menu-ct__stage-container--active .right-menu-ct__stage-round:after {
         top: 15px;
         left: 3px;
      }
      .right-menu-ct__stage-container--active .right-menu-ct__stage-text {
         line-height: 22px;
         font-size: 17px;
      }
      .right-menu-ct__stage-container--done .right-menu-ct__stage-round {
         width: 22px;
         height: 22px;
         margin-right: 15px;
      }
      .right-menu-ct__stage-container--done .right-menu-ct__stage-round:before {
         left: 6px;
         top: 7px;
      }
      .right-menu-ct__stage-container--done .right-menu-ct__stage-round:after {
         left: 10px;
         top: 22px;
      }
      .right-menu-ct__stage-container--done .right-menu-ct__stage-text {
         line-height: 22px;
         font-size: 17px;
         opacity: 1;
         color: #6cd000;
      }
      .right-menu-ct__stage-link {
         font-size: 15px;
         line-height: 22px;
         height: 22px;
      }
   }

   @media screen and (max-width: 767.98px) {
      .drop__icon,
      .drop__text {
         display: none;
      }
      .tab-menu__link {
         margin: 0 25px 0 0;
      }
      .article__clans-button {
         margin-bottom: 10px;
         margin-right: -15px;
         margin-top: 30px;
      }
      .help {
         padding: 0 50px;
      }
      .help__container {
         padding-top: 40px;
         padding-bottom: 40px;
      }
      .article-container {
         padding: 60px 35px 0;
      }
   }

   @media screen and (max-width: 849.98px) {
      .article-header__tab-menu-button {
         display: block;
      }
      .article-header__tab-menu-container {
         -webkit-box-align: end;
         -ms-flex-align: end;
         align-items: flex-end;
         -webkit-align-items: flex-end;
      }
      .tab-menu {
         height: 100%;
         overflow: hidden;
      }
   }

   @media screen and (max-width: 1649.98px) {
      .article-header__header {
         width: 100%;
      }
   }

   @media screen and (max-width: 1199.98px) and (min-width: 568px) {
      .article__header-container {
         min-height: 75vh;
         margin-bottom: -1px;
      }
      .article-header__header {
         font-size: 26px;
         line-height: 28px;
      }
   }
   @media screen and (max-width: 450px) {
      .article-header__background {
         min-height: 80px;
      }
   }
   @media screen and (max-width: 575.98px) and (min-width: 0) {
      .article-header__content {
         padding: 0em 1em 1em 1em;
      }
      .article-header__background {
         // min-height: 100vh;
         height: 100%; 
         min-height: 150px
      }
      .article-header__info {
         margin-bottom: 10px;
      }
      .article-header__header {
         font-size: 24px;
         line-height: 33px;
      }
      .registration__info {
         padding: 20px 30px 14px 20px;
      }
      .registration__column {
         padding-right: 40px;
      }
      .registration__players-container {
         padding: 0 5px 0 50px;
         position: relative;
         top: 8px;
      }
      .article-header__header,
      .article-header__info-container {
         margin: 5% 0%;
      }
   }

   @media screen and (max-width: 389.98px) {
      .registration__info {
         padding: 20px 20px 14px 12px;
      }
      .registration__column {
         padding-right: 20px;
      }
      .registration__players-container {
         padding: 0 5px 0 45px;
      }
      .registration__header {
         font-size: 20px;
      }
      .registration__time {
         font-size: 13px;
      }
      .registration__join {
         width: calc(100% - 20px);
         margin-left: 10px;
      }
   }

   @media screen and (max-width: 349.98px) {
      .registration__players-container {
         padding: 0 5px 0 40px;
      }
   }

   @media screen and (max-width: 667.98px) {
      .organizer__section {
         border-right: none;
         width: 100%;
         padding-bottom: 5px;
         margin-bottom: 10px;
         border-bottom: 1px solid #e8eef6;
      }
   }

   @media screen and (max-width: 1829.98px) {
      .article__clans-container {
         padding: 22px 35px 0;
      }
   }

   @media screen and (max-width: 1799.98px) {
      .article__container--additional-info {
         margin-bottom: 0;
      }
   }

   @media screen and (max-width: 1199.98px) {
      .help__background {
         width: 147px;
         height: 120px;
      }
      .table__secondary-row-container {
         padding: 15px 20px;
      }
      .table__main-row .table__column:not(.table__column--button) {
         padding: 0;
      }
      .table__column--button {
         padding-top: 7px;
      }
      .article__participant-picture--member {
         margin-top: 10px;
         margin-bottom: 10px;
      }
   }

   @media screen and (max-width: 1319.98px) {
      .article__slider-container {
         position: relative;
         width: calc(100% + 30px);
      }
      .article::-webkit-scrollbar {
         width: 0;
         display: none;
      }
      .article__container {
         padding-right: 0;
      }
      .article-container {
         height: auto;
         overflow: visible;
      }
      .right-menu-ct {
         position: relative;
         height: auto;
         display: -webkit-box;
         display: -ms-flexbox;
         display: flex;
         display: -webkit-flex;
         -webkit-box-orient: horizontal;
         -webkit-box-direction: normal;
         -ms-flex-direction: row;
         flex-direction: row;
         text-align: center;
      }
   }

   @media screen and (max-width: 1319.98px) and (min-width: 1200px) {
      .article-container {
         padding: 25px 115px 0;
      }
      .article__right-menu-ct-replacement {
         display: block;
      }
   }

   @media screen and (max-width: 1199.98px) and (min-width: 768px) {
      .article-container {
         padding: 25px 65px 0;
      }
   }

   @media screen and (max-width: 899.98px) and (min-width: 576px) {
      .article__width-2,
      .article__width-2 .react-datepicker__input-container {
         width: calc(50% - 30px);
         margin-right: 30px;
      }
      .article__width-2 .article__width-2 {
         width: 100%;
         margin-right: 0;
      }
   }

   @media screen and (max-width: 575.98px) and (max-width: 575.98px) {
      .article__next-step,
      .article__save-in-draft {
         margin-left: 5px;
         width: calc(100% - 5px);
         -ms-flex-negative: 0;
         flex-shrink: 0;
      }
      .article__next-step {
         margin-top: 10px;
      }
      .article__next-step--first {
         margin-top: 0;
         margin-left: 0;
         width: 100%;
      }
      .article__create-tournament {
         margin-top: 10px;
         margin-left: 5px;
      }
      .article__create-tournament,
      .article__prev-stage {
         width: calc(100% - 5px);
         -ms-flex-negative: 0;
         flex-shrink: 0;
      }
      .article__prev-stage {
         margin-right: 5px;
      }
   }

   @media screen and (min-width: 1501px) {
      .article__right-menu-ct-replacement--preview {
         width: 174px;
      }
      .right-menu-ct--preview {
         width: 174px;
         padding: 116px 72px 0;
      }
      .right-menu-ct--preview .right-menu-ct__stage-link,
      .right-menu-ct--preview .right-menu-ct__stage-text {
         display: none;
      }
      .table__main-row,
      .table__secondary-row {
         display: flex;
         flex-wrap: nowrap;
         padding: 1em;
      }
   }

   @media screen and (max-width: 899.98px) {
      .article__add-custom-field {
         margin-top: 20px;
      }
   }

   .article__repeat-every-hour-container .react-datepicker__day--highlighted,
   .article__repeat-every-day-container .react-datepicker__day--highlighted {
      background-color: #2a87d0;
   }

   .article__repeat-every-hour-container .react-datepicker__day--keyboard-selected,
   .article__repeat-every-day-container .react-datepicker__day--keyboard-selected {
      background-color: #fff;
      color: #000;
   }

   .error-visible {
      display: flex;
   }

   .highlight-invalid-control {
      border: 1px solid rgb(230, 72, 70);
   }

   input.white.react-datepicker-ignore-onclickoutside::placeholder {
      color: white;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
   }

   input.white::placeholder {
      color: white;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
   }

   .dim {
      opacity: 0.4;
   }

   .react-datepicker-popper {
      position: relative;
      will-change: transform;
      top: -32px;
      left: 0px;
      transform: translate3d(0px, 0px, 0px);
      z-index: 1000;
   }

   .ql-toolbar-buttons {
      border-right: 1px solid hsla(0, 0%, 100%, 0.2);
      padding-right: 0px;
      margin-right: 0px;
   }

   .ql-toolbar.ql-snow {
      overflow: visible;
   }

   .btn-small {
      margin: 35px 0px 0px 10px;
      width: 30%;
      height: 40%;
      font-weight: 100;
      padding: 15px;
   }

   .crop-image-design {
      height: 360px;
      width: 100%;
      position: absolute;
      margin: 34px 0px 0px -20px;
      background: transparent;
      z-index: 99;
   }

   .adm .select__title-ct {
      color: #001030;
   }

   .select__title {
      position: absolute;
      text-align: left;
      font-size: 14px;
      line-height: 40px;
      font-family: Burbank Small, sans-serif;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      padding: 0 20px;
   }
   .select__titleNew {
      position: absolute;
      text-align: left;
      font-size: 14px;
      line-height: 40px;
      font-family: Burbank Small, sans-serif;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      padding: 0 20px;
      border: 1px solid #dde3ec;
      border-radius: 4px;

   }

   .adm .select__title {
      color: #001030;
   }

   .article .select__title {
      color: #fff;
   }

   /* .....................common create tornament class End................................... */

   .mt-50 {
      margin-top: 0px;
   }

   @media screen and (min-width: 269.98px) and (max-width: 970px) {
      .mt-50 {
         margin-top: 50px;
      }
      .p-0-30 {
         padding-left: 0px;
         padding-right: 0px;
      }
      .addpadding {
         padding-left: 2em;
         padding-right: 2em;
         margin-left: 0em;
         margin-right: 2em;
      }
   }

   .white-button {
      background: rgba(0, 0, 0, 0);
      color: #fff;
      border: 1px solid #fff;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .white-button:hover {
      color: #061235;
      text-decoration: none;
      background: #fff;
   }

   .green-hover-button:hover {
      background: #6cd000;
      border: none;
      color: #fff;
   }

   .blue-hover-button:hover {
      background: -webkit-gradient(linear, left top, right top, from(#02b7fe), to(#0389fd));
      background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
      background: linear-gradient(90deg, #02b7fe, #0389fd);
      border: none;
      color: #fff;
   }

   .container-margin {
      padding-right: 20px;
      padding-left: 20px;
   }

   .btn_disable {
      border: none;
      outline: none;
      color: #fff;
      text-decoration: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-family: Burbank-Big-Cd-Bd;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      display: block;
      height: 40px;
      padding: 3px 65px 0;
      background: #e13b4c;
      border-radius: 2px;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   .btn_disable:hover {
      opacity: 0.8;
   }

   .adm .hydrated {
      visibility: inherit;
   }

   .hdr_icons {
      margin: 0;
      padding: 0;
      list-style: none;
      color: #55566b;
      display: flex;
   }

   .adm .hdr_icons li {
      margin: 0 0 0 15px;
   }

   .adm .hdr_icons li:first-of-type {
      margin: 0;
   }

   .adm .hdr_icon:hover {
      color: #6dd0ff;
   }

   .adm .hdr_icon {
      position: relative;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      font-size: 25px;
      color: #55566b;
      transition: all 0.2s;
   }

   /*
  .hdr, .hdr_icons {
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
  } */

   .closeMenubtn {
      position: relative;
      top: -23px;
      left: 7px;
      transform: rotate(90deg);
   }

   .hdr {
      padding: 0 20px;
      height: 75px;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      display: flex;
   }

   dl,
   ol,
   ul {
      margin-top: 0;
      margin-bottom: 1rem;
   }

   .hdr_icon .icon-inner,
   svg {
      display: block;
      fill: currentColor;
      stroke: currentColor;
      height: 27px;
      width: 27px;
   }

   a .hdr_icon:hover {
      color: #6dd0ff;
   }

   .adm .hdr_notification {
      z-index: 1;
      position: absolute;
      top: -5px;
      right: -7px;
      padding: 0 4px;
      height: 17px;
      line-height: 17px;
      font-size: 11px;
      color: #fff !important;
      background: #6dd0ff;
      border-radius: 50%;
      text-decoration: none !important;
      font-family: BurbankSmall-Medium, sans-serif;
      border: 1px solid #0d051a;
      border-radius: 6px;
   }

   .adm .ttl,
   .adm_ttl {
      margin: 32px 0 0;
      font-family: Burbank-Big-Cd-Bd;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 30px;
      color: #3c3daa;
   }

   .adm .brefc,
   .adm_brefc {
      margin: 15px 0 0;
      padding: 0;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap;
      list-style: none;
      color: #001030;
      font-style: normal;
      font-weight: 550;
      font-size: 15px;
      line-height: 15px;
      font-family: Burbank-Big-Rg-Md;
   }

   .adm .brefc a:after {
      margin: 0 0 0 6px;
      content: "/";
      display: inline;
      color: #7b8495;
   }

   .adm .brefc a {
      margin: 0 6px 0 0;
      color: #7b8495;
      text-decoration: none;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   .adm .brefc a:hover {
      color: #001030;
   }

   .adm_nav {
      z-index: 998;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      overflow: hidden;
      -webkit-transition: width 0.5s;
      -o-transition: width 0.5s;
      transition: width 0.5s;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 260px;
      -webkit-transform: translateX(-260px);
      -ms-transform: translateX(-260px);
      transform: translateX(-260px);
      -webkit-transition: -webkit-transform 0.5s;
      transition: -webkit-transform 0.5s;
      -o-transition: transform 0.5s;
      transition: transform 0.5s;
      transition: transform 0.5s, -webkit-transform 0.5s;
      color: #fff;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
      background: #3c3daa;
   }

   .adm_nav_inn {
      width: 100%;
      max-width: 100%;
      height: 100%;
   }

   @media screen and (min-width: 992px) {
      .adm-nav-open .adm .logo {
         margin: 0 auto;
         width: 200px;
         max-width: 200px;
      }
   }

   @media screen and (min-width: 992px) {
      .adm_nav {
         width: 75px;
         -webkit-transform: translateX(0);
         -ms-transform: translateX(0);
         transform: translateX(0);
      }
   }

   @media screen and (min-width: 992px) {
      .adm-nav-open .adm .logo {
         margin: 0 auto;
         width: 200px;
         max-width: 200px;
      }
   }

   @media screen and (min-width: 992px) {
      .adm -nav-open .adm .logo {
         -webkit-box-pack: center;
         -ms-flex-pack: center;
         justify-content: center;
      }
   }

   .adm-nav-open .adm_nav {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
   }

   @media screen and (min-width: 992px) {
      .adm_nav {
         width: 75px;
         -webkit-transform: translateX(0);
         -ms-transform: translateX(0);
         transform: translateX(0);
      }
   }

   .adm_nav {
      z-index: 998;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      overflow: hidden;
      -webkit-transition: width 0.5s;
      -o-transition: width 0.5s;
      transition: width 0.5s;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 260px;
      -webkit-transform: translateX(-260px);
      -ms-transform: translateX(-260px);
      transform: translateX(-260px);
      -webkit-transition: -webkit-transform 0.5s;
      transition: -webkit-transform 0.5s;
      -o-transition: transform 0.5s;
      transition: transform 0.5s;
      transition: transform 0.5s, -webkit-transform 0.5s;
      color: #fff;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
      background: #3c3daa;
   }

   @media screen and (min-width: 992px) {
      .adm .logo_inn {
         width: 46px;
         margin: 0 auto;
         overflow: hidden;
      }
   }

   .adm_nav {
      z-index: 998;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      overflow: hidden;
      -webkit-transition: width 0.5s;
      -o-transition: width 0.5s;
      transition: width 0.5s;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 260px;
      -webkit-transform: translateX(-260px);
      -ms-transform: translateX(-260px);
      transform: translateX(-260px);
      -webkit-transition: -webkit-transform 0.5s;
      transition: -webkit-transform 0.5s;
      -o-transition: transform 0.5s;
      transition: transform 0.5s;
      transition: transform 0.5s, -webkit-transform 0.5s;
      color: #fff;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
      background: #3c3daa;
   }

   .adm .logo {
      z-index: 1;
      position: relative;
      padding: 16.3px 0 16px;
      margin: 0 auto;
      width: 100%;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background: #3c3daa;
   }

   .adm-nav-open .adm_nav {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
   }

   @media screen and (min-width: 992px) {
      .adm-nav-open .adm_nav {
         width: 260px;
      }
   }

   .adm .logo {
      z-index: 1;
      position: relative;
      padding: 16.3px 0 16px;
      margin: 0 auto;
      width: 100%;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background: #3c3daa;
   }

   a.adm .logo_inn {
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   a.adm .logo_inn:hover {
      opacity: 0.8;
   }

   .adm .logo img {
      width: 200px;
      max-width: 200px;
   }

   .adm_usr {
      z-index: 1;
      height: 110px;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      -o-flex-direction: column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -ms-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 18px 18px 0;
      position: relative;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
      text-decoration: none;
      background: #3c3daa;
   }

   a.adm_usr:hover {
      background: #e5f7ff;
   }

   .adm_usr_inn {
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      max-width: 100%;
   }

   .adm_usr_img {
      display: block;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      border: 3px solid #d7ecfb;
   }

   a.adm_usr_img {
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   a.adm_usr_img:hover {
      opacity: 0.8;
   }

   .adm_usr_img > img {
      max-width: 100%;
   }

   .adm_usr_m {
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      -webkit-box-flex: 1;
      flex: 1;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      -o-flex-direction: column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -ms-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
   }

   .adm_usr_ttl,
   .adm_usr_txt {
      padding-left: 10px;
      width: 100%;
   }

   .adm_usr_ttl {
      margin: 3px 0 0;
      color: #fff;
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      font-family: Burbank-Big-Cd-Bd;
      text-decoration: none;
   }

   a.adm_usr_ttl {
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   a.adm_usr_ttl:hover {
      color: #6dd0ff;
   }

   .adm_usr_txt {
      margin: 0;
      color: #9ba4e5;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      font-family: Burbank-Big-Rg-Md;
      text-decoration: none;
   }

   .adm_usr_more {
      margin-top: 25px;
      display: block;
      background: rgba(0, 0, 0, 0);
      border: none;
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #fff;
      width: 20px;
      height: 20px;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      -o-flex-direction: column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -ms-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   .adm_usr_more:hover {
      color: #1991eb;
   }

   .adm_usr_drop {
      padding: 0 0 10px;
      display: none;
      width: 230px;
      position: absolute;
      left: 15px;
      top: 80%;
      background: #fff;
      border: 1px solid rgba(152, 166, 173, 0.15);
      border-radius: 4px;
   }

   .adm_usr_drop_arrow:before {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      border-left: 1px solid #eeece5;
      border-top: 1px solid #eeece5;
      top: 0;
      right: 5%;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -8px;
      background: #fff;
   }

   .adm_usr_drop .adm_usr {
      height: auto;
      margin-top: 10px;
      padding: 13px 10px;
      background: #fff;
   }

   .adm_usr_drop .adm_usr_img {
      width: 50px;
      height: 50px;
   }

   .adm_usr_drop .adm_usr_ttl {
      margin: 0;
      color: #435966;
      font-size: 16px;
      line-height: 18px;
   }

   .adm_usr_drop .adm_usr_txt {
      font-size: 13px;
      color: #424247;
   }

   .adm_usr_date {
      padding: 2px 0 0 10px;
      width: 100%;
      color: #cdcdd2;
      font-size: 13px;
      line-height: 13px;
      font-family: Burbank-Big-Rg-Md;
   }

   .adm .last-login a {
      top: 0;
      color: #435966;
      font-size: 14px;
      line-height: 20px;
      display: block;
      font-family: Burbank-Big-Rg-Md;
      padding: 7px 10px;
      text-decoration: none;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   .adm .add-user,
   .adm .last-login a:hover {
      color: #6dd0ff;
   }

   .adm .add-user {
      text-decoration: none;
      font-family: Burbank-Big-Cd-Bd;
      font-size: 14px;
      line-height: 20px;
      padding: 12px 10px;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   .adm .add-user ion-icon {
      margin-top: -3px;
      font-size: 18px;
      line-height: 20px;
      margin-right: 10px;
   }

   .adm .add-user span {
      padding: 3px 0 0;
   }

   .adm .add-user:hover {
      color: #000;
   }

   .adm .menu {
      position: relative;
      margin-top: -185px;
      padding-top: 185px;
      height: 100%;
   }

   .adm .menu_inn {
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      height: 100%;
      overflow: auto;
   }

   .adm .menu_list {
      margin: 25px 6px 0 0;
      padding: 0;
      list-style: none;
   }

   .adm .menu_i {
      padding: 16px 20px 12px;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #9ba4e5;
      text-decoration: none;
      font-size: 20px;
      line-height: 22px;
      font-family: Burbank-Big-Cd-Bd;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   .adm .menu_i:hover {
      color: #6dd0ff;
   }

   .adm .menu_i span {
      margin: 0 0 0 12px;
   }

   .adm .menu_icon {
      font-size: 22px;
   }

   .adm .menu_i .s-ion-icon {
      fill: #9ba4e5;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   .adm .menu_i:hover .s-ion-icon {
      fill: #6dd0ff;
   }

   .adm .menu_icon {
      margin: -4px 0 0;
      display: block;
      width: 22px;
      height: 22px;
      line-height: 0;
   }

   .disable-acc {
      padding: 40px 20px;
      width: 100%;
      max-width: 460px;
      height: auto;
   }

   .disable-acc_ttl {
      margin: 0;
      font-family: Burbank-Big-Rg-Md;
      font-size: 24px;
      line-height: 24px;
   }

   .disable-acc_ttl,
   .disable-acc_txt {
      font-style: normal;
      font-weight: 550;
      text-align: center;
      color: #001030;
   }

   .disable-acc_txt {
      margin: 18px auto 0;
      max-width: 260px;
      font-family: BurbankSmall-Medium, sans-serif;
      font-size: 17px;
      line-height: 27px;
   }

   .modal_disable_buttons {
      margin: 5px -5px 0;
   }

   .disable-acc .window__button--cancel {
      height: 40px;
      margin: 15px 5px 0;
      background: rgba(0, 0, 0, 0);
      border: 1px solid #e13b4c;
      color: #e13b4c;
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   .disable-acc .window__button--cancel:hover {
      background: #e13b4c;
      color: #fff;
   }

   .disable-acc .window__button--delete {
      height: 40px;
      margin: 15px 5px 0;
      background: #e13b4c;
      color: #fff;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
   }

   .disable-acc .window__button--delete:hover {
      background: #cb2f3f;
   }

   .sandwich {
      cursor: pointer;
   }

   .sandwich span {
      display: block;
      width: 23px;
      height: 3px;
      margin-bottom: 5px;
      position: relative;
      background: #55566b;
      border-radius: 4px;
      z-index: 1;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
   }

   .sandwich span:first-child {
      -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      transform-origin: 0 0;
   }

   .sandwich span:nth-last-child(2) {
      -webkit-transform-origin: 0 100%;
      -ms-transform-origin: 0 100%;
      transform-origin: 0 100%;
   }

   .sandwich span:last-child {
      margin-bottom: 0;
   }

   .sandwich:hover span {
      background: #6dd0ff;
   }

   .onlyforMobile {
      opacity: 0;
      display: none;
   }

   @media screen and (max-width: 767.96px) {
      .m-sm-40 {
         margin: 40px 0;
      }
      .m-sm-20 {
         margin: 20px 0;
      }
   }

   @media screen and (max-width: 991.98px) {
      .onlyforMobile {
         opacity: 1;
         display: block;
      }
      .p-l-sm-85 {
         padding-left: 85px;
      }
      .m-b-sm-20 {
         margin-bottom: 20px;
      }
   }

   @media screen and (min-width: 992px) {
      .m-t-lg-82 {
         margin-top: 82px;
      }
      .p-l-lg-135 {
         padding-left: 135px;
      }
      .h-lg-100 {
         height: 100%;
      }
      .m-md-0--15 {
         margin: 0 -15px;
      }
      .adm .ttl {
         margin: 15px 0 0;
         font-size: 36px;
         line-height: 36px;
      }
      .adm_nav {
         width: 75px;
         -webkit-transform: translateX(0);
         -ms-transform: translateX(0);
         transform: translateX(0);
      }
      .adm .page {
         margin-left: 75px;
      }
      .adm .logo {
         -webkit-box-pack: start;
         -ms-flex-pack: start;
         justify-content: flex-start;
      }
      .adm -nav-open .adm .logo {
         -webkit-box-pack: center;
         -ms-flex-pack: center;
         justify-content: center;
      }
      .adm .logo img {
         width: 140px;
         max-width: 140px;
      }
      .adm -nav-open .adm .logo {
         margin: 0 auto;
         width: 200px;
         max-width: 200px;
      }
      .adm -nav-open .adm .logo img {
         width: 100%;
         max-width: 100%;
      }
      .adm .logo_inn {
         width: 46px;
         margin: 0 auto;
         overflow: hidden;
      }
      .adm -nav-open .adm .logo_inn {
         width: auto;
      }
      .adm_usr {
         display: none;
      }
      .adm -nav-open .adm_usr {
         display: block;
      }
      .adm .menu_i span {
         display: none;
      }
      .adm -nav-open .adm .menu_i span {
         display: -moz-flex;
         display: -ms-flex;
         display: -o-flex;
         display: -webkit-box;
         display: -ms-flexbox;
         display: flex;
      }
      .adm .menu {
         position: relative;
         margin-top: -75px;
         padding-top: 75px;
      }
      .adm -nav-open .adm .menu {
         position: relative;
         margin-top: -185px;
         padding-top: 185px;
      }
      .adm .menu_list {
         margin: 15px 6px 0 0;
      }
      .adm -nav-open .adm .menu_list {
         margin: 25px 6px 0 0;
      }
   }

   @media screen and (min-width: 768px) {
      .p-r-md-20 {
         padding-right: 20px;
      }
      .p-r-md-25 {
         padding-right: 25px;
      }
   }

   @media screen and (min-width: 576px) {
      .p-l-sm-20 {
         padding-left: 20px;
      }
      .p-l-sm-1 {
         padding-left: 1px;
      }
      .m-l-sm-5 {
         margin-left: 5px;
      }
   }

   @media screen and (max-width: 575.98px) {
      .m-t-sm-10 {
         margin-top: 10px;
      }
      .p-l-sm-65 {
         padding-left: 65px;
      }
      .adm .article,
      .adm_content .article {
         margin: 0 -15px;
      }
      .disable-acc .window__button--cancel,
      .disable-acc .window__button--delete {
         width: 100%;
      }
   }

   @media screen and (min-width: 1920px) {
      .p-xxl-0-40 {
         padding: 0 40px;
      }
      .container-margin {
         padding-right: 40px;
         padding-left: 40px;
      }
      .hdr {
         padding: 0 40px;
      }
   }

   @media screen and (max-width: 991px) {
      .hdr {
         background-color: #fff;
         box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
         -moz-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
         -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
      }
   }

   .pull-right {
      float: right;
   }

   .adm .tab-menu__link {
      position: relative;
      display: block;
      margin: 0px 40px 0 0;
      padding: 0 0 24px;
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #001030;
      opacity: 0.5;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .adm .tab-menu__link--notification:after {
      content: "";
      display: block;
      position: absolute;
      right: -7px;
      top: -7px;
      width: 5px;
      height: 5px;
      background: #001030;
      opacity: 0.5;
      border-radius: 50%;
   }

   .adm .tab-menu__link--active.adm .tab-menu__link--notification:after {
      background: #49c3fd;
      opacity: 1;
   }

   .adm .tab-menu__link--active {
      opacity: 1;
      color: #49c3fd;
   }

   .adm .tab-menu__link--active:before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 2px;
      background: #49c3fd;
   }

   .adm .tab-menu__link:hover {
      text-decoration: none;
      color: #001030;
      opacity: 0.7;
   }

   @media screen and (max-width: 991.98px) {
      .adm .tab-menu {
         height: 100%;
      }
      .adm .tab-menu__link {
         padding: 0 0 15px;
      }
   }

   .adm .tab-menu__link:hover {
      text-decoration: none;
      color: #001030;
      opacity: 0.7;
   }

   .adm .tab-menu__link--active:hover {
      color: #49c3fd;
      opacity: 1;
   }

   .article__container-header {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #001030;
   }

   .article__color-director {
      width: 20px;
      height: 4px;
      border-radius: 20px;
      margin: 5px 10px 5px 0;
   }

   .article__label-container--vertical {
      margin-bottom: 25px;
      margin-left: 50px;
   }

   .article__color-director--purple {
      background: #3c3daa;
   }

   .article__color-director--green {
      background: #6cd000;
   }

   .article__color-director--orange {
      background: #ec5418;
   }

   .article__color-director--yellow {
      background: #f9d100;
   }

   .article__color-director--pink {
      background: #cb25b3;
   }

   .article__color-director--blue {
      background: #49c3fd;
   }

   .article__color-director--red {
      background: #e13b4c;
   }

   /* ..................................menu css end............................... */

   .select2-container {
      box-sizing: border-box;
      display: inline-block;
      margin: 0;
      position: relative;
      vertical-align: middle;
   }

   .select2-container .select2-selection--single {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      height: 28px;
      user-select: none;
      -webkit-user-select: none;
   }

   .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
   }

   .select2-container .select2-selection--single .select2-selection__clear {
      position: relative;
   }

   .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
      padding-right: 8px;
      padding-left: 20px;
   }

   .select2-container .select2-selection--multiple {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      min-height: 32px;
      user-select: none;
      -webkit-user-select: none;
   }

   .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
   }

   .select2-container .select2-search--inline {
      float: left;
   }

   .select2-container .select2-search--inline .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
      padding: 0;
   }

   .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none;
   }

   .select2-dropdown {
      background-color: white;
      border: 1px solid #aaa;
      border-radius: 4px;
      box-sizing: border-box;
      display: block;
      position: absolute;
      left: -100000px;
      width: 100%;
      z-index: 1051;
   }

   .select2-results {
      display: block;
   }

   .select2-results__options {
      list-style: none;
      margin: 0;
      padding: 0;
   }

   .select2-results__option {
      padding: 6px;
      user-select: none;
      -webkit-user-select: none;
   }

   .select2-results__option[aria-selected] {
      cursor: pointer;
   }

   .select2-container--open .select2-dropdown {
      left: 0;
   }

   .select2-container--open .select2-dropdown--above {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
   }

   .select2-container--open .select2-dropdown--below {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
   }

   .select2-search--dropdown {
      display: block;
      padding: 4px;
   }

   .select2-search--dropdown .select2-search__field {
      padding: 4px;
      width: 100%;
      box-sizing: border-box;
   }

   .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none;
   }

   .select2-search--dropdown.select2-search--hide {
      display: none;
   }

   .select2-close-mask {
      border: 0;
      margin: 0;
      padding: 0;
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      min-height: 100%;
      min-width: 100%;
      height: auto;
      width: auto;
      opacity: 0;
      z-index: 99;
      background-color: #fff;
      filter: alpha(opacity=0);
   }

   .select2-hidden-accessible {
      border: 0;
      clip: rect(0 0 0 0);
      -webkit-clip-path: inset(50%);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      white-space: nowrap;
   }

   .select2-container--default .select2-selection--single {
      background-color: #fff;
      border: 1px solid #aaa;
      border-radius: 4px;
   }

   .select2-container--default {
      color: #444;
      line-height: 28px;
   }

   .select2-container--default {
      cursor: pointer;
      float: right;
      font-weight: bold;
   }

   .select2-container--default {
      color: #999;
   }

   .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: 26px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
   }

   .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0;
   }

   .select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
      float: left;
   }

   .select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
      left: 1px;
      right: auto;
   }

   .select2-container--default.select2-container--disabled .select2-selection--single {
      background-color: #eee;
      cursor: default;
   }

   .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
      display: none;
   }

   .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #888 transparent;
      border-width: 0 4px 5px 4px;
   }

   .select2-container--default .select2-selection--multiple {
      background-color: white;
      border: 1px solid #aaa;
      border-radius: 4px;
      cursor: text;
   }

   .select2-container--default .select2-selection--multiple .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 5px;
      width: 100%;
   }

   .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
      list-style: none;
   }

   .select2-container--default .select2-selection--multiple .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-top: 5px;
      margin-right: 10px;
      padding: 1px;
   }

   .select2-container--default .select2-selection--multiple .select2-selection__choice {
      background-color: #e4e4e4;
      border: 1px solid #aaa;
      border-radius: 4px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
   }

   .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
      color: #999;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;
   }

   .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #333;
   }

   .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
   .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
      float: right;
   }

   .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
   }

   .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
   }

   .select2-container--default.select2-container--focus .select2-selection--multiple {
      border: 1px solid #c4c9d2;
      outline: 0;
   }

   .select2-container--default.select2-container--disabled .select2-selection--multiple {
      background-color: #eee;
      cursor: default;
   }

   .select2-container--default.select2-container--disabled .select2-selection__choice__remove {
      display: none;
   }

   .select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
   .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
   }

   .select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
   .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
   }

   .select2-container--default .select2-search--dropdown .select2-search__field {
      border: 1px solid #aaa;
   }

   .tab-menu__radio {
      display: none;
   }

   .tab-menu__header-link {
      position: relative;
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #fff;
   }

   .tab-menu__header-link:after {
      content: "";
      display: inline-block;
      margin-left: 10px;
      width: 13px;
      height: 11px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-image: url(#{$cdn}/img/arrow-right.svg);
   }

   .tab-menu__header-link:hover {
      color: #fff;
      text-decoration: none;
   }

   .window__header,
   .window__subheader {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
   }

   .table__button {
      width: 140px;
      height: 40px;
      margin-left: 10px;
   }

   .table__button:first-of-type {
      margin-left: 0;
   }

   .table__column--date {
      width: 70%;
   }

   .table__column--date:before {
      content: "Date";
   }

   .table__column--currency {
      width: 70%;
   }

   .table__column--currency:before {
      content: "Currency";
   }

   .table__column--tournament {
      width: 200%;
   }

   .table__column--tournament:before {
      content: "Tournament";
   }

   .table__column--type {
      width: 100%;
   }

   .table__column--type:before {
      content: "Type";
   }

   .table__column--venue {
      width: 80%;
   }

   .table__column--venue:before {
      content: "Venue";
   }

   .table__column--status {
      width: 150%;
   }

   .table__column--status:before {
      content: "Status";
   }

   .table__column--points {
      width: 80%;
   }

   .table__column--points:before {
      content: "Points";
   }

   .table__column--points-type {
      width: 400%;
   }

   .table__column--points-type:before {
      content: "Type";
   }

   .table__column--description {
      width: 300%;
   }

   .table__column--description:before {
      content: "Type";
   }

   .buy {
      background: #1e2c48;
      border-radius: 4px;
      padding: 50px 55px 40px 75px;

      @media screen and (max-width: 767px) {
         padding: 50px  40px;
      }
   }
   .buy__header {
      font-family: BurbankSmall-Bold, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: #fff;
      margin-bottom: 24px;
   }

   .buy__subheader {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #fff;
      opacity: 0.7;
      margin-bottom: 12px;
      @media screen and (max-width: 767px) {
         margin-top: 15px;
      }
   }

   .buy__subheader--big {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 51px;
   }

   .buy__input {
      display: none;
   }

   .buy__label-container {
      width: 33.33333%;
      padding: 0 10px 0 0;
   }

   .buy__label-container:last-of-type {
      padding: 0 30px 0 0;
   }

   .buy__label {
      height: 45px;
      width: 100%;
      background: #2f3d58;
      border-radius: 4px;
      text-align: center;
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 45px;
      letter-spacing: 0.05em;
      color: hsla(0, 0%, 100%, 0.4);
      cursor: pointer;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      border: 1px solid rgba(0, 0, 0, 0);
   }

   .buy__input:checked ~ .buy__label {
      color: #49c3fd;
      border: 1px solid #49c3fd;
   }

   .buy__select {
      width: 234px;
      background: #2f3d58;
      border-radius: 4px;
      height: 45px;
   }

   .buy__hr {
      width: 100%;
      height: 1px;
      background: #2f3d58;
      margin: 45px 0 39px;
   }

   .buy__warning-icon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-image: url(#{$cdn}/img/white_warning.svg);
      margin-right: 10px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
   }

   .buy__warning-text {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      opacity: 0.7;
   }

   .buy__button {
      width: 234px;
      height: 40px;
      margin-left: 10px;
   }

   .article .info {
      background: #1e2c48;
      width: 100%;
      border-radius: 4px;
      margin-bottom: 20px;
      padding: 29px 120px 29px 60px;
      margin-left: 17px;
      @media only screen and (max-width : 767px)  {
         margin-left: 0;
         padding: 29px 30px;
         width: 100vw;
      }
   }

   .info__icon {
      width: 62px;
      height: 62px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
   }

   .info__icon--money {
      background-image: url(#{$cdn}/img/total_tournament.png);
   }

   .info__icon--points {
      background-image: url(#{$cdn}/img/points_icon.svg);
   }

   .info__icon--bonus {
      background-image: url(#{$cdn}/img/bonus_icon.svg);
   }

   .info__header {
      font-family: BurbankSmall-Bold, sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 15px;
   }

   .info__header,
   .info__text {
      font-style: normal;
      color: #fff;
   }

   .info__text {
      font-family: BurbankSmall-Medium, sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      opacity: 0.7;
   }

   .window__slash {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 45px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      color: #001030;
      width: 30px;
      text-align: center;
   }

   @media screen and (max-width: 991.98px) {
      .tab-menu {
         height: 100%;
      }
   }

   @media screen and (max-width: 991.98px) and (min-width: 0) {
      .buy__button {
         margin-top: 20px;
      }
   }

   @media screen and (max-width: 575.98px) and (min-width: 0) {
      .buy__label-container {
         width: 100%;
         margin-bottom: 10px;
         padding-right: 0;
      }
      .buy__label-container:last-of-type {
         margin-bottom: 0;
         padding-right: 0;
      }
   }

   @media screen and (max-width: 439.98px) and (min-width: 0) {
      .info {
         padding: 29px 0 29px 20px;
      }
      .info__icon {
         width: 50px;
         height: 50px;
         margin-right: -10px;
      }
      .info__header {
         font-size: 18px;
         line-height: 18px;
         margin-bottom: 12px;
      }
      .info__text {
         font-size: 14px;
         line-height: 14px;
      }
   }

   .withdraw {
      height: 100%;
      overflow: auto;
   }

   @media screen and (max-width: 1700px) {
      .withdraw {
         height: auto;
         overflow: visible;
      }
   }

   @media screen and (max-width: 1199.98px) {
      .withdraw {
         padding: 70px 24px 0 70px;
      }
   }

   @media screen and (max-width: 991.98px) {
      .withdraw {
         padding: 60px 30px 0 30px;
      }
   }

   .withdraw__back {
      position: relative;
      display: block;
      text-decoration: none;
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      color: white;
      padding-left: 27px;
      margin-bottom: 50px;
   }

   .withdraw__back:hover {
      text-decoration: none;
      color: white;
   }

   .withdraw__back::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      width: 17px;
      height: 14px;
      background-color: transparent;
      background-size: 100% 100%;
      background-image: url(#{$cdn}/img/arrow_left.svg);
   }

   .withdraw__header {
      font-family: "Burbank-Big-Cd-Bd", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: white;
      padding-bottom: 40px;
      margin-bottom: 50px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
   }

   .withdraw__subheader {
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 12px;
   }

   @media screen and (max-width: 991.98px) and (min-width: 576px) {
      .withdraw__subheader--amount::after {
         content: " to withdraw";
      }
   }

   .withdraw__amount-container {
      width: 450px;
   }

   .withdraw__input {
      width: 100%;
      height: 55px;
      background: #25324e;
      border-radius: 4px;
      padding: 0 0 0 20px;
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.05em;
      color: white;
      border: none;
      outline: none;
      margin-bottom: 30px;
   }

   .withdraw__input-1 {
      width: 205px;
   }

   .withdraw__input-2 {
      width: 420px;
   }

   @media screen and (max-width: 575.98px) {
      .withdraw__input-1,
      .withdraw__input-2 {
         width: calc(100vw - 60px);
      }
      .withdraw__amount-container {
         width: calc(100vw - 30px);
      }
      .withdraw__payment-method-label {
         width: 205px;
      }
   }

   .withdraw__checkbox {
      display: none;
   }

   .withdraw__full-amount-label {
      display: block;
      width: 18px;
      height: 18px;
      border: 1px solid #5f6d8b;
      box-sizing: border-box;
      border-radius: 2px;
      transition: 150ms;
      background-color: transparent;
      background-size: 10px 8px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: linear-gradient(transparent, transparent);
      cursor: pointer;
   }

   .withdraw__checkbox:checked + .withdraw__full-amount-label {
      background-color: #6cd000;
      background-image: url(#{$cdn}/img/grey_ok.svg);
      border: none;
   }

   .withdraw__full-amount-text {
      font-family: "BurbankSmall-Medium", sans-serif;
      letter-spacing: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: white;
      margin-left: 10px;
   }

   .withdraw__payment-method-label {
      margin: 0 5px 30px 5px;
      height: 55px;
      background: #25324e;
      border-radius: 4px;
      text-align: center;
      display: block;
      cursor: pointer;
      font-family: "BurbankSmall-Bold", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 53px;
      letter-spacing: 0.05em;
      border: 1px solid transparent;
      color: rgba(255, 255, 255, 0.4);
      transition: 150ms;
      flex-shrink: 0;
   }

   .withdraw__checkbox--cheque:checked ~ .row .withdraw__payment-method-label:nth-of-type(1),
   .withdraw__checkbox--e-transfer:checked ~ .row .withdraw__payment-method-label:nth-of-type(2),
   .withdraw__checkbox--direct-debit:checked ~ .row .withdraw__payment-method-label:nth-of-type(3),
   .withdraw__checkbox--coin:checked ~ .row .withdraw__payment-method-label:nth-of-type(4) {
      color: #49c3fd;
      border: 1px solid #49c3fd;
   }

   .withdraw__confirm-container {
      padding: 40px 0;
      margin-top: 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
   }

   .withdraw__confirm {
      width: 205px;
      height: 55px;
      
   }

   .withdraw__confirm--active {
      opacity: 1;
   }

   .withdraw__agree-licence-label {
      width: 20px;
      height: 20px;
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background-size: 14px 10px;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 2px;
      cursor: pointer;
      display: block;
      margin: 0 10px 0 0;
      transition: 150ms;
      flex-shrink: 0;
   }

   .withdraw__checkbox:checked + .withdraw__agree-licence-label {
      background-image: url(#{$cdn}/img/ok.svg);
      background-color: #49c3fd;
      border: none;
   }

   .withdraw__agree-licence-text {
      padding: 0;
      font-family: "BurbankSmall", sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0;
      color: white;
   }

   @media screen and (max-width: 991.98px) {
      .withdraw__agree-licence-text {
         font-size: 13px;
      }
   }

   @media screen and (max-width: 767.98px) {
      .withdraw__confirm {
         margin-top: 20px;
      }
   }

   .withdraw__cheque-container,
   .withdraw__e-transfer-container,
   .withdraw__direct-debit-container,
   .withdraw__coin-container {
      display: none;
   }

   .withdraw__checkbox--cheque:checked ~ .withdraw__cheque-container,
   .withdraw__checkbox--e-transfer:checked ~ .withdraw__e-transfer-container,
   .withdraw__checkbox--direct-debit:checked ~ .withdraw__direct-debit-container,
   .withdraw__checkbox--coin:checked ~ .withdraw__coin-container {
      display: block;
   }

   .withdraw__warning-icon {
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: none;
      background: rgba(255, 255, 255, 0.2);
      opacity: 1;
      transition: 150ms;
      margin-bottom: 30px;
      margin-top: -8px;
   }

   .withdraw__warning-icon--ok {
      background: #6cd000;
   }

   .withdraw__warning-icon--error {
      background: #e13b4c;
   }

   .withdraw__warning-icon::before {
      content: "";
      display: block;
      position: absolute;
      width: 2px;
      height: 2px;
      top: 3px;
      left: 7px;
      background: white;
   }

   .withdraw__warning-icon::after {
      content: "";
      display: block;
      position: absolute;
      width: 2px;
      height: 6px;
      top: 7px;
      left: 7px;
      background: white;
   }

   .withdraw__warning-icon--ok::before,
   .withdraw__warning-icon--error::before {
      display: none;
   }

   .withdraw__warning-icon--ok::after,
   .withdraw__warning-icon--error::after {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: transparent;
      background-image: url(#{$cdn}/img/ok.svg);
      background-size: 9px 7px;
      background-position: center center;
      background-repeat: no-repeat;
   }

   .withdraw__warning-icon--error::after {
      background-image: url(#{$cdn}/img/cross.svg);
      background-size: 6px 6px;
   }

   .withdraw__warning-text {
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.05em;
      color: white;
      margin-left: -5px;
      margin-top: -8px;
   }

   .withdraw__warning-text--error {
      color: #e13b4c;
   }

   .withdraw__balance-header {
      font-family: "Burbank-Big-Cd-Bd", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #6cd000;
      margin-bottom: 7px;
   }

   .withdraw__balance-text {
      font-family: "Burbank-Big-Rg-Md", sans-serif;
      font-style: normal;
      font-size: 15px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #6cd000;
   }

   @media screen and (max-width: 767.98px) {
      .withdraw__balance {
         margin-left: 35px;
         margin-bottom: 20px;
      }
   }

   ._border {
      border: 1px solid gray;
      border-radius: 6px;
   }

   .withdraw__currency-container {
      position: relative;
   }

   @media screen and (max-width: 1700px) {
      .payment {
         right: 0;
      }
      .payment--next {
         right: 100%;
      }
   }

   .summary__header,
   .payment__header {
      font-family: "Burbank-Big-Cd-Bd", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: white;
      margin-bottom: 50px;
   }

   .summary__order {
      vertical-align: middle;
      margin: auto;
   }

   .payment__order {
      margin-bottom: 30px;
   }

   .summary__order-title,
   .payment__order-title {
      font-family: "Burbank-Big-Rg-Md", sans-serif;
      font-style: normal;
      font-size: 20px;
      line-height: 24px;
      color: white;
      opacity: 0.7;
   }

   .summary__order-price,
   .payment__order-price {
      font-family: "Burbank-Big-Rg-Md", sans-serif;
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      color: white;
   }

   .summary__total,
   .payment__total {
      padding: 30px 0 28px 0;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      margin-bottom: 60px;
   }

   .payment__total {
      border-bottom: 0;
   }

   .summary__total-price,
   .payment__total-price {
      font-family: "Burbank-Big-Rg-Md", sans-serif;
      font-style: normal;
      font-size: 30px;
      line-height: 30px;
      color: #49c3fd;
   }

   .summary__total-text,
   .payment__total-text {
      font-family: "Burbank-Big-Rg-Md", sans-serif;
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #ffffff;
   }

   .withdraw input::placeholder {
      color: rgba(255, 255, 255, 0.5);
   }

   .withdraw input:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.5);
   }

   .withdraw input::-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.5);
   }

   .withdraw__checkbox .active,
   .withdraw__agree-licence-label .active {
      background-image: url(#{$cdn}/img/ok.svg);
      background-color: #49c3fd;
      border: none;
   }

   .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
   }

   .upload-btn-wrapper input[type="file"] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
   }

   .table__button {
      width: 140px;
      height: 40px;
      margin-left: 10px;
   }

   .table__button:first-of-type {
      margin-left: 0;
   }

   .article .search__input {
      position: relative;
      z-index: 1;
      background: rgba(0, 0, 0, 0);
      border: none;
      height: 40px;
      outline: none;
      padding: 0 20px 0 39px;
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: #fff;
   }

   .tab-menu__button {
      width: 140px;
      height: 40px;
      margin-top: 15px;
   }

   .adm .window__subheader {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #49c3fd;
   }

   .window__headers-row {
      margin: 25px -85px 0;
      padding: 0 70px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      opacity: 0.5;
   }

   .window__row {
      margin: 0 -85px;
      padding: 20px 70px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
   }

   .window__column {
      width: 100%;
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #001030;
   }

   .window__column--name {
      width: 140%;
   }

   .window__column--time {
      width: 120%;
   }

   .window__column--status {
      width: 80%;
   }

   .window__row .window__column {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 22px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      letter-spacing: 0.05em;
      color: #001030;
   }

   .window__row .window__column--name {
      line-height: 20px;
      height: 40px;
   }

   .window__column--purchased {
      color: #6cd000;
   }

   .window__column--refunded {
      color: #e13b4c;
   }

   .table__column--id {
      width: 100%;
   }

   .table__column--id:before {
      content: "Event ID";
   }

   .table__column--name {
      width: 340%;
   }

   .table__column--name:before {
      content: "Event Name";
   }

   .table__column--price {
      width: 80%;
   }

   .table__column--price:before {
      content: "Price";
   }

   .table__column--sales {
      width: 100%;
   }

   .table__column--sales:before {
      content: "Sales";
   }

   .table__column--venue {
      width: 90%;
   }

   .table__column--venue:before {
      content: "Venue";
   }

   .table__column--date {
      width: 210%;
   }

   .table__column--date:before {
      content: "Event Date";
   }

   .table__column--sale-date {
      width: 160%;
   }

   .table__column--sale-date:before {
      content: "Sales Date";
   }

   .table__column--amount {
      width: 90%;
   }

   .table__column--amount:before {
      content: "Amount";
   }

   .table__column--fees {
      width: 150%;
   }

   .table__column--fees:before {
      content: "Service fees";
   }

   .table__column--balance {
      width: 100%;
   }

   .table__column--balance:before {
      content: "Balance";
   }

   @media screen and (max-width: 1609.98px) and (min-width: 1200px) {
      .table__column {
         padding: 1em;
         margin: auto;
      }
   }

   @media screen and (max-width: 1429.98px) {
      .table__headers-row {
         display: none;
      }
      .table__column {
         padding: 3px 0;
      }
      .table__row .table__column:before {
         display: inline-block;
      }
   }

   @media screen and (max-width: 1429.98px) and (min-width: 768px) {
      .table__column {
         width: 50%;
         padding: 3px 0;
      }
      .table__row {
         padding: 15px 20px;
      }
   }

   @media screen and (max-width: 767.98px) and (min-width: 576px) {
      .table__column {
         width: 100%;
      }
      .table__row {
         padding: 15px 20px;
      }
   }

   @media screen and (max-width: 399.98px) and (min-width: 0) {
      .table__row {
         padding: 12px 16px;
      }
   }

   .window__header {
      font-size: 24px;
   }

   @media screen and (max-width: 939.98px) {
      .tab-menu {
         -ms-flex-wrap: nowrap;
         flex-wrap: nowrap;
         -webkit-flex-wrap: nowrap;
      }
   }

   .window__header {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 30px;
      line-height: 30px;
      color: #001030;
      margin-bottom: 22px;
   }

   .window__headers-row {
      margin: 25px -85px 0;
      padding: 0 70px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      opacity: 0.5;
   }

   .window__row {
      margin: 0 -85px;
      padding: 20px 70px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
   }

   .window__column {
      width: 100%;
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #001030;
   }

   .window__column--name {
      width: 140%;
   }

   .window__column--time {
      width: 120%;
   }

   .window__column--status {
      width: 80%;
   }

   .window__row .window__column {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 22px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      letter-spacing: 0.05em;
      color: #001030;
   }

   .window__row .window__column--name {
      line-height: 20px;
      height: 40px;
   }

   .window__column--purchased {
      color: #6cd000;
   }

   .window__column--refunded {
      color: #e13b4c;
   }

   .table__column--id {
      width: 90%;
   }

   .table__main-row .table__column--id:before {
      content: "Event ID";
   }

   .table__column--name {
      width: 340%;
   }

   .table__column--name:before {
      content: "Name";
   }

   .table__column--type {
      width: 340%;
   }

   .table__column--type:before {
      content: "Type";
   }

   .table__column--price {
      width: 80%;
   }

   .table__column--price:before {
      content: "Price";
   }

   .table__column--sales {
      width: 100%;
   }

   .table__column--sales:before {
      content: "Sales";
   }

   .table__column--refunded {
      width: 120%;
   }

   .table__column--refunded:before {
      content: "Refunded";
   }

   .table__column--venue {
      width: 90%;
   }

   .table__column--venue:before {
      content: "Venue";
   }

   .table__column--date {
      width: 210%;
   }

   .table__column--date:before {
      content: "Event Date";
   }

   .table__column--sale-date {
      width: 150%;
   }

   .table__column--sale-date:before {
      content: "Date of sale";
   }

   .table__column--status {
      width: 124px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
   }

   .table__column--status:before {
      content: "Status";
   }

   .table__column--buttons {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 170px;
   }

   .table__column--details-button {
      width: 900%;
   }

   .table__hide {
      margin-left: 0;
   }

   @media screen and (max-width: 767.98px) {
      .table__checkbox:checked ~ .table__secondary-row-container {
         max-height: 1800px;
      }
      .tab-menu {
         overflow: hidden;
      }
      .window__headers-row {
         display: none;
      }
      .window__row {
         border-bottom: none;
         border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
      .window__column {
         display: -webkit-box;
         display: -ms-flexbox;
         display: flex;
         display: -webkit-flex;
         -webkit-box-orient: horizontal;
         -webkit-box-direction: normal;
         -ms-flex-direction: row;
         flex-direction: row;
         -webkit-box-pack: justify;
         -ms-flex-pack: justify;
         justify-content: space-between;
         -webkit-justify-content: space-between;
         width: 100%;
         margin: 3px 0;
      }
      .window__column:before {
         font-weight: 700;
         opacity: 0.5;
      }
      .window__row .window__column--name {
         height: auto;
      }
      .window__row .window__column--name:before {
         content: "Purchaser name";
      }
      .window__row .window__column--date:before {
         content: "Date";
      }
      .window__row .window__column--time:before {
         content: "Time";
      }
      .window__row .window__column--id:before {
         content: "Ticket #";
      }
      .window__row .window__column--ticketprice:before {
         content: "Price";
      }
      .window__row .window__column--quantity:before {
         content: "Quantity";
      }
      .window__row .window__column--section::before {
         content: "Section";
       }
       .window__row .window__column--row::before {
         content: "Row #";
       }
       .window__row .window__column--seat::before {
         content: "Seat #";
       }
      .window__row .window__column--status:before {
         content: "Status";
         color: #001030;
      }
   }

   @media screen and (max-width: 768px) {
      .tab-menu__link0 {
         padding-top: 30px;
      }
   }

   .window__header {
      font-size: 24px;
   }

   .table__secondary-row .table__column {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-size: 16px;
   }

   .table__button {
      width: 100%;
      margin-right: 0;
      height: 35px;
   }

   @media screen and (max-width: 939.98px) {
      .tab-menu {
         -ms-flex-wrap: nowrap;
         flex-wrap: nowrap;
         -webkit-flex-wrap: nowrap;
      }
   }

   @media screen and (max-width: 991.98px) {
      .table__column--name {
         width: 100%;
         -webkit-box-ordinal-group: 0;
         -ms-flex-order: -1;
         order: -1;
      }
      .table__column--buttons {
         width: 100%;
      }
   }

   @media screen and (max-width: 1409.98px) and (min-width: 0) {
      .table__headers-row {
         display: -webkit-box;
         display: -ms-flexbox;
         display: flex;
      }
      .table__headers-row .table__column--buttons {
         width: 100%;
         padding: 0 20px;
      }
      .table__secondary-row-container {
         padding: 15px 20px;
      }
      .table__secondary-row .table__column--id {
         display: none;
      }
      .table__details-button {
         margin-bottom: 10px;
      }
   }

   @media screen and (min-width: 1409.98px) {
      .table__secondary-row .table__column:not(.table__column--type):before {
         display: none;
      }
   }

   .upg {
      color: #fff;
   }

   .upg_r {
      background: -webkit-linear-gradient(1deg, #012065 0%, #5623a7 100%);
      background: -o-linear-gradient(1deg, #012065 0%, #5623a7 100%);
      background: linear-gradient(91deg, #012065 0%, #5623a7 100%);
   }

   .upg_r_inn {
      margin: 0 auto;
      max-width: 620px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      -o-flex-direction: column;
      flex-direction: column;
      -ms-align-items: center;
      align-items: center;
      justify-content: center;
      min-height: 100%;
   }

   @media (min-width: 1280px) {
      .upg {
         display: -webkit-flex;
         display: -moz-flex;
         display: -ms-flex;
         display: -o-flex;
         display: flex;
         justify-content: center;
         height: 100vh;
      }
      .upg_l .container {
         max-width: 1130px;
      }
      .upg_l {
         flex-grow: 1;
         -ms-overflow-style: none;
         height: 100vh;
         overflow: auto;
      }
      .upg_l::-webkit-scrollbar {
         display: none;
      }
      .upg_r {
         height: 100vh;
         overflow-y: auto;
         overflow-x: hidden;
      }
   }

   .upg_pay {
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: 52px 15px 40px;
      max-width: 520px;
   }

   @media (min-width: 400px) {
      .upg_pay {
         padding: 52px 50px 40px;
      }
   }

   @media (min-width: 1280px) {
      .upg_pay {
         padding: 52px 50px 22px;
      }
      .upg_r {
         height: 100vh;
         overflow-y: auto;
         overflow-x: hidden;
         min-width: 500px;
      }
   }

   @media (min-width: 1800px) {
      .upg_pay {
         max-width: 620px;
         padding: 52px 100px 22px;
      }
   }

   .payment__radio:checked + .payment__label--paypal:after {
      background-image: url(#{$cdn}/img/paypal-accent.svg);
      opacity: 1;
   }

   .payment__label--paypal:after {
      background-image: url(#{$cdn}/img/paypal_white.svg);
      width: 16px;
      height: 19px;
      opacity: 0.4;
      right: 17px;
      top: 17px;
   }

   .payment_tab.payment_tab-active {
      display: block;
   }

   .payment_tab {
      display: none;
   }

   .payment_tab .payment__agree-licence-container {
      margin-bottom: 0;
      padding-bottom: 28px;
      border: none;
   }

   .pay_pri {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding: 10px 0 23px;
   }

   .pay_pri_head {
      width: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: space-between;
      -ms-align-items: center;
      align-items: center;
   }

   .pay_pri_head_l,
   .pay_pri_head_r {
      margin-top: 15px;
   }

   .pay_pri_ttl {
      margin: 0 0 0;
      font-family: "Burbank-Big-Rg-Md";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
      opacity: 0.7;
   }

   .pay_pri_pri {
      font-family: "Burbank-Big-Rg-Md";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 20px;
      color: #ffffff;
   }

   .pay_pri_txt {
      padding: 0 0 0 23px;
      margin: 16px 0 0;
      position: relative;
      font-family: "BurbankSmall-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #ffffff;
      opacity: 0.7;
   }

   .pay_pri_txt:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      background: url(#{$cdn}/img/info.svg);
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
   }

   .upg_prem {
      padding: 50px 0 50px;
   }

   @media (min-width: 1280px) {
      .upg_prem {
         padding: 50px 0 70px;
      }
   }

   .upg_back {
      padding-left: 27px;
      margin-bottom: 46px;
      position: relative;
      display: inline-block;
      font-family: "BurbankSmall-Bold";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      text-decoration: none;
      color: #fff;
      transition: all 0.2s;
   }

   .upg_back:hover {
      opacity: 0.75;
   }

   .upg_back:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 1px;
      width: 17px;
      height: 14px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-image: url(#{$cdn}/img/arrow_left.svg);
   }

   .upg_ttl {
      margin: 0 0 38px;
      font-family: "Burbank-Big-Cd-Bd";
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 120%;
      letter-spacing: 0.05em;
      color: #ffffff;
   }

   .upg_ttl > span {
      color: #ca25b3;
   }

   @media (min-width: 450px) {
      .upg_ttl {
         font-size: 36px;
      }
   }

   .upg_desc {
      margin: 0 0 38px;
      font-family: "BurbankSmall-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      color: #ffffff;
      opacity: 0.7;
   }

   .check-list {
      display: block;
      position: relative;
      margin: 0 0;
      padding: 0 0;
      list-style: none;
   }

   .check-list li {
      margin: 0 0;
      padding: 12.5px 0 12.5px 33px;
      display: block;
      position: relative;
      font-family: "BurbankSmall-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;
      text-transform: capitalize;
   }

   .check-list li:before {
      position: absolute;
      top: 10.5px;
      left: 0;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #ca25b3;
      background-image: url(#{$cdn}/img/check-white.svg);
      -webkit-background-size: auto;
      background-size: auto;
      background-position: center;
      background-repeat: no-repeat;
   }

   @media (min-width: 992px) {
      .check-block--big .check-block_ttl {
         margin-bottom: 19px;
      }
      .check-block--big .check-block_txt {
         margin: 0 0 4px;
      }
      .check-block--h100 {
         height: 100%;
      }
      .check-block--h50 {
         height: calc(50% - 10px);
      }
   }

   .check-block_inn {
      padding: 19px 15px 7px 15px;
      width: 100%;
   }

   @media (min-width: 420px) {
      .check-block_inn {
         padding: 19px 30px 7px;
      }
   }

   .check-block {
      position: relative;
      background: #1e2c48;
      border-radius: 2px;
   }

   .check-block {
      margin: 20px 0 0;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      -o-flex-direction: column;
      flex-direction: column;
      -ms-align-items: center;
      align-items: center;
      justify-content: center;
      width: 100%;
   }

   .check-block_ttl {
      margin: 0 40px 6px 0;
      font-family: "BurbankSmall-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: #ffffff;
   }

   @media (min-width: 420px) {
      .check-block_ttl {
         margin: 0 25px 6px 0;
      }
   }

   .check-block_txt {
      margin: 0 0 -4px;
      font-family: "BurbankSmall-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #ffffff;
      opacity: 0.7;
   }

   .check-block_icon {
      position: absolute;
      top: 19px;
      right: 20px;
   }

   .payment-succes-modal {
      padding-bottom: 34px;
      padding-left: 15px;
      padding-right: 15px;
   }

   .payment-succes-modal_txt {
      margin: 26px 0 0;
      font-family: "BurbankSmall-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #001030;
   }

   @media screen and (max-width: 991.98px) and (min-width: 576px) {
      .payment-succes-modal {
         padding-left: 35px;
         padding-right: 35px;
      }
      .payment-succes-modal {
         width: 100%;
         max-width: 460px;
      }
   }

   .after-header-container {
      width: 100%;
   }

   .orders__table-headers {
      margin-top: 22px;
      padding-right: 176px;
      width: 100%;
   }

   .orders__table-header {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #fff;
      width: 100%;
      text-align: center;
   }

   .orders__table-header--ac {
      text-align: left;
   }

   .orders__table-header--amount,
   .orders__table-header--price {
      width: 130px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
   }

   .order {
      position: relative;
      background: #1e2c48;
      border-radius: 2px;
   }

   .order--participant:after,
   .order--viewer:after {
      content: "Spectator Ticket";
      display: block;
      position: absolute;
      color: #fff;
      // bottom: 0px;
      right: -10px;
      width: 143px;
      height: 30px;
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.05em;
      background: -webkit-gradient(linear, left top, right top, from(#cb25b3), to(#7d2eae));
      background: -o-linear-gradient(left, #cb25b3 0, #7d2eae 100%);
      background: linear-gradient(90deg, #cb25b3, #7d2eae);
      text-align: center;
   }

   .order--participant:before,
   .order--viewer:before {
      content: "";
      display: block;
      position: absolute;
      background: #590c84;
      width: 9px;
      height: 5px;
      bottom: 0px;
      right: -8px;
      -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
      transform: rotate(30deg);
   }

   .order--participant:after {
      content: "Participant Ticket";
      background: #6cd000;
      width: 179px;
   }

   .order--participant:before {
      background: #4e9502;
   }

   .order__picture {
      width: 180px;
      height: 150px;
      margin-right: 5px;
   }

   .order__title {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #fff;
   }

   .order__info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      display: -webkit-flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding-right: 0px;
      margin: 9px 0;
      overflow: hidden;
   }

   .order__info-header {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 12px;
      line-height: 12px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: hsla(0, 0%, 100%, 0.4);
      margin: 0 0 6px;
   }

   .order__info-text {
      font-size: 10px;
      line-height: 17px;
      display: contents;
      width: 100%;
   }

   .order__count,
   .order__info-text {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      color: #fff;
   }

   .order__count {
      font-size: 20px;
      line-height: 30px;
      margin: 20px 10px 0;
   }

   .order__add,
   .order__reduce {
      position: relative;
      width: 25px;
      height: 25px;
      background-color: #fff;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 50%;
      padding: 0;
      line-height: 20px;
      margin: 23px 0 3px;
   }

   .order__reduce {
      background: #566075;
   }

   .order__add:after,
   .order__add:before,
   .order__reduce:before {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 1px;
      background: #1e2c48;
      top: 12px;
      left: 8px;
   }

   .order__add:after {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
       transform: rotate(90deg);
      -moz-transform: rotate(90deg); 
      -o-transform: rotate(90deg); 
      
   }

   .order__remaining {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-size: 16px;
      line-height: 16px;
      opacity: 0.7;
      margin: 12px 15px 0;
   }

   .order__price,
   .order__remaining {
      font-style: normal;
      color: #49c3fd;
      text-align: center;
   }

   .order__price {
      font-family: Burbank-Big-Cd-Bd, sans-serif;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.05em;
      margin: 23px 0 0;
      width: 100px;
   }

   .order__remove {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 15px;
      height: 15px;
      cursor: pointer;
      outline: none;
      border: none;
      background-color: rgba(0, 0, 0, 0);
   }

   .order__remove:after,
   .order__remove:before {
      content: "";
      display: block;
      width: 15px;
      height: 1px;
      background-color: #fff;
      left: 0;
      position: absolute;
      top: 7px;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .order__remove:hover:after,
   .order__remove:hover:before {
      background-color: #49c3fd;
   }

   .order__remove:after {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
   }

   .order__remove:before {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
   }

   .order__organizer {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 13px;
      line-height: 13px;
      color: #fff;
      margin: 20px 0 0;
   }

   .order__organizer:before {
      content: "Organizer";
      margin-right: 5px;
      opacity: 0.4;
      font-size: 12px;
   }

   .payment {
      right: -100%;
      padding: 60px 100px 0;
   }

   .summary--next {
      right: 100%;
   }

   .payment--next {
      right: 0;
   }

   .payment__header,
   .summary__header {
      font-family: Burbank-Big-Cd-Bd, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: #fff;
      margin-bottom: 50px;
   }

   .summary__order {
      margin-bottom: 20px;
   }

   .payment__order {
      margin-bottom: 30px;
   }

   .payment__order-title,
   .summary__order-title {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 20px;
      line-height: 24px;
      color: #fff;
      opacity: 0.7;
   }

   .payment__order-price,
   .summary__order-price {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      color: #fff;
      padding-left: 15px;
   }

   .payment__total,
   .summary__total {
      padding: 30px 0 28px;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
      border-top: 1px solid hsla(0, 0%, 100%, 0.3);
      margin-bottom: 60px;
   }

   .payment__total {
      border-bottom: 0;
   }

   .payment__total-price,
   .summary__total-price {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 30px;
      line-height: 30px;
      color: #49c3fd;
   }

   .payment__total-text,
   .summary__total-text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
   }

   .payment__total-text,
   .summary__subheader,
   .summary__total-text {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      color: #fff;
   }

   .summary__subheader {
      margin-bottom: 40px;
      margin-top: 40px;
   }

   .summary__edit {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 20px;
      line-height: 24px;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      color: #fff;
      opacity: 0.8;
   }

   .summary__edit:hover {
      color: #fff;
   }

   .summary__detail {
      margin-bottom: 25px;
      line-height: 16px;
      opacity: 0.7;
   }

   .summary__date,
   .summary__detail {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-size: 20px;
      color: #fff;
   }

   .summary__date {
      line-height: 24px;
   }

   .payment__back,
   .payment__checkout,
   .summary__next {
      width: 100%;
      height: 55px;
      margin: 50px 0 30px;
   }

   .payment__subheader {
      font-size: 14px;
      line-height: 14px;
      opacity: 0.7;
      margin-bottom: 12px;
   }

   .payment__input,
   .payment__subheader {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      color: #fff;
   }

   .payment__input {
      height: 55px;
      width: 100%;
      background: #483798;
      border-radius: 4px;
      padding: 17px 20px;
      line-height: 19px;
      mix-blend-mode: normal;
      font-size: 18px;
      letter-spacing: 0.05em;
      border: none;
      outline: none;
      margin-bottom: 30px;
      color: #fff;
   }

   .payment__radio {
      display: none;
   }

   .payment__label {
      position: relative;
      font-family: BurbankSmall-Bold, sans-serif;
      font-weight: 700;
      color: hsla(0, 0%, 100%, 0.4);
      font-size: 13px;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      border: 1px solid #483798;
      letter-spacing: 0;
   }

   .payment__label:after {
      content: "";
      position: absolute;
      right: 16px;
      top: 19px;
      background-size: 100% 100%;
      background-color: rgba(0, 0, 0, 0);
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .payment__label--credit:after {
      background-image: url(#{$cdn}/img/mastercard.svg);
      width: 19px;
      height: 14px;
      top: 21px;
      opacity: 0.4;
   }

   .payment__label--cad:after {
      background-image: url(#{$cdn}/img/coins.svg);
      width: 21px;
      height: 18px;
      opacity: 0.4;
   }
   .payment__label--crypto:after {
      width: 21px;
      height: 18px;
      opacity: 0.4;
   }

   .payment__label--credit:before {
      content: "";
      position: absolute;
      background-size: 100% 100%;
      background-color: rgba(0, 0, 0, 0);
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      background-image: url(#{$cdn}/img/visa.svg);
      width: 24px;
      height: 8px;
      right: 40px;
      top: 24px;
      opacity: 0.4;
   }

   .payment__radio:checked + .payment__label {
      border: 1px solid #49c3fd;
      color: #49c3fd;
   }

   .payment__radio:checked + .payment__label--credit:before {
      background-image: url(#{$cdn}/img/blue_visa.svg);
   }

   .payment__radio:checked + .payment__label--credit:after {
      background-image: url(#{$cdn}/img/blue_mastercard.svg);
   }

   .payment__radio:checked + .payment__label--cad:after {
      background-image: url(#{$cdn}/img/blue_coins.svg);
   }

   .payment__radio:checked + .payment__label:after,
   .payment__radio:checked + .payment__label:before {
      opacity: 0.5;
   }

   .payment__expiration-input {
      text-align: center;
      padding: 17px 8px;
   }

   .payment__expiration-slash {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      color: #fff;
      margin-bottom: 30px;
   }

   .payment__agree-licence {
      display: none;
   }

   .payment__agree-licence-label {
      width: 20px;
      height: 20px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 14px 10px;
      background-position: 50%;
      background-repeat: no-repeat;
      border-radius: 2px;
      cursor: pointer;
      display: block;
      margin: 0 10px 0 0;
      border: 1px solid #fff;
      opacity: 0.4;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .payment__agree-licence:checked + .payment__agree-licence-label {
      background-color: #49c3fd;
      background-image: url(#{$cdn}/img/ok.svg);
      opacity: 1;
      border: none;
   }

   .payment__agree-licence-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      display: -webkit-flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-items: center;
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 13px;
      line-height: 20px;
      color: #fff;
      padding-bottom: 30px;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
      margin-bottom: 40px;
   }

   .payment__back,
   .payment__checkout {
      margin: 0 0 30px;
   }

   .popup__header {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 25px;
   }

   .popup__header,
   .popup__text {
      width: 100%;
      font-style: normal;
      text-align: center;
      color: #001030;
   }

   .popup__text {
      font-family: BurbankSmall-Medium, sans-serif;
      font-size: 15px;
      line-height: 26px;
      margin-bottom: 20px;
   }

   .window__button {
      width: 205px;
      height: 40px;
   }

   .payment__header,
   .summary__header {
      font-size: 24px;
      line-height: 24px;
   }

   .payment__order-title,
   .summary__order-title {
      font-size: 16px;
      line-height: 22px;
   }

   .payment__order-price,
   .summary__order-price {
      font-size: 20px;
      line-height: 22px;
   }

   .summary__subheader {
      font-size: 20px;
      line-height: 20px;
   }

   .summary__edit {
      font-size: 16px;
      line-height: 20px;
   }

   .summary__date,
   .summary__detail {
      font-size: 16px;
   }
   .card-detail{
      
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      margin-bottom: 40px;

    }
    .cart-text{
      color: #fff;
      font-weight: 500;
      font-size: 15px;
    }

   @media screen and (max-width: 1199.98px) {
      .orders__table-headers {
         padding-right: 146px;
      }
      .order__title {
         margin: 5px 0 10px;
      }
   }

   @media screen and (max-width: 991.98px) {
      .orders__table-headers {
         padding-right: 100px;
      }
      .order__price {
         width: 70px;
      }
      .order__remaining {
         margin: 15px 0px 0;
      }
      .order__organizer {
         margin: 10px 0 0;
      }
      .order__organizer:before {
         margin-bottom: 7px;
      }
   }

   @media screen and (max-width: 767.98px) {
      .orders__table-headers {
         display: none;
      }
      .order--participant:after,
      .order--viewer:after {
         bottom: 20px;
      }
      .order--participant:before,
      .order--viewer:before {
         bottom: 48px;
      }
      .order__remove {
         top: 15px;
         right: 12px;
      }
      .order__remaining {
         margin: 12px 0 0;
      }
   }

   @media screen and (max-width: 567.98px) {
      .order {
         -ms-flex-negative: 0;
         flex-shrink: 0;
      }
      .order--participant:after,
      .order--viewer:after {
         font-size: 13px;
      }
      .order--viewer:after {
         width: 130px;
      }
      .order--participant:after {
         width: 150px;
      }
      .order__picture {
         width: 100%;
         height: auto;
      }
   }

   .article .role-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 48%;
      height: 88px;
      background: #25324e;
      border: 1px solid rgba(73, 195, 253, 0);
      box-sizing: border-box;
      border-radius: 4px;
      transition: 200ms;
      cursor: pointer;
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.2);
      margin: 0 0 11px 0;
   }

   .article .listed-label {
      width: 48%;
      height: 54px;
      background: #25324e;
      border: 1px solid rgba(73, 195, 253, 0);
      border-radius: 4px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      justify-content: center;
      -webkit-justify-content: center;
      align-items: center;
      -webkit-align-items: center;
      color: white;
      font-size: 16px;
      line-height: 16px;
      font-family: "BurbankSmall-Light", sans-serif;
      transition: 200ms;
   }

   .adm .listed-label__round {
      display: block;
      background: white;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin: 0 6px 0 0;
      border: 3px solid #25324e;
      box-shadow: 0 0 0 1px white;
      box-sizing: content-box;
      transition: 200ms;
   }

   .back-container {
      margin-top: 100px;
      padding: 0 130px;
   }

   .back {
      font-family: "BurbankSmall-Medium", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      color: white;
      z-index: 10;
      text-decoration: none;
   }

   .back:hover {
      text-decoration: none;
      color: white;
   }

   .back::after {
      position: relative;
      width: 17px;
      height: 14px;
      margin: 6px 0 0 10px;
      display: inline-block;
      content: "";
      background-size: 100% 100%;
      background-color: transparent;
      background-image: url(#{$cdn}/img/arrow_left.svg);
      transform: rotate(180deg);
   }

   input::-ms-clear,
   input::-ms-reveal {
      display: none;
   }

   .bordered-box {
      border: 1px solid;
      padding: 3px;
   }

   .grey-button:hover,
   .grey-button--filled:hover {
      background: rgba(255, 255, 255, 0.2);
   }

   .col-sm-auto.col-12.p-0 {
      z-index: 0;
   }

   .blue-button1 {
      background: rgba(0, 0, 0, 0);
      color: #49c3fd;
      border: 1px solid #49c3fd;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .blue-button1:hover {
      color: #fff;
      text-decoration: none;
      background: #49c3fd;
   }

   .blue-button--filled-no-gradient:hover {
      background: #0389fd;
   }

   .red-hover-button:hover {
      background: #e13b4c;
      border: none;
      color: #fff;
   }

   @keyframes show {
      0% {
         opacity: 0;
         -webkit-transform: translate(-50%, -8px), scale(0.01);
         transform: translate(-50%, -8px), scale(0.01);
      }
      to {
         opacity: 1;
         -webkit-transform: translate(-50%, -8px), scale(1);
         transform: translate(-50%, -8px), scale(1);
      }
   }

   .map {
      margin: 40px -20px;
      width: calc(100% + 40px);
      position: relative;
   }

   .map svg {
      width: 100%;
      height: 100%;
      stroke: transparent;
   }

   .map__region {
      fill: #0f244b;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      stroke-width: 1px;
   }

   .map__region:hover {
      fill: #253b64;
   }

   .map__region--tournaments {
      fill: #3c3daa;
      cursor: pointer;
   }

   .map__region--tournaments:hover {
      fill: #6667d7;
   }

   .map__sphere {
      border-radius: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
   }

   .map__label,
   .map__sphere {
      position: absolute;
      background: #fff;
   }

   .map__label {
      border-radius: 2px;
      padding: 11px 15px;
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #001030;
      -webkit-transform: translate(-50%, -8px) scale(0.1);
      -ms-transform: translate(-50%, -8px) scale(0.1);
      transform: translate(-50%, -8px) scale(0.1);
      opacity: 0;
      left: 0;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
      z-index: 900;
   }

   .map__label--show {
      -webkit-transform: translate(-50%, -8px) scale(1);
      -ms-transform: translate(-50%, -8px) scale(1);
      transform: translate(-50%, -8px) scale(1);
      opacity: 1;
   }

   .map__label:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      bottom: -9px;
      border: 6px solid rgba(0, 0, 0, 0);
      border-top: 3px solid #fff;
      -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
   }

   .map__label--no-tournaments {
      -webkit-transform: translate(-50%) scale(0.1);
      -ms-transform: translate(-50%) scale(0.1);
      transform: translate(-50%) scale(0.1);
   }

   .map__label--no-tournaments .map__country {
      opacity: 1;
      margin-bottom: 0;
   }

   .map__label--no-tournaments:before {
      display: none;
   }

   .map__label--no-tournaments.map__label--show {
      -webkit-transform: translate(-50%) scale(1);
      -ms-transform: translate(-50%) scale(1);
      transform: translate(-50%) scale(1);
   }

   .map__country {
      opacity: 0.5;
      margin-bottom: 6px;
   }

   .map__country,
   .map__tournaments {
      white-space: nowrap;
   }

   .adm .search:after {
      content: "";
      width: 14px;
      height: 14px;
      left: 15px;
      background-size: 100% 100%;
      background-image: url(#{$cdn}/img/search-dark.svg);
      opacity: 0.5;
   }

   .search:after,
   .search__clear {
      z-index: 0;
      position: absolute;
      top: 13px;
      background-color: rgba(0, 0, 0, 0);
   }

   .search__clear {
      right: 15px;
      width: 15px;
      height: 15px;
      background-size: 10px 9px;
      background-position: 50%;
      background-repeat: no-repeat;
      display: block;
      text-decoration: none;
      background-image: url(#{$cdn}/img/cross.svg);
      cursor: pointer;
      opacity: 0;
      -webkit-transition: 0.1s;
      -o-transition: 0.1s;
      transition: 0.1s;
   }

   @-webkit-keyframes showBackground {
      0% {
         width: 100%;
         height: 2px;
      }
      to {
         width: 100%;
         height: 100%;
      }
   }

   @keyframes showBackground {
      0% {
         width: 100%;
         height: 2px;
      }
      to {
         width: 100%;
         height: 100%;
      }
   }

   @-webkit-keyframes showWindow {
      0% {
         opacity: 0;
      }
      30% {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
   }

   @keyframes showWindow {
      0% {
         opacity: 0;
      }
      30% {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
   }

   .modal-container--opened {
      display: block;
   }

   .modal-container__background {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 2px;
      left: 50%;
      top: 50%;
      background: #051534;
      opacity: 0.7;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
   }

   .modal-container--opened .modal-container__background {
      width: 100%;
      height: 100%;
      -webkit-animation: showBackground 0.35s ease-in-out;
      animation: showBackground 0.35s ease-in-out;
   }

   .tournament-info {
      padding: 20px 25px;
      background: hsla(0, 0%, 100%, 0.1);
      width: 486px;
   }

   .tournament-info__header {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #fff;
      margin-bottom: 15px;
   }

   .tournament-info__label {
      width: 136px;
      height: 26px;
      border-radius: 2px;
      font-family: BurbankSmall-Bold, sans-serif;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 26px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
   }

   .tournament-info__label--green {
      background: rgba(108, 208, 0, 0.15);
      color: #6cd000;
   }

   .tournament-info__open-bracket {
      display: block;
      width: 40px;
      height: 40px;
      background-color: hsla(0, 0%, 100%, 0.15);
      position: relative;
      cursor: pointer;
   }

   .tournament-info__open-bracket:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      background-size: 18px 21px;
      background-position: 50%;
      background-repeat: no-repeat;
      background-image: url(#{$cdn}/img/bracket.svg);
      opacity: 0.6;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .tournament-info__open-bracket:hover:after {
      opacity: 1;
   }

   .tournament-info__hr {
      height: 1px;
      margin: 21px -15px;
      background: #fff;
      opacity: 0.1;
   }

   .tournament-info__info {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      color: #fff;
   }

   .tournament-info__info:before {
      display: inline-block;
      width: 63px;
      font-size: 12px;
      opacity: 0.4;
   }

   .tournament-info__info--organizer {
      margin-bottom: 20px;
   }

   .tournament-info__info--organizer:before {
      content: "Organizer";
   }

   .tournament-info__info--venue:before {
      content: "Venue";
   }

   .tournament-info__winner {
      border-left: 1px solid hsla(0, 0%, 100%, 0.1);
   }

   .tournament-info__winner-picture {
      width: 22px;
      height: 22px;
      margin-right: 7px;
      border-radius: 50%;
   }

   .tournament-info__winner-username {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #fff;
      text-decoration: none;
   }

   .tournament-info__winner-username:hover {
      color: #fff;
      text-decoration: none;
   }

   .tournament-info__winner-prize {
      position: absolute;
      right: -13px;
      top: 1px;
      width: 19px;
      height: 19px;
      background-size: 100% 100%;
      background-image: url(#{$cdn}/img/gold_cup.svg);
   }

   .tournament-info__open-winners {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: #fff;
      opacity: 0.5;
      margin: 13px 0 6px;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .tournament-info__open-winners:hover {
      text-decoration: none;
      color: #fff;
      opacity: 1;
   }

   .tournament-info__open-winners:after {
      content: "";
      display: inline-block;
      width: 9px;
      height: 8px;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-image: url(#{$cdn}/img/arrow-right.svg);
      margin-left: 7px;
   }

   .tournament-info__notifier-container {
      position: absolute;
      left: 50%;
      bottom: 100%;
      width: 171px;
      padding-bottom: 8px;
      -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
      z-index: 9999;
      display: none;
      opacity: 0;
   }

   .tournament-info__notifier-container:hover {
      opacity: 1;
      display: block;
   }

   .tournament-info__open-bracket:hover ~ .tournament-info__notifier-container {
      opacity: 1;
      display: block;
      -webkit-animation: show 0.15s ease-in-out;
      animation: show 0.15s ease-in-out;
   }

   .tournament-info__notifier-content {
      background: #fff;
      border-radius: 2px;
      padding: 8px 16px;
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #001030;
   }

   .tournament-info__notifier-content:before {
      content: "";
      position: absolute;
      display: block;
      left: 50%;
      bottom: -1px;
      width: 0;
      height: 0;
      border: 6px solid rgba(0, 0, 0, 0);
      border-top: 3px solid #fff;
      -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
   }

   .movies {
      position: relative;
      width: calc(100% - 486px);
   }

   .movies__select {
      width: 235px;
      height: 34px;
      background: #25324e;
      border-radius: 2px;
   }

   .movies__slides {
      overflow-x: scroll;
   }

   .movies__slides::-webkit-scrollbar {
      width: 1px;
      height: 7px;
      border: none;
      background-color: rgba(255, 255, 255, 0.1);
      overflow: hidden;
      border-radius: 0 3px 3px 0;
   }

   .movies__slides::-webkit-scrollbar-thumb {
      /* width: 1px;
	height: 10px;
	border: none; */
      background-color: rgba(255, 255, 255, 0.445);
      /* overflow: hidden;
	border-radius: 0 3px 3px 0; */
   }

   .movies__next,
   .movies__prev {
      width: 34px;
      height: 34px;
      margin-left: 5px;
      background: hsla(0, 0%, 100%, 0.1);
      border-radius: 4px;
      position: relative;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .movies__next:after,
   .movies__prev:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      background-size: 6px 10px;
      background-image: url(#{$cdn}/img/arrow_right-2.svg);
      background-repeat: no-repeat;
      background-position: 50%;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .movies__next:hover,
   .movies__prev:hover {
      background: rgba(73, 195, 253, 0.15);
   }

   .movies__next:hover:after,
   .movies__prev:hover:after {
      background-image: url(#{$cdn}/img/blue_arrow_right_simple.svg);
   }

   .movies__prev:after {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
   }

   .movies__slides {
      margin: 0;
      overflow: hidden;
      width: 100%;
   }

   .movies__movie {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      padding: 0 15px 0 0;
      margin-top: 20px;
      width: calc((100% + 15px) / 4);
   }

   .movies__movie:last-of-type {
      padding: 0;
      width: calc((100% + 15px) / 4 - 15px);
   }

   .movies__stream {
      height: 132px;
      width: 100%;
      position: relative;
      border-radius: 2px;
      overflow: hidden;
   }

   .movies__stream-preview {
      width: 100%;
      height: 100%;
      display: block;
      background-size: cover;
      background-position: 50%;
      border-radius: 2px;
      cursor: pointer;
   }

   .movies__stream-preview:last-of-type {
      position: absolute;
      left: -25px;
      top: 0;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .movies__stream-preview:last-of-type div {
      opacity: 0;
      background: rgba(0, 16, 48, 0.5);
      width: 100%;
      height: calc(100% - 36px);
      position: absolute;
      display: block;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      z-index: 0;
   }

   .movies__stream-preview:first-of-type {
      left: 0;
   }

   .movies__stream-preview:first-of-type:hover + .movies__stream-preview:last-of-type {
      left: -50px;
   }

   .movies__stream-preview:first-of-type:hover + .movies__stream-preview:last-of-type div {
      opacity: 1;
   }

   .movies__stream-preview:last-of-type:before {
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% - 36px);
      background: rgba(0, 16, 48, 0.5);
   }

   .movies__stream-preview:last-of-type:after,
   .movies__stream-preview:last-of-type:before {
      content: "";
      position: absolute;
      opacity: 0;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
   }

   .movies__stream-preview:last-of-type:after {
      left: 50%;
      top: calc(50% - 18px);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-size: 18px 18px;
      background-position: 50%;
      background-repeat: no-repeat;
      background-color: hsla(0, 0%, 100%, 0.25);
      background-image: url(#{$cdn}/img/play.svg);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
   }

   .movies__stream-preview:last-of-type:hover:after,
   .movies__stream-preview:last-of-type:hover:before {
      opacity: 1;
   }

   .movies__stream-author {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 36px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: rgba(0, 16, 48, 0.8);
      border-radius: 0 0 2px 2px;
      padding: 0 10px;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .movies__stream-author--two {
      left: -25px;
      padding-left: 35px;
   }

   .movies__stream-preview:first-of-type:hover ~ .movies__stream-author--two {
      left: -50px;
      padding-left: 60px;
   }

   .movies__stream-profile-picture {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 7px 0 0;
   }

   .movies__stream-username {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.02em;
      color: #fff;
   }

   .movies__number {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      opacity: 0.3;
   }

   .movies__number span {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      display: block;
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 12px;
      color: #fff;
      padding-right: 10px;
      margin-top: 3px;
   }

   .movies__number:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: #fff;
   }

   .movies__details {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 12px;
      color: #fff;
   }

   .movies__details:hover {
      color: #fff;
      text-decoration: none;
   }

   iframe {
      width: 100%;
   }

   .movies__select .select__title {
      line-height: 34px;
   }

   .movies__select .select__title:before {
      top: 13px;
   }

   .movies__select .select__container {
      top: 40px;
   }

   #winners .window {
      width: 666px;
      padding: 50px 40px;
      max-height: 556px;
   }

   #match-info .window {
      width: 870px;
      padding: 50px 35px;
   }

   .window__header {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 30px;
      color: #001030;
      margin-bottom: 20px;
      padding: 0 20px 0 30px;
   }

   .window__subheader {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.05em;
      margin-bottom: 30px;
      padding: 0 0px;
   }

   .window .table__main-row {
      height: 60px;
   }

   .window .table__main-row,
   .window .table__secondary-row-container {
      background: #f5f5f5;
      padding: 0 30px;
   }

   .window .table__secondary-row {
      height: 50px;
   }

   .window .table__secondary-row-container {
      padding: 0 0 0 76px;
   }

   .window .table__checkbox:checked ~ .table__main-row {
      border-bottom: 1px solid rgba(0, 16, 48, 0.05);
   }

   .window .table__checkbox:checked ~ .table__secondary-row-container {
      padding: 10px 0 10px 76px;
   }

   .window .table__open {
      border: 1px solid #e0e1e2;
      width: 36px;
      height: 32px;
   }

   .window .table__open:before {
      background-image: url(#{$cdn}/img/grey_arrow_top.svg);
   }

   .window .table__checkbox:checked ~ .table__main-row .table__open {
      background-color: rgba(0, 16, 48, 0.05);
   }

   .window__label {
      width: 159px;
      height: 30px;
      background: rgba(73, 195, 253, 0.1);
      border-radius: 2px;
      text-align: center;
      font-family: BurbankSmall-Bold, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 30px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #49c3fd;
   }

   .window__winner-pciture {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 10px;
   }

   .window__winner-username {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #001030;
   }

   .window__winner-label {
      width: 90px;
      height: 30px;
      border-radius: 2px;
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #fff;
      background: #49c3fd;
   }

   .window__winner-label--gold {
      background: #f9d100;
   }

   .window__winner-label--silver {
      background: #d4d4d4;
   }

   .window__winner-label--bronze {
      background: #c88d61;
   }

   .window__player {
      height: 90px;
      padding: 0 30px;
      background: #f5f5f5;
   }

   .window__player-picture {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
   }

   .window__player-username {
      font-family: Burbank-Big-Rg-Md, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #001030;
   }

   .window__player-label {
      width: 90px;
      height: 30px;
      border-radius: 2px;
      text-align: center;
      font-family: BurbankSmall-Bold, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 30px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
   }

   .window__player-label--green {
      background: rgba(108, 208, 0, 0.2);
      color: #6cd000;
   }

   .window__player-label--red {
      background: rgba(225, 59, 76, 0.2);
      color: #e13b4c;
   }

   #match-info .window__header {
      font-family: BurbankSmall-Bold, sans-serif;
      font-weight: 700;
   }

   #show-hidden:checked ~ .table__checkbox-hide:checked + .row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
   }

   #show-hidden:checked ~ .table__headers-row .table__radio {
      background-color: #ec5418;
      border: 1px solid #ec5418;
      background-image: url(#{$cdn}/img/ok.svg);
   }

   .table__column--tournament {
      width: 240%;
   }

   .table__column--tournament:before {
      content: "Tournament";
   }

   .table__column--user {
      width: 340%;
   }

   .table__column--date {
      width: 100%;
   }

   .table__column--date:before {
      content: "Date";
   }

   .table__column--venue {
      width: 80%;
   }

   .table__column--venue:before {
      content: "Venue";
   }

   .table__column--button {
      width: 200%;
   }

   .table__column--brackets {
      width: 140%;
   }

   .table__column--brackets:before {
      content: "Brackets";
   }

   .table__column--type {
      width: 80%;
   }

   .table__column--type:before {
      content: "Type";
   }

   .table__column--link {
      width: 280%;
   }

   .table__column--link:before {
      content: "Link";
   }

   .table__column--buttons {
      width: 300px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
   }

   .table__delete {
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: hsla(0, 0%, 100%, 0.2);
      background-size: 21px 21px;
      background-position: 50%;
      background-repeat: no-repeat;
      background-image: url(#{$cdn}/img/grey_delete.svg);
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      margin-left: 10px;
   }

   .table__delete:hover {
      background-image: url(#{$cdn}/img/white_delete.svg);
      background-color: #e13b4c;
   }

   .article .table__secondary-row .table__column:before {
      display: none;
   }

   .tabs {
      margin-top: 35px;
   }

   .tabs__tab {
      width: 100px;
      height: 36px;
      border: 1px solid #3c3daa;
      font-family: Burbank-Big-Cd-Bd, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #fff;
      cursor: pointer;
   }

   .tabs__tab:hover {
      color: #fff;
      text-decoration: none;
   }

   .tabs__tab--active {
      background: #3c3daa;
   }

   .tabs__tab:first-of-type {
      border-radius: 2px 0 0 2px;
   }

   .tabs__tab:last-of-type {
      border-radius: 0 2px 2px 0;
   }

   @media screen and (max-width: 575.98px) {
      .window__container {
         width: calc(100% - 20px);
         height: calc(100% - 130px);
         position: absolute;
         top: 110px;
         left: 10px;
         padding: 30px 20px 10px;
      }
      .window__header-container {
         padding: 20px 30px 0;
         z-index: 10;
      }
      .window__profile-status {
         margin: 5px 0 30px;
      }
      .movies__select {
         width: 130px;
      }
      #match-info .window,
      #winners .window {
         padding: 30px 10px;
      }
      .window .table__main-row,
      .window .table__secondary-row-container {
         padding: 0 20px;
      }
      .window .table__main-row--clans {
         height: 100px;
      }
      .window__label {
         margin-left: 30px;
      }
      .table__main-row--clans .window__winner-pciture-pictire {
         margin-bottom: 10px;
      }
      .window__winner-pciture {
         width: 30px;
         height: 30px;
      }
      .window__winner-username {
         font-size: 14px;
      }
      .window__winner-label {
         width: 80px;
      }
      .window__player {
         height: 70px;
         padding: 0 15px;
      }
      .window__player-picture {
         width: 36px;
         height: 36px;
      }
      .window__player-username {
         font-size: 14px;
      }
      .window__player-label {
         width: 80px;
      }
      .tabs {
         margin-top: -10px;
         margin-bottom: 15px;
      }
   }

   @media screen and (max-width: 575.98px) and (min-width: 0) {
      .table__column {
         width: 100%;
         padding: 3px 0;
      }
      .table__secondary-row .table__column {
         font-size: 14px;
      }
      .table__warning-content {
         -webkit-transform: translate(-80%);
         -ms-transform: translate(-80%);
         transform: translate(-80%);
      }
      .table__warning-content:before {
         left: 80%;
      }
      .table__warning:hover .table__warning-content {
         display: block;
      }
   }

   @media screen and (max-width: 1199.98px) {
      .tournament-info {
         width: 100%;
         margin-bottom: 20px;
      }
      .table__column--tournament {
         width: 100%;
      }
   }

   @media screen and (max-width: 1549.98px) and (min-width: 1300px) {
      .movies__movie {
         width: calc((100% + 15px) / 3);
      }
      .movies__movie:last-of-type {
         padding: 0;
         width: calc((100% + 15px) / 3 - 15px);
      }
   }

   @media screen and (max-width: 1299.98px) and (min-width: 1200px) {
      .movies__movie {
         width: calc((100% + 15px) / 2);
      }
      .movies__movie:last-of-type {
         padding: 0;
         width: calc((100% + 15px) / 2 - 15px);
      }
   }

   @media screen and (max-width: 849.98px) and (min-width: 600px) {
      .movies__movie {
         width: calc((100% + 15px) / 3);
      }
      .movies__movie:last-of-type {
         padding: 0;
         width: calc((100% + 15px) / 3 - 15px);
      }
   }

   @media screen and (max-width: 599.98px) and (min-width: 400px) {
      .movies__movie {
         width: calc((100% + 15px) / 2);
      }
      .movies__movie:last-of-type {
         padding: 0;
         width: calc((100% + 15px) / 2 - 15px);
      }
   }

   @media screen and (max-width: 399.98px) and (min-width: 0) {
      .movies__movie,
      .movies__movie:last-of-type {
         width: 100%;
         padding: 0;
      }
   }

   @media screen and (max-width: 991.98px) {
      .window__player {
         margin-bottom: 10px;
      }
      .tab-menu {
         height: 100%;
      }
   }

   @media screen and (max-width: 1499.98px) and (min-width: 1399.98px) {
      .table__column {
         padding: 0 10px;
      }
      .table__row {
         margin-bottom: 15px;
      }
   }

   @media screen and (max-width: 1399.98px) {
      .table__headers-row {
         display: none;
      }
      .table__row .table__column:before {
         display: inline-block;
      }
      .table__open-replacer {
         display: none;
      }
      .table__column--buttons {
         width: 100%;
      }
   }

   @media screen and (max-width: 1399.98px) and (min-width: 768px) {
      .table__column {
         width: 50%;
         padding: 3px 0;
      }
   }

   @media screen and (max-width: 399.98px) {
      .table__button {
         width: 90px;
      }
      .table__column--link a {
         word-break: break-word;
      }
   }

   .pl-20 {
      padding-left: 20px;
   }

   @media screen and (max-width: 384.98px) {
      .tabs__tab {
         width: 80px;
      }
   }

   /* .................pagination css..................... */

   /* React Player -------------------- */

   .player-wrapper {
      position: relative;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
   }

   .react-player {
      position: absolute;
      top: 0;
      left: 0;
   }

   .bil_modal-container .window__header {
      font-weight: 500;
      font-size: 21px;
      line-height: 35px;
   }

   .bil_modal-container--small .bil_modal-container__window {
      max-width: 490px;
      width: 100%;
   }

   .bil_modal-container__window {
      height: auto;
      max-height: calc(100% - 100px);
      overflow: auto;
   }

   .bil_modal-container__window .window__container {
      position: static;
      margin: 0 auto;
      padding: 0 20px 25px 20px;
   }

   .bil_modal-container__window .window__header {
      margin-bottom: 30px;
   }

   .bil_modal_btns {
      margin: 10px -5px 10px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      justify-content: center;
   }

   .bil_modal_btns_i {
      width: 180px;
      max-width: 180px;
      padding: 5px 5px;
   }

   .bttn_blue-outline {
      position: static;
      padding: 2px 0 0;
      display: block;
      width: 100%;
      height: 40px;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: none;
      background: transparent;
      border: 1px solid #49c3fd;
      border-radius: 2px;
      font-family: "Burbank-Big-Cd-Bd";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #49c3fd;
   }

   .custom-close {
      display: block;
      width: 16px;
      height: 16px;
      cursor: pointer;
      position: absolute;
      right: 40px;
      top: 25px;
      z-index: 99;
   }

   div#victory-road-filters {
      z-index: 99999999;
   }

   .prize__reward-info-text a {
      color: #001030;
      text-decoration: none;
      position: relative;
      border-bottom: 1px solid #001030;
   }

   .prize__reward-info-text a:hover {
      text-decoration: none;
   }

   .prize__reward-type {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #fff;
      opacity: 0.5;
      margin-top: 5px;
   }

   .help {
      position: relative;
      padding: 10px 80px;
      margin: 55px 0 70px;
      background: #1e2c48;
      border-radius: 2px;
   }

   .help__container {
      padding-top: 60px;
      padding-bottom: 60px;
   }

   .help__header {
      font-weight: 700;
      font-size: 30px;
      line-height: 46px;
      margin: 0;
      -webkit-margin-before: 0;
      margin-block-start: 0;
      -webkit-margin-after: 0;
      margin-block-end: 0;
   }

   .adm .help__header,
   .adm .help__text {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      color: #001030;
   }

   .help__text {
      font-size: 15px;
      line-height: 26px;
      margin: 20px 0 30px;
   }

   .help__text a {
      color: #49c3fd;
      text-decoration: none;
   }

   .help__text a:hover {
      color: #49c3fd;
      text-decoration: underline;
   }

   .help__button {
      width: 150px;
      height: 40px;
   }

   .header__tab-menu-container {
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 0;
      width: 100%;
      height: 40px;
      padding: 0 60px;
   }

   .header__tab-menu-button {
      position: absolute;
      background-color: rgba(0, 0, 0, 0);
      background-size: 100% 100%;
      background-position: 50%;
      background-image: url(#{$cdn}/img/arrow_top.svg);
      border: none;
      cursor: pointer;
      bottom: 22px;
      width: 20px;
      height: 12px;
      display: none;
   }

   .header__tab-menu-button--left {
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      left: 30px;
   }

   .header__tab-menu-button--right {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      right: 30px;
   }

   .tab-menu {
      position: relative;
      margin: 0;
      -ms-overflow-style: none;
   }

   .tab-menu::-webkit-scrollbar {
      display: none;
   }

   .table__main-row {
      position: relative;
      background: hsla(0, 0%, 100%, 0.1);
      z-index: 2;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0);
   }

   .article__label-container--vertical:hover .article__color-director-value {
      opacity: 1;
   }

   .article__tab-link {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      color: #001030;
      text-decoration: none;
      opacity: 0.5;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      margin-right: 20px;
   }

   .article__tab-link--active {
      opacity: 1;
      color: #3c3daa;
      border-bottom: 1px solid #3c3daa;
   }

   .article__tab-link:hover {
      text-decoration: none;
      color: #001030;
   }

   .canvas {
      width: 100%;
   }

   .article__table-row {
      width: 100%;
      margin: 0;
      height: 48px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      display: -ms-flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-justify-content: space-between;
   }

   .article__table-row--headers {
      border-top: 1px solid #e8eef6;
      margin: 25px -30px 0;
      padding: 0 30px;
      width: calc(100% + 60px);
   }

   .article__column {
      width: 100%;
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #001030;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
   }

   .article__table-row--headers .article__column {
      font-size: 14px;
      opacity: 0.5;
   }

   .article__table-row--default:hover .article__column {
      color: #cb25b3;
   }

   .article__column--date {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 100px;
   }

   .article__column--fees {
      text-align: center;
      width: 80%;
   }

   .article__column--received {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 150px;
   }

   .article__table-row--headers .article__column--received:after {
      content: " money";
   }

   .article__income {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      font-size: 20px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #001030;
   }

   .article__income span {
      font-size: 13px;
      line-height: 16px;
      opacity: 0.5;
   }

   .tooltip-country {
      font-size: 14px;
      line-height: 13px;
      opacity: 0.7;
   }

   .tooltip-country,
   .tooltip-persons {
      font-family: BurbankSmall-Medium, sans-serif;
      font-style: normal;
      color: #001030;
   }

   .tooltip-persons {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 7px;
   }

   .article__label-container--countries {
      -webkit-transition: 0.1s;
      -o-transition: 0.1s;
      transition: 0.1s;
   }

   @media screen and (max-width: 1659.98px) {
      .article__container-header-container {
         width: 100%;
         -webkit-box-flex: 0;
         flex: 0 0 100%;
         -ms-flex: 0 0 100%;
         margin-bottom: 10px;
      }
      .article__column--date {
         width: 70px;
      }
   }

   @media screen and (max-width: 1519.98px) {
      .article__countries-canvas-container,
      .article__countries-label-container {
         width: 100%;
         -webkit-box-flex: 0;
         flex: 0 0 100%;
         -ms-flex: 0 0 100%;
         max-width: 100%;
      }
      .article__countries-canvas-container {
         margin-bottom: 30px;
         padding: 0 15%;
      }
      .article__label-container--vertical {
         margin-bottom: 25px;
         margin-left: 0;
      }
      .article__column--received {
         width: 80px;
      }
      .article__column--received:after {
         content: "";
      }
   }

   @media screen and (max-width: 1399.98px) {
      .article__countries-canvas-container {
         padding: 0 30px;
      }
   }

   @media screen and (max-width: 1199.98px) {
      .article__countries-canvas-container {
         padding: 0 25%;
      }
   }

   @media screen and (max-width: 575.98px) {
      .article__container {
      }
      .article__countries-canvas-container {
         padding: 0 15px;
      }
   }

   .adm .article__container {

      padding: 14px 30px 23px;
      width: 100%;
   }
   @media screen and (max-width: 575.98px) {
      .adm .article__container {
         margin-top: 0px;
         padding: 15px 30px 15px;
      }
   }

   .chartjs-render-monitor {
      animation: chartjs-render-animation 1ms;
      animation-duration: 1ms;
      animation-timing-function: ease;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-direction: normal;
      animation-fill-mode: none;
      animation-play-state: running;
      animation-name: chartjs-render-animation;
   }

   #dougnut label {
      display: none;
   }

   #canvas-holder {
      width: 100%;
      margin-top: 50px;
      text-align: center;
   }

   #chartjs-tooltip {
      opacity: 1;
      position: absolute;
      background: rgba(0, 0, 0, 0.7);
      color: white;
      border-radius: 3px;
      -webkit-transition: all 0.1s ease;
      transition: all 0.1s ease;
      pointer-events: none;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
   }

   .chartjs-tooltip-key {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 10px;
   }

   .chart-legend li span {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 5px;
   }

   .dropdown-scroll {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 10em;
   }

   .col-6.article__countries-label-container {
      overflow-y: scroll;
      height: 100%;
   }

   .white-tooltip-theme .tippy-tooltip {
      border: 1px solid #f0f3f6;
   }

   .adm .page .eli-page {
      margin-left: 0;
      margin-right: 0;
   }
   .alink{
      color:#5e5ee6 !important
    }
   @media (max-width: 1199.98px) {
      .adm .page .article-header__content {
         background: linear-gradient(180deg, rgba(0, 0, 0, 0) calc(100% - 54px), #f5f7fa calc(100% - 54px), #f5f7fa),
            linear-gradient(0deg, rgba(0, 20, 60, 0.6), rgba(0, 20, 60, 0.6));
      }
   }
}

body.grid-full-screen .adm .page .eli-container {
   background: #f5f7fa;
}

@media screen and (max-width: 1000px) and (min-width: 570px) {
   .wg-drop.country-selector a:after {
      display: none;
   }
   .wg-drop.country-selector:not(.closed) ul {
      display: block;
      background-color: #fff;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 55px;
   }
   .wg-default,
   .wg-default .country-selector {
      position: fixed;
      bottom: 5px;
      left: 1px;
      width: 48px;
      transform: scale(1.1);
      margin-left: 4px;
   }
   .wg-drop.country-selector .wgcurrent:after {
      top: -2px;
      right: 0px;
   }
   .wg-drop.country-selector a {
      display: inline-grid;
      height: 30px;
      line-height: 45px;
      color: transparent;
      padding: 6px 3px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 65px;
   }
}

.wg-default,
.wg-default .country-selector {
   position: fixed;
   bottom: 5px;
   left: 1px;
   width: 117px;
}

.wg-default,
.wg-default .country-selector {
   position: fixed;
   bottom: 5px;
   left: 1px;
   width: 117px;
}

.wg-default,
.wg-default .country-selector {
   position: fixed;
   bottom: 5px;
   left: 1px;
   width: 117px;
}


.wg-default,
.wg-default .country-selector {
   position: fixed;
   bottom: 5px;
   left: 1px;
   width: 117px;
}