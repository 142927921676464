.chat {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;
  background: #eef6ff;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
