.rightTile {
  font-family: BurbankSmall-Bold, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  border-bottom: 1px solid #25324e;
  margin-bottom: 20px;
}

.rightValue {
  margin-left: 10px;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: white;
}

.avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.username {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  
  &:hover {
    text-decoration: none;
    color: #49c3fd;
  }
}

.text {
  font-size: 14px;
  color: #fff;
}

.date {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.4);
  padding-top: 5px;
}

.review {
  padding-left: 15px;
}

.star {
  line-height: 36px;
}

.reviewContainer {
  padding-bottom: 15px;
  margin-top: 15px;
  border-bottom: 1px solid #25324e;
}

.messageContainer {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
}

.reviewLabel {
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.textLabel {
  color: #fff;
  font-size: 16px;
  padding-bottom: 10px;
}

.reviewText {
  width: 100%;
  min-height: 120px;
  font-size: 15px;
  padding: 20px 20px;
}

.buttonContainer {
  border-bottom: 1px solid #25324e;
  border-top: 1px solid #25324e;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}