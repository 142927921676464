#modalTicket .window {
  width: 100% !important;
}
#modalTicket div.container-article {
  background: #051534 !important;
}
#modalTicket .color-blue {
  color: #49c3fd;
}

#modalTicket .col-65 {
  width: 54%;
}

#modalTicket .hover-color-green:hover {
  color: #6cd000;
}

.opacity-5 {
  opacity: 0.5;
}

.z-index-5 {
  z-index: 5;
}

.z-index-10 {
  z-index: 10;
}
#modalTicket .blue-button,
#modalTicket .green-button,
#modalTicket .red-button,
#modalTicket .orange-button,
#modalTicket .pink-button,
#modalTicket .purple-button,
#modalTicket .grey-button,
#modalTicket .white-button,
#modalTicket .green-hover-button {
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  border-radius: 2px;
  width: 200px;
  height: 50px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

#modalTicket .blue-button {
  background: transparent;
  color: #49c3fd;
  border: 1px solid #49c3fd;
  transition: 150ms;
}

#modalTicket .blue-button--filled {
  background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  border: none;
  color: white;
}

#modalTicket .blue-button--filled-no-gradient {
  background: #49c3fd;
  border: none;
  color: white;
}

#modalTicket .blue-button--transparent-filled {
  background: linear-gradient(
    90deg,
    rgba(2, 183, 254, 0.1) 0%,
    rgba(3, 137, 253, 0.1) 100%
  );
  border: none;
}

#modalTicket .blue-button:hover {
  color: white;
  text-decoration: none;
  background: #49c3fd;
}

#modalTicket .blue-button--filled:hover {
  background: linear-gradient(90deg, #13a9e4 0%, #0a71cb 100%);
}

#modalTicket .blue-button--transparent-filled:hover {
  background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
}

#modalTicket .blue-button--filled-no-gradient:hover {
  background: #0389fd;
}

#modalTicket #modalTicket .green-button {
  background: transparent;
  color: #6cd000;
  border: 1px solid #6cd000;
  transition: 150ms;
}

#modalTicket .green-button--filled {
  background: #6cd000;
  border: none;
  color: white;
}

#modalTicket .green-button:hover {
  background: #6cd000;
  color: white;
  text-decoration: none;
}

#modalTicket .green-button--filled:hover {
  background: #64bc04;
}

#modalTicket .red-button {
  background: transparent;
  color: #e13b4c;
  border: 1px solid #e13b4c;
  transition: 150ms;
}

#modalTicket .red-button--filled {
  background: #e13b4c;
  border: none;
  color: white;
}

#modalTicket .red-button:hover {
  background: #e13b4c;
  color: white;
  text-decoration: none;
}

#modalTicket .red-button--filled:hover {
  background: #cb2f3f;
}

#modalTicket .orange-button {
  background: transparent;
  color: #ec5418;
  border: 1px solid #ec5418;
  transition: 150ms;
}

#modalTicket .orange-button--filled {
  background: linear-gradient(90deg, #ec5418 0%, #df2d06 100%);
  border: none;
  color: white;
}

#modalTicket .orange-button:hover {
  background: #ec5418;
  color: white;
  text-decoration: none;
}

#modalTicket .orange-button--filled:hover {
  background: linear-gradient(90deg, #df551f 0%, #c22c0b 100%);
}

#modalTicket .pink-button {
  background: transparent;
  color: #cb25b3;
  border: 1px solid #cb25b3;
  transition: 150ms;
}

#modalTicket .pink-button--filled {
  background: linear-gradient(90deg, #cb25b3 0%, #7d2eae 100%);
  border: none;
  color: white;
}

#modalTicket .pink-button:hover {
  background: #cb25b3;
  color: white;
  text-decoration: none;
}

#modalTicket .pink-button--filled:hover {
  background: linear-gradient(90deg, #a91493 0%, #640d9a 100%);
}

#modalTicket .purple-button {
  background: transparent;
  color: #3c3daa;
  border: 1px solid #3c3daa;
  transition: 150ms;
}

#modalTicket .purple-button--filled {
  background: #3c3daa;
  border: none;
  color: white;
}

#modalTicket .purple-button--transparent-filled {
  background: rgba(60, 61, 170, 0.05);
  color: #3c3daa;
  border: none;
}

#modalTicket .pink-button:hover {
  background: #3c3daa;
  color: white;
  text-decoration: none;
}

#modalTicket .pink-button--filled:hover {
  background: #3c3d9c;
}

#modalTicket .purple-button--transparent-filled:hover {
  background: #3c3daa;
  color: white;
}

#modalTicket .grey-button {
  background: transparent;
  color: white;
  border: 1px solid #4b566d;
  opacity: 0.5;
  transition: 150ms;
}

#modalTicket .grey-button--filled {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  opacity: 1;
  transition: none;
}

#modalTicket .grey-button:hover {
  color: white;
  text-decoration: none;
  border: 0 solid transparent;
  background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
  opacity: 1;
}

#modalTicket .grey-button--filled:hover {
  background: linear-gradient(90deg, #02b7fe 0%, #0389fd 100%);
}

#modalTicket .white-button {
  background: transparent;
  color: white;
  border: 1px solid white;
  transition: 150ms;
}

#modalTicket .white-button:hover {
  color: #061235;
  text-decoration: none;
  background: white;
}

#modalTicket .green-hover-button:hover {
  background: #6cd000;
  border: none;
  color: white;
}

#modalTicket .background {
  background-color: #051534;
  z-index: 0;
  opacity: 0.83;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

// .sign-up {
//   display: flex;
//   align-items: center;
//   min-height: 100vh;
// }

// .sign-up__header {
//   color: white;
//   font-family: "Burbank-Big-Cd-Bd", sans-serif;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 45px;
//   line-height: 45px;
//   letter-spacing: 0.05em;
//   margin-block-start: 0;
//   margin-block-end: 0;
//   margin: 40px 0;
// }

// .sign-up__big-header {
//   color: white;
//   font-family: "Burbank-Big-Cd-Bd", sans-serif;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 55px;
//   line-height: 65px;
//   letter-spacing: 0.05em;
//   margin-block-start: 0;
//   margin-block-end: 0;
//   margin: 0 0 30px 0;
// }

// .sign-up__subheader {
//   color: white;
//   font-family: "BurbankSmall-Medium", sans-serif;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 26px;
//   margin-block-start: 0;
//   margin-block-end: 0;
// }

@media screen and (max-width: 575.98px) {
  #modalTicket .sign-up__header {
    font-size: 38px;
    line-height: 38px;
  }
  #modalTicket .sign-up__big-header {
    font-size: 45px;
    line-height: 45px;
  }
  #modalTicket .sign-up__subheader {
    font-size: 16px;
    line-height: 22px;
  }
}

#modalTicket .sign-up__role {
  display: none;
}

#modalTicket .role-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 48%;
  height: 88px;
  background: #394664;
  border: 1px solid rgba(73, 195, 253, 0);
  box-sizing: border-box;
  border-radius: 4px;
  transition: 200ms;
  cursor: pointer;
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: #7584a5;
  //color: rgba(255, 255, 255, 0.2);
  margin: 0 0 11px 0;
}

// .sign-up__role:checked + .role-label {
//   border: 1px solid #49c3fd;
//   color: #49c3fd;
// }
// .sign-up__role:checked + .role-label .role-label__svg svg {
//   fill: #49c3fd;
// }

#modalTicket .role-label__svg {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 0 8px 0;
}

#modalTicket .role-label__svg svg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  // width: 100%;
  // height: 100%;
  // transition: 200ms;
  // fill: rgba(255, 255, 255, 0.2);
}

#modalTicket .sign-up__input-title {
  font-family: "BurbankSmall-Light", sans-serif;
  font-style: normal;
  // font-size: 14px;
  line-height: 14px;
  margin: 19px 0 12px 0;

  color: rgba(255, 255, 255, 0.7);
}

#modalTicket .sign-up__default-input {
  width: 100%;
  height: 55px;
  outline: none;
  background-color: #303e5c;
  border-radius: 4px;
  color: white;
  box-sizing: border-box;
  padding: 0 20px;
  border: none;
}

#modalTicket .sign-up__default-input:focus {
  border: 1px solid #49c3fd;
  padding: 0 19px;
}

#modalTicket .sign-up__clean-button {
  position: absolute;
  right: 16px;
  top: 19px;
  width: 18px;
  height: 16px;
  background-color: transparent;
  background-size: 10px 9px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("https://kombatlink-assets.azureedge.net/img/grey_cross.svg");
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 0;
  opacity: 0;
  transition: 150ms;
}

#modalTicket .sign-up__default-input:focus + .sign-up__clean-button {
  opacity: 1;
  z-index: 2;
}

#modalTicket .sign-up__visible-button {
  position: absolute;
  right: 16px;
  top: 19px;
  width: 18px;
  height: 16px;
  background-color: transparent;
  background-size: 18px 11px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("https://kombatlink-assets.azureedge.net/img/visible.svg");
  // background-image: url("../assets/visible.svg");
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 0;
  opacity: 0;
  transition: 150ms;
}

#modalTicket .sign-up__default-input:focus + .sign-up__visible-button {
  opacity: 0.5;
  z-index: 2;
}

// .sign-up__select {
//   appearance: none;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   z-index: 0;
//   position: absolute;
// }

// .sign-up__input-date {
//   background-color: transparent;
//   z-index: 2;
//   position: absolute;
// }

// .sign-up__input-date::-webkit-outer-spin-button,
// .sign-up__input-date::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
//   display: none;
// }

// .sign-up__input-date::-webkit-calendar-picker-indicator {
//   display: block;
//   position: absolute;
//   top: 20px;
//   right: 17px;
//   width: 12px;
//   height: 10px;
//   background-color: #25324e;
//   background-image: url("https://kombatlink-assets.azureedge.net/img/calendar.svg");
//   //background-image: url("../assets/calendar.svg");
//   background-size: 100% 100%;
//   z-index: 10;
//   color: transparent;
//   cursor: pointer;
// }

#modalTicket .sign-up__container {
  position: relative;
  background: #25324e;
  border-radius: 4px;
  height: 55px;
  width: 100%;
}

#modalTicket .sign-up__date-container::after {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 17px;
  height: 15px;
  background-color: #25324e;
  background-image: url("https://kombatlink-assets.azureedge.net/img/calendar.svg");
  //background-image: url("../assets/calendar.svg");
  background-size: 100% 100%;
  z-index: 1;
  color: transparent;
  cursor: pointer;
}

// .sign-up__select-container::after,
// .sign-up__select-container::before {
//   content: "";
//   display: block;
//   position: absolute;
//   top: 28px;
//   width: 10px;
//   height: 2px;
//   background-color: white;
//   z-index: 1;
// }

// .sign-up__select-container::after {
//   right: 15px;
//   transform: rotate(-40deg);
// }
// .sign-up__select-container::before {
//   right: 22px;
//   transform: rotate(40deg);
// }

// .sign-up__listed {
//   display: none;
// }

.listed-label {
  // width: 48%;
  height: 54px;
  background: #25324e;
  border: 1px solid rgba(73, 195, 253, 0);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  color: white;
  font-size: 16px;
  line-height: 16px;
  font-family: "BurbankSmall-Light", sans-serif;
  transition: 200ms;
}

.listed-label__round {
  display: block;
  background: white;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 6px 0 0;
  border: 3px solid #25324e;
  box-shadow: 0 0 0 1px white;
  box-sizing: content-box;
  transition: 200ms;
}

// .sign-up__listed:checked + .listed-label {
//   border: 1px solid #6acafc;
//   color: #6acafc;
// }

// .sign-up__listed:checked + .listed-label .listed-label__round {
//   background: #6acafc;
//   box-shadow: 0 0 0 1px #6acafc;
// }

// .sign-up__register {
//   width: 100%;
//   height: 55px;
//   font-size: 18px;
// }

@media screen and(min-width:768px) {
  .sign-up__register {
    margin: 0 10px;
  }
}

.sign-up__agree-licence {
  display: none;
}

.sign-up__agree-licence-label {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-size: 14px 10px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  margin: 0 10px 0 0;
  transition: 150ms;
}

.sign-up__agree-licence:checked + .sign-up__agree-licence-label {
  background-image: url("https://kombatlink-assets.azureedge.net/img/ok.svg");
  //background-image: url("../assets/ok.svg");
  background-color: #49c3fd;
  border: none;
}

.sign-up__agree-licence-container {
  padding: 0;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  font-family: "BurbankSmall-Light", sans-serif;
  font-style: normal;
  font-size: 14px;
  color: white;
}

hr {
  display: block;
  background: white;
  margin: 70px 0;
}

.sign-up__see-more {
  width: 270px;
  height: 55px;
  border-radius: 4px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-size: 20px;
}

.back-container {
  margin-top: 100px;
  padding: 0 130px;
}

.back {
  font-family: "BurbankSmall-Medium", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: white;
  z-index: 10;
  text-decoration: none;
}

.back:hover {
  text-decoration: none;
  color: white;
}

.back::after {
  position: relative;
  width: 17px;
  height: 14px;
  margin: 6px 0 0 10px;
  display: inline-block;
  content: "";
  background-size: 100% 100%;
  background-color: transparent;
  background-image: url("https://kombatlink-assets.azureedge.net/img/arrow_left.svg");
  //background-image: url("../assets/arrow_left.svg");
  transform: rotate(180deg);
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.sign-up input::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.sign-up input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.sign-up input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}

// @import url("../font/stylesheet.css")
.movie-container {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.movie-container select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0;
  padding: 5px 15px;
  margin-bottom: 40px;
  font-size: 14px;
  border-radius: 5px;
}

// .container {
//   max-width: 100%;
// }
// .article-left-col{
//   padding:15px !important
// }
.seats-page .container {
  max-width: 90%;
}
.seat {
  background-color: #49c3fd;
  height: 22px;
  width: 22px;
  margin: 4.27px 4px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 9px;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  color: transparent;
  min-width: 22px;
  max-height: 22px;
}
.col_seat-2 {
  margin-left: -100px;
  width: 10px;
}
.col_seat-1 {
  width: 110px;
  margin-right: 20px;
}
.col_seat-4 {
  margin-left: 15px;
}
.col_seat-3 {
  margin-left: 75px;
}
.col_seat-6 {
  margin-left: -124px;
  width: 10px;
}
.col_seat-5 {
  margin-left: -93px;
}
.col_seat-8 {
  width: 10px;
  margin-left: 22px;
}
.col_seat-7 {
  margin-left: 70px;
  width: 10px;
}
.seat::before {
  /*counter-increment: section;*/
  /*content: counter(section);*/
}
.ticket-seat-content {
  width: 20%;
  margin-left: 40%;
}

.selected {
  border: 2px solid #6cd000;
  color: #6cd000;
  background-color: #25324e;
}
.packageticketselected {
  border: 2px solid #f2f5f0;
  color: #f2f5f0;
  background-color: #25324e;
}

.occupied {
  background-color: #303e5c;
  color: transparent;
}
.occupied:hover {
  border-color: transparent;
  color: transparent;
}

.seat:not(.occupied):hover {
  cursor: pointer;
  transform: scale(1.2);
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='5.83423' width='2' height='13' rx='1' fill='white'/%3E%3Crect x='0.498291' y='7.5' width='2' height='13' rx='1' transform='rotate(-90 0.498291 7.5)' fill='white'/%3E%3C/svg%3E");
}
.seat.selected:hover {
  background-image: unset;
}

.showcase .seat:not(.occupied):hover {
  cursor: default;
  transform: scale(1);
}
.showcase-wrap {
  border: 1px solid #303e5c;
  padding: 20px 20px 20px 20px;
  margin-left: 40px;
}
.showcase-wrap h3 {
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #ffffff;
  font-family: "Burbank-Big-Rg-Md", sans-serif;
}
.showcase-wrap .datetime {
  font-size: 16px;
  line-height: 16px;
  color: #7c8495;
  font-family: "Burbank-Small", sans-serif;
  margin-top: 18px;
}
.showcase-wrap .datetime span {
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
  display: block;
  margin-top: 10px;
}
.showcase {
  display: flex;
  list-style-type: none;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding: 0;
  margin-top: 27px;
  flex-wrap: wrap;
}

.showcase li {
  width: auto;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.showcase li small {
  margin-left: 10px;
}

.screen {
  background: #fff;
  height: 70px;
  width: 70%;
  margin: 15px 0;
  transform: rotateX(-45deg);
  box-shadow: 0 3px 10px rgba(255, 255, 255, 0.7);
}
.seats-sum {
  align-items: stretch;
  margin: 0;
  height: 100%;
  padding: 0px 5px 30px;
}
.seats-sum-ticket {
  background: #303e5c;
  border-radius: 4px;
  //padding: 26px 5px 24px;
  //margin: 30px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #7c8495;
  align-items: stretch;
  margin: 0;
  height: auto;
  padding: 10px 5px 10px;
}
.seats-sum .total-wrap,
.seats-sum-ticket .total-wrap {
  color: #0081cb;
  font-weight: 600;
  box-sizing: content-box;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-top: 14px;
}
.seats-sum {
  background: #303e5c;
  border-radius: 4px;
  //padding: 26px 5px 24px;
  //margin: 30px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #7c8495;
  align-items: stretch;
  margin: 0;
  height: 100%;
  padding: 0px 5px 30px;
  // align-items: flex-start;
  // margin-left: 40px;
}
.credits a {
  color: #fff;
}

.shape-wrap {
  background: #303e5c;
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
}
.shape {
  & + .role-label {
    //background: transparent;
    width: 33.3% !important;
    height: 46px !important;
    margin-bottom: 0 !important;
  }
}
.seats-set .sign-up__input-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  margin: 30px 0 25px;
}
.seats-set .sign-up__default-input {
  background-color: #303e5c;
}

.seats-set .price-container {
  margin-top: 0 !important;
}
#modalTicket .sign-up__input-title {
  text-align: left;
}
// .art-select__input-date{

//   position:relative;

//   z-index:2}
// .art-select__input-date::-webkit-inner-spin-button,
// .art-select__input-date::-webkit-outer-spin-button{
//   -webkit-appearance:none;
//   margin:0;
//   display:none
// }
// .art-select__input-date::-webkit-calendar-picker-indicator{
//   display:block;
//   position:absolute;
//   top:17px;
//   right:16px;
//   width:17px;
//   height:17px;
//   z-index:10;
//   cursor:pointer;
//   opacity:0;
// }
// .art-select__input-date::-webkit-clear-button{
//   -webkit-appearance:none;
//   -moz-appearance:none;
//   margin:0
// }

// .art-select__input-date:after{
//   content:"";
//   display:block;
//   position:absolute;
//   top:17px;
//   right:16px;
//   width:17px;
//   height:17px;
//   background-image: url("https://kombatlink-assets.azureedge.net/img/calendar.svg");
//  // background-color:#25324e;background-image:url(../static/svg/calendar.svg);
//   background-size:100% 100%;
//   z-index:1;
//   color:rgba(0,0,0,0);
//   cursor:pointer;
//   opacity:.5;
// }
.seats-set input::-webkit-input-placeholder {
  color: #fff;
}
.seats-set input::-moz-placeholder {
  color: #fff;
} /* Firefox 19+ */
.seats-set input:-moz-placeholder {
  color: #fff;
} /* Firefox 18- */
.seats-set input:-ms-input-placeholder {
  color: #fff;
}
.seats-set .seats__button {
  height: 55px;
}
.seats__top {
  // padding-top: 80px!important;
  padding-top: 0px !important;
}
.shape.sign-up__role:checked + .role-label {
  background: #49c3fd !important;
}
.shape.sign-up__role + .role-label .role-label__svg {
  margin-bottom: 0;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.shape.sign-up__role + .role-label .role-label__svg svg {
  fill: #394664;
}
.shape.sign-up__role:checked + .role-label .role-label__svg svg {
  fill: #fff;
}

.shape.sign-up__role:checked + .role-label .role-label__svg svg rect {
  stroke: #fff;
}
.seats_list {
  background: #303e5c;
  margin-bottom: 5px;
}
.seats_list .listed-label {
  width: 50% !important;
  background: transparent;
  margin-bottom: 0;
}
.seats_list .sign-up__listed:checked + .listed-label {
  background: #49c3fd;
  color: #ffffff;
}
.sign-up__agree-licence-container {
  margin-top: 20px;
  margin-bottom: 34px;
}
.seats-set {
  position: relative;
  width: 100%;
  background: transparent;
  padding: 10px 25px 0;

  // display: flex;
  // flex-direction: column;
  // height: 100%;
  // box-shadow: 0 3px 6px rgba(0, 0, 0,0.16), 0 3px 6px rgba(0, 0, 0,0.23);
  // position: relative;

  // // box-shadow: 0 3px 6px rgba(0,0,0,0.6);
  // padding: 15px;
}
.seats-set_title {
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #ffffff;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  padding-left: 5px;
}
hr {
  background: rgba(255, 255, 255, 0.2);
  margin: 30px 0;
}
.seats-page .header {
  background: unset;
}

.seats-page .grey-button {
  width: 140px;
  height: 40px;
}
.seats__header {
  color: white !important;
}
.seats-page .seats__header {
  color: white !important;
  font-family: "Burbank-Big-Cd-Bd", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.05em;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 40px 0 35px;
}
.seats-section {
  min-height: 286px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.seats-section:not(:empty) {
  background-image: unset;
}
.seats-section > .row > .col-12 {
  counter-reset: section;
}
/*div[class*="seats-section_triangle"]{
  background-size: 90%;
}
div[class*="seats-section_triangle_right"]{
 background-position-x: 95%;
}
div[class*="seats-section_triangle_left"]{
 background-position-x: 5%;
}*/
div[class*="seats-section_triangle"] {
  background-size: 85%;
}
div[class*="seats-section_triangle_right"] {
  background-position-x: center;
}
div[class*="seats-section_triangle_left"] {
  background-position-x: center;
}
.seats-section_triangle_left {
  background-image: url("data:image/svg+xml,%3Csvg width='239' height='239' viewBox='0 0 239 239' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M238.5 10.1421C238.5 1.67854 228.267 -2.56004 222.282 3.42462L3.42461 222.282C-2.56004 228.267 1.67854 238.5 10.1421 238.5H229C234.247 238.5 238.5 234.247 238.5 229V10.1421Z' stroke='%2325324E'/%3E%3C/svg%3E");
}
.seats-section_triangle_right {
  background-image: url("data:image/svg+xml,%3Csvg width='239' height='239' viewBox='0 0 239 239' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 10.1421C0.5 1.67854 10.7328 -2.56004 16.7175 3.42462L235.575 222.282C241.56 228.267 237.321 238.5 228.858 238.5H10C4.75329 238.5 0.5 234.247 0.5 229V10.1421Z' stroke='%2325324E'/%3E%3C/svg%3E");
}
.seats-section_triangle_right-reverse {
  background-image: url("data:image/svg+xml,%3Csvg width='239' height='238' viewBox='0 0 239 238' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 227.925C0.5 236.38 10.7141 240.623 16.7042 234.656L235.494 16.7308C241.497 10.7516 237.263 0.5 228.79 0.5H10C4.75329 0.5 0.5 4.7533 0.5 10V227.925Z' stroke='%2325324E'/%3E%3C/svg%3E");
}
.seats-section_triangle_left-reverse {
  background-image: url("data:image/svg+xml,%3Csvg width='239' height='238' viewBox='0 0 239 238' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M238.5 227.925C238.5 236.38 228.286 240.623 222.296 234.656L3.50571 16.7308C-2.49724 10.7516 1.73723 0.5 10.2099 0.5H229C234.247 0.5 238.5 4.7533 238.5 10V227.925Z' stroke='%2325324E'/%3E%3C/svg%3E%0A");
}
.seats-page .modal {
  background-color: rgba(5, 21, 52, 0.7);
}
.seats-modal .seats-section {
  min-height: 240px;
  max-width: 240px;
}
.seats-modal .seats-section_triangle_left {
  background-image: url("data:image/svg+xml,%3Csvg width='202' height='202' viewBox='0 0 202 202' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M202 10.1421C202 1.23309 191.229 -3.22858 184.929 3.07107L3.07111 184.929C-3.22855 191.229 1.23308 202 10.1421 202H192C197.523 202 202 197.523 202 192V10.1421Z' fill='%23293754'/%3E%3C/svg%3E");
}
.seats-modal .seats-section_triangle_right {
  background-image: url("data:image/svg+xml,%3Csvg width='202' height='202' viewBox='0 0 202 202' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 10.1421C0 1.23309 10.7714 -3.22858 17.0711 3.07107L198.929 184.929C205.229 191.229 200.767 202 191.858 202H10C4.47715 202 0 197.523 0 192V10.1421Z' fill='%23293754'/%3E%3C/svg%3E");
}
.seats-modal .seats-section_triangle_right-reverse {
  background-image: url("data:image/svg+xml,%3Csvg width='202' height='201' viewBox='0 0 202 201' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 190.937C0 199.835 10.7483 204.301 17.0546 198.024L198.833 17.0875C205.155 10.7946 200.699 0 191.778 0H10C4.47715 0 0 4.47716 0 10V190.937Z' fill='%23293754'/%3E%3C/svg%3E%0A");
}
.seats-modal .seats-section_triangle_left-reverse {
  background-image: url("data:image/svg+xml,%3Csvg width='202' height='201' viewBox='0 0 202 201' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M202 190.937C202 199.835 191.252 204.301 184.945 198.024L3.16695 17.0875C-3.15523 10.7946 1.30133 0 10.2216 0H192C197.523 0 202 4.47716 202 10V190.937Z' fill='%23293754'/%3E%3C/svg%3E%0A");
}
.seats-modal .seat {
  height: 19px;
  width: 19px;
  line-height: 16px;
}
.seats-modal .seats-section_square:nth-child(4),
.seats-modal .seats-section_square:nth-child(6) {
  padding: 0;
}

.seats-section_square {
  background-size: 95%;
  background-image: url("data:image/svg+xml,%3Csvg width='266' height='265' viewBox='0 0 266 265' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='265' height='264' rx='19.5' stroke='%2325324E'/%3E%3C/svg%3E");
}
.seats-section_square:nth-child(4) .row:nth-child(4),
.seats-section_square:nth-child(6) .row:nth-child(5) {
  margin-right: 0px;
}
.seats-section_square:nth-child(4) .row:nth-child(5),
.seats-section_square:nth-child(6) .row:nth-child(4) {
  margin-left: 0px;
}

.seats-section_square:nth-child(2) .row:nth-child(4),
.seats-section_square:nth-child(2) .row:nth-child(6) {
  margin-bottom: 8px;
}
.seats-section_square:nth-child(8) .row:nth-child(5),
.seats-section_square:nth-child(8) .row:nth-child(3) {
  margin-bottom: 8px;
}

.seats-section_square:nth-child(2),
.seats-section_square:nth-child(8) {
  flex-direction: column-reverse;
}
.seats-section_square:nth-child(2) > .row > .col-12,
.seats-section_square:nth-child(8) > .row > .col-12 {
  display: flex;
  padding: 0;
}
.seats-section_square:nth-child(2) .seat,
.seats-section_square:nth-child(8) .seat {
  margin: 4.27px 2px;
  line-height: 16px;
}
.seats-section_square:nth-child(4) {
  background-position-x: 0;
  justify-content: flex-start;
  flex-direction: row-reverse;
}
.seats-section_square:nth-child(6) {
  background-position-x: 100%;
}
.seats-modal .seats-section_center {
  height: calc(100% - 30px);
  margin: 15px;
  font-size: 30.7212px;
  line-height: 31px;
}
.seats-section_center {
  background: #0a1a38;
  border-radius: 20px;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.2em;
  color: #2e3f5f;
  text-align: center;
  border-radius: 20px;
  margin: 15px 10px;
  height: calc(100% - 50px);
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

#seats-2 .seats-section_center {
  border-radius: 171px;
  height: 440px;
  width: 240px;
}
#seats-2 {
  margin-right: 20px;
}
#seats-2,
#seats-3 {
  margin-top: 190px;
}
.row-space-scroll {
  overflow-y: auto;
  height: 150px;
  overflow-x: hidden;
}
#seats-3 .seats-section_center {
  border-radius: 50%;
  height: 400px;
  width: 360px;
  // top: 429px;
  position: absolute;
}
.btn-w-40 {
  width: 40px;
  height: 55px;
  background-color: red;
  color: white;
  font-weight: 700;
}

.seats-modal {
  background-color: #25324e;
  border-radius: 4px;
  padding: 50px 42px 20px;
  position: relative;
}
.seats-modal_schema {
  margin: 15px 30px 0px 10px;
}
.seats-modal .seats-section_center {
  background: #293754;
}
.seats-modal__header {
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  font-family: "Burbank-Small", sans-serif;
  color: #ffffff;
  letter-spacing: 0.6px;
}
.seats-modal .modal-header {
  border-bottom: unset;
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1400px;
    margin: 1.75rem auto;
  }
}
.seats-modal .btn-close {
  opacity: 1;
  position: absolute;
  right: 35px;
  top: 55px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7463 0.264328C13.4077 -0.073735 12.8591 -0.073735 12.5208 0.264328L7.00539 5.77545L1.49003 0.264328C1.15742 -0.0796549 0.608779 -0.0891688 0.264531 0.243186C-0.0797163 0.575328 -0.0892375 1.12354 0.243373 1.46753C0.250355 1.47471 0.257338 1.4819 0.264531 1.48888L5.7799 7L0.264531 12.5111C-0.0797163 12.8433 -0.0892375 13.3915 0.243373 13.7357C0.575772 14.0797 1.12441 14.0892 1.46866 13.7568C1.47585 13.7498 1.48305 13.7429 1.49003 13.7357L7.00539 8.22455L12.5208 13.7357C12.865 14.0678 13.4136 14.0583 13.746 13.7143C14.0704 13.3788 14.0704 12.8466 13.746 12.5111L8.23089 7L13.7463 1.48888C14.0846 1.15082 14.0846 0.602602 13.7463 0.264328Z' fill='white'/%3E%3C/svg%3E");
}

[class*=" icon-"]:before,
[class^="icon-"]:before {
  font-family: kombatlink-icon, monospace;
  font-style: normal;
  font-weight: 400;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-nav-tournaments:before {
  content: "\e812";
}
.icon-nav-challenges:before {
  content: "\e80e";
}
.icon-nav-buy_sell:before {
  content: "\e80d";
}
.icon-nav-store:before {
  content: "\e811";
}
.icon-nav-news:before {
  content: "\e818";
}
.icon-nav-leaderboard:before {
  content: "\e823";
}
.p-l-lg-135 {
  padding-left: 135px;
}
.m-0--45 {
  margin: 0 -45px;
}
.m-0--30 {
  margin: 0 -30px;
}
.m-0--25 {
  margin: 0 -25px;
}
.m-0-30 {
  margin: 0 30px;
}
.m-0--10 {
  margin: 0 -10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-0 {
  margin-left: 0;
}
.m-0--20 {
  margin: 0 -20px;
}
.m-0--31 {
  margin: 0 -31px;
}
.m-0-15 {
  margin: 0 15px;
}
.p-r-0 {
  padding-right: 0;
}
.p-r-15 {
  padding-right: 15px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-r-16 {
  padding-right: 16px !important;
}
.p-r-30 {
  padding-right: 30px;
}
.p-r-35 {
  padding-right: 35px;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-l-7 {
  padding-left: 7px !important;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-30 {
  margin-right: 30px !important;
}
.m-r--30 {
  margin-right: -30px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.p-0-10 {
  padding: 0 10px;
}
.p-0-20 {
  padding: 0 20px;
}
.p-0-25 {
  padding: 0 25px;
}
.m-r-15 {
  margin-right: 15px;
}
.p-l-0 {
  padding-left: 0;
}
.p-l-1 {
  padding-left: 1px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-l-25 {
  padding-left: 25px;
}
.m-40 {
  margin: 40px 0;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-b-13 {
  margin-bottom: 13px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.m-0-5 {
  margin: 0 5px;
}
.p-0 {
  padding: 0;
}
.p-0-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.p-0-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.p-0-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p-0-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.p-32 {
  padding-bottom: 32px;
}
.p-32,
.p-t-32 {
  padding-top: 32px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-38 {
  margin-top: 38px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.w-200 {
  width: 200px;
}
.p-l-8 {
  padding-left: 8px !important;
}
.p-r-8 {
  padding-right: 8px !important;
}
.color-blue {
  color: #49c3fd;
}
.color-purple {
  color: #3c3daa;
}
.col-65 {
  width: 54%;
}
.hover-color-green:hover {
  color: #6cd000;
}
.opacity-5 {
  opacity: 0.5;
}
.z-index-5 {
  z-index: 5;
}
.z-index-10 {
  z-index: 10;
}
#modalTicket .blue-button,
#modalTicket .green-button,
#modalTicket .green-hover-button,
#modalTicket .grey-button,
#modalTicket .orange-button,
#modalTicket .pink-button,
#modalTicket .purple-button,
#modalTicket .red-button,
#modalTicket .white-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -ms-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  border-radius: 2px;
  width: 200px;
  height: 50px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  font-family: Burbank-Big-Cd-Bd, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
#modalTicket .blue-button {
  background: rgba(0, 0, 0, 0);
  color: #49c3fd;
  border: 1px solid #49c3fd;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}
#modalTicket .blue-button:hover {
  color: #fff;
  text-decoration: none;
  background: #49c3fd;
}
#modalTicket .blue-button--filled {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#02b7fe),
    to(#0389fd)
  );
  background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
  background: linear-gradient(90deg, #02b7fe, #0389fd);
  border: none;
  color: #fff;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
#modalTicket .blue-button--filled:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#13a9e4),
    to(#0a71cb)
  );
  background: -o-linear-gradient(left, #13a9e4 0, #0a71cb 100%);
  background: linear-gradient(90deg, #13a9e4, #0a71cb);
}
#modalTicket .blue-button--filled-no-gradient {
  background: #49c3fd;
  border: none;
  color: #fff;
}
#modalTicket .blue-button--filled-no-gradient:hover {
  background: #0389fd;
}
#modalTicket .blue-button--transparent-filled {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(2, 183, 254, 0.1)),
    to(rgba(3, 137, 253, 0.1))
  );
  background: -o-linear-gradient(
    left,
    rgba(2, 183, 254, 0.1) 0,
    rgba(3, 137, 253, 0.1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(2, 183, 254, 0.1),
    rgba(3, 137, 253, 0.1)
  );
  border: none;
}
#modalTicket .blue-button--transparent-filled:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#02b7fe),
    to(#0389fd)
  );
  background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
  background: linear-gradient(90deg, #02b7fe, #0389fd);
}
#modalTicket .green-button {
  background: rgba(0, 0, 0, 0);
  color: #6cd000;
  border: 1px solid #6cd000;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}
#modalTicket .green-button:hover {
  background: #6cd000;
  color: #fff;
  text-decoration: none;
}
#modalTicket .green-button--filled {
  background: #6cd000;
  border: none;
  color: #fff;
}
#modalTicket .green-button--filled:hover {
  background: #64bc04;
}
#modalTicket .red-button {
  background: linear-gradient(225deg, #e64846 -0.03%, #d61d57 96.73%);
  color: white;
  border: 1px solid #e13b4c;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}
#modalTicket .red-button:hover {
  background: #e13b4c;
  color: #fff;
  text-decoration: none;
}
#modalTicket .red-button--filled {
  background: #e13b4c;
  border: none;
  color: #fff;
  padding: 5px;
}
#modalTicket .red-button--filled:hover {
  background: #cb2f3f;
}
#modalTicket .orange-button {
  background: rgba(0, 0, 0, 0);
  color: #ec5418;
  border: 1px solid #ec5418;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}
#modalTicket .orange-button:hover {
  background: #ec5418;
  color: #fff;
  text-decoration: none;
}
#modalTicket .orange-button--filled {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ec5418),
    to(#df2d06)
  );
  background: -o-linear-gradient(left, #ec5418 0, #df2d06 100%);
  background: linear-gradient(90deg, #ec5418, #df2d06);
  border: none;
  color: #fff;
}
#modalTicket .orange-button--filled:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#df551f),
    to(#c22c0b)
  );
  background: -o-linear-gradient(left, #df551f 0, #c22c0b 100%);
  background: linear-gradient(90deg, #df551f, #c22c0b);
}
#modalTicket .pink-button {
  background: rgba(0, 0, 0, 0);
  color: #cb25b3;
  border: 1px solid #cb25b3;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}
#modalTicket .pink-button:hover {
  background: #cb25b3;
  color: #fff;
  text-decoration: none;
}
#modalTicket .pink-button--filled {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#cb25b3),
    to(#7d2eae)
  );
  background: -o-linear-gradient(left, #cb25b3 0, #7d2eae 100%);
  background: linear-gradient(90deg, #cb25b3, #7d2eae);
  border: none;
  color: #fff;
}
#modalTicket .pink-button--filled:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#a91493),
    to(#640d9a)
  );
  background: -o-linear-gradient(left, #a91493 0, #640d9a 100%);
  background: linear-gradient(90deg, #a91493, #640d9a);
}
#modalTicket .purple-button {
  background: rgba(0, 0, 0, 0);
  color: #3c3daa;
  border: 1px solid #3c3daa;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}
#modalTicket .purple-button--filled {
  background: #3c3daa;
  border: none;
  color: #fff;
}
#modalTicket .purple-button--transparent-filled {
  background: rgba(60, 61, 170, 0.05);
  color: #3c3daa;
  border: none;
}
#modalTicket .purple-button:hover {
  background: #3c3daa;
  color: #fff;
  text-decoration: none;
}
#modalTicket .purple-button--filled:hover {
  background: #3c3d9c;
}
#modalTicket .purple-button--transparent-filled:hover {
  background: #3c3daa;
  color: #fff;
}
#modalTicket .grey-button {
  background: rgba(0, 0, 0, 0);
  color: #fff;
  border: 1px solid #4b566d;
  opacity: 0.5;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}
#modalTicket .grey-button--filled {
  background: hsla(0, 0%, 100%, 0.2);
  border: none;
  color: #fff;
  opacity: 1;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
#modalTicket .grey-button:hover {
  color: #fff;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0);
  opacity: 1;
}
#modalTicket .grey-button--filled:hover,
.grey-button:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#02b7fe),
    to(#0389fd)
  );
  background: -o-linear-gradient(left, #02b7fe 0, #0389fd 100%);
  background: linear-gradient(90deg, #02b7fe, #0389fd);
}
#modalTicket .white-button {
  background: rgba(0, 0, 0, 0);
  color: #fff;
  border: 1px solid #fff;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}
#modalTicket .white-button:hover {
  color: #061235;
  text-decoration: none;
  background: #fff;
}
#modalTicket .green-hover-button:hover {
  background: #6cd000;
  border: none;
  color: #fff;
}
#modalTicket .red-hover-button:hover {
  background: #e13b4c;
  border: none;
  color: #fff;
}
@-webkit-keyframes show {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
label {
  margin-bottom: 0;
}

.palette {
  // width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}
.shape {
  margin: 10px;
  height: 40px;
  width: 40px;
  border: solid 1px #6c7a9b;
}
.rectangle {
  height: 30px;
  border: solid 1px #6c7a9b;
  width: 40px;
  margin-left: 0;
}

.square {
  height: 30px;
  width: 30px;
  border: solid 1px #6c7a9b;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 30px solid #6c7a9b;
  margin-top: 10px;
}
.arc {
  border: 5px solid #6c7a9b;
  border-right: none;
  display: inline-block;
  // min-width: 2em;
  // min-height: 6em;
  // padding: 0.5em;
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  // transform: rotate(-45deg);
  width: 30px;
  height: 44px;
  margin-left: 15px;
  margin-top: 5px;
}

.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  position: relative;

  // box-shadow: 0 3px 6px rgba(0,0,0,0.6);
  padding: 15px;
}

.properties {
  margin-top: 10px;
  margin-bottom: 20px;
}

.key {
  color: lightslategray;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  height: 25px;
}

.value {
  color: darkslategray;
  font-weight: bolder;
}

.no-data {
  color: darkslategray;
}

.canvas-div {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(57, 70, 100)' stroke-width='4' stroke-dasharray='6%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
#modalTicket .square-section {
  border: 1px solid #53617e;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #25324e;
}
#modalTicket .square-section-seats {
  position: absolute;
  width: 673px;
  height: 669px;
  left: 321px;
  top: 251px;
  border: 1px solid #53617e;
  box-sizing: border-box;
  border-radius: 20px;
}
#modalTicket .dot {
  height: 19px;
  width: 19px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
#modalTicket .no-wrap {
  flex-wrap: nowrap !important;
  padding: 0px 5px 0px 15px;
}
#modalTicket .scroll-seat-container {
  height: 460px; //top: 0;
  right: 0; //bottom:0;
  position: fixed;
  overflow-y: auto; //overflow-y:scroll;
  overflow-x: auto;
  box-sizing: border-box;
  border-radius: 20px;
}
#modalTicket .scroll-seat-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.445);
  border-radius: 10px;
}
#modalTicket .scroll-seat-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

#modalTicket .font-white {
  color: #ffffff !important;
}

#modalTicket .container-title {
  font-size: 20px;
  font-weight: 400;
  color: #a2a8b5;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
}

#modalTicket .border-bottom {
  border-bottom: 1px #dedede solid;
}

#modalTicket .section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
}
#modalTicket .section-title {
  // font-size: 22px !important;
  font-weight: 500;
  font-size: 30px !important;
  line-height: 30px;
  font-family: "Burbank-Small", sans-serif;
  letter-spacing: 0.6px;
}
#modalTicket .section-title-header{
  font-weight: 500;
  font-size: 25px !important;
  line-height: 30px;
  font-family: "Burbank-Small", sans-serif;
  letter-spacing: 0.6px;
}

#modalTicket .section-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#modalTicket .checkbox-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #394664;
}
#modalTicket .checkbox-btn:nth-child(2) svg {
  margin-right: 5px !important;
}
#modalTicket .checkbox-btn:first-child {
  border-radius: 6px 0 0 6px;
}
#modalTicket .checkbox-btn:last-child {
  border-radius: 0 6px 6px 0;
}
#modalTicket .checkbox-btn label.role-label {
  width: 100% !important;
  background: none;
  cursor: pointer;
}
#modalTicket .checkbox-btn .role-label__svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 !important;
  padding: 5px;
}
#modalTicket .checkbox-btn .role-label__svg span {
  color: #6b7999;
  text-transform: capitalize;
  margin-top: 3px;
}
#modalTicket .checkbox-btn .role-label__svg svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.shape.sign-up__role:checked + .role-label span {
  color: #fff !important;
}
// #modalTicket .konvajs-content canvas{
//   height :710px !important;
// }
// comman css
#modalTicket .btn-gray {
  background-color: #515b71;
  color: #fff;
  border-radius: 4px;
  text-transform: uppercase;
  border: 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  padding: 5px 40px 3px;
}
#modalTicket .btn-gray:hover {
  background-color: linear-gradient(90deg, #02b7fe, #0389fd);
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0);
  opacity: 1;
  color: #fff;
}

#modalTicket .properties-ul {
  padding: 0;
  list-style: none;
  margin: 20px 0;
}
#modalTicket .properties-ul li {
  color: #b1b6c1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #394664;
  padding: 8px 10px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
}
#modalTicket .properties-ul .value {
  background-color: transparent;
  border-radius: 2px;
}
#modalTicket .properties button {
  margin-left: 0 !important;
  text-transform: uppercase;
  border-radius: 3px !important;
  font-size: 14px;
  padding: 8px 30px;
  font-weight: 600;
  letter-spacing: 0.3px;
}
#modalTicket .section-save-btn {
  padding: 10px 0;
  background-color: #25324e;
}
#modalTicket .section-save-btn .green-button--filled {
  margin-left: 0 !important;
  width: 100%;
  border-radius: 3px;
  padding: 14px 10px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.3px;
}
#modalTicket .panel .inner-panel {
  height: 500px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

#modalTicket .panel .inner-panel::-webkit-scrollbar {
  display: none;
}

// seat section
.seat-back-arrow {
  color: #fff !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.seat-back-arrow svg {
  margin-right: 10px;
}

.square-section {
  background-color: #25324e;
}
.seat-left-box {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(57, 70, 100)' stroke-width='4' stroke-dasharray='6%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 10px;
  height: 550px;
}

.seat-left-box::-webkit-scrollbar {
  display: none;
}

.all-eyes-title {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
  background-color: #394664;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  letter-spacing: 0.8px;
}

#modalTicket .row-space-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.445);
  border-radius: 10px;
}
#modalTicket .row-space-scroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.seats-set .btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

#modalTicket > aside > .seat-popup-container > .model-popup-close::after,
#modalTicket > aside > .seat-popup-container > .model-popup-close::before {
  background-color: #fff !important ;
}

#space-container .btn {
  margin-left: 0 !important; 
}

.old-kl-css .error__text {
  font-size: 12px !important;
}
.article__error {
  margin-top: 10px !important;
}
.showcase-wrap > .properties > ul {
  margin-top: 27px !important;
}
.small-font {
  font-size: 1rem !important;
}
.modal-section-save-btn {
  width: 100% !important;
  justify-content: center;
}
#listed-container-prop .list-prop {
  background: #394664;
  padding: 13px 16px;
  height: 46px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  color: #c1c5ce;
  font-family: "Burbank-Small", sans-serif;
  margin-bottom: 10px;
}
.mt-2 {
  margin-top: 2px;
}
.shape-wrapper:hover {
  cursor: pointer;
}
.btn-section-detail {
  height: 50px;
  border: none;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  font-family: "Burbank-Big-Cd-Bd", ui-sans-serif;
  text-transform: uppercase;
  /* line-height: 14px; */
  text-align: center;
  letter-spacing: 0.05em;
  font-style: normal;
}
.tableFixHead {
  overflow-y: auto;
  height: 150px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  background: #303e5c;
}
.tableFixHead table {
  border-collapse: collapse;
  width: 100%;
}
.tableFixHead table th,
.tableFixHead table td {
  padding: 8px 16px;
}
.showcase-wrap-btn {
  padding: 20px 20px 20px 20px;
  margin-left: 40px;
}
.sold-out-seats {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #cb5d5d;
  font-size: 45px;
  text-transform: capitalize;
}
.controller-btn {
  display: flex;
  justify-content: flex-start;
}

.controller-btn .btn {
  max-width: 200px;
  width: 100%;
}

@media screen and (max-width: 1199px) {
  .seats-set {
    padding: 10px;
  }
  .shape-wrap {
    flex-wrap: wrap;
  }
  .seats_list {
    flex-wrap: wrap;
  }
  #modalTicket .checkbox-btn .role-label__svg {
    justify-content: flex-start;
    width: 100%;
    padding: 15px;
  }
  #listed-container-prop .list-prop span {
    margin-bottom: 10px;
  }
  #listed-container-prop .list-prop {
    padding: 10px;
    flex-wrap: wrap;
    height: auto;
  }
  #modalTicket .section-title {
    font-size: 15px !important;
  }
  .controller-btn {
    flex-wrap: wrap;
  }
  .controller-btn .btn {
    width: 100%;
  }
  #modalTicket .red-button--filled{
    padding: 5px;
  }

  .showcase-wrap{
    padding:10px;
    margin: 0;
  }
  .showcase-wrap-btn.confirm-btn{
    margin: 0;
  }
}

@media screen and (max-width: 991px) {
  #modalTicket .sign-up__input-title {
    margin: 15px 0 5px 0;
  }
  .canvas-div{
    overflow: auto;
  }
  #modalTicket .section-head{
    flex-wrap: wrap;
  }
  #modalTicket .window{
    padding: 20px;
  }
}
